import React from 'react'
import {
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  SectionTitle,
  t
} from '../../../Common'

const TagsDetails = props => {
  const {
    name,
    note,
    color,
    externalId
  } = props

  return (
    <div className='ta-tags__preview__details'>

      {(externalId &&
        <IconListItem icon='fingerprint' type='regular'>
          <IconListItemTitle>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      {(name &&
        <IconListItem icon='tag' iconType='regular'>
          <IconListItemTitle>{t('global.name.label')}:</IconListItemTitle>
          <IconListItemBody>{name}</IconListItemBody>
        </IconListItem>
      )}
      {(color &&
        <IconListItem icon='palette' iconType='regular'>
          <IconListItemTitle>{t('global.color')}:</IconListItemTitle>
          <IconListItemBody>
            <div className='ta-color-preview dotted' style={{ backgroundColor: color }} />
          </IconListItemBody>
        </IconListItem>
      )}
      {(note &&
        <>
          <SectionTitle label='Notes' icon='sticky-note' iconType='regular' />
          <IconListItem>
            {note}
          </IconListItem>
        </>
      )}

    </div>
  )
}

export default TagsDetails
