import React from 'react'
import {
  FormSection,
  SectionTitle,
  PriceInput,
  Checkbox,
  FormText,
  Row,
  Col,
  t
} from '../../../Common'

const ServiceCombinationFormPrice = props => {
  const { hasPriceOverwrite } = props

  return (
    <FormSection>
      <SectionTitle label={t('global.price.label')} icon='money-bill' />
      <FormText>
        {t('servicesGroups.form.section.serviceCombinationPrice.description')}
      </FormText>
      <Row>
        <Col>
          <Checkbox
            name='hasPriceOverwrite'
            label={t('servicesGroups.form.section.serviceCombinationPrice.overwrite')}
            theme='switch'
          />
        </Col>
      </Row>
      {(hasPriceOverwrite &&
        <PriceInput
          name='price'
          label={t('global.price.label')}
          hintText={t('global.price.hint')}
        />
      )}
    </FormSection>
  )
}

export default ServiceCombinationFormPrice
