import React from 'react'
import PropTypes from 'prop-types'
import { Link, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServiceCategoryDelete = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__resources-delete'>
      <div className='ta-popup__title'>
        {t('global.category.delete.text')}
      </div>
      <div className='ta-popup__text'>
        {dangerousHTML(t('servicesGroups.list.service.deleteCategoryNote.text'))}
      </div>
      <div className='ta-popup__buttons'>
        <Link className='ta-btn ta-btn-secondary' onClick={reject}>{t('buttons.cancel.label')}</Link>
        <Link className='ta-btn ta-btn-primary' onClick={approve}>{t('global.delete')}</Link>
      </div>
    </div>
  )
}

PopupServiceCategoryDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupServiceCategoryDelete
