import { AttributeCategory } from '../../Beauties'
import { connect, handlers, selectors } from '../../Store'

const AttributeCategoryWrapper = ({ category, attributes, isLast, isAdmin, hash }) => {
  const onAttributeClick = id => handlers.navigateToPath(`/management/attributes/${id}@@${hash}`)

  return (
    <AttributeCategory
      hash={hash}
      isAdmin={isAdmin}
      isLast={isLast}
      category={category}
      attributes={attributes}
      onAttributeClick={onAttributeClick}
    />
  )
}

const maps = (state, { category }) => ({
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
  attributes: selectors.attributesByCategorySelector(state, { categoryId: category.id })
})

export default connect(maps)(AttributeCategoryWrapper)
