import { translateServerCode } from '../../../Utils'
import { DEFAULT_TAG_HEXCOLOR } from '../../../Settings'

const companyTagsDefaults = {}

// PREVIEW
export const companyTagsPreviewErrorsTransform = error => {
  if (!error) return
  const errors = []
  errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })

  return errors
}

// FORM
export const companyTagFormTransform = ({ tag, branches, account }) => {
  if (!tag) tag = companyTagsDefaults
  const { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  const {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !tag.id
  const allowedBranchExternalIds = tag.allowedBranchExternalIds || []
  const allowedBranchIds = tag.allowedBranchIds || []
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []
  const result = {
    name: {
      value: tag.name || ''
    },
    externalId: {
      value: tag.externalId || ''
    },
    ownExternalId: tag.externalId,
    color: {
      value: tag.color || DEFAULT_TAG_HEXCOLOR
    },
    isForEvents: {
      value: !!tag.isForEvents
    },
    isForCustomers: {
      value: !!tag.isForCustomers
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }
  if (tag.id) result.id = tag.id
  if (tag.location && tag.location.values && !tag.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: (tag.location && tag.location.values) || []
    }
  }
  return result
}

export const companyTagFormValidate = (tag, externalIds) => {
  if (!tag) return
  const errors = []

  // Name
  if (!tag?.name?.value?.trim?.()) {
    errors.push({ key: 'name', value: 'errors.required', replace: [{ key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }] })
  }

  // Type
  if (!tag.isForEvents.value && !tag.isForCustomers.value) {
    errors.push({ key: 'globalErrors', value: 'errors.select.required.leastOne' })
  }

  // location
  if (!tag.location.values?.length) {
    errors.push({
      key: 'location',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.location.label', translateReplace: true }]
    })
  }

  // External ID
  const externalIdValue = tag.externalId && tag.externalId.value
  externalIds = externalIds.filter(item => item !== tag.ownExternalId)
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

export const companyTagSaveTransform = tag => {
  const result = {
    tag: {
      id: tag.id,
      externalId: (tag.externalId && tag.externalId.value) || null,
      name: tag.name && tag.name.value,
      color: tag.color && tag.color.value,
      isForEvents: tag.isForEvents && tag.isForEvents.value,
      isForCustomers: tag.isForCustomers && tag.isForCustomers.value
    }
  }
  if (tag.location && tag.location.values && !tag.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...tag.location.values,
        ...(tag.location.insufficient || [])
      ]
    }
  }
  return result
}

// UPDATE
export const companyTagTransform = tag => {
  if (!tag) return
  const result = {
    ...tag
  }
  return result
}

export const companyTagFormServerErrorsTransform = ({ error, branches }) => {
  const errors = []
  if (error && error.message) {
    if (error) {
      if (error.code === 'GlobalCustomerFieldSaveError') {
        const { data } = error || {}
        let { localErrors } = data || {}
        localErrors = localErrors || []
        const branchesWithErrors = localErrors.map(item => {
          const company = branches.find(branch => branch.id === item.companyId) || {}
          return company.name
        }) || []
        errors.push({
          key: 'globalCategoryErrors',
          value: 'errors.customerFields.branchesNotAllowed',
          replace: [
            { key: 'ENTITY_NAME', value: 'global.companyTag', translateReplace: true },
            { key: 'BRANCHES', value: branchesWithErrors.join(' <br/>') }
          ]
        })
      } else if (error.code === 'ExternalIdConflicts') {
        errors.push({ key: 'externalId', value: translateServerCode(error.code) })
      } else if (error.code === 'NoServiceAndCourse') {
        errors.push({ key: 'globalCategoryErrors', value: translateServerCode(error.code) })
      } else if (error.code === 'InvalidBranchIds') {
        errors.push({ key: 'locationErrors', value: translateServerCode(error.code) })
      } else if (error.code === 'InvalidBranchExternalIds') {
        errors.push({ key: 'locationErrors', value: translateServerCode(error.code) })
      } else {
        if (error.message) {
          errors.push({ key: 'customerFieldsGlobalErrors', value: error.message })
        } else {
          errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
        }
      }
    }
  }
  return errors
}
