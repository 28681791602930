import React from 'react'

import {
  BillingFormFields,
  BillingPreviewMethod
} from '../../../Beauties'
import {
  StripedTitle,
  FormSection,
  FormButtons,
  Spinner,
  Error,
  Radio,
  Form,
  Link,
  Row,
  Col,
  Alert,
  t
} from '../../../Common'

import './AppsSubscription.css'

const AppsSubscriptionBillingForm = (props) => {
  const {
    country,
    paymentMethods,
    noAutoCollect,
    handleOnBillingSubmit,
    handleOnBillingCancel,
    managePaymentMethods,
    pending,
    isOwner,
    hasChangesBillingForm
  } = props
  const paymentMethodButtonClassNames = ['ta-btn', 'ta-btn-block', 'ta-btn-black-ghost']
  if (paymentMethods.length > 0) paymentMethodButtonClassNames.push('ta-apps__subscription-form__payment-method__button')

  return (
    <Form onSubmit={handleOnBillingSubmit} name='appSubscriptionBilling' className='ta-apps__subscription-form'>
      {(pending &&
        <div className='ta-apps__subscription-form__billing-form__spinner'><Spinner /></div>
      )}
      <StripedTitle label={t('apps.subscriptionsAdd.billingDetails.billingAddress.title')} />
      <BillingFormFields country={country} />
      <StripedTitle label={t('global.paymentMethod')} />
      {paymentMethods.length === 0 && (
        <Row>
          <Col>
            <Alert noOffset label={t('global.noResults')} theme='no-content' />
          </Col>
        </Row>
      )}
      {paymentMethods.map(item => (
        <Row key={item.id}>
          <Col>
            <div className='ta-apps__subscription-form__payment-method'>
              <Radio name='paymentMethod' value={item.id}>
                <BillingPreviewMethod item={item} />
              </Radio>
            </div>
          </Col>
        </Row>
      ))}
      {(isOwner &&
        <Row>
          <Col>
            <Link className='ta-btn ta-btn-block ta-btn-black-ghost ' external onClick={managePaymentMethods}>
              {paymentMethods.length > 0 && t('billing.list.section.paymentDetails.buttonManage.label')}
              {paymentMethods.length === 0 && t('billing.list.section.paymentMethod.buttonAddNew.label')}
            </Link>
          </Col>
        </Row>
      )}
      <FormSection>
        <Row>
          <Col>
            <Error name='globalErrors' noTopOffset />
          </Col>
        </Row>
      </FormSection>
      {((noAutoCollect || paymentMethods.length > 0) &&
        <>
          <div className='ta-separator' />
          <FormButtons>
            <Row>
              <Col size={50}>
                <div className='ta-btn ta-btn-block ta-btn-secondary' onClick={handleOnBillingCancel}>
                  {t('buttons.cancel.label')}
                </div>
              </Col>
              <Col size={50}>
                <button type='submit' className={`ta-btn ta-btn-block ta-btn-primary ${!hasChangesBillingForm ? 'disabled' : ''}`}>
                  {t('buttons.apps.billingDetails.save')}
                </button>
              </Col>
            </Row>
          </FormButtons>
        </>
      )}
    </Form>
  )
}

export default AppsSubscriptionBillingForm
