import React from 'react'
import {
  UsersList,
  UsersTitle
} from '../../Beauties'
import {
  FixedContentHeader,
  FixedContentBody,
  FixedContent,
  t
} from '../../Common'

import './Users.css'

const Users = props => {
  const classNames = ['ta-users']
  const { users, userPermissionTypes, search, usersWrite } = props
  const userGroups = [
    { label: t('account.list.item.title.owner'), value: 'OWNER' },
    { label: t('global.custom'), value: 'CUSTOM' }
  ].concat(userPermissionTypes.map(userPermissionType => ({ label: userPermissionType.name, value: userPermissionType.id })))

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <UsersTitle userGroups={userGroups} usersWrite={usersWrite} />
        </FixedContentHeader>
        <FixedContentBody>
          <UsersList users={users} userPermissionTypes={userPermissionTypes} search={search} />
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Users
