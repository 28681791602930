import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  Loader,
  Input,
  FormText,
  SectionTitle,
  Form,
  Row,
  Col,
  Error,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCustomerFieldsDelete = props => {
  const {
    name,
    onSubmit,
    onCancel,
    pending
  } = props

  return (
    <div className='ta-popup__customer-fields-delete'>
      <Loader active={pending} />
      <SectionTitle label={t('customerFields.preview.heading.buttonDelete.tooltip.title')} icon='trash - alt' ignoreTopOffset />
      <Form name='customerFieldsDelete' onSubmit={onSubmit}>
        <FormText>{dangerousHTML(
          t('popup.customerFields.delete.warning', [{ key: 'NAME', value: name }])
        )}
        </FormText>
        <Row>
          <Col className='relative'>
            <Input
              name='name'
              label={t('global.name.label')}
              hintText={t('customerFields.preview.heading.buttonDelete.tooltip.hint')}
              mandatory
            />
          </Col>
        </Row>
        <Error name='globalErrors' noTopOffset shouldNotTranslate />
        <div className='ta-popup__buttons'>
          <Link className='ta-btn ta-btn-secondary' onClick={onCancel}>{t('buttons.cancel.label')}</Link>
          <button type='submit' className='ta-btn ta-btn-primary'>{t('global.delete')}</button>
        </div>
      </Form>
    </div>
  )
}

PopupCustomerFieldsDelete.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default PopupCustomerFieldsDelete
