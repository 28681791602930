import { AttributeCategoryWrapper } from '../../Beasts'
import { Alert, FontAwesome5, Link, t } from '../../Common'

const AttributesCategoriesList = ({ isAdmin, activeTab, groupedCategories }) => {
  const activeTabCategories = groupedCategories?.[activeTab.toUpperCase()]
  const hasCategories = !!activeTabCategories?.length

  return (
    <>
      {hasCategories && (
        activeTabCategories.map((category, idx) =>
          <AttributeCategoryWrapper key={category.id} category={category} isLast={idx === activeTabCategories.length - 1} />
        ))}
      {!hasCategories && (
        <Alert
          label={t('attributes.page.noCategories.label')}
          theme='no-content'
          noOffset
        >
          {isAdmin && (
            <Link to={`/management/attributes/categories/add@@${activeTab}`} className='ta-btn ta-btn-primary ta-attributes__categories-list__add-btn'>
              <FontAwesome5 icon='plus' />
              {t('attributes.page.addCategory.label')}
            </Link>
          )}
        </Alert>
      )}
    </>
  )
}

export default AttributesCategoriesList
