import React from 'react'
import {
  FormButtons,
  Link,
  t
} from '../../../../Common'

const BranchesServiceFormButtons = props => {
  const { cancelLink, disabled } = props || {}

  return (
    <FormButtons>
      <Link to={cancelLink} className='ta-btn ta-btn-secondary'>
        {t('buttons.cancel.label')}
      </Link>
      <button disabled={disabled} type='submit' className='ta-btn ta-btn-primary'>
        {t('buttons.save.label')}
      </button>
    </FormButtons>
  )
}

export default BranchesServiceFormButtons
