import { handlers, store } from '../../../Store'

export const companyPublicHolidaysDeleted = idsOfDeletedPhGroup => {
  idsOfDeletedPhGroup = idsOfDeletedPhGroup || []
  const state = store.getState()
  let { company } = state
  let { settings, rawSettings } = company || {}
  settings = settings || {}
  rawSettings = rawSettings || {}
  let { publicHolidays: rawPublicHolidays } = rawSettings || {}
  let { publicHolidays } = settings || {}
  publicHolidays = publicHolidays || []
  // if not set to inherit
  if (rawPublicHolidays !== null) {
    rawPublicHolidays = rawPublicHolidays || []
    rawPublicHolidays = rawPublicHolidays.filter(phGroup => !idsOfDeletedPhGroup.includes(phGroup.id))
  }
  // remove ph group that has only this resource category assigned to it
  publicHolidays = publicHolidays.filter(phGroup => !idsOfDeletedPhGroup.includes(phGroup.id))
  const companySettings = { ...settings, publicHolidays }
  const rawCompanySettings = { ...rawSettings, publicHolidays: rawPublicHolidays }
  company.settings = companySettings
  company.rawSettings = rawCompanySettings
  handlers.companyPopulate({ rawSettings: rawCompanySettings, settings: companySettings })
}
