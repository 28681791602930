import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  BorderedBox,
  FormText,
  Row,
  Col,
  Error,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Select,
  FormGroup,
  t
} from '../../../Common'

import './DependenciesInput.css'

const extractValues = arr => arr.map(({ value }) => value)

class DependenciesInput extends Component {
  constructor (props, context) {
    super(props)

    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.generateEmptyDependency = this.generateEmptyDependency.bind(this)
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.onResourceChange = this.onResourceChange.bind(this)
    this.onWorkInParalelChange = this.onWorkInParalelChange.bind(this)
    this.addDependency = this.addDependency.bind(this)
    this.deleteDependency = this.deleteDependency.bind(this)
    this.renderCategory = this.renderCategory.bind(this)
    this.renderResources = this.renderResources.bind(this)
    this.renderWorkInParallel = this.renderWorkInParallel.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.state = {
      focusedItem: null,
      focusedIndex: null
    }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOnFocus (index, item) {
    this.setState({
      focusedItem: item,
      focusedIndex: index
    })
  }

  handleOnBlur () {
    this.setState({
      focusedItem: null,
      focusedIndex: null
    })
  }

  generateEmptyDependency () {
    let { categories } = this.props
    categories = categories || []
    return {
      categoryIds: categories.length === 1 ? [categories[0].value] : null,
      resourceIds: null,
      workInParallel: false
    }
  }

  onCategoryChange (selectValues, index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    if (!dependencies[index]) return
    const selectCategoryIds = extractValues(selectValues)
    const { categoryIds, resourceIds } = dependencies[index]
    const isRemoving = selectValues?.length < categoryIds?.length && !selectCategoryIds.includes('all')
    const categoryIdToRemove = categoryIds?.find?.(id => !selectCategoryIds.includes(id)) || {}

    dependencies[index].categoryIds = selectCategoryIds
    if (isRemoving) dependencies[index].resourceIds = resourceIds?.filter?.(id => resources.find(({ value }) => value === id)?.categoryId !== categoryIdToRemove)
    if (!selectValues.length) dependencies[index].resourceIds = null

    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  onResourceChange (selectValues, index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    selectValues = selectValues.flatMap(element => element.items ? element.items : element)
    if (!dependencies[index]) return

    dependencies[index].resourceIds = selectValues.length > 0
      ? selectValues[selectValues.length - 1].value === 'all'
        ? ['all']
        : [...extractValues(selectValues).filter(value => value !== 'all')]
      : null

    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  onWorkInParalelChange (value, index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    if (!dependencies[index]) return
    dependencies[index].workInParallel = !!value
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  addDependency () {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies, this.generateEmptyDependency()],
        resources,
        categories,
        errors
      }
    })
  }

  deleteDependency (index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies.filter((item, key) => key !== index)],
        resources,
        categories,
        errors
      }
    })
  }

  getCategoryLabel (id, label) {
    const { categories } = this.props
    if (id === 'all') label = t('global.all')
    if (!label) ({ label } = categories.find(({ value }) => value === id) || {})
    if (label === 'default') label = t('global.resources')

    return label
  }

  // RENDER
  renderCategory (item, index) {
    let { categories, errors, disabled, allowedSet, isBranchForm } = this.props
    const { focusedIndex, focusedItem } = this.state
    errors = errors || []
    categories = categories || []
    const isOnlyDefaultCategory = !!(categories.length === 1)
    const isDependenciesDisabled = allowedSet && !allowedSet?.includes('dependencies')
    const isDisabled = isOnlyDefaultCategory || isDependenciesDisabled
    const displayCategories = categories.map(item => {
      return {
        ...item,
        label: this.getCategoryLabel(item.value, item.label)
      }
    })
    if (isBranchForm) displayCategories.unshift({ value: 'all', label: t('global.all'), isDependency: false })

    return (
      <HoverPopup disabled={!isDependenciesDisabled} className='ta-service-form-inputs__popup'>
        <HoverPopupContent position='top' autoSize>
          {t('globalSettings.form.section.attributes.disabled')}
        </HoverPopupContent>
        <HoverPopupTrigger>
          <div className={`ta-dependencies-input__select-container categories ${disabled ? 'disabled' : ''}`}>
            <FormGroup
              focused={focusedIndex === index && focusedItem === 'category'}
              labelText={t('servicesGroups.form.section.resources.fieldResourceCategory.label')}
            >
              <Select
                multi
                searchable
                disabled={isDisabled}
                autoComplete='off'
                aria-autocomplete='none'
                value={item.categoryIds}
                onBlur={this.handleOnBlur}
                options={displayCategories}
                className='ta-single-select'
                hasError={errors.length > 0}
                noResultsText={t('global.noResults')}
                onFocus={() => this.handleOnFocus(index, 'category')}
                onChange={(selectValue) => this.onCategoryChange(selectValue, index)}
              />
            </FormGroup>
          </div>
        </HoverPopupTrigger>
      </HoverPopup>
    )
  }

  renderResources (item, index) {
    const { focusedIndex, focusedItem } = this.state
    let { resources, categories, errors, disabled, allowedSet } = this.props
    let { categoryIds } = item
    categoryIds = categoryIds || []
    errors = errors || []
    resources = resources || []
    categories = categories || []
    const filteredResources = categoryIds.includes('all')
      ? resources
      : resources.filter(resource => categoryIds.includes(resource.categoryId))
    const resourceOptions = categories
      .filter(({ value }) => categoryIds.includes('all') || categoryIds.includes(value))
      .map(({ label, value }) => ({
        name: this.getCategoryLabel(value, label),
        items: filteredResources.filter(({ categoryId }) => categoryId === value)
      }))
    const options = [{ value: 'all', label: t('global.all') }, ...resourceOptions]
    const isDependenciesDisabled = allowedSet && !allowedSet?.includes('dependencies')

    return (
      <div className={`ta-dependencies-input__select-container resources ${disabled ? 'disabled' : ''}`}>
        <HoverPopup disabled={!isDependenciesDisabled} className='ta-service-form-inputs__popup'>
          <HoverPopupContent position='top' autoSize>
            {t('globalSettings.form.section.attributes.disabled')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <FormGroup
              focused={focusedIndex === index && focusedItem === 'resource'}
              labelText={t('servicesGroups.form.section.resources.fieldResource.placeholder')}
            >
              <Select
                multi
                noLabel
                searchable
                hasCategories
                options={options}
                autoComplete='off'
                aria-autocomplete='none'
                value={item.resourceIds}
                onBlur={this.handleOnBlur}
                className='ta-single-select'
                hasError={errors.length > 0}
                disabled={isDependenciesDisabled}
                noResultsText={t('global.noResults')}
                onFocus={() => this.handleOnFocus(index, 'resource')}
                onChange={(selectValues) => this.onResourceChange(selectValues, index)}
              />
            </FormGroup>
          </HoverPopupTrigger>
        </HoverPopup>
      </div>
    )
  }

  renderWorkInParallel (value, index) {
    let { isCourse, plan, disabled, allowedSet } = this.props
    plan = plan || 'CLASSIC'
    const yesClassNames = ['ta-radio']
    const noClassNames = ['ta-radio']
    if (plan === 'ENTERPRISE' && value) yesClassNames.push('active')
    if (plan !== 'ENTERPRISE') yesClassNames.push('disabled')
    if (!value) noClassNames.push('active')
    if (disabled) {
      yesClassNames.push('disabled')
      noClassNames.push('disabled')
    }
    // TODO: Update renderWorkInParallel to consider also the plan
    // Make it available only for enterprise users and show enterpriseOnly label

    return (
      <div className='ta-dependencies-input__work-in-parallel'>
        <FormText bold>
          {t('servicesGroups.form.service.section.resources.dependency.options.title')}
        </FormText>
        <Row>
          <Col>
            <div className='ta-radio-wrapper'>
              <div className={noClassNames.join(' ')} onClick={() => (plan === 'ENTERPRISE' && !disabled) ? this.onWorkInParalelChange(false, index) : undefined}>
                {t(isCourse
                  ? 'servicesGroups.form.group.section.resources.dependency.options.one'
                  : 'servicesGroups.form.service.section.resources.dependency.options.one'
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <HoverPopup disabled={plan === 'ENTERPRISE' && !disabled}>
              <HoverPopupContent position='top'>
                {!allowedSet?.includes('dependencies') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className='ta-radio-wrapper'>
                  <div className={yesClassNames.join(' ')} onClick={() => (plan === 'ENTERPRISE' && !disabled) ? this.onWorkInParalelChange(true, index) : undefined}>
                    {t(isCourse
                      ? 'servicesGroups.form.group.section.resources.dependency.options.all'
                      : 'servicesGroups.form.service.section.resources.dependency.options.all'
                    )}
                  </div>
                </div>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      </div>
    )
  }

  renderButtons (item, index) {
    let { dependencies, isBranchForm, plan, disabled, allowedSet } = this.props
    plan = plan || 'CLASSIC'
    dependencies = dependencies || []
    const showAddBtn = dependencies.length === 1 ? !!item.categoryIds?.length : index === (dependencies.length - 1)
    const isDependenciesDisabled = allowedSet && !allowedSet?.includes('dependencies')

    return (
      isBranchForm
        ? (
          <div className='ta-dependencies-input__buttons'>
            {item.resourceIds && item.resourceIds.length > 0 && index === (dependencies.length - 1) &&
              <HoverPopup disabled={plan === 'ENTERPRISE'} block>
                <HoverPopupContent position='top'>
                  {allowedSet?.includes('dependencies') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <div className={`ta-btn ta-btn-grey-ghost ta-dependencies-input__add-btn ${(plan !== 'ENTERPRISE' || disabled) ? 'ta-btn-disabled' : ''}`} onClick={(plan === 'ENTERPRISE' && !disabled) ? this.addDependency : undefined}>
                    <FontAwesome5 icon='plus' />
                    {t('servicesGroups.form.section.resources.buttonDependency.label')}
                  </div>
                </HoverPopupTrigger>
              </HoverPopup>}
            <div className={`ta-btn ta-btn-secondary ta-dependencies-input__buttons__btn-delete ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && this.deleteDependency(index)}>
              <FontAwesome5 icon='trash' type='regular' />
            </div>
          </div>
          )
        : (
          <>
            <div className='ta-dependencies-input__buttons'>
              {showAddBtn && !isDependenciesDisabled &&
                <div className={`ta-btn ta-btn-grey-ghost ta-dependencies-input__add-btn ${disabled ? 'ta-btn-disabled' : ''}`} onClick={!disabled && this.addDependency}>
                  <FontAwesome5 icon='plus' />
                  {t('servicesGroups.form.section.resources.buttonDependency.label')}
                </div>}
              <div className={`ta-btn ta-btn-secondary ta-dependencies-input__buttons__btn-delete ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && this.deleteDependency(index)}>
                <FontAwesome5 icon='trash' type='regular' />
              </div>
            </div>
          </>
          )
    )
  }

  render () {
    let { className, dependencies, errors, isBranchForm } = this.props
    errors = errors || []
    dependencies = dependencies || []
    const classNames = ['ta-dependencies-input']
    if (className) classNames.push(className)
    if (dependencies.length === 0) dependencies.push(this.generateEmptyDependency())
    if (dependencies.length === 1) classNames.push('one')

    return (
      <div className={classNames.join(' ')}>
        {dependencies.map((item, index) => (
          <div className='ta-dependencies-input__group' key={index}>
            <Row>
              <Col>
                {this.renderCategory(item, index)}
              </Col>
            </Row>
            {isBranchForm && !!item.categoryIds?.length && (
              <BorderedBox>
                {this.renderResources(item, index)}
                {item.resourceIds && (item.resourceIds.length > 1 || (item.resourceIds.length === 1 && item.resourceIds[0] === 'all')) && (
                  this.renderWorkInParallel(item.workInParallel, index)
                )}
              </BorderedBox>
            )}
            {errors.length > 0 && errors.map((error, key) => error.index === index &&
              <Error key={key} error={error} />
            )}
            {this.renderButtons(item, index)}
          </div>
        ))}
      </div>
    )
  }
}

const maps = (state, props) => {
  const { router } = state
  const { data } = router || {}
  const { branchId } = data || {}
  let { list: branches } = state.branches || {}
  branches = branches || []
  const branch = branches.find(item => item.id === branchId) || {}
  const { plan } = branch || {}
  return {
    resources: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].resources) || [],
    categories: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].categories) || [],
    dependencies: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].values) || [],
    errors: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].errors) || [],
    plan: plan || 'CLASSIC'
  }
}

export default feedContextInProps(connect(maps)(DependenciesInput), FormContext)
