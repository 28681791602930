import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { BillingPreview, BillingMock } from '../../Beauties'

const BillingPreviewWrapper = props => {
  let {
    hash,
    billingData,
    subscriptions,
    paymentMethods,
    isPendingMethod,
    chargebeeId,
    isChargebeeHidden,
    account,
    isBillingRead,
    isBillingWrite,
    isOwner
  } = props
  account = account || {}
  paymentMethods = paymentMethods || []
  const {
    name,
    address1,
    address2,
    city,
    zipCode,
    vatNo
  } = billingData || {}
  const { enterprisePermissions, isAdmin } = account || {}
  const accountRead = !!isAdmin || !!enterprisePermissions.accountRead
  const managePaymentMethods = () => handlers.chargebeePortalOpen('PAYMENT_SOURCES')

  useEffect(() => {
    handlers.paymentMethodsGet()
  }, [])

  return (
    <>
      {(((!isChargebeeHidden && accountRead) || isBillingRead)
        ? (
          <BillingPreview
            hash={hash}
            name={name}
            address1={address1}
            address2={address2}
            city={city}
            zipCode={zipCode}
            vatNo={vatNo}
            paymentMethods={paymentMethods}
            isPendingMethod={isPendingMethod}
            managePaymentMethods={managePaymentMethods}
            subscriptions={subscriptions}
            chargebeeId={chargebeeId}
            isOwner={isOwner}
            isBillingWrite={isBillingWrite}
          />
        )
        : <BillingMock />
      )}
    </>
  )
}

const maps = state => ({
  hash: state.router.hash,
  billingData: state.billing.data || {},
  subscriptions: state.billing.subscriptions || [],
  paymentMethods: state.paymentMethods.list || [],
  isPendingMethod: state.paymentMethods.isPending || false,
  chargebeeId: (state.account && state.account.enterprise && state.account && state.account.enterprise.chargebeeId),
  isChargebeeHidden: state.chargebee.isHidden || false,
  isOwner: state.account && state.account.isOwner,
  account: state.account,
  isBillingRead: state.account && state.account.isBillingRead,
  isBillingWrite: state.account && state.account.isBillingWrite
})

export default connect(maps)(BillingPreviewWrapper)
