import React from 'react'
import { FontAwesome5, Alert, SimpleListItem, t } from '../../../Common'

const PermissionTypePreviewChangedLocally = props => {
  let { locallyChangedData, onResetGlobalEntity, globalPermissionsGroupsWrite } = props
  locallyChangedData = locallyChangedData || []

  return (
    <div className='ta-permission-types__preview__changed-locally'>
      {locallyChangedData.map((item, i) => {
        const { branch, id } = item
        const { id: branchId, name, region } = branch || {}

        return (
          <SimpleListItem className='ta-permission-types__preview__changed-locally__branch' key={branchId || i}>
            <div className='ta-permission-types__preview__changed-locally__branch__data'>
              <p className='ta-permission-types__preview__changed-locally__branch__name'>{name || t('global.insufficientPermissions')}</p>
              {(branchId &&
                <div className='ta-permission-types__preview__changed-locally__item'>
                  <FontAwesome5 icon='hashtag' type='s' /> <p>{branchId}</p>
                </div>
              )}
              <div className='ta-permission-types__preview__changed-locally__item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{id}</p>
              </div>
            </div>
            {(globalPermissionsGroupsWrite && branchId &&
              <div className='ta-services__preview--changed-locally--branch__buttons'>
                <button onClick={() => onResetGlobalEntity(branchId, region)}>
                  {t('mobileApp.customers.filter.buttonReset.label')}
                </button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {(!locallyChangedData.length &&
        <Alert noOffset label={t('tags.noBranches')} theme='no-content' />
      )}
    </div>
  )
}

export default PermissionTypePreviewChangedLocally
