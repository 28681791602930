import React from 'react'
import {
  Title,
  Link,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

import { PopupTriggerWrapper } from '../../Beasts'
import './Services.css'

const Services = props => {
  const {
    isDragAndDropMode,
    areExpanded,
    onCategoriesToggle,
    globalServicesWrite
  } = props

  return (
    <div className='ta-services__title'>
      {(isDragAndDropMode &&
        <Title label={areExpanded ? t('servicesGroups.list.serviceReorder.heading') : t('global.categoryOrder')}>
          {(globalServicesWrite &&
            <>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {(areExpanded
                    ? t('global.switchCategoryOrder')
                    : t('servicesGroups.list.buttonHeading.switchServiceOrderTooltip')
                  )}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link className='ta-btn ta-btn-secondary ta-btn-icon-only' action={onCategoriesToggle}>
                    <FontAwesome5 icon={areExpanded ? 'compress-alt' : 'expand-alt'} />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.exitOrdering')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link to='/management/services' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                    <FontAwesome5 icon='times' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
      {(!isDragAndDropMode &&
        <Title label={t('global.services')}>
          <PopupTriggerWrapper name='services-help-box' position='bottom' extraClassName='ta-help-box'>
            <Link className='ta-title__link' external>
              {t('global.help')} <FontAwesome5 icon='lightbulb' />
            </Link>
          </PopupTriggerWrapper>
          {(globalServicesWrite &&
            <>
              {/* <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.serviceOrderTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link to='/management/services/reorder' className='ta-btn ta-btn-secondary ta-btn-icon-only'>
                    <FontAwesome5 icon='arrows-v' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup> */}
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.serviceCategoryTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link to='/management/services/categories/add' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                    <FontAwesome5 icon='folder' type='solid' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
    </div>
  )
}

export default Services
