import { createSelector } from '../../../Utils'

export const companySelector = state => state.company

export const companyPlanSelector = createSelector(
  companySelector,
  company => (company || {}).plan
)

export const companyLocaleSelector = createSelector(
  companySelector,
  company => (company || {}).locale
)

export const companyCustomisationsSelector = createSelector(
  companySelector,
  company => (company || {}).enterpriseCustomisation?.payload
)

export const companyFieldSelector = createSelector(
  companySelector,
  (_, props) => props.field,
  (company, field) => (company || {})[field]
)

export const companySettingsFieldSelector = createSelector(
  state => companyFieldSelector(state, { field: 'settings' }),
  (_, props) => props.field,
  (settings, field) => (settings || {})[field]
)
