import React from 'react'
import {
  Link,
  Form,
  SectionTitle,
  Textarea,
  Row,
  Col,
  Loader,
  FormText,
  t,
  Error
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAppsCancelSubscription = props => {
  const {
    onSubmit,
    onCancel,
    pending,
    name
  } = props

  return (
    <div className='ta-popup__apps-subscription-cancel'>
      <Loader active={pending} />
      <SectionTitle
        label={dangerousHTML(t('apps.subscription.cancel'))}
        icon='unlink'
        ignoreTopOffset
      />
      <Form name='popup' onSubmit={onSubmit}>
        <FormText>
          {dangerousHTML(t('apps.subscription.cancel.popup.text', [{ key: 'APP_NAME', value: name }]))}
        </FormText>
        <Row>
          <Col>
            <Textarea
              name='reason'
              label={t('apps.subscription.cancel.popup.reason.label')}
              hintText={t('apps.subscription.cancel.popup.reason.hint')}
              limit={200}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Error noTopOffset name='globalErrors' />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Link className='ta-btn ta-btn-secondary' onClick={onCancel}>
            {t('global.exit')}
          </Link>
          <button type='submit' className='ta-btn ta-btn-primary'>
            {t('apps.subscription.cancel.popup.buttonYes')}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default PopupAppsCancelSubscription
