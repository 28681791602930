import { translateServerCode } from '../../../Utils'

// CATEGORY FORM

export const resourceCategoryFormTransform = ({ category, branches, account }) => {
  category = category || {}
  branches = branches || []
  let { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  let {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !category.id
  const allowedBranchExternalIds = category.allowedBranchExternalIds || []
  const allowedBranchIds = category.allowedBranchIds || []
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []

  return {
    id: {
      value: category.id || ''
    },
    externalId: {
      value: category.externalId || ''
    },
    ownExternalId: category.externalId,
    name: {
      value: category.name === 'default' ? category.defaultCategoryName : (category.name || '')
    },
    isDependency: {
      value: category.isDependency ? 'dependency' : 'bookable'
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }
}

export const resourceCategoryFormValidate = (category, externalIds) => {
  if (!category) return
  const errors = []
  const categoryName = category.name && category.name.value
  if (!categoryName) errors.push({ key: 'name', value: 'errors.required', replace: [{ key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }] })
  const externalIdValue = category.externalId && category.externalId.value
  externalIds = externalIds.filter(item => item !== category.ownExternalId)
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

// CATEGORY SAVE

export const resourceCategorySaveTransform = category => {
  if (!category) return
  const result = {
    category: {
      id: category.id && category.id.value,
      externalId: (category.externalId && category.externalId.value && category.externalId.value.trim() !== '')
        ? category.externalId.value
        : null,
      name: category.name.value,
      isDependency: category.isDependency.value === 'dependency'
    }
  }
  if (category.location && category.location.values && !category.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...category.location.values,
        ...(category.location.insufficient || [])
      ]
    }
  }
  return result
}

// Filter form
export const resourceCategoryFilterTransform = ({ categories, branchesList }) => {
  const branchIds = []
  categories.forEach(item => {
    if ((item.allowedBranchIds || []).length) {
      item.allowedBranchIds.forEach(id => branchIds.push(id))
    }
  })
  const options = [{ label: 'All locations', value: 'all' }]
  const uniqueBranchIds = branchIds.filter((val, i, self) => self.indexOf(val) === i) || []
  const branchOptions = uniqueBranchIds.map(id => ({
    label: (branchesList.find(branch => branch.id === id) || {}).name, value: id
  }))

  return {
    branchName: {
      value: 'all',
      options: options.concat(branchOptions)
    }
  }
}

export const resourceCategoriesServerErrorsTransform = error => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code && error.message) {
    errors.push({ key: 'globalResourceCategories', value: error.message })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}
