import React from 'react'
import {
  SectionTitle,
  ListItem,
  BorderedBox,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './SecuritySettings.css'

const SecuritySettingsWebapp = props => {
  const {
    securitySessionConfig: sessionLength,
    securitySessionInactivityConfig: sessionInactivityLength,
    rawSettings,
    requiredAuth2FAType
  } = props
  const { value: sessionLengthValue, mode: sessionLengthMetric } = sessionLength || {}
  const { value: sessionInactivityLengthValue, mode: sessionInactivityLengthMetric } = sessionInactivityLength || {}

  let sessionMetricText = null
  if (sessionLengthMetric === 'MINUTES') sessionMetricText = t('global.minutes.label').toLowerCase()
  if (sessionLengthMetric === 'HOURS') sessionMetricText = t('global.hours.label').toLowerCase()
  if (sessionLengthMetric === 'DAYS') sessionMetricText = t('global.days.counter').toLowerCase()
  let inactivityMetricText = null
  if (sessionInactivityLengthMetric === 'MINUTES') inactivityMetricText = t('global.minutes.label').toLowerCase()
  if (sessionInactivityLengthMetric === 'HOURS') inactivityMetricText = t('global.hours.label').toLowerCase()
  if (sessionInactivityLengthMetric === 'DAYS') inactivityMetricText = t('global.days.counter').toLowerCase()
  const isSessionLengthDefault = rawSettings.security === null ||
    (rawSettings.security && rawSettings.security.sessionLength === null) ||
    (rawSettings.security && rawSettings.security.sessionLength && rawSettings.security.sessionLength.value === null && rawSettings.security.sessionLength.mode === null)
  const isSessionInactivityLengthDefault = rawSettings.security === null ||
    (rawSettings.security && rawSettings.security.sessionInactivityLength === null) ||
    (rawSettings.security && rawSettings.security.sessionInactivityLength && rawSettings.security.sessionInactivityLength.value === null && rawSettings.security.sessionInactivityLength.mode === null)

  return (
    <>
      <SectionTitle label={t('user.preview.security.title')} icon='lock' />
      <ListItem>
        {requiredAuth2FAType === 'OTP' ? t('settings.section.security.webapp.2fa.infoText.active') : t('settings.section.security.webapp.2fa.infoText')}
      </ListItem>
      <SectionTitle label={t('settings.section.security.session.heading')} icon='user-clock' />
      <ListItem>
        {(isSessionLengthDefault &&
          <>
            <strong>{t('global.defaultValues')}</strong>
            <br />
          </>
        )}
        {dangerousHTML(t('settings.section.security.session.infoText', [
          { key: 'SESSION_LENGTH', value: sessionLengthValue },
          { key: 'SESSION_METRIC', value: sessionMetricText }
        ]))}
        {(!!sessionInactivityLengthValue &&
          <BorderedBox topOffset>
            {(isSessionInactivityLengthDefault &&
              <>
                <strong>{t('global.defaultValues')}</strong>
                <br />
              </>
            )}
            {dangerousHTML(
              t('settings.section.security.session.inactivity.infoText', [
                { key: 'INACTIVITY_LENGTH', value: sessionInactivityLengthValue },
                { key: 'INACTIVITY_METRIC', value: inactivityMetricText }
              ])
            )}
          </BorderedBox>
        )}
      </ListItem>
    </>
  )
}

export default SecuritySettingsWebapp
