import { createSelector, sortByOrderIndex } from '../../../Utils'

export const attributesSelector = state => state.attributes

export const attributesFieldSelector = createSelector(
  attributesSelector,
  (_, props) => props?.field,
  (attributes, field) => attributes?.[field]
)

export const attributesCategoriesListSelector = createSelector(
  state => attributesFieldSelector(state, { field: 'categoriesList' }),
  categories => categories.sort(sortByOrderIndex)
)

export const attributesListSelector = createSelector(
  state => attributesFieldSelector(state, { field: 'list' }),
  attributes => attributes.sort(sortByOrderIndex)
)

export const attributesByCategorySelector = createSelector(
  attributesListSelector,
  (_, props) => props?.categoryId,
  (attributes, categoryId) => (attributes || []).filter(item => item.categoryId === categoryId)
)
