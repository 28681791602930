import React from 'react'
import { AvailabilityTimelinesHeader, AvailabilityTimelinesGrid } from '../../Common'
import {
  getFirstDayOfWeek,
  range,
  getUnavailableAvailabilityTimelines,
  getMergedAvailabilityTimelines
} from '../../Utils'
import { DEFAULT_AVAILABILITY_MIN, DEFAULT_AVAILABILITY_MAX } from '../../Settings'
import './AvailabilityTimelines.css'

const AvailabilityTimelines = props => {
  let { firstTimeline, secondTimeline } = props
  firstTimeline = firstTimeline || []
  const isDouble = !!secondTimeline
  const availabilityTimelines = [firstTimeline]
  if (isDouble) availabilityTimelines.push(secondTimeline)
  const availabilityTimelineLimits = availabilityTimelines.reduce((acc, availabilityTimeline) => {
    const { minBegin: min, maxEnd: max } = (availabilityTimeline || [])
      .reduce((intervals, day) => {
        const { times, isActive } = day || {}
        if (!isActive) return [...intervals]
        return [...intervals, ...(times || [])]
      }, [])
      .reduce((limits, interval) => {
        const { minBegin, maxEnd } = limits
        const newLimits = { ...limits }
        if (!minBegin || (minBegin && interval.begin < minBegin)) newLimits.minBegin = interval.begin
        if (!maxEnd || (maxEnd && interval.end > maxEnd)) newLimits.maxEnd = interval.end
        return newLimits
      }, { minBegin: '', maxEnd: '' })
    return {
      min: (!acc.min || (acc.min && min && min < acc.min)) ? min : acc.min,
      max: (!acc.max || (acc.max && max > acc.max)) ? max : acc.max
    }
  }, { min: '', max: '' })
  if (!availabilityTimelineLimits.min) availabilityTimelineLimits.min = DEFAULT_AVAILABILITY_MIN
  if (availabilityTimelineLimits.min && availabilityTimelineLimits.min <= '01:00') availabilityTimelineLimits.min = '00:00'
  if (availabilityTimelineLimits.min && availabilityTimelineLimits.min > '01:00') {
    const [minHour, minMinute] = availabilityTimelineLimits.min.split(':').map(item => parseInt(item, 10))
    let rangeMinHour = `${minHour}`
    if (minMinute < 30) rangeMinHour = `${minHour - 1}`
    availabilityTimelineLimits.min = `${rangeMinHour.padStart(2, '0')}:00`
  }
  if (!availabilityTimelineLimits.max) availabilityTimelineLimits.max = DEFAULT_AVAILABILITY_MAX
  if (availabilityTimelineLimits.max && availabilityTimelineLimits.max >= '23:00') availabilityTimelineLimits.max = '24:00'
  if (availabilityTimelineLimits.max && availabilityTimelineLimits.max < '23:00') {
    const [maxHour, maxMinute] = availabilityTimelineLimits.max.split(':').map(item => parseInt(item, 10))
    let rangeMaxHour = `${maxHour + 1}`
    if (maxMinute > 30) rangeMaxHour = `${maxHour + 2}`
    availabilityTimelineLimits.max = `${rangeMaxHour.padStart(2, '0')}:00`
  }
  const minHour = (availabilityTimelineLimits.min || '').split(':')[0]
  const maxHour = (availabilityTimelineLimits.max || '').split(':')[0]
  const parsedMin = parseInt(minHour, 10)
  const parsedMax = parseInt(maxHour, 10)
  const hoursRange = range(parsedMax - parsedMin + 1, parsedMin)

  const firstDayOfWeek = getFirstDayOfWeek()
  const orderedAvailabilityTimelines = availabilityTimelines.map(timeline => {
    const orderedAvailabilityTimeline = [...timeline].map((item, index) => ({ ...item, index }))
    if (firstDayOfWeek === 1) {
      orderedAvailabilityTimeline.push(orderedAvailabilityTimeline[0])
      orderedAvailabilityTimeline.shift()
    }
    return orderedAvailabilityTimeline
  })
  const mergedTimelines = getMergedAvailabilityTimelines(orderedAvailabilityTimelines)
  const unavailableTimelines = getUnavailableAvailabilityTimelines(mergedTimelines, availabilityTimelineLimits)

  return (
    <div className='ta-availability-timelines'>
      <AvailabilityTimelinesHeader hoursRange={hoursRange} />
      <AvailabilityTimelinesGrid
        isDouble={isDouble}
        hoursRange={hoursRange}
        timelines={mergedTimelines}
        unavailableTimelines={unavailableTimelines}
        availabilityTimelineLimits={availabilityTimelineLimits}
      />

    </div>
  )
}

export default AvailabilityTimelines
