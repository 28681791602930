import { actions, globalActions, handlers, payloads$, selectors, store } from '../../../Store'
import {
  attributeCategoryFormValidate,
  attributeCategorySaveTransform,
  attributeCategoryServerErrorsTransform,
  attributeFormValidate,
  attributeSaveTransform,
  attributeServerErrorsTransform,
  attributeDeleteFormValidate
} from './utils'
import { q } from '../../API'
import { attributeCategoryUpdated, attributeUpdated } from './subscriptions'

payloads$(actions.ATTRIBUTES_GET)
  .subscribe(async () => {
    const result = await q('getEnterpriseAttributesAndCategories')
    const { error } = result || { error: { text: 'errors.api.unavailable' } }
    if (error) return

    handlers.attributesPopulate({
      list: result.getEnterpriseAttributes,
      categoriesList: result.getEnterpriseAttributeCategories
    })
  })

payloads$(actions.ATTRIBUTES_POPULATE)
  .subscribe(() => {
    handlers.attributesReady()
  })

payloads$(actions.ATTRIBUTES_CATEGORY_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    let { list: branchesList } = state.branches || {}
    const hash = selectors.routerFieldSelector(state, { field: 'hash' })
    const categoriesList = selectors.attributesCategoriesListSelector(state) || []
    const category = categoriesList.find(item => item.id === id)
    if (branchesList.length === 0) branchesList = await globalActions.populateBranches()

    handlers.attributesCategoryFormPopulate({ category, branches: branchesList, hash })
  })

payloads$(actions.ATTRIBUTES_CATEGORY_FORM_SUBMIT)
  .subscribe(async scrollToError => {
    const state = store.getState()
    const hash = selectors.routerFieldSelector(state, { field: 'hash' })
    const form = selectors.formSelector(state, { formName: 'attributeCategory' })
    const attributeCategories = selectors.attributesCategoriesListSelector(state) || []
    const externalIds = attributeCategories.filter(({ id }) => id !== form?.id?.value).map(({ externalId }) => externalId)
    const errors = attributeCategoryFormValidate(form, externalIds)
    if (errors?.length) return setAttributeCategoryErrors(errors, scrollToError)
    const result = await q('saveEnterpriseAttributeCategory', attributeCategorySaveTransform(form))
    if (result?.error || !result) return setAttributeCategoryErrors(attributeCategoryServerErrorsTransform(result?.error))

    handlers.attributeCategoryUpdate(result)
    handlers.navigateToPath(`/management/attributes@@${hash}`)
  })

const setAttributeCategoryErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('attributeCategory', errors)
  handlers.attributesCategoryFormReady()
  scrollToError?.(errors)
}

payloads$(actions.ATTRIBUTES_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    let { list: branchesList } = state.branches || {}
    if (branchesList.length === 0) branchesList = await globalActions.populateBranches()
    const attributesList = selectors.attributesListSelector(state) || []
    const attribute = attributesList.find(item => item.id === id)
    const categoryId = selectors.routerDataFieldSelector(state, { field: 'c' })

    handlers.attributesFormPopulate({ branches: branchesList, categoryId, attribute })
  })

payloads$(actions.ATTRIBUTES_FORM_SUBMIT)
  .subscribe(async scrollToError => {
    const state = store.getState()
    const form = selectors.formSelector(state, { formName: 'attribute' })
    const hash = selectors.routerFieldSelector(state, { field: 'hash' })
    const attributesList = selectors.attributesListSelector(state) || []
    const categoriesList = selectors.attributesCategoriesListSelector(state) || []
    const branchesList = selectors.branchesFieldSelector(state, { field: 'list' }) || []
    const externalIds = attributesList.filter(item => item.id !== form?.id?.value).map(({ externalId }) => externalId)
    const attributeCategory = categoriesList.find(({ id }) => id === form?.category?.value)
    const errors = attributeFormValidate(form, externalIds)
    if (errors?.length) return setAttributeErrors(errors, scrollToError)
    const result = await q('saveEnterpriseAttribute', attributeSaveTransform(form, attributeCategory))
    if (result?.error || !result) return setAttributeErrors(attributeServerErrorsTransform(result?.error, branchesList))

    handlers.attributeUpdate(result)
    handlers.navigateToPath(`/management/attributes@@${hash}`)
  })

const setAttributeErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('attribute', errors)
  handlers.attributesFormReady()
  scrollToError?.(errors)
}

payloads$(actions.ATTRIBUTES_DELETE_FORM_SUBMIT)
  .subscribe((id, scrollToError) => {
    const state = store.getState()
    const form = selectors.formSelector(state, { formName: 'attributeDelete' })
    const attributesList = selectors.attributesListSelector(state) || []
    const selectedAttribute = attributesList.find(item => item.id === id)
    const errors = attributeDeleteFormValidate(form, selectedAttribute)
    if (errors.length) return setAttributeDeleteErrors(errors, scrollToError)

    handlers.attributesDelete(id)
    handlers.popupSet()
  })

const setAttributeDeleteErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('attributeDelete', errors)
  scrollToError?.(errors)
}

payloads$(actions.ATTRIBUTES_CATEGORY_DELETE)
  .subscribe(async id => {
    if (!id) return
    const state = store.getState()
    const hash = selectors.routerFieldSelector(state, { field: 'hash' })
    const result = await q('deleteEnterpriseAttributeCategory', { id })
    handlers.attributesPreviewReady()
    if (!result || result?.error) return
    handlers.attributesCategoryDeleted(id)
    handlers.navigateToPath(`/management/attributes@@${hash}`)
  })

payloads$(actions.ATTRIBUTES_DELETE)
  .subscribe(async id => {
    const state = store.getState()
    const hash = selectors.routerFieldSelector(state, { field: 'hash' })
    const result = await q('deleteEnterpriseAttribute', { id })
    handlers.attributesPreviewReady()
    if (!result || result?.error) return
    handlers.attributesDeleted(id)
    handlers.navigateToPath(`/management/attributes@@${hash}`)
  })

payloads$(actions.ATTRIBUTES_SUBSCRIPTION_SET)
  .subscribe(({ name, id }) => {
    const subscriptionsFunctions = {
      attributeEnterpriseUpdated: handlers.attributesUpdatedSubscription,
      attributeEnterpriseDeleted: handlers.attributesDeleted,
      attributeCategoryEnterpriseUpdated: handlers.attributeCategoryUpdatedSubscription,
      attributeCategoryEnterpriseDeleted: handlers.attributesCategoryDeleted
    }

    subscriptionsFunctions?.[name]?.(id)
  })

payloads$(actions.ATTRIBUTES_UPDATED_SUBSCRIPTION)
  .subscribe(async id => {
    if (!id) return
    const result = await q('getEnterpriseAttribute', { id })
    if (result?.error || !result) return
    attributeUpdated(result)
  })

payloads$(actions.ATTRIBUTE_CATEGORY_UPDATED_SUBSCRIPTION)
  .subscribe(async id => {
    if (!id) return
    const result = await q('getEnterpriseAttributeCategory', { id })
    if (result?.error || !result) return
    attributeCategoryUpdated(result)
  })
