import React from 'react'
import {
  Row,
  Col,
  FormSection,
  SectionTitle,
  Checkbox,
  FormText,
  HoverPopupTrigger,
  t
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'

import '../SecuritySettings.css'

const SecuritySettingsBranchManagerEditForm = props => {
  const {
    settings
  } = props

  return (
    <FormSection>
      <SectionTitle label={t('user.preview.security.title')} icon='lock' />
      <Row>
        <Col>
          <FormText noOffset>{t('settings.section.security.form.2fa.infoText')}</FormText>
        </Col>
      </Row>
      {(settings.requiredEnterpriseAuth2FAType === 'NONE' &&
      <Row>
        <Col>
          <FormText noOffset>
            {dangerousHTML(t('settings.section.security.form.2fa.warning'))}
          </FormText>
        </Col>
      </Row>
      )}
      <Row noOffset>
        <Col>
          <HoverPopupTrigger>
            <Checkbox
              name='use2FA'
              label={t('global.enable')}
              theme='switch'
            />
          </HoverPopupTrigger>
        </Col>
      </Row>
    </FormSection>
  )
}

export default SecuritySettingsBranchManagerEditForm
