import React from 'react'
import { FormButtons, Link, t } from '../../../Common'

const ServiceFormButtons = props => {
  const { cancelLink } = props || {}

  return (
    <FormButtons>
      <Link to={cancelLink} className='ta-btn ta-btn-secondary'>
        {t('buttons.cancel.label')}
      </Link>
      <button type='submit' className='ta-btn ta-btn-primary'>
        {t('buttons.saveChanges.label')}
      </button>
    </FormButtons>
  )
}

export default ServiceFormButtons
