import React from 'react'
import PropTypes from 'prop-types'
import { connect, selectors } from '../../Store'
import { ServicePreviewAvailability } from '../../Beauties'

const ServicePreviewAvailabilityWrapper = ({ service }) => {
  service = service || {}

  return (
    <ServicePreviewAvailability {...service} />
  )
}

ServicePreviewAvailabilityWrapper.propTypes = {
  service: PropTypes.object.isRequired,
  plan: PropTypes.string
}

const maps = (state) => ({
  plan: selectors.companyPlanSelector(state)
})

export default connect(maps)(ServicePreviewAvailabilityWrapper)
