import React from 'react'
import { SimpleListItem, FontAwesome5, Alert, t } from '../../Common'

const CompanyTagsToDelete = props => {
  let { tagsToDelete = [], onDeleteGlobalEntity, globalTagsWrite } = props
  tagsToDelete = tagsToDelete || []

  return (
    <div className='ta-company-tags-to-delete'>
      {(tagsToDelete.length > 0 &&
        <Alert label={t('tags.delete.pending')} theme='alert'>
          {t('tags.delete.conflicts')}
        </Alert>
      )}
      {tagsToDelete.map(item => {
        let { branch, tag } = item
        branch = branch || {}
        tag = tag || {}
        return (
          <SimpleListItem className='ta-company-tags-to-delete--branch' key={tag.id}>
            <div className='ta-company-tags-to-delete--branch__data'>
              <p className='ta-company-tags-to-delete--branch__name'>{branch.name}</p>
              <div className='ta-company-tags-to-delete--item'>
                <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
              </div>
              <div className='ta-company-tags-to-delete--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{tag.id}</p>
              </div>
            </div>
            {(globalTagsWrite &&
              <div className='ta-company-tags-to-delete--branch__buttons'>
                <button onClick={() => onDeleteGlobalEntity(branch.id, branch.region, tag.externalId, tag.id)}>{t('global.delete')}</button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {(!tagsToDelete.length &&
        <Alert noOffset label='No tags to delete' theme='no-content' />
      )}
    </div>
  )
}

export default CompanyTagsToDelete
