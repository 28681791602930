import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Link,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Alert,
  Form,
  SingleSelect,
  Blur,
  t
} from '../../../Common'
import {
  PopupTriggerWrapper
} from '../../../Beasts'
import {
  PermissionTypeItem,
  PermissionTypesLimitBanner
} from '../../../Beauties'

import './PermissionTypes.css'

// TODO: replace constant with company limit from API once it's ready
const PERMISSION_TYPES_LIMIT = 15

const PermissionTypes = props => {
  let {
    list,
    plan,
    hash,
    permissionTypesCount,
    globalPermissionsGroupsRead,
    globalPermissionsGroupsWrite
  } = props
  list = list || []
  const availableTabs = ['activePermissionGroups', 'deletedPermissionGroups']
  const activeTab = availableTabs.includes(hash) ? hash : 'activePermissionGroups'

  const permissionTypes = list.filter(permissionType => !permissionType.isGloballyDeleted)
  const markedToDelete = list.filter(permissionType => permissionType.isGloballyDeleted)

  return (
    <div className='ta-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-permission-types__title'>
            <Title label={t('permissionTypes.list.headingPermissionTypes')}>
              <PopupTriggerWrapper name='permission-types-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 icon='lightbulb' />
                </Link>
              </PopupTriggerWrapper>
              {(globalPermissionsGroupsWrite &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('permissionTypes.list.buttonHeading.addTooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link
                      to='/management/permission-types/add'
                      className={`ta-btn ta-btn-primary ta-btn-icon-only ${list.length >= PERMISSION_TYPES_LIMIT && 'disabled'}`}
                      disabled={list.length >= PERMISSION_TYPES_LIMIT}
                    >
                      <FontAwesome5 icon='plus' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalPermissionsGroupsRead ? 'inactive' : ''} active={!globalPermissionsGroupsRead} icon='ban' label={t('global.accessDenied')}>
            <div className='ta-permission-types__list'>
              <Alert label={t('permissionTypes.global.label')} theme='alert'>
                {t('permissionTypes.global.message')}
              </Alert>
              {(plan === 'ENTERPRISE' &&
                <PermissionTypesLimitBanner
                  permissionTypesCount={permissionTypesCount}
                  permissionTypesAccountLimit={PERMISSION_TYPES_LIMIT}
                />
              )}
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='activePermissionGroups' label={t('permissionTypes.activeCategories')} icon='sliders-h' />
                  <SimpleTabsHeaderItem name='deletedPermissionGroups' label={t('permissionTypes.deleteCategories')} icon='minus-octagon' />
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalPermissionsGroupsRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='activePermissionGroups'>
                    <Form name='permissionTypesFilter' className='ta-resource-categories__filter'>
                      <SingleSelect name='branchName' label={`${t('global.location')}:`} />
                    </Form>
                    {(permissionTypes.length > 0 &&
                      list.filter(permissionType => !permissionType.isGloballyDeleted).map(permissionType => (
                        <PermissionTypeItem item={permissionType} key={permissionType.id} />
                      ))
                    )}
                    {(permissionTypes.length === 0 &&
                      <Alert noOffset label={t('permissionTypes.noResults')} theme='no-content' />
                    )}
                  </SimpleTabsBodyItem>
                  <SimpleTabsBodyItem name='deletedPermissionGroups'>
                    {(markedToDelete.length > 0 &&
                      list.filter(permissionType => permissionType.isGloballyDeleted).map(permissionType => (
                        <PermissionTypeItem item={permissionType} key={permissionType.id} />
                      ))
                    )}
                    {(markedToDelete.length === 0 &&
                      <Alert noOffset label={t('permissionTypes.noResults')} theme='no-content' />
                    )}
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            </div>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

PermissionTypes.propTypes = {
  list: PropTypes.array.isRequired,
  plan: PropTypes.string,
  isAdmin: PropTypes.bool,
  permissionGroupsRead: PropTypes.bool
}

export default PermissionTypes
