import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Form,
  FormButtons,
  Input,
  Error,
  FontAwesome5,
  Link,
  // Checkbox,
  SingleSelect,
  t
} from '../../Common'
import { WEBAPP_URL } from '../../Settings'

const LoginForm = props => {
  const {
    onSubmit,
    togglePasswordFieldType,
    passwordFieldType,
    locale,
    onLanguageChange
    // showAutocompleteLoginPage
  } = props

  return (
    <div className='ta-login-form'>
      <Form onSubmit={onSubmit} name='login'>

        <Row>
          <Col>
            <Input
              name='email'
              label={t('global.email.label')}
              mandatory
              hideError
              hasAutocomplete
            />
          </Col>
        </Row>

        <Row>
          <Col className='relative'>
            <Input
              name='password'
              label={t('global.password')}
              mandatory
              hideError
              hasAutocomplete
            />
            <div className='ta-login__password-type-toggle-btn' onClick={togglePasswordFieldType}>
              <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='relative'>
            <SingleSelect
              name='language'
              label={t('global.language.label')}
              hideError
              onChangeAddon={onLanguageChange}
            />
          </Col>
        </Row>

        <Row className='ta-row-password'>
          {/* <Col size={50}>
            <Checkbox name='remember' label={t('global.passwordRemember')} />
          </Col> */}
          <Col size={50}>
            <Link
              className='pull-right ta-login_forgot-password'
              to={`${WEBAPP_URL}/forgot-password?locale=${locale}&enterprise=1`}
              external
            >{t('global.passwordForgotten')}
            </Link>
          </Col>
        </Row>

        <Error name='emailOrPassword' noTopOffset />

        <Row className='ta-row-login'>
          <Col>
            <FormButtons>
              <button type='submit' className='ta-btn ta-btn-primary ta-btn-block'>{t('global.login')}</button>
              <Link to='/login-sso' className='ta-btn ta-btn-black-ghost ta-btn-block ta-login__sso-btn'>
                {t('login.ssoLogin.btn')}
              </Link>
            </FormButtons>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

LoginForm.propTypes = {
  locale: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  togglePasswordFieldType: PropTypes.func.isRequired,
  passwordFieldType: PropTypes.string.isRequired
}

export default LoginForm
