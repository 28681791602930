import React from 'react'
import PropTypes from 'prop-types'
import {
  UserPreview,
  SectionTitle,
  IconListItem,
  Form,
  Error,
  t,
  Row,
  Col
} from '../../../Common'
import {
  CustomerPreviewDetailsField,
  CustomerPreviewDetailsTagItem
} from '../../../Beauties'

const CustomerPreviewDetails = props => {
  const { customer = {}, timezones } = props
  const defaultCategory = (customer.groupedFields || []).find(item => item.isDefault) || { fields: [] }
  const otherCategories = (customer.groupedFields || []).filter(item => !item.isDefault) || []
  const fields = customer.fields || []
  const userPreviewProps = { name: customer.fullName || customer.companyName || '' }
  const filterSalutationByDefaultOptions = ['mrs', 'mr']
  if (customer.isAvatarActive) userPreviewProps.avatarUrl = customer.avatarUrl

  return (
    <div className='ta-customers__preview__details'>
      <Row>
        <Col>
          <UserPreview {...userPreviewProps} />
        </Col>
      </Row>
      <Form name='customerPreview'>
        <Error name='globalErrors' noTopOffset />
      </Form>
      {fields.length > 0 && <hr />}
      {(customer.externalId &&
        <IconListItem icon='fingerprint'>
          <strong>{t('global.externalId')} </strong>
          {customer.externalId}
        </IconListItem>
      )}
      {defaultCategory.fields.filter(item => item.defaultId !== 'salutation' || filterSalutationByDefaultOptions.includes(item.value)).map(item => (
        <CustomerPreviewDetailsField item={item} timezones={timezones} key={item.id} showIcon />
      ))}
      {otherCategories.map(category => (
        <div key={category.id}>
          <SectionTitle label={category.name || t(category.translationKey)} icon='sliders-h' />
          {category.fields.map(item => (
            <CustomerPreviewDetailsField item={item} timezones={timezones} key={item.id} />
          ))}
        </div>
      ))}
      {((customer.tags || []).length > 0 &&
        <>
          <SectionTitle label={t('global.tags')} icon='tag' />
          {customer.tags.map(item => (
            <CustomerPreviewDetailsTagItem key={item.id} name={item.name} color={item.color} />
          ))}
        </>
      )}
    </div>
  )
}

CustomerPreviewDetails.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  birthday: PropTypes.string,
  notes: PropTypes.string,
  email: PropTypes.string,
  customFields: PropTypes.arrayOf(PropTypes.object)
}

export default CustomerPreviewDetails
