import React from 'react'
import PropTypes from 'prop-types'
import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE } from '../../../Settings'
import {
  Title,
  Link,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  IconListItem,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  IconListItemTitle,
  IconListItemBody,
  IconListItemButtons,
  HoverPopupTrigger,
  HoverPopupContent,
  HoverPopup,
  SectionTitle,
  t
} from '../../../Common'
import { PopupTriggerWrapper, UserAccountSecurityWrapper } from '../../../Beasts'

import '../User.css'

const User = props => {
  const {
    account,
    language,
    onResendEmailClick,
    onAccountChange,
    hash
  } = props
  const {
    email,
    userId,
    name,
    phone,
    pendingEmail,
    userAccounts = [],
    enterprise
  } = account

  const { id: selectedCompanyId } = enterprise || {}

  const editEmailButtonClassNames = ['ta-btn', 'ta-btn-icon-only', 'ta-btn-primary']
  if (pendingEmail) editEmailButtonClassNames.push('ta-btn-disabled')

  const systemLanguage = AVAILABLE_LANGUAGES.find(i => i.locale === language) || DEFAULT_LOCALE
  const phoneFormated = phone && '+' + phone.prefix + ' (0) ' + phone.number
  const ownCompanyAccounts = userAccounts
    .filter(item => item.authScope === 'COMPANY' && item.isOwner && item.company)
    .sort((a, b) => a.company.name > b.company.name ? 1 : -1) || []
  const ownEnterpriseAccounts = userAccounts
    .filter(item => item.authScope === 'ENTERPRISE' && item.isOwner && item.enterprise)
    .sort((a, b) => a.enterprise.name > b.enterprise.name ? 1 : -1) || []
  const resourceEnterpriseAccounts = userAccounts
    .filter(item => item.authScope === 'ENTERPRISE' && !item.isOwner && item.enterprise)
    .sort((a, b) => a.enterprise.name > b.enterprise.name ? 1 : -1) || []
  const resourceCompanyAccounts = userAccounts
    .filter(item => item.authScope === 'COMPANY' && !item.isOwner && item.company)
    .sort((a, b) => a.company.name > b.company.name ? 1 : -1) || []

  const bookerAccount = userAccounts.find(item => item.authScope === 'BOOKER')
  const developerAccount = userAccounts.find(item => item.authScope === 'DEVELOPER')
  const timifyAccount = userAccounts.find(item => item.authScope === 'TIMIFY')

  const availableTabs = ['user', 'userSecurity']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]

  return (
    <div className='ta-account__details'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-account__title'>
            <Title label={t('global.user')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <SimpleTabs active={activeTab}>

            <SimpleTabsHeader>
              <SimpleTabsHeaderItem name='user' label={<><FontAwesome5 icon='user' type='solid' />{t('global.userDetails')}</>} />
              <SimpleTabsHeaderItem name='userSecurity' label={<><FontAwesome5 icon='lock' type='solid' />{t('global.accountSecurity')}</>} />
            </SimpleTabsHeader>

            <SimpleTabsBody>
              <SimpleTabsBodyItem name='user'>
                {(userId &&
                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.userId')}:</IconListItemTitle>
                    <IconListItemBody offset='180px'>{userId}</IconListItemBody>
                  </IconListItem>
                )}

                {(name &&
                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.detailsBasic')}:</IconListItemTitle>
                    <IconListItemBody offset='180px'>{name}</IconListItemBody>
                    <IconListItemButtons>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('user.preview.details.change')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Link to='/user/edit' className='ta-btn ta-btn-icon-only ta-btn-primary'>
                            <FontAwesome5 icon='pen' type='solid' />
                          </Link>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </IconListItemButtons>
                  </IconListItem>
                )}

                {(email &&
                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.email')}:</IconListItemTitle>
                    <IconListItemBody offset='180px'>{email}</IconListItemBody>
                    <IconListItemButtons>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {pendingEmail ? t('user.preview.tabDetails.emailChangeInProcess') : t('user.preview.tabDetails.changeLoginEmail')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Link to='/user/change-email' className={editEmailButtonClassNames.join(' ')} disabled={!!pendingEmail}>
                            <FontAwesome5 icon='pen' type='solid' />
                          </Link>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </IconListItemButtons>
                  </IconListItem>
                )}

                {(pendingEmail &&
                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('user.preview.tabDetails.pendingEmail')}:</IconListItemTitle>
                    <IconListItemBody offset='180px'>{pendingEmail}</IconListItemBody>
                    <IconListItemButtons>
                      <PopupTriggerWrapper name='email-change-cancel' position='left'>
                        <HoverPopup>
                          <HoverPopupContent position='left' autoSize>
                            {t('user.preview.tabDetails.cancelEmailChange')}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                              <FontAwesome5 icon='trash' type='solid' />
                            </Link>
                          </HoverPopupTrigger>
                        </HoverPopup>
                      </PopupTriggerWrapper>
                    </IconListItemButtons>
                  </IconListItem>
                )}

                <IconListItem>
                  <IconListItemTitle width='130px'>{t('global.password')}:</IconListItemTitle>
                  <IconListItemBody offset='180px'>****************</IconListItemBody>
                  <IconListItemButtons>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('global.passwordChange')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link to='/user/change-password' className='ta-btn ta-btn-icon-only ta-btn-primary' data-testid='changePasswordButton'>
                          <FontAwesome5 icon='pen' type='solid' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </IconListItemButtons>
                </IconListItem>

                {(phoneFormated &&
                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.telephone.label')}:</IconListItemTitle>
                    <IconListItemBody offset='180px'>{phoneFormated}</IconListItemBody>
                  </IconListItem>
                )}

                {((systemLanguage && systemLanguage.name) &&
                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.systemLanguage')}:</IconListItemTitle>
                    <IconListItemBody offset='180px'>{systemLanguage.name}</IconListItemBody>
                    <IconListItemButtons>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('user.buttonHeading.editTooltip')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Link to='/user/change-language' className='ta-btn ta-btn-icon-only ta-btn-primary'>
                            <FontAwesome5 icon='pen' type='solid' />
                          </Link>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </IconListItemButtons>
                  </IconListItem>
                )}

                {(ownCompanyAccounts.length > 0 &&
                  <>
                    <SectionTitle icon='briefcase' label={t('global.webappAccounts')} />
                    {ownCompanyAccounts.map(item => {
                      const { id, name } = item.company || {}

                      return (
                        <IconListItem key={id}>
                          <IconListItemTitle>{name}</IconListItemTitle>
                        </IconListItem>
                      )
                    })}
                  </>
                )}

                {(resourceCompanyAccounts.length > 0 &&
                  <>
                    <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.companyInvitations')} />
                    {resourceCompanyAccounts.map(item => {
                      const { id, name } = item.company || {}

                      return (
                        <IconListItem key={id} className={item.isConfirmed ? '' : 'not-confirmed'}>
                          <div className={!item.isConfirmed ? 'ta-account__pending-invitation-container' : ''}>
                            <IconListItemTitle>
                              {name}
                              {(!item.isConfirmed &&
                                <div className='ta-account__pending-invitation'>
                                  {t('user.preview.tabDetails.pendingInvitation')}
                                </div>
                              )}
                            </IconListItemTitle>
                          </div>
                          {(item.isConfirmed &&
                            <IconListItemButtons>
                              <PopupTriggerWrapper
                                name='resource-unlink'
                                position='left'
                                id={id}
                              >
                                <HoverPopup>
                                  <HoverPopupContent position='left' autoSize>
                                    {name}
                                  </HoverPopupContent>
                                  <HoverPopupTrigger>
                                    <Link
                                      className='ta-btn ta-btn-delete ta-account__action-button'
                                      external
                                    >
                                      {t('global.unlink')}
                                    </Link>
                                  </HoverPopupTrigger>
                                </HoverPopup>
                              </PopupTriggerWrapper>
                            </IconListItemButtons>
                          )}
                          {(!item.isConfirmed && !item.isConfirmationEmailReSent &&
                            <IconListItemButtons>
                              <HoverPopup>
                                <HoverPopupContent position='left' autoSize>
                                  {name}
                                </HoverPopupContent>
                                <HoverPopupTrigger>
                                  <div
                                    className='ta-btn ta-btn-primary ta-account__action-button'
                                    onClick={() => onResendEmailClick({ accountId: id, email: item.email })}
                                  >
                                    {t('global.invitationResend')}
                                  </div>
                                </HoverPopupTrigger>
                              </HoverPopup>
                            </IconListItemButtons>
                          )}
                        </IconListItem>
                      )
                    })}
                  </>
                )}

                {(ownEnterpriseAccounts.length > 0 &&
                  <>
                    <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.ownerKeyAccounts')} />
                    {ownEnterpriseAccounts.map(item => {
                      const { id, name } = item.enterprise || {}
                      const isSelectedCompany = selectedCompanyId === id

                      return (
                        <IconListItem key={id}>
                          <div className={!item.isConfirmed ? 'ta-account__pending-invitation-container' : ''}>
                            <IconListItemTitle>
                              {name}
                              {!item.isConfirmed && <div className='ta-account__pending-invitation'>{t('user.preview.tabDetails.pendingInvitation')}</div>}
                            </IconListItemTitle>
                          </div>
                          {(item.isConfirmed && !isSelectedCompany &&
                            <IconListItemButtons>
                              <HoverPopup>
                                <HoverPopupContent position='left' autoSize>
                                  {name}
                                </HoverPopupContent>
                                <HoverPopupTrigger>
                                  <Link className='ta-btn ta-btn-icon-only ta-btn-delete' onClick={() => onAccountChange(id)}>
                                    <FontAwesome5 icon='angle-right' type='solid' />
                                  </Link>
                                </HoverPopupTrigger>
                              </HoverPopup>
                            </IconListItemButtons>
                          )}
                          {(!item.isConfirmed && !item.isConfirmationEmailReSent &&
                            <IconListItemButtons>
                              <HoverPopup>
                                <HoverPopupContent position='left' autoSize>
                                  {name}
                                </HoverPopupContent>
                                <HoverPopupTrigger>
                                  <div
                                    className='ta-btn ta-btn-primary ta-account__action-button'
                                    onClick={() => onResendEmailClick({ accountId: id, email: item.email })}
                                  >
                                    {t('global.invitationResend')}
                                  </div>
                                </HoverPopupTrigger>
                              </HoverPopup>
                            </IconListItemButtons>
                          )}
                        </IconListItem>
                      )
                    })}
                  </>
                )}

                {(resourceEnterpriseAccounts.length > 0 &&
                  <>
                    <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.keyAccountInvitations')} />
                    {resourceEnterpriseAccounts.map(item => {
                      const { id, name } = item.enterprise || {}
                      const isSelectedCompany = selectedCompanyId === id

                      return (
                        <IconListItem key={id}>
                          <div className={!item.isConfirmed ? 'ta-account__pending-invitation-container' : ''}>
                            <IconListItemTitle>
                              {name}
                              {!item.isConfirmed && <div className='ta-account__pending-invitation'>{t('user.preview.tabDetails.pendingInvitation')}</div>}
                            </IconListItemTitle>
                          </div>
                          {(item.isConfirmed &&
                            <IconListItemButtons>
                              <PopupTriggerWrapper
                                name='account-unlink'
                                position='left'
                                id={id}
                                disable={isSelectedCompany}
                              >
                                <HoverPopup>
                                  <HoverPopupContent position='left' autoSize>
                                    {isSelectedCompany
                                      ? t('user.preview.tabTitle.loggedIn')
                                      : name}
                                  </HoverPopupContent>
                                  <HoverPopupTrigger>
                                    <Link
                                      className={`ta-btn ta-btn-delete ta-account__action-button ${isSelectedCompany ? 'disabled' : ''}`}
                                      external
                                    >
                                      {t('global.unlink')}
                                    </Link>
                                  </HoverPopupTrigger>
                                </HoverPopup>
                              </PopupTriggerWrapper>
                            </IconListItemButtons>
                          )}
                          {(!item.isConfirmed && !item.isConfirmationEmailReSent &&
                            <IconListItemButtons>
                              <HoverPopup>
                                <HoverPopupContent position='left' autoSize>
                                  {name}
                                </HoverPopupContent>
                                <HoverPopupTrigger>
                                  <div
                                    className='ta-btn ta-btn-primary ta-account__action-button'
                                    onClick={() => onResendEmailClick({ accountId: id, email: item.email })}
                                  >
                                    {t('global.invitationResend')}
                                  </div>
                                </HoverPopupTrigger>
                              </HoverPopup>
                            </IconListItemButtons>
                          )}
                        </IconListItem>
                      )
                    })}
                  </>
                )}

                {((timifyAccount || developerAccount || bookerAccount) &&
                  <>
                    <SectionTitle icon='briefcase' label={t('user.preview.tabDetails.otherAccounts')} />
                    {(timifyAccount &&
                      <IconListItem>
                        <IconListItemTitle width='130px'>{t('user.preview.tabDetails.adminPannelAccount')}</IconListItemTitle>
                        <IconListItemBody offset='180px'>
                          <FontAwesome5 icon='check' type='solid' />
                        </IconListItemBody>
                      </IconListItem>
                    )}
                    {(developerAccount &&
                      <IconListItem>
                        <IconListItemTitle width='130px'>{t('user.preview.tabDetails.developerAccount')}</IconListItemTitle>
                        <IconListItemBody offset='180px'>
                          <FontAwesome5 icon='check' type='solid' />
                        </IconListItemBody>
                      </IconListItem>
                    )}
                    {(bookerAccount &&
                      <IconListItem>
                        <IconListItemTitle width='130px'>{t('user.preview.tabDetails.bookerAccount')}</IconListItemTitle>
                        <IconListItemBody offset='180px'>
                          <FontAwesome5 icon='check' type='solid' />
                        </IconListItemBody>
                      </IconListItem>
                    )}
                  </>
                )}

              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='userSecurity'>
                <UserAccountSecurityWrapper />
              </SimpleTabsBodyItem>
            </SimpleTabsBody>
          </SimpleTabs>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

User.propTypes = {
  account: PropTypes.object.isRequired,
  message: PropTypes.object,
  onResendEmailClick: PropTypes.func.isRequired,
  onAccountChange: PropTypes.func.isRequired
}

export default User
