import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  Title,
  Link,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

import './Courses.css'

const Courses = props => {
  const {
    isDragAndDropMode,
    areExpanded,
    onCategoriesToggle,
    globalGroupsWrite
  } = props

  return (
    <div className='ta-courses__title'>
      {(isDragAndDropMode &&
        <Title label={areExpanded ? t('servicesGroups.list.groupReorder.heading') : t('global.categoryOrder')}>
          {(globalGroupsWrite &&
            <>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {(areExpanded
                    ? t('global.switchCategoryOrder')
                    : t('servicesGroups.list.buttonHeading.switchGroupOrderTooltip')
                    )}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link
                    className='ta-btn ta-btn-secondary ta-btn-icon-only'
                    action={onCategoriesToggle}
                  >
                    <FontAwesome5 icon={areExpanded ? 'compress-alt' : 'expand-alt'} />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.exitOrdering')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link
                    to='/management/courses'
                    className='ta-btn ta-btn-primary ta-btn-icon-only'
                  >
                    <FontAwesome5 icon='times' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
      {(!isDragAndDropMode &&
        <Title label={t('global.groups')}>
          <PopupTriggerWrapper name='courses-help-box' position='bottom' extraClassName='ta-help-box'>
            <Link
              className='ta-title__link'
              external
            >
              {t('global.help')} <FontAwesome5 icon='lightbulb' />
            </Link>
          </PopupTriggerWrapper>
          {(globalGroupsWrite &&
            <>
              {/* <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.groupOrderTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link
                    to='/management/courses/reorder'
                    className={`ta-btn ta-btn-secondary ta-btn-icon-only`}
                  >
                    <FontAwesome5 icon='arrows-v' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup> */}
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.groupCategoryTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link
                    to='/management/courses/categories/add'
                    className='ta-btn ta-btn-primary ta-btn-icon-only'
                  >
                    <FontAwesome5 icon='folder' type='solid' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
    </div>
  )
}

export default Courses
