import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  FontAwesome5,
  SimpleListItemButtons,
  Link,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t,
  Avatar
} from '../../Common'
import { highlight, dangerousHTML } from '../../Utils'

const CustomersListItem = props => {
  const {
    customer = {},
    search,
    isFirst
  } = props
  const classNames = ['ta-customers__customer']
  if (customer.isDeleted) classNames.push('deleted')
  if (customer.isUpdated) classNames.push('updated')
  const { locallyUpdatedBranches, fullName, avatarUrl } = customer || {}
  const globalLabelClassNames = ['global-label', 'edited']
  const globalIcon = 'pencil'
  const locallyChangedBranches = (locallyUpdatedBranches && Object.keys(locallyUpdatedBranches).length) || false
  const customerName = customer
    ? (customer.fullName && customer.companyName)
        ? `${customer.fullName}, ${customer.companyName}`
        : customer.fullName || customer.companyName
    : null
  const globalLabelPosition = isFirst
    ? customerName.length > 2
      ? 'bottom'
      : 'top-right'
    : customerName.length > 2
      ? 'top'
      : 'right'

  return (
    <SimpleListItem
      className={classNames.join(' ')}
      key={customer.id}
      to={`/customers/${customer.id}`}
    >
      <Avatar name={fullName} image={avatarUrl} />
      <div className='ta-customers__customer__body'>
        <SimpleListItemTitle title={highlight(customerName, search)}>
          {(locallyChangedBranches &&
            <>
              <HoverPopup>
                <HoverPopupContent position={globalLabelPosition}>
                  {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
                </HoverPopupContent>
                <div className={globalLabelClassNames.join(' ')} onClick={e => e.stopPropagation()}>
                  <HoverPopupTrigger>
                    <FontAwesome5 icon={globalIcon} type='s' />
                    {t('global.changedLocally')}
                  </HoverPopupTrigger>
                </div>
              </HoverPopup>
            </>
          )}
        </SimpleListItemTitle>
        <SimpleListItemContent>
          <ul>
            <li>
              <FontAwesome5 icon='phone' />
              {(customer.phone
                ? highlight(customer.phone.phone, search)
                : <Link to={`/customers/${customer.id}/edit`}>{t('customers.list.addPhone')}</Link>
              )}
            </li>
            <li>
              <FontAwesome5 icon='envelope' />
              {(customer.email
                ? highlight(customer.email, search)
                : <Link to={`/customers/${customer.id}/edit`}>{t('customers.list.addEmail')}</Link>
              )}
            </li>
          </ul>
        </SimpleListItemContent>
      </div>
      <SimpleListItemButtons>
        <Link to={`/customers/${customer.id}@@bookings`} disabled={customer.totalBookings === 0}>
          <div className={`ta-customers__bookings-count ${customer.totalBookings === 0 ? 'empty' : ''}`}>
            <strong>{customer.totalBookings}</strong>
            {t('customers.list.totalBookings')}
          </div>
        </Link>
      </SimpleListItemButtons>
    </SimpleListItem>
  )
}

export default CustomersListItem
