import React from 'react'
import { PopupTriggerWrapper } from '../../../../Beasts'
import {
  FormSection,
  SectionTitle,
  Row,
  Col,
  BorderedBox,
  Radio,
  Checkbox,
  Alert,
  Link,
  FontAwesome5,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'

const BranchesServiceFormSettings = props => {
  let {
    hasOnlinePayment,
    hasStripeConnected,
    isCombination,
    allowedSet
  } = props
  isCombination = !!isCombination
  hasOnlinePayment = !!hasOnlinePayment
  hasStripeConnected = !!hasStripeConnected
  const hasOnlinePaymentDisabled = !hasStripeConnected && !hasOnlinePayment

  const isHasCombinationSameResourcesPreferenceDisabled = !allowedSet?.includes('hasCombinationSameResourcesPreference')
  const isIsBookableDisabled = !allowedSet?.includes('isBookable')
  const isHasOnlinePaymentDisabled = !allowedSet?.includes('hasOnlinePayment')
  const isIsPaymentMandatoryDisabled = !allowedSet?.includes('isPaymentMandatory')

  return (
    <FormSection>
      <SectionTitle label={t('branches.form.serviceSettings.edit.title')} icon='cogs' />
      {(isCombination &&
        <Row>
          <Col>
            <HoverPopup disabled={!isHasCombinationSameResourcesPreferenceDisabled} className='ta-service-form-inputs__popup'>
              <HoverPopupContent position='top'>
                {t('globalSettings.form.section.attributes.disabled')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Checkbox
                  name='hasCombinationSameResourcesPreference'
                  disabled={isHasCombinationSameResourcesPreferenceDisabled}
                  label={t('servicesGroups.form.service.section.settings.serviceCombinationSameResource.label')}
                />
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
          <Col className='vertical-center'>
            <PopupTriggerWrapper name='services-combination-same-resource' position='top'>
              <Link className='btn-small-icon-only' external>
                <FontAwesome5 icon='question-circle' type='regular' />
              </Link>
            </PopupTriggerWrapper>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <HoverPopup disabled={!isIsBookableDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                name='isActive'
                disabled={isIsBookableDisabled}
                label={t('branches.form.serviceSettings.edit.makeOnlineBookable.label')}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      <Row>
        <Col>
          <HoverPopup disabled={!isHasOnlinePaymentDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                name='hasOnlinePayment'
                label={t('branches.form.group.edit.settings.activateOnlinePayment.label')}
                disabled={hasOnlinePaymentDisabled || isHasOnlinePaymentDisabled}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      {(hasOnlinePayment &&
        <BorderedBox>
          <HoverPopup disabled={!isIsPaymentMandatoryDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Row>
                <Col>
                  <Radio name='isOnlinePaymentMandatory' value={false} label={t('branches.form.group.edit.settings.paymentMandatory.optional.label')} disabled={isIsPaymentMandatoryDisabled} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Radio name='isOnlinePaymentMandatory' value={!!true} label={t('branches.form.group.edit.settings.paymentMandatory.mandatory.label')} disabled={isIsPaymentMandatoryDisabled} />
                </Col>
              </Row>
            </HoverPopupTrigger>
          </HoverPopup>
        </BorderedBox>
      )}
      {(!hasStripeConnected && !hasOnlinePayment &&
        <Alert noOffset label={t('branches.form.serviceSettings.edit.stripeNotConnected.label')} theme='alert'>
          <p>{dangerousHTML(t('branches.form.serviceSettings.edit.stripeNotConnected.message'))}</p>
        </Alert>
      )}
      {(!hasStripeConnected && hasOnlinePayment &&
        <Alert noOffset label={t('branches.form.serviceSettings.edit.stripeNotConnectedWithPayment.label')} theme='alert'>
          <p>{dangerousHTML(t('branches.form.serviceSettings.edit.stripeNotConnectedWithPayment.message'))}</p>
        </Alert>
      )}
    </FormSection>
  )
}

export default BranchesServiceFormSettings
