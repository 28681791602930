import React from 'react'
import {
  BorderedBox,
  Row,
  Col,
  Radio,
  Accordion,
  AccordionTitle,
  AccordionBody,
  Title,
  SingleSelect,
  MultipleSelect,
  HintText,
  StripedTitle,
  Blur,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const BranchesResourceFormCustomPermissions = props => {
  let {
    availableResources,
    availableResourceCategories,
    permissionsAccountRead,
    permissionsCalendarRead,
    permissionsCalendarReadOptions,
    permissionsCalendarReadCategories,
    permissionsCalendarWrite,
    permissionsShiftPlanRead,
    permissionsShiftPlanWrite,
    permissionsCustomersRead,
    permissionsCustomersReadOptions,
    permissionsCustomersWrite,
    permissionsResourcesRead,
    permissionsResourcesReadOptions,
    permissionsResourcesWrite,
    permissionsAppsRead,
    onCategoryChange,
    isOwner,
    plan,
    hasAccount,
    hasInvitation
  } = props
  plan = plan || 'CLASSIC'
  const arePermissionsDisabled = hasInvitation || hasAccount ? undefined : true
  const resourcesOptions = (availableResources || []).map(item => ({
    label: item.name,
    value: item.id,
    categoryId: item.categoryId
  }))
  const resourceCategoriesOptions = (availableResourceCategories || []).map(item => ({
    label: item.name === 'default' ? t('branches.form.resource.permissions.sectionDefault.title') : item.name,
    value: item.id
  }))

  return (
    <div className='ta-resources__form__acl'>
      <BorderedBox>
        {(plan !== 'ENTERPRISE' &&
          <Blur
            active
            icon='lock'
            text={dangerousHTML(t('resources.form.permissions.note.enterpriseOnly'))}
            button={isOwner && {
              label: t('global.upgradeNow'),
              link: '/subscriptions'
            }}
          />
        )}
        <Accordion zIndex={140}>
          <AccordionTitle>
            <Title label={t('global.account')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsAccountRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsAccountRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
            {(permissionsAccountRead &&
              <>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
                  </Col>
                  <Col>
                    <Radio name='permissionsAccountWrite' value='NO' label={t('global.no')} inline disabled />
                    <Radio name='permissionsAccountWrite' value='YES' label={t('global.yes')} inline disabled />
                  </Col>
                </Row>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                  </Col>
                  <Col>
                    <Radio name='permissionsAccountDelete' value='NO' label={t('global.no')} inline disabled />
                    <Radio name='permissionsAccountDelete' value='YES' label={t('global.yes')} inline disabled />
                  </Col>
                </Row>
              </>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={110}>
          <AccordionTitle>
            <Title label={t('global.calendarAndScheduling')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsCalendarRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsCalendarRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
            {(permissionsCalendarRead &&
              <BorderedBox>
                <Row>
                  <Col>
                    <SingleSelect
                      disabled={arePermissionsDisabled}
                      name='permissionsCalendarReadOptions'
                      label={t('global.read')}
                      hasAll
                      options={[{
                        label: t('branches.form.resource.permissions.readSelected.placeholder'),
                        value: 'selected'
                      }]}
                    />
                  </Col>
                </Row>
                {(permissionsCalendarReadOptions === 'selected' &&
                  <>
                    <HintText>
                      {t('branches.form.resource.permissions.calendarReadSelected.hint')}
                      {/* This resource will have access to Calendars &amp; Schedulings for resources selected below and himself */}
                    </HintText>
                    <Row>
                      <Col>
                        <MultipleSelect
                          disabled={arePermissionsDisabled}
                          name='permissionsCalendarReadCategories'
                          label={t('branches.form.resource.permissions.chooseResourceCategory.label')}
                          options={resourceCategoriesOptions}
                          onChangeAddon={onCategoryChange}
                          searchable
                        />
                      </Col>
                    </Row>
                    <StripedTitle label={t('global.andOr')} />
                    <Row>
                      <Col>
                        <MultipleSelect
                          disabled={arePermissionsDisabled}
                          name='permissionsCalendarReadItems'
                          label={t('global.resources')}
                          options={resourcesOptions.filter(item => !permissionsCalendarReadCategories.includes(item.categoryId))}
                          searchable
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </BorderedBox>
            )}
            {(permissionsCalendarRead &&
              <>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
                  </Col>
                  <Col>
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsCalendarWrite'
                      value='NO'
                      label={t('global.no')}
                      inline
                    />
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsCalendarWrite'
                      value='YES'
                      label={t('global.yes')}
                      inline
                    />
                  </Col>
                </Row>
                {(permissionsCalendarWrite &&
                  <Row>
                    <Col size={15}>
                      <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                    </Col>
                    <Col>
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsCalendarDelete'
                        value='NO'
                        label={t('global.no')}
                        inline
                      />
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsCalendarDelete'
                        value='YES'
                        label={t('global.yes')}
                        inline
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={100}>
          <AccordionTitle>
            <Title label={t('global.shiftplan')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsShiftPlanRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsShiftPlanRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
            {(permissionsShiftPlanRead &&
              <>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
                  </Col>
                  <Col>
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsShiftPlanWrite'
                      value='NO'
                      label={t('global.no')}
                      inline
                    />
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsShiftPlanWrite'
                      value='YES'
                      label={t('global.yes')}
                      inline
                    />
                  </Col>
                </Row>
                {(permissionsShiftPlanWrite &&
                  <Row>
                    <Col size={15}>
                      <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                    </Col>
                    <Col>
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsShiftPlanDelete'
                        value='NO'
                        label={t('global.no')}
                        inline
                      />
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsShiftPlanDelete'
                        value='YES'
                        label={t('global.yes')}
                        inline
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={90}>
          <AccordionTitle>
            <Title label={t('global.statistics')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsStatisticsRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsStatisticsRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={80}>
          <AccordionTitle>
            <Title label={t('global.notifications')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsNotificationsRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsNotificationsRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={70}>
          <AccordionTitle>
            <Title label={t('global.customers')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsCustomersRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsCustomersRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
            {(permissionsCustomersRead &&
              <BorderedBox>
                <Row>
                  <Col>
                    <SingleSelect
                      disabled={arePermissionsDisabled}
                      name='permissionsCustomersReadOptions'
                      label={t('global.read')}
                      hasAll
                      options={[{
                        label: t('resources.form.permissions.readSelected.placeholder'),
                        value: 'selected'
                      }]}
                    />
                  </Col>
                </Row>
                {(permissionsCustomersReadOptions === 'selected' &&
                  <>
                    <HintText>
                      {t('branches.form.resource.permissions.customersReadSelected.hint')}
                    </HintText>
                    <Row>
                      <Col>
                        <MultipleSelect
                          disabled={arePermissionsDisabled}
                          name='permissionsCustomersReadCategories'
                          label={t('branches.form.resource.permissions.chooseResourceCategory.label')}
                          options={resourceCategoriesOptions}
                          onChangeAddon={onCategoryChange}
                          searchable
                        />
                      </Col>
                    </Row>
                    <StripedTitle label={t('global.andOr')} />
                    <Row>
                      <Col>
                        <MultipleSelect
                          disabled={arePermissionsDisabled}
                          name='permissionsCustomersReadItems'
                          label={t('global.resources')}
                          options={resourcesOptions.filter(item => !permissionsCalendarReadCategories.includes(item.categoryId))}
                          searchable
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </BorderedBox>
            )}
            {(permissionsCustomersRead &&
              <>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
                  </Col>
                  <Col>
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsCustomersWrite'
                      value='NO'
                      label={t('global.no')}
                      inline
                    />
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsCustomersWrite'
                      value='YES'
                      label={t('global.yes')}
                      inline
                    />
                  </Col>
                </Row>
                {(permissionsCustomersWrite &&
                  <Row>
                    <Col size={15}>
                      <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                    </Col>
                    <Col>
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsCustomersDelete'
                        value='NO'
                        label={t('global.no')}
                        inline
                      />
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsCustomersDelete'
                        value='YES'
                        label={t('global.yes')}
                        inline
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={60}>
          <AccordionTitle>
            <Title label={t('global.resources')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsResourcesRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsResourcesRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
            {(permissionsResourcesRead &&
              <BorderedBox>
                <Row>
                  <Col>
                    <SingleSelect
                      disabled={arePermissionsDisabled}
                      name='permissionsResourcesReadOptions'
                      label={t('global.read')}
                      hasAll
                      options={[{
                        label: t('branches.form.resource.permissions.readSelected.placeholder'),
                        value: 'selected'
                      }]}
                    />
                  </Col>
                </Row>
                {(permissionsResourcesReadOptions === 'selected' &&
                  <>
                    <HintText>
                      {t('branches.form.resource.permissions.resourceReadSelected.hint')}
                    </HintText>
                    <Row>
                      <Col>
                        <MultipleSelect
                          disabled={arePermissionsDisabled}
                          name='permissionsResourcesReadCategories'
                          label={t('branches.form.resource.permissions.chooseResourceCategory.label')}
                          options={resourceCategoriesOptions}
                          onChangeAddon={onCategoryChange}
                          searchable
                        />
                      </Col>
                    </Row>
                    <StripedTitle label={t('global.andOr')} />
                    <Row>
                      <Col>
                        <MultipleSelect
                          disabled={arePermissionsDisabled}
                          name='permissionsResourcesReadItems'
                          label={t('global.resources')}
                          options={resourcesOptions}
                          searchable
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </BorderedBox>
            )}
            {(permissionsResourcesRead &&
              <>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
                  </Col>
                  <Col>
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsResourcesWrite'
                      value='NO'
                      label={t('global.no')}
                      inline
                    />
                    <Radio
                      disabled={arePermissionsDisabled}
                      name='permissionsResourcesWrite'
                      value='YES'
                      label={t('global.yes')}
                      inline
                    />
                  </Col>
                </Row>
                {permissionsResourcesWrite && (
                  <Row>
                    <Col size={15}>
                      <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                    </Col>
                    <Col>
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsResourcesDelete'
                        value='NO'
                        label={t('global.no')}
                        inline
                      />
                      <Radio
                        disabled={arePermissionsDisabled}
                        name='permissionsResourcesDelete'
                        value='YES'
                        label={t('global.yes')}
                        inline
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={50}>
          <AccordionTitle>
            <Title label={t('global.servicesAndGroups')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio name='permissionsServicesRead' value='NO' label={t('global.no')} inline disabled />
                <Radio name='permissionsServicesRead' value='YES' label={t('global.yes')} inline disabled />
              </Col>
            </Row>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Radio name='permissionsServicesWrite' value='NO' label={t('global.no')} inline disabled />
                <Radio name='permissionsServicesWrite' value='YES' label={t('global.yes')} inline disabled />
              </Col>
            </Row>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
              </Col>
              <Col>
                <Radio name='permissionsServicesDelete' value='NO' label={t('global.no')} inline disabled />
                <Radio name='permissionsServicesDelete' value='YES' label={t('global.yes')} inline disabled />
              </Col>
            </Row>
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={10}>
          <AccordionTitle>
            <Title label={t('global.apps')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsAppsRead'
                  value='NO'
                  label={t('global.no')}
                  inline
                />
                <Radio
                  disabled={arePermissionsDisabled}
                  name='permissionsAppsRead'
                  value='YES'
                  label={t('global.yes')}
                  inline
                />
              </Col>
            </Row>
            {(permissionsAppsRead &&
              <>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
                  </Col>
                  <Col>
                    <Radio name='permissionsAppsWrite' value='NO' label={t('global.no')} inline disabled />
                    <Radio name='permissionsAppsWrite' value='YES' label={t('global.yes')} inline disabled />
                  </Col>
                </Row>
                <Row>
                  <Col size={15}>
                    <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                  </Col>
                  <Col>
                    <Radio name='permissionsAppsDelete' value='NO' label={t('global.no')} inline disabled />
                    <Radio name='permissionsAppsDelete' value='YES' label={t('global.yes')} inline disabled />
                  </Col>
                </Row>
              </>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion zIndex={50}>
          <AccordionTitle>
            <Title label={t('global.permissionTypes')} isCompact />
          </AccordionTitle>
          <AccordionBody>
            <Row>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
              </Col>
              <Col>
                <Radio name='permissionsGroupsRead' value='NO' label={t('global.no')} inline disabled />
                <Radio name='permissionsGroupsRead' value='YES' label={t('global.yes')} inline disabled />
              </Col>
            </Row>
          </AccordionBody>
        </Accordion>
      </BorderedBox>
    </div>
  )
}

export default BranchesResourceFormCustomPermissions
