import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  Title,
  Row,
  Col,
  Alert,
  Link,
  FontAwesome5,
  FilesInput,
  Form,
  FormButtons,
  FormSection,
  SectionTitle,
  Radio,
  HintText,
  Input,
  Error,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'
import './Branches.css'

const BranchesServiceCategoriesImport = props => {
  const {
    onSubmit,
    cancelLink,
    enableButton,
    onClickDownload,
    myPasswordFieldType,
    toggleMyPasswordFieldType
  } = props
  const btnClassNames = ['ta-btn', 'ta-btn-primary', 'ta-btn-submit']
  if (!enableButton) btnClassNames.push('disabled')

  return (
    <div className='ta-branches__import'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title label={t('branches.list.serviceCategories.import.title')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchServiceCategoriesImport'>
            <FormSection>
              <Alert label={t('branches.list.serviceCategories.import.bulk')} theme='alert'>
                {dangerousHTML(t('branches.list.serviceCategories.import.message'))}
                {/* Uncomment when Clare is done with article <Link to='...'>More information is available in this article.</Link> */}
              </Alert>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>1</div>
                    <p className='ta-branches__import__step-title'>{t('branches.list.import.downloadTemplate')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>{t('branches.list.import.clickToDownload')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link onClick={() => onClickDownload('exportServiceCategoriesTemplate')} className='ta-btn ta-btn-secondary'>
                    <FontAwesome5 icon='file-download' type='solid' /> {t('branches.list.import.downloadTemplate.title')}
                  </Link>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>2</div>
                    <p className='ta-branches__import__step-title'>{t('branches.list.import.uploadCSV')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>{t('branches.list.import.uploadCSVFinish')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FilesInput
                    name='files'
                    maxFileSize='20MB'
                    acceptedFileTypes={[
                      'text/csv',
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]}
                    acceptedFileTypesLabel='csv'
                    label={t('global.chooseFileOrDnD')}
                  />
                </Col>
              </Row>
              <SectionTitle label={t('branches.list.import.mergeRule')} icon='code-merge' />
              <Row noOffset>
                <Col>
                  <Radio name='mergeRule' value='OVERWRITE' label={t('branches.list.import.overwriteData')} />
                  <HintText>{t('global.uploadAndReplace')}</HintText>
                </Col>
              </Row>
              <Row noOffset>
                <Col>
                  <Radio name='mergeRule' value='IGNORE' label={t('branches.list.import.uploadNewData')} />
                  <HintText>{t('branches.list.import.uploadNewData.hint')}</HintText>
                </Col>
              </Row>
              <SectionTitle label={t('global.protection')} icon='shield-alt' />
              <Row>
                <Col className='relative'>
                  <Input
                    type={myPasswordFieldType}
                    name='myPassword'
                    hintText={t('global.yourPassword.hint')}
                    mandatory
                  />
                  <div className='ta-password__type-toggle-btn' onClick={toggleMyPasswordFieldType}>
                    <FontAwesome5 icon={myPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
              <Error name='globalErrors' noTopOffset />
              <Error name='file' />
              <Error name='requestLimit' />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button
                disabled={!enableButton}
                type='submit'
                className={btnClassNames.join(' ')}
              >
                <FontAwesome5 type='s' icon='upload' /> {t('global.import')}
              </button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesServiceCategoriesImport
