import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { StripedTitle, SelectTags, SelectTag } from '../../Common'

const SelectTagsWithCategories = (props) => {
  let {
    values,
    options,
    disabled,
    onDelete,
    disabledRemove,
    insufficientTag,
    renderSelectedItem
  } = props
  values = values || []
  options = options || []
  disabledRemove = disabledRemove || []

  const selectedOptions = useMemo(
    () =>
      options
        .map((category) => {
          let { name, items, ...rest } = category
          if (!name) return category
          items = items || []
          items = items.filter((item) => values.includes(item.value))
          return { name, items, ...rest }
        })
        .filter((category) => (values.includes(category.value) || (category.items || []).length > 0)),
    [options, values]
  )

  return selectedOptions.map((option, key) => {
    if (option.value === 'all') {
      const isRemoveDisabled = disabledRemove.includes('all')
      return (
        <SelectTag
          key={key}
          index={key}
          item={option}
          onDelete={onDelete}
          disabled={disabled || isRemoveDisabled}
          renderSelectedItem={renderSelectedItem}
        />
      )
    }

    return (
      <div className='ta-select__values__category' key={key}>
        <StripedTitle label={option.name} extraText={option.extraText} />
        <SelectTags
          values={values}
          disabled={disabled}
          onDelete={onDelete}
          options={option.items}
          disabledRemove={disabledRemove}
          insufficientTag={insufficientTag}
          renderSelectedItem={renderSelectedItem}
        />
      </div>
    )
  })
}

SelectTagsWithCategories.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  renderSelectedItem: PropTypes.func
}

export default SelectTagsWithCategories
