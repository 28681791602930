import React from 'react'
import {
  FormSection,
  SectionTitle,
  DependenciesInput,
  t
} from '../../../Common'

const ServiceFormDependencies = props => {
  return (
    <FormSection>
      <SectionTitle label={t('global.resources')} icon='users' />
      <DependenciesInput name='dependencies' />
    </FormSection>
  )
}

export default ServiceFormDependencies
