import router from '../Router'
import forms from '../Forms'
import app from '../Components/App'
import auth from '../Components/Auth'
import account from '../Components/Account'
import navigation from '../Components/Navigation'
import popup from '../Components/Popup'
import modal from '../Components/Modal'
import overlay from '../Components/Overlay'
import branches from '../Components/Branches'
import tags from '../Components/Tags'
import users from '../Components/Users'
import userGroups from '../Components/UserGroups'
import dashboard from '../Components/Dashboard'
import customerFields from '../Components/CustomerFields'
import services from '../Components/Services'
import courses from '../Components/Courses'
import user from '../Components/User'
import invoices from '../Components/Invoices'
import company from '../Components/Company'
import staticData from '../Components/StaticData'
import apps from '../Components/Apps'
import globalsSettings from '../Components/GlobalsSettings'
import globals from '../Components/Globals'
import customers from '../Components/Customers'
import companyTags from '../Components/CompanyTags'
import resources from '../Components/Resources'
import bookingSettings from '../Components/BookingSettings'
import settings from '../Components/Settings'
import phrase from '../Components/Phrase'
import debugOptions from '../Components/DebugOptions'
import persist from '../Components/Persist'
import twoFA from '../Components/TwoFA'
import chargebee from '../Components/Chargebee'
import billing from '../Components/Billing'
import paymentMethods from '../Components/PaymentMethods'
import userPermissions from '../Components/UserPermissions'
import permissionTypes from '../Components/PermissionTypes'
import callCentre from '../Components/CallCentre'
import notifications from '../Components/Notifications'
import activity from '../Components/Activity'
import attributes from '../Components/Attributes'

// This name will be available in store object
export default {
  router,
  forms,
  app,
  apps,
  auth,
  account,
  branches,
  bookingSettings,
  settings,
  dashboard,
  customerFields,
  services,
  courses,
  invoices,
  modal,
  customers,
  companyTags,
  navigation,
  overlay,
  popup,
  tags,
  users,
  userGroups,
  user,
  company,
  staticData,
  globalsSettings,
  globals,
  resources,
  phrase,
  debugOptions,
  persist,
  twoFA,
  chargebee,
  billing,
  paymentMethods,
  userPermissions,
  permissionTypes,
  callCentre,
  notifications,
  activity,
  attributes
}
