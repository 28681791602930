import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { ResourceCategoryForm } from '../../Beauties'

class ResourceCategoryEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const { id } = this.props
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.resourceCategoryFormGet(id)
  }

  onSubmit (cb) {
    let { form } = this.props
    form = form || {}
    handlers.resourceCategoryFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    let {
      id,
      pending,
      showExternalIds,
      isAdmin,
      branchesAccessBlacklist,
      form
    } = this.props
    id = id || null
    form = form || {}
    pending = !!pending
    showExternalIds = !!showExternalIds
    const { categoriesList = [] } = store.getState().resources
    const selectedCategory = categoriesList.find(category => category.id === id) || {}
    const disableRadioBookable = !!selectedCategory.isDependency
    const disableRadioDependency = !selectedCategory.isDependency

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <ResourceCategoryForm
            title={t('resource.preview.category.edit')}
            type='edit'
            cancelLink='/management/resource-categories'
            onSubmit={this.onSubmit}
            disableRadioBookable={disableRadioBookable}
            disableRadioDependency={disableRadioDependency}
            showExternalIds={showExternalIds}
            isAdmin={isAdmin}
            branchesAccessBlacklist={branchesAccessBlacklist}
            location={form.location && form.location.values}
          />
        )}
      </>
    )
  }
}

ResourceCategoryEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.categoryId,
  pending: state.resources.pendingCategoryForm,
  showExternalIds: (state.company.settings && state.company.settings.showExternalIds),
  isAdmin: state.account.isAdmin,
  form: state.forms.resourceCategory
})

export default connect(maps)(ResourceCategoryEditWrapper)
