export default [{
  id: 'company',
  translationKey: 'global.account',
  icon: 'briefcase',
  iconType: 'solid',
  routes: ['account', 'accountEdit'],
  to: '/account',
  submenu: [
    {
      id: 'accountDetails',
      translationKey: 'global.details',
      routes: ['account', 'accountEdit'],
      to: '/account'
    }, {
      id: 'billing',
      translationKey: 'global.billing',
      routes: ['accountBilling', 'accountBillingEdit'],
      to: '/account/billing'
    },
    {
      id: 'invoices',
      translationKey: 'global.invoices',
      routes: ['accountInvoices'],
      to: '/account/invoices'
    }
  ]
}, {
  id: 'notifications',
  translationKey: 'global.notifications',
  routes: ['notifications'],
  to: '/notifications',
  icon: 'bell',
  iconType: 'regular',
  notifications: 0,
  hideWhenOffline: true
}, {
  id: 'branches',
  translationKey: 'global.branches',
  icon: 'building',
  iconType: 'solid',
  routes: [],
  to: '/branches',
  submenu: [
    {
      id: 'branchesList',
      translationKey: 'global.list',
      routes: [
        'branches',
        'branchesAdd',
        'branchesImport',
        'branchesEdit',
        'branchesPreview',
        'branchesResourceEdit',
        'branchesResourcePasswordChange',
        'branchesServiceEdit',
        'branchesServiceCombinationEdit',
        'branchesServicesImport',
        'branchesServiceCategoriesImport',
        'branchesGroupsImport',
        'branchesGroupCategoriesImport',
        'branchesResourcesImport',
        'branchesResourceCategoriesImport',
        'branchesGroupEdit',
        'branchesCustomerEdit'
      ],
      to: '/branches'
    },
    {
      id: 'branchesTags',
      translationKey: 'global.branchTags',
      routes: ['branchesTags', 'branchesTagsAdd', 'branchesTagsEdit', 'branchesTagsPreview'],
      to: '/branches/tags'
    }
  ]
}, {
  id: 'globalCustomers',
  translationKey: 'navigation.globalCustomers',
  routes: [],
  icon: 'user-friends',
  iconType: 'solid',
  to: '/customers',
  submenu: [
    {
      id: 'customers',
      translationKey: 'global.customers',
      routes: ['customers', 'customerPreview', 'customersEdit', 'customersAdd'],
      to: '/customers'
    },
    {
      id: 'customerFields',
      translationKey: 'customerFields.heading',
      routes: [
        'customerFields',
        'customerFieldsPreview',
        'customerFieldAdd',
        'customerFieldsEdit',
        'customerFieldsCategoriesPreview',
        'customerFieldsCategoryEdit',
        'customerFieldsCategoryAdd',
        'customerFieldsCategoryPreview',
        'customerFieldsCategories',
        'customerFieldCategoryPreview',
        'customerFieldsReorder'
      ],
      to: '/customers/customer-fields'
    },
    {
      id: 'companyTags',
      translationKey: 'global.tags',
      routes: ['companyTags', 'companyTagPreview', 'companyTagAdd', 'companyTagEdit', 'companyTagsReorder'],
      to: '/customers/company-tags'
    }
  ]
}, {
  id: 'global-management',
  translationKey: 'navigation.globalManagement',
  routes: [],
  icon: 'suitcase',
  iconType: 'solid',
  to: '/management',
  submenu: [
    {
      id: 'globalsResources',
      translationKey: 'navigation.resourceCategories',
      routes: ['resourceCategories', 'resourceCategoryAdd', 'resourceCategoryPreview', 'resourceCategoryEdit'],
      to: '/management/resource-categories'
    }, {
      id: 'managementPermissionTypes',
      translationKey: 'global.permissionTypes',
      routes: ['management', 'permissionTypes', 'permissionTypePreview', 'permissionTypeEdit', 'permissionTypeAdd'],
      to: '/management/permission-types'
    }, {
      id: 'services',
      translationKey: 'global.services',
      routes: [
        'services',
        'servicePreview',
        'serviceAdd',
        'serviceEdit',
        'serviceCombinationAdd',
        'serviceCombinationEdit',
        'serviceCategoryPreview',
        'serviceCategoryEdit',
        'servicesReorder',
        'serviceCategoryAdd',
        'servicesCategories',
        'serviceBranchRestore',
        'serviceRestore'
      ],
      to: '/management/services'
    }, {
      id: 'courses',
      translationKey: 'global.groups',
      routes: [
        'courses',
        'coursePreview',
        'courseAdd',
        'courseEdit',
        'courseCategoryPreview',
        'courseCategoryAdd',
        'courseCategoryEdit',
        'coursesReorder'
      ],
      to: '/management/courses'
    }, {
      id: 'attributes',
      translationKey: 'global.attributes',
      routes: ['attributes', 'attributeCategoryAdd', 'attributeCategoryEdit', 'attributeAdd', 'attributeEdit', 'attributePreview', 'attributeCategoryPreview'],
      to: '/management/attributes'
    }
  ]
},
{
  id: 'settings',
  translationKey: 'navigation.globalSettings',
  routes: ['bookingSettings'],
  icon: 'cogs',
  iconType: 'solid',
  to: '/settings',
  submenu: [
    // {
    //   id: 'webAppSettings',
    //   label: 'Web App',
    //   routes: ['webAppSettings', 'webAppSettingsEdit'],
    //   to: '/settings/webApp'
    // },
    {
      id: 'bookingSettings',
      translationKey: 'global.booking',
      routes: ['bookingSettings', 'bookingSettingsEdit'],
      to: '/settings/bookings'
    }, {
      id: 'emailSettings',
      translationKey: 'global.emails',
      routes: ['emailSettings', 'emailSettingsEdit'],
      to: '/settings/email'
    },
    {
      id: 'customersSettings',
      translationKey: 'global.customers',
      routes: ['customersSettings', 'customersSettingsEdit'],
      to: '/settings/customers'
    },
    {
      id: 'securitySettings',
      translationKey: 'global.security',
      routes: ['securitySettings', 'securitySettingsEdit'],
      to: '/settings/security'
    },
    {
      id: 'globalsSettings',
      translationKey: 'global.globals',
      routes: ['globalsSettings', 'globalsSettingsEdit'],
      to: '/settings/globals'
    }
  ]
}, {
  id: 'activity',
  translationKey: 'global.activityLog',
  routes: ['activity'],
  to: '/activity',
  icon: 'history',
  iconType: 'regular',
  notifications: 0,
  hideWhenOffline: true
}, {
  id: 'apps',
  translationKey: 'navigation.globalApps',
  routes: ['apps', 'appsPreview'],
  icon: 'th',
  iconType: 'solid',
  to: '/apps'
}, {
  id: 'managers',
  translationKey: 'navigation.managers',
  routes: ['users', 'usersEdit', 'usersAdd', 'usersPreview'],
  icon: 'user-ninja',
  iconType: 'solid',
  to: '/managers',
  submenu: [
    {
      id: 'users',
      translationKey: 'navigation.users',
      routes: ['users', 'usersEdit', 'usersAdd', 'usersPreview'],
      to: '/managers/users'
    }, {
      id: 'userPermissions',
      translationKey: 'navigation.userPermissions',
      routes: ['userPermissions', 'userPermissionsPreview', 'userPermissionsEdit', 'userPermissionsAdd'],
      to: '/managers/user-permissions'
    }
  ]
}, {
  id: 'loginCallCentre',
  // translationKey: 'navigation.callCentre',
  label: 'Call Centre',
  routes: [],
  icon: 'phone-square',
  iconType: 'solid',
  to: '/login-call-centre'
}]
