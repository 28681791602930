import React from 'react'
import PropTypes from 'prop-types'
import {
  FixedContentHeader,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SectionTitle,
  FixedContent,
  FontAwesome5,
  FormSection,
  BorderedBox,
  HoverPopup,
  ListItem,
  Title,
  Link,
  Blur,
  t,
  format,
  StripedTitle,
  Row,
  Col
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'
import { BookingRemindersExample } from '../../../Beauties'
import './EmailSettings.css'

const SettingsNotifications = props => {
  let {
    resourceEmailRemindersMinutes,
    customerEmailRemindersMinutes,
    allowUserLocaleForCustomers,
    allowUserLocaleForResources,
    resourceBookingCreateEmailRecipients = [],
    resourceBookingUpdateEmailRecipients = [],
    resourceBookingDeleteEmailRecipients = [],
    customerBookingCreateEmailRecipients = [],
    customerBookingUpdateEmailRecipients = [],
    customerBookingDeleteEmailRecipients = [],
    resourceRemindersEmailRecipients = [],
    rawSettings,
    settingsRead,
    settingsWrite,
    dailyAgenda,
    categoriesList,
    customisations
  } = props
  dailyAgenda = dailyAgenda || {}
  let {
    time,
    specificResourceCategoryIds
  } = dailyAgenda
  specificResourceCategoryIds = specificResourceCategoryIds || []
  const { hideNotificationsDailyAgenda } = customisations?.settings || {}
  const isDailyAgendaActivated = dailyAgenda.time !== null && dailyAgenda.specificResourceCategoryIds !== null
  // confirmation
  const bookingConfirmationHasCustomers = customerBookingCreateEmailRecipients !== null && customerBookingCreateEmailRecipients.length > 0
  const bookingConfirmationHasStaff = resourceBookingCreateEmailRecipients !== null && resourceBookingCreateEmailRecipients.length > 0
  const bookingConfirmationHasOwner = resourceBookingCreateEmailRecipients !== null && resourceBookingCreateEmailRecipients.includes('owner')
  const bookingConfirmationHasResource = resourceBookingCreateEmailRecipients !== null && resourceBookingCreateEmailRecipients.includes('resource')
  const bookingConfirmationOthers = resourceBookingCreateEmailRecipients && resourceBookingCreateEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
  // cancellation
  const bookingCancellationHasCustomers = customerBookingDeleteEmailRecipients !== null && customerBookingDeleteEmailRecipients.length > 0
  const bookingCancellationHasStaff = resourceBookingDeleteEmailRecipients !== null && resourceBookingDeleteEmailRecipients.length > 0
  const bookingCancellationHasOwner = resourceBookingDeleteEmailRecipients !== null && resourceBookingDeleteEmailRecipients.includes('owner')
  const bookingCancellationHasResource = resourceBookingDeleteEmailRecipients !== null && resourceBookingDeleteEmailRecipients.includes('resource')
  const bookingCancellationOthers = resourceBookingDeleteEmailRecipients !== null && resourceBookingDeleteEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
  // change
  const bookingChangeHasCustomers = customerBookingUpdateEmailRecipients !== null && customerBookingUpdateEmailRecipients.length > 0
  const bookingChangeHasStaff = resourceBookingUpdateEmailRecipients !== null && resourceBookingUpdateEmailRecipients.length > 0
  const bookingChangeHasOwner = resourceBookingUpdateEmailRecipients !== null && resourceBookingUpdateEmailRecipients.includes('owner')
  const bookingChangeHasResource = resourceBookingUpdateEmailRecipients !== null && resourceBookingUpdateEmailRecipients.includes('resource')
  const bookingChangeOthers = resourceBookingUpdateEmailRecipients !== null && resourceBookingUpdateEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
  // reminders
  const bookingRemindersHasCustomers = customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0
  const bookingRemindersHasStaff = resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length > 0
  const bookingRemindersHasOwner = resourceRemindersEmailRecipients !== null && resourceRemindersEmailRecipients.includes('owner')
  const bookingRemindersHasResource = resourceRemindersEmailRecipients !== null && resourceRemindersEmailRecipients.includes('resource')
  const bookingRemindersOthers = (resourceRemindersEmailRecipients && resourceRemindersEmailRecipients.length && resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')) || []
  const dailyAgendaResourcesGroupTranslationKey = specificResourceCategoryIds?.length ? 'global.resourceCategory.plural' : 'settings.section.dailyAgenda.preview.allResources'
  const selectedCategories = categoriesList
    .filter(item => specificResourceCategoryIds.includes(item.id))
    .map(item => ({ id: item.id, name: item.name })).filter(Boolean)

  return (
    <div className='ta-emails-settings'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-emails-settings__title'>
            <Title label={t('global.emailSettings')}>
              <PopupTriggerWrapper name='email-settings-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 icon='lightbulb' />
                </Link>
              </PopupTriggerWrapper>
              {(settingsWrite &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('global.settings.edit')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link to='/settings/email/edit' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                      <FontAwesome5 icon='pencil' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={settingsRead ? 'inactive' : ''} active={!settingsRead} icon='ban' label={t('global.accessDenied')}>
            <FormSection>
              <SectionTitle label={t('settings.section.confirmation.heading')} icon='calendar-check' />
              <ListItem>
                {t('settings.emails.confirmation.infoText')}
                {(rawSettings.resourceBookingCreateEmailRecipients === null &&
                  rawSettings.customerBookingCreateEmailRecipients === null &&
                    <>
                      <br />
                      <strong>{t('global.defaultValues')}</strong>
                    </>
                )}
                <br />
                {(!bookingConfirmationHasCustomers && !bookingConfirmationHasStaff &&
                  <strong><li>{t('global.nobody')}</li></strong>
                )}
                {(bookingConfirmationHasCustomers &&
                  <strong><li>{t('servicesGroups.reminder.emails.customersAndParticipants')}</li></strong>
                )}
                {bookingConfirmationHasStaff && <strong><li>{t('global.staff')}</li></strong>}
                {((bookingConfirmationHasOwner || (bookingConfirmationOthers && bookingConfirmationOthers.length > 0) || bookingConfirmationHasResource) &&
                  <BorderedBox>
                    {bookingConfirmationHasOwner && <strong><li>{t('account.list.item.title.owner')}</li></strong>}
                    {bookingConfirmationHasResource && <strong><li>{t('settings.emails.resources')}</li></strong>}
                    {(bookingConfirmationOthers.length > 0 &&
                      <>
                        <strong><li>{t('settings.emails.others')}</li></strong>
                        {bookingConfirmationOthers.map(item => <li className='ta-list-item__participant' key={item}>{item}</li>)}
                      </>
                    )}
                  </BorderedBox>
                )}
              </ListItem>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('settings.emails.cancellation.heading')} icon='calendar-times' />
              <ListItem>
                {t('settings.emails.cancellation.infoText')}
                {(rawSettings.resourceBookingDeleteEmailRecipients === null &&
                  rawSettings.customerBookingDeleteEmailRecipients === null &&
                    <>
                      <br />
                      <strong>{t('global.defaultValues')}</strong>
                    </>
                )}
                <br />
                {(!bookingCancellationHasCustomers && !bookingCancellationHasStaff &&
                  <strong><li>{t('global.nobody')}</li></strong>
                )}
                {(bookingCancellationHasCustomers &&
                  <strong><li>{t('servicesGroups.reminder.emails.customersAndParticipants')}</li></strong>
                )}
                {bookingCancellationHasStaff && <strong><li>{t('global.staff')}</li></strong>}
                {((bookingCancellationHasOwner || (bookingCancellationOthers && bookingCancellationOthers.length > 0) || bookingCancellationHasResource) &&
                  <BorderedBox>
                    {bookingCancellationHasOwner && <strong><li>{t('account.list.item.title.owner')}</li></strong>}
                    {bookingCancellationHasResource && <strong><li>{t('settings.emails.resources')}</li></strong>}
                    {(bookingCancellationOthers.length > 0 &&
                      <>
                        <strong><li>{t('settings.emails.others')}</li></strong>
                        {bookingCancellationOthers.map(item => <li className='ta-list-item__participant' key={item}>{item}</li>)}
                      </>
                    )}
                  </BorderedBox>
                )}
              </ListItem>
              <SectionTitle label={t('settings.emails.change.heading')} icon='calendar-edit' />
              <ListItem>
                {t('settings.emails.change.infoText')}
                {(rawSettings.resourceBookingUpdateEmailRecipients === null &&
                  rawSettings.customerBookingUpdateEmailRecipients === null &&
                    <>
                      <br />
                      <strong>{t('global.defaultValues')}</strong>
                    </>
                )}
                <br />
                {(!bookingChangeHasCustomers && !bookingChangeHasStaff &&
                  <strong><li>{t('global.nobody')}</li></strong>
                )}
                {(bookingChangeHasCustomers &&
                  <strong><li>{t('servicesGroups.reminder.emails.customersAndParticipants')}</li></strong>
                )}
                {bookingChangeHasStaff && <strong><li>{t('global.staff')}</li></strong>}
                {((bookingChangeHasOwner || (bookingChangeOthers && bookingChangeOthers.length > 0) || bookingChangeHasResource) &&
                  <BorderedBox>
                    {bookingChangeHasOwner && <strong><li>{t('account.list.item.title.owner')}</li></strong>}
                    {bookingChangeHasResource && <strong><li>{t('settings.emails.resources')}</li></strong>}
                    {(bookingChangeOthers.length > 0 &&
                      <>
                        <strong><li>{t('settings.emails.others')}</li></strong>
                        {bookingChangeOthers.map(item => <li className='ta-list-item__participant' key={item}>{item}</li>)}
                      </>
                    )}
                  </BorderedBox>
                )}
              </ListItem>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('settings.section.reminder.heading')} icon='bell' />
              <ListItem>
                {t('settings.section.reminder.infoText')}
                {(rawSettings.resourceEmailRemindersMinutes === null &&
                  rawSettings.resourceRemindersEmailRecipients === null &&
                  rawSettings.customerEmailRemindersMinutes === null &&
                    <>
                      <br />
                      <strong>{t('global.defaultValues')}</strong>
                    </>
                )}
                <strong><li>{t('servicesGroups.reminder.emails.customersAndParticipants')}</li></strong>
                <BorderedBox>
                  {!bookingRemindersHasCustomers && <strong>{t('global.disabled')}</strong>}
                  {bookingRemindersHasCustomers && <BookingRemindersExample values={customerEmailRemindersMinutes} />}
                </BorderedBox>
                <strong><li>{t('global.staff')}</li></strong>
                <BorderedBox>
                  {!bookingRemindersHasStaff && <strong>{t('global.disabled')}</strong>}
                  {(bookingRemindersHasStaff &&
                    <>
                      {bookingRemindersHasOwner && <strong><li>{t('account.list.item.title.owner')}</li></strong>}
                      {bookingRemindersHasResource && <strong><li>{t('settings.emails.resources')}</li></strong>}
                      {(bookingRemindersOthers.length > 0 &&
                        <>
                          <strong><li>{t('settings.emails.others')}</li></strong>
                          {bookingRemindersOthers.map(item => <li className='ta-list-item__participant' key={item}>{item}</li>)}
                        </>
                      )}
                      <BookingRemindersExample values={resourceEmailRemindersMinutes} />
                    </>
                  )}
                </BorderedBox>
              </ListItem>
            </FormSection>
            {!hideNotificationsDailyAgenda && (
              <FormSection>
                <SectionTitle label={t('settings.section.daily.agenda.heading')} icon='calendar-day' />
                <ListItem>
                  {t('settings.section.dailyAgenda.infoText')}
                  {(!isDailyAgendaActivated &&
                    <strong><li>{t('global.nobody')}</li></strong>
                  )}
                  {(isDailyAgendaActivated &&
                    <>
                      <Row><Col /></Row>
                      {(!selectedCategories?.length
                        ? <strong><li>{t(dailyAgendaResourcesGroupTranslationKey)}</li></strong>
                        : <StripedTitle className='ta-settings__notifications__daily-agenda__resource-group-item__striped-title' label={t(dailyAgendaResourcesGroupTranslationKey)} />
                      )}
                      {(selectedCategories && selectedCategories.length > 0 &&
                        selectedCategories.map(category => (
                          <div className='ta-settings__notifications__daily-agenda__resource-group-item' key={category.id}>
                            {category.name === 'default' ? t('resources.list.sectionDefault.title') : category.name}
                          </div>
                        ))
                      )}
                      <BorderedBox topOffset>
                        <>
                          <strong><li>{`${t('settings.section.dailyAgenda.deliveryAt', [{ key: 'TIME', value: format(time, 'time', { isUTC: true, format: 'HH:mm' }) }])}`}</li></strong>
                        </>
                      </BorderedBox>
                    </>
                  )}

                </ListItem>
              </FormSection>)}
            <FormSection className='ta-settings__notifications__last-section'>
              <SectionTitle label={t('settings.emails.language')} icon='envelope' />
              <ListItem>
                {(rawSettings.allowUserLocaleForCustomers === null &&
                  rawSettings.allowUserLocaleForResources === null &&
                    <>
                      <strong>{t('global.defaultValues')}</strong>
                      <br />
                    </>
                )}
                <strong><li>{t('global.customers')}</li></strong>
                {(allowUserLocaleForCustomers
                  ? t('settings.section.notifications.eMailNotificationsLanguage.customers.enabled.infoText')
                  : t('settings.section.notifications.eMailNotificationsLanguage.customers.disabled.infoText')
                )}
                <strong><li>{t('global.resources')}</li></strong>
                {(allowUserLocaleForResources
                  ? t('settings.section.notifications.eMailNotificationsLanguage.resources.enabled.infoText')
                  : t('settings.section.notifications.eMailNotificationsLanguage.customers.disabled.infoText')
                )}
              </ListItem>
            </FormSection>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

SettingsNotifications.propTypes = {
  id: PropTypes.string
}

export default SettingsNotifications
