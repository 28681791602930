import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../../../Settings'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../../Settings/settings'
import { validator, translateServerCode } from '../../../Utils'

export const accountPopulateTransform = account => {
  if (!account) return
  let { accounts } = account
  accounts = accounts || []
  const settings = account.settings || {}
  const pattern = /^((http|https|ftp):\/\/)/
  const prefix = (account.enterprise && account.enterprise.avatarPrefix) || ''
  const avatarUrl = (
    settings.avatarUrl && pattern.test(settings.avatarUrl)
      ? settings.avatarUrl
      : (settings.avatarUrl && settings.avatarUrl !== '')
          ? `${prefix}/${settings.avatarUrl}`
          : account.avatarUrl || ''
  )
  const result = {
    ...account,
    ...settings,
    avatarUrl,
    accounts: accounts.map(item => {
      const formattedAccount = {
        ...item,
        ...item.enterprise
      }
      delete formattedAccount.enterprise
      return formattedAccount
    })
  }
  delete result.settings

  return result
}

export const accountFormTransform = account => {
  if (!account) return
  let { enterprise } = account || {}
  enterprise = enterprise || {}
  const address = enterprise.address || {}
  let locale = enterprise.locale || DEFAULT_LOCALE
  const mappedLocales = {}
  const availableLocales = AVAILABLE_LANGUAGES.map(language => language.locale)
  SUPPORTED_LOCALES.forEach(locale => {
    mappedLocales[locale] = availableLocales.find(availableLocale => locale.includes(availableLocale.split('-')[0])) || 'en-gb'
  })
  locale = mappedLocales[locale] || DEFAULT_LOCALE
  const result = {
    companyName: {
      value: enterprise.name || ''
    },
    name: {
      value: account.name || ''
    },
    slug: {
      value: enterprise.slug || ''
    },
    language: {
      value: locale || DEFAULT_LOCALE,
      options: AVAILABLE_LANGUAGES.map(language => ({
        label: language.name,
        value: language.locale
      }))
    },
    phone: (account && enterprise && enterprise.phone && enterprise.phone.number) && {
      phone: enterprise.phone.number || '',
      phoneCountry: enterprise.phone.country || 'DE'
    },
    address: {
      value: address.formatted || '',
      data: ((address.formatted && address.formatted !== '') && {
        placeId: address.placeId || '',
        city: address.city || '',
        streetName: address.street || '',
        streetNumber: address.streetNumber || '',
        postalCode: address.zipCode || '',
        country: address.country || '',
        lat: address.latitude || '',
        lng: address.longitude || '',
        formattedAddress: address.formatted || '',
        details: address.details || ''
      }) || null
    },
    addressSecondary: {
      value: address.details || ''
    },
    oldPassword: {
      value: '',
      type: 'password'
    },
    newPassword: {
      value: '',
      type: 'password'
    }
  }
  return result
}

export const accountFormValidate = account => {
  if (!account) return
  const { address } = account || {}
  const { value: addressValue } = address || {}
  const isAddressSet = !!addressValue

  const rules = [
    { 'companyName.value': ['required', 'max:255'] },
    { 'phone.phone': ['required', 'max:16'] },
    { 'slug.value': ['max:255'] },
    { 'language.value': ['required', 'max:64'] },
    { 'address.value': ['max:600'] },
    // { 'address.data.postalCode': [`requiredIf:${isAddressSet}`] },
    // { 'address.data.streetNumber': [`requiredIf:${isAddressSet}`] }
    { 'address.data.city': [`requiredIf:${isAddressSet}`] },
    { 'address.data.streetName': [`requiredIf:${isAddressSet}`] }
  ]

  let addressErrorMessage = 'errors.address.required'

  if (isAddressSet && address && address.data) {
    if (!address.data.city || address.data.city === '') {
      addressErrorMessage = 'errors.address.fillCity'
    } else if (!address.data.streetNumber || address.data.streetNumber === '') {
      addressErrorMessage = 'errors.address.fillStreetNumber'
    }
    // else if (!address.data.postalCode || address.data.postalCode === '') {
    //   addressErrorMessage = 'errors.address.fillZipCode'
    // } else if (!address.data.streetName || address.data.streetName === '') {
    //   addressErrorMessage = 'errors.address.fillStreetName'
    // }
  }

  const messages = {
    companyName: {
      max: 'errors.invalidMaxLength',
      required: 'errors.required'
    },
    phone: {
      max: 'errors.invalidMaxLength',
      required: 'errors.phone.invalid'
    },
    slug: {
      max: 'errors.invalidMaxLength'
    },
    language: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    address: {
      max: 'errors.invalidMinLength',
      requiredIf: `${addressErrorMessage}`
    }
  }
  const replaces = {
    companyName: {
      max: { key: 'MAX', value: '255' },
      required: { key: 'FIELD_NAME', value: 'Company name' }
    },
    slug: {
      max: { key: 'MAX', value: '255' }
    },
    phone: {
      max: { key: 'MAX', value: '16' }
    },
    language: {
      max: { key: 'MAX', value: '64' },
      required: { key: 'FIELD_NAME', value: 'global.language.label', translateReplace: true }
    },
    address: {
      max: { key: 'MAX', value: 600 }
    }
  }
  const errors = validator(account, rules, messages, replaces)

  return errors
}

export const accountFormSaveTransform = account => {
  const { data } = account.address

  return {
    name: account.companyName.value || '',
    slug: account.slug.value,
    // locale: account.language.value || '',
    phone: (account.phone && account.phone.phoneCountry && account.phone.phone) && {
      country: (account.phone && account.phone.phoneCountry) || '',
      number: (account.phone && account.phone.phone) || ''
    },
    address: (data && {
      placeId: data && data.placeId,
      latitude: data && data.lat,
      longitude: data && data.lng,
      street: data && data.streetName,
      streetNumber: data && data.streetNumber,
      city: data && data.city,
      country: data && data.country,
      zipCode: data && data.postalCode,
      formatted: data && data.formattedAddress,
      details: data && account.addressSecondary.value
    }) || null
  }
}

export const accountFormServerErrorsTransform = (error) => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

export const accountDeleteFormValidate = form => {
  if (!form) return
  const errors = []

  if (form.password && form.password.value.length === 0) errors.push({ key: 'password', value: 'errors.password.required' })

  return errors
}

export const accountDeleteFormSaveTransform = form => {
  const comment = form.comment && form.comment.value
  const hasNotifyMe = (form.hasNotifyMe && form.hasNotifyMe.value) || false
  const result = {
    password: form.password && form.password.value,
    reason: form.reason && form.reason.value
  }
  if (comment) result.comment = comment
  if (comment && hasNotifyMe) result.hasNotifyMe = hasNotifyMe
  return result
}

export const accountDeleteFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'password', value: translateServerCode(err.code) })
  }
  return errors
}

// Password change

export const accountPasswordChangeFormValidate = (account, enforcePasswordMode) => {
  if (!account) return
  let { oldPassword, newPassword } = account || {}
  oldPassword = oldPassword || {}
  newPassword = newPassword || {}
  const { value: oldPasswordValue } = oldPassword || {}
  const { value: newPasswordValue } = newPassword || {}

  const passwordMinLength = PASSWORD_MIN_LENGTH
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [{
    'oldPassword.value': ['required', `max:${passwordMaxLength}`, `min:${passwordMinLength}`]
  }]
  const messages = {
    oldPassword: {
      required: 'errors.password.required',
      max: 'errors.invalidMaxLength',
      min: 'errors.password.weak'
    },
    newPassword: {
      required: 'errors.password.required',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    oldPassword: {
      max: { key: 'MAX', value: passwordMaxLength },
      min: { key: 'MIN', value: passwordMinLength }
    },
    newPassword: {
      max: { key: 'MAX', value: passwordMaxLength },
      min: { key: 'MIN', value: passwordMinLength }
    }
  }
  if (!enforcePasswordMode) {
    // rules for if not enforce password form
    rules.push({
      'newPassword.value': ['required', `min:${passwordMinLength}`, `max:${passwordMaxLength}`]
    })
    messages.oldPassword.required = 'errors.password.old.required'
    messages.oldPassword.min = 'errors.invalidMinLength'
    messages.newPassword.min = 'errors.password.weak'
  }
  const errors = validator(account, rules, messages, replaces)

  if (enforcePasswordMode &&
    oldPasswordValue !== newPasswordValue &&
    oldPasswordValue.length >= passwordMinLength
  ) {
    errors.push({ key: 'globalErrors', value: 'errors.password.notMatching' })
  }
  if (!new RegExp(/[!@#$%^&*(),.?":{}|<>\-_]/).test(newPassword.value)) {
    errors.push({ key: 'newPassword', value: 'errors.password.specialCharacter.required' })
  }

  return errors
}

export const accountSaveUserDataTransform = account => {
  const { oldPassword, newPassword } = account || {}
  const { value: oldPasswordValue } = oldPassword || {}
  const { value: newPasswordValue } = newPassword || {}
  return {
    oldPassword: oldPasswordValue,
    password: newPasswordValue
  }
}

export const accountPasswordChangeFormServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'oldPassword', value: translateServerCode(err.code) })
  }
  if (err.code === 'PasswordWeak') {
    errors.push({ key: 'newPassword', value: translateServerCode(err.code) })
  }
  return errors
}

export const accountPasswordChangeSaveTransform = account => {
  const { oldPassword, newPassword } = account || {}
  return { oldPassword, newPassword }
}

// Email change

export const accountUserEmailChangeFormValidate = account => {
  if (!account) return

  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [
    { 'oldPassword.value': ['required', `max:${passwordMaxLength}`] },
    { 'email.value': ['required', 'email'] },
    { 'reEmail.value': ['required', 'email'] }
  ]
  const messages = {
    oldPassword: {
      required: 'errors.password.old.required',
      max: 'errors.invalidMaxLength'
    },
    email: {
      required: 'errors.email.required',
      email: 'errors.email.invalid'
    },
    reEmail: {
      required: 'errors.email.required',
      email: 'errors.email.invalid'
    }
  }
  const replaces = {
    oldPassword: {
      max: { key: 'MAX', value: passwordMaxLength }
    }
  }
  const errors = validator(account, rules, messages, replaces)
  const { email, reEmail } = account || {}
  const { value: emailValue } = email || {}
  const { value: reEmailValue } = reEmail || {}
  if (!errors.length && emailValue !== reEmailValue) errors.push({ key: 'reEmail', value: 'errors.emails.dontMatch' })

  return errors
}

export const accountEmailChangeSaveTransform = account => {
  const { email, oldPassword, reEmail } = account || {}
  return { email, oldPassword, reEmail }
}

export const accountUserEmailChangeTransform = account => {
  const { email, oldPassword } = account || {}
  const { value: emailValue } = email || {}
  const { value: oldPasswordValue } = oldPassword || {}
  return {
    email: emailValue,
    password: oldPasswordValue
  }
}

export const accountUserEmailChangeFormServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'oldPassword', value: translateServerCode(err.code) })
  }
  if (err.code === 'EmailChangeInProcess') {
    errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  if (err.code === 'SameEmail') {
    errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  if (err.code === 'EmailExists') {
    errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  if (errors.length === 0) {
    errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }

  return errors
}

// Email change cancel

export const accountEmailChangeCancelFormValidate = account => {
  if (!account) return

  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [
    { 'oldPassword.value': ['required', `max:${passwordMaxLength}`] }
  ]
  const messages = {
    oldPassword: {
      required: 'errors.password.old.required',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    oldPassword: {
      max: { key: 'MAX', value: passwordMaxLength }
    }
  }
  const errors = validator(account, rules, messages, replaces)

  return errors
}

export const accountUserEmailChangeCancelTransform = account => {
  const { oldPassword } = account || {}
  const { value: oldPasswordValue } = oldPassword || {}
  return { password: oldPasswordValue }
}

export const accountEmailChangeCancelSaveServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'oldPassword', value: translateServerCode(err.code) })
  }

  return errors
}

export const accountEmailChangeCancelSaveTransform = account => {
  const { oldPassword } = account || {}
  return { oldPassword }
}
