import React from 'react'

import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Blur,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  FontAwesome5,
  Title,
  t
} from '../../Common'

import './CompanyTags.css'

const CompanyTagsMock = props => {
  const listItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <div className='ta-tags'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-company-tags__title'>
            <Title label={t('global.tags')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur
            active
            label={t('global.accessDenied')}
            icon='ban'
          >
            <div className='ta-tags-mock-container'>
              <div className='ta-tags__list'>
                {listItems.map((item, index) => (
                  <div className='ta-tags__tag' key={index}>
                    <div>
                      <SimpleListItem
                        status='green'
                        draggable={false}
                      >
                        <SimpleListItemTitle title={`Tag ${index}`} />
                        <SimpleListItemContent>
                          <ul className='ta-list-inline'>
                            <li><FontAwesome5 icon='user-tag' type='solid' /> {t('customerTags.used.text', [{ key: 'AMOUNT', value: 12 || 0 }])}</li>
                          </ul>
                        </SimpleListItemContent>
                      </SimpleListItem>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CompanyTagsMock
