import { payloads$, actions, handlers, languageChange, store$, store } from '../../../Store'
import { q } from '../../API'
import { fromEvent } from 'rxjs'
import { startWith, debounceTime, map, distinctUntilChanged } from 'rxjs/operators'
import moment from 'moment'
import { downloadBase64, translateServerCode } from '../../../Utils'
import { CHANNEL_ID } from '../../../Settings'
import { t } from '../../../Common'
// Favicon
const favicon = document.getElementById('favicon')
let faviconUrl = null
if (['development', 'staging'].includes(process.env.REACT_APP_ENV)) faviconUrl = '/favicon-stg.png'
if (favicon && faviconUrl) {
  favicon.href = faviconUrl
}

fromEvent(window, 'resize').pipe(
  startWith(null),
  debounceTime(100)
).subscribe(() => handlers.windowResize(window))

payloads$(actions.LANGUAGE_CHANGE).subscribe(locale => {
  languageChange(locale, () => handlers.languageLoaded(locale))
  moment.locale(locale)
  // moment.locale('en-us')
})

let isLoaded = false
store$.pipe(
  map(state => state.app?.isReady),
  distinctUntilChanged()
).subscribe(async (isReady) => {
  const state = store.getState()
  let customisations = state.app?.customisations
  if (!isLoaded && CHANNEL_ID) {
    const result = (await q('getChannelCustomisation', { type: 'ENTERPRISE' }))
    const { error } = result || { error: {} }
    if (!error) customisations = result?.payload
  }
  handlers.appReady(customisations)
  if (customisations) handlers.accountCustomisationsApply()
  if (isLoaded) return
  handlers.phraseLoad()
  // Get static data on first load
  const {
    getAllBusinessSectors,
    getAllTimezones,
    getAllLocales,
    getAllCountries,
    getStaticData
  } = (await q('getStaticData')) || {}
  const { publicHolidaysData } = getStaticData || {}
  handlers.staticDataPopulate({
    businessSectors: (getAllBusinessSectors || []).map(({ code }) => code),
    timezones: getAllTimezones,
    locales: getAllLocales,
    countries: getAllCountries,
    publicHolidays: publicHolidaysData
  })

  // Refetch account on first load after refresh
  if (state.auth.tokens) {
    isLoaded = true
    const account = await q('getAccount')
    handlers.accountChanged(account)
  }
})

payloads$(actions.APP_FILE_DOWNLOAD)
  .subscribe(async ({ url }) => {
    try {
      handlers.navigateToPath('/', true)
      const data = await q('downloadEnterpriseFile', { url })
      const { error } = data || {}
      if (!data || error) {
        const { code } = error || {}
        handlers.appToastrShow({
          title: t('files.fileNotFound.title'),
          message: t(translateServerCode(code))
        })
        return
      }

      setTimeout(() => {
        downloadBase64(data)
      }, 2000)
    } catch (err) {
      console.warn({ err })
    }
  })
