import { store, selectors } from '../../Store'

export const isRouteDisabledByCustomisations = () => {
  const state = store.getState()
  const { router } = state || {}
  const route = router.name
  const customisations = selectors.customisationsSelector(state)
  const {
    hideMenuGlobalCustomers,
    hideMenuGlobalCustomersCustomers,
    hideMenuGlobalCustomersCustomerFields,
    hideMenuGlobalCustomersTags,
    hideBranchEditResourceBtn,
    hideBranchEditServiceBtn,
    hideBranchEditGroupServiceBtn,
    hideBranchAddBtn,
    hideBranchImportBtn,
    hideMenuNotifications,
    hideMenuActivity
  } = customisations?.settings || {}
  const ROUTES_GLOBAL_CUSTOMERS = ['globalCustomers']
  const ROUTES_CUSTOMERS = ['customers', 'customerPreview', 'customersEdit', 'customersAdd']
  const ROUTES_CUSTOMER_FIELDS = ['customerFields', 'customerFieldsPreview', 'customerFieldAdd', 'customerFieldsEdit', 'customerFieldsCategoriesPreview', 'customerFieldsCategoryEdit', 'customerFieldsCategoryAdd', 'customerFieldsCategoryPreview', 'customerFieldsCategories', 'customerFieldCategoryPreview', 'customerFieldsReorder']
  const ROUTES_TAGS = ['companyTags', 'companyTagPreview', 'companyTagAdd', 'companyTagEdit', 'companyTagsReorder']
  const ROUTES_BRANCHES_RESOURCES_EDIT = ['branchesResourceEdit']
  const ROUTES_BRANCHES_SERVICES_EDIT = ['branchesServiceEdit']
  const ROUTES_BRANCHES_GROUP_SERVICES_EDIT = ['branchesGroupEdit']
  const ROUTES_BRANCHES_ADD = ['branchesAdd']
  const ROUTES_BRANCHES_IMPORT = ['branchesImport']
  const ROUTES_ACTIVITY = ['activity']
  const ROUTES_NOTIFICATIONS = ['notifications']

  if (
    (ROUTES_GLOBAL_CUSTOMERS.includes(route) && hideMenuGlobalCustomers) ||
    (ROUTES_CUSTOMERS.includes(route) && (hideMenuGlobalCustomers || hideMenuGlobalCustomersCustomers)) ||
    (ROUTES_CUSTOMER_FIELDS.includes(route) && (hideMenuGlobalCustomers || hideMenuGlobalCustomersCustomerFields)) ||
    (ROUTES_TAGS.includes(route) && (hideMenuGlobalCustomers || hideMenuGlobalCustomersTags)) ||
    (ROUTES_BRANCHES_RESOURCES_EDIT.includes(route) && hideBranchEditResourceBtn) ||
    (ROUTES_BRANCHES_SERVICES_EDIT.includes(route) && hideBranchEditServiceBtn) ||
    (ROUTES_BRANCHES_GROUP_SERVICES_EDIT.includes(route) && hideBranchEditGroupServiceBtn) ||
    (ROUTES_BRANCHES_ADD.includes(route) && hideBranchAddBtn) ||
    (ROUTES_ACTIVITY.includes(route) && hideMenuActivity) ||
    (ROUTES_NOTIFICATIONS.includes(route) && hideMenuNotifications) ||
    (ROUTES_BRANCHES_IMPORT.includes(route) && hideBranchImportBtn)
  ) return true
  return false
}
