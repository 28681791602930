import React from 'react'
import PropTypes from 'prop-types'
import {
  IconAvatar,
  SectionTitle,
  FormSection,
  t
} from '../../../Common'
import { PermissionTypeSummaryPermissions } from '../../../Beauties'

const PermissionTypeSummary = props => {
  const { detail, categoriesList } = props
  const { name, color, icon, id } = detail || {}
  const isAdminPermission = id === 'ADMIN'

  return (
    <div className='ta-permission-types__summary'>
      <div className='ta-permission-types__summary__header'>
        <IconAvatar
          color={color}
          icon={icon}
          className='ta-permission-types__summary__header__icon'
        />
        <div className='ta-permission-types__summary__header__content'>
          <div className='ta-permission-types__summary__header__content__name'>
            { name }
          </div>
        </div>
      </div>
      <FormSection>
        <SectionTitle
          label={t('permissionTypes.detail.tabSummary.section.permissions.heading')}
          icon='unlock-alt'
          ignoreTopOffset
        />
        <PermissionTypeSummaryPermissions
          isAdminPermission={isAdminPermission}
          permissions={detail.permissions}
          categoriesList={categoriesList}
          isPermissionTypes
        />
      </FormSection>
    </div>
  )
}

PermissionTypeSummary.propTypes = {
  detail: PropTypes.object.isRequired
}

export default PermissionTypeSummary
