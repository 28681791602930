import {
  Alert,
  Col,
  Error,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  Form,
  FormButtons,
  FormSection,
  IconSelect,
  Input,
  Link,
  MultipleSelect,
  Row,
  SectionTitle,
  SingleSelect,
  Title,
  t
} from '../../../Common'

import './AttributeCategoryForm.css'

const AttributeCategoryForm = ({
  title,
  isAdmin,
  onSubmit,
  isEditing,
  cancelLink,
  showExternalIds,
  branchesAccessBlacklist
}) => {
  const hasAll = isAdmin || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)
  const categoryTypeTranslations = {
    resource: t('global.resources'),
    service: t('global.services'),
    course: t('global.groups')
  }
  const attributeTypeOptions = Object.entries(categoryTypeTranslations).map(([value, label]) => ({ value, label }))

  return (
    <div className='ta-attributes__category-form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-services__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='attributeCategory'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <SectionTitle label={t('global.details')} icon='sliders-h' />
              <Row>
                <Col className='ta-attributes__category-form__icon-select'>
                  <IconSelect
                    name='icon'
                  />
                  <Input
                    name='name'
                    className='ta-attributes__category-form__name-input'
                    label={t('global.name.label')}
                    hintText={t('attributes.category.form.name.input.hint')}
                    limit={50}
                    mandatory
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SingleSelect
                    name='attributeType'
                    label={t('attributes.category.form.type.selector.label')}
                    hintText={t('attributes.category.form.type.selector.hint')}
                    options={attributeTypeOptions}
                    mandatory
                    disabled
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('global.branches')} icon='building' />
              <Alert theme='alert' label={t('attributes.category.form.branches.alert.title')}>
                {t('attributes.category.form.branches.alert.text')}
              </Alert>
              <MultipleSelect
                searchable
                mandatory
                name='location'
                hasAll={hasAll}
                label={t('global.select')}
                allLabel={t('global.allLocations')}
                disableSelected={isEditing}
              />
            </FormSection>
            <Row>
              <Col>
                <Error name='attributeCategoryGlobalError' />
              </Col>
            </Row>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary ta-attributes__category-form__submit-btn'>
                <FontAwesome5 icon='save' />
                {t('buttons.saveChanges.label')}
              </button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default AttributeCategoryForm
