import { globalActions, store } from '../../../Store'
import {
  DEFAULT_SERVICE_HEXCOLOR,
  DEFAULT_COURSE_HEXCOLOR,
  DEFAULT_LOCALE_COUNTRY_CODE,
  EMAIL_VALIDATION_REGEX
} from '../../../Settings'
import {
  validator,
  translateServerCode,
  normalizePrice,
  getFirstDayOfWeek,
  sortByOrderIndex,
  extractFromHtml,
  removeMultipleSpaces,
  getOS
} from '../../../Utils'
import moment from 'moment-timezone'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../../Settings/settings'

const days = [...Array(7).keys()].map(key => ({
  isActive: false,
  times: []
}))
const servicesDefaults = {
  bookingWeeklyAllowancePlan: [...days],
  workingWeeklyAllowancePlan: [...days],
  category: {}
}

const courseDefaults = {
  bookingWeeklyAllowancePlan: [...days],
  workingWeeklyAllowancePlan: [...days],
  category: {}
}
// LIST
export const listTransform = (items = [], tags) => {
  if (!items) return

  return items.length > 0 && items.map(item => {
    const selectedTags = []
    item.tagIds && item.tagIds.forEach(tagId => {
      if (tags && tags.length > 0) {
        const findTag = tags.find(tag => tag.id === tagId) || null
        if (findTag) selectedTags.push(findTag)
      }
    })
    return ({
      ...item,
      tags: selectedTags
    })
  })
}

// ONE
export const transformStatistics = statistics => {
  if (!statistics) return
  const eventsByHours = (statistics.eventsByHours && JSON.parse(statistics.eventsByHours).data.map(item => item.value)) || []
  const eventsByDayOfWeek = (statistics.eventsByDayOfWeek && JSON.parse(statistics.eventsByDayOfWeek).data.map(item => item.value)) || []
  return {
    ...statistics,
    lastSevenDays: statistics.lastSevenDays && JSON.parse(statistics.lastSevenDays),
    nextSevenDays: statistics.nextSevenDays && JSON.parse(statistics.nextSevenDays),
    revenueResource: statistics.revenueResource && JSON.parse(statistics.revenueResource),
    revenueService: statistics.revenueService && JSON.parse(statistics.revenueService),
    bookingsAverage: statistics.bookingsAverage && JSON.parse(statistics.bookingsAverage),
    totalResourcesWorkingTimesWorking: statistics.totalResourcesWorkingTimes || {},
    totalResourcesWorkingTimesBooking: statistics.totalResourcesWorkingTimes || {},
    eventsByHours,
    eventsByDayOfWeek
  }
}

export const branchesStatisticsFilterFormPopulate = labels => {
  const {
    lifetime: lifetimeLabel,
    month: monthLabel,
    until: untilLabel
  } = labels
  const yearsOptions = []
  const now = moment()
  const currentYear = now.year()
  const currentMonth = now.month() + 1
  const currentDay = now.format('YYYY-MM-DD')
  const prevYear = currentYear - 1
  const previousMonth = currentMonth - 1 || 12
  const prevDay = moment().subtract('1', 'day').format('DD MMM')
  const months = moment.months().map((item, i) => ({ label: item, value: now.month(i).format('MM'), i: i + 1 }))
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
  const isFirstDayOfMonth = currentDay === firstDayOfCurrentMonth
  const lastDayOfLastMonth = moment().subtract('1', 'day').format('DD MMM')
  let monthsOptions = months.filter(item => item.i <= (isFirstDayOfMonth ? previousMonth : currentMonth)).map((item, i) => item.i === (isFirstDayOfMonth ? previousMonth : currentMonth)
    ? {
        label: `${item.label} (${untilLabel} ${isFirstDayOfMonth ? lastDayOfLastMonth : prevDay})`,
        value: item.value,
        i: item.i
      }
    : item
  )
  // start of calculation of statistics November, 2020. Disable all months + years before that
  if (currentYear === 2020 || (currentYear === 2021 && isFirstDayOfMonth && currentMonth === 1)) monthsOptions = monthsOptions.filter((item, i) => i > 9)
  for (let year = (isFirstDayOfMonth && currentMonth === 1) ? prevYear : currentYear; year >= 2020; year--) yearsOptions.push({ label: year.toString(), value: year.toString() })

  return {
    type: {
      value: 'lifetime',
      options: [
        { label: lifetimeLabel, value: 'lifetime' },
        { label: monthLabel, value: 'months' }
      ]
    },
    year: {
      value: yearsOptions[0].value,
      options: yearsOptions
    },
    month: {
      value: monthsOptions.find(item => item.i === (isFirstDayOfMonth ? previousMonth : currentMonth)).value,
      options: monthsOptions
    }
  }
}

export const branchesStatisticsMostBookedResourcesFilterFormPopulate = ({ allKey, dependenciesKey, bookableKey }) => {
  return {
    type: {
      value: 'topBookingResources',
      options: [
        { label: allKey, value: 'topBookingResources' },
        { label: bookableKey, value: 'topBookingBookableResources' },
        { label: dependenciesKey, value: 'topBookingDependencyResources' }
      ]
    }
  }
}

export const branchMarketingFormPopulate = ({
  campaign,
  content,
  source,
  medium,
  utmTerm
}) => {
  return {
    type: {
      value: 'utmCampaign',
      options: [
        { label: campaign, value: 'utmCampaign' },
        { label: content, value: 'utmContent' },
        { label: source, value: 'utmSource' },
        { label: medium, value: 'utmMedium' },
        { label: utmTerm, value: 'utmTerm' }
      ]
    }
  }
}

export const branchPeakSignUpTimesFilterFormPopulate = () => {
  return {
    type: {
      value: false
    }
  }
}

export const getStatisticsFiltersFromState = () => {
  const state = store.getState()
  const branchFiltersForm = ((state.forms && state.forms.branchStatisticsFilter) && state.forms.branchStatisticsFilter) || {}

  return branchFiltersForm
}

export const formatBranchStatisticsFilter = (statsMode, getPrevMonth, companyId, region) => {
  const now = moment()
  const currentYear = now.format('YYYY')
  const currentMonth = now.format('MM')
  const currentDay = now.format('YYYY-MM-DD')
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
  const isFirstDayOfMonth = currentDay === firstDayOfCurrentMonth
  const { year, month } = getStatisticsFiltersFromState()
  const { value: branchFiltersYear } = year || {}
  const { value: branchFiltersMonth, options: monthOptions } = month || {}
  const currentYearAndMonth = currentYear === branchFiltersYear && currentMonth === branchFiltersMonth
  const previousMonthIndex = (monthOptions.find(item => item.value === branchFiltersMonth) || {}).i
  const previousMonth = moment().month(previousMonthIndex - 2).format('MM')
  const differenceInFilteredMonths = parseFloat(currentMonth) - parseFloat(branchFiltersMonth)
  if (isFirstDayOfMonth) {
    return (differenceInFilteredMonths > 1 || differenceInFilteredMonths < 0)
      ? {
          companyId,
          region,
          filter: {
            year: (previousMonth === '12' && getPrevMonth) || (previousMonth === '12' && isFirstDayOfMonth) ? `${branchFiltersYear - 1}` : branchFiltersYear,
            month: getPrevMonth ? previousMonth : branchFiltersMonth,
            statisticsMode: 'MONTHLY'
          }
        }
      : {
          companyId,
          region,
          filter: {
            year: (previousMonth === '12' && getPrevMonth) || (previousMonth === '12' && isFirstDayOfMonth) ? `${branchFiltersYear - 1}` : branchFiltersYear,
            month: branchFiltersMonth,
            statisticsMode: getPrevMonth ? 'MONTHLY' : statsMode
          }
        }
  }

  return currentYearAndMonth
    ? { companyId, region, filter: { statisticsMode: statsMode } }
    : {
        companyId,
        region,
        filter: {
          year: (previousMonth === '12' && getPrevMonth) || (previousMonth === '12' && isFirstDayOfMonth) ? `${branchFiltersYear - 1}` : branchFiltersYear,
          month: getPrevMonth ? previousMonth : branchFiltersMonth,
          statisticsMode: 'MONTHLY'
        }
      }
}

// FORM
export const formTransform = data => {
  data = data || {}
  const {
    id,
    region,
    name,
    ownerEmail,
    phone,
    locale,
    timezone,
    tagIds,
    plan,
    externalId,
    info,
    enterpriseRegion
  } = data || {}

  const form = {
    name: {
      value: name || ''
    },
    email: {
      value: ownerEmail || ''
    },
    phone: {
      phone: (phone && phone.number) || '',
      phoneCountry: (phone && phone.country) || 'DE'
    },
    locale: {
      value: locale || ''
    },
    timezone: {
      value: timezone || ''
    },
    tags: {
      values: tagIds || []
    },
    plan: {
      value: plan || ''
    },
    externalId: {
      value: externalId || ''
    },
    info: {
      value: info || ''
    },
    customRegion: {
      value: enterpriseRegion || region || ''
    },
    id: id || null,
    region: region || 'EUROPE'
  }
  return form
}

export const branchesResourceFormTransform = resource => {
  const firstDayOfWeek = getFirstDayOfWeek()
  const firstIndex = firstDayOfWeek === 1 ? 7 : 0
  const days = [...Array(7).keys()].map(key => ({
    isActive: false,
    times: []
  }))
  const resourcesDefaults = {
    workingWeeklyAllowancePlan: [...days],
    bookingWeeklyAllowancePlan: [...days],
    category: {}
  }
  if (!resource) resource = resourcesDefaults
  const { workingWeeklyAllowancePlan = [], bookingWeeklyAllowancePlan = [] } = resource.calendar || {}
  if (!workingWeeklyAllowancePlan) resource.workingWeeklyAllowancePlan = days
  if (!bookingWeeklyAllowancePlan) resource.bookingWeeklyAllowancePlan = days
  if (!resource.category) resource.category = resourcesDefaults.category
  resource.hasDifferentWeeklyAllowancePlans = JSON.stringify(workingWeeklyAllowancePlan) !== JSON.stringify(bookingWeeklyAllowancePlan)
  const transformedResource = {
    id: {
      value: resource.id || ''
    },
    abbreviation: {
      value: resource.abbreviation || ''
    },
    companyId: resource.companyId,
    name: {
      value: resource.name || ''
    },
    color: {
      value: resource.color || DEFAULT_SERVICE_HEXCOLOR
    },
    email: {
      value: resource.email || '',
      disabled: !!resource.account
    },
    hasInvitation: {
      value: false
    },
    isBookable: {
      value: resource.isBookable === undefined ? true : resource.isBookable
    },
    // permissions: {
    //   value: resource.account
    //     ? resource.account.isAdmin
    //       ? 'ADMIN'
    //       : 'CUSTOM'
    //     : undefined
    // },
    hasDifferentWeeklyAllowancePlans: {
      value: !!resource.hasDifferentWeeklyAllowancePlans
    },
    avatar: {
      avatarUrl: {
        value: resource.avatarUrl
      }
    },
    category: {
      value: resource.category.id,
      options: resource.categories.map(item => ({
        label: item.name === 'default' ? resource.defaultCategoryName : item.name,
        value: item.id
      }))
    },
    categoryName: {
      value: resource.category.name === 'default' ? resource.defaultCategoryName : resource.category.name
    },
    hasDifferentTimezone: {
      value: resource.plan === 'ENTERPRISE' && resource.timezone && resource.timezone !== resource.companyTimezone
    },
    timezone: {
      value: resource.timezone
    },
    companyTimezone: {
      value: resource.companyTimezone
    },
    externalId: {
      value: resource.plan === 'ENTERPRISE' ? resource.externalId || '' : ''
    },
    hasAccount: {
      value: !!resource.account
    },
    isDependency: resource.category.isDependency,
    userId: resource.userId,
    isOwner: resource.isOwner || false
    // isPermissionUpdateAllowedByIssuer: typeof resource.isPermissionUpdateAllowedByIssuer === 'boolean'
    //   ? resource.isPermissionUpdateAllowedByIssuer
    //   : true
  }
  // let { account } = resource || {}
  // account = account || {}
  // const { companyPermissions, isAdmin } = account || {}
  // const permissions = companyPermissions || {}
  // const myselfId = resource.myself && resource.myself.id
  // const rules = branchesResourceFormPermissionsTransform({
  //   permissions,
  //   id: myselfId,
  //   email: resource.email,
  //   isAdmin,
  //   isPermissionUpdateAllowedByIssuer: transformedResource.isPermissionUpdateAllowedByIssuer,
  //   userEmail: resource.userEmail,
  //   plan: resource.plan
  // })
  // Object.assign(transformedResource, { ...rules })

  // Flatten booking and working weekly allowance plan
  bookingWeeklyAllowancePlan.forEach((item, itemIndex) => {
    transformedResource[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
      isActive: !!item.isActive,
      values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
    }
  })
  workingWeeklyAllowancePlan.forEach((item, itemIndex) => {
    transformedResource[`workingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
      isActive: !!item.isActive,
      values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
    }
  })

  transformedResource.isConfirmed = {
    value: resource.isConfirmed
  }

  return transformedResource
}

export const branchesResourcePasswordChangeFormTransform = () => {
  return {
    newPassword: {
      value: '',
      type: 'password'
    },
    repeatPassword: {
      value: '',
      type: 'password'
    },
    yourPassword: {
      value: '',
      type: 'password'
    },
    generatedPassword: {
      value: ''
    }
  }
}

export const branchesResourcePasswordChangeFormValidate = (form) => {
  if (!form) return

  const passwordMinLength = PASSWORD_MIN_LENGTH
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [{
    'yourPassword.value': ['required', `max:${passwordMaxLength}`]
  }, {
    'newPassword.value': ['required', `min:${passwordMinLength}`, `max:${passwordMaxLength}`]
  }, {
    'repeatPassword.value': ['required', `min:${passwordMinLength}`, `max:${passwordMaxLength}`]
  }]
  const messages = {
    yourPassword: {
      required: 'errors.password.old.required',
      max: 'errors.invalidMaxLength'
    },
    newPassword: {
      required: 'errors.password.required',
      min: 'errors.invalidMinLength',
      max: 'errors.invalidMaxLength'
    },
    repeatPassword: {
      required: 'errors.password.required',
      min: 'errors.invalidMinLength',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    yourPassword: {
      max: { key: 'MAX', value: passwordMaxLength }
    },
    newPassword: {
      min: { key: 'MIN', value: passwordMinLength },
      max: { key: 'MAX', value: passwordMaxLength }
    },
    repeatPassword: {
      min: { key: 'MIN', value: passwordMinLength },
      max: { key: 'MAX', value: passwordMaxLength }
    }
  }
  const errors = validator(form, rules, messages, replaces)
  const { newPassword, repeatPassword } = form || {}
  const { value: newPasswordValue } = newPassword || {}
  const { value: repeatPasswordValue } = repeatPassword || {}
  if (!errors.length && newPasswordValue !== repeatPasswordValue) errors.push({ key: 'reEmail', value: 'errors.password.notMatching' })

  return errors.length && errors
}

export const branchesResourcePasswordChangeSaveDataTransform = (form) => {
  const { newPassword, yourPassword } = form || {}
  const { value: newPasswordValue } = newPassword || {}
  const { value: yourPasswordValue } = yourPassword || {}
  return { userPassword: newPasswordValue, enterprisePassword: yourPasswordValue }
}

export const branchesResourcePasswordChangeFormServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'yourPassword', value: translateServerCode(err.code) })
  } else if (err.code === 'PasswordWeak') {
    errors.push({ key: 'newPassword', value: translateServerCode(err.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}

export const branchesResourceFormPermissionsTransform = ({
  permissions,
  id,
  email,
  isAdmin,
  isPermissionUpdateAllowedByIssuer,
  userEmail,
  plan
}) => {
  permissions = permissions || {}
  const arePermissionChangeDisabled = plan !== 'ENTERPRISE' || !isPermissionUpdateAllowedByIssuer || email === userEmail

  const defaultCustomPermissions = {
    // Selected resources
    selectedResources: id ? [id] : [],
    // Account
    accountRead: 'NO',
    // // Billing
    // billingRead: 'NO',
    // billingWrite: 'NO',
    // billingDelete: 'NO',
    // // Invoices
    // invoicesRead: 'NO',
    // Calendar
    calendarRead: 'YES',
    calendarWrite: 'YES',
    calendarDelete: 'YES',
    // ShiftPlan
    shiftPlanRead: 'YES',
    shiftPlanWrite: 'NO',
    shiftPlanDelete: 'NO',
    // Statistics
    statisticsRead: 'NO',
    // // Notifications
    notificationsRead: 'YES',
    // Customers
    customersRead: 'YES',
    customersWrite: 'YES',
    customersDelete: 'YES',
    // Resources
    resourcesRead: 'YES',
    resourcesWrite: 'NO',
    resourcesDelete: 'NO',
    // Services
    servicesRead: 'YES',
    servicesWrite: 'NO',
    servicesDelete: 'NO',
    // // Courses
    // coursesRead: 'YES',
    // // Booking setup
    // bookingSetupRead: 'NO',
    // bookingSetupWrite: 'NO',
    // // Offers
    // offersRead: 'NO',
    // offersWrite: 'NO',
    // offersDelete: 'NO',
    // Apps
    appsRead: 'NO',
    appsWrite: 'NO',
    appsDelete: 'NO'
  }
  const rules = {
    // Account
    permissionsAccountRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.accountRead !== undefined
        ? permissions.accountRead ? 'YES' : 'NO'
        : defaultCustomPermissions.accountRead
    },
    permissionsAccountWrite: {
      value: 'NO'
    },
    permissionsAccountDelete: {
      value: 'NO'
    },
    // // Billing
    // permissionsBillingRead: {
    //   value: permissions.readBilling !== undefined ? permissions.readBilling : defaultCustomPermissions.readBilling
    // },
    // permissionsBillingWrite: {
    //   value: permissions.editBilling !== undefined ? permissions.writeBilling : defaultCustomPermissions.writeBilling
    // },
    // permissionsBillingDelete: {
    //   value: permissions.deleteBilling !== undefined ? permissions.deleteBilling : defaultCustomPermissions.deleteBilling
    // },
    // // Invoices
    // permissionsInvoicesRead: {
    //   value: permissions.readInvoices !== undefined ? permissions.readInvoices : defaultCustomPermissions.readInvoices
    // },
    // Calendar
    permissionsCalendarRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.calendarReadSet !== undefined
        ? permissions.calendarReadSet ? 'YES' : 'NO'
        : defaultCustomPermissions.calendarRead
    },
    permissionsCalendarReadOptions: {
      disabled: arePermissionChangeDisabled,
      value: (permissions.calendarReadSet && (
        (permissions.calendarReadSet.specificResourceCategoryIds && permissions.calendarReadSet.specificResourceCategoryIds.length === 0) ||
        (permissions.calendarReadSet.specificResourceIds && permissions.calendarReadSet.specificResourceIds.length === 0)
      )) || !Object.keys(permissions).length
        ? 'all'
        : 'selected'
    },
    permissionsCalendarReadCategories: {
      disabled: arePermissionChangeDisabled,
      values: (permissions.calendarReadSet && permissions.calendarReadSet.specificResourceCategoryIds) || []
    },
    permissionsCalendarReadItems: {
      disabled: arePermissionChangeDisabled,
      values: (permissions.calendarReadSet && permissions.calendarReadSet.specificResourceIds) || []
    },
    permissionsCalendarWrite: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.calendarWrite !== undefined
        ? permissions.calendarWrite ? 'YES' : 'NO'
        : defaultCustomPermissions.calendarWrite
    },
    permissionsCalendarDelete: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.calendarDelete !== undefined
        ? permissions.calendarDelete ? 'YES' : 'NO'
        : defaultCustomPermissions.calendarDelete
    },
    // ShiftPlan
    permissionsShiftPlanRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.shiftRead !== undefined
        ? permissions.shiftRead ? 'YES' : 'NO'
        : defaultCustomPermissions.shiftPlanRead
    },
    permissionsShiftPlanWrite: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.shiftWrite !== undefined
        ? permissions.shiftWrite ? 'YES' : 'NO'
        : defaultCustomPermissions.shiftWrite
    },
    permissionsShiftPlanDelete: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.shiftDelete !== undefined
        ? permissions.shiftDelete ? 'YES' : 'NO'
        : defaultCustomPermissions.shiftDelete
    },
    // Statistics
    permissionsStatisticsRead: {
      disabled: arePermissionChangeDisabled,
      value: permissions.statisticsRead !== undefined && permissions.statisticsRead !== null
        ? permissions.statisticsRead ? 'YES' : 'NO'
        : defaultCustomPermissions.statisticsRead
    },
    // Notifications
    permissionsNotificationsRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.notificationsRead !== undefined
        ? permissions.notificationsRead ? 'YES' : 'NO'
        : defaultCustomPermissions.notificationsRead
    },
    // Customers
    permissionsCustomersRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.customersReadSet !== undefined
        ? permissions.customersReadSet ? 'YES' : 'NO'
        : defaultCustomPermissions.customersRead
    },
    permissionsCustomersReadOptions: {
      disabled: arePermissionChangeDisabled,
      value: (permissions.customersReadSet && (
        (permissions.customersReadSet.specificResourceCategoryIds && permissions.customersReadSet.specificResourceCategoryIds.length === 0) ||
        (permissions.customersReadSet.specificResourceIds && permissions.customersReadSet.specificResourceIds.length === 0)
      )) || !Object.keys(permissions).length
        ? 'all'
        : 'selected'
    },
    permissionsCustomersReadCategories: {
      disabled: arePermissionChangeDisabled,
      values: (permissions.customersReadSet && permissions.customersReadSet.specificResourceCategoryIds) || []
    },
    permissionsCustomersReadItems: {
      disabled: arePermissionChangeDisabled,
      values: (permissions.customersReadSet && permissions.customersReadSet.specificResourceIds) || []
    },
    permissionsCustomersWrite: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.customersWrite !== undefined
        ? permissions.customersWrite ? 'YES' : 'NO'
        : defaultCustomPermissions.customersWrite
    },
    permissionsCustomersDelete: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.customersDelete !== undefined
        ? permissions.customersDelete ? 'YES' : 'NO'
        : defaultCustomPermissions.customersDelete
    },
    // Resources
    permissionsResourcesRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.resourcesReadSet !== undefined
        ? permissions.resourcesReadSet ? 'YES' : 'NO'
        : defaultCustomPermissions.resourcesRead
    },
    permissionsResourcesReadOptions: {
      disabled: arePermissionChangeDisabled,
      value:
        (permissions.resourcesReadSet && (
          (permissions.resourcesReadSet.specificResourceCategoryIds && permissions.resourcesReadSet.specificResourceCategoryIds.length === 0) ||
          (permissions.resourcesReadSet.specificResourceIds && permissions.resourcesReadSet.specificResourceIds.length === 0)
        )) || !Object.keys(permissions).length
          ? 'all'
          : 'selected'
    },
    permissionsResourcesReadCategories: {
      disabled: arePermissionChangeDisabled,
      values: (permissions.resourcesReadSet && permissions.resourcesReadSet.specificResourceCategoryIds) || []
    },
    permissionsResourcesReadItems: {
      disabled: arePermissionChangeDisabled,
      values: (permissions.resourcesReadSet && permissions.resourcesReadSet.specificResourceIds) || []
    },
    permissionsResourcesWrite: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.resourcesWrite !== undefined
        ? permissions.resourcesWrite ? 'YES' : 'NO'
        : defaultCustomPermissions.resourcesWrite
    },
    permissionsResourcesDelete: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.resourcesDelete !== undefined
        ? permissions.resourcesDelete ? 'YES' : 'NO'
        : defaultCustomPermissions.resourcesDelete
    },
    // Services
    permissionsServicesRead: {
      disabled: arePermissionChangeDisabled,
      value: permissions.servicesRead !== undefined
        ? permissions.servicesRead ? 'YES' : 'NO'
        : defaultCustomPermissions.servicesRead
    },
    permissionsServicesWrite: {
      disabled: arePermissionChangeDisabled,
      value: permissions.servicesWrite !== undefined
        ? permissions.servicesWrite ? 'YES' : 'NO'
        : defaultCustomPermissions.servicesWrite
    },
    permissionsServicesDelete: {
      disabled: arePermissionChangeDisabled,
      value: permissions.servicesDelete !== undefined
        ? permissions.servicesDelete ? 'YES' : 'NO'
        : defaultCustomPermissions.servicesDelete
    },
    // // Courses
    // permissionsCoursesRead: {
    //   value: 'YES'
    // },
    // permissionsCoursesWrite: {
    //   value: 'NO'
    // },
    // permissionsCoursesDelete: {
    //   value: 'NO'
    // },
    // // Booking setup
    // permissionsBookingSetupRead: {
    //   value: permissions.readBookingSetup !== undefined ? permissions.readBookingSetup : defaultCustomPermissions.readBookingSetup
    // },
    // permissionsBookingSetupWrite: {
    //   value: permissions.writeBookingSetup !== undefined ? permissions.writeBookingSetup : defaultCustomPermissions.writeBookingSetup
    // },
    // // Offers
    // permissionsOffersRead: {
    //   value: permissions.readOffers !== undefined ? permissions.readCourses : defaultCustomPermissions.readOffers
    // },
    // permissionsOffersWrite: {
    //   value: permissions.writeOffers !== undefined ? permissions.writeOffers : defaultCustomPermissions.writeOffers
    // },
    // permissionsOffersDelete: {
    //   value: permissions.deleteOffers !== undefined ? permissions.deleteOffers : defaultCustomPermissions.deleteOffers
    // },
    // Apps
    permissionsAppsRead: {
      disabled: arePermissionChangeDisabled,
      value: !isAdmin && permissions.appsRead !== undefined
        ? permissions.appsRead ? 'YES' : 'NO'
        : defaultCustomPermissions.appsRead
    },
    permissionsAppsWrite: {
      value: 'NO'
    },
    permissionsAppsDelete: {
      value: 'NO'
    },
    // Permission Types
    permissionsGroupsRead: {
      value: 'YES'
    }
  }

  rules.serialisedEmail = email
  return rules
}

export const branchesResourceDeleteFormTransform = () => {
  return {
    name: {
      value: ''
    }
  }
}

export const branchesResourceSaveTransform = resource => {
  // const { userEmail } = resource || {}
  const days = [...Array(7).keys()].map(() => ({}))
  const calendar = {
    timezone: resource.hasDifferentTimezone.value ? resource.timezone.value : resource.companyTimezone.value,
    workingWeeklyAllowancePlan: [...days],
    bookingWeeklyAllowancePlan: [...days]
  }

  // Working and booking weekly allowance plans
  Object
    .keys(resource)
    .filter(key => key.indexOf('workingWeeklyAllowancePlan') > -1)
    .map(key => ({
      key,
      index: parseInt(key.replace('workingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
      isActive: resource[key].isActive,
      intervals: resource[key].values
    }))
    .forEach(item => {
      const index = (item.index === 0 || item.index === 7) ? 0 : item.index
      calendar.workingWeeklyAllowancePlan[index] = {
        isActive: !!item.isActive,
        times: item.intervals
          ? [...item.intervals.map(interval => ({ begin: interval.from, end: interval.until }))]
          : []
      }
      calendar.bookingWeeklyAllowancePlan[index] = {
        isActive: !!item.isActive,
        times: item.intervals
          ? [...item.intervals.map(interval => ({ begin: interval.from, end: interval.until }))]
          : []
      }
    })

  // Override booking weekly allowance plan if different
  if (resource.hasDifferentWeeklyAllowancePlans.value) {
    Object
      .keys(resource)
      .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
      .map(key => ({
        index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
        isActive: resource[key].isActive,
        intervals: resource[key].values
      }))
      .forEach(item => {
        const index = (item.index === 0 || item.index === 7) ? 0 : item.index
        calendar.bookingWeeklyAllowancePlan[index] = {
          isActive: !!item.isActive,
          times: item.isActive ? [...item.intervals.map(interval => ({ begin: interval.from, end: interval.until }))] : []
        }
      })
  }
  // const permissions = {}
  // if (resource.permissions.value === 'CUSTOM' && resource.isPermissionUpdateAllowedByIssuer) {
  //   // Account
  //   permissions.accountRead = false
  //   if (resource.permissionsAccountRead.value === 'YES') {
  //     permissions.accountRead = true
  //   }
  //   // Calendar
  //   permissions.calendarReadSet = null
  //   permissions.calendarWrite = false
  //   permissions.calendarDelete = false
  //   if (resource.permissionsCalendarRead.value === 'YES') {
  //     permissions.calendarReadSet = {
  //       specificResourceCategoryIds: resource.permissionsCalendarReadOptions.value === 'all'
  //         ? []
  //         : resource.permissionsCalendarReadCategories.values.length > 0
  //           ? resource.permissionsCalendarReadCategories.values
  //           : null,
  //       specificResourceIds: resource.permissionsCalendarReadOptions.value === 'all'
  //         ? []
  //         : resource.permissionsCalendarReadItems.values.length > 0
  //           ? resource.permissionsCalendarReadItems.values
  //           : null
  //     }
  //     if (resource.permissionsCalendarWrite.value === 'YES') {
  //       permissions.calendarWrite = true
  //       if (resource.permissionsCalendarDelete.value === 'YES') {
  //         permissions.calendarDelete = true
  //       }
  //     }
  //   }
  //   // ShiftPlan
  //   permissions.shiftRead = false
  //   permissions.shiftWrite = false
  //   permissions.shiftDelete = false
  //   if (resource.permissionsShiftPlanRead.value === 'YES') {
  //     permissions.shiftRead = true
  //     if (resource.permissionsShiftPlanWrite.value === 'YES') {
  //       permissions.shiftWrite = true
  //       if (resource.permissionsShiftPlanDelete.value === 'YES') {
  //         permissions.shiftDelete = true
  //       }
  //     }
  //   }
  //   // Statistics
  //   permissions.statisticsRead = false
  //   if (resource.permissionsStatisticsRead.value === 'YES') {
  //     permissions.statisticsRead = true
  //   }
  //   // Notifications
  //   permissions.notificationsRead = false
  //   if (resource.permissionsNotificationsRead.value === 'YES') {
  //     permissions.notificationsRead = true
  //   }
  //   // Customers
  //   permissions.customersReadSet = null
  //   permissions.customersWrite = false
  //   permissions.customersDelete = false
  //   if (resource.permissionsCustomersRead.value === 'YES') {
  //     permissions.customersReadSet = {
  //       specificResourceCategoryIds: resource.permissionsCustomersReadOptions.value === 'all'
  //         ? []
  //         : resource.permissionsCustomersReadCategories.values.length > 0
  //           ? resource.permissionsCustomersReadCategories.values
  //           : null,
  //       specificResourceIds: resource.permissionsCustomersReadOptions.value === 'all'
  //         ? []
  //         : resource.permissionsCustomersReadItems.values.length > 0
  //           ? resource.permissionsCustomersReadItems.values
  //           : null
  //     }
  //     if (resource.permissionsCustomersWrite.value === 'YES') {
  //       permissions.customersWrite = true
  //       if (resource.permissionsCustomersDelete.value === 'YES') {
  //         permissions.customersDelete = true
  //       }
  //     }
  //   }
  //   // Resources
  //   permissions.resourcesReadSet = null
  //   permissions.resourcesWrite = false
  //   permissions.resourcesDelete = false
  //   if (resource.permissionsResourcesRead.value === 'YES') {
  //     permissions.resourcesReadSet = {
  //       specificResourceCategoryIds: resource.permissionsResourcesReadOptions.value === 'all'
  //         ? []
  //         : resource.permissionsResourcesReadCategories.values.length > 0
  //           ? resource.permissionsResourcesReadCategories.values
  //           : null,
  //       specificResourceIds: resource.permissionsResourcesReadOptions.value === 'all'
  //         ? []
  //         : resource.permissionsResourcesReadItems.values.length > 0
  //           ? resource.permissionsResourcesReadItems.values
  //           : null
  //     }
  //     if (resource.permissionsResourcesWrite.value === 'YES') {
  //       permissions.resourcesWrite = true
  //       if (resource.permissionsResourcesDelete.value === 'YES') {
  //         permissions.resourcesDelete = true
  //       }
  //     }
  //   }
  //   // // Booking settings
  //   // if (resource.permissionsBookingSetupRead) permissions.readBookingSetup = resource.permissionsBookingSetupRead.value
  //   // if (resource.permissionsBookingSetupEdit) permissions.editBookingSetup = resource.permissionsBookingSetupEdit.value
  //   // // Offers
  //   // if (resource.permissionsOffersRead) permissions.readOffers = resource.permissionsOffersRead.value
  //   // if (resource.permissionsOffersAdd) permissions.addOffers = resource.permissionsOffersAdd.value
  //   // if (resource.permissionsOffersEdit) permissions.editOffers = resource.permissionsOffersEdit.value
  //   // if (resource.permissionsOffersDelete) permissions.deleteOffers = resource.permissionsOffersDelete.value
  //   // Apps
  //   permissions.appsRead = false
  //   if (resource.permissionsAppsRead.value === 'YES') {
  //     permissions.appsRead = true
  //   }
  //   // Permission Types
  //   permissions.permissionsGroupsRead = false
  //   if (resource.permissionsGroupsRead.value) {
  //     permissions.permissionsGroupsRead = true
  //   }
  // }

  // const isAdmin = ((resource.email.value || resource.userId) && resource.permissions.value === 'ADMIN') || false
  const avatarUrl = (resource.avatar && resource.avatar.avatarUrl) && resource.avatar.avatarUrl.value
  const email = resource.email && resource.email.value
  const result = {
    id: resource.id && resource.id.value,
    companyId: resource.companyId,
    name: resource.name.value,
    abbreviation: resource.abbreviation.value || null,
    avatarFile: resource.avatar && resource.avatar.avatarFile,
    categoryId: resource.category.value,
    isBookable: resource.isBookable.value,
    email,
    // isAdmin,
    color: resource.color && resource.color.value,
    // permissions: !isAdmin ? permissions : null,
    calendar
  }
  if (resource.plan === 'ENTERPRISE') result.externalId = resource.externalId.value || null
  if (email) result.skipInvitation = !resource.hasInvitation.value
  if (!avatarUrl) result.avatarUrl = null
  // if (!resource.isPermissionUpdateAllowedByIssuer || userEmail === email || (!resource.hasInvitation.value && !resource.hasAccount.value)) {
  //   delete result.permissions
  // }

  return result
}

export const branchesResourceFormValidate = resource => {
  if (!resource) return

  const abbreviationMaxLength = 4

  const rules = [
    { 'name.value': ['required'] },
    { 'email.value': [`requiredIf:${!!resource.hasInvitation.value}`, 'email'] },
    { 'abbreviation.value': [`max:${abbreviationMaxLength}`] }
  ]

  const messages = {
    name: {
      required: 'errors.required'
    },
    email: {
      requiredIf: 'errors.email.required',
      email: 'errors.email.invalid'
    },
    abbreviation: {
      max: 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      }
    },
    abbreviation: {
      max: { key: 'MAX', value: abbreviationMaxLength }
    }
  }

  const errors = validator(resource, rules, messages, replaces)

  // ADVANCED VALIDATION

  // bookingWeeklyAllowancePlan interval validation
  Object
    .keys(resource)
    .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
    .map(key => ({
      index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
      isActive: resource[key].isActive,
      intervals: resource[key].values
    }))
    .forEach(item => {
      if (!item.isActive) return
      item.intervals && item.intervals.forEach((interval, intervalIndex) => {
        // Missing from and until
        if (item.intervals.length > 1 && !interval.from && !interval.until) {
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.bookingTime.interval.required',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Missing only from
        if (!interval.from && interval.until) {
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.bookingTime.interval.missingIntervalFromValue',
            index: intervalIndex,
            fields: ['from']
          })
        }
        // Missing only until
        if (interval.from && !interval.until) {
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.bookingTime.interval.missingIntervalUntilValue',
            index: intervalIndex,
            fields: ['until']
          })
        }
        // The same from and until
        if (interval.from && interval.until && interval.from === interval.until) {
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.bookingTime.interval.fromUntilNotDifferent',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Interval end on next day
        if (interval.from > interval.until) {
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.bookingTime.interval.mustEndSameDay',
            replace: [
              { key: 'FROM', value: interval.from },
              { key: 'UNTIL', value: interval.until }
            ],
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
      })
    })

  // workingWeeklyAllowancePlan interval validation
  Object
    .keys(resource)
    .filter(key => key.indexOf('workingWeeklyAllowancePlan') > -1)
    .map(key => ({
      index: parseInt(key.replace('workingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
      isActive: resource[key].isActive,
      intervals: resource[key].values || []
    }))
    .forEach(item => {
      if (!item.isActive) return
      item.intervals && item.intervals.forEach((interval, intervalIndex) => {
        // Missing from and until and only 1 interval
        if (item.isActive && item.intervals.length === 1 && !interval.from && !interval.until) {
          errors.push({
            key: `workingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.workingTime.interval.required',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Missing from and until and more than 1 intervals
        if (item.isActive && item.intervals.length > 1 && !interval.from && !interval.until) {
          errors.push({
            key: `workingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.workingTime.interval.missingFromUntilMoreThanOne',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Missing only from
        if (!interval.from && interval.until) {
          errors.push({
            key: `workingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.workingTime.interval.missingIntervalFromValue',
            index: intervalIndex,
            fields: ['from']
          })
        }
        // Missing only until
        if (interval.from && !interval.until) {
          errors.push({
            key: `workingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.workingTime.interval.missingIntervalUntilValue',
            index: intervalIndex,
            fields: ['until']
          })
        }
        // The same from and until
        if (interval.from && interval.until && interval.from === interval.until) {
          errors.push({
            key: `workingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.workingTime.interval.fromUntilNotDifferent',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Interval end on next day
        if (interval.from > interval.until) {
          errors.push({
            key: `workingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.resources.workingTime.interval.mustEndSameDay',
            replace: [
              { key: 'FROM', value: interval.from },
              { key: 'UNTIL', value: interval.until }
            ],
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
      })
    })

  return errors
}

export const branchesResourceFormServerErrorsTransform = (error) => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code === 'InvalidAbbreviation') {
    errors.push({ key: 'abbreviation', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

export const branchesServiceTransform = service => {
  if (!service) return
  const { error } = service || {}
  if (error) return
  const result = {
    ...service,
    isBatch: (service.durationsPattern || []).length > 1
  }
  if (service.calendar) result.hasSpecificBookingDays = true
  return result
}

export const branchesServiceSaveTransform = (service, timezone) => {
  const dependencies = globalActions.serviceDependenciesSaveTransform(service)
  const hasDescription = service.description && extractFromHtml(service.description.value)

  const result = {
    id: service.id,
    categoryId: service.category && service.category.value,
    name: service.name && service.name.value,
    description: hasDescription ? service.description.value : null,
    dependencies,
    durationBefore: 0,
    durationAfter: 0,
    color: service.color && service.color.value,
    price: (service.price && service.price.value && normalizePrice(service.price.value)) || 0,
    isBookable: service.isActive && service.isActive.value,
    hasOnlinePayment: service.hasOnlinePayment && service.hasOnlinePayment.value,
    isPaymentMandatory: service.isOnlinePaymentMandatory && service.isOnlinePaymentMandatory.value,
    externalId: (service.externalId && service.externalId.value) || null,
    calendar: null,
    customerEmailRemindersMinutes: !service.serviceCustomReminderCustomerSwitch.value ? null : !service.serviceReminderCustomerSwitch.value ? [] : service.customerEmailRemindersMinutes.values,
    resourceEmailRemindersMinutes: !service.serviceCustomReminderStaffSwitch.value ? null : !service.serviceReminderStaffSwitch.value ? [] : service.resourceEmailRemindersMinutes.values
  }

  if (service.hasSpecificBookingDays && service.hasSpecificBookingDays.value) {
    const days = [...Array(7).keys()].map(() => ({}))
    const calendar = {
      timezone,
      bookingWeeklyAllowancePlan: [...days],
      workingWeeklyAllowancePlan: [...days]
    }

    // Booking weekly allowance plans
    Object
      .keys(service)
      .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
      .map(key => ({
        key,
        index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
        isActive: service[key].isActive,
        intervals: service[key].values
      }))
      .forEach(item => {
        const index = (item.index === 0 || item.index === 7) ? 0 : item.index
        const allowancePlan = {
          isActive: !!item.isActive,
          times: item.intervals
            ? [...(item.intervals || []).map(interval => ({ begin: interval.from || null, end: interval.until || null }))]
            : []
        }
        calendar.bookingWeeklyAllowancePlan[index] = allowancePlan
        calendar.workingWeeklyAllowancePlan[index] = allowancePlan
      })

    result.calendar = calendar
  }

  // Normal booking
  if (!service.splitDurationInIntervals.value) {
    result.duration = service.duration.value
  }
  // Batch booking
  if (service.splitDurationInIntervals.value) {
    result.durationsPattern = service.intervals.values
  }

  // Duration before and after
  if (service.showDurationBeforeAndAfter && service.showDurationBeforeAndAfter.value) {
    const durationBefore = service.durationBefore.value
    const durationAfter = service.durationAfter.value
    if (durationBefore) result.durationBefore = durationBefore
    if (durationAfter) result.durationAfter = durationAfter
  }

  return result
}

// Services
export const branchesServiceFormTransform = service => {
  if (!service) service = servicesDefaults
  if (!service.category) service.category = servicesDefaults.category
  if (!service.bookingWeeklyAllowancePlan) service.bookingWeeklyAllowancePlan = days
  if (!service.workingWeeklyAllowancePlan) service.workingWeeklyAllowancePlan = days
  const dependencies = globalActions.serviceDependenciesFormTransform(service)
  const companyCustomerEmailRemindersMinutes = service.companySettings && service.companySettings.customerEmailRemindersMinutes
  const companyResourceEmailRemindersMinutes = service.companySettings && service.companySettings.resourceEmailRemindersMinutes
  const serviceCustomReminderCustomerSwitch = (!service.customerEmailRemindersMinutes || JSON.stringify(service.customerEmailRemindersMinutes) === JSON.stringify(companyCustomerEmailRemindersMinutes))
    ? false
    : service.customerEmailRemindersMinutes !== null
  const serviceCustomReminderStaffSwitch = (!service.resourceEmailRemindersMinutes || JSON.stringify(service.resourceEmailRemindersMinutes) === JSON.stringify(companyResourceEmailRemindersMinutes))
    ? false
    : service.resourceEmailRemindersMinutes !== null
  const customerEmailRemindersMinutes = serviceCustomReminderCustomerSwitch ? (service.customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes) : null
  const resourceEmailRemindersMinutes = serviceCustomReminderStaffSwitch ? (service.resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes) : null
  const resourceRemindersEmailOtherRecipients = service.companySettings && service.companySettings.resourceRemindersEmailRecipients && service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']

  const result = {
    name: {
      value: service.name || ''
    },
    description: {
      value: service.description || ''
    },
    color: {
      value: service.color || DEFAULT_SERVICE_HEXCOLOR
    },
    price: {
      value: (service.price && service.price.toFixed(2)) || ''
    },
    duration: {
      value: service.duration || null
    },
    durationBefore: {
      value: service.durationBefore || null
    },
    durationAfter: {
      value: service.durationAfter || null
    },
    showDurationBeforeAndAfter: {
      value: service.durationBefore || service.durationAfter
    },
    splitDurationInIntervals: {
      value: (service.durationsPattern || []).length > 1
    },
    intervals: {
      values: service.durationsPattern && service.durationsPattern.length > 2 ? service.durationsPattern : [0, 0, 0]
    },
    hasAllResources: {
      value: !!service.hasAllResources
    },
    resources: {
      values: (!service.hasAllResources && service.resources && service.resources.map(resource => resource.id)) || [],
      options: (service.availableResources && service.availableResources.map(resource => ({
        value: resource.id,
        label: resource.name
      }))) || []
    },
    dependencies,
    isActive: {
      value: service.isBookable === undefined ? true : !!service.isBookable
    },
    hasOnlinePayment: {
      value: service.hasOnlinePayment || false
    },
    isOnlinePaymentMandatory: {
      value: service.isPaymentMandatory || false
    },
    orderIndex: {
      value: service.orderIndex
    },
    category: {
      value: service.category.id,
      type: 'hidden'
    },
    categoryName: {
      value: service.category.name
    },
    externalId: {
      value: service.externalId || ''
    },
    hasSpecificBookingDays: {
      value: !!service.hasSpecificBookingDays
    },
    serviceCustomReminderCustomerSwitch: {
      value: serviceCustomReminderCustomerSwitch
    },
    serviceReminderCustomerSwitch: {
      value: customerEmailRemindersMinutes !== null ? (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0) : false
    },
    customerEmailRemindersMinutes: {
      values: (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length) ? customerEmailRemindersMinutes : [0]
    },
    serviceCustomReminderStaffSwitch: {
      value: serviceCustomReminderStaffSwitch
    },
    serviceReminderStaffSwitch: {
      value: resourceEmailRemindersMinutes !== null ? (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length > 0) : false
    },
    resourceEmailRemindersMinutes: {
      values: (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) ? resourceEmailRemindersMinutes : [0]
    },
    serviceReminderStaffOwnerCheckBox: {
      value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
    },
    serviceReminderStaffResourcesCheckBox: {
      value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('resource') : false
    },
    serviceReminderStaffOthersCheckBox: {
      value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
    },
    serviceReminderStaffOthers: {
      values: resourceRemindersEmailOtherRecipients || ['']
    },
    isGlobal: {
      value: service.isGlobal
    }
  }
  const firstDayOfWeek = getFirstDayOfWeek()
  const firstIndex = firstDayOfWeek === 1 ? 7 : 0

  // Flatten booking weekly allowance plan
  if (service.calendar && service.calendar.bookingWeeklyAllowancePlan) {
    (service.calendar.bookingWeeklyAllowancePlan || []).forEach((item, itemIndex) => {
      result[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
        isActive: !!item.isActive,
        values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
      }
    })
  }

  if (service.id) result.id = service.id
  if (service.companyId) result.companyId = service.companyId

  return result
}

export const branchesServiceFormValidate = (service, stripeMinPrice, locale, currency, externalIds) => {
  if (!service) return
  const serviceExternalId = service.externalId && service.externalId.value
  const serviceCustomReminderCustomerSwitch = service.serviceCustomReminderCustomerSwitch && service.serviceCustomReminderCustomerSwitch.value
  const serviceReminderCustomerSwitch = service.serviceReminderCustomerSwitch && service.serviceReminderCustomerSwitch.value
  const customerEmailRemindersMinutes = (service.customerEmailRemindersMinutes && service.customerEmailRemindersMinutes.values) || []
  const serviceCustomReminderStaffSwitch = service.serviceCustomReminderStaffSwitch && service.serviceCustomReminderStaffSwitch.value
  const serviceReminderStaffSwitch = service.serviceReminderStaffSwitch && service.serviceReminderStaffSwitch.value
  const serviceReminderStaffOthers = (service.serviceReminderStaffOthersCheckBox && service.serviceReminderStaffOthersCheckBox.value && service.serviceReminderStaffOthers && service.serviceReminderStaffOthers.values) || []
  const resourceEmailRemindersMinutes = (service.resourceEmailRemindersMinutes && service.resourceEmailRemindersMinutes.values) || []
  const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)

  const rules = [
    { 'externalId.value': ['max:255'] },
    { 'name.value': ['required', 'max:255'] },
    { 'description.value': ['max:400'] },
    { 'duration.value': [`requiredIf:${!service.splitDurationInIntervals.value}`] },
    { 'price.value': [`requiredIf:${!!service.hasOnlinePayment.value}`, 'price', 'max:32'] }
  ]

  const messages = {
    externalId: {
      max: 'errors.invalidMaxLength'
    },
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    duration: {
      requiredIf: 'errors.services.chooseDuration'
    },
    description: {
      max: 'errors.invalidMaxLength'
    },
    price: {
      requiredIf: 'errors.required',
      price: 'errors.price.invalid',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    externalId: {
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    description: {
      max: {
        key: 'MAX',
        value: '400'
      }
    },
    price: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.price.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    }
  }

  let tabWithError = 'global.summary'
  const errors = validator(service, rules, messages, replaces)

  // ADVANCED VALIDATION

  // external id
  if (externalIds.includes(serviceExternalId)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  // Duration (max 60 days)
  if (service.duration && service.duration.value && service.duration.value > 60 * 24 * 60) {
    errors.push({ key: 'duration', value: 'errors.duration.limit' })
  }

  // Prep-followUp times (max 60 days)
  if (service.durationBefore && service.durationBefore.value && service.durationBefore.value > 60 * 24 * 60) {
    errors.push({ key: 'durationBefore', value: 'errors.duration.limit' })
  }
  if (service.durationAfter && service.durationAfter.value && service.durationAfter.value > 60 * 24 * 60) {
    errors.push({ key: 'durationAfter', value: 'errors.duration.limit' })
  }

  // Price 0 and online payment
  if (service.price && service.price.value && parseFloat(service.price.value) === 0 && !!service.hasOnlinePayment.value) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.required',
      replace: [replaces.price.requiredIf]
    })
  }

  // Description without HTML max 400 characters
  if (service.description && service.description.value && extractFromHtml(removeMultipleSpaces(service.description.value)).trim().length > 400) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'description',
      value: messages.description.max,
      replace: [replaces.description.max]
    })
  }

  if (!!service.hasOnlinePayment.value && stripeMinPrice && service.price && service.price.value && parseFloat(service.price.value) < stripeMinPrice) {
    const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.services.stripeMinPrice',
      replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
    })
  }

  // Resources
  if (service.dependencies && service.dependencies.values && service.dependencies.values.length > 0) {
    service.dependencies.values.forEach((item, index) => {
      if (!item.categoryIds?.length) {
        tabWithError = 'global.summary'
        errors.push({
          key: 'dependencies',
          value: 'errors.servicesGroups.selectResourceCategory',
          index
        })
      } else if (!item.resourceIds || (item.resourceIds && item.resourceIds.length === 0)) {
        tabWithError = 'global.summary'
        errors.push({
          key: 'dependencies',
          value: 'errors.servicesGroups.selectResource',
          index
        })
      }
    })
  }

  // Intervals
  if (service.splitDurationInIntervals.value && service.intervals && service.intervals.values && service.intervals.values.includes(0)) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'intervals',
      value: 'errors.intervals.invalid',
      indexes: service.intervals.values.reduce((acc, item, index) => {
        if (item !== 0) return acc
        return [...acc, index]
      }, [])
    })
  }
  if (
    service.splitDurationInIntervals.value &&
    service.intervals.values &&
    service.intervals.values.reduce((sum, item) => sum + item, 0) > 60 * 24 * 60
  ) {
    errors.push({ key: 'intervals', value: 'errors.duration.limit' })
  }

  // bookingWeeklyAllowancePlan interval validation
  const bookingWeeklyAllowancePlanDays = Object
    .keys(service)
    .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
    .map(key => ({
      index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
      isActive: service[key].isActive,
      intervals: service[key].values
    }))
  const areAllDaysInactive = bookingWeeklyAllowancePlanDays.reduce((acc, item) => {
    return acc && !item.isActive
  }, true)
  if (service.hasSpecificBookingDays && service.hasSpecificBookingDays.value && areAllDaysInactive) {
    tabWithError = 'global.availability'
    errors.push({
      key: 'hasSpecificBookingDays',
      value: 'errors.services.bookingTime.interval.atLeastOne'
    })
  }
  if (service.hasSpecificBookingDays.value) {
    bookingWeeklyAllowancePlanDays.forEach(item => {
      if (!item.isActive) return
      item.intervals && (item.intervals || []).forEach((interval, intervalIndex) => {
      // Missing from and until
        if (!interval.from && !interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.services.bookingTime.interval.required',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Missing only from
        if (!interval.from && interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.services.bookingTime.interval.missingIntervalFromValue',
            index: intervalIndex,
            fields: ['from']
          })
        }
        // Missing only until
        if (interval.from && !interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.services.bookingTime.interval.missingIntervalUntilValue',
            index: intervalIndex,
            fields: ['until']
          })
        }
        // The same from and until
        if (interval.from && interval.until && interval.from === interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.services.bookingTime.interval.fromUntilNotDifferent',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Interval end on next day
        if (interval.from > interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.services.bookingTime.interval.mustEndSameDay',
            replace: [
              { key: 'FROM', value: interval.from },
              { key: 'UNTIL', value: interval.until }
            ],
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
      })
    })
  }

  // service specific customer reminders
  if (serviceCustomReminderCustomerSwitch && serviceReminderCustomerSwitch) {
    customerEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'customerEmailRemindersMinutes',
          value: 'errors.reminder.incorrect',
          replace: [{ key: 'FIELD_NAME', value: 'global.customer', translateReplace: true }]
        })
      }
    })
  }

  // service specific staff reminders
  if (serviceCustomReminderStaffSwitch && serviceReminderStaffSwitch) {
    if (!service.serviceReminderStaffOthersCheckBox.value && !service.serviceReminderStaffResourcesCheckBox.value && !service.serviceReminderStaffOwnerCheckBox.value) {
      tabWithError = 'global.advanced'
      errors.push({
        key: 'serviceReminderStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    serviceReminderStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'serviceReminderStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (!item) {
        tabWithError = 'global.advanced'
        errors.push({ key: 'serviceReminderStaffOthers', value: 'errors.email.required' })
      }
    })
    resourceEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'resourceEmailRemindersMinutes',
          value: 'errors.reminder.incorrect',
          replace: [{ key: 'FIELD_NAME', value: 'global.resource', translateReplace: true }]
        })
      }
    })
  }

  if (errors && errors.length) {
    errors.push({
      key: 'globalErrors',
      value: 'errors.tabs.followingTabs',
      replace: [{ key: 'TAB_NAME', value: tabWithError, translateReplace: true }]
    })
  }

  return errors
}

export const branchesServiceFormServerErrorsTransform = error => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

// Service Combiations
export const branchesServiceCombinationFormTransform = service => {
  service = service || servicesDefaults
  service.category = service.category || servicesDefaults.category
  service.categorizedServices = service.categorizedServices || []

  const companyCustomerEmailRemindersMinutes = service.companySettings && service.companySettings.customerEmailRemindersMinutes
  const companyResourceEmailRemindersMinutes = service.companySettings && service.companySettings.resourceEmailRemindersMinutes
  const serviceCustomReminderCustomerSwitch = (!service.customerEmailRemindersMinutes || JSON.stringify(service.customerEmailRemindersMinutes) === JSON.stringify(companyCustomerEmailRemindersMinutes))
    ? false
    : service.customerEmailRemindersMinutes !== null
  const serviceCustomReminderStaffSwitch = (!service.resourceEmailRemindersMinutes || JSON.stringify(service.resourceEmailRemindersMinutes) === JSON.stringify(companyResourceEmailRemindersMinutes))
    ? false
    : service.resourceEmailRemindersMinutes !== null
  const customerEmailRemindersMinutes = serviceCustomReminderCustomerSwitch ? (service.customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes) : null
  const resourceEmailRemindersMinutes = serviceCustomReminderStaffSwitch ? (service.resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes) : null
  const resourceRemindersEmailOtherRecipients = service.companySettings && service.companySettings.resourceRemindersEmailRecipients && service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']

  const result = {
    name: {
      value: service.name || ''
    },
    description: {
      value: service.description || ''
    },
    hasPriceOverwrite: {
      value: !!service.isCombinationPriceOverwritten
    },
    price: {
      value: (service.price && service.price.toFixed(2)) || ''
    },
    isActive: {
      value: service.isBookable === undefined ? true : !!service.isBookable
    },
    hasOnlinePayment: {
      value: service.hasOnlinePayment || false
    },
    isOnlinePaymentMandatory: {
      value: service.isPaymentMandatory || false
    },
    orderIndex: {
      value: service.orderIndex
    },
    category: {
      value: service.category.id,
      type: 'hidden'
    },
    categoryName: {
      value: service.category.name
    },
    externalId: {
      value: service.externalId || ''
    },
    services: {
      values: service.combinationServiceIds || [],
      options: service.categorizedServices
    },
    hasCombinationSameResourcesPreference: {
      value: !!service.hasCombinationSameResourcesPreference
    },
    hasSpecificBookingDays: {
      value: !!service.hasSpecificBookingDays
    },
    durationBefore: {
      value: service.durationBefore || null
    },
    durationAfter: {
      value: service.durationAfter || null
    },
    showDurationBeforeAndAfter: {
      value: service.durationBefore || service.durationAfter
    },
    serviceCustomReminderCustomerSwitch: {
      value: serviceCustomReminderCustomerSwitch
    },
    serviceReminderCustomerSwitch: {
      value: customerEmailRemindersMinutes !== null ? (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0) : false
    },
    customerEmailRemindersMinutes: {
      values: (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length) ? customerEmailRemindersMinutes : [0]
    },
    serviceCustomReminderStaffSwitch: {
      value: serviceCustomReminderStaffSwitch
    },
    serviceReminderStaffSwitch: {
      value: resourceEmailRemindersMinutes !== null ? (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length > 0) : false
    },
    resourceEmailRemindersMinutes: {
      values: (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) ? resourceEmailRemindersMinutes : [0]
    },
    serviceReminderStaffOwnerCheckBox: {
      value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
    },
    serviceReminderStaffResourcesCheckBox: {
      value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('resource') : false
    },
    serviceReminderStaffOthersCheckBox: {
      value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
    },
    serviceReminderStaffOthers: {
      values: resourceRemindersEmailOtherRecipients || ['']
    },
    isGlobal: {
      value: service.isGlobal
    }
  }
  const firstDayOfWeek = getFirstDayOfWeek()
  const firstIndex = firstDayOfWeek === 1 ? 7 : 0

  // Flatten booking weekly allowance plan
  if (service.calendar && service.calendar.bookingWeeklyAllowancePlan) {
    (service.calendar.bookingWeeklyAllowancePlan || []).forEach((item, itemIndex) => {
      result[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
        isActive: !!item.isActive,
        values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
      }
    })
  }

  if (service.id) result.id = service.id
  return result
}

export const branchesServiceCombinationSaveTransform = service => {
  let {
    id,
    category,
    name,
    description,
    hasPriceOverwrite,
    price,
    isActive,
    hasOnlinePayment,
    isOnlinePaymentMandatory,
    externalId,
    hasCombinationSameResourcesPreference,
    services,
    showDurationBeforeAndAfter,
    durationBefore,
    durationAfter
  } = service || {}
  category = category || {}
  name = name || {}
  description = description || {}
  hasPriceOverwrite = hasPriceOverwrite || {}
  price = price || {}
  isActive = isActive || {}
  hasOnlinePayment = hasOnlinePayment || {}
  isOnlinePaymentMandatory = isOnlinePaymentMandatory || {}
  externalId = externalId || {}
  hasCombinationSameResourcesPreference = hasCombinationSameResourcesPreference || {}
  services = services || {}

  const result = {
    isCombination: true,
    id,
    categoryId: category.value,
    name: name.value,
    description: description.value,
    isCombinationPriceOverwritten: !!hasPriceOverwrite.value,
    price: hasPriceOverwrite.value ? price.value && normalizePrice(price.value) : undefined,
    isBookable: isActive.value,
    hasOnlinePayment: hasOnlinePayment.value,
    isPaymentMandatory: isOnlinePaymentMandatory.value,
    externalId: externalId.value || null,
    durationBefore: 0,
    durationAfter: 0,
    hasCombinationSameResourcesPreference: !!hasCombinationSameResourcesPreference.value,
    combinationServiceIds: services.values || [],
    calendar: null,
    customerEmailRemindersMinutes: !service.serviceCustomReminderCustomerSwitch.value ? null : !service.serviceReminderCustomerSwitch.value ? [] : service.customerEmailRemindersMinutes.values,
    resourceEmailRemindersMinutes: !service.serviceCustomReminderStaffSwitch.value ? null : !service.serviceReminderStaffSwitch.value ? [] : service.resourceEmailRemindersMinutes.values
  }

  if (service.hasSpecificBookingDays && service.hasSpecificBookingDays.value) {
    const days = [...Array(7).keys()].map(() => ({}))
    const calendar = {
      timezone: service.companyTimezone,
      bookingWeeklyAllowancePlan: [...days],
      workingWeeklyAllowancePlan: [...days]
    }
    // Booking weekly allowance plans
    Object
      .keys(service)
      .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
      .map(key => ({
        key,
        index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
        isActive: service[key].isActive,
        intervals: service[key].values
      }))
      .forEach(item => {
        const index = (item.index === 0 || item.index === 7) ? 0 : item.index
        const allowancePlan = {
          isActive: !!item.isActive,
          times: item.intervals
            ? [...(item.intervals || []).map(interval => ({ begin: interval.from || null, end: interval.until || null }))]
            : []
        }
        calendar.bookingWeeklyAllowancePlan[index] = allowancePlan
        calendar.workingWeeklyAllowancePlan[index] = allowancePlan
      })
    result.calendar = calendar
  }

  // Duration before and after
  if (showDurationBeforeAndAfter.value) {
    if (durationBefore.value) result.durationBefore = durationBefore.value
    if (durationAfter.value) result.durationAfter = durationAfter.value
  }

  return result
}

export const branchesServiceCombinationFormValidate = (service, stripeMinPrice, locale, currency, externalIds) => {
  if (!service) return
  const {
    allServices,
    services,
    hasPriceOverwrite,
    hasOnlinePayment,
    externalId
  } = service || {}
  let { values: selectedServices } = services || {}
  const { value: externalIdValue } = externalId || {}
  const { value: hasPriceOverwriteValue } = hasPriceOverwrite || {}
  const { value: hasOnlinePaymentValue } = hasOnlinePayment || {}
  selectedServices = selectedServices || []
  const filteredServices = allServices.filter(item => selectedServices.includes(item.id))
  const combinationPrice = filteredServices.reduce((acc, item) => {
    acc = acc + (item.price || 0)
    return acc
  }, 0)
  const rules = [
    { 'externalId.value': ['max:255'] },
    { 'name.value': ['required', 'max:255'] },
    { 'description.value': ['max:400'] },
    { 'price.value': [`requiredIf:${(!!hasOnlinePaymentValue && !combinationPrice) || !!hasPriceOverwriteValue}`, 'price', 'max:32'] },
    { 'hasPriceOverwrite.value': [`requiredIf:${!!hasOnlinePaymentValue && !combinationPrice}`] }
  ]
  const messages = {
    externalId: {
      max: 'errors.invalidMaxLength'
    },
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    description: {
      max: 'errors.invalidMaxLength'
    },
    price: {
      requiredIf: 'errors.required',
      price: 'errors.price.invalid',
      max: 'errors.invalidMaxLength'
    },
    hasPriceOverwrite: {
      requiredIf: 'errors.required'
    }
  }
  const replaces = {
    externalId: {
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    description: {
      max: {
        key: 'MAX',
        value: '400'
      }
    },
    price: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.price.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    },
    hasPriceOverwrite: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.price.label',
        translateReplace: true
      }
    }
  }

  let tabWithError = 'global.summary'
  const errors = validator(service, rules, messages, replaces)

  // ADVANCED VALIDATION

  // external Id
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  // Price 0 and online payment
  if (service.price && service.price.value && parseFloat(service.price.value) === 0 && !!service.hasOnlinePayment.value) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.required',
      replace: [replaces.price.requiredIf]
    })
  }

  if (!!service.hasOnlinePayment.value && stripeMinPrice && service.price && service.price.value && parseFloat(service.price.value) < stripeMinPrice) {
    tabWithError = 'global.summary'
    const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
    errors.push({
      key: 'price',
      value: 'errors.services.stripeMinPrice',
      replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
    })
  }

  if (selectedServices.length < 2) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'services',
      value: 'errors.services.minimunAmountRequired'
    })
  }

  if (errors && errors.length) {
    errors.push({
      key: 'globalErrors',
      value: 'errors.tabs.followingTabs',
      replace: [{ key: 'TAB_NAME', value: tabWithError, translateReplace: true }]
    })
  }
  return errors
}

export const branchesServiceCombinationFormServerErrorsTransform = (error, stripeMinPrice, locale, currency) => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code === 'NoCalendarWithoutEnterprisePlan') {
    errors.push({ key: 'hasSpecificBookingDays', value: translateServerCode(error.code) })
  } else if (error.code === 'MinAmountStripe') {
    const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
    errors.push({
      key: 'price',
      value: 'errors.services.stripeMinPrice',
      replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
    })
  } else if (error.code === 'NoCombinationsWithSplit') {
    errors.push({
      key: 'services',
      value: 'errors.services.minimunAmountRequired'
    })
  } else if (error.code === 'CombinationServicesNotFound') {
    errors.push({ key: 'services', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

// Groups
export const branchesGroupFormTransform = course => {
  if (!course) course = courseDefaults
  if (!course.category) course.category = courseDefaults.category
  if (!course.bookingWeeklyAllowancePlan) course.bookingWeeklyAllowancePlan = days
  if (!course.workingWeeklyAllowancePlan) course.workingWeeklyAllowancePlan = days
  const dependencies = globalActions.serviceDependenciesFormTransform(course)
  const companyCustomerEmailRemindersMinutes = course.companySettings && course.companySettings.customerEmailRemindersMinutes
  const companyResourceEmailRemindersMinutes = course.companySettings && course.companySettings.resourceEmailRemindersMinutes
  const courseCustomReminderCustomerSwitch = (!course.customerEmailRemindersMinutes || JSON.stringify(course.customerEmailRemindersMinutes) === JSON.stringify(companyCustomerEmailRemindersMinutes))
    ? false
    : course.customerEmailRemindersMinutes !== null
  const courseCustomReminderStaffSwitch = (!course.resourceEmailRemindersMinutes || JSON.stringify(course.resourceEmailRemindersMinutes) === JSON.stringify(companyResourceEmailRemindersMinutes))
    ? false
    : course.resourceEmailRemindersMinutes !== null
  const customerEmailRemindersMinutes = courseCustomReminderCustomerSwitch ? (course.customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes) : null
  const resourceEmailRemindersMinutes = courseCustomReminderStaffSwitch ? (course.resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes) : null
  const resourceRemindersEmailOtherRecipients = course.companySettings && course.companySettings.resourceRemindersEmailRecipients && course.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? course.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']
  const result = {
    name: {
      value: course.name || ''
    },
    description: {
      value: course.description || ''
    },
    color: {
      value: course.color || DEFAULT_COURSE_HEXCOLOR
    },
    price: {
      value: (course.price && course.price.toFixed(2)) || ''
    },
    duration: {
      value: course.duration || null
    },
    durationBefore: {
      value: course.durationBefore || null
    },
    durationAfter: {
      value: course.durationAfter || null
    },
    showDurationBeforeAndAfter: {
      value: course.durationBefore || course.durationAfter
    },
    splitDurationInIntervals: {
      value: course.isBatch
    },
    intervals: {
      values: course.durationsPattern && course.durationsPattern.length > 2 ? course.durationsPattern : [0, 0, 0]
    },
    hasAllResources: {
      value: !!course.hasAllResources
    },
    resources: {
      values: (!course.hasAllResources && course.resources && course.resources.map(resource => resource.id)) || [],
      options: (course.availableResources && course.availableResources.map(resource => ({
        value: resource.id,
        label: resource.name
      }))) || []
    },
    dependencies,
    isActive: {
      value: course.isBookable === undefined ? true : !!course.isBookable
    },
    hasOnlinePayment: {
      value: course.hasOnlinePayment || false
    },
    isOnlinePaymentMandatory: {
      value: course.isPaymentMandatory || false
    },
    orderIndex: {
      value: course.orderIndex
    },
    category: {
      value: course.category.id,
      type: 'hidden'
    },
    categoryName: {
      value: course.category.name
    },
    maxParticipants: {
      value: course.maxParticipants || ''
    },
    extraPersonsPerParticipant: {
      value: course.extraPersonsPerParticipant || ''
    },
    externalId: {
      value: course.externalId || ''
    },
    hasSpecificBookingDays: {
      value: !!course.hasSpecificBookingDays
    },
    courseCustomReminderCustomerSwitch: {
      value: courseCustomReminderCustomerSwitch
    },
    courseReminderCustomerSwitch: {
      value: customerEmailRemindersMinutes !== null ? (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0) : false
    },
    customerEmailRemindersMinutes: {
      values: (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length) ? customerEmailRemindersMinutes : [0]
    },
    courseCustomReminderStaffSwitch: {
      value: courseCustomReminderStaffSwitch
    },
    courseReminderStaffSwitch: {
      value: resourceEmailRemindersMinutes !== null ? (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) > 0 : false
    },
    resourceEmailRemindersMinutes: {
      values: (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) ? resourceEmailRemindersMinutes : [0]
    },
    courseReminderStaffOwnerCheckBox: {
      value: course.companySettings && course.companySettings.resourceRemindersEmailRecipients ? course.companySettings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
    },
    courseReminderStaffResourcesCheckBox: {
      value: course.companySettings && course.companySettings.resourceRemindersEmailRecipients ? course.companySettings.resourceRemindersEmailRecipients.includes('resource') : false
    },
    courseReminderStaffOthersCheckBox: {
      value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
    },
    courseReminderStaffOthers: {
      values: resourceRemindersEmailOtherRecipients || ['']
    },
    isGlobal: {
      value: course.isGlobal
    }
  }
  const firstDayOfWeek = getFirstDayOfWeek()
  const firstIndex = firstDayOfWeek === 1 ? 7 : 0

  // Flatten booking weekly allowance plan
  if (course.calendar && course.calendar.bookingWeeklyAllowancePlan) {
    (course.calendar.bookingWeeklyAllowancePlan || []).forEach((item, itemIndex) => {
      result[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
        isActive: !!item.isActive,
        values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
      }
    })
  }

  if (course.id) result.id = course.id
  if (course.companyId) result.companyId = course.companyId

  return result
}

export const branchesGroupFormValidate = (course, stripeMinPrice, locale, currency, externalIds) => {
  if (!course) return
  const courseExternalId = course.externalId && course.externalId.value
  const courseCustomReminderCustomerSwitch = course.courseCustomReminderCustomerSwitch && course.courseCustomReminderCustomerSwitch.value
  const courseReminderCustomerSwitch = course.courseReminderCustomerSwitch && course.courseReminderCustomerSwitch.value
  const customerEmailRemindersMinutes = (course.customerEmailRemindersMinutes && course.customerEmailRemindersMinutes.values) || []
  const courseCustomReminderStaffSwitch = course.courseCustomReminderStaffSwitch && course.courseCustomReminderStaffSwitch.value
  const courseReminderStaffSwitch = course.courseReminderStaffSwitch && course.courseReminderStaffSwitch.value
  const courseReminderStaffOthers = (course.courseReminderStaffOthersCheckBox.value && course.courseReminderStaffOthers && course.courseReminderStaffOthers.values) || []
  const resourceEmailRemindersMinutes = (course.resourceEmailRemindersMinutes && course.resourceEmailRemindersMinutes.values) || []
  const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)
  const isSplitted = (course.splitDurationInIntervals && course.splitDurationInIntervals.value)

  const rules = [
    { 'externalId.value': ['max:255'] },
    { 'name.value': ['required', 'max:255'] },
    { 'maxParticipants.value': ['required', 'digits', 'max:32'] },
    { 'extraPersonsPerParticipant.value': ['digits', 'max:32'] },
    { 'description.value': ['max:400'] },
    { 'duration.value': [`requiredIf:${!isSplitted}`] },
    { 'price.value': [`requiredIf:${!!course.hasOnlinePayment.value}`, 'price', 'max:32'] }
  ]

  const messages = {
    externalId: {
      max: 'errors.invalidMaxLength'
    },
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    maxParticipants: {
      required: 'errors.required',
      digits: 'errors.fillNumbers',
      max: 'errors.invalidMaxLength'
    },
    extraPersonsPerParticipant: {
      digits: 'errors.fillNumbers',
      max: 'errors.invalidMaxLength'
    },
    duration: {
      requiredIf: 'errors.services.chooseDuration'
    },
    description: {
      max: 'errors.invalidMaxLength'
    },
    price: {
      requiredIf: 'errors.required',
      price: 'errors.price.invalid',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    externalId: {
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    description: {
      max: {
        key: 'MAX',
        value: '400'
      }
    },
    maxParticipants: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.maxParticipants',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    },
    extraPersonsPerParticipant: {
      max: {
        key: 'MAX',
        value: '32'
      }
    },
    price: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.price.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    }
  }

  let tabWithError = 'global.summary'
  const errors = validator(course, rules, messages, replaces)

  // ADVANCED VALIDATION

  // External id
  if (externalIds.includes(courseExternalId)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  // Duration (max 60 days)
  if (course.duration && course.duration.value && course.duration.value > 60 * 24 * 60) {
    errors.push({ key: 'duration', value: 'errors.duration.limit' })
  }

  // Prep-followUp times (max 60 days)
  if (course.durationBefore && course.durationBefore.value && course.durationBefore.value > 60 * 24 * 60) {
    errors.push({ key: 'durationBefore', value: 'errors.duration.limit' })
  }
  if (course.durationAfter && course.durationAfter.value && course.durationAfter.value > 60 * 24 * 60) {
    errors.push({ key: 'durationAfter', value: 'errors.duration.limit' })
  }

  // Price 0 and online payment
  if (course.price && course.price.value && course.price.value.toString() === '0' && !!course.hasOnlinePayment.value) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.required',
      replace: [replaces.price.requiredIf]
    })
  }

  if (!!course.hasOnlinePayment.value && stripeMinPrice && course.price && course.price.value && parseFloat(course.price.value) < stripeMinPrice) {
    const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.courses.stripeMinPrice',
      replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
    })
  }

  // Description without HTML max 400 characters
  if (course.description && course.description.value && extractFromHtml(removeMultipleSpaces(course.description.value)).trim().length > 400) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'description',
      value: messages.description.max,
      replace: [replaces.description.max]
    })
  }

  // Resources
  if (course.dependencies && course.dependencies.values && course.dependencies.values.length > 0) {
    course.dependencies.values.forEach((item, index) => {
      if (!item.categoryIds?.length) {
        tabWithError = 'global.summary'
        errors.push({
          key: 'dependencies',
          value: 'errors.servicesGroups.selectResourceCategory',
          index
        })
      } else if (!item.resourceIds || (item.resourceIds && item.resourceIds.length === 0)) {
        tabWithError = 'global.summary'
        errors.push({
          key: 'dependencies',
          value: 'errors.servicesGroups.selectResourceCategory',
          index
        })
      }
    })
  }

  // Max participants
  if (course.maxParticipants.value && course.maxParticipants.value < 1) {
    tabWithError = 'global.summary'
    errors.push({ key: 'participantsGlobalErrors', value: 'errors.maxParticipants' })
  }
  if (
    course.extraPersonsPerParticipant.value &&
    course.extraPersonsPerParticipant.value > 0 &&
    parseInt(course.extraPersonsPerParticipant.value) >= course.maxParticipants.value
  ) {
    tabWithError = 'global.summary'
    errors.push({ key: 'participantsGlobalErrors', value: 'errors.extraParticipantsHasToBeLessThanMaxParticipants' })
  }

  // Intervals
  if (course.splitDurationInIntervals.value && course.intervals.values.includes(0)) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'intervals',
      value: 'errors.intervals.invalid',
      indexes: course.intervals.values.reduce((acc, item, index) => {
        if (item !== 0) return acc
        return [...acc, index]
      }, [])
    })
  }
  if (
    course.splitDurationInIntervals.value &&
    course.intervals.values &&
    course.intervals.values.reduce((sum, item) => sum + item, 0) > 60 * 24 * 60
  ) {
    errors.push({ key: 'intervals', value: 'errors.duration.limit' })
  }

  // bookingWeeklyAllowancePlan interval validation
  Object
    .keys(course)
    .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
    .map(key => ({
      index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
      isActive: course[key].isActive,
      intervals: course[key].values
    }))
    .forEach(item => {
      if (!item.isActive) return
      item.intervals && (item.intervals || []).forEach((interval, intervalIndex) => {
        // Missing from and until
        if (item.intervals.length > 1 && !interval.from && !interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.courses.bookingTime.interval.required',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Missing only from
        if (!interval.from && interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.courses.bookingTime.interval.missingIntervalFromValue',
            index: intervalIndex,
            fields: ['from']
          })
        }
        // Missing only until
        if (interval.from && !interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.courses.bookingTime.interval.missingIntervalUntilValue',
            index: intervalIndex,
            fields: ['until']
          })
        }
        // The same from and until
        if (interval.from && interval.until && interval.from === interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.courses.bookingTime.interval.fromUntilNotDifferent',
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
        // Interval end on next day
        if (interval.from > interval.until) {
          tabWithError = 'global.availability'
          errors.push({
            key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
            value: 'errors.courses.bookingTime.interval.mustEndSameDay',
            replace: [
              { key: 'FROM', value: interval.from },
              { key: 'UNTIL', value: interval.until }
            ],
            index: intervalIndex,
            fields: ['from', 'until']
          })
        }
      })
    })

  // course specific customer reminders
  if (courseCustomReminderCustomerSwitch && courseReminderCustomerSwitch) {
    customerEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'customerEmailRemindersMinutes',
          value: 'errors.reminder.incorrect',
          replace: [{ key: 'FIELD_NAME', value: 'global.customer', translateReplace: true }]
        })
      }
    })
  }

  // course specific staff reminders
  if (courseCustomReminderStaffSwitch && courseReminderStaffSwitch) {
    if (!course.courseReminderStaffOthersCheckBox.value && !course.courseReminderStaffResourcesCheckBox.value && !course.courseReminderStaffOwnerCheckBox.value) {
      tabWithError = 'global.advanced'
      errors.push({
        key: 'courseReminderStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    courseReminderStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'courseReminderStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (!item) errors.push({ key: 'courseReminderStaffOthers', value: 'errors.email.required' })
    })
    resourceEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'resourceEmailRemindersMinutes',
          value: 'errors.reminder.incorrect',
          replace: [{ key: 'FIELD_NAME', value: 'global.resource', translateReplace: true }]
        })
      }
    })
  }

  if (errors && errors.length) {
    errors.push({
      key: 'globalErrors',
      value: 'errors.tabs.followingTabs',
      replace: [{ key: 'TAB_NAME', value: tabWithError, translateReplace: true }]
    })
  }

  return errors
}

export const branchesGroupSaveTransform = course => {
  const dependencies = globalActions.serviceDependenciesSaveTransform(course)
  const hasDescription = course.description && extractFromHtml(course.description.value)

  const result = {
    id: course.id,
    companyId: course.companyId,
    categoryId: course.category.value,
    name: course.name && course.name.value,
    description: hasDescription ? course.description.value : null,
    dependencies,
    durationBefore: 0,
    durationAfter: 0,
    color: course.color && course.color.value,
    price: (course.price.value && normalizePrice(course.price.value)) || 0,
    isBookable: course.isActive && course.isActive.value,
    hasOnlinePayment: course.hasOnlinePayment && course.hasOnlinePayment.value,
    isPaymentMandatory: course.isOnlinePaymentMandatory && course.isOnlinePaymentMandatory.value,
    maxParticipants: course.maxParticipants && course.maxParticipants.value,
    extraPersonsPerParticipant: (course.extraPersonsPerParticipant && course.extraPersonsPerParticipant.value) || 0,
    externalId: course.externalId.value || null,
    calendar: null,
    customerEmailRemindersMinutes: !course.courseCustomReminderCustomerSwitch.value ? null : !course.courseReminderCustomerSwitch.value ? [] : course.customerEmailRemindersMinutes.values,
    resourceEmailRemindersMinutes: !course.courseCustomReminderStaffSwitch.value ? null : !course.courseReminderStaffSwitch.value ? [] : course.resourceEmailRemindersMinutes.values
  }

  if (course.hasSpecificBookingDays && course.hasSpecificBookingDays.value) {
    const days = [...Array(7).keys()].map(() => ({}))
    const calendar = {
      timezone: course.companyTimezone,
      bookingWeeklyAllowancePlan: [...days],
      workingWeeklyAllowancePlan: [...days]
    }

    // Booking weekly allowance plans
    Object
      .keys(course)
      .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
      .map(key => ({
        key,
        index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
        isActive: course[key].isActive,
        intervals: course[key].values
      }))
      .forEach(item => {
        const index = (item.index === 0 || item.index === 7) ? 0 : item.index
        const allowancePlan = {
          isActive: !!item.isActive,
          times: item.intervals
            ? [...(item.intervals || []).map(interval => ({ begin: interval.from, end: interval.until }))]
            : []
        }
        calendar.bookingWeeklyAllowancePlan[index] = allowancePlan
        calendar.workingWeeklyAllowancePlan[index] = allowancePlan
      })

    result.calendar = calendar
  }

  // Normal booking
  if (!course.splitDurationInIntervals.value) {
    result.duration = course.duration.value
  }
  // Batch booking
  if (course.splitDurationInIntervals.value) {
    result.durationsPattern = course.intervals.values
  }

  // Duration before and after
  if (course.showDurationBeforeAndAfter.value) {
    const durationBefore = course.durationBefore.value
    const durationAfter = course.durationAfter.value
    if (durationBefore) result.durationBefore = durationBefore
    if (durationAfter) result.durationAfter = durationAfter
  }

  return result
}

export const branchesGroupFormServerErrorsTransform = error => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

// Validate
export const formValidateCreate = (form, externalIds, isCustomRegion) => {
  if (!form) return

  const phoneLength = 16
  const rules = [
    { 'name.value': ['required', 'max:255'] },
    { 'personName.value': ['required', 'max:255'] },
    { 'email.value': ['required', 'email', 'max:255'] },
    { 'externalId.value': ['max:255'] },
    { 'plan.value': ['required', 'max:255'] },
    { 'phone.phone': ['required', `max:${phoneLength}`, 'phone'] },
    { 'locale.value': ['required'] },
    { 'timezone.value': ['required'] },
    { 'customRegion.value': [`requiredIf:${isCustomRegion}`] }
  ]

  const messages = {
    personName: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    email: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    plan: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    externalId: {
      max: 'errors.invalidMaxLength'
    },
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    phone: {
      required: 'errors.phone.required',
      phone: 'errors.phone.invalid',
      max: 'errors.invalidMaxLength'
    },
    locale: {
      required: 'errors.required'
    },
    timezone: {
      required: 'errors.required'
    },
    customRegion: {
      required: 'errors.required'
    }
  }

  const replaces = {
    personName: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    email: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.email.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    plan: {
      required: {
        key: 'FIELD_NAME',
        value: 'Plan'
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    externalId: {
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    phone: {
      max: {
        key: 'MAX',
        value: phoneLength
      }
    },
    locale: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.locale.label',
        translateReplace: true
      }
    },
    timezone: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.timezone.label',
        translateReplace: true
      }
    },
    customRegion: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.customRegion.label',
        translateReplace: true
      }
    }
  }

  const errors = validator(form, rules, messages, replaces)

  const externalIdValue = form.externalId && form.externalId.value
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

// Customer Form
const customersDefaults = {}

export const branchesCustomerTransform = (customer, skipTransform) => {
  if (!customer) return
  if (skipTransform) return customer
  const PARSE_TYPES = ['PHONE', 'ADDRESS']
  return {
    id: customer.id,
    externalId: customer.externalId,
    fullName: customer.fullName,
    companyName: customer.companyName,
    phone: customer.phone,
    email: customer.email,
    totalBookings: customer.totalBookings,
    avatarUrl: customer.avatarUrl,
    tagIds: customer.tagIds,
    ts: customer.ts,
    fields: customer.fields && customer.fields
      .map(item => ({
        id: item.id,
        isActive: item.isActive,
        type: item.type,
        value: item.value && PARSE_TYPES.includes(item.type)
          ? JSON.parse(item.value)
          : item.value
      }))
      .sort(sortByOrderIndex)
  }
}

export const branchesCustomerFormTransform = customer => {
  if (!customer) customer = customersDefaults
  const companyLocale = customer.companyLocale || ''
  const customerFields = customer.fields.filter(item => item.isActive)
  const result = {
    externalId: {
      value: customer.externalId || ''
    },
    tags: {
      values: customer.tagIds || [],
      options: (customer.availableTags || []).map(item => ({
        label: item.name,
        value: item.id
      }))
    }
  }
  customerFields
    .filter(item => item.hasOverwrite)
    .forEach(item => {
      result[`customerFields${item.id}`] = {
        value: item.value || ''
      }
      if (item.type === 'CHECKBOX') {
        result[`customerFields${item.id}`].value = item.value + '' === 'true'
      }
      if (item.type === 'SELECT') {
        result[`customerFields${item.id}`].options = item.selectOptions
        if ((item.selectOptions || []).findIndex(option => option.value === item.value) === -1) result[`customerFields${item.id}`].value = ''
      }
      if (item.type === 'PHONE') {
        result[`customerFields${item.id}`] = {
          phone: (item.value && item.value.number) || '',
          phoneCountry: item.value
            ? item.value.country
            : companyLocale.length > 2
              ? (companyLocale.split('-')[1] || '').toUpperCase()
              : DEFAULT_LOCALE_COUNTRY_CODE
        }
      }
      if (item.type === 'FILE' && item.defaultId === 'avatar') {
        result[`customerFields${item.id}`] = {
          avatarUrl: { value: item.value || '' },
          value: ''
        }
      }
      if (item.type === 'ADDRESS') {
        const address = item.value || {}
        result[`customerFields${item.id}`].value = address.formatted
        result[`customerFields${item.id}`].data = ((address.formatted && address.formatted !== '') && {
          placeId: address.placeId || '',
          city: address.city || '',
          streetName: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.zipCode || '',
          country: address.country || '',
          lat: address.latitude || '',
          lng: address.longitude || '',
          formattedAddress: address.formatted || '',
          details: address.details || ''
        }) || null
        result[`customerFields${item.id}Secondary`] = {
          value: address.details || ''
        }
      }
      result[`customerFields${item.id}`].type = item.type
      result[`customerFields${item.id}`].defaultId = item.defaultId
      result[`customerFields${item.id}`].isMandatory = item.isMandatoryOffline
      result[`customerFields${item.id}`].translationKey = item.translationKey
    })
  result.customerPrintUpcomingBookings = { value: false }
  result.customerPrintPastBookings = { value: false }
  if (customer.id) result.id = customer.id
  if (customer.companyId) result.companyId = customer.companyId
  return result
}

export const branchesCustomerFormValidate = customer => {
  if (!customer) return

  const combinedFields = Object.keys(customer).reduce((acc, key) => {
    if (key.indexOf('customerFields') !== -1) {
      acc.push({
        ...customer[key],
        name: key
      })
    }
    return acc
  }, [])

  const rules = []
  const messages = {}
  const replaces = {}
  const atLeastOneIsRequired = ['firstName', 'lastName', 'company']
  const atLeastOneIsRequiredValues = []
  const atLeastOneIsRequiredFields = []

  let atLeastOneCheck = true

  combinedFields.forEach((field, index) => {
    const name = field.name
    const defaultId = (field.defaultId && field.defaultId) || ''
    const type = field.type && field.type
    const value = field.value && field.value
    const isMandatory = !!field.isMandatory
    const translationKey = field.translationKey || 'global.theField'

    switch (type) {
      case 'FILE':
        if (defaultId.toLowerCase() === 'avatar') {
          if (isMandatory) {
            const isAvatarSet = !!(field.avatarUrl && field.avatarUrl.value && field.avatarUrl.value !== '')
            rules.push({ [`${name}.avatarFile`]: [`requiredIf:${!isAvatarSet}`] })
          }
          messages[name] = {
            requiredIf: 'errors.required'
          }
          replaces[name] = {
            requiredIf: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
          }
        }
        break

      case 'TEXT':
        if (atLeastOneIsRequired.includes(defaultId) && !atLeastOneIsRequiredValues.find(item => item === value)) {
          if (value && value.trim() !== '') atLeastOneIsRequiredValues.push(value)
          if (isMandatory) atLeastOneCheck = false
        }

        atLeastOneIsRequiredFields.push(name)

        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })

        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'TEXTAREA':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:400'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 400 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'SELECT':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'DATE':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'CHECKBOX':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:64'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 64 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'EMAIL':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['email', 'max:255'] })
        messages[name] = {
          email: 'errors.email.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'PHONE':
        if (isMandatory) rules.push({ [`${name}.phone`]: ['required'] })
        rules.push({ [`${name}.phone`]: ['phone', 'max:16'] })
        messages[name] = {
          phone: 'errors.phone.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 64 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'ADDRESS':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required', 'max:800'] })
        const isAddressSet = (customer[name] && customer[name].value && customer[name].value !== '')
        if (isAddressSet) {
          rules.push({ [`${name}.data.city`]: [`requiredIf:${isAddressSet}`] })
          rules.push({ [`${name}.data.streetName`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.streetNumber`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.postalCode`]: [`requiredIf:${isAddressSet}`] })
        }

        let addressErrorMessage = 'errors.address.required'

        if (isAddressSet && customer[name] && customer[name].data) {
          if (!customer[name].data.city || customer[name].data.city === '') {
            addressErrorMessage = 'errors.address.fillCity'
          } else if (!customer[name].data.streetName || customer[name].data.streetName === '') {
            addressErrorMessage = 'errors.address.fillStreetName'
          }
          // else if (!customer[name].data.postalCode || customer[name].data.postalCode === '') {
          //   addressErrorMessage = 'errors.address.fillZipCode'
          // }
          // else if (!customer[name].data.streetNumber || customer[name].data.streetNumber === '') {
          //   addressErrorMessage = 'errors.address.fillStreetNumber'
          // }
        }

        messages[name] = {
          requiredIf: addressErrorMessage,
          max: 'errors.invalidMaxLength'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 800 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      default:
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })

        messages[name] = {
          max: 'invalidMaxLength',
          required: 'errors.required'
        }
        break
    }
  })

  const errors = validator(customer, rules, messages, replaces)

  if (atLeastOneCheck && atLeastOneIsRequiredValues.length === 0) {
    atLeastOneIsRequiredFields.forEach(item => {
      errors.push({
        key: item,
        value: 'errors.requiredOne'
      })
    })
  }

  return errors
}

export const branchesCustomerSaveTransform = customer => {
  const fields = {}
  Object
    .keys(customer)
    .filter(item => item.includes('customerFields'))
    .forEach(item => {
      const field = customer[item]
      const id = item.replace('customerFields', '')
      if (field.type === 'DATE' && field.value) {
        const value = moment.utc(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
        if (value === 'Invalid date') {
          fields[id] = null
          field.value = null
        } else {
          fields[id] = value
        }
      }
      if (field.type === 'PHONE' && field.phone) {
        fields[id] = JSON.stringify({
          number: field.phone,
          country: field.phoneCountry
        })
      }
      if (field.type === 'CHECKBOX') {
        fields[id] = field.value + ''
      }
      if (field.type === 'FILE' && field.defaultId === 'avatar') {
        if (field.value === null) fields[id] = null
        if (field.avatarFile) fields[id] = field.avatarFile
      }
      if (field.type === 'ADDRESS' && field.value) {
        const value = fields[id] ? JSON.parse(fields[id]) : null
        const data = field.data || null
        const newValue = (data && {
          placeId: data && data.placeId,
          latitude: data && data.lat,
          longitude: data && data.lng,
          street: data && data.streetName,
          streetNumber: data && data.streetNumber,
          city: data && data.city,
          country: data && data.country,
          zipCode: data && data.postalCode,
          formatted: data && data.formattedAddress
        }) || null
        // if secondary address come first, we have to pre-fill it
        if (newValue && value && value.details) newValue.details = value.details
        fields[id] = JSON.stringify(newValue)
      }
      if (id.includes('Secondary')) {
        const addressId = id.replace('Secondary', '')
        const newValue = fields[addressId] ? JSON.parse(fields[addressId]) : null
        if (newValue) {
          newValue.details = field.value
          fields[addressId] = JSON.stringify(newValue)
        }
      }
      if (!fields[id] && field.type !== 'FILE') fields[id] = field.value || null
    })

  const result = {
    id: customer.id,
    externalId: customer.externalId.value || null,
    tagIds: customer.tags.values || null,
    fields: Object
      .keys(fields)
      .filter(item => item.length === 24)
      .map(id => ({ id, value: fields[id] }))
  }

  return result
}

export const formValidateEdit = (form, externalIds) => {
  if (!form) return

  const rules = [
    { 'plan.value': ['required', 'max:255'] },
    { 'externalId.value': ['max:255'] }
  ]
  const messages = {
    plan: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    externalId: {
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    plan: {
      required: {
        key: 'FIELD_NAME',
        value: 'Plan'
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    externalId: {
      max: {
        key: 'MAX',
        value: '255'
      }
    }
  }

  const errors = validator(form, rules, messages, replaces)

  const externalIdValue = form.externalId && form.externalId.value
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

// Save
export const createSaveTransform = (form) => {
  const locale = form.locale.value ? form.locale.value.toLowerCase() : 'en-gb'
  const result = {
    branch: {
      externalId: form.externalId.value || null,
      personName: form.personName.value || '',
      name: form.name.value || '',
      email: form.email.value || '',
      phone: {
        country: (form.phone && form.phone.phoneCountry),
        number: (form.phone && form.phone.phone)
      },
      locale,
      timezone: form.timezone.value || 'UTC',
      tagIds: form.tags.values || [],
      plan: form.plan.value || 'CLASSIC',
      info: form.info.value
    }
  }
  if (form.customRegion.value && form.customRegion.value.length > 0) result.branch.customRegion = form.customRegion.value
  return result
}

export const branchesCreateFormServerErrorsTransform = (error) => {
  const errors = []

  if (error.code === 'CompanyExists') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code === 'UnsupportedCustomRegion') {
    errors.push({ key: 'customRegion', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

export const editSaveTransform = form => {
  const { id: companyId, region } = form || {}
  return {
    companyId,
    region,
    branch: {
      externalId: form.externalId.value || null,
      tagIds: (form.tags && form.tags.values) || [],
      region: (form.region && form.region.value),
      plan: form.plan.value || 'CLASSIC',
      info: form.info.value
    }
  }
}

export const branchesEditFormServerErrorsTransform = (error) => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

export const branchDeleteFormValidate = branch => {
  if (!branch) return

  const rules = [
    { 'password.value': ['required'] }
  ]
  const messages = {
    password: {
      required: 'errors.password.required'
    }
  }

  const errors = validator(branch, rules, messages, {})

  return errors
}

export const branchesDeleteFormServerErrorsTransform = (error) => {
  const errors = []

  if (error.code === 'FailedToAuthenticate') {
    errors.push({ key: 'password', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

// ERRORS
export const serverErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// Branches Import
export const branchesImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  }
})

export const branchesImportFormValidate = form => {
  if (!form) return
  const { files } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })

  return errors
}

export const branchesImportFormSaveTransform = (form, company) => {
  const { files } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { id } = company || {}
  const result = {
    enterpriseId: id,
    key
  }
  return result
}

export const branchesImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Resources import
export const branchResourcesImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  },
  myPassword: {
    value: '',
    type: 'password'
  },
  mergeRule: {
    value: 'OVERWRITE'
  }
})

export const branchResourcesImportFormValidate = form => {
  if (!form) return
  const passwordMinLength = 6
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const { files, myPassword } = form || {}
  let { values: filesValues } = files || {}
  const { value: passwordValue } = myPassword || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })
  if (passwordValue.length === 0) errors.push({ key: 'globalErrors', value: 'global.passwordRequired' })
  if (passwordValue.length > passwordMaxLength) errors.push({ key: 'globalErrors', value: 'global.passwordMaxLength.error', replace: [{ key: 'MAX_LENGTH', value: passwordMaxLength }] })
  if (passwordValue.length > 0 && passwordValue.length < passwordMinLength) errors.push({ key: 'globalErrors', value: 'global.passwordMinLength.error', replace: [{ key: 'MIN_LENGTH', value: passwordMinLength }] })

  return errors
}

export const branchResourcesImportFormSaveTransform = (form, branch) => {
  const { files, myPassword, mergeRule } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { value: password } = myPassword || {}
  const { value: mergeRuleValue } = mergeRule || {}
  const { id, region } = branch || {}
  const result = {
    companyId: id,
    region,
    key,
    mergeRule: mergeRuleValue,
    password
  }
  return result
}

export const branchResourcesImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else if (error.code === 'PasswordInvalid') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Resource categories import
export const branchResourceCategoriesImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  },
  myPassword: {
    value: '',
    type: 'password'
  },
  mergeRule: {
    value: 'OVERWRITE'
  }
})

export const branchResourceCategoriesImportFormValidate = form => {
  if (!form) return
  const passwordMinLength = 6
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const { files, myPassword } = form || {}
  const { value: passwordValue } = myPassword || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })
  if (passwordValue.length === 0) errors.push({ key: 'globalErrors', value: 'global.passwordRequired' })
  if (passwordValue.length > passwordMaxLength) errors.push({ key: 'globalErrors', value: 'global.passwordMaxLength.error', replace: [{ key: 'MAX_LENGTH', value: passwordMaxLength }] })
  if (passwordValue.length > 0 && passwordValue.length < passwordMinLength) errors.push({ key: 'globalErrors', value: 'global.passwordMinLength.error', replace: [{ key: 'MIN_LENGTH', value: passwordMinLength }] })

  return errors
}

export const branchResourceCategoriesImportFormSaveTransform = (form, branch) => {
  const { files, myPassword, mergeRule } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  const { value: password } = myPassword || {}
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { value: mergeRuleValue } = mergeRule || {}
  const { id, region } = branch || {}
  const result = {
    companyId: id,
    region,
    key,
    mergeRule: mergeRuleValue,
    password
  }
  return result
}

export const branchResourceCategoriesImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else if (error.code === 'PasswordInvalid') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Branch services import
export const branchServicesImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  },
  mergeRule: {
    value: 'OVERWRITE'
  },
  wantsToBeOnline: {
    value: false
  },
  myPassword: {
    value: '',
    type: 'password'
  }
})

export const branchServicesImportFormValidate = form => {
  if (!form) return
  const passwordMinLength = 6
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const { files, myPassword } = form || {}
  let { values: filesValues } = files || {}
  const { value: passwordValue } = myPassword || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })
  if (passwordValue.length === 0) errors.push({ key: 'globalErrors', value: 'global.passwordRequired' })
  if (passwordValue.length > passwordMaxLength) errors.push({ key: 'globalErrors', value: 'global.passwordMaxLength.error', replace: [{ key: 'MAX_LENGTH', value: passwordMaxLength }] })
  if (passwordValue.length > 0 && passwordValue.length < passwordMinLength) errors.push({ key: 'globalErrors', value: 'global.passwordMinLength.error', replace: [{ key: 'MIN_LENGTH', value: passwordMinLength }] })

  return errors
}

export const branchServicesImportFormSaveTransform = (form, branch) => {
  const {
    files,
    myPassword,
    mergeRule,
    wantsToBeOnline
  } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { value: password } = myPassword || {}
  const { value: mergeRuleValue } = mergeRule || {}
  const { value: wantsToBeOnlineValue } = wantsToBeOnline || {}
  const { id, region } = branch || {}
  const result = {
    companyId: id,
    region,
    key,
    password,
    mergeRule: mergeRuleValue,
    wantsToBeOnline: wantsToBeOnlineValue
  }
  return result
}

export const branchServicesImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else if (error.code === 'PasswordInvalid') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Branch service categories import
export const branchServiceCategoriesImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  },
  mergeRule: {
    value: 'OVERWRITE'
  },
  myPassword: {
    value: '',
    type: 'password'
  }
})

export const branchServiceCategoriesImportFormValidate = form => {
  if (!form) return
  const passwordMinLength = 6
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const { files, myPassword } = form || {}
  let { values: filesValues } = files || {}
  const { value: passwordValue } = myPassword || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })
  if (passwordValue.length === 0) errors.push({ key: 'globalErrors', value: 'global.passwordRequired' })
  if (passwordValue.length > passwordMaxLength) errors.push({ key: 'globalErrors', value: 'global.passwordMaxLength.error', replace: [{ key: 'MAX_LENGTH', value: passwordMaxLength }] })
  if (passwordValue.length > 0 && passwordValue.length < passwordMinLength) errors.push({ key: 'globalErrors', value: 'global.passwordMinLength.error', replace: [{ key: 'MIN_LENGTH', value: passwordMinLength }] })

  return errors
}

export const branchServiceCategoriesImportFormSaveTransform = (form, branch) => {
  const {
    files,
    myPassword,
    mergeRule
  } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { value: password } = myPassword || {}
  const { value: mergeRuleValue } = mergeRule || {}
  const { id, region } = branch || {}
  const result = {
    companyId: id,
    region,
    key,
    password,
    mergeRule: mergeRuleValue
  }
  return result
}

export const branchServiceCategoriesImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else if (error.code === 'PasswordInvalid') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Branch groups import
export const branchGroupsImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  },
  mergeRule: {
    value: 'OVERWRITE'
  },
  wantsToBeOnline: {
    value: false
  },
  myPassword: {
    value: '',
    type: 'password'
  }
})

export const branchGroupsImportFormValidate = form => {
  if (!form) return
  const passwordMinLength = 6
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const { files, myPassword } = form || {}
  let { values: filesValues } = files || {}
  const { value: passwordValue } = myPassword || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })
  if (passwordValue.length === 0) errors.push({ key: 'globalErrors', value: 'global.passwordRequired' })
  if (passwordValue.length > passwordMaxLength) errors.push({ key: 'globalErrors', value: 'global.passwordMaxLength.error', replace: [{ key: 'MAX_LENGTH', value: passwordMaxLength }] })
  if (passwordValue.length > 0 && passwordValue.length < passwordMinLength) errors.push({ key: 'globalErrors', value: 'global.passwordMinLength.error', replace: [{ key: 'MIN_LENGTH', value: passwordMinLength }] })

  return errors
}

export const branchGroupsImportFormSaveTransform = (form, branch) => {
  const {
    files,
    myPassword,
    mergeRule,
    wantsToBeOnline
  } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { value: password } = myPassword || {}
  const { value: mergeRuleValue } = mergeRule || {}
  const { value: wantsToBeOnlineValue } = wantsToBeOnline || {}
  const { id, region } = branch || {}
  const result = {
    companyId: id,
    region,
    key,
    password,
    mergeRule: mergeRuleValue,
    wantsToBeOnline: wantsToBeOnlineValue
  }
  return result
}

export const branchGroupsImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else if (error.code === 'PasswordInvalid') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Branch group categories import
export const branchGroupCategoriesImportFormTransform = () => ({
  files: {
    selected: null,
    values: [],
    rawFiles: [],
    file: null
  },
  mergeRule: {
    value: 'OVERWRITE'
  },
  myPassword: {
    value: '',
    type: 'password'
  }
})

export const branchServiceListTransform = services => {
  if (!services) return
  return services.map(item => ({
    ...item,
    isBatch: (item.durationsPattern || []).length > 1
  }))
}

export const branchGroupCategoriesImportFormValidate = form => {
  if (!form) return
  const passwordMinLength = 6
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const { files, myPassword } = form || {}
  let { values: filesValues } = files || {}
  const { value: passwordValue } = myPassword || {}
  filesValues = filesValues || []
  const errors = []

  if (filesValues.length === 0) errors.push({ key: 'files', value: 'branches.list.importBranches.file.required.error' })
  if (passwordValue.length === 0) errors.push({ key: 'globalErrors', value: 'global.passwordRequired' })
  if (passwordValue.length > passwordMaxLength) errors.push({ key: 'globalErrors', value: 'global.passwordMaxLength.error', replace: [{ key: 'MAX_LENGTH', value: passwordMaxLength }] })
  if (passwordValue.length > 0 && passwordValue.length < passwordMinLength) errors.push({ key: 'globalErrors', value: 'global.passwordMinLength.error', replace: [{ key: 'MIN_LENGTH', value: passwordMinLength }] })

  return errors
}

export const branchGroupCategoriesImportFormSaveTransform = (form, branch) => {
  const {
    files,
    myPassword,
    mergeRule
  } = form || {}
  let { values: filesValues } = files || {}
  filesValues = filesValues || []
  if (filesValues.length === 0) return
  const file = filesValues[0]
  const { key } = file || {}
  const { value: password } = myPassword || {}
  const { value: mergeRuleValue } = mergeRule || {}
  const { id, region } = branch || {}
  const result = {
    companyId: id,
    region,
    key,
    password,
    mergeRule: mergeRuleValue
  }
  return result
}

export const branchGroupCategoriesImportFormServerErrorsTransform = (error, filesErrorMsg = '') => {
  const errors = []

  if (error.code === 'CSVError') {
    const { data } = error || {}
    let { errors: apiErrors } = data || {}
    apiErrors = apiErrors || []
    const fieldsWithErrors = []
    apiErrors.forEach(error => {
      if (error.header) {
        fieldsWithErrors.push(`"${error.header}" field is ${error.message || 'mandatory'}`)
      }
    })
    errors.push({
      key: 'files', value: 'branches.list.importBranches.file.fieldsWithErrors', replace: [{ key: 'FIELDS_WITH_ERRORS', value: fieldsWithErrors.length > 0 ? fieldsWithErrors.join('; ') : '' }]
    })
  } else if (error.code === 'AccountRequestLimit') {
    errors.push({ key: 'requestLimit', value: error.message })
  } else if (error.code === 'PasswordInvalid') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: error.message })
  }

  return errors
}

// Export

export const branchExportFormTransform = ({ branchStatisticsFilter }) => {
  const { year, month } = branchStatisticsFilter || {}

  return {
    year,
    month,
    exportMode: {
      value: 'ALL'
    },
    exportBookings: {
      value: false
    },
    exportCancellations: {
      value: false
    },
    exportFinances: {
      value: false
    },
    exportResources: {
      value: false
    },
    exportCustomers: {
      value: false
    }
  }
}

export const branchExportFormValidate = form => {
  if (!form) return
  const {
    exportMode,
    exportBookings,
    exportCancellations,
    exportFinances,
    exportResources,
    exportCustomers
  } = form || {}
  const exportModeValue = exportMode && exportMode.value
  const exportBookingsValue = exportBookings && exportBookings.value
  const exportCancellationsValue = exportCancellations && exportCancellations.value
  const exportFinancesValue = exportFinances && exportFinances.value
  const exportResourcesValue = exportResources && exportResources.value
  const exportCustomersValue = exportCustomers && exportCustomers.value
  const rules = [
    { 'year.value': ['required'] },
    { 'month.value': ['required'] }
  ]
  const messages = {
    year: {
      required: 'global.year.label'
    },
    month: {
      required: 'global.month.label'
    }
  }
  const errors = validator(form, rules, messages)

  if (
    exportModeValue === 'SOME' &&
    !exportBookingsValue &&
    !exportCancellationsValue &&
    !exportFinancesValue &&
    !exportResourcesValue &&
    !exportCustomersValue
  ) {
    errors.push({ key: 'globalErrors', value: 'errors.statistics.export.categories.atLeastOne' })
  }

  return errors.length && errors
}

export const branchExportFormSaveTransform = (form) => {
  const {
    exportMode,
    exportBookings,
    exportCancellations,
    exportCustomers,
    exportFinances,
    exportResources,
    month,
    year,
    type,
    companyId,
    region
  } = form || {}
  const exportModeValue = exportMode && exportMode.value
  const exportBookingsValue = exportBookings && exportBookings.value
  const exportCancellationsValue = exportCancellations && exportCancellations.value
  const exportCustomersValue = exportCustomers && exportCustomers.value
  const exportFinancesValue = exportFinances && exportFinances.value
  const exportResourcesValue = exportResources && exportResources.value
  const monthValue = month && month.value
  const yearValue = year && year.value
  const typeValue = type && type.value
  let statisticsSections = []
  if (exportModeValue === 'ALL') statisticsSections = ['RESOURCE', 'BOOKING', 'CANCELLATION', 'CUSTOMER', 'REVENUE']
  if (exportModeValue === 'SOME' && exportResourcesValue) statisticsSections.push('RESOURCE')
  if (exportModeValue === 'SOME' && exportBookingsValue) statisticsSections.push('BOOKING')
  if (exportModeValue === 'SOME' && exportCancellationsValue) statisticsSections.push('CANCELLATION')
  if (exportModeValue === 'SOME' && exportCustomersValue) statisticsSections.push('CUSTOMER')
  if (exportModeValue === 'SOME' && exportFinancesValue) statisticsSections.push('REVENUE')
  let statisticsMode = 'MONTHLY'
  if (typeValue === 'lifetime') statisticsMode = 'OVERALL'

  const result = {
    region,
    companyId,
    fileTypeName: getOS() === 'Apple' ? 'CSV' : 'EXCEL',
    statisticsSections,
    filter: {
      month: monthValue,
      year: yearValue,
      statisticsMode
    }
  }
  return result
}

export const branchExportFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }

  return errors
}

export const branchBookingExportFormSaveTransform = (form, company) => {
  const {
    begin: { value: beginValue },
    end: { value: endValue },
    exportMode: { value: exportModeValue },
    exportTemplateCode
  } = form || {}
  const begin = moment(beginValue).format('YYYY-MM-DD')
  const end = moment(endValue).add(1, 'day').format('YYYY-MM-DD')
  const result = {
    dateFrom: begin,
    dateTo: end,
    fileTypeName: exportModeValue,
    exportTemplateCode
  }
  return result
}

export const branchBookingExportFormValidate = form => {
  if (!form) return
  const {
    begin: { value: beginValue },
    end: { value: endValue }
  } = form || {}
  const begin = moment(beginValue).format('YYYY-MM-DD')
  const end = moment(endValue).format('YYYY-MM-DD')
  const rules = [
    { 'begin.value': ['required'] },
    { 'end.value': ['required'] }
  ]
  const messages = {
    begin: {
      required: 'bookings.export.errorIntervalStart'
    },
    end: {
      required: 'bookings.export.errorIntervalEnd'
    }
  }
  const errors = validator(form, rules, messages)
  if (begin && end && begin > end) {
    errors.push({ key: 'globalErrors', value: 'bookings.export.errorIntervalBeginningBeforeEnd' })
  }

  return errors.length && errors
}

export const branchesExportFormTransform = () => {
  const today = moment()
  return {
    begin: {
      value: today
    },
    end: {
      value: today
    },
    exportMode: {
      value: 'CSV'
    }
  }
}
