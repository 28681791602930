import React, { memo } from 'react'
import { connect } from '../../Store'
import { ServiceFormAllowancePlan } from '../../Beauties'

const ServiceFormAllowancePlanWrapper = ({ services }) => {
  let { hasSpecificBookingDays } = services || {}
  hasSpecificBookingDays = hasSpecificBookingDays || {}
  hasSpecificBookingDays = hasSpecificBookingDays.value || false

  return (
    <ServiceFormAllowancePlan {...{ hasSpecificBookingDays }} />
  )
}

const maps = state => ({
  services: state.forms.services
})

export default memo(connect(maps)(ServiceFormAllowancePlanWrapper))
