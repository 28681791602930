import React from 'react'
import PropTypes from 'prop-types'
import {
  FontAwesome5,
  Link,
  Spinner,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../Common'
import { dangerousHTML, truncateText } from '../../../Utils'
import { PopupTriggerWrapper } from '../../../Beasts'

import './SimpleBox.css'

const SimpleBox = props => {
  const {
    headerText,
    extraHeaderText,
    label,
    filterLabel,
    filterLabelMaxLength = 10,
    color,
    isLoading,
    children,
    className,
    headerLabel,
    itemName,
    itemColor,
    headerSettingsButton,
    buttons,
    statisticId,
    isResourceStatistic,
    isOverallStatistic,
    labels = [],
    infoPopup,
    isBig
  } = props
  const availableColors = ['white', 'grey', 'green', 'blue', 'purple', 'none']
  const selectedColor = availableColors.indexOf(color) > -1 ? color : availableColors[0]
  const classNames = ['ta-simple-box']
  if (isBig) classNames.push('large')
  classNames.push(`color-${selectedColor}`)
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {headerText &&
        <div className='ta-simple-box__header'>
          <div>
            {headerText}
            {extraHeaderText && <span className='ta-simple-box__header__extra-text'> {extraHeaderText}</span>}
            <div className='ta-simple-box-label'>
              {headerLabel &&
                <p className='ta-simple-box-label-text'>{headerLabel}</p>
              }
              {labels && labels.map((item, i) => {
                const { icon, position, text, color, textColor } = item
                return (
                  <React.Fragment key={i}>
                    <HoverPopup className='ta-statistics__label'>
                      <HoverPopupContent position={position}>
                        {text}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <div className='ta-statistics-box__label' style={{ background: color }}>
                          <FontAwesome5 icon={icon} type='s' color={textColor} />
                        </div>
                      </HoverPopupTrigger>
                    </HoverPopup>
                    {i !== labels.length - 1 &&
                      <p className='ta-statistics__sign'>+</p>
                    }
                  </React.Fragment>
                )
              })}
              {filterLabel &&
                <div className='ta-simple-box-label-button' style={{ background: itemColor }}>{truncateText(itemName || filterLabel, filterLabelMaxLength, true)}</div>
              }
            </div>
          </div>
          {headerSettingsButton &&
            <PopupTriggerWrapper
              name={`${headerSettingsButton}-statistics-settings`}
              position='left'
              extraClassName='ta-statistics-box'
              id={{ statisticId, isResourceStatistic, isOverallStatistic, customContentProps: { width: 216 } }}
            >
              <FontAwesome5 icon='filter' type='s' />
            </PopupTriggerWrapper>
          }
          {buttons && buttons}
        </div>
      }
      {label}
      {infoPopup &&
        <HoverPopup className='ta-statistics__info-popup'>
          <HoverPopupContent position={infoPopup.position}>
            {dangerousHTML(infoPopup.text)}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className='ta-statistics-box__popup-icon'>
              <FontAwesome5 icon='info' type='solid' />
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
      }
      <div className='ta-simple-box__content'>{isLoading ? <Spinner /> : children}</div>
    </div>
  )
}

SimpleBox.propTypes = {
  label: PropTypes.string,
  headerText: PropTypes.any
}

export default SimpleBox
