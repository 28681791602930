import React from 'react'
import {
  FormSection,
  SectionTitle,
  Row,
  Col,
  BorderedBox,
  Checkbox,
  FormText,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { ServiceRemindersSelect, ServiceOtherRecipientsSelect } from '../../../Beauties'
import './ServiceForm.css'

const ServiceFormReminders = props => {
  const {
    serviceCustomReminderCustomerSwitch,
    serviceReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    serviceCustomReminderStaffSwitch,
    serviceReminderStaffSwitch,
    serviceReminderStaffOthersCheckBox,
    serviceReminderStaffOthers,
    resourceEmailRemindersMinutes,
    settingsResourceRemindersEmailRecipients,
    settingsCustomerEmailRemindersMinutes
  } = props

  return (
    <FormSection ignoreBottomOffset>
      <SectionTitle label={t('servicesGroups.reminder.heading')} icon='bell' ignoreTopOffset />
      <Row>
        <Col>
          <Row>
            <Col>
              <FormText noOffset>{dangerousHTML(t('servicesGroups.reminder.questionText'))}</FormText>
            </Col>
          </Row>
          {(settingsCustomerEmailRemindersMinutes && settingsCustomerEmailRemindersMinutes.length > 0 && (settingsCustomerEmailRemindersMinutes.length !== 1 || settingsCustomerEmailRemindersMinutes[0] !== 0) &&
            <>
              <Row>
                <Col>
                  <Checkbox name='serviceCustomReminderCustomerSwitch' label={t('servicesGroups.reminder.customers.custom.checkbox.label')} theme='switch' />
                </Col>
              </Row>
              {(serviceCustomReminderCustomerSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <Checkbox name='serviceReminderCustomerSwitch' label={t('servicesGroups.reminder.customers.checkbox.label')} theme='switch' />
                    </Col>
                  </Row>
                  {(serviceReminderCustomerSwitch &&
                    <Row className='ta-row__no-margin'>
                      <Col>
                        <ServiceRemindersSelect
                          formName='services'
                          reminders={customerEmailRemindersMinutes}
                          name='customerEmailRemindersMinutes'
                        />
                      </Col>
                    </Row>
                  )}
                </BorderedBox>
              )}
            </>
          )}
          {(!!(settingsResourceRemindersEmailRecipients && settingsResourceRemindersEmailRecipients.length) &&
            <>
              <Row>
                <Col>
                  <Checkbox name='serviceCustomReminderStaffSwitch' label={t('servicesGroups.reminder.staff.custom.checkbox.label')} theme='switch' />
                </Col>
              </Row>
              {(serviceCustomReminderStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <Checkbox name='serviceReminderStaffSwitch' label={t('servicesGroups.reminder.staff.checkbox.label')} theme='switch' />
                    </Col>
                  </Row>
                  {(serviceReminderStaffSwitch &&
                    <BorderedBox>
                      <Row>
                        <Col>
                          <BorderedBox className='ta-services__form--outer-box'>
                            <Row>
                              <Col>
                                <Checkbox disabled name='serviceReminderStaffOwnerCheckBox' label={t('global.owner')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox disabled name='serviceReminderStaffResourcesCheckBox' label={t('servicesGroups.resources')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox disabled name='serviceReminderStaffOthersCheckBox' label={t('servicesGroups.others')} />
                              </Col>
                            </Row>
                            {(serviceReminderStaffOthersCheckBox &&
                              <ServiceOtherRecipientsSelect
                                recipients={serviceReminderStaffOthers}
                                name='serviceReminderStaffOthers'
                              />
                            )}
                          </BorderedBox>
                        </Col>
                      </Row>
                      <ServiceRemindersSelect
                        formName='services'
                        reminders={resourceEmailRemindersMinutes}
                        name='resourceEmailRemindersMinutes'
                      />
                    </BorderedBox>
                  )}
                </BorderedBox>
              )}
            </>
          )}
        </Col>
      </Row>
    </FormSection>
  )
}

export default ServiceFormReminders
