import React from 'react'
import {
  SectionTitle,
  ListItem,
  FormSection,
  t
} from '../../../Common'
import {
  MaximumLeadTimeMonthsExample,
  MinimumLeadTimeHoursExample,
  SnapToGridIntervalExample
} from '../../../Beauties'
import { SettingsBookingPreviewIcsFileCustomisationWrapper, SettingsBookingPreviewServiceAllocationsWrapper } from '../../../Beasts'
import { dangerousHTML } from '../../../Utils'

const BookingSettingsBooking = props => {
  let {
    maximumLeadTime,
    minimumLeadTime,
    cancellationLeadTime,
    enforceResourcesAllowanceType,
    enforceReschedulingTagRule,
    snapToGridInterval,
    internalSnapToGridInterval,
    hideFastWidgetTimeFrame,
    tags,
    rawSettings,
    servicesAllocation,
    icsFileCustomisation,
    customisations
  } = props
  tags = tags || []
  maximumLeadTime = maximumLeadTime || {}
  minimumLeadTime = minimumLeadTime || {}
  servicesAllocation = servicesAllocation || {}
  let { allocations: serviceAllocations, settings: serviceAllocationsSettings } = servicesAllocation || {}
  serviceAllocations = serviceAllocations || []
  serviceAllocationsSettings = serviceAllocationsSettings || {}
  const hasServiceAllocations = serviceAllocations.length > 0

  cancellationLeadTime = cancellationLeadTime || { value: -1 }
  const selectedTag = tags.find(item => item.id === enforceReschedulingTagRule) || {}
  let snapToGridIntervalValue = 'serviceLength'
  if (snapToGridInterval === 15) snapToGridIntervalValue = 'quarterly'
  if (snapToGridInterval === 30) snapToGridIntervalValue = 'halfHourly'
  if (snapToGridInterval === 60) snapToGridIntervalValue = 'hourly'
  let internalSnapToGridIntervalValue = 'serviceLength'
  if (internalSnapToGridInterval === 15) internalSnapToGridIntervalValue = 'quarterly'
  if (internalSnapToGridInterval === 30) internalSnapToGridIntervalValue = 'halfHourly'
  if (internalSnapToGridInterval === 60) internalSnapToGridIntervalValue = 'hourly'
  let maximumLeadTimeDurationKey = 'global.months.counter'
  if (maximumLeadTime.frequency === 'WEEKS') maximumLeadTimeDurationKey = 'global.weeks.counter'
  let minimumLeadTimeDurationKey = 'global.hours.counter'
  if (minimumLeadTime.frequency === 'DAYS') minimumLeadTimeDurationKey = 'global.days.counter'
  let cancellationLeadTimeDurationKey = 'global.hours.counter'
  if (cancellationLeadTime.frequency === 'DAYS') cancellationLeadTimeDurationKey = 'global.days.counter'
  // Customisations
  const { settings } = customisations || {}
  const { hideBookingSettingsServiceAllocations, hideBookingSettingsIcsFileCustomisation } = settings || {}
  const { icsCustomisationCustomer, icsCustomisationResource } = icsFileCustomisation || {}
  const hasIcsFileCustomisationCustomerSetting = (
    icsCustomisationCustomer &&
    icsCustomisationCustomer.customerIcsAddress &&
    icsCustomisationCustomer.customerIcsNotes &&
    icsCustomisationCustomer.customerIcsSubject
  )
  const hasIcsFileCustomisationResourceSetting = (
    icsCustomisationResource &&
    icsCustomisationResource.resourceIcsAddress &&
    icsCustomisationResource.resourceIcsNotes &&
    icsCustomisationResource.resourceIcsSubject
  )
  const hasIcsFileCustomisation = hasIcsFileCustomisationCustomerSetting || hasIcsFileCustomisationResourceSetting

  return (
    <div className='ta-booking-settings__booking'>
      <FormSection>
        <SectionTitle label={t('settings.section.maxLeadTime.heading')} icon='arrows-h' />
        <ListItem>
          {((rawSettings.maximumLeadTime === null || (rawSettings.maximumLeadTime && rawSettings.maximumLeadTime.value === null)) &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {dangerousHTML(t('settings.section.booking.maxLeadTime.infoText', [
            { key: 'MAXIMUM_LEAD_TIME', value: maximumLeadTime.value },
            { key: 'MAXIMUM_LEAD_TIME_DURATION', value: maximumLeadTimeDurationKey, translateReplace: true }
          ]))}
        </ListItem>
        <MaximumLeadTimeMonthsExample value={maximumLeadTime.value} />
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.minLeadTime.heading')} icon='clock' />
        <ListItem>
          {((rawSettings.minimumLeadTime === null || (rawSettings.minimumLeadTime && rawSettings.minimumLeadTime.value === null)) &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {dangerousHTML(t('settings.section.booking.minLeadTime.infoText', [
            { key: 'MINIMUM_LEAD_TIME', value: minimumLeadTime.value || '0' },
            { key: 'MINIMUM_LEAD_TIME_DURATION', value: minimumLeadTimeDurationKey, translateReplace: true }
          ]))}
        </ListItem>
        <MinimumLeadTimeHoursExample value={minimumLeadTime.value} duration={minimumLeadTime.frequency} />
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.cancellation.heading')} icon='ban' />
        <ListItem>
          {(rawSettings.cancellationLeadTime === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {cancellationLeadTime.value === -1 && dangerousHTML(t('settings.section.booking.cancelationLeadTime.disabled.infoText'))}
          {cancellationLeadTime.value === 0 && dangerousHTML(t('settings.section.booking.cancelationLeadTime.anyTime.infoText'))}
          {cancellationLeadTime.value > 0 && dangerousHTML(t('settings.section.booking.cancelationLeadTime.specificTime.infoText', [
            { key: 'CANCELATION_LEAD_TIME', value: cancellationLeadTime.value },
            { key: 'CANCELATION_LEAD_TIME_DURATION', value: cancellationLeadTimeDurationKey, translateReplace: true }
          ]))}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.enforceReschedulingTagRule.heading')} icon='calendar' />
        <ListItem>
          {(rawSettings.enforceReschedulingTagRule === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          <strong>
            {enforceReschedulingTagRule === 'NONE' && t('settings.section.enforceReschedulingTagRule.none')}
            {enforceReschedulingTagRule && enforceReschedulingTagRule !== 'NONE' && selectedTag.name}
          </strong>
          {enforceReschedulingTagRule && <br />}
          {(enforceReschedulingTagRule === 'NONE' || rawSettings.enforceReschedulingTagRule === null) && t('settings.section.enforceReschedulingTagRule.absent')}
          {enforceReschedulingTagRule && enforceReschedulingTagRule !== 'NONE' && t('settings.section.enforceReschedulingTagRule.infoText.tag')}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.resourceAvailability.heading')} icon='calendar' />
        <ListItem>
          {(rawSettings.enforceResourcesAllowanceType === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          <strong>
            {!enforceResourcesAllowanceType && t('settings.internalReschedulingRules.noRules.text')}
            {enforceResourcesAllowanceType === 'NONE' && <>{dangerousHTML(t('settings.internalReschedulingRules.noRules'))}</>}
            {enforceResourcesAllowanceType === 'WORKING' && t('settings.section.internalSchedulingRules.infoText.working')}
            {enforceResourcesAllowanceType === 'BOOKING' && t('settings.section.internalSchedulingRules.infoText.booking')}
          </strong>
          {(enforceResourcesAllowanceType === 'NONE' &&
            t('settings.internalReschedulingRules.outside')
          )}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.bookingSlots.heading')} icon='th' />
        <ListItem>
          {(rawSettings.snapToGridInterval === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          <strong>
            {snapToGridInterval !== 0 ? `${snapToGridInterval} minute(s)` : t('settings.section.bookingSlots.radioServiceLength')}
          </strong>
          <br />
          {snapToGridInterval === 0 && t('settings.section.bookingSlots.serviceLength.infoText')}
          {(snapToGridInterval > 0 &&
            t('settings.section.bookingSlots.everyMin.infoText', [{ key: 'SNAP_TO_GRID_INTERVAL', value: snapToGridInterval }])
          )}
        </ListItem>
        <SnapToGridIntervalExample value={snapToGridIntervalValue} label={t('settings.section.bookingSlots.example')} />
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.internalBookingSlots.heading')} icon='th' />
        <ListItem>
          {(rawSettings.internalSnapToGridInterval === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          <strong>
            {internalSnapToGridInterval !== 0 ? `${internalSnapToGridInterval} minute(s)` : t('settings.section.bookingSlots.radioServiceLength')}
          </strong>
          <br />
          {(internalSnapToGridInterval === 0 &&
            t('settings.section.internalBookingSlots.serviceLength.infoText')
          )}
          {(internalSnapToGridInterval > 0 &&
            t('settings.section.internalBookingSlots.everyMin.infoText', [{ key: 'SNAP_TO_GRID_INTERVAL', value: internalSnapToGridInterval }])
          )}
        </ListItem>
        <SnapToGridIntervalExample value={internalSnapToGridIntervalValue} label={t('settings.section.bookingSlots.example')} />
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.internalBookingPreferredTimeframe.heading')} icon='calendar-check' />
        <ListItem>
          {(rawSettings.hideFastWidgetTimeFrame === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          <strong>{hideFastWidgetTimeFrame ? t('global.disabled') : t('global.enabled')}</strong>
          <br />
          {(hideFastWidgetTimeFrame
            ? t('settings.section.internalBookingPreferredTimeframe.disabledText')
            : t('settings.section.internalBookingPreferredTimeframe.enabledText')
          )}
        </ListItem>
      </FormSection>
      {(!hideBookingSettingsServiceAllocations &&
        <FormSection className='service-allocations'>
          <SectionTitle label={t('settings.section.serviceAllocations.title')} icon='sort-numeric-down-alt' />
          {(rawSettings.servicesAllocation === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {(hasServiceAllocations &&
            <SettingsBookingPreviewServiceAllocationsWrapper
              settings={serviceAllocationsSettings}
              serviceAllocations={serviceAllocations}
            />
          )}
          {(!hasServiceAllocations &&
            <ListItem>
              <strong>{t('global.disabled')}</strong>
            </ListItem>
          )}
        </FormSection>
      )}
      {(!hideBookingSettingsIcsFileCustomisation &&
        <FormSection className='ics-file-customisation'>
          <SectionTitle label={t('settings.section.icsFileCustomisation.title')} icon='file-alt' />
          <ListItem className='no-offset'>
            <strong>{t(hasIcsFileCustomisation ? 'global.enabled' : 'global.disabled')}</strong>
          </ListItem>
          {(hasIcsFileCustomisation &&
            <SettingsBookingPreviewIcsFileCustomisationWrapper />
          )}
        </FormSection>
        )}
    </div>
  )
}

export default BookingSettingsBooking
