import React, { useEffect } from 'react'
import { connect, globalActions, selectors } from '../../../Store'
import { EmailSettings } from '../../../Beauties'

const EmailSettingsWrapper = props => {
  let {
    settings,
    rawSettings,
    account,
    categoriesList,
    customisations
  } = props
  settings = settings || {}
  rawSettings = rawSettings || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsRead, settingsWrite } = enterprisePermissions || {}
  settingsRead = !!isAdmin || !!settingsRead
  settingsWrite = !!isAdmin || !!settingsWrite

  useEffect(() => {
    const fetchNeededData = async () => {
      await globalActions.populateEnterpriseResourceCategories()
    }
    if (settingsRead) fetchNeededData()
  }, [settingsRead])

  return (
    <EmailSettings
      settingsRead={settingsRead}
      settingsWrite={settingsWrite}
      categoriesList={categoriesList}
      rawSettings={rawSettings}
      customisations={customisations}
      {...settings}
    />
  )
}

const maps = state => ({
  settings: state.company.settings,
  rawSettings: state.company.rawSettings,
  account: state.account,
  customisations: selectors.customisationsSelector(state),
  categoriesList: state.resources.categoriesList || []
})

export default connect(maps)(EmailSettingsWrapper)
