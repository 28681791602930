import { handlers } from '../../../Store'
import { PopupAttributeDelete } from '../../../Beauties'
import { useEffect } from 'react'

export const PopupAttributeDeleteWrapper = ({ id, closePopup }) => {
  useEffect(() => {
    handlers.formSet('attributeDelete', {})
  }, [])

  const approve = cb => handlers.attributesDeleteFormSubmit(id, errors => cb?.(errors))
  const reject = () => closePopup?.()

  return (
    <PopupAttributeDelete approve={approve} reject={reject} />
  )
}

export default PopupAttributeDeleteWrapper
