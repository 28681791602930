import React from 'react'
import { Alert, Link, t } from '../../Common'

export default () => {
  return (
    <div className='ta-route-not-found'>
      <Alert noOffset label={t('global.pageNotFound')} theme='no-content'>
        <Link to='/' className='ta-btn ta-btn-primary'>Back to home</Link>
      </Alert>
    </div>
  )
}
