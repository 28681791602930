import React from 'react'
import {
  DottedTimelineItem,
  IconListItemTitle,
  IconListItemBody,
  DottedTimeline,
  SectionTitle,
  IconListItem,
  FontAwesome5,
  FormSection,
  Price,
  Row,
  Col,
  t
} from '../../../Common'
import { ServicePreviewResources, ServicePreviewServices } from '../../../Beauties'
import { convertMinutesToDaysHoursMinutes, dangerousHTML, extractFromHtml } from '../../../Utils'

const ServiceDetails = props => {
  const {
    id,
    externalId,
    category,
    description,
    duration,
    durationsPattern,
    dependenciesResources,
    dependenciesCategories,
    durationBefore,
    durationAfter,
    price,
    color,
    hasOnlinePayment,
    isPaymentMandatory,
    isBookable,
    isCombination,
    combinationServiceIds,
    areAllResourcesSelected,
    allServices,
    permissionTypes
  } = props
  const translations = {
    minutes: t('global.minutes'),
    hours: t('global.hours'),
    days: t('global.days')
  }
  const categoryName = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
  const combinationServices = isCombination
    ? combinationServiceIds.map(item => allServices.find(service => service.id === item)).filter(Boolean)
    : []

  return (
    <div className='ta-services__preview__details'>
      {(extractFromHtml(description) &&
        <div className='ta-services__preview__details__description'>
          <SectionTitle icon='sticky-note' label={t('servicesGroups.preview.description')} ignoreTopOffset />
          {dangerousHTML(description, false, true)}
        </div>
      )}
      <SectionTitle icon='sliders-h' label={t('global.details')} ignoreTopOffset />
      <IconListItem icon='id-badge' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.serviceID')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>{id}</IconListItemBody>
      </IconListItem>
      {(externalId &&
        <IconListItem icon='fingerprint' type='regular'>
          <IconListItemTitle width='130px'>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      {(categoryName &&
        <IconListItem icon='folder' iconType='regular'>
          <IconListItemTitle width='130px'>{t('global.category')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>{categoryName}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon='hourglass-half' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.duration')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>
          {duration > 0 && convertMinutesToDaysHoursMinutes(duration, translations)}
          {(durationsPattern && durationsPattern.length > 1 &&
            <DottedTimeline>
              {durationsPattern.map((item, index) => {
                const label = t(index % 2 === 0 ? 'global.interval' : 'global.gap')
                return (
                  <div key={index}>
                    <DottedTimelineItem type='big'><strong>{label}</strong></DottedTimelineItem>
                    <DottedTimelineItem type='small' className={index === durationsPattern.length - 1 ? 'last' : ''}>
                      {convertMinutesToDaysHoursMinutes(item, translations)}
                    </DottedTimelineItem>
                  </div>
                )
              })}
            </DottedTimeline>
          )}
        </IconListItemBody>
      </IconListItem>
      {(durationBefore > 0 &&
        <IconListItem icon='hourglass-start' iconType='regular'>
          <IconListItemTitle width='130px'>{t('global.duration.preparationTime')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>{convertMinutesToDaysHoursMinutes(durationBefore, translations)}</IconListItemBody>
        </IconListItem>
      )}
      {(durationAfter > 0 &&
        <IconListItem icon='hourglass-end' iconType='regular'>
          <IconListItemTitle width='130px'>{t('global.duration.followUpTime')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>{convertMinutesToDaysHoursMinutes(durationAfter, translations)}</IconListItemBody>
        </IconListItem>
      )}
      {(price > 0 &&
        <IconListItem icon='money-bill' iconType='regular'>
          <IconListItemTitle width='130px'>{t('global.price.label')}:</IconListItemTitle>
          <IconListItemBody offset='130px'><Price price={price} /></IconListItemBody>
        </IconListItem>
      )}
      {(color && !isCombination &&
        <IconListItem icon='eye-dropper' iconType='regular'>
          <IconListItemTitle width='130px'>{t('global.color')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>
            <FontAwesome5 icon='square' type='solid' color={color} className='ta-services__preview__details__color' />
          </IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon='cc-stripe' iconType='brand'>
        <IconListItemTitle width='130px'>{t('global.onlinePayment')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>
          {hasOnlinePayment ? t('global.enabled') : t('global.disabled')}
          {(hasOnlinePayment &&
            <span> &amp; {isPaymentMandatory ? t('global.mandatory').toString().toLowerCase() : t('global.notMandatory').toString().toLowerCase()}</span>
          )}
        </IconListItemBody>
      </IconListItem>
      <IconListItem icon={isBookable ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='130px'>{t('global.status')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>
          {t('servicesGroups.preview.status', [{ key: 'STATUS', value: isBookable ? t('global.enabled') : t('global.disabled') }])}
        </IconListItemBody>
      </IconListItem>
      <Row>
        <Col>
          {(areAllResourcesSelected &&
            <>
              <SectionTitle icon='cube' label={t('global.resources')} />
              <ServicePreviewResources resources='all' />
            </>
          )}
          {!areAllResourcesSelected && dependenciesCategories.map(item => {
            const resources = dependenciesResources.filter(resource => resource.categoryId === item.id) || []
            const categoryName = item.name === 'default' ? t('resources.list.sectionDefault.title') : item.name
            return (
              <div key={item.id}>
                <SectionTitle icon='cube' label={categoryName} />
                <ServicePreviewResources
                  resources={item.hasAllResources ? 'allPerCategory' : resources}
                  permissionTypes={permissionTypes}
                  categoryName={categoryName}
                />
              </div>
            )
          })}
        </Col>
      </Row>
      {(isCombination &&
        <FormSection>
          <SectionTitle label={t('global.services')} icon='list' />
          <ServicePreviewServices services={combinationServices} />
        </FormSection>
      )}
    </div>
  )
}

export default ServiceDetails
