import React from 'react'
import PropTypes from 'prop-types'
import {
  SimpleTabsHeaderItem,
  FixedContentHeader,
  SimpleTabsBodyItem,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FontAwesome5,
  FixedContent,
  SimpleTabs,
  HoverPopup,
  Alert,
  Title,
  Blur,
  Link,
  t
} from '../../../Common'
import {
  PopupTriggerWrapper,
  ServicePreviewDetailsWrapper,
  ServicePreviewChangedLocallyWrapper,
  ServicePreviewAdvancedWrapper,
  ServicePreviewAvailabilityWrapper
} from '../../../Beasts'

import './ServicePreview.css'

const ServicePreview = props => {
  const {
    service,
    branches,
    hash,
    message,
    globalServicesRead,
    globalServicesWrite,
    globalServicesDelete
  } = props
  const {
    id,
    name,
    locallyUpdatedBranches,
    customerEmailRemindersMinutes,
    resourceEmailRemindersMinutes,
    isCombination,
    isGloballyDeleted,
    hasSpecificBookingDays,
    calendar
  } = service || {}

  const hasCustomerReminders = !!customerEmailRemindersMinutes
  const hasResourceReminders = !!resourceEmailRemindersMinutes

  const availableTabs = ['summary', 'changedLocally']
  if (hasSpecificBookingDays && calendar && calendar.bookingWeeklyAllowancePlan) availableTabs.push('availability')
  if (hasCustomerReminders || hasResourceReminders) availableTabs.push('advanced')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-services__preview']
  const edited = !!Object.keys(locallyUpdatedBranches || {})
    .filter(item => !Object.keys(locallyUpdatedBranches[item])
      .includes('locallyUpdatedServiceIds')
    ).length
  if (edited) classNames.push('edited global')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const title = globalServicesRead ? name || t('global.details') : t('global.accessDenied')
  const hasChangesMadeByProhibitedBranches = Object.keys(locallyUpdatedBranches || {}).some(id => !branches.map(({ id }) => id).includes(id))

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-services__title'>
            <Title label={title}>
              {(globalServicesWrite && id &&
                <>
                  {(globalServicesDelete &&
                    <PopupTriggerWrapper name='services-delete' position='bottom' id={id}>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('servicesGroups.service.buttonHeading.deleteTooltip')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                            <FontAwesome5 icon='trash' />
                          </Link>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </PopupTriggerWrapper>
                  )}
                  {(edited && (
                    <HoverPopup disabled={hasChangesMadeByProhibitedBranches}>
                      <HoverPopupContent position='left' autoSize>
                        {t('globalEntities.service.buttonHeading.restoreTooltip', [
                          { key: 'ELEMENT', value: t('global.service') }
                        ])}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link
                          to={`/management/services/${id}/branches/restore`}
                          className={`ta-btn ta-btn-delete ta-btn-icon-only ${hasChangesMadeByProhibitedBranches ? 'disabled' : ''}`}
                          disabled={hasChangesMadeByProhibitedBranches}
                        >
                          <FontAwesome5 icon='cloud-upload' type='s' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  ))}
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('servicesGroups.service.buttonHeading.editTooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        to={`/management/services/${id}/${isCombination
                          ? `edit-combination@@${hash}`
                          : `edit@@${hash}`}`}
                        className='ta-btn ta-btn-primary ta-btn-icon-only'
                      >
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalServicesRead ? 'inactive' : ''} active={!globalServicesRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && !isGloballyDeleted &&
              <Alert
                className='ta-service__alert-height-fix'
                theme='alert'
                label={
                  t('globalEntities.locallyEdittedWarning.title', [
                    { key: 'ELEMENT', value: isCombination ? t('global.serviceCombination') : t('global.service') }
                  ])
                }
              >
                {t('globalEntities.preview.locallyEdittedWarning.message', [
                  { key: 'ELEMENT', value: isCombination ? t('global.serviceCombination') : t('global.service') }
                ])}
              </Alert>
            )}
            {(isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                {t('globalEntities.preview.removedFromDatabase')}
                <PopupTriggerWrapper name='services-delete-global-entity' position='right' id={id}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(service.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem
                    name='summary' icon='list-ul' label={t('global.summary')}
                  />
                  {((hasSpecificBookingDays && calendar && calendar.bookingWeeklyAllowancePlan) &&
                    <SimpleTabsHeaderItem
                      name='availability'
                      icon='clock'
                      label={t('global.availability')}
                      tooltipPosition='bottom'
                    />
                    )}
                  {((hasCustomerReminders || hasResourceReminders) &&
                    <SimpleTabsHeaderItem
                      name='advanced'
                      icon='cogs'
                      label={t('global.advanced')}
                      tooltipPosition='bottom'
                    />
                  )}
                  {(edited &&
                    <SimpleTabsHeaderItem
                      name='changedLocally'
                      icon='pencil'
                      label={t('global.changedLocally')}
                    />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalServicesRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='summary'>
                    <ServicePreviewDetailsWrapper service={service} />
                  </SimpleTabsBodyItem>
                  {((hasSpecificBookingDays && calendar && calendar.bookingWeeklyAllowancePlan) &&
                    <SimpleTabsBodyItem name='availability'>
                      <ServicePreviewAvailabilityWrapper service={service} />
                    </SimpleTabsBodyItem>
                  )}
                  {((hasCustomerReminders || hasResourceReminders) &&
                    <SimpleTabsBodyItem name='advanced'>
                      <ServicePreviewAdvancedWrapper service={service} />
                    </SimpleTabsBodyItem>
                  )}
                  <SimpleTabsBodyItem name='changedLocally'>
                    <ServicePreviewChangedLocallyWrapper />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

ServicePreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default ServicePreview
