import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FormSection,
  Error,
  Form,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  t
} from '../../../../Common'
import {
  BranchesServiceFormButtons,
  BranchesServiceFormAdvanced,
  BranchesServiceCombinationFormSummary
} from '../../../../Beauties'

import './BranchesServiceCombinationForm.css'

const BranchesServiceCombinationForm = props => {
  const {
    title,
    onSubmit,
    cancelLink,
    showExternalIds,
    plan,
    customisations,
    hash,
    type,
    allowedSet
  } = props
  const availableTabs = ['summary', 'advanced']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]

  return (
    <div className='ta-services__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesService'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='summary' icon='list-ul' label={t('global.summary')} />
                  <SimpleTabsHeaderItem
                    name='advanced'
                    icon='cogs'
                    label={t('global.advanced')}
                    disable={plan !== 'ENTERPRISE'}
                    tooltipText={plan !== 'ENTERPRISE' && t('global.enterpriseOnly')}
                    tooltipPosition='bottom'
                  />
                </SimpleTabsHeader>
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='summary'>
                  <BranchesServiceCombinationFormSummary
                    showExternalIds={showExternalIds}
                    plan={plan}
                    customisations={customisations}
                    type={type}
                    allowedSet={allowedSet}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='advanced'>
                  <BranchesServiceFormAdvanced plan={plan} allowedSet={allowedSet} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <BranchesServiceFormButtons disabled={!allowedSet.length} cancelLink={cancelLink} />
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesServiceCombinationForm
