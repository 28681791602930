import React from 'react'
import {
  FormSection,
  SectionTitle,
  Row,
  Col,
  Checkbox,
  FontAwesome5,
  Link,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'

const ServiceFormSettings = props => {
  const { isCombination } = props

  return (
    <FormSection>
      <SectionTitle label={t('global.settings')} icon='cogs' />
      {(isCombination &&
        <Row>
          <Col>
            <Checkbox
              name='hasCombinationSameResourcesPreference'
              label={t('servicesGroups.form.service.section.settings.serviceCombinationSameResource.label')}
            />
          </Col>
          <Col className='vertical-center'>
            <PopupTriggerWrapper name='services-combination-same-resource' position='top'>
              <Link className='btn-small-icon-only' external>
                <FontAwesome5 icon='question-circle' type='regular' />
              </Link>
            </PopupTriggerWrapper>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Checkbox name='isActive' label={t('servicesGroups.form.service.section.settings.checkboxOnlineBooking.label')} />
        </Col>
      </Row>
    </FormSection>
  )
}

export default ServiceFormSettings
