import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { CustomerFieldForm } from '../../Beauties'

const CustomerFieldEditWrapper = props => {
  let {
    id,
    pending,
    form,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist
  } = props
  id = id || ''
  pending = !!pending
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
  showExternalIds = !!showExternalIds
  form = form || {}
  let {
    isPublished,
    isDefault,
    isActive,
    isMandatoryOffline,
    hasOverwrite,
    type,
    defaultId,
    customisations,
    location
  } = form || {}
  let { value: isPublishedValue, disabled: isPublishedDisabled } = isPublished || {}
  isPublishedDisabled = !!isPublishedDisabled
  let { value: isDefaultValue } = isDefault || {}
  isDefaultValue = !!isDefaultValue
  let { value: isActiveValue, disabled: isActiveDisabled } = isActive || {}
  isActiveDisabled = !!isActiveDisabled
  let { value: isMandatoryOfflineValue } = isMandatoryOffline || {}
  isMandatoryOfflineValue = !!isMandatoryOfflineValue
  let { value: hasOverwriteValue } = hasOverwrite || {}
  hasOverwriteValue = !!hasOverwriteValue
  let { value: typeValue } = type || {}
  typeValue = typeValue || ''
  defaultId = defaultId || null
  let { courses, services } = form || {}
  services = services || {}
  courses = courses || {}
  let { options: servicesOptions } = services || {}
  servicesOptions = servicesOptions || []
  let { options: coursesOptions } = courses || {}
  coursesOptions = coursesOptions || []
  coursesOptions = (coursesOptions || []).map(item => ({
    ...item,
    name: item.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : item.name
  }))
  servicesOptions = (servicesOptions || []).map(item => ({
    ...item,
    name: item.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : item.name
  }))
  const isNameField = ['firstName', 'lastName', 'company'].includes(defaultId)

  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.customerFieldFormGet(id)
  }, [initial])

  const onSubmit = cb => {
    handlers.customerFieldFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return pending
    ? <Loader active />
    : <CustomerFieldForm
        id={id}
        title={t('customerFields.edit.heading')}
        formType='edit'
        cancelLink={`/customers/customer-fields/${id}`}
        onSubmit={onSubmit}
        courses={coursesOptions}
        services={servicesOptions}
        isDefault={isDefaultValue}
        isActive={isActiveValue}
        isActiveDisabled={isActiveDisabled}
        isPublished={isPublishedValue}
        isPublishedDisabled={isPublishedDisabled}
        isMandatoryOffline={isMandatoryOfflineValue}
        isNameField={isNameField}
        hasOverwrite={hasOverwriteValue}
        type={typeValue}
        defaultId={defaultId}
        showExternalIds={showExternalIds}
        allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        customisations={customisations}
        isAdmin={isAdmin}
        branchesAccessBlacklist={branchesAccessBlacklist}
        location={location && location.values}
      />
}

CustomerFieldEditWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.id,
  pending: state.customerFields.pendingForm,
  form: state.forms.customerFields,
  showExternalIds: state.company.settings.showExternalIds,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers,
  customisations: selectors.customisationsSelector(state),
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default connect(maps)(CustomerFieldEditWrapper)
