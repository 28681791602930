import React from 'react'
import {
  FixedContentHeader,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  FixedContent,
  FontAwesome5,
  HoverPopup,
  Title,
  Link,
  Blur,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  UserPermissionsLimitBanner,
  UserPermissionsItem
} from '../../Beauties'

import './UserPermissions.css'
const PERMISSION_TYPES_LIMIT = 15
const userPermissionsMock = [
  {
    color: '#cb3b43',
    icon: 'user-shield',
    id: 'ADMIN',
    isGlobal: true,
    name: 'Admin'
  },
  {
    color: '#cb3b43',
    icon: 'user-clock',
    id: 'ADMIN',
    isGlobal: true,
    name: 'Employees'
  },
  {
    color: '#cb3b43',
    icon: 'user-nurse',
    id: 'ADMIN',
    isGlobal: true,
    name: 'Nurses'
  },
  {
    color: '#cb3b43',
    icon: 'user-shield',
    id: 'ADMIN',
    isGlobal: true,
    name: 'Security'
  },
  {
    color: '#cb3b43',
    icon: 'user-tie',
    id: 'ADMIN',
    isGlobal: true,
    name: 'Executives'
  }
]

const UserPermissions = props => {
  let {
    userPermissionTypes,
    permissionsGroupsRead,
    permissionsGroupsWrite
  } = props
  userPermissionTypes = permissionsGroupsRead ? userPermissionTypes || [] : userPermissionsMock

  return (
    <div className='ta-user-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-user-permission-types__title'>
            <Title label={t('global.userPermissionTypes')}>
              <PopupTriggerWrapper name='user-permissions-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 icon='lightbulb' type='r' />
                </Link>
              </PopupTriggerWrapper>
              {(permissionsGroupsWrite &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('userPermissionTypes.list.buttonHeading.addTooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link
                      to='/managers/user-permissions/add'
                      className={`ta-btn ta-btn-primary ta-btn-icon-only ${userPermissionTypes.length >= PERMISSION_TYPES_LIMIT && 'disabled'}`}
                      disabled={userPermissionTypes.length >= PERMISSION_TYPES_LIMIT}
                    >
                      <FontAwesome5 icon='plus' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!permissionsGroupsRead} label={t('global.accessDenied')} icon='ban'>
            <UserPermissionsLimitBanner
              permissionTypesCount={userPermissionTypes.length}
              permissionTypesAccountLimit={PERMISSION_TYPES_LIMIT}
            />
            {userPermissionTypes.map(permissionType => (
              <UserPermissionsItem permissionType={permissionType} key={permissionType.id} />
            ))}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default UserPermissions
