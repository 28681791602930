import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  SectionTitle,
  Error,
  Row,
  Col,
  Input,
  Blur,
  MultipleSelect,
  t
} from '../../../Common'
import { BranchesCustomerFormField } from '../../../Beauties'

import './BranchesCustomerForm.css'

const BranchesCustomerForm = props => {
  const {
    onSubmit,
    cancelLink,
    groupedFields,
    availableFields,
    timezones,
    plan,
    showExternalIds
  } = props

  return (
    <div className='ta-branches-customers__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title label={t('branches.customer.form.edit.title')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesCustomer'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            {groupedFields.map((item, index) => (
              <FormSection key={index} className='ta-cleaner'>
                <SectionTitle label={item.name || t(item.translationKey)} icon='folder' />
                {item.fields.map(field => (
                  <BranchesCustomerFormField
                    key={field.id}
                    field={field}
                    availableFields={availableFields}
                    timezones={timezones}
                    plan={plan}
                  />
                ))}
              </FormSection>
            )
            )}
            <FormSection ignoreBottomOffset className='ta-cleaner'>
              <SectionTitle label={t('global.tags')} icon='tag' />
              <Row>
                <Col>
                  {(plan !== 'ENTERPRISE' &&
                    <Blur
                      active
                      icon='lock'
                      label={t('branches.customer.form.tags.onlyEnterprise')}
                      activateLabel={t('global.upgradeNow')}
                      link='/subscriptions'
                    >
                      <MultipleSelect name='tags_blur' label={t('global.tags')} />
                    </Blur>
                  )}
                  {(plan === 'ENTERPRISE' &&
                    <MultipleSelect name='tags' label={t('global.tags')} searchable />
                  )}
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormSection className='ta-cleaner'>
              <FormButtons>
                <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
                <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
              </FormButtons>
            </FormSection>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

BranchesCustomerForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default BranchesCustomerForm
