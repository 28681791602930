export default (timelines) => {
  let [firstTimeline, secondTimeline] = timelines || []
  firstTimeline = firstTimeline || []
  return firstTimeline.map((item, index) => {
    const mergedItem = {
      index: item.index,
      isActive: item.isActive,
      times1: item.isActive ? item.times : []
    }
    const secondItem = secondTimeline && secondTimeline[index]
    if (secondItem) {
      mergedItem.isActive = mergedItem.isActive || secondItem.isActive
      mergedItem.times2 = secondItem.isActive ? secondItem.times : []
    }
    return mergedItem
  })
}
