import React from 'react'

const AvailabilityTimelinesGridBackground = props => {
  let { hoursRange } = props
  hoursRange = hoursRange || []

  return (
    <div className='ta-availability-timelines__grid__background'>
      {hoursRange.map((item, index) => {
        let left = `${100 / (hoursRange.length - 1) * index}%`
        if (index === hoursRange.length - 1) left = `calc(${left} - 1px)`
        return (
          <div
            key={item}
            className='ta-availability-timelines__grid__background-line'
            style={{ left }}
          />
        )
      })}
    </div>
  )
}

export default AvailabilityTimelinesGridBackground
