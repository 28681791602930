import PropTypes from 'prop-types'
import { Link, t, Title } from '../../../Common'
import { dangerousHTML, withHttp } from '../../../Utils'

const PopupUrlOpenWarning = props => {
  const { approve, reject, url } = props
  const urlWithHttp = withHttp(url)

  return (
    <div className='ta-popup__courses-reset-global-entity'>
      <Title
        ignoreTopOffset
        label={dangerousHTML(t('popup.urlWarning.title'))}
        icon='exclamation-triangle'
        iconBoxStyle={{
          backgroundColor: '#fff',
          color: 'rgba(28, 39, 43, 0.6)'
        }}
      />
      <div className='ta-popup__text'>
        {dangerousHTML(t('popup.urlWarning.text', [{ key: 'URL', value: url }]))}
      </div>
      <div className='ta-popup__buttons'>
        <Link className='ta-btn ta-btn-secondary' onClick={reject}>{t('buttons.cancel.label')}</Link>
        <Link
          external
          className='ta-btn ta-btn-primary'
          to={urlWithHttp}
          onClick={approve}
          target='_blank'
        >
          {t('global.continue')}
        </Link>
      </div>
    </div>
  )
}

PopupUrlOpenWarning.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupUrlOpenWarning
