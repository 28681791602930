import React from 'react'
import {
  ServiceCombinationSelect,
  SectionTitle,
  FormText,
  Alert,
  Row,
  Col,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'

const BranchesServiceCombinationFormServicesSelector = props => {
  const disabled = !props.allowedSet?.includes('combinationServiceIds')

  return (
    <>
      <SectionTitle label={t('servicesGroups.form.section.serviceSelection.title')} icon='list' />
      <FormText>
        {t('servicesGroups.form.section.serviceSelection.description')}
      </FormText>
      <Alert theme='alert'>
        {t('servicesGroups.form.section.serviceSelection.warningMessage')}
      </Alert>
      <Row>
        <Col>
          <HoverPopup disabled={!disabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <ServiceCombinationSelect
                disabled={disabled}
                name='services'
                label={t('servicesGroups.form.section.serviceSelection.select.title')}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
    </>
  )
}

export default BranchesServiceCombinationFormServicesSelector
