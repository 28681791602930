import React from 'react'
import {
  SimpleTabsHeaderItem,
  FixedContentHeader,
  SimpleTabsBodyItem,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FontAwesome5,
  FixedContent,
  SimpleTabs,
  HoverPopup,
  Alert,
  Title,
  Link,
  Blur,
  t
} from '../../Common'
import { ServiceCategoryPreviewChangedLocallyWrapper, PopupTriggerWrapper } from '../../Beasts'
import { ServiceCategoryPreviewDetails } from '../../Beauties'

import './ServiceCategoryPreview.css'

const ServiceCategoryPreview = props => {
  const {
    category,
    hash,
    message,
    enterpriseId,
    globalServicesRead,
    globalServicesWrite,
    globalServicesDelete,
    allowOnlyGlobalCustomers
  } = props
  const availableTabs = ['details']
  if (!allowOnlyGlobalCustomers) availableTabs.push('changedLocally')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-customer-fields__preview']
  const { id, internalId, isUpdatedLocally, isGloballyDeleted } = category || {}
  const isGlobal = enterpriseId && internalId
  const edited = isGlobal && isUpdatedLocally
  if (edited) classNames.push('edited global')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const isDefaultCategory = category.name === 'default'
  const categoryName = isDefaultCategory ? t('servicesGroups.list.service.defaultCategoryName') : category.name
  const title = globalServicesRead ? categoryName : t('global.accessDenied')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-services__title'>
            <Title label={title}>
              {(globalServicesWrite && category.id &&
                <>
                  {(globalServicesDelete && !category.isDefault &&
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('service.category.delete')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <PopupTriggerWrapper name='service-category-delete' position='bottom' id={category.id}>
                          <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                            <FontAwesome5 icon='trash' />
                          </Link>
                        </PopupTriggerWrapper>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('service.category.edit')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link to={`/management/services/categories/${category.id}/edit`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalServicesRead ? 'inactive' : ''} active={!globalServicesRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && isGlobal && !isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                <p>{t('globalEntities.preview.locallyEdittedWarning')}</p>
                <PopupTriggerWrapper name='customer-fields-reset-global-entity' position='right' id={{ internalId }}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(isGloballyDeleted && isGlobal &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                <p>{t('globalEntities.preview.removedFromDatabase')}</p>
                <PopupTriggerWrapper name='customer-fields-delete-global-entity' position='right' id={id}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(category.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
                  {!allowOnlyGlobalCustomers && (
                    <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalServicesRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <ServiceCategoryPreviewDetails category={category} />
                  </SimpleTabsBodyItem>
                  {!allowOnlyGlobalCustomers && (
                    <SimpleTabsBodyItem name='changedLocally' renderOnlyIfActive>
                      <ServiceCategoryPreviewChangedLocallyWrapper />
                    </SimpleTabsBodyItem>
                  )}
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default ServiceCategoryPreview
