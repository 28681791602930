import React, { useEffect } from 'react'
import moment from 'moment'

import { connect, handlers, selectors } from '../../Store'
import { isRouteDisabledByCustomisations, Loader } from '../../Common'
import { AppsUninstall } from '../../Beauties'

// const mock = {
//   hasCancelledSubscription: true,
//   hasActiveSubscription: false,
//   hasActiveTrial: false,
//   activeUntil: '22.11.2023',
//   trialDaysLeft: 7
// }

const AppsUninstallWrapper = props => {
  let {
    id,
    app,
    form,
    isOwner,
    isAdmin,
    pending,
    pendingPreview,
    passwordFieldType,
    passwordFieldValue
  } = props
  app = app || {}
  form = form || {}
  // const {
  // hasCancelledSubscription,
  // hasActiveSubscription,
  // hasActiveTrial,
  // activeUntil
  // trialDaysLeft
  // } = mock
  const {
    isTrial,
    renewAt,
    trialEndAt,
    subscribedAt,
    unsubscribedAt,
    isBlocked,
    blockedReasonCode
  } = app.appInstallation || {}
  const hasActiveSubscription = subscribedAt && !unsubscribedAt && (!isBlocked || blockedReasonCode !== 'SUBSCRIBE')
  const today = moment()
  const trialEndDay = moment(trialEndAt, 'YYYY-MM-DD')
  const trialDaysLeft = trialEndDay.diff(today, 'days')
  const activeUntil = renewAt && moment(renewAt, 'YYYY-MM-DD').format('L')
  const appsWrite = !!isAdmin
  const isPending = pending || pendingPreview

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }
    if (!appsWrite) {
      handlers.navigateToPath('/apps')
      return
    }

    handlers.appPreviewGet({ id })
    handlers.appUninstallFormGet()
  }, [id, appsWrite])

  const handleOnSubmit = () => {
    if (hasActiveSubscription || !passwordFieldValue) return
    handlers.appUninstallFormSave(form)
  }

  const togglePasswordFieldType = () => {
    if (hasActiveSubscription) return
    const { value, type } = form.password || {}
    const password = {
      value,
      type: type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('appUninstall', { password })

    return password.type
  }

  return (
    <>
      <Loader active={isPending} />
      {!isPending && (
        <AppsUninstall
          {...app}
          onSubmit={handleOnSubmit}
          unsubscribedAt={unsubscribedAt}
          hasActiveSubscription={hasActiveSubscription}
          isTrial={isTrial}
          activeUntil={activeUntil}
          trialDaysLeft={trialDaysLeft}
          isBlocked={isBlocked}
          togglePasswordFieldType={togglePasswordFieldType}
          passwordFieldType={passwordFieldType || 'password'}
          passwordFieldValue={passwordFieldValue}
          isOwner={isOwner}
        />
      )}
    </>
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    form: selectors.formSelector(state, { formName: 'appUninstall' }),
    pending: selectors.appsFieldSelector(state, { field: 'pendingUninstallForm' }),
    pendingPreview: selectors.appsFieldSelector(state, { field: 'pendingPreview' }),
    passwordFieldType: selectors.formFieldPropertySelector(state, { formName: 'appUninstall', name: 'password', property: 'type' }),
    passwordFieldValue: selectors.formFieldPropertySelector(state, { formName: 'appUninstall', name: 'password', property: 'value' }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' })
  }
}

export default connect(maps)(AppsUninstallWrapper)
