import React from 'react'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Title,
  Blur,
  SectionTitle,
  FontAwesome5,
  SimpleListItemTitle,
  SimpleListItem,
  SimpleListItemContent,
  t
} from '../../Common'

import './Courses.css'

const CoursesMock = props => {
  const classNames = ['ta-courses']

  const courses = [
    {
      allowedBranchIds: [],
      categoryId: '1234',
      color: '#385f87',
      combinationServiceIds: null,
      companyId: null,
      customerEmailRemindersMinutes: null,
      dependencies: [],
      description: '<p><strong>Pass</strong></p>',
      duration: null,
      durationAfter: 5,
      durationBefore: 5,
      durationsPattern: [10, 5, 10],
      externalId: 'w',
      hasCombinationSameResourcesPreference: null,
      hasOnlinePayment: false,
      hasOnlinePaymentWarning: null,
      hasOnlineWarning: null,
      id: '54312',
      internalId: '54312',
      isBatch: true,
      isBookable: true,
      isCombination: null,
      isCombinationPriceOverwritten: null,
      isGloballyDeleted: null,
      isPaymentMandatory: false,
      isStalled: null,
      isUpdatedLocally: null,
      name: 'Car inspection',
      orderIndex: 2,
      price: 12,
      resourceEmailRemindersMinutes: null,
      resourcesCount: 12
    },
    {
      allowedBranchIds: [],
      categoryId: '1234',
      color: '#385f87',
      combinationServiceIds: null,
      companyId: null,
      customerEmailRemindersMinutes: null,
      dependencies: [],
      description: '<p><strong>Pass</strong></p>',
      duration: null,
      durationAfter: 5,
      durationBefore: 5,
      durationsPattern: [10, 5, 10],
      externalId: 'w',
      hasCombinationSameResourcesPreference: null,
      hasOnlinePayment: false,
      hasOnlinePaymentWarning: null,
      hasOnlineWarning: null,
      id: '54312',
      internalId: '54312',
      isBatch: true,
      isBookable: true,
      isCombination: null,
      isCombinationPriceOverwritten: null,
      isGloballyDeleted: null,
      isPaymentMandatory: false,
      isStalled: null,
      isUpdatedLocally: null,
      name: 'Car service',
      orderIndex: 2,
      resourceEmailRemindersMinutes: null,
      resourcesCount: 2
    },
    {
      allowedBranchIds: [],
      categoryId: '1234',
      color: '#385f87',
      combinationServiceIds: null,
      companyId: null,
      customerEmailRemindersMinutes: null,
      dependencies: [],
      duration: null,
      durationAfter: 5,
      durationBefore: 5,
      durationsPattern: [10, 5, 10],
      externalId: 'w',
      hasCombinationSameResourcesPreference: null,
      hasOnlinePayment: false,
      hasOnlinePaymentWarning: null,
      hasOnlineWarning: null,
      id: '54312',
      internalId: '54312',
      isBatch: true,
      isBookable: true,
      isCombination: null,
      isCombinationPriceOverwritten: null,
      isGloballyDeleted: null,
      isPaymentMandatory: false,
      isStalled: null,
      isUpdatedLocally: null,
      name: 'Car repair',
      orderIndex: 2,
      price: 12,
      resourceEmailRemindersMinutes: null,
      resourcesCount: 0
    },
    {
      allowedBranchIds: [],
      categoryId: '1234',
      color: '#385f87',
      combinationServiceIds: null,
      companyId: null,
      customerEmailRemindersMinutes: null,
      dependencies: [],
      duration: null,
      durationAfter: 5,
      durationBefore: 5,
      durationsPattern: [10, 5, 10],
      externalId: 'w',
      hasCombinationSameResourcesPreference: null,
      hasOnlinePayment: false,
      hasOnlinePaymentWarning: null,
      hasOnlineWarning: null,
      id: '54312',
      internalId: '54312',
      isBatch: true,
      isBookable: true,
      isCombination: null,
      isCombinationPriceOverwritten: null,
      isGloballyDeleted: null,
      isPaymentMandatory: false,
      isStalled: null,
      isUpdatedLocally: null,
      name: 'Car testing',
      orderIndex: 2,
      price: 12,
      resourceEmailRemindersMinutes: null,
      resourcesCount: 4
    },
    {
      allowedBranchIds: [],
      categoryId: '1234',
      color: '#385f87',
      combinationServiceIds: null,
      companyId: null,
      customerEmailRemindersMinutes: null,
      dependencies: [],
      description: '<p><strong>Pass</strong></p>',
      duration: null,
      durationAfter: 5,
      durationBefore: 5,
      durationsPattern: [10, 5, 10],
      externalId: 'w',
      hasCombinationSameResourcesPreference: null,
      hasOnlinePayment: false,
      hasOnlinePaymentWarning: null,
      hasOnlineWarning: null,
      id: '54312',
      internalId: '54312',
      isBatch: true,
      isBookable: true,
      isCombination: null,
      isCombinationPriceOverwritten: null,
      isGloballyDeleted: null,
      isPaymentMandatory: false,
      isStalled: null,
      isUpdatedLocally: null,
      name: 'fffffffffffffff',
      orderIndex: 2,
      price: 12,
      resourceEmailRemindersMinutes: null,
      resourcesCount: 0
    }
  ]

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-courses__title'>
            <Title label={t('global.groups')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur label={t('global.accessDenied')} icon='ban' active>
            <div className='ta-customer-fields-mock-container'>
              <div className='ta-customer-fields__category'>
                <SectionTitle
                  label='Category name'
                  extraText='(default)'
                  icon='folder'
                />
                <div className='ta-customer-fields__list'>
                  {courses.map((service, index) => (
                    <div className={classNames.join(' ')} key={index}>

                      <SimpleListItem
                        status={service.isPublished ? 'active' : (service.isActive ? 'title-booking' : 'inactive')}
                        draggable={false}
                      >
                        <SimpleListItemTitle title={service.label || t(service.translationKey)} />
                        <SimpleListItemContent>
                          <ul className='ta-list-inline'>
                            {(service.isCombination &&
                              <li>
                                <FontAwesome5 icon='layer-group' type='solid' /> 2 {t('global.services')}
                              </li>
                            )}
                            <li><FontAwesome5 icon='users' type='solid' /> {service.resourcesCount} {t('global.resources')}</li>
                            {(service.duration &&
                              <li><FontAwesome5 icon='hourglass-half' type='solid' /> 50</li>
                            )}
                            {(service.price > 0 &&
                              <li><FontAwesome5 icon='money-bill' type='solid' />20</li>
                            )}
                          </ul>
                        </SimpleListItemContent>
                      </SimpleListItem>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CoursesMock
