import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import {
  AVAILABLE_ICONS
} from '../../../Settings'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  Error
} from '../../../Common'

import './IconSelect.css'

class IconSelect extends Component {
  constructor (props, context) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.state = { open: false }
  }

  componentDidMount () {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.addEventListener('mouseup', this.handleOutsideClick)
    }
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.removeEventListener('mouseup', this.handleOutsideClick)
    }
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOutsideClick (e) {
    if (this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  toggle () {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  onChange (color) {
    let { name, form } = this.props
    form = form || { value: '' }
    this.setState({ open: false })
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value: color } })
  }

  render () {
    const { open } = this.state

    let {
      name,
      pattern,
      icons,
      value,
      form,
      hideError
    } = this.props
    pattern = pattern || ''
    form = form || { value: '' }
    const { icons: formIcons } = form
    icons = icons || formIcons || AVAILABLE_ICONS

    const optionsClasses = ['ta-icon-select__options']
    const selectedOptionIconClasses = ['ta-icon-select__options__icon']
    const selectedClassNames = ['ta-icon-select__selected']

    if (open) {
      optionsClasses.push('active')
      selectedClassNames.push('active')
    }
    if (pattern !== '') {
      optionsClasses.push(pattern)
      selectedOptionIconClasses.push(pattern)
    }

    return (
      <div ref={wrapper => { this.wrapper = wrapper }}>
        <div className='ta-icon-select'>
          <div className={selectedClassNames.join(' ')} onClick={this.toggle}>
            <div className='ta-icon-select__selected-icon'>
              <FontAwesome5 icon={value || form.value} type='solid' />
            </div>
            <FontAwesome5 icon='sort' type='solid' />
          </div>
          <div className={optionsClasses.join(' ')}>
            {icons.map(icon => (
              <div
                key={icon}
                className={selectedOptionIconClasses.join(' ')}
                onClick={() => this.onChange(icon)}
                style={{
                  color: icon === form.value && '#505a5e',
                  border: icon === form.value && '2px solid #9C9E97'
                }}
              >
                <FontAwesome5 icon={icon} type='solid' />
              </div>
            ))}
          </div>
        </div>
        {!hideError &&
          <Error noOffset name={name} />}
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(IconSelect), FormContext)
