import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Alert,
  Blur,
  t,
  Form,
  Error
} from '../../../Common'
import {
  PopupTriggerWrapper,
  CompanyTagPreviewDetailsWrapper,
  CompanyTagPreviewChangedLocallyWrapper
} from '../../../Beasts'

import './CompanyTagPreview.css'

const CompanyTagPreview = props => {
  const {
    tag,
    hash,
    message,
    enterpriseId,
    enforceReschedulingTagRule,
    globalTagsRead,
    globalTagsWrite,
    globalTagsDelete,
    allowOnlyGlobalCustomers
  } = props
  const availableTabs = ['details']
  if (!allowOnlyGlobalCustomers) availableTabs.push('changedLocally')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-company-tags__preview']
  const { id, internalId, isUpdatedLocally, isGloballyDeleted } = tag || {}
  const isGlobal = enterpriseId && internalId
  const edited = isGlobal && isUpdatedLocally
  if (edited) classNames.push('edited global')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const title = globalTagsRead ? tag.name || '' : t('global.accessDenied')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-company-tags__title'>
            <Title label={title}>
              {(globalTagsWrite && tag.id &&
                <>
                  {(globalTagsDelete && tag.id !== enforceReschedulingTagRule &&
                    <PopupTriggerWrapper name='company-tags-delete' position='bottom' id={tag.id}>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('tags.buttonHeading.deleteTooltip')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Link
                            className='ta-btn ta-btn-delete ta-btn-icon-only'
                            disabled={tag.id === enforceReschedulingTagRule}
                            external
                          >
                            <FontAwesome5 icon='trash' />
                          </Link>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </PopupTriggerWrapper>
                  )}
                  {(globalTagsDelete && tag.id === enforceReschedulingTagRule &&
                    <HoverPopup>
                      <HoverPopupContent position='left'>
                        {t('tags.buttonHeading.usedForReschedulingTagRule')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link
                          className='ta-btn ta-btn-delete ta-btn-icon-only disabled'
                          disabled
                          external
                        >
                          <FontAwesome5 icon='trash' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('tags.buttonHeading.editTooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link to={`/customers/company-tags/${tag.id}/edit`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalTagsRead ? 'inactive' : ''} active={!globalTagsRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {edited && isGlobal && !isGloballyDeleted && (
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                <p>{t('globalEntities.preview.locallyEdittedWarning')}</p>
                <PopupTriggerWrapper name='tags-reset-global-entity' position='right' id={{ internalId }}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {isGloballyDeleted && isGlobal && (
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                <p>{t('globalEntities.preview.removedFromDatabase')}</p>
                <PopupTriggerWrapper name='tags-delete-global-entity' position='right' id={id}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(edited && isGlobal && !isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                {t('globalEntities.preview.locallyEdittedWarning')}
                <PopupTriggerWrapper name='tags-reset-global-entity' position='right' id={{ internalId }}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(isGloballyDeleted && isGlobal &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                {t('globalEntities.preview.removedFromDatabase')}
                <PopupTriggerWrapper name='tags-delete-global-entity' position='right' id={id}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(tag.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='tag' label={t('global.details')} />
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalTagsRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <CompanyTagPreviewDetailsWrapper tag={tag} />
                  </SimpleTabsBodyItem>
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsBodyItem name='changedLocally' renderOnlyIfActive>
                      <CompanyTagPreviewChangedLocallyWrapper />
                    </SimpleTabsBodyItem>
                  )}
                  <Form name='companyTagsPreview'>
                    <Error name='globalErrors' />
                  </Form>
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CompanyTagPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default CompanyTagPreview
