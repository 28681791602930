const reducer = {}

reducer.initialState = {
  activityLogs: [],
  pendingActivityLogs: true,
  activityLogsCount: 0,
  activityLogsPage: 1,
  areFetchedActivityLogs: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  notificationsReset: () => dispatch(actions.NOTIFICATIONS_RESET),

  // Activity logs
  activityLogsSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.ACTIVITY_LOGS_SUBSCRIPTION_SET, { name, id, data, ts }),
  activityLogsListGet: () => dispatch(actions.ACTIVITY_LOGS_LIST_GET),
  activityLogsListPending: () => dispatch(actions.ACTIVITY_LOGS_LIST_PENDING),
  activityLogsListPopulate: (activityLogs, isLoadMore, isFromSubscription) => dispatch(actions.ACTIVITY_LOGS_LIST_POPULATE, { activityLogs, isLoadMore, isFromSubscription }),
  activityLogsListCounterPopulate: count => dispatch(actions.ACTIVITY_LOGS_LIST_COUNTER_POPULATE, count),
  activityLogsListOptionsChange: options => dispatch(actions.ACTIVITY_LOGS_LIST_OPTIONS_CHANGE, options),
  activityLogsListPageIncrement: page => dispatch(actions.ACTIVITY_LOGS_LIST_PAGE_INCREMENT, page),
  activityLogsListReady: () => dispatch(actions.ACTIVITY_LOGS_LIST_READY),
  activityLogsListMessageSet: message => dispatch(actions.ACTIVITY_LOGS_LIST_MESSAGE_SET, message)
})

// Activity logs

reducer.ACTIVITY_LOGS_SUBSCRIPTION_SET = state => state

reducer.ACTIVITY_LOGS_LIST_GET = state => ({
  ...state,
  pendingActivityLogs: true,
  activityLogsPage: 1,
  messageList: null
})

reducer.ACTIVITY_LOGS_LIST_PENDING = state => ({
  ...state,
  pendingActivityLogs: true
})

reducer.ACTIVITY_LOGS_LIST_OPTIONS_CHANGE = (state, options = {}) => ({
  ...state,
  ...options
})

reducer.ACTIVITY_LOGS_LIST_POPULATE = (state, { activityLogs = [], isLoadMore = false, isFromSubscription }) => ({
  ...state,
  activityLogs: (isLoadMore && [...state.activityLogs, ...activityLogs]) || activityLogs,
  pendingActivityLogs: !!isFromSubscription,
  areFetchedActivityLogs: true,
  activityLogsPage: state.activityLogsPage || 1
})

reducer.ACTIVITY_LOGS_LIST_COUNTER_POPULATE = (state, count) => ({
  ...state,
  activityLogsCount: count || 0
})

reducer.ACTIVITY_LOGS_LIST_PAGE_INCREMENT = state => ({
  ...state,
  activityLogsPage: state.activityLogsPage + 1
})

reducer.ACTIVITY_LOGS_LIST_READY = state => ({
  ...state,
  pendingActivityLogs: false
})

reducer.ACTIVITY_LOGS_LIST_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingActivityLogs: false,
  messageList: message
})

export default reducer
