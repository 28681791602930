import React from 'react'
import { format } from '../../Common'
import { getLocalizedTimeFormat } from '../../Utils'

const AvailabilityTimelinesHeader = props => {
  let { hoursRange } = props
  hoursRange = hoursRange || []
  const timeFormat = getLocalizedTimeFormat()
  // timeFormat will be either 'HH:mm' or 'h:mm A', depending on current locale
  const isAmPmFormat = timeFormat && timeFormat.split(' ') && timeFormat.split(' ')[1] === 'A'
  const rangeDiff = hoursRange[hoursRange.length - 1] - hoursRange[0]
  const displayEveryHourLimit = isAmPmFormat ? 10 : 12
  const displayEveryHour = rangeDiff <= displayEveryHourLimit
  const displayEveryTwoHours = !displayEveryHour
  const isAsymmetric = !displayEveryHour && rangeDiff % 2 !== 0
  let hideLastHours = false
  if (displayEveryTwoHours) {
    hideLastHours = !!isAmPmFormat || (isAsymmetric && hoursRange.length > 13)
    hoursRange = hoursRange.filter((_, index) => index % 2 === 0)
  }
  const classNames = ['ta-availability-timelines__header']
  if (isAmPmFormat) classNames.push('tall')

  const extractHour = formattedTime => {
    const [time, extension] = (formattedTime || '').split(' ')
    const hour = (time || '').split(':')[0]
    if (extension) return `${`${hour}`.padStart(2, '0')} ${extension}`
    return hour
  }

  return (
    <div className={classNames.join(' ')}>
      {hoursRange.map((item, index) => {
        let left
        if (!isAsymmetric) left = `${100 / (hoursRange.length - 1) * index}%`
        if (isAsymmetric) {
          let calculatedSpace = 100 - (100 / (2 * hoursRange.length - 1))
          left = `${calculatedSpace / (hoursRange.length - 2) * index}%`
          if (displayEveryTwoHours && hideLastHours) {
            calculatedSpace = 100 - (100 / (2 * hoursRange.length - 2))
            left = `${calculatedSpace / (hoursRange.length - 1) * index}%`
          }
        }
        const hourClassNames = ['ta-availability-timelines__header__hour']
        if (index === hoursRange.length - 1 && !isAsymmetric) hourClassNames.push('left-shift')

        return (
          <div
            key={item}
            className={hourClassNames.join(' ')}
            style={{ left }}
          >
            {extractHour(format(`${item}`, 'LT', { format: 'HH', isUTC: true }))}
          </div>
        )
      })}
    </div>
  )
}

export default AvailabilityTimelinesHeader
