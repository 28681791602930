import { useEffect } from 'react'
import { AttributeForm } from '../../Beauties'
import { Loader, t } from '../../Common'
import { connect, handlers, selectors } from '../../Store'

const AttributeAddWrapper = ({
  type,
  hash,
  categories,
  hasTooltip,
  pendingForm,
  pendingList,
  hasExplanation,
  fileUploadTypes,
  showExternalIds,
  enterprisePermissions
}) => {
  const { branchesAccessBlacklist } = enterprisePermissions || {}
  const isPending = pendingForm || pendingList
  const typeLabelByHash = {
    resource: 'global.resource',
    service: 'global.service',
    course: 'global.group'
  }
  const title = t('attributes.form.add.title', [
    { key: 'TYPE', value: typeLabelByHash[hash] || 'global.resource', translateReplace: true }
  ])

  const onSubmit = cb => {
    handlers.attributesFormSubmit((errors) => cb?.(errors))
  }

  useEffect(() => {
    handlers.attributesFormGet()
  }, [])

  return (
    <>
      <Loader active={isPending} />
      {!isPending && (
        <AttributeForm
          title={title}
          type={type}
          onSubmit={onSubmit}
          categories={categories}
          hasTooltip={hasTooltip}
          hasExplanation={hasExplanation}
          fileUploadTypes={fileUploadTypes}
          showExternalIds={showExternalIds}
          cancelLink={`/management/attributes@@${hash}`}
          branchesAccessBlacklist={branchesAccessBlacklist}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  categories: selectors.attributesCategoriesListSelector(state),
  pendingForm: selectors.attributesFieldSelector(state, { field: 'pendingForm' }),
  pendingList: selectors.attributesFieldSelector(state, { field: 'pendingList' }),
  showExternalIds: selectors.companySettingsFieldSelector(state, { field: 'showExternalIds' }),
  enterprisePermissions: selectors.accountFieldSelector(state, { field: 'enterprisePermissions' }),
  type: selectors.formFieldPropertySelector(state, { formName: 'attribute', name: 'type', property: 'value' }),
  hasTooltip: selectors.formFieldPropertySelector(state, { formName: 'attribute', name: 'hasTooltip', property: 'value' }),
  hasExplanation: selectors.formFieldPropertySelector(state, { formName: 'attribute', name: 'hasExplanation', property: 'value' }),
  fileUploadTypes: selectors.formFieldPropertySelector(state, { formName: 'attribute', name: 'fileUploadTypes', property: 'values' })
})

export default connect(maps)(AttributeAddWrapper)
