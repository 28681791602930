import { AttributeCategoryPreview } from '../../Beauties'
import { Loader } from '../../Common'
import { connect, selectors } from '../../Store'

const AttributeCategoryPreviewWrapper = ({ id, attributesList, categoriesList, pendingList, pendingPreview, isAdmin, hash }) => {
  const category = categoriesList.find(item => item.id === id) || {}
  const hasAttributes = !!attributesList.filter(item => item.categoryId === id).length
  const isPending = pendingList || pendingPreview

  return (
    <>
      <Loader active={isPending} />
      {!isPending && (
        <AttributeCategoryPreview
          hash={hash}
          isAdmin={isAdmin}
          category={category}
          hasAttributes={hasAttributes}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
  attributesList: selectors.attributesListSelector(state),
  categoriesList: selectors.attributesCategoriesListSelector(state),
  id: selectors.routerDataFieldSelector(state, { field: 'id' }),
  pendingList: selectors.attributesFieldSelector(state, { field: 'pendingList' }),
  pendingPreview: selectors.attributesFieldSelector(state, { field: 'pendingPreview' })
})

export default connect(maps)(AttributeCategoryPreviewWrapper)
