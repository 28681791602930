import React from 'react'
import {
  FixedContentHeader,
  FixedContentBody,
  ColorIconSelect,
  FixedContent,
  SectionTitle,
  FormSection,
  FormButtons,
  Title,
  Error,
  Input,
  Link,
  Form,
  Row,
  Col,
  t
} from '../../../Common'
import { UserPermissionsFormPermissionsWrapper } from '../../../Beasts'
import './UserPermissionsForm.css'

const UserPermissionsForm = props => {
  const { cancelLink, title, showExternalIds, onSubmit } = props
  return (
    <div className='ta-user-permission-types__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-users__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='userPermissionType'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle
                  label={t('global.externalId')}
                  icon='fingerprint'
                />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <SectionTitle
                label={t('userPermissionTypes.edit.section.details.heading')}
                icon='sliders-h'
              />
              <Row>
                <Col>
                  <ColorIconSelect name='color' iconFieldName='icon'>
                    <Input
                      name='name'
                      mandatory
                      label={t('global.name.placeholder')}
                      hintText={t('userPermissionTypes.edit.section.details.name.example')}
                    />
                  </ColorIconSelect>
                </Col>
              </Row>
            </FormSection>
            <FormSection ignoreBottomOffset>
              <SectionTitle
                label={t('userPermissionTypes.edit.section.permissions.heading')}
                icon='unlock-alt'
              />
              <UserPermissionsFormPermissionsWrapper />
            </FormSection>
            <FormSection>
              <Error noOffset name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>
                {t('buttons.cancel.label')}
              </Link>
              <button type='submit' className='ta-btn ta-btn-primary'>
                {t('buttons.save.label')}
              </button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default UserPermissionsForm
