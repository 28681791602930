import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../../Common'

import './Title.css'

const darkGreyIconBoxStyle = {
  backgroundColor: '#505a5e',
  color: '#ffffff'
}

const lightGreyIconBoxStyle = {
  backgroundColor: '#e5e6e7',
  color: 'rgba(28, 39, 43, 0.6)'
}

// const transparentGreyIconBoxStyle = {
//   backgroundColor: 'transparent',
//   color: 'rgba(28, 39, 43, 0.6)'
// }

const Title = props => {
  let { label, children, isCompact, theme, icon, iconBoxStyle, addOn, onIconClick, isCentered } = props
  const classNames = ['ta-title']
  const availableThemes = ['default']
  classNames.push(availableThemes.indexOf(theme) > -1 ? theme : availableThemes[0])
  if (isCompact) classNames.push('ta-title__compact')
  if (isCentered) {
    classNames.push('ta-title__centered')
    iconBoxStyle = lightGreyIconBoxStyle
  }

  return (
    <div className={classNames.join(' ')} data-testid='title'>
      <div className='ta-title__label' data-testid='title-label'>
        {(icon &&
          <div className='ta-title__label__icon' style={iconBoxStyle || darkGreyIconBoxStyle} onClick={onIconClick}>
            <FontAwesome5 icon={icon} />
          </div>
        )}
        <p className='ta-title__label__text'>{label}</p>
        {(addOn &&
          <div className='ta-title__label__addon' data-testid='title-addOn'>
            {addOn}
          </div>
        )}
      </div>
      {!isCentered && <div className='ta-title__content' data-testid='title-content'>{children}</div>}
    </div>
  )
}

Title.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  theme: PropTypes.string,
  iconBoxStyle: PropTypes.object,
  addOn: PropTypes.node,
  onIconClick: PropTypes.func,
  isCompact: PropTypes.bool,
  isCentered: PropTypes.bool
}

Title.defaultProps = {
  label: '',
  icon: null,
  theme: null,
  iconBoxStyle: null,
  addOn: null,
  onIconClick: null,
  isCompact: false,
  isCentered: false
}

export default Title
