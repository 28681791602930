import {
  sortByOrderIndex
} from '../../../Utils'

import {
  listTransform,
  formTransform,
  branchesServiceFormTransform,
  branchesGroupFormTransform,
  branchesResourceFormTransform,
  branchesResourcePasswordChangeFormTransform,
  branchesCustomerFormTransform,
  branchesResourceDeleteFormTransform,
  branchesStatisticsFilterFormPopulate,
  branchPeakSignUpTimesFilterFormPopulate,
  branchesImportFormTransform,
  branchResourcesImportFormTransform,
  branchResourceCategoriesImportFormTransform,
  branchServicesImportFormTransform,
  branchServiceCategoriesImportFormTransform,
  branchGroupsImportFormTransform,
  branchGroupCategoriesImportFormTransform,
  branchServiceListTransform,
  branchesServiceCombinationFormTransform,
  branchesExportFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  pending: true,
  pendingForm: true,
  pendingPreview: true,
  selected: null,
  pendingCompanyConfirm: false,
  page: 1,
  areFetched: false,
  pendingEmailUnlink: false,
  pendingImportForm: false,
  showImportMessage: false,
  showImportResourcesMessage: false,
  importResourcesType: '',
  showImportServicesMessage: false,
  importServicesType: '',
  showImportGroupsMessage: false,
  importGroupsType: '',
  pendingDeleteForm: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  branchesReset: () => dispatch(actions.BRANCHES_RESET),

  // List
  branchesListGet: forceFetch => dispatch(actions.BRANCHES_LIST_GET, forceFetch),
  branchesListPopulate: (data, tags) => dispatch(actions.BRANCHES_LIST_POPULATE, listTransform(data, tags)),
  branchesListPopulateSearch: (data, tags) => dispatch(actions.BRANCHES_LIST_POPULATE_SEARCH, listTransform(data, tags)),
  branchesListReady: () => dispatch(actions.BRANCHES_LIST_READY),
  branchesListPending: () => dispatch(actions.BRANCHES_LIST_PENDING),

  // Search
  branchesSearch: () => dispatch(actions.BRANCHES_LIST_SEARCH),

  // One
  branchesUpdate: data => dispatch(actions.BRANCHES_UPDATE, data),
  branchesUpdated: data => dispatch(actions.BRANCHES_UPDATED, data),
  branchDeleted: id => dispatch(actions.BRANCH_DELETED, id),
  branchRemoveDeleted: id => dispatch(actions.BRANCH_REMOVE_DELETED, id),

  // Form
  branchDeleteFormSave: (branch, scrollToError = () => { }) => dispatch(actions.BRANCH_DELETE_FORM_SAVE, { branch, scrollToError }),
  branchDeleteFormReady: () => dispatch(actions.BRANCH_DELETE_FORM_READY),

  // Preview
  branchesPreviewGet: ({ id }) => dispatch(actions.BRANCHES_PREVIEW_GET, { id }),
  branchesPreviewPopulate: branch => dispatch(actions.BRANCHES_PREVIEW_POPULATE, branch),
  branchesPreviewCompanyConfirm: data => dispatch(actions.BRANCHES_PREVIEW_COMPANY_CONFIRM, data),
  branchesPreviewCompanyConfirmed: data => dispatch(actions.BRANCHES_PREVIEW_COMPANY_CONFIRMED, data),
  branchesPreviewConfirmationResend: data => dispatch(actions.BRANCHES_PREVIEW_CONFIRMATION_RESEND, data),
  branchesPreviewConfirmationResendSended: data => dispatch(actions.BRANCHES_PREVIEW_CONFIRMATION_RESEND_SENDED, data),
  branchPreviewResourcesGet: id => dispatch(actions.BRANCH_PREVIEW_RESOURCES_GET, id),
  branchPreviewResourcesPopulate: ({ id, resources }) => dispatch(actions.BRANCH_PREVIEW_RESOURCES_POPULATE, { id, resources }),
  branchesPreviewAccountUnlock: ({ resourceId, userId }) => dispatch(actions.BRANCHES_PREVIEW_ACCOUNT_UNLOCK, { resourceId, userId }),
  branchesPreviewAccountUnlockReady: ({ resourceId, branchId }) => dispatch(actions.BRANCHES_PREVIEW_ACCOUNT_UNLOCK_READY, { resourceId, branchId }),

  // Unlink
  branchResourceFormEmailUnlink: ({ id, branch }) => dispatch(actions.BRANCH_RESOURCE_FORM_EMAIL_UNLINK, { id, branch }),
  branchResourceFormEmailUnlinkReady: () => dispatch(actions.BRANCH_RESOURCE_FORM_EMAIL_UNLINK_READY),
  // Delete
  branchResourceDelete: id => dispatch(actions.BRANCH_RESOURCE_DELETE, id),
  branchResourceDeleted: ({ id, branchId }) => dispatch(actions.BRANCH_RESOURCE_DELETED, { id, branchId }),
  branchResourceDeleteFailed: () => dispatch(actions.BRANCH_RESOURCE_DELETE_FAILED),
  branchResourceDeleteFormPopulate: () => {
    handlers.formSet('branchResourceDelete', branchesResourceDeleteFormTransform())
    handlers.branchResourceDeleteFormReady()
  },
  branchResourceDeleteFormSave: (resource, scrollToError = () => { }) => dispatch(actions.BRANCH_RESOURCE_DELETE_FORM_SAVE, { resource, scrollToError }),
  branchResourceDeleteFormReady: () => dispatch(actions.BRANCH_RESOURCE_DELETE_FORM_READY),
  branchPreviewServicesGet: id => dispatch(actions.BRANCH_PREVIEW_SERVICES_GET, id),
  branchPreviewServicesPopulate: ({ id, services }) => dispatch(actions.BRANCH_PREVIEW_SERVICES_POPULATE, { id, services }),
  branchServiceDelete: id => dispatch(actions.BRANCH_SERVICE_DELETE, id),
  branchServiceDeleted: ({ id, branchId }) => dispatch(actions.BRANCH_SERVICE_DELETED, { id, branchId }),
  branchPreviewGroupsGet: id => dispatch(actions.BRANCH_PREVIEW_GROUPS_GET, id),
  branchPreviewGroupsPopulate: ({ id, courses, courseCategories }) => dispatch(actions.BRANCH_PREVIEW_GROUPS_POPULATE, {
 id,
    groups: {
      courses: branchServiceListTransform(courses),
      courseCategories
    } 
}),
  branchCourseDelete: id => dispatch(actions.BRANCH_COURSE_DELETE, id),
  branchCourseDeleted: ({ id, branchId }) => dispatch(actions.BRANCH_COURSE_DELETED, { id, branchId }),
  branchPreviewCustomersGet: id => dispatch(actions.BRANCH_PREVIEW_CUSTOMERS_GET, id),
  branchPreviewCustomersPopulate: ({ id, customers }) => dispatch(actions.BRANCH_PREVIEW_CUSTOMERS_POPULATE, { id, customers }),
  branchCustomerDelete: id => dispatch(actions.BRANCH_CUSTOMER_DELETE, id),
  branchCustomerDeleted: ({ id, branchId }) => dispatch(actions.BRANCH_CUSTOMER_DELETED, { id, branchId }),
  branchPreviewStatisticsGet: id => dispatch(actions.BRANCH_PREVIEW_STATISTICS_GET, id),
  branchPreviewStatisticsPopulate: ({ id, dailyStatistics, monthlyStatistics }) => dispatch(actions.BRANCH_PREVIEW_STATISTICS_POPULATE, { id, dailyStatistics, monthlyStatistics }),
  branchesPreviewPasswordResetSend: data => dispatch(actions.BRANCHES_PREVIEW_PASSWORD_RESET_SEND, data),
  branchesPreviewPasswordResetSended: data => dispatch(actions.BRANCHES_PREVIEW_PASSWORD_RESET_SENDED, data),
  // Resource Form
  branchesResourceFormGet: id => dispatch(actions.BRANCHES_RESOURCE_FORM_GET, id),
  branchesResourceFormPopulate: resource => {
    handlers.formSet('branchesResource', branchesResourceFormTransform(resource))
    handlers.branchesResourceFormReady()
  },
  branchesResourceFormReady: () => dispatch(actions.BRANCHES_RESOURCE_FORM_READY),
  branchesResourceFormSave: (branch, resource, scrollToError = () => { }) => dispatch(actions.BRANCHES_RESOURCE_FORM_SAVE, { branch, resource, scrollToError }),
  branchesResourceUpdate: ({ branchId, resource }) => dispatch(actions.BRANCHES_RESOURCE_UPDATE, { branchId, resource }),
  // Resource Password Change Form
  branchesResourcePasswordChangeFormGet: () => dispatch(actions.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_GET),
  branchesResourcePasswordChangeFormPopulate: () => {
    handlers.formSet('branchesResourcePasswordChange', branchesResourcePasswordChangeFormTransform())
    handlers.branchesResourcePasswordChangeFormReady()
  },
  branchesResourcePasswordChangeFormReady: () => dispatch(actions.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_READY),
  branchesResourcePasswordChangeFormSave: (form, scrollToError = () => { }) => dispatch(actions.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_SAVE, { form, scrollToError }),
  // Service Form
  branchesServiceFormGet: (id, branch) => {
    dispatch(actions.BRANCHES_SERVICE_FORM_GET, { id, branch })
  },
  branchesServiceFormPopulate: service => {
    handlers.formSet('branchesService', branchesServiceFormTransform(service))
    handlers.branchesServiceFormReady()
  },
  branchesServiceFormSave: (branch, service, scrollToError = () => { }) => dispatch(actions.BRANCHES_SERVICE_FORM_SAVE, { branch, service, scrollToError }),
  branchesServiceFormReady: () => dispatch(actions.BRANCHES_SERVICE_FORM_READY),
  // Service Combination Form
  branchesServiceCombinationFormGet: ({ service, branch }) => dispatch(actions.BRANCHES_SERVICE_COMBINATION_FORM_GET, { service, branch }),
  branchesServiceCombinationFormPopulate: service => {
    handlers.formSet('branchesService', branchesServiceCombinationFormTransform(service))
    handlers.branchesServiceCombinationFormReady()
  },
  branchesServiceCombinationFormReady: () => dispatch(actions.BRANCHES_SERVICE_COMBINATION_FORM_READY),
  branchesServiceCombinationFormSave: (branch, service, scrollToError = () => { }) => dispatch(actions.BRANCHES_SERVICE_COMBINATION_FORM_SAVE, { branch, service, scrollToError }),
  // Group Form
  branchesGroupFormGet: (id) => {
    dispatch(actions.BRANCHES_GROUP_FORM_GET, id)
  },
  branchesGroupFormPopulate: group => {
    handlers.formSet('branchesGroup', branchesGroupFormTransform(group))
    handlers.branchesGroupFormReady()
  },
  branchesGroupFormSave: (branch, group, scrollToError = () => { }) => dispatch(actions.BRANCHES_GROUP_FORM_SAVE, { branch, group, scrollToError }),
  branchesGroupFormReady: () => dispatch(actions.BRANCHES_GROUP_FORM_READY),
  // Customer Form
  branchesCustomerFormGet: id => dispatch(actions.BRANCHES_CUSTOMER_FORM_GET, id),
  branchesCustomerFormPopulate: customer => {
    handlers.formSet('branchesCustomer', branchesCustomerFormTransform(customer))
    handlers.branchesCustomerFormReady()
  },
  branchesCustomerFormReady: () => dispatch(actions.BRANCHES_CUSTOMER_FORM_READY),
  branchesCustomerFormSave: (branch, customer, scrollToError = () => { }) => dispatch(actions.BRANCHES_CUSTOMER_FORM_SAVE, { branch, customer, scrollToError }),
  branchesFormGet: ({ id }) => dispatch(actions.BRANCHES_FORM_GET, { id }),
  // Branches Form
  branchesFormPopulate: data => {
    handlers.formSet('branches', formTransform(data))
    handlers.branchesFormReady()
  },
  branchesTagsFormPopulate: (tags) => {
    handlers.formSet('branchesFilter', tags)
  },
  branchesFormReady: () => dispatch(actions.BRANCHES_FORM_READY),
  branchesFormSaveCreate: (form, scrollToError = () => { }) => dispatch(actions.BRANCHES_FORM_SAVE_CREATE, form, scrollToError),
  branchesFormSaveEdit: (form, scrollToError = () => { }) => dispatch(actions.BRANCHES_FORM_SAVE_EDIT, form, scrollToError),

  // Login
  branchesLogin: (id, isCustomisationIgnored) => dispatch(actions.BRANCHES_LOGIN, { id, isCustomisationIgnored }),

  // Statistics
  branchStatisticsFiltersGet: labels => {
    dispatch(actions.BRANCHES_STATISTICS_FILTERS_GET)
    handlers.formSet('branchStatisticsFilter', branchesStatisticsFilterFormPopulate(labels))
  },
  branchStatisticsMostBookedResourcesFiltersGet: () => dispatch(actions.BRANCHES_STATISTICS_MOST_BOOKED_RESOURCES_FILTERS_GET),
  branchPreviewStatisticsOverallGet: (id, region) => dispatch(actions.BRANCH_PREVIEW_STATISTICS_OVERALL_GET, { id, region }),
  branchPreviewStatisticsOverallPopulate: ({ id, overallStatistics }) => dispatch(actions.BRANCH_PREVIEW_STATISTICS_OVERALL_POPULATE, { id, overallStatistics }),
  branchPreviewMarketingFormGet: () => dispatch(actions.DASHBOARD_MARKETING_FORM_GET),
  branchPreviewStatiticsPeakSignUpTimesFiltersGet: () => {
    dispatch(actions.DASHBOARD_MOST_BOOKED_RESOURCES_FILTERS_GET)
    handlers.formSet('branchStatisticsSignUpTimes', branchPeakSignUpTimesFilterFormPopulate())
  },
  // filter by tag functionality
  branchPreviewStatisticFilter: (id, statisticId, filter, isOverall) => dispatch(actions.BRANCH_PREVIEW_STATISTIC_FILTER, { id, statisticId, filter, isOverall }),
  branchPreviewStatisticUpdate: ({ id, statisticId, filteredDailyStatistic, filteredMonthlyStatistic, filteredOverallStatistic, statisticsId, isOverall }) => dispatch(actions.BRANCH_PREVIEW_STATISTIC_UPDATE, { id, statisticId, filteredDailyStatistic, filteredMonthlyStatistic, filteredOverallStatistic, statisticsId, isOverall }),
  branchPreviewStatisticsResetSingleStatisticFilters: () => dispatch(actions.BRANCH_PREVIEW_STATISTIC_FILTERS_RESET),
  branchPreviewStatisticOverallFilter: (companyId, statisticId, filter) => dispatch(actions.BRANCH_PREVIEW_STATISTIC_OVERALL_FILTER, { companyId, statisticId, filter }),
  branchPreviewStatisticOverallUpdate: ({ companyId, statisticId, filteredStatistic }) => dispatch(actions.BRANCH_PREVIEW_STATISTIC_OVERALL_UPDATE, { companyId, statisticId, filteredStatistic }),

  // branches import
  branchesImportFormGet: () => {
    dispatch(actions.BRANCHES_IMPORT_FORM_GET)
    handlers.formSet('branchesImport', branchesImportFormTransform())
    handlers.branchesImportFormReady()
  },
  branchesImportFormReady: () => dispatch(actions.BRANCHES_IMPORT_FORM_READY),
  branchesImportFormSubmit: ({ form }) => dispatch(actions.BRANCHES_IMPORT_FORM_SUBMIT, { form }),
  branchesImportShowMessage: () => dispatch(actions.BRANCHES_IMPORT_SHOW_MESSAGE),

  // branch resources import
  branchResourcesImportFormGet: () => {
    dispatch(actions.BRANCH_RESOURCES_IMPORT_FORM_GET)
    handlers.formSet('branchResourcesImport', branchResourcesImportFormTransform())
    handlers.branchResourcesImportFormReady()
  },
  branchResourcesImportFormReady: () => dispatch(actions.BRANCH_RESOURCES_IMPORT_FORM_READY),
  branchResourcesImportFormSubmit: ({ form }) => dispatch(actions.BRANCH_RESOURCES_IMPORT_FORM_SUBMIT, { form }),
  branchResourcesImportShowMessage: () => dispatch(actions.BRANCH_RESOURCES_IMPORT_SHOW_MESSAGE),

  // branch resource categories import
  branchResourceCategoriesImportFormGet: () => {
    dispatch(actions.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_GET)
    handlers.formSet('branchResourceCategoriesImport', branchResourceCategoriesImportFormTransform())
    handlers.branchResourceCategoriesImportFormReady()
  },
  branchResourceCategoriesImportFormReady: () => dispatch(actions.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_READY),
  branchResourceCategoriesImportFormSubmit: ({ form }) => dispatch(actions.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_SUBMIT, { form }),
  branchResourceCategoriesImportShowMessage: () => dispatch(actions.BRANCH_RESOURCE_CATEGORIES_IMPORT_SHOW_MESSAGE),

  // branch services import
  branchServicesImportFormGet: () => {
    dispatch(actions.BRANCH_SERVICES_IMPORT_FORM_GET)
    handlers.formSet('branchServicesImport', branchServicesImportFormTransform())
    handlers.branchServicesImportFormReady()
  },
  branchServicesImportFormReady: () => dispatch(actions.BRANCH_SERVICES_IMPORT_FORM_READY),
  branchServicesImportFormSubmit: ({ form }) => dispatch(actions.BRANCH_SERVICES_IMPORT_FORM_SUBMIT, { form }),
  branchServicesImportShowMessage: () => dispatch(actions.BRANCH_SERVICES_IMPORT_SHOW_MESSAGE),

  // branch service categories import
  branchServiceCategoriesImportFormGet: () => {
    dispatch(actions.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_GET)
    handlers.formSet('branchServiceCategoriesImport', branchServiceCategoriesImportFormTransform())
    handlers.branchServiceCategoriesImportFormReady()
  },
  branchServiceCategoriesImportFormReady: () => dispatch(actions.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_READY),
  branchServiceCategoriesImportFormSubmit: ({ form }) => dispatch(actions.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_SUBMIT, { form }),
  branchServiceCategoriesImportShowMessage: () => dispatch(actions.BRANCH_SERVICE_CATEGORIES_IMPORT_SHOW_MESSAGE),

  // branch groups import
  branchGroupsImportFormGet: () => {
    dispatch(actions.BRANCH_GROUPS_IMPORT_FORM_GET)
    handlers.formSet('branchGroupsImport', branchGroupsImportFormTransform())
    handlers.branchGroupsImportFormReady()
  },
  branchGroupsImportFormReady: () => dispatch(actions.BRANCH_GROUPS_IMPORT_FORM_READY),
  branchGroupsImportFormSubmit: ({ form }) => dispatch(actions.BRANCH_GROUPS_IMPORT_FORM_SUBMIT, { form }),
  branchGroupsImportShowMessage: () => dispatch(actions.BRANCH_GROUPS_IMPORT_SHOW_MESSAGE),

  // branch group categories import
  branchGroupCategoriesImportFormGet: () => {
    dispatch(actions.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_GET)
    handlers.formSet('branchGroupCategoriesImport', branchGroupCategoriesImportFormTransform())
    handlers.branchGroupCategoriesImportFormReady()
  },
  branchGroupCategoriesImportFormReady: () => dispatch(actions.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_READY),
  branchGroupCategoriesImportFormSubmit: ({ form }) => dispatch(actions.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_SUBMIT, { form }),
  branchGroupCategoriesImportShowMessage: () => dispatch(actions.BRANCH_GROUP_CATEGORIES_IMPORT_SHOW_MESSAGE),

  // branch import messages
  branchImportResourceMessagesHide: () => dispatch(actions.BRANCH_HIDE_IMPORT_RESOURCE_MESSAGES),
  branchImportServiceMessagesHide: () => dispatch(actions.BRANCH_HIDE_IMPORT_SERVICE_MESSAGES),
  branchImportGroupMessagesHide: () => dispatch(actions.BRANCH_HIDE_IMPORT_GROUP_MESSAGES),

  // templates export
  branchesCSVDownload: (type, isUrl) => dispatch(actions.BRANCHES_CSV_DOWNLOAD, { type, isUrl }),

  // Export Statistics
  branchStatisticsExportFormGet: () => dispatch(actions.BRANCH_STATISTICS_EXPORT_FORM_GET),
  branchStatisticsExportFormReady: () => dispatch(actions.BRANCH_STATISTICS_EXPORT_FORM_READY),
  branchStatisticsExportFormSave: (form, scrollToError = () => {}) => dispatch(actions.BRANCH_STATISTICS_EXPORT_FORM_SAVE, { form, scrollToError }),
  branchStatisticsExportMessageSet: message => dispatch(actions.BRANCH_STATISTICS_EXPORT_MESSAGE_SET, message),
  // Export
  branchesExportFormGet: () => {
    handlers.formSet('branchesExport', branchesExportFormTransform())
    handlers.branchesExportFormReady()
  },
  branchesExportFormReady: () => dispatch(actions.BRANCHES_EXPORT_FORM_READY),
  branchesExportFormSave: (form, scrollToError = () => {}) => dispatch(actions.BRANCHES_EXPORT_FORM_SAVE, { form, scrollToError })
})

// Reset
reducer.BRANCHES_RESET = state => reducer.initialState

// Search
reducer.BRANCHES_LIST_SEARCH = state => ({
  ...state,
  pending: true
})

// List
reducer.BRANCHES_LIST_GET = state => ({
  ...state,
  pending: true
})
reducer.BRANCHES_LIST_POPULATE = (state, data = []) => ({
  ...state,
  list: data,
  searchList: [],
  pending: false,
  pendingPreview: false,
  areFetched: true
})
reducer.BRANCHES_LIST_POPULATE_SEARCH = (state, data = []) => ({
  ...state,
  searchList: data,
  pending: false
})
reducer.BRANCHES_LIST_READY = state => ({
  ...state,
  pending: false
})
reducer.BRANCHES_LIST_PENDING = state => ({
  ...state,
  pending: true
})

// One
reducer.BRANCHES_UPDATE = (state, item) => {
  if (!item) return state
  return {
    ...state,
    list: state.list
      .filter(listItem => listItem.id !== item.id)
      .concat([{ ...item, isUpdated: true }])
      .sort(sortByOrderIndex)
  }
}

reducer.BRANCHES_UPDATED = (state, data) => {
  if (!data) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === data.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}

reducer.BRANCH_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}

reducer.BRANCH_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

// Form

reducer.BRANCH_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

reducer.BRANCH_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

// Preview

reducer.BRANCHES_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCHES_PREVIEW_POPULATE = (state, branch) => {
  const branches = [...(state.list || [])]
  const { id } = branch || {}
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return { ...state, pendingPreview: false, pending: false }
  branches[index] = { ...branch, ...branches[index] }
  if (branches[index].resources && branches[index].resources.resources && branch.resources) {
    branches[index].resources.resources = branches[index].resources.resources.map(item => ({
      ...item,
      ...(branch.resources.find(resource => resource.id === item.id) || {})
    }))
  }

  return {
    ...state,
    list: branches,
    pendingPreview: false,
    pending: false
  }
}

reducer.BRANCHES_PREVIEW_COMPANY_CONFIRM = (state, data) => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCHES_PREVIEW_COMPANY_CONFIRMED = (state, data) => {
  const { resourceId, branchId, confirmationError } = data || {}
  if (!resourceId || !branchId) return { ...state, pendingPreview: false }
  let { list: branchesList } = state
  branchesList = branchesList || []
  const index = branchesList.findIndex(item => item.id === branchId)
  if (index === -1) return { ...state, pendingPreview: false }
  const branch = branchesList[index] || {}
  let { resources: branchResources } = branch || {}
  branchResources = branchResources || {}
  let { resources: branchResourcesList } = branchResources || {}
  branchResourcesList = branchResourcesList || []
  const updatedResourceIndex = branchResourcesList.findIndex(branchResource => branchResource.id === resourceId)
  if (updatedResourceIndex === -1) return { ...state, pendingPreview: false }
  if (confirmationError) {
    branchResourcesList[updatedResourceIndex] = {
      ...branchResourcesList[updatedResourceIndex],
      confirmationError
    }
  } else {
    branchResourcesList[updatedResourceIndex] = {
      ...branchResourcesList[updatedResourceIndex],
      account: {
        ...branchResourcesList[updatedResourceIndex].account,
        isConfirmed: true
      },
      confirmationError: null,
      pendingCompanyConfirm: false
    }
  }
  branchesList[index] = {
    ...branch,
    resources: {
      ...branch.resources,
      resources: branchResourcesList
    }
  }

  return {
    ...state,
    list: branchesList,
    pendingPreview: false
  }
}

reducer.BRANCHES_PREVIEW_CONFIRMATION_RESEND = state => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCHES_PREVIEW_CONFIRMATION_RESEND_SENDED = (state, data) => {
  const { resourceId, branchId } = data || {}
  if (!resourceId || !branchId) return { ...state, pendingPreview: false }
  let { list: branchesList } = state
  branchesList = branchesList || []
  const index = branchesList.findIndex(item => item.id === branchId)
  if (index === -1) return { ...state, pendingPreview: false }
  const branch = branchesList[index] || {}
  let { resources: branchResources } = branch || {}
  branchResources = branchResources || {}
  let { resources: branchResourcesList } = branchResources || {}
  branchResourcesList = branchResourcesList || []
  const updatedResourceIndex = branchResourcesList.findIndex(branchResource => branchResource.id === resourceId)
  if (updatedResourceIndex === -1) return { ...state, pendingPreview: false }
  branchResourcesList[updatedResourceIndex] = {
    ...branchResourcesList[updatedResourceIndex],
    isConfirmationSent: true
  }
  branchesList[index] = {
    ...branch,
    resources: {
      ...branch.resources,
      resources: branchResourcesList
    }
  }

  return {
    ...state,
    list: branchesList,
    pendingPreview: false
  }
}

reducer.BRANCHES_PREVIEW_PASSWORD_RESET_SEND = (state, data) => {
  const branches = [...state.list]
  const { branchId, resourceId } = data || {}
  const index = branches.findIndex(item => item.id === branchId)
  if (index === -1) return state
  const branch = branches[index] || {}
  const branchResources = branch.resources || {}
  branches[index] = {
    ...branch,
    resources: {
      ...branch.resources,
      resources: [...branchResources.resources].map(item => item.id === resourceId
        ? {
            ...item,
            pendingPasswordResetSend: true,
            arePasswordResetSended: false
          }
        : item
      )
    }
  }

  return {
    ...state,
    list: branches,
    pendingPreview: true
  }
}

reducer.BRANCHES_PREVIEW_PASSWORD_RESET_SENDED = (state, data) => {
  const branches = [...state.list]
  const { branchId, resourceId } = data || {}
  const index = branches.findIndex(item => item.id === branchId)
  if (index === -1) return state
  const branch = branches[index] || {}
  const branchResources = branch.resources
  branches[index] = {
    ...branch,
    resources: {
      ...branch.resources,
      resources: [...branchResources.resources].map(item => item.id === resourceId
        ? {
            ...item,
            pendingPasswordResetSend: false,
            arePasswordResetSended: true
          }
        : item
      )
    }
  }

  return {
    ...state,
    list: branches,
    pendingPreview: false
  }
}

reducer.BRANCHES_RESOURCE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_RESOURCE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_RESOURCE_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_RESOURCE_UPDATE = (state, { branchId, resource }) => {
  resource = resource || {}
  let { list: branchesList } = state
  branchesList = branchesList || []
  const index = branchesList.findIndex(item => item.id === branchId)
  if (index === -1) return { ...state, pendingPreview: false }
  const branch = branchesList[index] || {}
  let { resources: branchResources } = branch || {}
  branchResources = branchResources || {}
  let { resources: branchResourcesList } = branchResources || {}
  branchResourcesList = branchResourcesList || []
  const updatedResourceIndex = branchResourcesList.findIndex(branchResource => branchResource.id === resource.id)
  if (updatedResourceIndex === -1) return { ...state, pendingPreview: false }
  branchResourcesList[updatedResourceIndex] = resource
  branchesList[index] = {
    ...branch,
    resources: {
      ...branch.resources,
      resources: branchResourcesList
    }
  }

  return {
    ...state,
    list: branchesList,
    pendingPreview: false
  }
}
reducer.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Unlink

reducer.BRANCH_RESOURCE_FORM_EMAIL_UNLINK = state => ({
  ...state,
  pendingEmailUnlink: true
})

reducer.BRANCH_RESOURCE_FORM_EMAIL_UNLINK_READY = state => ({
  ...state,
  pendingEmailUnlink: false
})

reducer.BRANCH_RESOURCE_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCH_RESOURCE_DELETED = (state, { id, branchId }) => {
  if (!id) return state
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === branchId)
  const branch = branches[index]
  let branchResourcesList = branch.resources.resources || []
  branchResourcesList = branchResourcesList.length === 1 ? [] : branchResourcesList.filter(item => item.id !== id)
  const updatedBranch = { ...branch, resources: { ...branch.resources, resources: branchResourcesList } }
  branches[index] = updatedBranch
  return {
    ...state,
    list: branches,
    pendingPreview: false
  }
}

reducer.BRANCH_RESOURCE_DELETE_FAILED = state => ({
  ...state,
  pendingPreview: false
})

reducer.BRANCH_RESOURCE_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

reducer.BRANCH_RESOURCE_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

reducer.BRANCH_SERVICE_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCH_SERVICE_DELETED = (state, { id, branchId }) => {
  if (!id) return state
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === branchId)
  const branch = branches[index]
  let branchServicesList = branch.services.services || []
  branchServicesList = branchServicesList.length === 1 ? [] : branchServicesList.filter(item => item.id !== id)
  const updatedBranch = { ...branch, services: branchServicesList }
  branches[index] = updatedBranch
  return {
    ...state,
    list: branches,
    pendingPreview: false
  }
}

reducer.BRANCHES_SERVICE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_SERVICE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_SERVICE_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_SERVICE_COMBINATION_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_SERVICE_COMBINATION_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_SERVICE_COMBINATION_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_GROUP_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_GROUP_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_GROUP_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCH_COURSE_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCH_COURSE_DELETED = (state, { id, branchId }) => {
  if (!id) return state
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === branchId)
  const branch = branches[index]
  let branchCoursesList = branch.groups.courses || []
  branchCoursesList = branchCoursesList.length === 1 ? [] : branchCoursesList.filter(item => item.id !== id)
  const updatedBranch = { ...branch, groups: { ...branch.groups, courses: branchCoursesList } }
  branches[index] = updatedBranch
  return {
    ...state,
    list: branches,
    pendingPreview: false
  }
}

reducer.BRANCHES_CUSTOMER_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_CUSTOMER_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_CUSTOMER_FORM_SAVE = state => ({
  ...state,
  pendingForm: true,
  areCustomersUpdated: true
})

reducer.BRANCH_CUSTOMER_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.BRANCH_CUSTOMER_DELETED = (state, { id, branchId }) => {
  if (!id) return state
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === branchId)
  const branch = branches[index]
  let branchCustomersList = branch.customers || []
  branchCustomersList = branchCustomersList.length === 1 ? [] : branchCustomersList.filter(item => item.id !== id)
  const updatedBranch = { ...branch, customers: branchCustomersList }
  branches[index] = updatedBranch
  return {
    ...state,
    list: branches,
    pendingPreview: false
  }
}

reducer.BRANCH_PREVIEW_RESOURCES_GET = state => state

reducer.BRANCH_PREVIEW_RESOURCES_POPULATE = (state, { id, resources }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    resources,
    areResourcesFetched: true
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCHES_PREVIEW_ACCOUNT_UNLOCK = state => state

reducer.BRANCHES_PREVIEW_ACCOUNT_UNLOCK_READY = (state, { resourceId, branchId }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === branchId)
  if (index === -1) return state
  const resources = [...(branches[index].resources || {}).resources].map(item => {
    if (item.id !== resourceId) return item
    return { ...item, userLoginBlockedUntil: null }
  })

  branches[index] = {
    ...branches[index],
    resources: {
      ...branches[index].resources,
      resources
    }
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCH_PREVIEW_SERVICES_GET = state => state

reducer.BRANCH_PREVIEW_SERVICES_POPULATE = (state, { id, services }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    services,
    areServicesFetched: true
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCH_PREVIEW_GROUPS_GET = state => state

reducer.BRANCH_PREVIEW_GROUPS_POPULATE = (state, { id, groups }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    groups,
    areGroupsFetched: true
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCH_PREVIEW_CUSTOMERS_GET = state => state

reducer.BRANCH_PREVIEW_CUSTOMERS_POPULATE = (state, { id, customers }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    customers,
    areCustomersFetched: true
  }
  return {
    ...state,
    list: branches,
    areCustomersUpdated: false
  }
}

// Statistics
reducer.BRANCHES_STATISTICS_FILTERS_GET = state => state

reducer.BRANCH_PREVIEW_STATISTICS_OVERALL_GET = (state, id) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    areStatisticsFetched: false
  }
  return {
    ...state,
    list: branches,
    pendingPreview: true
  }
}

reducer.BRANCH_PREVIEW_STATISTICS_OVERALL_POPULATE = (state, { id, overallStatistics }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    overallStatistics
  }
  return {
    ...state,
    list: branches,
    pendingPreview: false
  }
}

reducer.BRANCH_PREVIEW_STATISTIC_OVERALL_UPDATE = (state, { overallStatistics }) => ({
  ...state,
  overallStatistics
})

reducer.BRANCH_PREVIEW_STATISTICS_GET = (state, id) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    areStatisticsFetched: false
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCH_PREVIEW_STATISTICS_POPULATE = (state, { id, dailyStatistics, monthlyStatistics }) => {
  const branches = [...state.list]
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    dailyStatistics,
    monthlyStatistics,
    areStatisticsFetched: true
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCHES_STATISTICS_MOST_BOOKED_RESOURCES_FILTERS_GET = state => state

reducer.BRANCH_PREVIEW_STATISTIC_FILTER = (state, { id, statisticId, isOverall }) => {
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    [`pending${statisticId}${isOverall ? 'Overall' : ''}`]: true
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCH_PREVIEW_STATISTIC_UPDATE = (state, { id, statisticId, filteredDailyStatistic, filteredMonthlyStatistic, filteredOverallStatistic, statisticsId, isOverall }) => {
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === id)
  if (index === -1) return state
  if (!isOverall) {
    branches[index] = {
      ...branches[index],
      dailyStatistics: {
        ...branches[index].dailyStatistics,
        [statisticId]: filteredDailyStatistic
      },
      monthlyStatistics: {
        ...branches[index].monthlyStatistics,
        [statisticId]: filteredMonthlyStatistic
      },
      [`pending${statisticsId || statisticId}`]: false
    }
  } else {
    branches[index] = {
      ...branches[index],
      overallStatistics: {
        ...branches[index].overallStatistics,
        [statisticId]: filteredOverallStatistic
      },
      [`pending${statisticsId || statisticId}Overall`]: false
    }
  }
  return {
    ...state,
    list: branches,
    isThereFilteredStatistic: true
  }
}

reducer.BRANCH_PREVIEW_STATISTIC_OVERALL_FILTER = (state, { companyId, statisticId }) => {
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === companyId)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    [`pending${statisticId}Overall`]: true
  }
  return {
    ...state,
    list: branches
  }
}

reducer.BRANCH_PREVIEW_STATISTIC_OVERALL_UPDATE = (state, { companyId, statisticId, filteredStatistic }) => {
  const branches = state.list || []
  const index = branches.findIndex(item => item.id === companyId)
  if (index === -1) return state
  branches[index] = {
    ...branches[index],
    overallStatistics: {
      ...branches[index].overallStatistics,
      [statisticId]: filteredStatistic
    },
    [`pending${statisticId}Overall`]: false
  }
  return {
    ...state,
    list: branches,
    isThereFilteredStatistic: true
  }
}

reducer.BRANCH_PREVIEW_STATISTIC_FILTERS_RESET = state => state

// Form

reducer.BRANCHES_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_FORM_SAVE_CREATE = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_FORM_SAVE_EDIT = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCHES_LOGIN = state => state

// branches import
reducer.BRANCHES_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCHES_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCHES_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCHES_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportMessage: true
})

// Branch resources import

reducer.BRANCH_RESOURCES_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_RESOURCES_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCH_RESOURCES_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_RESOURCES_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportResourcesMessage: true,
  importResourcesType: 'resource'
})

// Branch resource categories import

reducer.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_RESOURCE_CATEGORIES_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportResourcesMessage: true,
  importResourcesType: 'resource category'
})

// Branch services import

reducer.BRANCH_SERVICES_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_SERVICES_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCH_SERVICES_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_SERVICES_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportServicesMessage: true,
  importServicesType: 'service'
})

// Branch service categories import

reducer.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_SERVICE_CATEGORIES_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportServicesMessage: true,
  importServicesType: 'service category'
})

// Branch groups import

reducer.BRANCH_GROUPS_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_GROUPS_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCH_GROUPS_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_GROUPS_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportGroupsMessage: true,
  importGroupsType: 'group'
})

// Branch group categories import

reducer.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_GET = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_READY = state => ({
  ...state,
  pendingImportForm: false
})

reducer.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_SUBMIT = state => ({
  ...state,
  pendingImportForm: true
})

reducer.BRANCH_GROUP_CATEGORIES_IMPORT_SHOW_MESSAGE = state => ({
  ...state,
  showImportGroupsMessage: true,
  importGroupsType: 'group category'
})

reducer.BRANCH_HIDE_IMPORT_SERVICE_MESSAGES = state => ({
  ...state,
  showImportServicesMessage: false,
  importServicesType: ''
})

reducer.BRANCH_HIDE_IMPORT_GROUP_MESSAGES = state => ({
  ...state,
  showImportGroupsMessage: false,
  importGroupsType: ''
})

reducer.BRANCH_HIDE_IMPORT_RESOURCE_MESSAGES = state => ({
  ...state,
  showImportResourcesMessage: false,
  importResourcesType: ''
})

// CSV exports
reducer.BRANCHES_CSV_DOWNLOAD = state => state

// Export

reducer.BRANCH_STATISTICS_EXPORT_FORM_GET = state => ({
  ...state,
  pendingForm: true,
  messageExport: null
})

reducer.BRANCH_STATISTICS_EXPORT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCH_STATISTICS_EXPORT_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BRANCH_STATISTICS_EXPORT_MESSAGE_SET = (state, message) => ({
  ...state,
  messageExport: message
})

reducer.BRANCHES_EXPORT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BRANCHES_EXPORT_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
