import React from 'react'

import {
  AppsSkeleton,
  AppsSubscriptionInvoiceRow
} from '../../Beauties'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  StripedTitle,
  Form,
  FormText,
  Radio,
  HintText,
  Row,
  Col,
  t,
  Input,
  FontAwesome5,
  BorderedBox,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Error,
  Spinner
} from '../../Common'
import { dangerousHTML } from '../../Utils'

const AppsUninstall = (props) => {
  const {
    name,
    version,
    plan,
    onSubmit,
    unsubscribedAt,
    hasActiveSubscription,
    isTrial,
    activeUntil,
    trialDaysLeft,
    isBlocked,
    togglePasswordFieldType,
    passwordFieldType,
    passwordFieldValue,
    isOwner
  } = props
  let isPending = false
  let description = ''
  if (unsubscribedAt && !isBlocked) {
    description = t('apps.subscriptionInfo.cancelled.text', [{ key: 'DATE', value: activeUntil }])
    if (!activeUntil) isPending = true
  }
  if (hasActiveSubscription) {
    description = t('apps.subscriptionInfo.active.text')
    isPending = false
  }
  if (isTrial) {
    description = t('apps.subscriptionInfo.trial.text', [{ key: 'DAYS_LEFT', value: trialDaysLeft }])
    isPending = false
  }
  const cancelButtonClassNames = ['ta-btn', 'ta-btn-block', 'ta-btn-black-ghost']
  if (!isOwner) cancelButtonClassNames.push('disabled')
  const uninstallButtonClassNames = ['ta-btn', 'ta-btn-block', 'ta-btn-primary']
  if (hasActiveSubscription || !passwordFieldValue) uninstallButtonClassNames.push('disabled')

  return (
    <AppsSkeleton
      name={name}
      version={version}
      plan={plan}
      className='ta-apps__uninstall'
      headerText={t('apps.uninstall.header')}
      headerImage='/images/paidApps/banner-uninstall.png'
    >
      {(unsubscribedAt || hasActiveSubscription) && (
        <StripedTitle label={t('apps.subscriptionInfo.title')} />
      )}
      {isTrial && (
        <StripedTitle label={t('apps.freeTrial.title')} />
      )}
      {(isPending &&
        <div className='relative'>
          <Spinner />
        </div>
      )}
      {(!isPending &&
        <AppsSubscriptionInvoiceRow description={description} />
      )}
      {hasActiveSubscription && (
        <HoverPopup disabled={isOwner}>
          <HoverPopupContent position='top'>
            {t('apps.uninstall.form.cancel.button.tooltip.admin')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <PopupTriggerWrapper name='apps-subscription-cancel' position='top' disable={!isOwner}>
              <button className={cancelButtonClassNames.join(' ')}>
                {t('apps.subscription.cancel')}
              </button>
            </PopupTriggerWrapper>
          </HoverPopupTrigger>
        </HoverPopup>
      )}
      {(unsubscribedAt || hasActiveSubscription || isTrial) && (
        <StripedTitle label={t('apps.form.appData.title')} topOffset />
      )}
      <Form name='appUninstall' onSubmit={onSubmit}>
        <FormText>{t('apps.form.appData.text')}</FormText>
        <Row>
          <Col>
            <Radio
              name='keepData'
              label={t('apps.form.appData.keepData.label')}
              value
              hideError
              disabled={hasActiveSubscription}
            />
            <HintText>{t('apps.form.appData.keepData.hint')}</HintText>
          </Col>
        </Row>
        <Row>
          <Col>
            <Radio
              name='keepData'
              label={t('apps.form.appData.wipeData.label')}
              value={false}
              hideError
              disabled={hasActiveSubscription}
            />
            <HintText>{dangerousHTML(t('apps.form.appData.wipeData.hint'))}</HintText>
          </Col>
        </Row>
        <Row>
          <Col>
            <BorderedBox noOffset>
              <div className='ta-apps__skeleton__header-text'>{t('user.preview.tabDetails.buttonCancel.tooltip.text')}</div>
              <Row>
                <Col className='relative'>
                  <Input
                    name='password'
                    dataTestid='password'
                    label={t('global.password')}
                    mandatory
                    disabled={hasActiveSubscription}
                  />
                  <div className={`ta-login__password-type-toggle-btn ${hasActiveSubscription ? 'disabled' : ''}`} onClick={togglePasswordFieldType}>
                    <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
            </BorderedBox>
          </Col>
        </Row>
        <Row noOffset>
          <Col>
            <Error name='globalErrors' noTopOffset />
          </Col>
        </Row>
        <HoverPopup disabled={!hasActiveSubscription}>
          <HoverPopupContent position='top'>
            {isOwner && t('apps.uninstall.form.uninstall.button.tooltip.owner')}
            {!isOwner && t('apps.uninstall.form.uninstall.button.tooltip.admin')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <button
              className={uninstallButtonClassNames.join(' ')}
              type='submit'
            >
              {t('buttons.uninstall.app')}
            </button>
          </HoverPopupTrigger>
        </HoverPopup>
      </Form>
    </AppsSkeleton>
  )
}

export default AppsUninstall
