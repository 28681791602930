export default (timelines, limits) => {
  timelines = timelines || []
  limits = limits || {}

  return timelines.map(timeline => {
    let unavailableTimes = [{ begin: limits.min || '00:00', end: limits.max || '24:00' }]
    if (timeline.isActive) {
      const combinedTimes = [...timeline.times1]
      combinedTimes.forEach(timeRange => {
        let newUnavailableTimes = [...unavailableTimes]
        unavailableTimes.forEach(unavailableRange => {
          if (unavailableRange.begin < timeRange.begin && unavailableRange.end > timeRange.end) {
            newUnavailableTimes = newUnavailableTimes.filter(item => !(item.begin === unavailableRange.begin && item.end === unavailableRange.end))
            newUnavailableTimes = [
              ...newUnavailableTimes,
              { begin: unavailableRange.begin, end: timeRange.begin },
              { begin: timeRange.end, end: unavailableRange.end }
            ]
          } else if (unavailableRange.begin < timeRange.begin && unavailableRange.end > timeRange.begin) {
            newUnavailableTimes = newUnavailableTimes.filter(item => !(item.begin === unavailableRange.begin && item.end === unavailableRange.end))
            newUnavailableTimes = [
              ...newUnavailableTimes,
              { begin: unavailableRange.begin, end: timeRange.begin }
            ]
          } else if (unavailableRange.begin > timeRange.begin && unavailableRange.begin < timeRange.end && unavailableRange.end > timeRange.end) {
            newUnavailableTimes = newUnavailableTimes.filter(item => !(item.begin === unavailableRange.begin && item.end === unavailableRange.end))
            newUnavailableTimes = [
              ...newUnavailableTimes,
              { begin: timeRange.end, end: unavailableRange.end }
            ]
          } else if (unavailableRange.begin > timeRange.begin && unavailableRange.end < timeRange.end) {
            newUnavailableTimes = newUnavailableTimes.filter(item => !(item.begin === unavailableRange.begin && item.end === unavailableRange.end))
          }
        })
        unavailableTimes = [...newUnavailableTimes]
      })
    }

    return {
      index: timeline.index,
      unavailableTimes
    }
  })
}
