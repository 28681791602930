import React from 'react'
import {
  Title,
  Link,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Alert,
  Blur,
  t
} from '../../../Common'
import { PopupTriggerWrapper, ResourceCategoryPreviewChangedLocallyWrapper } from '../../../Beasts'
import { ResourceCategoryPreviewDetails } from '../../../Beauties'

import './ResourceCategoryPreview.css'

const ResourceCategoryPreview = props => {
  const {
    category,
    hash,
    message,
    enterpriseId,
    globalResourceCategoriesRead,
    globalResourceCategoriesWrite,
    globalResourceCategoriesDelete
  } = props
  const availableTabs = ['details', 'changedLocally']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-customer-fields__preview']
  const { id, internalId, isUpdatedLocally, isGloballyDeleted } = category
  const isGlobal = enterpriseId && internalId
  const edited = isGlobal && isUpdatedLocally
  if (edited) classNames.push('edited global')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const label = globalResourceCategoriesRead
    ? category.name === 'default' ? t('resources.list.sectionDefault.title') : category.name
    : t('global.accessDenied')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-resource-categories__title'>
            <Title label={label || ''}>
              {(globalResourceCategoriesWrite && category.id &&
                <>
                  {(globalResourceCategoriesDelete &&
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('resource.preview.category.delete')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <PopupTriggerWrapper name='resource-category-delete' position='bottom' id={category.id}>
                          <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                            <FontAwesome5 icon='trash' />
                          </Link>
                        </PopupTriggerWrapper>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('resource.preview.category.edit')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link to={`/management/resource-categories/${category.id}/edit`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalResourceCategoriesRead ? 'inactive' : ''} active={!globalResourceCategoriesRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && isGlobal && !isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                <p>{t('globalEntities.preview.locallyEdittedWarning')}</p>
                <PopupTriggerWrapper name='customer-fields-reset-global-entity' position='right' id={{ internalId }}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(isGloballyDeleted && isGlobal &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                <p>{t('globalEntities.preview.removedFromDatabase')}</p>
                <PopupTriggerWrapper name='customer-fields-delete-global-entity' position='right' id={id}>
                  <Link className='ta-btn ta-btn-primary ta-btn-block ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Link>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(category.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
                  <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalResourceCategoriesRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <ResourceCategoryPreviewDetails category={category} />
                  </SimpleTabsBodyItem>
                  <SimpleTabsBodyItem name='changedLocally'>
                    <ResourceCategoryPreviewChangedLocallyWrapper />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default ResourceCategoryPreview
