import { BorderedBox, FontAwesome5, t } from '../../Common'
import { getAttributeFieldLabel } from '../../Utils'

const AttributesListItem = ({ attribute, onClick }) => {
  const { id, name, fieldType, isAvailableInWidget } = attribute || {}
  const classNames = ['ta-attributes__attribute']
  if (isAvailableInWidget) classNames.push('available-in-widget')

  return (
    <BorderedBox className={classNames.join(' ')} onClick={() => onClick(id)}>
      <div className='ta-attributes__attribute__name'>{name}</div>
      <div className='ta-attributes__attribute__type'>{t(getAttributeFieldLabel(fieldType))}</div>
      <div className='ta-attributes__attribute__visibility'>
        <FontAwesome5 icon='eye' />
        {isAvailableInWidget && t('global.widget') + ' & '}
        {t('global.companyUsers')}
      </div>
    </BorderedBox>
  )
}

export default AttributesListItem
