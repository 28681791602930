import React from 'react'
import {
  FormSection,
  SectionTitle,
  Row,
  Col,
  BorderedBox,
  Checkbox,
  FormText,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'
import { ServiceRemindersSelect, ServiceOtherRecipientsSelect } from '../../../../Beauties'
import './BranchesServiceForm.css'

const BranchesServiceFormReminders = props => {
  const {
    plan,
    serviceCustomReminderCustomerSwitch,
    serviceReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    serviceCustomReminderStaffSwitch,
    serviceReminderStaffSwitch,
    serviceReminderStaffOthersCheckBox,
    serviceReminderStaffOthers,
    resourceEmailRemindersMinutes,
    allowedSet
  } = props

  return (
    <FormSection>
      <SectionTitle label={t('servicesGroups.reminder.heading')} icon='bell' ignoreTopOffset />
      <Row>
        <Col>
          <Row>
            <Col>
              <FormText noOffset>{dangerousHTML(t('servicesGroups.reminder.questionText'))}</FormText>
            </Col>
          </Row>
          <Row>
            <Col>
              <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('customerEmailRemindersMinutes')}>
                <HoverPopupContent position='top'>
                  {!allowedSet.includes('customerEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('customerEmailRemindersMinutes')} name='serviceCustomReminderCustomerSwitch' label={t('servicesGroups.reminder.customers.custom.checkbox.label')} theme='switch' />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
          {(serviceCustomReminderCustomerSwitch &&
            <BorderedBox>
              <Row>
                <Col>
                  <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('customerEmailRemindersMinutes')}>
                    <HoverPopupContent position='top'>
                      {!allowedSet.includes('customerEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('customerEmailRemindersMinutes')} name='serviceReminderCustomerSwitch' label={t('servicesGroups.reminder.customers.checkbox.label')} theme='switch' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(serviceReminderCustomerSwitch &&
                <Row className='ta-row__no-margin'>
                  <Col>
                    <ServiceRemindersSelect
                      formName='branchesService'
                      isDisabled={plan !== 'ENTERPRISE' || !allowedSet.includes('customerEmailRemindersMinutes')}
                      disabledKey={!allowedSet.includes('customerEmailRemindersMinutes') ? 'globalSettings.form.section.attributes.disabled' : undefined}
                      reminders={customerEmailRemindersMinutes}
                      name='customerEmailRemindersMinutes'
                    />
                  </Col>
                </Row>
              )}
            </BorderedBox>
          )}
          <Row>
            <Col>
              <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('resourceEmailRemindersMinutes')}>
                <HoverPopupContent position='top'>
                  {!allowedSet.includes('resourceEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('resourceEmailRemindersMinutes')} name='serviceCustomReminderStaffSwitch' label={t('servicesGroups.reminder.staff.custom.checkbox.label')} theme='switch' />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
          {(serviceCustomReminderStaffSwitch &&
            <BorderedBox>
              <Row>
                <Col>
                  <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('resourceEmailRemindersMinutes')}>
                    <HoverPopupContent position='top'>
                      {!allowedSet.includes('resourceEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('resourceEmailRemindersMinutes')} name='serviceReminderStaffSwitch' label={t('servicesGroups.reminder.staff.checkbox.label')} theme='switch' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(serviceReminderStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <BorderedBox className='ta-services__form--outer-box'>
                        <Row>
                          <Col>
                            <Checkbox disabled name='serviceReminderStaffOwnerCheckBox' label={t('global.owner')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox disabled name='serviceReminderStaffResourcesCheckBox' label={t('servicesGroups.resources')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox disabled name='serviceReminderStaffOthersCheckBox' label={t('servicesGroups.others')} />
                          </Col>
                        </Row>
                        {(serviceReminderStaffOthersCheckBox &&
                          <ServiceOtherRecipientsSelect
                            recipients={serviceReminderStaffOthers}
                            name='serviceReminderStaffOthers'
                          />
                        )}
                      </BorderedBox>
                    </Col>
                  </Row>
                  <ServiceRemindersSelect
                    formName='branchesService'
                    isDisabled={plan !== 'ENTERPRISE' || !allowedSet.includes('resourceEmailRemindersMinutes')}
                    disabledKey={!allowedSet.includes('resourceEmailRemindersMinutes') ? 'globalSettings.form.section.attributes.disabled' : undefined}
                    reminders={resourceEmailRemindersMinutes}
                    name='resourceEmailRemindersMinutes'
                  />
                </BorderedBox>
              )}
            </BorderedBox>
          )}
        </Col>
      </Row>
    </FormSection>
  )
}

export default BranchesServiceFormReminders
