import React from 'react'
import { connect, handlers } from '../../Store'
import { SectionTitle, Link, t } from '../../Common'

const FormDiscardPopup = props => {
  let { isActive } = props
  isActive = !!isActive
  const classNames = ['ta-form__discard-popup']
  if (isActive) classNames.push('active')

  const onClickNo = () => {
    handlers.formDiscardPopupReset(null)
  }

  const onClickYes = () => {
    let { redirectUrl, router } = props
    redirectUrl = redirectUrl || null
    router = router || {}
    let { props: routerProps } = router || {}
    routerProps = routerProps || {}
    let { formDiscard } = routerProps || {}
    handlers.formDiscardPopupReset(formDiscard)
    redirectUrl && handlers.navigateToPath(redirectUrl)
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-form__discard-popup__overlay' />
      <div className='ta-form__discard-popup__content'>
        <SectionTitle label={t('global.discardChangesPopUp.title')} icon='save' ignoreTopOffset />
        <Link className='ta-btn ta-btn-secondary' onClick={onClickNo}>{t('buttons.cancel.label')}</Link>
        <Link className='ta-btn ta-btn-primary' onClick={onClickYes}>{t('global.discard')}</Link>
      </div>
    </div>
  )
}

const maps = state => ({
  isActive: state.forms.isDiscardPopupActive,
  redirectUrl: state.forms.discardRedirectUrl,
  router: state.router
})

export default connect(maps)(FormDiscardPopup)
