import React from 'react'

const SimpleListItemTitle = props => {
  const { title, htmlTitle, children } = props

  return (
    <div className='ta-simple-list-item__title'>
      <div className='ta-simple-list-item__title-text' title={htmlTitle || title}>{title}</div>
      {children &&
        <div className='ta-simple-list-item__title-icon'>{children}</div>
      }
    </div>
  )
}

export default SimpleListItemTitle
