import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomerPreviewBookingsDataFieldItem } from '../../../Beauties'
import { getNameByColor, dangerousHTML } from '../../../Utils'
import {
  SectionTitle,
  FontAwesome5,
  InfiniteScroll,
  InfiniteScrollLoader,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  Loader,
  Avatar,
  t,
  format,
  ListButton,
  SimpleListItemButtons,
  Alert,
  SingleSelect,
  Row,
  Col,
  Form
} from '../../../Common'

const CustomerPreviewBookings = ({ bookings = [], ...props }) => {
  const {
    loadMoreBookings,
    hasMoreBookings,
    activeTab,
    pending,
    timezones,
    showBookingOnCalendar,
    message,
    plan,
    hash,
    messageBookings,
    onClickPrintBookings,
    getCustomerBookings,
    bookingsType,
    resources
  } = props
  const [dataFieldsPreview, setDataFieldsPreview] = useState({
    isActive: false,
    activeId: null
  })
  const loader = <InfiniteScrollLoader key='' />
  const toggleDataFieldsPreview = id => {
    if (dataFieldsPreview.activeId !== null && id !== dataFieldsPreview.activeId) {
      setDataFieldsPreview({
        isActive: true,
        activeId: id
      })
    } else {
      setDataFieldsPreview({
        isActive: !dataFieldsPreview.isActive,
        activeId: !dataFieldsPreview.isActive && id
      })
    }
  }
  const onClick = (id, date, resourceIds, bookingsType) => {
    if (bookingsType === 'DELETED') return
    showBookingOnCalendar(id, date, resourceIds)
  }
  const isUpcomingBooking = bookingsType === 'UPCOMING'
  const isPastBooking = bookingsType === 'PAST'
  const isDeletedBooking = bookingsType === 'DELETED'
  let label = ''
  if (isUpcomingBooking) label = t('customers.list.upcoming')
  if (isPastBooking) label = t('customers.list.past')
  if (isDeletedBooking) label = t('customers.tabBookings.section.cancelled.heading')
  const classNames = []
  if (isPastBooking) classNames.push('past')
  if (isDeletedBooking) classNames.push('deleted')

  return (
    <div className='ta-customers__preview__bookings-wrapper'>
      {(pending && !message &&
        <Loader active />
      )}
      {(message &&
        <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
          {message.text && t(message.text)}
        </Alert>
      )}
      <div className='ta-customers__preview__bookings'>
        {(activeTab === 'bookings' && !message &&
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMoreBookings}
            hasMore={hasMoreBookings}
            loader={loader}
            useWindow={false}
          >
            <Row>
              <Col size={90}>
                <Form name='customerEvents'>
                  <SingleSelect
                    name='type'
                    disabled={pending}
                    label={t('global.view')}
                    onChangeAddon={getCustomerBookings}
                  />
                </Form>
              </Col>
              {(bookings.length > 0 &&
                <Col size={10}>
                  {plan !== 'CLASSIC' && hash === 'bookings' && !messageBookings && (
                    <div className='ta-print-btn ta-btn ta-btn-icon-only' onClick={onClickPrintBookings}>
                      <FontAwesome5 icon='print' />
                    </div>
                  )}
                </Col>
              )}
            </Row>
            {(bookings.length > 0 &&
              <SectionTitle label={label} icon='calendar' />
            )}
            {bookings.map((item, index) => {
              const dateParts = item.interval.from.split(' ')
              const date = dateParts[0]
              const time = dateParts[1]
              const firstResource = item.resources[0] || {}
              const extraResourcesCount = item.resourceIds.length - 1
              const extraTitle = item.isBatch ? ` (${item.interval.batchNumber + 1}/${item.batchCount})` : ''
              const resourceIssued = resources.find(resource => resource.id === (item.issuedByResource && item.issuedByResource.id) || '') || { name: '-' }
              const nameKey = !isDeletedBooking ? item.createdBy : resourceIssued.name || '-'
              const bookingDuration = isDeletedBooking ? item.durationsPattern : item.duration

              return (
                <div
                  onClick={() => onClick(item.id, date, item.resourceIds, bookingsType)}
                  key={index}
                >
                  <SimpleListItem
                    status={getNameByColor(item.color)}
                    className={classNames.join(' ')}
                  >
                    <SimpleListItemTitle title={`${item.title}${extraTitle}`}>
                      {(item.isBookedOnline &&
                        <div className='ta-customers__preview__bookings__booked-online' />
                      )}
                    </SimpleListItemTitle>
                    <SimpleListItemContent>
                      <div className='ta-customers__preview__bookings__resource'>
                        <Avatar
                          name={firstResource.name}
                          image={firstResource.avatarUrl}
                          color={firstResource.color}
                        />
                        {(extraResourcesCount > 0 &&
                          <div className='ta-customers__preview__bookings__resource__extra'>
                            +{extraResourcesCount}
                          </div>
                        )}
                      </div>
                      <ul className='ta-list-inline'>
                        <li><FontAwesome5 icon='calendar' /> {format(date, 'long', { isUTC: true })}</li>
                        <li><FontAwesome5 icon='clock' type='solid' /> {format(time, 'time', { isUTC: true, format: 'HH:mm' })}</li>
                        <li><FontAwesome5 icon='hourglass-half' /> {t('global.min', [{ key: 'MIN', value: bookingDuration }])}</li>
                      </ul>
                      <p>
                        {dangerousHTML(t(`customers.tabBookings.${isDeletedBooking ? 'cancelledByOn' : 'createdByOn'}`, [
                          { key: 'NAME', value: nameKey },
                          { key: 'DATE', value: format(item.createdAt, 'long', { isUTC: true, format: 'YYYY-MM-DD HH:mm' }) || '-' },
                          { key: 'TIME', value: format(item.createdAt, 'time', { format: 'YYYY-MM-DD HH:mm' }) || '-' }
                        ]))}
                      </p>
                    </SimpleListItemContent>
                    {(item.dataFields && item.dataFields.length > 0 &&
                      <SimpleListItemButtons>
                        <ListButton
                          action={() => toggleDataFieldsPreview(index)}
                          icon={index === dataFieldsPreview.activeId ? 'angle-up' : 'angle-down'}
                        />
                      </SimpleListItemButtons>
                    )}
                  </SimpleListItem>
                  {(item.dataFields && item.dataFields.length > 0 && dataFieldsPreview.activeId === index &&
                    <CustomerPreviewBookingsDataFieldItem
                      timezones={timezones}
                      dataFields={item.dataFields}
                    />
                  )}
                </div>
              )
            })}
            {(!pending && !bookings.length &&
              <Alert noOffset label={t('customers.tabBookings.section.past.noResultsBox.text')} theme='no-content' />
            )}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

CustomerPreviewBookings.propTypes = {
  loadMoreBookings: PropTypes.func.isRequired,
  hasMoreBookings: PropTypes.bool.isRequired,
  activeTab: PropTypes.string
}

export default CustomerPreviewBookings
