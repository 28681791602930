import React from 'react'
import PropTypes from 'prop-types'

import './StripedTitle.css'

const StripedTitle = props => {
  const { label, extraText, topOffset, customStyle, className } = props
  const classNames = ['ta-striped-title']
  if (className) classNames.push(className)
  if (topOffset) classNames.push('top-offset')

  return (
    <div className={classNames.join(' ')} data-testid='striped-title' style={customStyle}>
      <div className='ta-striped-title__label'>
        {label}
      </div>
      {extraText && (
        <div className='ta-striped-title__extraText' data-testid='striped-title-extra-text'>
          ({extraText})
        </div>
      )}
    </div>
  )
}

StripedTitle.propTypes = {
  label: PropTypes.string.isRequired
}

export default StripedTitle
