import React from 'react'
import PropTypes from 'prop-types'
import { Link, Form, Row, Col, Input, Error, FontAwesome5, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupBranchDelete = props => {
  const { approve, reject, togglePasswordFieldType, passwordFieldType } = props

  return (
    <div className='ta-popup__branch-delete'>
      <div className='ta-popup__title'>{dangerousHTML(t('popup.branch.delete'))}</div>
      <Form name='branchesDelete' onSubmit={approve}>
        <Row>
          <Col className='relative'>
            <Input
              type={passwordFieldType}
              name='password'
              label={t('global.password')}
              mandatory
            />
            <div className='ta-popup__branch-delete__password-type-toggle-btn' onClick={togglePasswordFieldType}>
              <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
            </div>
          </Col>
        </Row>
        <Error name='globalErrors' noTopOffset />
        <div className='ta-popup__buttons'>
          <Link className='ta-btn ta-btn-secondary' onClick={reject}>
            {t('buttons.cancel.label')}
          </Link>
          <button type='submit' className='ta-btn ta-btn-primary'>
            {t('global.delete')}
          </button>
        </div>
      </Form>
    </div>
  )
}

PopupBranchDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupBranchDelete
