import React from 'react'
import {
  Col,
  FormText,
  Row,
  SectionTitle,
  t
} from '../../../../Common'
import { BranchesServiceFormAllowancePlanWrapper } from '../../../../Beasts'

import './BranchesServiceForm.css'
import { dangerousHTML } from '../../../../Utils'

const BranchesServiceFormAvailability = ({ plan, allowedSet }) => {
  const isAvailabilityDisabled = !allowedSet?.includes('calendar')

  return (
    <>
      <SectionTitle label={t('servicesGroups.section.service.availability')} icon='clock' ignoreTopOffset />
      <Row>
        <Col>
          <FormText noOffset>{dangerousHTML(t('servicesGroups.section.service.availability.description'))}</FormText>
        </Col>
      </Row>
      <BranchesServiceFormAllowancePlanWrapper plan={plan} isAvailabilityDisabled={isAvailabilityDisabled} />
    </>
  )
}

export default BranchesServiceFormAvailability
