import {
  appTransform,
  appUninstallFormTransform,
  appSubscriptionFormTransform,
  appSubscriptionBillingFormTransform,
  appSubscriptionCancelFormTransform,
  appSubscriptionRuleFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  pendingList: true,
  pendingPreview: true,
  selected: null,
  messageAccessKey: null,
  popupShownId: null,
  pendingUninstallForm: false,
  pendingSubscriptionForm: false,
  billingFormLocked: null,
  hasChangesBillingForm: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  appsReset: () => dispatch(actions.APPS_RESET),

  // Subscription
  appsSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.APPS_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  appsListGet: () => dispatch(actions.APPS_LIST_GET),
  appsListPopulate: ({ apps }) => dispatch(actions.APPS_LIST_POPULATE, { apps }),

  // One
  appUpdate: app => dispatch(actions.APP_UPDATE, appTransform(app)),
  appUpdated: app => dispatch(actions.APP_UPDATED, app),
  appMetadataSet: ({ appId, metadata }) => dispatch(actions.ACTION_METADATE_SET, ({ appId, metadata })),
  appLoad: ({ appId, ref, refreshToken }) => dispatch(actions.APP_LOAD, ({ appId, ref, refreshToken })),

  // Installation
  appInstallationGet: ({ id }) => dispatch(actions.APP_INSTALLATION_GET, { id }),

  // Preview
  appPreviewGet: ({ id, forceFetch, oldThreshold }) => dispatch(actions.APP_PREVIEW_GET, { id, forceFetch, oldThreshold }),
  appPreviewPopulate: id => dispatch(actions.APP_PREVIEW_POPULATE, id),

  // Form Subscription
  appSubscriptionFormGet: () => dispatch(actions.APP_SUBSCRIPTION_FORM_GET),
  appSubscriptionFormPopulate: data => {
    handlers.appSubscriptionFormReady()
    handlers.formSet('appSubscription', appSubscriptionFormTransform(data))
  },
  appSubscriptionFormReady: () => dispatch(actions.APP_SUBSCRIPTION_FORM_READY),
  appSubscriptionFormSave: (data, scrollToError = () => { }) => dispatch(actions.APP_SUBSCRIPTION_FORM_SAVE, { data, scrollToError }),
  appSubscriptionBillingFormGet: () => dispatch(actions.APP_SUBSCRIPTION_BILLING_FORM_GET),
  appSubscriptionBillingFormPopulate: data => {
    const formFields = appSubscriptionBillingFormTransform(data)
    dispatch(actions.APP_SUBSCRIPTION_BILLING_FORM_POPULATE, formFields)
    handlers.appSubscriptionBillingFormReady()
    handlers.formSet('appSubscriptionBilling', formFields)
  },
  appSubscriptionBillingFormReady: () => dispatch(actions.APP_SUBSCRIPTION_BILLING_FORM_READY),
  appSubscriptionBillingFormSave: (data, scrollToError = () => { }) => dispatch(actions.APP_SUBSCRIPTION_BILLING_FORM_SAVE, { data, scrollToError }),
  appSubscriptionBillingFormIsUpdated: (hasChanges) => dispatch(actions.APP_SUBSCRIPTION_BILLING_FORM_IS_UPDATED, hasChanges),
  appsSubscriptionCancelFormPopulate: data => {
    handlers.appSubscriptionCancelFormReady()
    handlers.formSet('popup', appSubscriptionCancelFormTransform(data))
  },
  appSubscriptionCancelFormReady: () => dispatch(actions.APP_SUBSCRIPTION_CANCEL_FORM_READY),
  appSubscriptionCancelFormSave: (data) => dispatch(actions.APP_SUBSCRIPTION_CANCEL_FORM_SAVE, { data }),
  appsSubscriptionRuleFormPopulate: data => {
    handlers.appSubscriptionRuleFormReady()
    handlers.formSet('popup', appSubscriptionRuleFormTransform(data))
  },
  appSubscriptionRuleFormReady: () => dispatch(actions.APP_SUBSCRIPTION_RULE_FORM_READY),
  appSubscriptionRuleFormSave: (data) => dispatch(actions.APP_SUBSCRIPTION_RULE_FORM_SAVE, { data }),
  appSubscriptionResubscribeFormReady: () => dispatch(actions.APP_SUBSCRIPTION_RESUBSCRIBE_FORM_READY),
  appSubscriptionResubscribeFormSave: () => dispatch(actions.APP_SUBSCRIPTION_RESUBSCRIBE_FORM_SAVE),
  appSubscriptionMessageSet: message => dispatch(actions.APP_SUBSCRIPTION_MESSAGE_SET, message),

  // App Trial
  appTrialStart: ({ id, externalId }) => dispatch(actions.APP_TRIAL_START, ({ id, externalId })),

  // App Payment Retry
  appPaymentRetry: ({ id, externalId }) => dispatch(actions.APP_PAYMENT_RETRY, ({ id, externalId })),
  appPaymentRetryReady: () => dispatch(actions.APP_PAYMENT_RETRY_READY),

  // Form Uninstall
  appUninstallFormGet: () => dispatch(actions.APP_UNINSTALL_FORM_GET),
  appUninstallFormPopulate: data => {
    handlers.appUninstallFormReady()
    handlers.formSet('appUninstall', appUninstallFormTransform(data))
  },
  appUninstallFormReady: () => dispatch(actions.APP_UNINSTALL_FORM_READY),
  appUninstallFormSave: (data, scrollToError = () => { }) => dispatch(actions.APP_UNINSTALL_FORM_SAVE, { data, scrollToError }),

  // Other
  appInstall: id => dispatch(actions.APP_INSTALL, id),
  appUninstall: id => dispatch(actions.APP_UNINSTALL, id),
  appUninstalledRemove: newAppsList => dispatch(actions.APP_UNINSTALLED_REMOVE, newAppsList),
  appsPrivateAccessKeyGet: () => dispatch(actions.APPS_PRIVATE_ACCESS_KEY_GET),
  appsPrivateAccessKeyReset: () => dispatch(actions.APPS_PRIVATE_ACCESS_KEY_RESET),
  appPopupShownIdSet: id => dispatch(actions.APP_POPUP_SHOWN_ID_SET, id)
})

// Reset

reducer.APPS_RESET = state => reducer.initialState

// Subscription

reducer.APPS_SUBSCRIPTION_SET = state => state

// List

reducer.APPS_LIST_GET = state => ({
  ...state,
  pendingList: true
})

reducer.APPS_LIST_POPULATE = (state, { apps }) => {
  apps = apps || []
  const selected = state.list.find(item => item.id === state.selected)
  return {
    ...state,
    pendingList: false,
    selected: null,
    list: apps
      .map(item => item.id === state.selected ? selected : item)
      .sort((a, b) => { return a.name < b.name ? -1 : 1 })
  }
}

// One

reducer.APP_UPDATE = (state, app) => {
  if (!app) return state
  const currentApp = state.list
    .find(item => item.id === app.id) || {}
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== app.id)
      .concat([{ ...currentApp, ...app, isUpdated: true }])
      .sort((a, b) => { return a.name < b.name ? -1 : 1 })
  }
}

reducer.APP_UPDATED = (state, app) => {
  if (!app) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === app.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}

reducer.ACTION_METADATE_SET = (state, { appId, metadata }) => {
  if (typeof metadata !== 'string') return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === appId)
  if (index < 0) return state
  list[index] = { ...list[index], metadata }
  return {
    ...state,
    list
  }
}

reducer.APP_LOAD = state => state

// Installation
reducer.APP_INSTALLATION_GET = state => state

// Preview

reducer.APP_PREVIEW_GET = state => ({
  ...state
  // pendingPreview: true
})

reducer.APP_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

// Form Subscription

reducer.APP_SUBSCRIPTION_FORM_GET = state => ({
  ...state,
  pendingSubscriptionForm: true
})

reducer.APP_SUBSCRIPTION_FORM_READY = state => ({
  ...state,
  pendingSubscriptionForm: false
})

reducer.APP_SUBSCRIPTION_FORM_SAVE = state => ({
  ...state,
  pendingSubscriptionForm: true
})

reducer.APP_SUBSCRIPTION_BILLING_FORM_GET = state => ({
  ...state,
  pendingSubscriptionBillingForm: true,
  billingFormLocked: null,
  hasChangesBillingForm: false
})

reducer.APP_SUBSCRIPTION_BILLING_FORM_POPULATE = (state, formFields) => {
  const form = { ...formFields }
  const billingFormLocked = JSON.stringify(
    Object.keys(form).reduce((acc, item) => {
      acc[item] = form[item]?.value
      return acc
    }, {})
  )
  return {
    ...state,
    billingFormLocked
  }
}

reducer.APP_SUBSCRIPTION_BILLING_FORM_READY = state => ({
  ...state,
  pendingSubscriptionBillingForm: false
})

reducer.APP_SUBSCRIPTION_BILLING_FORM_SAVE = state => ({
  ...state,
  pendingSubscriptionBillingForm: true
})

reducer.APP_SUBSCRIPTION_BILLING_FORM_IS_UPDATED = (state, hasChanges) => ({
  ...state,
  hasChangesBillingForm: !!hasChanges
})

reducer.APP_SUBSCRIPTION_CANCEL_FORM_READY = state => ({
  ...state,
  pendingSubscriptionCancelForm: false
})

reducer.APP_SUBSCRIPTION_CANCEL_FORM_SAVE = state => ({
  ...state,
  pendingSubscriptionCancelForm: true
})

reducer.APP_SUBSCRIPTION_RULE_FORM_READY = state => ({
  ...state,
  pendingSubscriptionRuleForm: false
})

reducer.APP_SUBSCRIPTION_RULE_FORM_SAVE = state => ({
  ...state,
  pendingSubscriptionRuleForm: true
})

reducer.APP_SUBSCRIPTION_RESUBSCRIBE_FORM_READY = state => ({
  ...state,
  pendingPreview: false
})

reducer.APP_SUBSCRIPTION_RESUBSCRIBE_FORM_SAVE = state => ({
  ...state,
  pendingPreview: true
})

reducer.APP_SUBSCRIPTION_MESSAGE_SET = (state, message) => ({
  ...state,
  messageSubscription: message
})

// App Trial

reducer.APP_TRIAL_START = state => state

// App Payment Retry

reducer.APP_PAYMENT_RETRY = state => ({
  ...state,
  pendingPaymentRetry: true
})

reducer.APP_PAYMENT_RETRY_READY = state => ({
  ...state,
  pendingPaymentRetry: false
})

// Form Uninstall
reducer.APP_UNINSTALL_FORM_GET = state => ({
  ...state,
  pendingUninstallForm: true
})

reducer.APP_UNINSTALL_FORM_READY = state => ({
  ...state,
  pendingUninstallForm: false
})

reducer.APP_UNINSTALL_FORM_SAVE = state => ({
  ...state,
  pendingUninstallForm: true
})

// Other

reducer.APP_INSTALL = state => ({
  ...state,
  pendingPreview: true
})

reducer.APP_UNINSTALL = (state, id) => ({
  ...state,
  list: state.list.map(item => ({ ...item, installedVersion: item.id === id ? null : item.installedVersion }))
})

reducer.APP_UNINSTALLED_REMOVE = (state, newAppsList) => ({
  ...state,
  list: newAppsList,
  pendingPreview: false,
  pendingList: false,
  selected: null
})

reducer.APPS_PRIVATE_ACCESS_KEY_GET = state => state

reducer.APPS_PRIVATE_ACCESS_KEY_RESET = state => state

reducer.APP_POPUP_SHOWN_ID_SET = (state, id) => ({
  ...state,
  popupShownId: state.popupShownId === id ? null : id
})

export default reducer
