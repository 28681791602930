import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  Title,
  Link,
  FontAwesome5,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../Common'
import { PopupTriggerWrapper, CustomerFieldsToDeleteWrapper } from '../../Beasts'
import { CustomerFieldsActive } from '../../Beauties'
import './CustomerFields.css'

const CustomerFields = props => {
  const {
    categories,
    onCategoriesToggle,
    isDragAndDropMode,
    areExpanded,
    onDragStart,
    onDragUpdate,
    onDragEnd,
    hash,
    customerFieldsWrite
  } = props
  const availableTabs = ['activeDataFields', 'deletedDataFields']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeDataFields'
  const listClassNames = ['ta-customer-fields__list']

  return (
    <div className='ta-customer-fields'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-customer-fields__title'>
            {(customerFieldsWrite && isDragAndDropMode &&
              <Title label={t('customerFields.heading')}>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {(areExpanded
                      ? t('global.switchCategoryOrder')
                      : t('customerFields.list.buttonHeading.switchFieldOrderTooltip')
                    )}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link className='ta-btn ta-btn-secondary ta-btn-icon-only' action={onCategoriesToggle}>
                      <FontAwesome5 icon={areExpanded ? 'compress-alt' : 'expand-alt'} />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('global.exitOrdering')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link to='/customers/customer-fields' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                      <FontAwesome5 icon='times' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              </Title>
            )}
            {(!isDragAndDropMode &&
              <Title label={t('customerFields.heading')}>
                <PopupTriggerWrapper name='customer-fields-help-box' position='bottom' extraClassName='ta-help-box'>
                  <Link className='ta-title__link' external>
                    {t('global.help')} <FontAwesome5 icon='lightbulb' />
                  </Link>
                </PopupTriggerWrapper>
                {(customerFieldsWrite &&
                  <>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('global.category.add')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link to='/customers/customer-fields/categories/add' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                          <FontAwesome5 icon='folder' type='solid' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                    {/* Uncomment if there will be ordering */}
                    {/* <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('customerFields.list.buttonHeading.fieldOrderTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link to='/customers/customer-fields/reorder' className='ta-btn ta-btn-secondary ta-btn-icon-only'>
                          <FontAwesome5 icon='arrows-v' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup> */}
                  </>
                )}
              </Title>
            )}
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <div className={listClassNames.join(' ')}>
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='activeDataFields' label={t('customerFields.activeFields')} icon='sliders-h' />
                <SimpleTabsHeaderItem name='deletedDataFields' label={t('customerFields.markedForDelete')} icon='minus-octagon' />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='activeDataFields'>
                  <CustomerFieldsActive
                    categories={categories}
                    isDragAndDropMode={isDragAndDropMode}
                    areExpanded={areExpanded}
                    onDragStart={onDragStart}
                    onDragUpdate={onDragUpdate}
                    onDragEnd={onDragEnd}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem renderOnlyIfActive name='deletedDataFields'>
                  <CustomerFieldsToDeleteWrapper />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomerFields
