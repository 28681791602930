import React from 'react'
import {
  Title,
  SectionTitle,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Row,
  Col,
  Alert,
  Input,
  Error,
  SingleSelect,
  t
} from '../../../Common'
import {
  BranchesPreviewResourceFormDetailsWrapper,
  // BranchesPreviewResourceFormPermissionsWrapper,
  BranchesPreviewResourceFormAllowancePlanWrapper
} from '../../../Beasts'
import { BranchesResourceFormAvatar } from '../../../Beauties'
import { dangerousHTML } from '../../../Utils'

import './BranchesResourceForm.css'

const BranchesResourceForm = props => {
  let {
    branch,
    onSubmit,
    cancelLink,
    type,
    isDependency,
    showExternalIds,
    customisations,
    plan,
    providers
  } = props
  providers = providers || []
  const { settings } = customisations || {}
  const { hideResourcesFormShiftPlanNote } = settings || {}

  const linkToShiftPlanClassNames = ['ta-btn ta-btn-secondary']
  if (type === 'add') linkToShiftPlanClassNames.push('disabled')
  return (
    <div className='ta-branches-resources__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title
              label={t('branches.form.group.edit.resourcesDetails.title')}
              addOn={isDependency && <p className='ta-branches-resources__form__dependency'>{t('branches.form.group.edit.resourcesDetails.title.extraText')}</p>}
            />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesResource'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                      disabled={providers.includes('PRIVATE_APP')}
                      limit={60}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection className='ta-cleaner'>
              <SectionTitle
                label={t('branches.form.group.edit.resourcesDetails.section.details.title')}
                icon='user'
              />
              <BranchesResourceFormAvatar name='avatar' />
              <BranchesPreviewResourceFormDetailsWrapper type={type} branch={branch} />
            </FormSection>
            <FormSection>
              <SectionTitle
                label={t('branches.form.group.edit.resourcesDetails.section.category.title')}
                icon='folder'
              />
              <Row>
                <Col>
                  <SingleSelect
                    disabled={plan !== 'ENTERPRISE'}
                    name='category'
                    label={t('branches.form.group.edit.resourcesDetails.section.category.select.label')}
                    mandatory
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection ignoreBottomOffset>
              <SectionTitle
                label={t('branches.form.group.edit.resourcesDetails.section.workingTimes.title')}
                icon='clock'
              />
              <BranchesPreviewResourceFormAllowancePlanWrapper plan={plan} customisations={customisations} />
              {(!hideResourcesFormShiftPlanNote &&
                <Row>
                  <Col>
                    <Alert
                      noOffset
                      label={dangerousHTML(t('branches.form.group.edit.resourcesDetails.section.workingTimes.warning'))}
                      theme='alert'
                    >
                      {type === 'add' && <p>{dangerousHTML(t('branches.form.group.edit.resourcesDetails.section.workingTimes.warning.add'))}</p>}
                      {type === 'edit' && <p>{dangerousHTML(t('branches.form.group.edit.resourcesDetails.section.workingTimes.warning.edit'))}</p>}
                    </Alert>
                  </Col>
                </Row>
              )}
            </FormSection>
            {/* <BranchesPreviewResourceFormPermissionsWrapper branch={branch} /> */}
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesResourceForm
