import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Link,
  FixedContentBody,
  t
} from '../../Common'
import { CustomersListItem } from '../../Beauties'

const CustomersList = props => {
  const {
    customers,
    search,
    enterpriseId
  } = props
  const classNames = ['ta-customers__list']

  return (
    <div className={classNames.join(' ')}>
      <FixedContentBody>
        {customers.length > 0 && customers.map((customer, index) => (
          <CustomersListItem
            isFirst={index === 0}
            key={index}
            customer={customer}
            search={search}
            blurTotalBookings={false}
            enterpriseId={enterpriseId}
          />
        ))}
        {(!customers.length && search === '' &&
          <Alert noOffset label={t('customers.list.noResultsBox.title')} theme='no-content'>
            <Link className='ta-btn ta-btn-primary' to='/customers/add' >{t('customers.list.noResultsBox.text')}</Link>
          </Alert>
        )}
        {(!customers.length && search !== '' &&
          <Alert noOffset label={t('global.noResults')} theme='no-content' />
        )}
      </FixedContentBody>
    </div>
  )
}

CustomersList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default CustomersList
