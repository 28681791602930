import { payloads$, actions, handlers, store } from '../../../Store'
import { q } from '../../API'
import {
  userFormValidate,
  userSaveTransform,
  userSavePasswordChangeTransform,
  userFormPasswordChangeValidate,
  userFormServerErrorsTransform
} from './utils'

// SAVE
payloads$(actions.USER_FORM_SAVE)
  .subscribe(async ({ user, scrollToError }) => {
    const errors = userFormValidate(user)
    const userData = userSaveTransform(user)
    const avatarUrl = (user.avatar && user.avatar.avatarUrl) && user.avatar.avatarUrl.value

    if (errors.length) return userFormSaveErrors(errors, scrollToError)

    const saveUserData = await q('saveUserData', {
      userData
    })

    const saveUserDataError = saveUserData && saveUserData.error

    if (saveUserDataError) return userFormSaveErrors(userFormServerErrorsTransform(saveUserDataError), scrollToError)

    const result = {
      name: user.name.value,
      phone: (user.phone && user.phone.phone) && {
        number: user.phone.phone,
        country: user.phone.phoneCountry
      },
      avatarFile: user.avatar && user.avatar.avatarFile
    }

    if (!avatarUrl) result.avatarUrl = null

    const saveEnterpriseUserProfile = await q('saveEnterpriseUserProfile', { user: result })
    const saveEnterpriseUserProfileError = saveEnterpriseUserProfile && saveEnterpriseUserProfile.error

    if (saveEnterpriseUserProfileError) return userFormSaveErrors(userFormServerErrorsTransform(saveEnterpriseUserProfileError), scrollToError)

    const account = store.getState().account

    handlers.languageChange(userData.locale)
    handlers.userFormPopulate()
    handlers.accountPopulate(
      {
        ...account,
        name: saveEnterpriseUserProfile.name,
        phone: saveEnterpriseUserProfile.phone,
        enterprise: {
          ...account.enterprise
        },
        avatarUrl: saveEnterpriseUserProfile.avatarUrl
      }
    )
    handlers.navigateToPath('/user')
  })

payloads$(actions.USER_FORM_PASSWORD_CHANGE_SAVE)
  .subscribe(async ({ user, userId, scrollToError }) => {
    const errors = userFormPasswordChangeValidate(user)
    if (errors.length) return userFormPasswordChangeSaveErrors(errors, scrollToError)
    const savedUser = await q('saveUserData', userSavePasswordChangeTransform(user, userId))
    const { error } = savedUser
    if (error) return userFormPasswordChangeSaveErrors(userFormServerErrorsTransform(error), scrollToError)
    handlers.userFormReady()
    handlers.userPasswordChangeFormGet()
    handlers.navigateToPath('/user')
  })

payloads$(actions.USER_LANGUAGE_FORM_CHANGE_SAVE)
  .subscribe(async ({ user, scrollToError }) => {
    const userData = userSaveTransform(user)
    const savedUser = await q('saveUserData', { userData })
    const { error } = savedUser || {}
    if (error) return userFormLocaleChangeSaveErrors(userFormServerErrorsTransform(error), scrollToError)
    handlers.languageChange(user.language.value)
    handlers.userLanguageFormReady()
    handlers.userLanguageChangeFormGet()
    handlers.navigateToPath('/user')
  })

// ERRORS
const userFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('user', errors)
  scrollToError && scrollToError(errors)
  handlers.userFormReady()
}

const userFormPasswordChangeSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('userPasswordChange', errors)
  scrollToError && scrollToError(errors)
  handlers.userFormReady()
}

const userFormLocaleChangeSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('userLanguageChange', errors)
  scrollToError && scrollToError(errors)
  handlers.userFormReady()
}

// Form
payloads$(actions.USER_FORM_GET)
  .subscribe(async () => {
    const { account = {} } = store.getState()

    handlers.userFormPopulate({ ...account })
  })

payloads$(actions.USER_LANGUAGE_FORM_GET)
  .subscribe(async () => {
    const { account = {} } = store.getState()

    handlers.userLanguageChangeFormPopulate({ ...account })
  })

payloads$(actions.USER_SECURITY_FORM_GET)
  .subscribe(async () => {
    const { account = {}, user = {} } = store.getState()

    handlers.userSecurityFormPopulate({ ...account }, user.errorToShow)
  })
