import { AttributePreview } from '../../Beauties'
import { Loader } from '../../Common'
import { connect, selectors } from '../../Store'

const AttributePreviewWrapper = ({
  id,
  attributesList,
  categoriesList,
  pendingList,
  pendingPreview,
  isAdmin,
  hash
}) => {
  const attribute = attributesList.find(item => item.id === id) || {}
  const category = categoriesList.find(item => item.id === attribute.categoryId) || {}
  const isPending = pendingList || pendingPreview

  return (
    <>
      <Loader active={isPending} />
      {!isPending && (
        <AttributePreview
          hash={hash}
          isAdmin={isAdmin}
          attribute={attribute}
          category={category}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
  attributesList: selectors.attributesListSelector(state),
  categoriesList: selectors.attributesCategoriesListSelector(state),
  id: selectors.routerDataFieldSelector(state, { field: 'id' }),
  pendingList: selectors.attributesFieldSelector(state, { field: 'pendingList' }),
  pendingPreview: selectors.attributesFieldSelector(state, { field: 'pendingPreview' })
})

export default connect(maps)(AttributePreviewWrapper)
