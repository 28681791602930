import { handlers, selectors, store } from '../../../Store'

export const attributeUpdated = attribute => {
  if (!attribute) return
  const state = store.getState()
  const attributesList = selectors.attributesListSelector(state) || []
  const { ts } = attributesList.find(item => item.id === attribute.id) || {}
  if (ts === attribute.ts) return
  handlers.attributeUpdate(attribute)
}

export const attributeCategoryUpdated = category => {
  if (!category) return
  const state = store.getState()
  const categoriesList = selectors.attributesCategoriesListSelector(state) || []
  const { ts } = categoriesList.find(item => item.id === category.id) || {}
  if (ts === category.ts) return
  handlers.attributeCategoryUpdate(category)
}
