import React from 'react'
// import PropTypes from 'prop-types'
import { PopupTriggerWrapper } from '../../Beasts'

import {
  Title,
  FontAwesome5,
  Link,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const UserGroupTitle = props => {
  const classNames = ['ta-user-group__title']

  return (
    <div className={classNames.join(' ')}>
      <Title label='User Groups' >
        <PopupTriggerWrapper name='tags-help-box' position='bottom' extraClassName='ta-help-box'>
          <Link className='ta-title__link' external>
            {t('global.heading.help')} <FontAwesome5 icon='lightbulb' type='s' />
          </Link>
        </PopupTriggerWrapper>
        <HoverPopup>
          <HoverPopupContent position='left' autoSize>
            {t('userGroup.title.add')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <Link to={`/managers/users/user-groups/add`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
              <FontAwesome5 icon='plus' type='s' />
            </Link>
          </HoverPopupTrigger>
        </HoverPopup>
      </Title>
    </div>
  )
}

UserGroupTitle.propTypes = {}

export default UserGroupTitle
