import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Link, t } from '../../Common'
import { UsersListItem } from '../../Beauties'

const UsersList = props => {
  const {
    users,
    userPermissionTypes,
    search
  } = props

  return (
    <div className='ta-users__list'>
      {users.length > 0 && users.map((user, index) => (
        <UsersListItem
          key={user.id}
          user={user}
          userPermissionTypes={userPermissionTypes}
          index={index}
          search={search}
        />
      ))}
      {(!users.length &&
        <Alert noOffset label={search ? t('global.noResults') : t('global.noResultsYet')} theme='no-content'>
          <Link to='users/add' className='ta-btn ta-btn-primary'>
            {t('users.list.add')}
          </Link>
        </Alert>
      )}
    </div>
  )
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default UsersList
