import React from 'react'
import { sortBy } from '../../Utils'

const AvailabilityTimelinesGridUnavailableIntervals = props => {
  let { unavailableTimelines, dayIndex, getIntervalPositioning } = props
  unavailableTimelines = unavailableTimelines || []
  let currentUnavailableTimelines = (unavailableTimelines.find(item => item.index === dayIndex) || {}).unavailableTimes || []
  currentUnavailableTimelines = currentUnavailableTimelines.sort(sortBy('begin'))

  return (
    currentUnavailableTimelines.map(unavailableTime => (
      <div
        key={unavailableTime.begin}
        className='ta-availability-timelines__grid-day-unavailable-interval'
        style={getIntervalPositioning(unavailableTime)}
      />
    ))
  )
}

export default AvailabilityTimelinesGridUnavailableIntervals
