import { handlers } from '../../../Store'
import { PopupAttributeCategoryDelete } from '../../../Beauties'

const PopupAttributeCategoryDeleteWrapper = ({ id, closePopup }) => {
  const approve = () => {
    if (id) handlers.attributesCategoryDelete(id)
    closePopup?.()
  }
  const reject = () => closePopup?.()

  return (
    <PopupAttributeCategoryDelete approve={approve} reject={reject} />
  )
}

export default PopupAttributeCategoryDeleteWrapper
