import React from 'react'
import PropTypes from 'prop-types'
import { UserFormCustomPermissionsWrapper } from '../../../Beasts'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Input,
  SectionTitle,
  SingleSelect,
  PhoneInput,
  Error,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Alert,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './UsersForm.css'

const UsersForm = props => {
  let {
    title,
    isOwner,
    onSubmit,
    isCustom,
    cancelLink,
    permissionType,
    isEditingOwnUser,
    permissionTypesOptions,
    isPermissionUpdateAllowedByEnterpriseIssuer,
    type,
    amIAdmin
  } = props
  permissionType = permissionType || {}
  const isPermissionInOptions = !!permissionTypesOptions.find(item => item.value === permissionType.value)

  return (
    <div className='ta-users-form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-users__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='users'>
            <FormSection>
              <SectionTitle label={t('global.detailsBasic')} icon='building' />
              <Row>
                <Col>
                  <Input
                    type='text'
                    name='name'
                    label={t('global.name.label')}
                    mandatory
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    name='email'
                    label={t('global.email.label')}
                    type='email'
                    mandatory
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PhoneInput
                    label={t('global.telephone.label')}
                    name='phone'
                    mandatory
                  />
                </Col>
              </Row>
              <SectionTitle label={t('globalSettings.form.tab.localUpdates')} icon='lock' />
              {(!isEditingOwnUser && !isPermissionUpdateAllowedByEnterpriseIssuer &&
                <Alert theme='alert'>
                  {t('users.form.permissions.issuerCannotEdit.message')}
                </Alert>
              )}
              {(!isEditingOwnUser && !isCustom && type === 'add' && !amIAdmin &&
                <Alert theme='alert'>
                  {t('users.form.permissions.resourceVisibleToPG.message', [{ key: 'PERMISSION_TYPE', value: permissionType.label }])}
                </Alert>
              )}
              <HoverPopup
                disabled={!isOwner && !isEditingOwnUser && isPermissionUpdateAllowedByEnterpriseIssuer}
                className='ta-users__form__permissions__select'
              >
                <HoverPopupContent position='top' autoSize>
                  {isEditingOwnUser && !isOwner && t('users.form.permissions.cannotEditOwn.tooltip')}
                  {!isEditingOwnUser && !isPermissionUpdateAllowedByEnterpriseIssuer && t('users.form.permissions.issuerCannotEdit.tooltip')}
                  {!isEditingOwnUser && isPermissionUpdateAllowedByEnterpriseIssuer && isOwner && dangerousHTML(t('users.form.permissions.cannotEditOwner.tooltip'))}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Row>
                    <Col>
                      <SingleSelect
                        name='permissionType'
                        label={t('globalSettings.form.tab.localUpdates')}
                        form='users'
                        disabled={isOwner || isEditingOwnUser || !isPermissionUpdateAllowedByEnterpriseIssuer}
                        value={isPermissionInOptions ? permissionType.value : ''}
                        customizedValue={isPermissionInOptions ? undefined : (permissionType.label || 'CUSTOM')}
                      />
                    </Col>
                  </Row>
                </HoverPopupTrigger>
              </HoverPopup>
              {(permissionType.value === 'CUSTOM' && !isEditingOwnUser &&
                <UserFormCustomPermissionsWrapper />
              )}
              <Error noTopOffset name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

UsersForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default UsersForm
