import React, { useRef } from 'react'

import {
  AppsSkeleton,
  AppsSubscriptionRow,
  AppsSubscriptionInvoiceRow
} from '../../../Beauties'
import {
  AppsSubscriptionFormWrapper,
  AppsSubscriptionBillingFormWrapper,
  AppsSubscriptionBillingPreviewWrapper
} from '../../../Beasts'
import {
  Accordion,
  AccordionTitle,
  AccordionBody,
  StripedTitle,
  Price,
  Loader,
  t,
  Alert
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { handlers } from '../../../Store'

import './AppsSubscription.css'

const AppsSubscriptionAdd = (props) => {
  const {
    name,
    version,
    plan,
    pricingType,
    activationFeePrice,
    remainingUnits,
    unitDescription,
    unitDescriptionPlural,
    isVariablePrice,
    usageUnitPrice,
    priceRange,
    priceDescription,
    onUninstallClick,
    hasBillingDetails,
    hasPaymentMethod,
    noAutoCollect,
    pending,
    today,
    todayNextMonth,
    endDay,
    nextInvoiceDay,
    isTrial,
    isOwner,
    isPaidByEnterprise,
    currencyCode
  } = props
  const accordionRef = useRef()
  let [minPrice, maxPrice] = priceRange || []
  minPrice = minPrice || ''
  maxPrice = maxPrice || ''
  const oneTimeSetupPrice = <Price currency={currencyCode} price={activationFeePrice} />
  let headerText = ''
  if (pricingType === 'monthly') headerText = t('apps.subscriptionsAdd.monthlySubscription.header')
  if (pricingType === 'payPerUsage' && !isTrial) headerText = t('apps.subscriptionsAdd.payPerUsage.header', [{ key: 'COUNT', value: remainingUnits }, { key: 'NAME', value: unitDescriptionPlural }])
  if (pricingType === 'payPerUsage' && isTrial) headerText = t('apps.subscriptionsAdd.payPerUsage.isTrial.header', [{ key: 'COUNT', value: remainingUnits }, { key: 'NAME', value: unitDescriptionPlural }])
  if (pricingType === 'payPerUsage' && !isPaidByEnterprise) headerText = t('apps.subscriptionsAdd.payPerUsage.localPayments.header')
  let pricingPayPerUsageLabel = ''
  if (pricingType === 'payPerUsage' && !isVariablePrice) pricingPayPerUsageLabel = t('apps.subscriptions.entityPrice', [{ key: 'NAME', value: unitDescription }])
  if (pricingType === 'payPerUsage' && isVariablePrice) pricingPayPerUsageLabel = t('apps.subscriptions.variablePrice')
  let payPerUsagePrice = <Price currency={currencyCode} price={usageUnitPrice} />
  if (isVariablePrice) {
    payPerUsagePrice = (
      <>
        <Price currency={currencyCode} price={minPrice} /> - <Price currency={currencyCode} price={maxPrice} />
      </>
    )
  }
  const payPerUsageInvoiceText = t('apps.subscriptionsAdd.payPerUsage.invoice.text', [
    { key: 'DATE_FROM', value: today },
    { key: 'DATE_UNTIL', value: todayNextMonth },
    { key: 'DAY', value: nextInvoiceDay },
    { key: 'END_DAY', value: endDay }
  ])

  const renderExtraContent = () => {
    if (!hasBillingDetails || (!hasPaymentMethod && !noAutoCollect)) return null
    return (
      <AppsSubscriptionBillingPreviewWrapper />
    )
  }

  const closeAccordion = () => {
    // accordion
    if (accordionRef && accordionRef.current && accordionRef.current.toggle) {
      accordionRef.current.toggle()
    }
    handlers.appSubscriptionBillingFormIsUpdated(false)
  }

  if (pending) {
    return (<Loader active />)
  }

  return (
    <AppsSkeleton
      name={name}
      version={version}
      plan={plan}
      className='ta-apps__subscription-add'
      headerText={headerText}
      headerImage='/images/paidApps/banner-subscription-add.png'
    >
      <div className='ta-apps__subscription-add__text'>
        {pricingType === 'monthly' && t('apps.subscriptionsAdd.monthlySubscription.text')}
        {pricingType === 'payPerUsage' && isPaidByEnterprise && t('apps.subscriptionsAdd.payPerUsage.text')}
        {pricingType === 'payPerUsage' && !isPaidByEnterprise && dangerousHTML(t('apps.subscriptionsAdd.payPerUsage.localPayments.text'))}
      </div>
      {isPaidByEnterprise && (
        <Alert theme='info'>
          {t('global.vatApplicability')}
        </Alert>
      )}
      {isPaidByEnterprise && (
        <>
          {!!activationFeePrice && (
            <>
              <StripedTitle label={t('apps.preview.section.pricing.oneTimeSetup.title')} />
              <AppsSubscriptionRow
                active
                bottomOffset
                title={t('apps.preview.section.pricing.oneTimeSetup.setup')}
                text={t('apps.subscriptions.oneTimePayment.setup.text')}
                boxValue={oneTimeSetupPrice}
                boxLabel={t('apps.preview.section.pricing.oneTimeSetup.oneTime').toLowerCase()}
              />
            </>
          )}
          {pricingType === 'payPerUsage' && (
            <>
              {!!activationFeePrice && (
                <StripedTitle label={t('apps.preview.section.pricing.payPerUsage')} />
              )}
              <AppsSubscriptionRow
                active
                title={pricingPayPerUsageLabel}
                text={priceDescription}
                boxValue={payPerUsagePrice}
                boxLabel={`/${unitDescription}`}
                big={isVariablePrice}
                bottomOffset
              />
            </>
          )}
          <StripedTitle topOffset label={t('global.overview')} />
          {!!activationFeePrice && (
            <AppsSubscriptionInvoiceRow
              label={t('apps.subscriptionsAdd.oneTimeSetup.setup')}
              price={oneTimeSetupPrice}
              description={t('apps.subscriptionsAdd.oneTimeSetup.setup.text')}
              withDivider
            />
          )}
          {pricingType === 'payPerUsage' && (
            <>
              <AppsSubscriptionInvoiceRow
                label={t('apps.preview.section.pricing.payPerUsage')}
                description={payPerUsageInvoiceText}
              />
              {isOwner && (
                <AppsSubscriptionFormWrapper type='add' renderExtraContent={renderExtraContent} />
              )}
            </>
          )}
          {isOwner && (!hasBillingDetails || (!hasPaymentMethod && !noAutoCollect)) && (
            <Accordion
              ref={accordionRef}
              className='ta-apps__subscription__accordion ta-btn ta-btn-block ta-btn-primary'
              expand={false}
            >
              <AccordionTitle>{t('buttons.billingDetails.add')}</AccordionTitle>
              <AccordionBody>
                <AppsSubscriptionBillingFormWrapper closeAccordion={closeAccordion} />
              </AccordionBody>
            </Accordion>
          )}
        </>
      )}
      <button className='ta-btn ta-btn-block ta-btn-black-ghost' onClick={onUninstallClick}>
        {t('buttons.uninstall.app')}
      </button>
    </AppsSkeleton>
  )
}

export default AppsSubscriptionAdd
