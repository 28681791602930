import { translateServerCode, isUrl, validator, extractFromHtml, removeMultipleSpaces } from '../../../Utils'
import {
  FIVE_MINUTES_INTERVAL_TIMES,
  NUMBERS_VALIDATION_REGEX,
  MAX_LEAD_TIME_MAXIMUMS,
  MIN_LEAD_TIME_MAXIMUMS,
  CANCELATION_LEAD_TIME_MAXIMUMS,
  BOOKING_SETTINGS_DEFAULTS
} from '../../../Settings'

const transformBookingInterval = ({ formToApi, apiToForm }) => {
  const mapping = {
    0: 'serviceLength',
    15: 'quarterly',
    30: 'halfHourly',
    60: 'hourly'
  }
  return formToApi
    ? parseInt(Object.keys(mapping).find(key => mapping[key] === formToApi), 10)
    : mapping[apiToForm || 0]
}

export const bookingSettingsFormTransform = ({ settings, tags, serviceAllocationsTimeOptions, customerFields, isAdmin }) => {
  settings = settings || {}
  const { forbidBookingsInThePast } = settings
  customerFields = customerFields || []
  let { disclaimerConfig, servicesAllocation, dateFormat, timeFormat } = settings || {}
  disclaimerConfig = disclaimerConfig || {}
  // Service allocations
  let {
    settings: servicesAllocationSettings,
    allocations: serviceAllocations
  } = servicesAllocation || {}
  servicesAllocationSettings = servicesAllocationSettings || {}
  serviceAllocations = serviceAllocations || []
  let {
    allowPartialUpdateOfBookingsInThePast,
    forbidAddingBookingsInThePast,
    forbidDeletingBookingInThePast,
    forbidPastBookingsUpdate,
    forbidUpdatingBookingsInThePast
  } = forbidBookingsInThePast || {}
  allowPartialUpdateOfBookingsInThePast = allowPartialUpdateOfBookingsInThePast || false
  forbidAddingBookingsInThePast = forbidAddingBookingsInThePast || false
  forbidDeletingBookingInThePast = forbidDeletingBookingInThePast || false
  forbidUpdatingBookingsInThePast = forbidUpdatingBookingsInThePast || false
  let {
    customDataFields: pastBookingCustomDataFields,
    customer: pastBookingCustomer,
    duration: pastBookingDuration,
    groupPrice: pastBookingGroupPrice,
    notes: pastBookingNotes,
    participantsAndGuests: pastBookingParticipantsAndGuests,
    prepAndFollowUp: pastBookingPrepAndFollowUp,
    resources: pastBookingResources,
    startTime: pastBookingStartTime,
    tags: pastBookingTags,
    title: pastBookingTitle
  } = forbidPastBookingsUpdate || {}
  pastBookingCustomDataFields = pastBookingCustomDataFields || false
  pastBookingCustomer = pastBookingCustomer || false
  pastBookingDuration = pastBookingDuration || false
  pastBookingGroupPrice = pastBookingGroupPrice || false
  pastBookingNotes = pastBookingNotes || false
  pastBookingParticipantsAndGuests = pastBookingParticipantsAndGuests || false
  pastBookingPrepAndFollowUp = pastBookingPrepAndFollowUp || false
  pastBookingResources = pastBookingResources || false
  pastBookingStartTime = pastBookingStartTime || false
  pastBookingTags = pastBookingTags || false
  pastBookingTitle = pastBookingTitle || false
  const hasServiceAllocations = serviceAllocations.length > 0
  let {
    shouldAllowBookingWidget,
    shouldDisplayInfo,
    hoursBeforeAllocationExpiration // TODO: maybe a calculation is needed here
  } = servicesAllocationSettings || {}
  shouldAllowBookingWidget = !!shouldAllowBookingWidget
  shouldDisplayInfo = !!shouldDisplayInfo
  const isSuspendTimeDays = hoursBeforeAllocationExpiration % 24 === 0
  const isServiceAllocationsEnabledFixedTime = hoursBeforeAllocationExpiration > 0
  const convertedSuspendHours = hoursBeforeAllocationExpiration % 24 === 0
    ? hoursBeforeAllocationExpiration / 24
    : hoursBeforeAllocationExpiration
  const isEnforceResourcesAllowanceTypeEnabled = ['WORKING', 'BOOKING'].includes(settings.enforceResourcesAllowanceType)
  tags = tags || []
  const minutesOptions = FIVE_MINUTES_INTERVAL_TIMES.filter(t => t < 60).map(time => ({
    label: time,
    value: time
  }))
  // Date and Time format
  const {
    dateFormatPositionDefault,
    dateFormatYearDefault,
    dateFormatMonthDefault,
    dateFormatDayDefault,
    dateFormatSeparatorDefault,
    timeFormatTypeDefault,
    timeFormatTwelveDefault,
    timeFormatTwentyFourDefault
  } = BOOKING_SETTINGS_DEFAULTS
  const {
    dateFormatPosition,
    dateFormatYear,
    dateFormatMonth,
    dateFormatDay,
    dateFormatSeparator
  } = dateFormat || {}
  const {
    timeFormatType,
    timeFormatTwelve,
    timeFormatTwentyFour
  } = timeFormat || {}

  let { widgetConversions } = settings
  widgetConversions = widgetConversions || {}

  const hasIcsFileCustomisationSetting = settings.icsFileCustomisation
  const hasIcsFileCustomisationCustomerSetting = hasIcsFileCustomisationSetting && (
    !!settings.icsFileCustomisation.icsCustomisationCustomer &&
    !!settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsAddress &&
    !!settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes &&
    !!settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsSubject
  )
  const hasIcsFileCustomisationResourceSetting = hasIcsFileCustomisationSetting && (
    !!settings.icsFileCustomisation.icsCustomisationResource &&
    !!settings.icsFileCustomisation.icsCustomisationResource.resourceIcsAddress &&
    !!settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes &&
    !!settings.icsFileCustomisation.icsCustomisationResource.resourceIcsSubject
  )

  let customDataFieldsNoteResource = hasIcsFileCustomisationResourceSetting &&
    settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
    ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.customDataFieldsNote
    : []

  customDataFieldsNoteResource = customDataFieldsNoteResource.map(item => {
    const customerField = customerFields.find(customerFieldItem => customerFieldItem.id === item.id)
    if (!customerField) return null
    return item.id
  }).filter(item => !!item)

  let customDataFieldsNoteCustomer = hasIcsFileCustomisationCustomerSetting &&
    settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
    ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.customDataFieldsNote
    : []

  customDataFieldsNoteCustomer = customDataFieldsNoteCustomer.map(item => {
    const customerField = customerFields.find(customerFieldItem => customerFieldItem.id === item.id)
    if (!customerField) return null
    return item.id
  }).filter(item => !!item)

  const result = {
    snapToGridInterval: {
      value: transformBookingInterval({ apiToForm: settings.snapToGridInterval })
    },
    internalSnapToGridInterval: {
      value: transformBookingInterval({ apiToForm: settings.internalSnapToGridInterval })
    },
    hasTimifyLogin: {
      value: !!settings.hasTimifyLogin
    },
    hasFacebookLogin: {
      value: !!settings.hasFacebookLogin
    },
    hasGuestBooking: {
      value: !!settings.hasGuestBooking
    },
    maximumLeadTime: {
      value: (settings.maximumLeadTime && settings.maximumLeadTime.value) || 3
    },
    maximumLeadTimeDuration: {
      value: (settings.maximumLeadTime && settings.maximumLeadTime.frequency) || 'MONTHS'
    },
    minimumLeadTime: {
      value: `${(settings.minimumLeadTime && settings.minimumLeadTime.value) || 0}`
    },
    minimumLeadTimeDuration: {
      value: (settings.minimumLeadTime && settings.minimumLeadTime.frequency) || 'HOURS'
    },
    useCancellationLeadTime: {
      value: settings.cancellationLeadTime && settings.cancellationLeadTime.value > -1
    },
    cancellationLeadTimeAnyTime: {
      value: settings.cancellationLeadTime && settings.cancellationLeadTime.value === 0 ? 'yes' : 'no'
    },
    cancellationLeadTime: {
      value: `${settings.cancellationLeadTime && settings.cancellationLeadTime.value && settings.cancellationLeadTime.value > 0 ? settings.cancellationLeadTime.value : 1}`
    },
    cancellationLeadTimeDuration: {
      value: (settings.cancellationLeadTime && settings.cancellationLeadTime.frequency) || 'HOURS'
    },
    enforceResourcesAllowanceType: {
      value: isEnforceResourcesAllowanceTypeEnabled ? settings.enforceResourcesAllowanceType : null
    },
    enforceResourcesAllowanceTypeRadio: {
      value: settings.enforceResourcesAllowanceType && settings.enforceResourcesAllowanceType !== 'NONE' ? settings.enforceResourcesAllowanceType : 'WORKING'
    },
    enforceResourcesAllowanceTypeCheckbox: {
      value: isEnforceResourcesAllowanceTypeEnabled
    },
    enforceReschedulingTagRuleCheckbox: {
      value: settings.enforceReschedulingTagRule && settings.enforceReschedulingTagRule !== 'NONE'
    },
    enforceReschedulingTagRule: {
      value: settings.enforceReschedulingTagRule && settings.enforceReschedulingTagRule !== 'NONE' ? settings.enforceReschedulingTagRule : null,
      options: tags
        .filter(item => item.isForEvents)
        .map(item => ({ label: item.name, value: item.id }))
    },
    enforceServiceDurationLimitCheckbox: {
      value: settings.enforceServiceDurationLimit !== -1
    },
    enforceServiceDurationLimit: {
      value: settings.enforceServiceDurationLimit >= 0 ? settings.enforceServiceDurationLimit : null,
      options: minutesOptions
    },
    showWidgetLanguageSelector: {
      value: !!settings.showWidgetLanguageSelector
    },
    isWidgetWeeklyView: {
      value: !!settings.isWidgetWeeklyView
    },
    showWidgetCategoriesClosed: {
      value: !!settings.showWidgetCategoriesClosed
    },
    showWidgetFirstCategoryOpen: {
      value: !!settings.showWidgetFirstCategoryOpen
    },
    hideResourcesSection: {
      value: !!settings.hideResourcesSection
    },
    hideServiceDuration: {
      value: !!settings.hideServiceDuration
    },
    showCustomTermsAndConditions: {
      value: !!settings.customTermsAndConditionsUrl
    },
    showCustomPrivacyPolicy: {
      value: !!settings.customPrivacyPolicyUrl
    },
    customTermsAndConditionsUrl: {
      value: settings.customTermsAndConditionsUrl || ''
    },
    customPrivacyPolicyUrl: {
      value: settings.customPrivacyPolicyUrl || ''
    },
    showDisclaimer: {
      value: !!disclaimerConfig.active
    },
    disclaimerText: {
      value: disclaimerConfig.text || ''
    },
    disclaimerType: {
      value: disclaimerConfig.displayOn && disclaimerConfig.displayOn.includes('BOOKING_SUMMARY_VIEW')
        ? 'BOOKING_SUMMARY_VIEW'
        : 'CUSTOMER_DETAIL_FORM'
    },
    showExternalIds: {
      value: !!settings.showExternalIds
    },
    enableCrossAccountsBookings: {
      value: !!settings.enableCrossAccountsBookings
    },
    reschedulingSwitch: {
      value: !!settings.reschedulingLeadTime
    },
    reschedulingLeadTime: {
      value: settings.reschedulingLeadTime || 2
    },
    isReschedulingConfirmationRequired: {
      value: !!settings.isReschedulingConfirmationRequired
    },
    showWidgetTimezoneSelector: {
      value: !!settings.showWidgetTimezoneSelector
    },
    showFastWidgetTimeFrame: {
      value: !settings.hideFastWidgetTimeFrame
    },
    showWidgetExternalUrlRedirect: {
      value: !!settings.widgetExternalUrlRedirect
    },
    widgetExternalUrlRedirect: {
      value: settings.widgetExternalUrlRedirect || ''
    },
    hasNotifyOnResources: {
      value: settings.hasNotifyOnResources
    },
    hasNotifyOnCustomers: {
      value: settings.hasNotifyOnCustomers
    },
    resetCopyBookingClipboardAfterPaste: {
      value: settings.resetCopyBookingClipboardAfterPaste ? 'once' : 'multiple'
    },
    forbidCreatingAndUpdatingBookingsInThePast: {
      value: settings.forbidCreatingAndUpdatingBookingsInThePast
    },
    forbidMoreThanOneBookingInFBW: {
      value: !settings.forbidMoreThanOneBookingInFBW
    },
    showAddServiceCombinations: {
      value: !!settings.showAddServiceCombinations
    },
    hasPublicHolidays: {
      value: (settings.publicHolidays && settings.publicHolidays.length > 0) || false
    },
    publicHolidays: {
      deleted: [],
      modified: [],
      values: (settings.publicHolidays || []).map(item => {
        const resourceCategoryIds = item.resourceCategoryIds || []
        return {
          ...item,
          resourceCategoryIds: resourceCategoryIds.length === 0 ? ['all'] : resourceCategoryIds
        }
      })
    },
    hasServiceAllocations: {
      value: !!hasServiceAllocations
    },
    shouldAllowBookingWidget: {
      value: !!shouldAllowBookingWidget
    },
    shouldDisplayInfo: {
      value: !!shouldDisplayInfo
    },
    isServiceAllocationsEnabledFixedTime: {
      value: !!isServiceAllocationsEnabledFixedTime
    },
    serviceAllocationsEnabledFixedTimeUnits: {
      value: isSuspendTimeDays ? 24 : 1,
      options: serviceAllocationsTimeOptions
    },
    serviceAllocationsEnabledFixedTimeLeadTime: {
      value: convertedSuspendHours || 0
    },
    useEnterpriseShowWidgetConversionTrackingOptions: {
      value: (!widgetConversions.facebookPixelId && !widgetConversions.googleAnalyticsId)
    },
    facebookConversionTrackingID: {
      value: widgetConversions.facebookPixelId ? widgetConversions.facebookPixelId : ''
    },
    useFacebookConversionTracking: {
      value: !!widgetConversions.facebookPixelId
    },
    googleConversionTrackingID: {
      value: widgetConversions.googleAnalyticsId ? widgetConversions.googleAnalyticsId : ''
    },
    useGoogleConversionTracking: {
      value: !!widgetConversions.googleAnalyticsId
    },
    customConsentMessage: {
      value: widgetConversions.customMessage ? widgetConversions.customMessage : ''
    },
    useCustomiseConsentMessage: {
      value: !!widgetConversions.customMessage
    },
    customMessageVisibility: {
      value: widgetConversions.messageTarget || 'EU_ONLY'
    },
    serviceAllocations: {
      values: (serviceAllocations || []).map(serviceAllocation => {
        let { serviceIds, percentage, name } = serviceAllocation || []
        serviceIds = serviceIds || []
        percentage = percentage || 0
        name = name || ''
        return {
          id: Math.random(),
          name,
          serviceIds: serviceIds.length === 0 ? [] : serviceIds,
          percentage
        }
      })
    },
    hasDateFormat: {
      value: dateFormat !== null && Object.values(dateFormat || {}).length > 0 && !Object.values(dateFormat || {}).every(item => item === null)
    },
    dateFormatPosition: {
      value: dateFormatPosition || dateFormatPositionDefault
    },
    dateFormatYear: {
      value: dateFormatYear || dateFormatYearDefault
    },
    dateFormatMonth: {
      value: dateFormatMonth || dateFormatMonthDefault
    },
    dateFormatDay: {
      value: dateFormatDay || dateFormatDayDefault
    },
    dateFormatSeparator: {
      value: dateFormatSeparator || dateFormatSeparatorDefault
    },
    hasTimeFormat: {
      value: timeFormat !== null && Object.values(timeFormat || {}).length > 0 && !Object.values(timeFormat || {}).every(item => item === null)
    },
    timeFormatType: {
      value: timeFormatType || timeFormatTypeDefault
    },
    timeFormat12: {
      value: timeFormatTwelve || timeFormatTwelveDefault
    },
    timeFormat24: {
      value: timeFormatTwentyFour || timeFormatTwentyFourDefault
    },
    // Ics File Customisation
    icsSubjectCustomer: {
      value: hasIcsFileCustomisationCustomerSetting ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsSubject || 'BOOKED_SERVICE' : 'BOOKED_SERVICE'
    },
    icsAddressTypeCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsAddress
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsAddress.icsAddressType || 'ACCOUNT_ADDRESS'
        : 'ACCOUNT_ADDRESS'
    },
    icsAddressCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsAddress
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsAddress.icsAddress
        : ''
    },
    companyNameNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.companyNameNote
        : false
    },
    onlineBookableResourceNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.onlineBookableResourceNote
        : false
    },
    dependantResourceNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.dependantResourceNote
        : false
    },
    bookedServiceNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.bookedServiceNote
        : false
    },
    bookedServiceDescriptionNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.bookedServiceDescriptionNote
        : false
    },
    bookedServicePriceNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.bookedServicePriceNote
        : false
    },
    customDataFieldsNoteCustomer: {
      values: customDataFieldsNoteCustomer
    },
    customNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.customNote
        : ''
    },
    useCustomDataFieldsNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? !!(settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.customDataFieldsNote && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.customDataFieldsNote.length)
        : false
    },
    linkToCancelRescheduleNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.eventCancellationLink
        ? !!settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.eventCancellationLink
        : false
    },
    useCustomNoteCustomer: {
      value: hasIcsFileCustomisationCustomerSetting && settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes
        ? !!settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes.customNote
        : false
    },
    hasIcsFileCustomisationCustomer: {
      value: hasIcsFileCustomisationCustomerSetting
        ? (settings.icsFileCustomisation !== null && (settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsSubject || settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsAddress || settings.icsFileCustomisation.icsCustomisationCustomer.customerIcsNotes))
        : false
    },

    icsSubjectResource: {
      value: hasIcsFileCustomisationResourceSetting ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsSubject || 'BOOKED_SERVICE' : 'BOOKED_SERVICE'
    },
    icsAddressTypeResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsAddress
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsAddress.icsAddressType || 'ACCOUNT_ADDRESS'
        : 'ACCOUNT_ADDRESS'
    },
    icsAddressResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsAddress
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsAddress.icsAddress
        : ''
    },
    customerNameNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.customerNameNote
        : false
    },
    onlineBookableResourceNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.onlineBookableResourceNote
        : false
    },
    dependantResourceNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.dependantResourceNote
        : false
    },
    bookedServiceNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.bookedServiceNote
        : false
    },
    bookedServiceDescriptionNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.bookedServiceDescriptionNote
        : false
    },
    bookedServicePriceNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.bookedServicePriceNote
        : false
    },
    customDataFieldsNoteResource: {
      values: customDataFieldsNoteResource
    },
    customNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.customNote
        : ''
    },
    linkToAppointmentResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.linkToTimifyCalendar
        : false
    },
    useCustomDataFieldsNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? !!(settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.customDataFieldsNote && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.customDataFieldsNote.length)
        : false
    },
    useCustomNoteResource: {
      value: hasIcsFileCustomisationResourceSetting && settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes
        ? !!settings.icsFileCustomisation.icsCustomisationResource.resourceIcsNotes.customNote
        : false
    },
    hasIcsFileCustomisationResource: {
      value: hasIcsFileCustomisationResourceSetting
        ? (settings.icsFileCustomisation !== null && (settings.icsFileCustomisation.icsCustomisationResource.icsSubject || settings.icsFileCustomisation.icsCustomisationResource.icsAddress || settings.icsFileCustomisation.icsCustomisationResource.icsNotes))
        : false
    },
    hasCustomerIcsFileCustomisation: {
      value: hasIcsFileCustomisationCustomerSetting
    },
    hasResourceIcsFileCustomisation: {
      value: hasIcsFileCustomisationResourceSetting
    },
    callCenterLoginButton: {
      value: !!settings.callCenterLoginButton
    },
    allowPartialUpdateOfBookingsInThePast: { value: allowPartialUpdateOfBookingsInThePast },
    forbidAddingBookingsInThePast: { value: forbidAddingBookingsInThePast },
    forbidDeletingBookingInThePast: { value: forbidDeletingBookingInThePast },
    forbidUpdatingBookingsInThePast: { value: forbidUpdatingBookingsInThePast },
    pastBookingCustomDataFields: { value: pastBookingCustomDataFields },
    pastBookingCustomer: { value: pastBookingCustomer },
    pastBookingDuration: { value: pastBookingDuration },
    pastBookingGroupPrice: { value: pastBookingGroupPrice },
    pastBookingNotes: { value: pastBookingNotes },
    pastBookingParticipantsAndGuests: { value: pastBookingParticipantsAndGuests },
    pastBookingPrepAndFollowUp: { value: pastBookingPrepAndFollowUp },
    pastBookingResources: { value: pastBookingResources },
    pastBookingStartTime: { value: pastBookingStartTime },
    pastBookingTags: { value: pastBookingTags },
    pastBookingTitle: { value: pastBookingTitle }
  }

  if (!isAdmin) {
    delete result.callCenterLoginButton
  }

  return result
}

export const bookingSettingsFormValidate = bookingSettings => {
  bookingSettings = bookingSettings || {}
  const {
    hasTimifyLogin,
    hasFacebookLogin,
    hasGuestBooking,
    enforceReschedulingTagRuleCheckbox,
    enforceReschedulingTagRule,
    customTermsAndConditionsUrl,
    customPrivacyPolicyUrl,
    widgetExternalUrlRedirect,
    showCustomTermsAndConditions,
    showCustomPrivacyPolicy,
    showWidgetExternalUrlRedirect,
    enforceServiceDurationLimit,
    enforceServiceDurationLimitCheckbox,
    publicHolidays,
    hasPublicHolidays,
    maximumLeadTime,
    minimumLeadTime,
    maximumLeadTimeDuration,
    minimumLeadTimeDuration,
    useCancellationLeadTime,
    cancellationLeadTimeAnyTime,
    cancellationLeadTime,
    cancellationLeadTimeDuration,
    showDisclaimer,
    disclaimerType,
    disclaimerText,
    serviceAllocations,
    hasServiceAllocations,
    shouldAllowBookingWidget,
    shouldDisplayInfo,
    isServiceAllocationsEnabledFixedTime,
    serviceAllocationsEnabledFixedTimeUnits,
    serviceAllocationsEnabledFixedTimeLeadTime,
    useFacebookConversionTracking,
    useGoogleConversionTracking,
    hasDateFormat,
    hasTimeFormat,
    useCustomiseConsentMessage,
    customConsentMessage,
    useCustomDataFieldsNoteCustomer,
    useCustomNoteCustomer,
    customNoteCustomer,
    icsAddressCustomer,
    icsAddressTypeCustomer,
    customDataFieldsNoteCustomer,
    hasCustomerIcsFileCustomisation,
    useCustomDataFieldsNoteResource,
    useCustomNoteResource,
    customNoteResource,
    icsAddressResource,
    icsAddressTypeResource,
    customDataFieldsNoteResource,
    hasResourceIcsFileCustomisation,
    allowPartialUpdateOfBookingsInThePast,
    pastBookingCustomDataFields,
    pastBookingCustomer,
    pastBookingDuration,
    pastBookingGroupPrice,
    pastBookingNotes,
    pastBookingParticipantsAndGuests,
    pastBookingPrepAndFollowUp,
    pastBookingResources,
    pastBookingStartTime,
    pastBookingTitle,
    pastBookingTags
  } = bookingSettings || {}

  const { value: enforceServiceDurationLimitValue } = enforceServiceDurationLimit || {}
  const { value: enforceServiceDurationLimitCheckboxValue } = enforceServiceDurationLimitCheckbox || {}
  const { value: enforceReschedulingTagRuleCheckboxValue } = enforceReschedulingTagRuleCheckbox || {}
  const { value: hasTimifyLoginValue } = hasTimifyLogin || {}
  const { value: hasFacebookLoginValue } = hasFacebookLogin || {}
  const { value: hasGuestBookingValue } = hasGuestBooking || {}
  const { value: customTermsAndConditionsUrlValue } = customTermsAndConditionsUrl || {}
  const { value: customPrivacyPolicyUrlValue } = customPrivacyPolicyUrl || {}
  const { value: showDisclaimerValue } = showDisclaimer || {}
  const { value: disclaimerTypeValue } = disclaimerType || {}
  const { value: disclaimerTextValue } = disclaimerText || {}
  const { value: widgetExternalUrlRedirectValue } = widgetExternalUrlRedirect || {}
  const { value: showCustomTermsAndConditionsValue } = showCustomTermsAndConditions || {}
  const { value: showCustomPrivacyPolicyValue } = showCustomPrivacyPolicy || {}
  const { value: showWidgetExternalUrlRedirectValue } = showWidgetExternalUrlRedirect || {}
  const { value: enforceReschedulingTagRuleValue } = enforceReschedulingTagRule || {}
  const { value: maximumLeadTimeValue } = maximumLeadTime || {}
  const { value: minimumLeadTimeValue } = minimumLeadTime || {}
  const { value: maximumLeadTimeDurationValue } = maximumLeadTimeDuration || {}
  const { value: minimumLeadTimeDurationValue } = minimumLeadTimeDuration || {}
  const { value: useCancellationLeadTimeValue } = useCancellationLeadTime || {}
  const { value: cancellationLeadTimeAnyTimeValue } = cancellationLeadTimeAnyTime || {}
  const { value: cancellationLeadTimeValue } = cancellationLeadTime || {}
  const { value: cancellationLeadTimeDurationValue } = cancellationLeadTimeDuration || {}
  const { value: hasPublicHolidaysValue } = hasPublicHolidays || {}
  let { values: publicHolidaysValues } = publicHolidays || {}
  const { value: hasServiceAllocationsValue } = hasServiceAllocations || {}
  const { value: shouldAllowBookingWidgetValue } = shouldAllowBookingWidget || {}
  const { value: shouldDisplayInfoValue } = shouldDisplayInfo || {}
  const { value: serviceAllocationsEnabledFixedTimeLeadTimeValue } = serviceAllocationsEnabledFixedTimeLeadTime || {}
  const { value: serviceAllocationsEnabledFixedTimeUnitsValue } = serviceAllocationsEnabledFixedTimeUnits || {}
  const { value: isServiceAllocationsEnabledFixedTimeValue } = isServiceAllocationsEnabledFixedTime || {}
  const { value: useGoogleConversionTrackingValue } = useGoogleConversionTracking || {}
  const { value: useFacebookConversionTrackingValue } = useFacebookConversionTracking || {}

  const { value: useCustomDataFieldsNoteCustomerValue } = useCustomDataFieldsNoteCustomer || {}
  const { value: useCustomNoteCustomerValue } = useCustomNoteCustomer || {}
  const { value: customNoteCustomerValue } = customNoteCustomer || {}
  const { value: icsAddressCustomerValue } = icsAddressCustomer || {}
  const { value: icsAddressTypeCustomerValue } = icsAddressTypeCustomer || {}
  const { values: customDataFieldsNoteCustomerValues } = customDataFieldsNoteCustomer || {}
  const { value: hasCustomerIcsFileCustomisationValue } = hasCustomerIcsFileCustomisation || {}

  const { value: useCustomDataFieldsNoteResourceValue } = useCustomDataFieldsNoteResource || {}
  const { value: useCustomNoteResourceValue } = useCustomNoteResource || {}
  const { value: customNoteResourceValue } = customNoteResource || {}
  const { value: icsAddressResourceValue } = icsAddressResource || {}
  const { value: icsAddressTypeResourceValue } = icsAddressTypeResource || {}
  const { values: customDataFieldsNoteResourceValues } = customDataFieldsNoteResource || {}
  const { value: hasCustomerIcsFileResourceValue } = hasResourceIcsFileCustomisation || {}

  const { values: serviceAllocationsValues } = serviceAllocations || {}
  const { value: hasDateFormatValue } = hasDateFormat || {}
  const { value: hasTimeFormatValue } = hasTimeFormat || {}
  const { value: useCustomiseConsentMessageValue } = useCustomiseConsentMessage || {}
  const { value: customConsentMessageValue } = customConsentMessage || {}
  const { value: pastBookingTitleValue } = pastBookingTitle || {}
  const { value: pastBookingTagsValue } = pastBookingTags || {}
  const { value: pastBookingStartTimeValue } = pastBookingStartTime || {}
  const { value: pastBookingResourcesValue } = pastBookingResources || {}
  const { value: pastBookingPrepAndFollowUpValue } = pastBookingPrepAndFollowUp || {}
  const { value: pastBookingParticipantsAndGuestsValue } = pastBookingParticipantsAndGuests || {}
  const { value: pastBookingNotesValue } = pastBookingNotes || {}
  const { value: pastBookingGroupPriceValue } = pastBookingGroupPrice || {}
  const { value: pastBookingDurationValue } = pastBookingDuration || {}
  const { value: pastBookingCustomerValue } = pastBookingCustomer || {}
  const { value: pastBookingCustomDataFieldsValue } = pastBookingCustomDataFields || {}
  const { value: allowPartialUpdateOfBookingsInThePastValue } = allowPartialUpdateOfBookingsInThePast || {}

  publicHolidaysValues = publicHolidaysValues || []

  const rules = [
    { 'customTermsAndConditionsUrl.value': [`requiredIf:${showCustomTermsAndConditionsValue}`] },
    { 'customPrivacyPolicyUrl.value': [`requiredIf:${showCustomPrivacyPolicyValue}`] },
    { 'widgetExternalUrlRedirect.value': [`requiredIf:${showWidgetExternalUrlRedirectValue}`] },
    { 'disclaimerText.value': [`requiredIf:${showDisclaimerValue && disclaimerTypeValue}`] },
    { 'dateFormatPosition.value': [`requirvedIf:${hasDateFormatValue}`] },
    { 'dateFormatYear.value': [`requirvedIf:${hasDateFormatValue}`] },
    { 'dateFormatMonth.value': [`requirvedIf:${hasDateFormatValue}`] },
    { 'dateFormatDay.value': [`requirvedIf:${hasDateFormatValue}`] },
    { 'dateFormatSeparator.value': [`requirvedIf:${hasDateFormatValue}`] },
    { 'timeFormatType.value': [`requirvedIf:${hasTimeFormatValue}`] },
    { 'timeFormat12.value': [`requirvedIf:${hasTimeFormatValue}`] },
    { 'timeFormat24.value': [`requirvedIf:${hasTimeFormatValue}`] },
    { 'googleConversionTrackingID.value': [`requiredIf:${useGoogleConversionTrackingValue}`] },
    { 'facebookConversionTrackingID.value': [`requiredIf:${useFacebookConversionTrackingValue}`] },
    { 'customDataFieldsNoteCustomer.values': [`requiredIf:${hasCustomerIcsFileCustomisationValue && useCustomDataFieldsNoteCustomerValue}`] },
    { 'customNoteCustomer.value': [`requiredIf:${hasCustomerIcsFileCustomisationValue && useCustomNoteCustomerValue}`] },
    { 'icsAddressCustomer.value': [`requiredIf:${hasCustomerIcsFileCustomisationValue && icsAddressTypeCustomerValue === 'CUSTOM_ADDRESS'}`] },
    { 'customDataFieldsNoteResource.values': [`requiredIf:${hasCustomerIcsFileResourceValue && useCustomDataFieldsNoteResourceValue}`] },
    { 'customNoteResource.value': [`requiredIf:${hasCustomerIcsFileResourceValue && useCustomNoteResourceValue}`] },
    { 'icsAddressResource.value': [`requiredIf:${hasCustomerIcsFileResourceValue && icsAddressTypeResourceValue === 'CUSTOM_ADDRESS'}`] }
  ]

  const messages = {
    customTermsAndConditionsUrl: {
      requiredIf: 'errors.required'
    },
    customPrivacyPolicyUrl: {
      requiredIf: 'errors.required'
    },
    widgetExternalUrlRedirect: {
      requiredIf: 'errors.required'
    },
    disclaimerText: {
      requiredIf: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    googleConversionTrackingID: {
      requiredIf: 'errors.required'
    },
    facebookConversionTrackingID: {
      requiredIf: 'errors.required'
    },
    dateFormatPosition: {
      requiredIf: 'errors.required'
    },
    dateFormatYear: {
      requiredIf: 'errors.required'
    },
    dateFormatMonth: {
      requiredIf: 'errors.required'
    },
    dateFormatDay: {
      requiredIf: 'errors.required'
    },
    dateFormatSeparator: {
      requiredIf: 'errors.required'
    },
    timeFormatType: {
      requiredIf: 'errors.required'
    },
    timeFormat12: {
      requiredIf: 'errors.required'
    },
    timeFormat24: {
      requiredIf: 'errors.required'
    },
    customConsentMessage: {
      requiredIf: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    customDataFieldsNoteCustomer: {
      requiredIf: 'errors.required'
    },
    customNoteCustomer: {
      requiredIf: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    icsAddressCustomer: {
      requiredIf: 'errors.required'
    },
    customDataFieldsNoteResource: {
      requiredIf: 'errors.required'
    },
    customNoteResource: {
      requiredIf: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    icsAddressResource: {
      requiredIf: 'errors.required'
    }
  }
  const replaces = {
    customTermsAndConditionsUrl: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.termsAndConditions',
        translateReplace: true
      }
    },
    customPrivacyPolicyUrl: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.privacyPolicy',
        translateReplace: true
      }
    },
    widgetExternalUrlRedirect: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.redirectionLink',
        translateReplace: true
      }
    },
    googleConversionTrackingID: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'Google Tracking ID'
      }
    },
    facebookConversionTrackingID: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'Facebook Pixel ID'
      }
    },
    disclaimerText: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.widget.input.disclaimer.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '400'
      }
    },
    dateFormatPosition: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.dateFormat.dateFormatPosition.label',
        translateReplace: true
      }
    },
    dateFormatYear: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.dateFormat.dateFormatYear.label',
        translateReplace: true
      }
    },
    dateFormatMonth: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.dateFormat.dateFormatMonth.label',
        translateReplace: true
      }
    },
    dateFormatDay: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.dateFormat.dateFormatDay.label',
        translateReplace: true
      }
    },
    dateFormatSeparator: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.dateFormat.dateFormatSeparator.label',
        translateReplace: true
      }
    },
    timeFormatType: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.timeFormat.timeFormatType.label',
        translateReplace: true
      }
    },
    timeFormat12: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.timeFormat.timeFormatTwelve.label',
        translateReplace: true
      }
    },
    timeFormat24: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'ettings.section.timeFormat.timeFormatTwentyFour.label',
        translateReplace: true
      }
    },
    customConsentMessage: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.widget.conversionTracking.customConsentMessage.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '1000'
      }
    },
    customDataFieldsNoteCustomer: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.icsFileCustomisation.customer.bookingDataFields.label',
        translateReplace: true
      }
    },
    customNoteCustomer: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.icsFileCustomisation.customer.notes.options.customNote.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '4000'
      }
    },
    icsAddressCustomer: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.icsFileCustomisation.customer.bookingLocation.option.otherAddress.textarea.label',
        translateReplace: true
      }
    },
    customDataFieldsNoteResource: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.icsFileCustomisation.resource.bookingDataFields.label',
        translateReplace: true
      }
    },
    customNoteResource: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.icsFileCustomisation.resource.notes.options.customNote.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '4000'
      }
    },
    icsAddressResource: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'settings.section.icsFileCustomisation.resource.bookingLocation.option.otherAddress.textarea.label',
        translateReplace: true
      }
    }
  }
  const errors = validator(bookingSettings, rules, messages, replaces)
  const tabsWithErrors = []
  const missingServiceLimit = enforceServiceDurationLimitCheckboxValue && enforceServiceDurationLimitValue !== 0 && !enforceServiceDurationLimitValue
  if (missingServiceLimit) {
    errors.push({
      key: 'enforceServiceDurationLimit',
      value: 'errors.services.chooseDuration'
    })
    tabsWithErrors.push('global.calendar')
  }
  if (enforceReschedulingTagRuleCheckboxValue && !enforceReschedulingTagRuleValue) {
    errors.push({
      key: 'enforceReschedulingTagRule',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.tag', translateReplace: true }]
    })
    tabsWithErrors.push('global.booking')
  }
  if (!hasTimifyLoginValue && !hasFacebookLoginValue && !hasGuestBookingValue) {
    errors.push({ key: 'settingsAuthGlobalErrors', value: 'errors.settings.registration.required' })
    tabsWithErrors.push('global.widget')
  }
  if (showCustomTermsAndConditionsValue && customTermsAndConditionsUrlValue && !isUrl(customTermsAndConditionsUrlValue)) {
    errors.push({
      key: 'customTermsAndConditionsUrl',
      value: 'errors.url.invalid'
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  if (showCustomTermsAndConditionsValue && !customTermsAndConditionsUrlValue) {
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  if (showCustomPrivacyPolicyValue && customPrivacyPolicyUrlValue && !isUrl(customPrivacyPolicyUrlValue)) {
    errors.push({
      key: 'customPrivacyPolicyUrl',
      value: 'errors.url.invalid'
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  if (showCustomPrivacyPolicyValue && !customPrivacyPolicyUrlValue) {
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  if (showWidgetExternalUrlRedirectValue && widgetExternalUrlRedirectValue && !isUrl(widgetExternalUrlRedirectValue)) {
    errors.push({
      key: 'widgetExternalUrlRedirect',
      value: 'errors.url.invalid'
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  if (showWidgetExternalUrlRedirectValue && !widgetExternalUrlRedirectValue) {
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  // Disclaimer
  if (disclaimerTextValue && extractFromHtml(removeMultipleSpaces(disclaimerTextValue)).trim().length > 400) {
    errors.push({
      key: 'disclaimerText',
      value: messages.disclaimerText.max,
      replace: [replaces.disclaimerText.max]
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }
  if (showDisclaimerValue && extractFromHtml(removeMultipleSpaces(disclaimerTextValue)).trim().length === 0) {
    errors.push({
      key: 'disclaimerText',
      value: messages.disclaimerText.requiredIf,
      replace: [replaces.disclaimerText.requiredIf]
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }

  // custom consent message
  if ((useGoogleConversionTrackingValue || useFacebookConversionTrackingValue) && useCustomiseConsentMessageValue && customConsentMessageValue && extractFromHtml(removeMultipleSpaces(customConsentMessageValue)).trim().length > 1000) {
    errors.push({
      key: 'customConsentMessage',
      value: messages.customConsentMessage.max,
      replace: [replaces.customConsentMessage.max]
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }

  if ((useGoogleConversionTrackingValue || useFacebookConversionTrackingValue) && useCustomiseConsentMessageValue && customConsentMessage && extractFromHtml(removeMultipleSpaces(customConsentMessageValue)).trim().length === 0) {
    errors.push({
      key: 'customConsentMessage',
      value: messages.customConsentMessage.requiredIf,
      replace: [replaces.customConsentMessage.requiredIf]
    })
    if (!tabsWithErrors.includes('global.widget')) tabsWithErrors.push('global.widget')
  }

  // Service Allocations
  if (hasServiceAllocationsValue) {
    if (serviceAllocationsValues.length === 0) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'serviceAllocationCountErrors',
        value: 'errors.serviceAllocations.requiredOne'
      })
    }
    if (!shouldAllowBookingWidgetValue && !shouldDisplayInfoValue) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'serviceAllocationSettingsErrors',
        value: 'errors.serviceAllocations.requiredOneSetting'
      })
    }
    if (isServiceAllocationsEnabledFixedTimeValue && serviceAllocationsEnabledFixedTimeLeadTimeValue < 1) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'serviceAllocationsEnabledFixedTimeLeadTime',
        value: 'errors.serviceAllocations.durationMinValue'
      })
    }
    const maxHoursLimit = 144
    const maxDaysLimit = maxHoursLimit / 24
    const calculatedSuspendLeadTimeValue = serviceAllocationsEnabledFixedTimeUnitsValue * serviceAllocationsEnabledFixedTimeLeadTimeValue
    const isTimeUnitDays = serviceAllocationsEnabledFixedTimeUnitsValue % 24 === 0
    if (isServiceAllocationsEnabledFixedTimeValue && calculatedSuspendLeadTimeValue > maxHoursLimit) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'serviceAllocationSuspendTimeErrors',
        value: isTimeUnitDays ? 'errors.serviceAllocations.durationMaxValueDays' : 'errors.serviceAllocations.durationMaxValue',
        replace: [{ key: 'MAX', value: isTimeUnitDays ? maxDaysLimit : maxHoursLimit }]
      })
    }
    const serviceAllocationsTotalPercent = serviceAllocationsValues.reduce((acc, serviceAllocation) => {
      return acc + serviceAllocation.percentage
    }, 0)
    if (serviceAllocationsTotalPercent > 99) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'serviceAllocationTotalPercentErrors',
        value: 'errors.serviceAllocations.totalPercent'
      })
    }
    serviceAllocationsValues.forEach(serviceAllocation => {
      if (serviceAllocation.percentage < 1) {
        if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
        errors.push({
          key: `serviceAllocation${serviceAllocation.id}PercentErrors`,
          value: 'errors.serviceAllocations.percent'
        })
      }
      if ((!serviceAllocation.name || serviceAllocation.name.length > 0 && serviceAllocation.name.trim()?.length === 0) || serviceAllocation.name.length === 0) {
        if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
        errors.push({
          key: `serviceAllocation${serviceAllocation.id}NameErrors`,
          value: 'errors.serviceAllocations.name'
        })
      }
      if (serviceAllocation.serviceIds && serviceAllocation.serviceIds.length === 0) {
        if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
        errors.push({
          key: `serviceAllocation${serviceAllocation.id}ServiceErrors`,
          value: 'errors.serviceAllocations.serviceRequired'
        })
      }
    })
  }

  // Public Holidays
  if (hasPublicHolidaysValue) {
    if (publicHolidaysValues.length === 0) {
      if (!tabsWithErrors.includes('global.calendar')) tabsWithErrors.push('global.calendar')
      errors.push({
        key: 'globalErrors',
        value: 'errors.publicHolidays.requiredOne'
      })
    }
    publicHolidaysValues.forEach(item => {
      const publicHolidaysKeys = item.publicHolidayKeys || []
      if (!publicHolidaysKeys.length) {
        if (!tabsWithErrors.includes('global.calendar')) tabsWithErrors.push('global.calendar')
        errors.push({
          key: 'globalErrors',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'settings.section.publicHolidays.title', translateReplace: true }]
        })
      }
      if (item.resourceCategoryIds && item.resourceCategoryIds.length === 0) {
        if (!tabsWithErrors.includes('global.calendar')) tabsWithErrors.push('global.calendar')
        errors.push({
          key: 'globalErrors',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'global.resourceCategory', translateReplace: true }]
        })
      }
    })
  }

  // Ics File Customisation
  if (useCustomNoteCustomerValue && customNoteCustomerValue && extractFromHtml(removeMultipleSpaces(customNoteCustomerValue)).trim().length > 4000) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'customNoteCustomer',
      value: messages.customNoteCustomer.max,
      replace: [replaces.customNoteCustomer.max]
    })
  }
  if (useCustomNoteCustomerValue && customNoteCustomerValue && extractFromHtml(removeMultipleSpaces(customNoteCustomerValue)).trim().length === 0) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'customNoteCustomer',
      value: messages.customNoteCustomer.requiredIf,
      replace: [replaces.customNoteCustomer.requiredIf]
    })
  }

  if (icsAddressTypeCustomerValue && icsAddressTypeCustomerValue === 'CUSTOM_ADDRESS' && !icsAddressCustomerValue) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
  }

  if (useCustomDataFieldsNoteCustomerValue && customDataFieldsNoteCustomerValues) {
    if (customDataFieldsNoteCustomerValues.length === 0) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    }
  }

  if (useCustomNoteResourceValue && customNoteResourceValue && extractFromHtml(removeMultipleSpaces(customNoteResourceValue)).trim().length > 4000) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'customNoteResource',
      value: messages.customNoteResource.max,
      replace: [replaces.customNoteResource.max]
    })
  }
  if (useCustomNoteResourceValue && customNoteResourceValue && extractFromHtml(removeMultipleSpaces(customNoteResourceValue)).trim().length === 0) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'customNoteResource',
      value: messages.customNoteResource.requiredIf,
      replace: [replaces.customNoteResource.requiredIf]
    })
  }

  if (icsAddressTypeResourceValue && icsAddressTypeResourceValue === 'CUSTOM_ADDRESS' && !icsAddressResourceValue) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
  }

  if (useCustomDataFieldsNoteResourceValue && customDataFieldsNoteResourceValues) {
    if (customDataFieldsNoteResourceValues.length === 0) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    }
  }

  // Max lead times
  if (maximumLeadTimeValue && (parseInt(maximumLeadTimeValue, 10) === 0 || !NUMBERS_VALIDATION_REGEX.test(maximumLeadTimeValue))) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'maximumLeadTime',
      value: 'errors.frequency.onlyNumbers'
    })
  }
  if (!maximumLeadTimeValue) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'maximumLeadTime',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'settings.section.leadTime.input.label', translateReplace: true }]
    })
  }
  if (!maximumLeadTimeDurationValue) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'maximumLeadTimeDuration',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'settings.section.leadTimeDuration.select.label', translateReplace: true }]
    })
  }
  if (maximumLeadTimeValue && maximumLeadTimeDurationValue && parseInt(maximumLeadTimeValue, 10) > MAX_LEAD_TIME_MAXIMUMS[maximumLeadTimeDurationValue]) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'maximumLeadTime',
      value: 'errors.minMaxLeadTime.maximum',
      replace: [
        { key: 'MAX_VALUE', value: MAX_LEAD_TIME_MAXIMUMS[maximumLeadTimeDurationValue] },
        { key: 'MAX_DURATION', value: `global.${maximumLeadTimeDurationValue.toLowerCase()}.counter`, translateReplace: true }
      ]
    })
  }

  // Min lead times
  if (minimumLeadTimeValue && !NUMBERS_VALIDATION_REGEX.test(minimumLeadTimeValue)) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'minimumLeadTime',
      value: 'errors.fillNumbers'
    })
  }
  if (minimumLeadTimeValue && !minimumLeadTimeDurationValue) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'minimumLeadTimeDuration',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'settings.section.leadTimeDuration.select.label', translateReplace: true }]
    })
  }
  if (minimumLeadTimeValue && minimumLeadTimeDurationValue && parseInt(minimumLeadTimeValue, 10) > MIN_LEAD_TIME_MAXIMUMS[minimumLeadTimeDurationValue]) {
    if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
    errors.push({
      key: 'minimumLeadTime',
      value: 'errors.minMaxLeadTime.maximum',
      replace: [
        { key: 'MAX_VALUE', value: MIN_LEAD_TIME_MAXIMUMS[minimumLeadTimeDurationValue] },
        { key: 'MAX_DURATION', value: `global.${minimumLeadTimeDurationValue.toLowerCase()}.counter`, translateReplace: true }
      ]
    })
  }

  // cancellation lead time
  if (useCancellationLeadTimeValue && cancellationLeadTimeAnyTimeValue === 'no') {
    if (cancellationLeadTimeValue && !NUMBERS_VALIDATION_REGEX.test(cancellationLeadTimeValue)) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'cancellationLeadTime',
        value: 'errors.fillNumbers'
      })
    }
    if (!cancellationLeadTimeValue || cancellationLeadTimeValue < 1) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'cancellationLeadTime',
        value: 'errors.required',
        replace: [{ key: 'FIELD_NAME', value: 'settings.section.leadTime.input.label', translateReplace: true }]
      })
    }
    if (cancellationLeadTimeValue && !cancellationLeadTimeDurationValue) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'cancellationLeadTimeDuration',
        value: 'errors.required',
        replace: [{ key: 'FIELD_NAME', value: 'settings.section.leadTimeDuration.select.label', translateReplace: true }]
      })
    }
    if (cancellationLeadTimeValue && cancellationLeadTimeDurationValue && parseInt(cancellationLeadTimeValue, 10) > CANCELATION_LEAD_TIME_MAXIMUMS[cancellationLeadTimeDurationValue]) {
      if (!tabsWithErrors.includes('global.booking')) tabsWithErrors.push('global.booking')
      errors.push({
        key: 'cancellationLeadTime',
        value: 'errors.minMaxLeadTime.maximum',
        replace: [
          { key: 'MAX_VALUE', value: CANCELATION_LEAD_TIME_MAXIMUMS[cancellationLeadTimeDurationValue] },
          { key: 'MAX_DURATION', value: `global.${cancellationLeadTimeDurationValue.toLowerCase()}.counter`, translateReplace: true }
        ]
      })
    }
  }
  // Past booking
  if (
    allowPartialUpdateOfBookingsInThePastValue &&
    !(pastBookingTitleValue ||
      pastBookingTagsValue ||
      pastBookingStartTimeValue ||
      pastBookingResourcesValue ||
      pastBookingPrepAndFollowUpValue ||
      pastBookingParticipantsAndGuestsValue ||
      pastBookingNotesValue ||
      pastBookingGroupPriceValue ||
      pastBookingDurationValue ||
      pastBookingCustomerValue ||
      pastBookingCustomDataFieldsValue
    )
  ) {
    if (!tabsWithErrors.includes('global.calendar')) tabsWithErrors.push('global.calendar')
    errors.push({ key: 'pastBookingTags', value: 'errors.settings.pastBooking.atLeastOne' })
  }

  if (errors.length) {
    errors.push({
      key: 'globalErrors',
      value: 'errors.tabs.followingTabs',
      replace: [{
        key: 'TAB_NAME',
        value: tabsWithErrors,
        translateReplace: true
      }]
    })
    return errors
  }
}

export const bookingSettingsSaveTransform = (settings, customerFields = [], isAdmin) => {
  let enforceResourcesAllowanceTypeValue = null

  let {
    publicHolidays,
    showDisclaimer,
    disclaimerText,
    disclaimerType,
    serviceAllocations: serviceAllocationsSetting,
    isServiceAllocationsEnabledFixedTime,
    shouldAllowBookingWidget,
    shouldDisplayInfo,
    serviceAllocationsEnabledFixedTimeLeadTime,
    serviceAllocationsEnabledFixedTimeUnits,
    forbidAddingBookingsInThePast,
    forbidUpdatingBookingsInThePast,
    allowPartialUpdateOfBookingsInThePast,
    pastBookingTitle,
    pastBookingStartTime,
    pastBookingDuration,
    pastBookingResources,
    pastBookingPrepAndFollowUp,
    pastBookingCustomer,
    pastBookingCustomDataFields,
    pastBookingNotes,
    pastBookingTags,
    pastBookingParticipantsAndGuests,
    pastBookingGroupPrice,
    forbidDeletingBookingInThePast
  } = settings || {}
  const { value: showDisclaimerValue } = showDisclaimer || {}
  const { value: disclaimerTextValue } = disclaimerText || {}
  const { value: disclaimerTypeValue } = disclaimerType || {}
  const hasDisclaimerActive = !!showDisclaimerValue && !!disclaimerTextValue && !!disclaimerTypeValue
  // Service allocations transformation
  serviceAllocationsSetting = serviceAllocationsSetting || {}
  const { values: serviceAllocationsSettingValues } = serviceAllocationsSetting || {}
  let { value: isServiceAllocationsEnabledFixedTimeValue } = isServiceAllocationsEnabledFixedTime || {}
  isServiceAllocationsEnabledFixedTimeValue = !!isServiceAllocationsEnabledFixedTimeValue
  const { value: shouldAllowBookingWidgetValue } = shouldAllowBookingWidget || {}
  const { value: shouldDisplayInfoValue } = shouldDisplayInfo || {}
  let { value: serviceAllocationsEnabledFixedTimeLeadTimeValue } = serviceAllocationsEnabledFixedTimeLeadTime || {}
  serviceAllocationsEnabledFixedTimeLeadTimeValue = parseFloat(serviceAllocationsEnabledFixedTimeLeadTimeValue || 0)
  let { value: serviceAllocationsEnabledFixedTimeUnitsValue } = serviceAllocationsEnabledFixedTimeUnits || {}
  serviceAllocationsEnabledFixedTimeUnitsValue = parseFloat(serviceAllocationsEnabledFixedTimeUnitsValue || 0)
  const hoursBeforeAllocationExpiration = isServiceAllocationsEnabledFixedTimeValue
    ? serviceAllocationsEnabledFixedTimeLeadTimeValue * serviceAllocationsEnabledFixedTimeUnitsValue
    : 0
  const serviceAllocationsSettings = {
    shouldAllowBookingWidget: shouldAllowBookingWidgetValue,
    shouldDisplayInfo: shouldDisplayInfoValue,
    hoursBeforeAllocationExpiration
  }
  const serviceAllocations = serviceAllocationsSettingValues.map(serviceAllocation => ({
    name: serviceAllocation.name,
    serviceIds: serviceAllocation.serviceIds,
    percentage: serviceAllocation.percentage
  }))
  // PH transformation
  publicHolidays = publicHolidays || {}
  let { deleted: deletedPublicHolidays } = publicHolidays || {}
  deletedPublicHolidays = deletedPublicHolidays || []
  let { modified: modifiedPublicHolidays } = publicHolidays || {}
  modifiedPublicHolidays = modifiedPublicHolidays || []
  modifiedPublicHolidays = modifiedPublicHolidays.map(({ index, resourceCategoryIds, ...item }) => {
    const phGroup = {
      ...item,
      resourceCategoryIds: resourceCategoryIds && resourceCategoryIds.length > 0 && resourceCategoryIds.includes('all')
        ? []
        : resourceCategoryIds
    }
    if (item.isNew) delete phGroup.id
    delete phGroup.isNew
    return phGroup
  })

  if (settings.enforceResourcesAllowanceTypeCheckbox.value) {
    enforceResourcesAllowanceTypeValue = settings.enforceResourcesAllowanceTypeRadio.value
  }

  let customDataFieldsNoteResource = settings.useCustomDataFieldsNoteResource &&
    settings.useCustomDataFieldsNoteResource.value
    ? settings.customDataFieldsNoteResource &&
    settings.customDataFieldsNoteResource.values
    : []

  customDataFieldsNoteResource = customDataFieldsNoteResource.map(id => {
    const customerField = customerFields.find(customerFieldItem => customerFieldItem.id === id)
    if (!customerField) return null
    return {
      id: customerField.id,
      internalId: customerField.internalId
    }
  }).filter(item => !!item)

  let customDataFieldsNoteCustomer = settings.useCustomDataFieldsNoteCustomer &&
    settings.useCustomDataFieldsNoteCustomer.value
    ? settings.customDataFieldsNoteCustomer &&
    settings.customDataFieldsNoteCustomer.values
    : []

  customDataFieldsNoteCustomer = customDataFieldsNoteCustomer.map(id => {
    const customerField = customerFields.find(customerFieldItem => customerFieldItem.id === id)
    if (!customerField) return null
    return {
      id: customerField.id,
      internalId: customerField.internalId
    }
  }).filter(item => !!item)
  // Post Booking Transformation
  let { value: forbidAddingBookingsInThePastValue } = forbidAddingBookingsInThePast || {}
  let { value: forbidUpdatingBookingsInThePastValue } = forbidUpdatingBookingsInThePast || {}
  let { value: allowPartialUpdateOfBookingsInThePastValue } = allowPartialUpdateOfBookingsInThePast || {}
  let { value: pastBookingTitleValue } = pastBookingTitle || {}
  let { value: pastBookingStartTimeValue } = pastBookingStartTime || {}
  let { value: pastBookingDurationValue } = pastBookingDuration || {}
  let { value: pastBookingResourcesValue } = pastBookingResources || {}
  let { value: pastBookingPrepAndFollowUpValue } = pastBookingPrepAndFollowUp || {}
  let { value: pastBookingCustomerValue } = pastBookingCustomer || {}
  let { value: pastBookingCustomDataFieldsValue } = pastBookingCustomDataFields || {}
  let { value: pastBookingNotesValue } = pastBookingNotes || {}
  let { value: pastBookingTagsValue } = pastBookingTags || {}
  let { value: pastBookingParticipantsAndGuestsValue } = pastBookingParticipantsAndGuests || {}
  let { value: pastBookingGroupPriceValue } = pastBookingGroupPrice || {}
  let { value: forbidDeletingBookingInThePastValue } = forbidDeletingBookingInThePast || {}
  forbidAddingBookingsInThePastValue = forbidAddingBookingsInThePastValue || false
  forbidUpdatingBookingsInThePastValue = forbidUpdatingBookingsInThePastValue || false
  allowPartialUpdateOfBookingsInThePastValue = allowPartialUpdateOfBookingsInThePastValue || false
  pastBookingTitleValue = pastBookingTitleValue || false
  pastBookingStartTimeValue = pastBookingStartTimeValue || false
  pastBookingDurationValue = pastBookingDurationValue || false
  pastBookingResourcesValue = pastBookingResourcesValue || false
  pastBookingPrepAndFollowUpValue = pastBookingPrepAndFollowUpValue || false
  pastBookingCustomerValue = pastBookingCustomerValue || false
  pastBookingCustomDataFieldsValue = pastBookingCustomDataFieldsValue || false
  pastBookingNotesValue = pastBookingNotesValue || false
  pastBookingTagsValue = pastBookingTagsValue || false
  pastBookingParticipantsAndGuestsValue = pastBookingParticipantsAndGuestsValue || false
  pastBookingGroupPriceValue = pastBookingGroupPriceValue || false
  forbidDeletingBookingInThePastValue = forbidDeletingBookingInThePastValue || false
  const forbidPastBookingsUpdate = {
    title: pastBookingTitleValue,
    startTime: pastBookingStartTimeValue,
    duration: pastBookingDurationValue,
    resources: pastBookingResourcesValue,
    customDataFields: pastBookingCustomDataFieldsValue,
    prepAndFollowUp: pastBookingPrepAndFollowUpValue,
    customer: pastBookingCustomerValue,
    notes: pastBookingNotesValue,
    tags: pastBookingTagsValue,
    participantsAndGuests: pastBookingParticipantsAndGuestsValue,
    groupPrice: pastBookingGroupPriceValue
  }
  const forbidBookingsInThePast = {
    forbidAddingBookingsInThePast: forbidAddingBookingsInThePastValue,
    forbidUpdatingBookingsInThePast: forbidUpdatingBookingsInThePastValue,
    allowPartialUpdateOfBookingsInThePast: allowPartialUpdateOfBookingsInThePastValue,
    forbidPastBookingsUpdate,
    forbidDeletingBookingInThePast: forbidDeletingBookingInThePastValue
  }

  const result = {
    cancellationLeadTime: {
      value: !settings.useCancellationLeadTime.value
        ? -1
        : settings.cancellationLeadTimeAnyTime.value === 'yes'
          ? 0
          : parseInt(settings.cancellationLeadTime.value, 10),
      frequency: settings.cancellationLeadTimeDuration.value || 'HOURS'
    },
    snapToGridInterval: transformBookingInterval({ formToApi: settings.snapToGridInterval.value }),
    internalSnapToGridInterval: transformBookingInterval({ formToApi: settings.internalSnapToGridInterval.value }),
    hasTimifyLogin: !!settings.hasTimifyLogin.value,
    hasGuestBooking: !!settings.hasGuestBooking.value,
    hasFacebookLogin: !!settings.hasFacebookLogin.value,
    isWidgetWeeklyView: !!settings.isWidgetWeeklyView.value,
    showWidgetLanguageSelector: !!settings.showWidgetLanguageSelector.value,
    showWidgetCategoriesClosed: !!settings.showWidgetCategoriesClosed.value,
    showWidgetFirstCategoryOpen: settings.showWidgetCategoriesClosed.value ? !!settings.showWidgetFirstCategoryOpen.value : false,
    widgetConversions: {
      facebookPixelId: settings.useFacebookConversionTracking && settings.useFacebookConversionTracking.value && settings.facebookConversionTrackingID ? settings.facebookConversionTrackingID.value : null,
      googleAnalyticsId: settings.useGoogleConversionTracking && settings.useGoogleConversionTracking.value && settings.googleConversionTrackingID ? settings.googleConversionTrackingID.value : null,
      customMessage: ((settings.useFacebookConversionTracking && settings.useFacebookConversionTracking.value) || (settings.useGoogleConversionTracking && settings.useGoogleConversionTracking.value)) && settings.useCustomiseConsentMessage && settings.useCustomiseConsentMessage.value && settings.customConsentMessage ? settings.customConsentMessage.value : null,
      messageTarget: ((settings.useFacebookConversionTracking && settings.useFacebookConversionTracking.value) || (settings.useGoogleConversionTracking && settings.useGoogleConversionTracking.value)) && settings.customMessageVisibility ? settings.customMessageVisibility.value : null
    },
    showWidgetTimezoneSelector: !!settings.showWidgetTimezoneSelector.value,
    hideResourcesSection: !!settings.hideResourcesSection.value,
    hideServiceDuration: !!settings.hideServiceDuration.value,
    showExternalIds: !!settings.showExternalIds.value,
    enableCrossAccountsBookings: !!settings.enableCrossAccountsBookings.value,
    hideFastWidgetTimeFrame: !settings.showFastWidgetTimeFrame.value,
    enforceServiceDurationLimit: settings.enforceServiceDurationLimitCheckbox.value
      ? settings.enforceServiceDurationLimit.value
      : -1,
    enforceResourcesAllowanceType: enforceResourcesAllowanceTypeValue,
    enforceReschedulingTagRule: settings.enforceReschedulingTagRuleCheckbox.value
      ? settings.enforceReschedulingTagRule.value
      : null,
    customTermsAndConditionsUrl: settings.showCustomTermsAndConditions.value
      ? settings.customTermsAndConditionsUrl.value || null
      : null,
    customPrivacyPolicyUrl: settings.showCustomPrivacyPolicy.value
      ? settings.customPrivacyPolicyUrl.value || null
      : null,
    disclaimerConfig: hasDisclaimerActive
      ? {
          active: true,
          text: disclaimerTextValue,
          displayOn: disclaimerTypeValue
        }
      : null,
    hasNotifyOnResources: settings.hasNotifyOnResources.value === 'default'
      ? 'SELECTED'
      : settings.hasNotifyOnResources.value === 'selected'
        ? 'TRUE'
        : 'FALSE',
    hasNotifyOnCustomers: settings.hasNotifyOnCustomers.value === 'default'
      ? 'SELECTED'
      : settings.hasNotifyOnCustomers.value === 'selected'
        ? 'TRUE'
        : 'FALSE',
    resetCopyBookingClipboardAfterPaste: settings.resetCopyBookingClipboardAfterPaste.value === 'once',
    forbidCreatingAndUpdatingBookingsInThePast: settings.forbidCreatingAndUpdatingBookingsInThePast.value,
    forbidMoreThanOneBookingInFBW: !settings.forbidMoreThanOneBookingInFBW.value,
    widgetExternalUrlRedirect: settings.showWidgetExternalUrlRedirect.value
      ? settings.widgetExternalUrlRedirect.value
      : null,
    showAddServiceCombinations: !!settings.showAddServiceCombinations.value,
    publicHolidays: {
      publicHolidayIdsToDelete: deletedPublicHolidays,
      publicHolidaysToSave: modifiedPublicHolidays
    },
    maximumLeadTime: {
      value: parseInt(settings.maximumLeadTime.value, 10),
      frequency: settings.maximumLeadTimeDuration.value
    },
    minimumLeadTime: {
      value: settings.minimumLeadTime.value ? parseInt(settings.minimumLeadTime.value, 10) : 0,
      frequency: settings.minimumLeadTimeDuration.value
    },
    servicesAllocation: settings.hasServiceAllocations.value
      ? {
          settings: serviceAllocationsSettings,
          allocations: serviceAllocations
        }
      : {},
    dateFormat: settings.hasDateFormat && settings.hasDateFormat.value
      ? {
          dateFormatPosition: settings.dateFormatPosition && settings.dateFormatPosition.value,
          dateFormatYear: settings.dateFormatYear && settings.dateFormatYear.value,
          dateFormatMonth: settings.dateFormatMonth && settings.dateFormatMonth.value,
          dateFormatDay: settings.dateFormatDay && settings.dateFormatDay.value,
          dateFormatSeparator: settings.dateFormatSeparator && settings.dateFormatSeparator.value
        }
      : {},
    timeFormat: settings.hasTimeFormat && settings.hasTimeFormat.value
      ? {
          timeFormatType: settings.timeFormatType && settings.timeFormatType.value,
          timeFormatTwelve: settings.timeFormat12 && settings.timeFormat12.value,
          timeFormatTwentyFour: settings.timeFormat24 && settings.timeFormat24.value
        }
      : {},
    icsFileCustomisation: {
      icsCustomisationCustomer: (
        settings.hasCustomerIcsFileCustomisation && settings.hasCustomerIcsFileCustomisation.value
          ? {
              customerIcsSubject: settings.icsSubjectCustomer && settings.icsSubjectCustomer.value,
              customerIcsAddress: {
                icsAddressType: settings.icsAddressTypeCustomer && settings.icsAddressTypeCustomer.value,
                icsAddress: settings.icsAddressTypeCustomer && settings.icsAddressTypeCustomer.value === 'CUSTOM_ADDRESS'
                  ? settings.icsAddressCustomer && settings.icsAddressCustomer.value
                  : ''
              },
              customerIcsNotes: {
                companyNameNote: settings.companyNameNoteCustomer && settings.companyNameNoteCustomer.value,
                onlineBookableResourceNote: settings.onlineBookableResourceNoteCustomer && settings.onlineBookableResourceNoteCustomer.value,
                dependantResourceNote: settings.dependantResourceNoteCustomer && settings.dependantResourceNoteCustomer.value,
                bookedServiceNote: settings.bookedServiceNoteCustomer && settings.bookedServiceNoteCustomer.value,
                bookedServiceDescriptionNote: settings.bookedServiceDescriptionNoteCustomer && settings.bookedServiceDescriptionNoteCustomer.value,
                bookedServicePriceNote: settings.bookedServicePriceNoteCustomer && settings.bookedServicePriceNoteCustomer.value,
                eventCancellationLink: settings.linkToCancelRescheduleNoteCustomer && settings.linkToCancelRescheduleNoteCustomer.value,
                customDataFieldsNote: customDataFieldsNoteCustomer,
                hasCustomNote: settings.useCustomNoteCustomer && settings.useCustomNoteCustomer.value,
                customNote: settings.useCustomNoteCustomer && settings.useCustomNoteCustomer.value
                  ? settings.customNoteCustomer && settings.customNoteCustomer.value
                  : ''
              }
            }
          : {}),
      icsCustomisationResource: (
        settings.hasResourceIcsFileCustomisation && settings.hasResourceIcsFileCustomisation.value
          ? {
              resourceIcsSubject: settings.icsSubjectResource && settings.icsSubjectResource.value,
              resourceIcsAddress: {
                icsAddressType: settings.icsAddressTypeResource && settings.icsAddressTypeResource.value,
                icsAddress: settings.icsAddressTypeResource && settings.icsAddressTypeResource.value === 'CUSTOM_ADDRESS'
                  ? settings.icsAddressResource && settings.icsAddressResource.value
                  : ''
              },
              resourceIcsNotes: {
                customerNameNote: settings.customerNameNoteResource && settings.customerNameNoteResource.value,
                onlineBookableResourceNote: settings.onlineBookableResourceNoteResource && settings.onlineBookableResourceNoteResource.value,
                dependantResourceNote: settings.dependantResourceNoteResource && settings.dependantResourceNoteResource.value,
                bookedServiceNote: settings.bookedServiceNoteResource && settings.bookedServiceNoteResource.value,
                bookedServiceDescriptionNote: settings.bookedServiceDescriptionNoteResource && settings.bookedServiceDescriptionNoteResource.value,
                bookedServicePriceNote: settings.bookedServicePriceNoteResource && settings.bookedServicePriceNoteResource.value,
                linkToTimifyCalendar: settings.linkToAppointmentResource && settings.linkToAppointmentResource.value,
                customDataFieldsNote: customDataFieldsNoteResource,
                hasCustomNote: settings.useCustomNoteResource && settings.useCustomNoteResource.value,
                customNote: settings.useCustomNoteResource && settings.useCustomNoteResource.value ? settings.customNoteResource.value : ''
              }
            }
          : {})
    },
    callCenterLoginButton: settings.callCenterLoginButton && settings.callCenterLoginButton.value,
    forbidBookingsInThePast
  }

  if (!isAdmin) {
    delete result.callCenterLoginButton
  }

  return result
}

export const bookingSettingsFormServerErrorsTransform = (error) => {
  const errors = []
  if (error.code) {
    if (error.code === 'GlobalServicesAllocationsSaveError' || error.code === 'GlobalHolidaySaveError') {
      errors.push({
        key: 'globalErrors',
        value: translateServerCode(error.code),
        replace: [{ key: 'CONTENT', value: error.value }]
      })
    } else if (error.code === 'ExternalIdLimitEnterprise') {
      errors.push({ key: 'showExternalIds', value: translateServerCode(error.code) })
    } else {
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
    }
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}
