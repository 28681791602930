import React from 'react'
import PropTypes from 'prop-types'
import {
  SectionTitle,
  Input,
  Link,
  Form,
  Row,
  Col,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupUsersDelete = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__users-delete'>
      <SectionTitle
        label={dangerousHTML(t('popup.users.delete'))}
        icon='trash-alt'
        ignoreTopOffset
      />
      <Form name='usersDelete' onSubmit={approve}>
        <Row>
          <Col className='relative'>
            <Input
              name='name'
              label={t('global.name')}
              hintText={t('users.preview.heading.buttonDelete.tooltip.hint')}
              mandatory
            />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Link className='ta-btn ta-btn-secondary' onClick={reject}>
            {t('buttons.cancel.label')}
          </Link>
          <button type='submit' className='ta-btn ta-btn-primary'>
            {t('global.delete')}
          </button>
        </div>
      </Form>
    </div>
  )
}

PopupUsersDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupUsersDelete
