import React from 'react'
import {
  FormSection,
  SectionTitle,
  Error,
  Row,
  Col,
  HintText,
  Input,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'

const BranchesCourseFormParticipants = ({ allowedSet }) => {
  const isMaxParticipantsDisabled = !allowedSet?.includes('maxParticipants')
  const isExtraPersonsPerParticipantDisabled = !allowedSet?.includes('extraPersonsPerParticipant')

  return (
    <FormSection>
      <SectionTitle label={t('branches.form.group.edit.participants.title')} icon='sliders-h' />
      <Row noOffset>
        <Col>
          <HoverPopup disabled={!isMaxParticipantsDisabled} className='ta-services__form__description-hover-popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Input
                name='maxParticipants'
                disabled={isMaxParticipantsDisabled}
                label={t('global.maxParticipants.label')}
                mandatory
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
        <Col>
          <HoverPopup disabled={!isExtraPersonsPerParticipantDisabled} className='ta-services__form__description-hover-popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Input
                name='extraPersonsPerParticipant'
                disabled={isExtraPersonsPerParticipantDisabled}
                label={t('global.guests.label')}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      <Row>
        <Col>
          <HintText>
            {dangerousHTML(t('branches.form.group.edit.participants.maxParticipantsMessage'))}
          </HintText>
        </Col>
      </Row>
      <Error name='participantsGlobalErrors' noTopOffset />
    </FormSection>
  )
}

export default BranchesCourseFormParticipants
