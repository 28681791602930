import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  SectionTitle,
  Row,
  Col,
  t,
  SingleSelect
} from '../../../Common'

const UserLanguageForm = props => {
  const {
    title,
    message,
    onSubmit,
    cancelLink
  } = props

  return (
    <div className='ta-account__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-account__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <>{message && message.text}</>
          <Form onSubmit={onSubmit} name='userLanguageChange'>
            <FormSection>
              <SectionTitle label={t('user.edit.account.sectionLanguage')} icon='language' />
              <Row>
                <Col>
                  <SingleSelect name='language' label={t('forms.selectbox.language.label')} />
                </Col>
              </Row>
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

UserLanguageForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default UserLanguageForm
