import React, { Component } from 'react'
import { WeekDay } from '../../Beauties'

class WeekDayWrapper extends Component {
  render () {
    const { day, prefix, isAvailabilityDisabled, intervalGapMessage } = this.props

    return (
      <WeekDay
        day={day}
        prefix={prefix}
        isAvailabilityDisabled={isAvailabilityDisabled}
        intervalGapMessage={intervalGapMessage}
      />
    )
  }
}

export default WeekDayWrapper
