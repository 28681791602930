import React, { Component } from 'react'

class FixedContentBody extends Component {
  render () {
    const { children } = this.props

    return (
      <div className='ta-fixed-content__body'>
        {children}
      </div>
    )
  }
}

export default FixedContentBody
