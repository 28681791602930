import React from 'react'
import {
  SectionTitle,
  Row,
  Col,
  FormSection,
  t,
  FormText,
  BorderedBox,
  StripedTitle
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const keysMap = {
  externalId: 'global.externalId',
  name: 'globalSettings.form.section.attributes.serviceName',
  color: 'globalSettings.form.section.attributes.serviceColor',
  description: 'globalSettings.form.section.attributes.serviceDescription',
  durationsPattern: 'globalSettings.form.section.attributes.duration',
  durationBefore: 'globalSettings.form.section.attributes.preparationTime',
  durationAfter: 'globalSettings.form.section.attributes.followup',
  maxParticipants: 'global.participants',
  extraPersonsPerParticipant: 'globalSettings.form.section.attributes.guests',
  hasCombinationSameResourcesPreference: 'globalSettings.form.section.attributes.assignToSameResource',
  combinationServiceIds: 'globalSettings.form.section.attributes.serviceSelection',
  dependencies: 'globalSettings.form.section.attributes.resources',
  price: 'globalSettings.form.section.attributes.price',
  isBookable: 'globalSettings.form.section.attributes.displayInBookingWidget',
  hasOnlinePayment: 'globalSettings.form.section.attributes.onlinePayment',
  isPaymentMandatory: 'globalSettings.form.section.attributes.onlinePaymentMandatory',
  customerEmailRemindersMinutes: 'globalSettings.form.section.attributes.customerEmailReminders',
  resourceEmailRemindersMinutes: 'globalSettings.form.section.attributes.resourceEmailReminders'
}

const order = {
  services: [
    'externalId',
    'name',
    'color',
    'description',
    'durationsPattern',
    'durationBefore',
    'durationAfter',
    'extraPersonsPerParticipant',
    'hasCombinationSameResourcesPreference',
    'combinationServiceIds',
    'dependencies',
    'price',
    'isBookable',
    'hasOnlinePayment',
    'isPaymentMandatory',
    'customerEmailRemindersMinutes',
    'resourceEmailRemindersMinutes'
  ],
  combinations: [
    'externalId',
    'name',
    'description',
    'combinationServiceIds',
    'durationBefore',
    'durationAfter',
    'isCombinationPriceOverwritten',
    'price',
    'hasCombinationSameResourcesPreference',
    'isBookable',
    'hasOnlinePayment',
    'isPaymentMandatory',
    'customerEmailRemindersMinutes',
    'resourceEmailRemindersMinutes'
  ],
  courses: [
    'externalId',
    'name',
    'color',
    'description',
    'durationsPattern',
    'durationBefore',
    'durationAfter',
    'maxParticipants',
    'extraPersonsPerParticipant',
    'dependencies',
    'price',
    'isBookable',
    'hasOnlinePayment',
    'isPaymentMandatory',
    'customerEmailRemindersMinutes',
    'resourceEmailRemindersMinutes'
  ]
}

const GlobalsSettingsList = props => {
  const {
    isCompanyTagsLocalUpdateForbidden,
    isCustomersLocalUpdateForbidden,
    isCustomerFieldsLocalUpdateForbidden,
    isResourceLocalUpdateForbidden,
    serviceLocalUpdates,
    serviceCombinationLocalUpdates,
    courseLocalUpdates
  } = props

  const isServiceLocalUpdateForbidden = serviceLocalUpdates.isForbidden
  const isCourseLocalUpdateForbidden = courseLocalUpdates.isForbidden
  const isCombinationServiceLocalUpdateForbidden = serviceCombinationLocalUpdates.isForbidden

  return (
    <div className='ta-globals-settings__list'>
      <FormSection>
        <SectionTitle className='ta-globals-settings__section_title' label={t('settings.global.list.service.localUpdates')} icon='list' />
        <Row>
          <Col>
            {isServiceLocalUpdateForbidden
              ? (
                <>
                  <strong>{t('global.notPermitted')}</strong>
                  <div className='ta-globals-settings__not_permitted_text'>{dangerousHTML(t('globalSettings.form.section.services.localChanges.notPermitted.desc'))}</div>
                </>
                )
              : (
                <strong>{t('global.permitted')}</strong>
                )}
          </Col>
        </Row>
        {!isServiceLocalUpdateForbidden && (
          <>
            <FormText>{t('settings.global.form.section.services.localChanges.allowChanges')}</FormText>
            {!!serviceLocalUpdates.keys.length && (
              <BorderedBox className='ta-globals-attributes_container'>
                <StripedTitle
                  label={
                    <strong>
                      {t('globalSettings.list.attributes.canEdit.label')}
                    </strong>
                  }
                />
                <ul className='ta-globals-settings__attributes_list'>
                  {(order.services.filter(item => serviceLocalUpdates.keys.includes(item)).map(key => (
                    <li key={key}>{t(keysMap[key])}</li>
                  )))}
                </ul>
              </BorderedBox>
            )}
          </>
        )}
      </FormSection>
      <FormSection>
        <SectionTitle className='ta-globals-settings__section_title' label={t('globalSettings.list.service.combinationUpdates')} icon='list' />
        <Row>
          <Col>
            {isCombinationServiceLocalUpdateForbidden
              ? (
                <>
                  <strong>{t('global.notPermitted')}</strong>
                  <div className='ta-globals-settings__not_permitted_text'>{dangerousHTML(t('globalSettings.form.section.services.combinationChanges.notPermitted.desc'))}</div>
                </>
                )
              : (
                <strong>{t('global.permitted')}</strong>
                )}
          </Col>
        </Row>
        {!isCombinationServiceLocalUpdateForbidden && (
          <>
            <FormText>{t('globalSettings.form.section.services.combinationChanges.allowChanges')}</FormText>
            {!!serviceCombinationLocalUpdates.keys.length && (
              <BorderedBox className='ta-globals-attributes_container'>
                <StripedTitle
                  label={
                    <strong>
                      {t('globalSettings.list.attributes.canEdit.label')}
                    </strong>
                  }
                />
                <ul className='ta-globals-settings__attributes_list'>
                  {(order.combinations.filter(item => serviceCombinationLocalUpdates.keys.includes(item)).map(key => (
                    <li key={key}>{t(keysMap[key])}</li>
                  )))}
                </ul>
              </BorderedBox>
            )}
          </>
        )}
      </FormSection>
      <FormSection>
        <SectionTitle className='ta-globals-settings__section_title' label={t('settings.global.list.courses.localUpdates')} icon='list' />
        <Row>
          <Col>
            {isCourseLocalUpdateForbidden
              ? (
                <>
                  <strong>{t('global.notPermitted')}</strong>
                  <div className='ta-globals-settings__not_permitted_text'>{dangerousHTML(t('globalSettings.form.section.courses.changes.notPermitted.desc'))}</div>
                </>
                )
              : (
                <strong>{t('global.permitted')}</strong>
                )}
          </Col>
        </Row>
        {!isCourseLocalUpdateForbidden && (
          <>
            <FormText>{t('settings.global.form.section.courses.changes.allow')}</FormText>
            {!!courseLocalUpdates.keys.length && (
              <BorderedBox className='ta-globals-attributes_container'>
                <StripedTitle
                  label={
                    <strong>
                      {t('globalSettings.list.attributes.canEdit.label')}
                    </strong>
                    }
                />
                <ul className='ta-globals-settings__attributes_list'>
                  {(order.courses.filter(item => courseLocalUpdates.keys.includes(item)).map(key => (
                    <li key={key}>{t(keysMap[key])}</li>
                  )))}
                </ul>
              </BorderedBox>
            )}
          </>
        )}
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.global.list.customers.localUpdates')} icon='user-edit' />
        <Row>
          <Col>
            <strong>
              {isCustomersLocalUpdateForbidden ? t('global.notPermitted') : t('global.permitted')}
            </strong>
          </Col>
        </Row>
        {!isCustomersLocalUpdateForbidden ? <FormText>{t('settings.global.form.section.customers.changes.allow')}</FormText> : <FormText>{t('globalSettings.form.section.globalCustomers.changes.notPermitted.desc')}</FormText>}
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.global.list.customerFields.localUpdates')} icon='database' />
        <Row>
          <Col>
            <strong>
              {isCustomerFieldsLocalUpdateForbidden ? t('global.notPermitted') : t('global.permitted')}
            </strong>
          </Col>
        </Row>
        {!isCustomerFieldsLocalUpdateForbidden ? <FormText>{t('globalSettings.form.section.customerFields.changes.allow')}</FormText> : <FormText>{t('globalSettings.form.section.globalDataFields.changes.notPermitted.desc')}</FormText>}
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.global.list.tags.localUpdates')} icon='tags' />
        <Row>
          <Col>
            <strong>
              {isCompanyTagsLocalUpdateForbidden ? t('global.notPermitted') : t('global.permitted')}
            </strong>
          </Col>
        </Row>
        {!isCompanyTagsLocalUpdateForbidden ? <FormText>{t('globalSettings.form.section.tags.changes.allow')}</FormText> : <FormText>{t('globalSettings.form.section.globalTags.changes.notPermitted.desc')}</FormText>}
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.global.list.categories.localUpdates')} icon='folder-tree' />
        <Row>
          <Col>
            <strong>
              {isResourceLocalUpdateForbidden ? t('global.notPermitted') : t('global.permitted')}
            </strong>
          </Col>
        </Row>
        {!isResourceLocalUpdateForbidden ? <FormText>{t('globalSettings.form.section.categories.changes.allow')}</FormText> : <FormText>{t('globalSettings.form.section.globalResourceCategories.changes.notPermitted.desc')}</FormText>}
      </FormSection>
      {/* <FormSection>
        <SectionTitle label={t('settings.global.list.permissionTypes.localUpdates')} icon='cogs' />
        <Row>
          <Col>
            <strong>
              {isPermissionsGroupsLocalUpdateForbidden ? t('global.permitted') : t('global.allowed')}
            </strong>
          </Col>
        </Row>
      </FormSection> */}
    </div>
  )
}

export default GlobalsSettingsList
