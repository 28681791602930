import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  t
} from '../../../Common'
import { PermissionTypeSummary } from '../../../Beauties'
import { PermissionTypePreviewChangedLocallyWrapper, PopupTriggerWrapper } from '../../../Beasts'

import './PermissionTypesPreview.css'

const PermissionTypePreview = props => {
  const {
    detail,
    hash,
    categoriesList,
    globalPermissionsGroupsWrite,
    globalPermissionsGroupsDelete
  } = props
  const { id, name } = detail || {}
  const availableTabs = ['summary', 'changedLocally']
  const activeTab = availableTabs.includes(hash)
    ? hash
    : availableTabs[0]

  return (
    <div className='ta-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-permission-types__title'>
            <Title label={name || t('permissionTypes.detail.heading.default')}>
              {((globalPermissionsGroupsWrite && id !== 'ADMIN') &&
                <>
                  {(globalPermissionsGroupsDelete &&
                    <HoverPopup>
                      <HoverPopupContent position='left' minWidth={390} width={390}>
                        {t('permissionTypes.detail.buttonHeading.delete')}
                      </HoverPopupContent>
                      <PopupTriggerWrapper name='permission-type-delete-help-box' position='bottom' id={{ id }}>
                        <Link
                          className='ta-btn ta-btn-delete ta-btn-icon-only'
                          external
                        >
                          <FontAwesome5 icon='trash-alt' />
                        </Link>
                      </PopupTriggerWrapper>
                    </HoverPopup>
                  )}
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('permissionTypes.buttonHeading.editTooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        to={`/management/permission-types/${id}/edit`}
                        className='ta-btn ta-btn-primary ta-btn-icon-only'
                      >
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <SimpleTabs active={activeTab}>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem name='summary' icon='shield-alt' label={t('permissionTypes.detail.tabs.summary')} />
              {id !== 'ADMIN' && <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />}
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <SimpleTabsBodyItem name='summary'>
                <PermissionTypeSummary
                  detail={detail}
                  categoriesList={categoriesList}
                />
              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='changedLocally'>
                <PermissionTypePreviewChangedLocallyWrapper />
              </SimpleTabsBodyItem>
            </SimpleTabsBody>
          </SimpleTabs>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

PermissionTypePreview.propTypes = {
  detail: PropTypes.object.isRequired
}

export default PermissionTypePreview
