import React from 'react'
import { FontAwesome5, Link } from '../../Common'

import './AccountsSwitch.css'

const AccountsSwitch = props => {
  const { isActive, companyName } = props
  const classNames = ['ta-accounts-switch']
  if (isActive) classNames.push('active')

  return (
    <Link to='/accounts' className={classNames.join(' ')}>
      <div className='ta-accounts-switch__label'>
        <FontAwesome5 icon='arrow-alt-circle-left' type='solid' className='ta-accounts-switch__icon' />
        {companyName}
      </div>
    </Link>
  )
}

export default AccountsSwitch
