import React from 'react'
import {
  SimpleTabsHeaderItem,
  FixedContentHeader,
  SimpleTabsBodyItem,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FontAwesome5,
  FixedContent,
  HoverPopup,
  SimpleTabs,
  Title,
  Link,
  t,
  Row,
  Col
} from '../../../Common'
import {
  UserPermissionTypeSummary,
  UserPermissionTypeUsers
} from '../../../Beauties'
import { PopupTriggerWrapper } from '../../../Beasts'

const UserPermissionsPreview = props => {
  let {
    userPermission,
    hash,
    pendingUsers,
    permissionsGroupsWrite,
    branches,
    isAdmin,
    canLogedInUserReadUsers,
    permissionsGroupsDelete,
    isEditingOwnPG
  } = props
  pendingUsers = !!pendingUsers
  let { id, name, users, isUpdateAllowedByIssuer } = userPermission || {}
  users = users || []
  const availableTabs = ['summary', 'users']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]
  const editButtonClassNames = ['ta-btn', 'ta-btn-primary', 'ta-btn-icon-only']
  if (isEditingOwnPG) editButtonClassNames.push('disabled')
  if (isAdmin) isUpdateAllowedByIssuer = true
  const addManagerButtonClassName = ['ta-btn', 'ta-btn-block', ' ta-btn-primary']
  if (!isUpdateAllowedByIssuer) addManagerButtonClassName.push('ta-btn-disabled-opacity')
  const addButtonHoverPosition = users.length < 5 ? 'bottom' : 'top'

  return (
    <div className='ta-user-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-users__title'>
            <Title label={name || t('userPermissionTypes.detail.heading.default')}>
              {(permissionsGroupsWrite && id !== 'ADMIN' &&
                <>
                  {(permissionsGroupsDelete &&
                    <PopupTriggerWrapper name='user-permission-delete' disable={users.length > 0} id={id} position='bottom'>
                      <HoverPopup>
                        <HoverPopupContent position='bottom-left'>
                          {users.length > 0 ? t('userPermissionTypes.detail.buttonHeading.deleteDisabled') : t('userPermissionTypes.detail.buttonHeading.deleteTooltip')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <div className={`ta-btn ta-btn-delete ta-btn-icon-only ${users.length > 0 && 'disabled'}`}>
                            <FontAwesome5 icon='trash-alt' />
                          </div>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </PopupTriggerWrapper>
                  )}
                  <HoverPopup disabled={!isEditingOwnPG}>
                    <HoverPopupContent position='left' autoSize>
                      {isEditingOwnPG && t('users.form.permissions.cannotEditOwn.tooltip')}
                      {!isEditingOwnPG && t('userPermissionTypes.buttonHeading.editTooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        to={`/managers/user-permissions/${id}/edit`}
                        className={editButtonClassNames.join(' ')}
                        disabled={isEditingOwnPG}
                      >
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <SimpleTabs active={activeTab}>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem name='summary' icon='shield-alt' label={t('global.summary')} />
              <SimpleTabsHeaderItem name='users' icon='user' label={t('global.users')} />
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <SimpleTabsBodyItem name='summary'>
                <UserPermissionTypeSummary
                  canLogedInUserReadUsers={canLogedInUserReadUsers}
                  isLogedInUserAdmin={isAdmin}
                  userPermission={userPermission}
                  branches={branches}
                />
              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='users'>
                <Row>
                  <Col>
                    <UserPermissionTypeUsers pendingUsers={pendingUsers} users={users} />
                  </Col>
                </Row>
                <div>
                  <Row>
                    <Col>
                      <HoverPopup className='ta-row'>
                        {(!isUpdateAllowedByIssuer &&
                          <HoverPopupContent position={addButtonHoverPosition}>
                            {t('permission.type.manager.add.hover')}
                          </HoverPopupContent>
                        )}
                        <HoverPopupTrigger>
                          <Link disabled={!isUpdateAllowedByIssuer} className={addManagerButtonClassName.join(' ')} to={`/managers/users/add?permission-group-id=${id}`}>
                            {t('permission.type.manager.add')}
                          </Link>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </Col>
                  </Row>
                </div>
              </SimpleTabsBodyItem>
            </SimpleTabsBody>
          </SimpleTabs>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default UserPermissionsPreview
