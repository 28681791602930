import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { BranchesPreviewServices } from '../../Beauties'
import { Loader } from '../../Common'

class BranchesPreviewServicesWrapper extends Component {
  componentDidMount () {
    let { id, account } = this.props
    account = account || {}
    let { enterprisePermissions, isAdmin } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { branchesRead } = enterprisePermissions || {}
    branchesRead = isAdmin || !!branchesRead
    if (branchesRead) {
      handlers.branchPreviewServicesGet(id)
      handlers.branchImportResourceMessagesHide()
      handlers.branchImportGroupMessagesHide()
    } else {
      handlers.navigateToPath(`/branches/${id}/preview`)
    }
  }

  render () {
    let {
      id,
      isPending,
      branches,
      categoriesWithServices,
      filter,
      showImportMessage,
      importType,
      account,
      customisations
    } = this.props
    isPending = !!isPending
    showImportMessage = !!showImportMessage
    branches = branches || []
    categoriesWithServices = categoriesWithServices || []
    importType = importType || ''
    account = account || {}
    let { enterprisePermissions, isAdmin } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { branchesWrite, branchesDelete } = enterprisePermissions || {}
    branchesWrite = !!isAdmin || !!branchesWrite
    branchesDelete = !!isAdmin || !!branchesDelete
    const branch = branches.find(item => item.id === id)
    const { areServicesFetched } = branch || {}
    const {
      hideBranchEditServiceBtn,
      hideBranchDeleteServiceBtn
    } = customisations?.settings || {}

    return (
      !isPending
        ? (
          <BranchesPreviewServices
            branch={branch}
            categoriesWithServices={categoriesWithServices}
            filter={filter}
            isPending={!areServicesFetched}
            showImportMessage={showImportMessage}
            importType={importType}
            branchesWrite={branchesWrite}
            branchesDelete={branchesDelete}
            hideBranchEditServiceBtn={hideBranchEditServiceBtn}
            hideBranchDeleteServiceBtn={hideBranchDeleteServiceBtn}
          />
          )
        : <Loader active />
    )
  }
}

const maps = (state, props) => {
  const branches = state.branches.list || []
  const id = (state.router.data && state.router.data.id) || ''
  const branch = branches.find(item => item.id === id) || {}
  const categories = (branch.services && branch.services.serviceCategories) || []
  const services = (branch.services && branch.services.services) || []
  const filter = ((state.forms.branchServicesFilter.name && state.forms.branchServicesFilter.name.value) && state.forms.branchServicesFilter.name.value.toLowerCase()) || ''
  const categoriesWithServices = categories.map(category => ({
    ...category,
    services: services.filter(item => item.categoryId === category.id && item.name.toLowerCase().includes(filter))
  })) || []

  return {
    id,
    isPending: state.branches.pendingPreview,
    branches,
    categoriesWithServices,
    filter,
    showImportMessage: state.branches.showImportServicesMessage,
    importType: state.branches.importServicesType,
    account: state.account,
    customisations: selectors.customisationsSelector(state)
  }
}

export default connect(maps)(BranchesPreviewServicesWrapper)
