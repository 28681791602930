import React from 'react'
import {
  HoverPopupContent,
  HoverPopupTrigger,
  SectionTitle,
  ColorSelect,
  FormSection,
  HoverPopup,
  Textarea,
  Input,
  Row,
  Col,
  t
} from '../../../../Common'

const BranchesServiceFormBasicDetails = props => {
  const { plan, hideColorSelector, ignoreTopOffset, allowedSet } = props || {}
  const isNameDisabled = !allowedSet?.includes('name')
  const isColorDisabled = !allowedSet?.includes('color')
  const isDescriptionDisabled = !allowedSet?.includes('description')

  return (
    <FormSection>
      <SectionTitle label={t('global.basicDetails')} icon='sliders-h' ignoreTopOffset={ignoreTopOffset} />
      <Row>
        <Col>
          <HoverPopup disabled={!isNameDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <ColorSelect name='color' hide={hideColorSelector || isColorDisabled}>
                <Input
                  disabled={isNameDisabled}
                  name='name'
                  label={t('global.name.label')}
                  hintText={t('servicesGroups.form.service.section.details.fieldName.hint')}
                  mandatory
                />
              </ColorSelect>
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      <Row>
        <Col>
          <HoverPopup disabled={plan !== 'CLASSIC' && !isDescriptionDisabled} className='ta-services__form__description-hover-popup'>
            <HoverPopupContent position='top'>
              {isDescriptionDisabled ? t('globalSettings.form.section.attributes.disabled') : t('global.premiumOnly')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Textarea
                useEditor
                disabled={plan === 'CLASSIC' || isDescriptionDisabled}
                name='description'
                label={t('global.description.label')}
                limit={400}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
    </FormSection>
  )
}

export default BranchesServiceFormBasicDetails
