import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAttributesHelpBox = props => (
  <div className='ta-popup__attributes-help-box'>
    <Alert noOffset noBorder label={t('popup.attributes.help.label')} theme='alert'>
      <div>
        {dangerousHTML(t('popup.attributes.help.message'))}
      </div>
    </Alert>
  </div>
)

export default PopupAttributesHelpBox
