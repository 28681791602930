import React from 'react'
import {
  Input,
  Checkbox,
  ColorSelect,
  Row,
  Col,
  Link,
  FontAwesome5,
  Alert,
  FormText,
  BorderedBox,
  Spinner,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'

const BranchesResourceFormDetails = props => {
  const {
    id,
    hasAccount,
    pendingEmailUnlink,
    hasDuplicateEmails,
    isUser,
    isOwner,
    amIOwner,
    branch
  } = props
  const resourceAbbreviationLimit = 4

  return (
    <div className='ta-branches-resources__form__details'>
      <div className='ta-branches-resources__form__details-fields'>
        <Row>
          <Col>
            <ColorSelect name='color'>
              <Input
                name='name'
                label={t('global.name.label')}
                mandatory
                limit={60}
              />
            </ColorSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name='abbreviation'
              limit={resourceAbbreviationLimit}
              label={t('global.abbreviation.label')}
              className='ta-resource-abbreviation__field'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name='email'
              label={t('global.email.label')}
              mandatory
            />
            {hasDuplicateEmails && (
              <Alert theme='alert'>
                {t('branches.form.resource.section.details.inviteUser.noteText')}
              </Alert>
            )}
          </Col>
        </Row>
        {(!hasAccount && !hasDuplicateEmails &&
          <Row>
            <Col>
              <Checkbox name='hasInvitation' label={t('branches.form.resource.invite')} inline />
              <PopupTriggerWrapper name='resources-account-create' position='right'>
                <Link className='btn-small-icon-only' external>
                  <FontAwesome5 icon='question-circle' />
                </Link>
              </PopupTriggerWrapper>
            </Col>
          </Row>
        )}
        {(hasAccount && (!isOwner || amIOwner) &&
          <BorderedBox className={`ta-branches-resources__form__details__unlink-box ${pendingEmailUnlink ? 'pending' : ''}`}>
            {(pendingEmailUnlink &&
              <Spinner />
            )}
            <FormText>
              {t('branches.form.resource.section.details.changeEmail')}
              <PopupTriggerWrapper name='resources-account-unlink' position='right'>
                <Link className='btn-small-icon-only' external>
                  <FontAwesome5 icon='question-circle' />
                </Link>
              </PopupTriggerWrapper>
            </FormText>
            <PopupTriggerWrapper
              disable={isUser && !amIOwner}
              name='resources-account-unlink-confirmation'
              position='right'
              id={{ id, branch }}
            >
              <HoverPopup disabled={!isUser || amIOwner}>
                <HoverPopupContent position='top' autoSize>
                  {t('branches.form.resource.section.details.unlink.warning')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link
                    external
                    className={isUser && !amIOwner
                      ? 'ta-btn ta-btn-secondary ta-btn-disabled'
                      : 'ta-btn ta-btn-secondary'}
                  >
                    {t('branches.form.resource.section.details.unlink')}
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </PopupTriggerWrapper>
          </BorderedBox>
        )}
        <Row>
          <Col>
            <Checkbox name='isBookable' label={t('branches.form.resource.section.details.makeOnlineBookable')} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BranchesResourceFormDetails
