import React from 'react'
import PropTypes from 'prop-types'
import { Link, FontAwesome5 } from '../../Common'
import { MenuItemNotifications } from '../../Beauties'

const MenuItem = props => {
  const {
    active,
    label,
    icon,
    iconType,
    to,
    hasChildrens,
    notifications,
    children,
    onClick
  } = props
  const classNames = []
  if (active) classNames.push('active')
  if (active && !hasChildrens) classNames.push('no-childrens')

  return (
    <li className={classNames.join(' ')}>
      <Link onClick={() => onClick(to)}>
        {(icon &&
          <div className='ta-menu__icon'>
            <FontAwesome5 icon={icon} type={iconType} />
          </div>
        )}
        {(notifications > 0 &&
          <MenuItemNotifications notifications={notifications} active={active} />
        )}
        <span>{label}</span>
      </Link>
      {children}
    </li>
  )
}

MenuItem.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icons: PropTypes.arrayOf(PropTypes.string),
  to: PropTypes.string.isRequired
}

export default MenuItem
