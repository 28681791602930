import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { ServicePreview } from '../../Beauties'

const ServicePreviewWrapper = props => {
  let {
    id,
    services,
    service = {},
    pending,
    hash,
    message,
    customisations,
    settings,
    account,
    branches
  } = props
  id = id || ''
  hash = hash || ''
  services = services || []
  pending = !!pending
  message = message || null
  settings = settings || {}
  account = account || {}
  const selectedService = services.find(item => item.id === id) || {}
  const { internalId } = selectedService || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalServicesRead, globalServicesWrite, globalServicesDelete } = enterprisePermissions || {}
  globalServicesRead = !!isAdmin || !!globalServicesRead
  globalServicesWrite = !!isAdmin || !!globalServicesWrite
  globalServicesDelete = !!isAdmin || !!globalServicesDelete

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.servicePreviewGet({ id })
  }, [id, internalId])

  const { resourceRemindersEmailRecipients } = settings || {}

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServicePreview
          service={service}
          hash={hash}
          branches={branches}
          message={message}
          customisations={customisations}
          companyResourceRemindersEmailRecipients={resourceRemindersEmailRecipients}
          globalServicesRead={globalServicesRead}
          globalServicesWrite={globalServicesWrite}
          globalServicesDelete={globalServicesDelete}
        />
      )}
    </>
  )
}

ServicePreviewWrapper.propTypes = {
  id: PropTypes.string,
  hash: PropTypes.string,
  service: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = (state, props) => ({
  id: state.router.data.id,
  hash: state.router.hash,
  service: (state.services.selected && state.services.list.find(r => r.id === state.services.selected)) || {},
  services: state.services.list,
  pending: state.services.pendingPreview,
  account: state.account,
  message: state.services.messagePreview,
  customisations: state.company.customisations,
  settings: state.company.settings,
  branches: selectors.branchesFieldSelector(state, { field: 'list' })
})

export default connect(maps)(ServicePreviewWrapper)
