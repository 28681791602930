import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import {
  PopupExampleDeleteWrapper,
  PopupUsersDeleteWrapper,
  PopupTagsDeleteWrapper,
  PopupBranchDeleteWrapper,
  PopupBranchResourceDeleteWrapper,
  PopupBranchResourcePasswordResetWrapper,
  PopupBranchServiceDeleteWrapper,
  PopupBranchGroupDeleteWrapper,
  PopupBranchCustomerDeleteWrapper,
  PopupAppUninstallWrapper,
  PopupResourcesAccountUnlinkConfirmationWrapper,
  PopupDashboardStatisticsSettingsWrapper,
  PopupBranchStatisticsSettingsWrapper,
  PopupDashboardSignupsWrapper,
  PopupBranchSignupsWrapper,
  PopupCustomerFieldCategoryDeleteWrapper,
  PopupCustomerFieldDeleteWrapper,
  PopupServiceCategoryDeleteWrapper,
  PopupServiceDeleteWrapper,
  PopupCustomersDeleteWrapper,
  PopupResourceCategoryDeleteWrapper,
  PopupCourseCategoryDeleteWrapper,
  PopupCoursesDeleteWrapper,
  PopupCompanyTagDeleteWrapper,
  PopupEmailChangeCancelWrapper,
  PopupAccountUnlinkWrapper,
  PopupResourceUnlinkWrapper,
  PopupServicesImportOptionsWrapper,
  PopupGroupsImportOptionsWrapper,
  PopupResourcesImportOptionsWrapper,
  PopupUserPermissionDeleteWrapper,
  TwoFADisableWrapper,
  TwoFARefreshCodesWrapper,
  PopupPermissionTypeDeleteWrapper,
  PopupAppsCancelSubscriptionWrapper,
  PopupAppsPaidInstallWrapper,
  PopupAttributeDeleteWrapper,
  PopupAttributeCategoryDeleteWrapper,
  PopupUrlOpenWarningWrapper
} from '../../Beasts'
import {
  Popup,
  PopupTagsHelpBox,
  PopupAccountInvoicesHelpBox,
  PopupAccountDetailsHelpBox,
  PopupResourcesAccountCreate,
  PopupResourcesAccountUnlink,
  PopupCoursesSplitIntervals,
  PopupServiceDurationTooltip,
  PopupServicesDurationBeforeAndAfter,
  PopupServicesPriceOverriden,
  PopupServicesHelpBox,
  PopupServicesCreateTypeSelect,
  PopupCustomersHelpBox,
  PopupResourcesHelpBox,
  PopupCoursesDurationBeforeAndAfter,
  PopupCoursesHelpBox,
  PopupCustomerFieldsHelpBox,
  PopupCustomerFieldsSelectboxValues,
  PopupCustomerFieldsDataStorage,
  PopupCustomerFieldsServices,
  PopupCustomerFieldsCourses,
  PopupSettingsHelpBox,
  PopupSecuritySettingsHelpBox,
  PopupAppsHelpBox,
  PopupUsersHelpBox,
  PopupBranchTagsHelpBox,
  PopupSettingsCustomersHelpBox,
  PopupUserPermissionsHelpBox,
  PopupServicesCombinationSameResource,
  PopupPermissionTypesHelpBox,
  PopupEmailSettingsHelpBox,
  PopupCustomersSettingsHelpBox,
  PopupGlobalsSettingsHelpBox,
  PopupServiceAllocationsHelpBox,
  PopupICSTooltip,
  PopupGoogleConversion,
  PopupFacebookConversion,
  PopupPastBookingTooltip,
  PopupAttributesHelpBox
} from '../../Beauties'

export class PopupWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.closePopup = this.closePopup.bind(this)
  }

  closePopup () {
    const { content } = this.props
    handlers.popupHide(content)

    setTimeout(() => {
      handlers.popupSet()
    }, 500)
  }

  render () {
    const { content, ...rest } = this.props

    return (
      <Popup {...rest} closePopup={this.closePopup}>
        {content === 'attribute-category-delete' && <PopupAttributeCategoryDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'attribute-delete' && <PopupAttributeDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'attributes-help-box' && <PopupAttributesHelpBox {...rest} />}
        {content === 'booking-delete' && <PopupExampleDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'tags-help-box' && <PopupTagsHelpBox />}
        {content === 'account-invoices-help-box' && <PopupAccountInvoicesHelpBox />}
        {content === 'account-details-help-box' && <PopupAccountDetailsHelpBox />}
        {content === 'users-delete' && <PopupUsersDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'tags-delete' && <PopupTagsDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-delete' && <PopupBranchDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-resource-password-reset' && <PopupBranchResourcePasswordResetWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-resource-delete' && <PopupBranchResourceDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-service-delete' && <PopupBranchServiceDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-group-delete' && <PopupBranchGroupDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-customer-delete' && <PopupBranchCustomerDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-services-import' && <PopupServicesImportOptionsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-groups-import' && <PopupGroupsImportOptionsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-resources-import' && <PopupResourcesImportOptionsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'dashboard-statistics-settings' && <PopupDashboardStatisticsSettingsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'dashboard-sign-ups' && <PopupDashboardSignupsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-sign-ups' && <PopupBranchSignupsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'branch-statistics-settings' && <PopupBranchStatisticsSettingsWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'app-uninstall' && <PopupAppUninstallWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'service-duration-before-and-after' && <PopupServicesDurationBeforeAndAfter />}
        {content === 'service-duration-tooltip' && <PopupServiceDurationTooltip />}
        {content === 'google-conversion-tooltip' && <PopupGoogleConversion />}
        {content === 'facebook-conversion-tooltip' && <PopupFacebookConversion />}
        {content === 'resources-account-create' && <PopupResourcesAccountCreate />}
        {content === 'resources-account-unlink' && <PopupResourcesAccountUnlink />}
        {content === 'company-tags-delete' && <PopupCompanyTagDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'resources-account-unlink-confirmation' && <PopupResourcesAccountUnlinkConfirmationWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'customers-delete' && <PopupCustomersDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'customer-field-category-delete' && <PopupCustomerFieldCategoryDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'customer-fields-delete' && <PopupCustomerFieldDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'customer-fields-selectbox-values' && <PopupCustomerFieldsSelectboxValues />}
        {content === 'customer-fields-data-storage' && <PopupCustomerFieldsDataStorage />}
        {content === 'customer-fields-services' && <PopupCustomerFieldsServices />}
        {content === 'customer-fields-courses' && <PopupCustomerFieldsCourses />}
        {content === 'customer-fields-help-box' && <PopupCustomerFieldsHelpBox />}
        {content === 'service-category-delete' && <PopupServiceCategoryDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'services-delete' && <PopupServiceDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'services-help-box' && <PopupServicesHelpBox />}
        {content === 'services-duration-before-and-after' && <PopupServicesDurationBeforeAndAfter />}
        {content === 'services-price-overriden' && <PopupServicesPriceOverriden />}
        {content === 'services-create-type-select' && <PopupServicesCreateTypeSelect {...rest} closePopup={this.closePopup} />}
        {content === 'customers-help-box' && <PopupCustomersHelpBox />}
        {content === 'resources-help-box' && <PopupResourcesHelpBox />}
        {content === 'resource-category-delete' && <PopupResourceCategoryDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'course-category-delete' && <PopupCourseCategoryDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'courses-duration-before-and-after' && <PopupCoursesDurationBeforeAndAfter />}
        {content === 'courses-split-intervals' && <PopupCoursesSplitIntervals />}
        {content === 'courses-delete' && <PopupCoursesDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'booking-settings-help-box' && <PopupSettingsHelpBox />}
        {content === 'customer-settings-help-box' && <PopupSettingsCustomersHelpBox />}
        {content === 'courses-help-box' && <PopupCoursesHelpBox />}
        {content === 'email-change-cancel' && <PopupEmailChangeCancelWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'account-unlink' && <PopupAccountUnlinkWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'resource-unlink' && <PopupResourceUnlinkWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'settings-security-help-box' && <PopupSecuritySettingsHelpBox />}
        {content === 'apps-help-box' && <PopupAppsHelpBox />}
        {content === 'users-help-box' && <PopupUsersHelpBox />}
        {content === 'branch-tags-help-box' && <PopupBranchTagsHelpBox />}
        {content === 'email-settings-help-box' && <PopupEmailSettingsHelpBox />}
        {content === 'customers-settings-help-box' && <PopupCustomersSettingsHelpBox />}
        {content === 'globals-settings-help-box' && <PopupGlobalsSettingsHelpBox />}
        {content === 'services-combination-same-resource' && <PopupServicesCombinationSameResource />}
        {content === 'two-fa-disable' && <TwoFADisableWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'two-fa-refresh-codes' && <TwoFARefreshCodesWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'user-permissions-help-box' && <PopupUserPermissionsHelpBox {...rest} closePopup={this.closePopup} />}
        {content === 'user-permission-delete' && <PopupUserPermissionDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'url-open-warning' && <PopupUrlOpenWarningWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'permission-types-help-box' && <PopupPermissionTypesHelpBox {...rest} closePopup={this.closePopup} />}
        {content === 'permission-type-delete-help-box' && <PopupPermissionTypeDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'services-allocations-options' && <PopupServiceAllocationsHelpBox {...rest} />}
        {content === 'apps-subscription-cancel' && <PopupAppsCancelSubscriptionWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'apps-paid-install' && <PopupAppsPaidInstallWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'settings-ics-tooltip' && <PopupICSTooltip {...rest} />}
        {content === 'settings-past-booking-calendar' && <PopupPastBookingTooltip {...rest} />}
      </Popup>
    )
  }
}

PopupWrapper.propTypes = {
  content: PropTypes.string
}

const maps = state => ({
  id: state.popup.id,
  status: state.popup.status,
  position: state.popup.position,
  content: state.popup.content,
  trigger: state.popup.trigger,
  extraClassName: state.popup.extraClassName,
  extras: state.popup.extras
})

export default connect(maps)(PopupWrapper)
