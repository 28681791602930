import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  IconListItem,
  IconListItemBody,
  IconListItemTitle,
  Link,
  SectionTitle,
  Title,
  t
} from '../../../Common'

import './AttributeCategoryPreview.css'

const AttributeCategoryPreview = ({ category, hasAttributes, isAdmin, hash }) => {
  const {
    id,
    externalId,
    name,
    attributeType
  } = category || {}
  const attributeTypeTranslations = {
    RESOURCE: t('global.resources'),
    SERVICE: t('global.services'),
    COURSE: t('global.groups')
  }
  const attributeTypeValue = attributeTypeTranslations[attributeType]

  return (
    <div className='ta-attributes__preview'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-attributes__preview__title'>
            <Title label={name}>
              {isAdmin && (
                <>
                  <HoverPopup block>
                    <HoverPopupContent autoSize position='left'>
                      {t(hasAttributes
                        ? 'attribute.category.delete.btn.disabled'
                        : 'global.category.delete')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        className={`ta-btn ta-btn-delete ta-btn-icon-only ${hasAttributes ? 'ta-btn-disabled' : ''}`}
                        external
                      >
                        <FontAwesome5 icon='trash' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('buttons.editCategory.tooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        to={`/management/attributes/categories/${id}/edit@@${hash}`}
                        className='ta-btn ta-btn-primary ta-btn-icon-only'
                      >
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <div className='ta-attributes__preview__summary'>
            <SectionTitle label={t('global.details')} icon='file' ignoreTopOffset />
            <IconListItem>
              <IconListItemTitle width='160px'>{t('global.categoryID')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{id}</IconListItemBody>
            </IconListItem>
            {externalId && (
              <IconListItem>
                <IconListItemTitle width='160px'>{t('global.externalId')}:</IconListItemTitle>
                <IconListItemBody offset='160px'>{externalId}</IconListItemBody>
              </IconListItem>
            )}
            <IconListItem>
              <IconListItemTitle width='160px'>{t('attributes.category.form.type.selector.label')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{attributeTypeValue}</IconListItemBody>
            </IconListItem>
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default AttributeCategoryPreview
