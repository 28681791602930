import momenttz from 'moment-timezone'
import { payloads$, actions, handlers, store, selectors, store$, globalActions } from '../../../Store'
import { map, distinctUntilChanged, debounceTime } from 'rxjs/operators'
import { q } from '../../API'
import { DEFAULT_LOCALE } from '../../../Settings'
import { t } from '../../../Common'
import {
  appsListTransform,
  appSubscriptionFormValidate,
  appSubscriptionFormSaveTransform,
  appSubscriptionFormServerErrorsTransform,
  appSubscriptionBillingFormValidate,
  appSubscriptionCancelFormValidate,
  appSubscriptionCancelFormSaveTransform,
  appSubscriptionCancelFormServerErrorsTransform,
  appSubscriptionRuleFormSaveTransform,
  appSubscriptionRuleFormServerErrorsTransform,
  appUninstallFormValidate,
  appUninstallFormSaveTransform,
  appUninstallFormServerErrorsTransform,
  appPaymentRetryServerErrorsTransform
} from './utils'
import { appUninstalled, appUpdated } from './subscriptions'

globalActions.populateApps = async () => {
  const state = store.getState()
  const locale = selectors.appLocaleSelector(state) || DEFAULT_LOCALE
  const companyLocale = selectors.companyLocaleSelector(state) || DEFAULT_LOCALE
  const apps = await q('getEnterpriseApps', { locale }) || { error: { message: 'Not found' } }
  const { error } = apps
  if (error) {
    // TODO: handle errors
    return
  }
  handlers.appsListPopulate({ apps: appsListTransform(apps, companyLocale) })
}

// Subscription
payloads$(actions.APPS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (!id) return
    if (name === 'appInstalled') return // already handled
    if (name === 'appUninstalled') appUninstalled(id)
    if (name === 'appInstallationUpdated' && id) appUpdated({ id, ts })
  })

// List
payloads$(actions.APPS_LIST_GET)
  .subscribe(async () => {
    await globalActions.populateApps()
  })

// Private app access key
let isGenerateAccessKeyLoading = false
payloads$(actions.APPS_PRIVATE_ACCESS_KEY_GET)
  .subscribe(async () => {
    if (isGenerateAccessKeyLoading) return
    isGenerateAccessKeyLoading = true
    const privateAccessKey = await q('generateEnterprisePrivateAppsOneTimeCode') || { error: { text: 'Not found ' } }
    const { error } = privateAccessKey
    if (error) {
      isGenerateAccessKeyLoading = false
      return
    }
    handlers.formFieldsUpdate('privateApp', { privateAccessKey: { value: privateAccessKey } })
    isGenerateAccessKeyLoading = false
  })

payloads$(actions.APPS_PRIVATE_ACCESS_KEY_RESET)
  .subscribe(async () => {
    handlers.formFieldsUpdate('privateApp', { privateAccessKey: { value: '' } })
  })

// Preview
payloads$(actions.APP_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false, oldThreshold }) => {
    const state = store.getState()
    const { list = [] } = state.apps
    const selectedApp = list.find(app => app.id === id) || {}
    const { isComplete } = selectedApp
    handlers.formFieldsUpdate('apps', { hasAcceptTerms: { value: false } })
    if (isComplete && !forceFetch) {
      handlers.appPreviewPopulate(id)
      return
    }
    const locale = store.getState().app.locale || DEFAULT_LOCALE
    const result = (await q('getEnterpriseAppWithToken', { locale, id })) || { error: { text: 'Not found ' } }
    const { error } = result
    if (error) return
    const { getEnterpriseApp: app, getAppAccessToken } = result
    const { refreshToken } = getAppAccessToken || {}
    const companyLocale = selectors.companyLocaleSelector(state) || DEFAULT_LOCALE
    handlers.appUpdate({ ...(app || {}), refreshToken, isComplete: true, companyLocale, oldThreshold })
    handlers.appPreviewPopulate(id)
    handlers.sidebarSizeChange(app.bmSidebarSize)
  })

// Installation
payloads$(actions.APP_INSTALLATION_GET)
  .subscribe(async ({ id }) => {
    const state = store.getState()
    const { router, apps } = state
    let { list: appsList } = apps || {}
    appsList = appsList || []
    const { name: routerName, data } = router || {}
    const { id: appId } = data || {}
    const selectedApp = appsList.find(item => item.id === id) || {}
    const { refreshToken, oldThreshold } = selectedApp || {}
    const locale = selectors.appLocaleSelector(state) || DEFAULT_LOCALE
    const result = (await q('getEnterpriseAppWithToken', { locale, id })) || { error: { text: 'errors.api.unavailable' } }
    const { error } = result
    if (error) return
    let { getEnterpriseApp } = result || {}
    const { installedVersion, appInstallation } = getEnterpriseApp || {}
    const {
      hasPayment,
      subscribedAt,
      trialUnits,
      hasDuePayment,
      cancelledAt,
      isBlocked,
      blockingAt
    } = appInstallation || {}
    if (!getEnterpriseApp || (installedVersion && !refreshToken)) return
    getEnterpriseApp = getEnterpriseApp || {}
    const companyLocale = selectors.companyLocaleSelector(state) || DEFAULT_LOCALE
    const appPayload = {
      ...getEnterpriseApp,
      refreshToken,
      isComplete: true,
      companyLocale,
      oldThreshold
    }
    const chargebee = selectors.companyFieldSelector(state, { field: 'chargebee' })
    const { noAutoCollect } = chargebee || {}
    const now = momenttz().utc().format('YYYY-MM-DD HH:mm')
    const cancelledAtFormatted = cancelledAt && momenttz.tz(cancelledAt, 'UTC').utc().format('YYYY-MM-DD HH:mm')
    const isCancelled = cancelledAt && now >= cancelledAtFormatted
    handlers.appUpdate(appPayload)
    if (id !== appId) return
    if (installedVersion && noAutoCollect && isBlocked && blockingAt) handlers.navigateToRoute('appsSubscriptionFailed', { id })
    else if (installedVersion && hasDuePayment && !noAutoCollect) handlers.navigateToRoute('appsSubscriptionFailed', { id })
    else if (installedVersion && !hasDuePayment && isCancelled) handlers.navigateToRoute('appsSubscriptionGone', { id })
    else if (installedVersion && hasPayment && !subscribedAt && !trialUnits && router !== 'appsSubscriptionAdd') handlers.navigateToRoute('appsSubscriptionAdd', { id })
    else if (routerName === 'appsSubscriptionFailed') handlers.navigateToRoute('appsPreview', { id })
  })

// Install
payloads$(actions.APP_INSTALL)
  .subscribe(async id => {
    const response = await q('installEnterpriseApp', { appId: id })
    if (response) {
      const locale = store.getState().app.locale || DEFAULT_LOCALE
      const result = (await q('getEnterpriseAppWithToken', { locale, id })) || { error: { text: 'Not found ' } }
      const { error } = result
      if (error) return
      const { getEnterpriseApp: app, getAppAccessToken } = result
      const { id: appId, externalId, version, appInstallation } = app || {}
      const {
        hasPayment,
        hasTrial,
        trialEndAt,
        isTrial,
        trialDays,
        hasUsage,
        trialUnits
      } = appInstallation || {}
      const canStartTrial = hasPayment && hasTrial && !isTrial && !trialEndAt && trialDays > 0 && (!hasUsage || trialUnits > 0)
      // console.warn('------------APP_INSTALL', {
      //   canStartTrial,
      //   hasPayment,
      //   hasTrial,
      //   isTrial,
      //   trialEndAt,
      //   trialDays,
      //   hasUsage,
      //   trialUnits
      // })
      const { refreshToken } = getAppAccessToken || {}
      const state = store.getState()
      const companyLocale = selectors.companyLocaleSelector(state) || DEFAULT_LOCALE
      const updatedApp = { ...app, refreshToken, installedVersion: version, companyLocale }
      if (canStartTrial) handlers.appTrialStart({ id: appId, externalId })
      handlers.appPreviewPopulate(id)
      handlers.appUpdate(updatedApp)
    }
  })

// Uninstall
payloads$(actions.APP_UNINSTALL)
  .subscribe(async id => {
    await q('uninstallEnterpriseApp', { appId: id })
    handlers.navigateToPath('/apps')
  })

// Update
payloads$(actions.APP_UPDATE)
  .subscribe(async app => {
    if (!app) return handlers.appPreviewPopulate()
    setTimeout(() => handlers.appUpdated(app), 2000)
  })

// Form Subscription
payloads$(actions.APP_SUBSCRIPTION_FORM_GET)
  .subscribe(() => {
    const state = store.getState()
    const id = selectors.routerDataFieldSelector(state, { field: 'id' })
    const { externalId, appInstallation } = selectors.appsFindByIdSelector(state, { id }) || {}
    const { limitValue, plans, nextLimitValue } = appInstallation || {}
    const { paymentMethods } = state || {}
    let { list: paymentMethodsList } = paymentMethods || {}
    paymentMethodsList = paymentMethodsList || []
    const plan = (plans || [])[0] || {} // only one plan for pay per usage
    handlers.appSubscriptionFormPopulate({
      id,
      externalId,
      limitValue,
      nextLimitValue,
      paymentMethods: paymentMethodsList,
      planName: plan.name
    })
  })

payloads$(actions.APP_SUBSCRIPTION_FORM_SAVE)
  .subscribe(async ({ data, scrollToError }) => {
    const { id, externalId, oldThreshold, threshold, hasThreshold } = data || {}
    const { value: newThresholdValue } = threshold || {}
    const { value: hasThresholdValue } = hasThreshold || {}
    const state = store.getState()
    const name = selectors.routerFieldSelector(state, { field: 'name' })
    const routeData = selectors.routerFieldSelector(state, { field: 'data' })
    const { id: routerAppId } = routeData || {}
    let query = 'subscribeEnterpriseAppToPlan'
    if (name === 'appsSubscription') query = 'updateEnterpriseAppSubscription'
    if (name === 'appsSubscriptionCancelled') {
      const result = await q('cancelUnsubscribeEnterpriseAppFromPlan', {
        appId: id || routerAppId,
        appExternalId: externalId
      }) || {}
      const { error: cancelUnsubscribeError } = result || { error: {} }
      if (cancelUnsubscribeError) return appSubscriptionFormSaveErrors(appSubscriptionFormServerErrorsTransform(cancelUnsubscribeError, scrollToError))
      query = 'updateEnterpriseAppSubscription'
    }

    const errors = appSubscriptionFormValidate(data)
    if (errors.length) return appSubscriptionFormSaveErrors(errors)
    const { error } = await q(query, appSubscriptionFormSaveTransform(data)) || {}
    if (error) return appSubscriptionFormSaveErrors(appSubscriptionFormServerErrorsTransform(error, scrollToError))
    handlers.appSubscriptionFormReady()
    if (name === 'appsSubscription') {
      const appPreviewParams = { id }
      if (hasThresholdValue && oldThreshold !== parseFloat(newThresholdValue)) appPreviewParams.oldThreshold = oldThreshold
      handlers.appPreviewGet(appPreviewParams)
      handlers.appSubscriptionMessageSet(t('apps.subscriptions.updated.success.message'))
      handlers.formDiscardPopupReset('appSubscription')
      setTimeout(() => {
        handlers.appSubscriptionMessageSet(null)
      }, 6000)
      return
    }
    handlers.navigateToRoute('appsSubscription', { id })
  })

const appSubscriptionFormSaveErrors = (errors, scrollToError) => {
  const billingErrors = errors.filter(item => ['vatNo'].includes(item.key))
  const subscriptionErrors = errors.filter(item => !['vatNo'].includes(item.key))
  appSubscriptionBillingFormSaveErrors(billingErrors, scrollToError)
  handlers.formErrorsSet('appSubscription', subscriptionErrors)
  scrollToError && scrollToError(errors)
  handlers.appSubscriptionFormReady()
}

payloads$(actions.APP_SUBSCRIPTION_BILLING_FORM_GET)
  .subscribe(async () => {
    const state = store.getState()
    const { paymentMethods, billing } = state || {}
    let { data: billingData } = billing || {}
    billingData = billingData || {}
    let { list: paymentMethodsList } = paymentMethods || {}
    paymentMethodsList = paymentMethodsList || []
    const form = selectors.formSelector(state, { formName: 'appSubscriptionBilling' }) || {}
    handlers.appSubscriptionBillingFormPopulate({ paymentMethods: paymentMethodsList, ...billingData, form })
  })

payloads$(actions.APP_SUBSCRIPTION_BILLING_FORM_SAVE)
  .subscribe(async ({ data }) => {
    const errors = appSubscriptionBillingFormValidate(data)
    if (errors.length) return appSubscriptionBillingFormSaveErrors(errors)
    const state = store.getState()
    const form = selectors.formSelector(state, { formName: 'appSubscription' }) || {}
    handlers.appSubscriptionFormSave({ ...form, ...(data || {}) })
    handlers.appSubscriptionBillingFormReady()
  })

const appSubscriptionBillingFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('appSubscriptionBilling', errors)
  scrollToError && scrollToError(errors)
  handlers.appSubscriptionBillingFormReady()
}

payloads$(actions.APP_SUBSCRIPTION_CANCEL_FORM_SAVE)
  .subscribe(async ({ data }) => {
    const errors = appSubscriptionCancelFormValidate(data)
    if (errors.length) return appSubscriptionCancelFormSaveErrors(errors)
    const state = store.getState()
    const { id, externalId } = selectors.appsSelectedSelector(state) || {}
    const { error } = await q('unsubscribeEnterpriseAppFromPlan', appSubscriptionCancelFormSaveTransform({ ...data, id, externalId }))
    if (error) return appSubscriptionCancelFormSaveErrors(appSubscriptionCancelFormServerErrorsTransform(error))
    handlers.appSubscriptionCancelFormReady()
    handlers.popupHide('apps-subscription-cancel')
    const name = selectors.routerFieldSelector(state, { field: 'name' })
    if (name !== 'appsUninstall') handlers.navigateToRoute('appsSubscriptionCancelled', { id })
    else handlers.appPreviewGet({ id })
  })

const appSubscriptionCancelFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('popup', errors)
  scrollToError && scrollToError(errors)
  handlers.appSubscriptionCancelFormReady()
}

payloads$(actions.APP_SUBSCRIPTION_RULE_FORM_SAVE)
  .subscribe(async ({ data }) => {
    data = data || {}
    const payload = appSubscriptionRuleFormSaveTransform(data)
    const response = await q('installEnterpriseApp', payload)
    if (response) {
      if (response.error) return appSubscriptionRuleFormSaveErrors(appSubscriptionRuleFormServerErrorsTransform(response.error))
      const locale = store.getState().app.locale || DEFAULT_LOCALE
      const result = (await q('getEnterpriseAppWithToken', { locale, id: data.id })) || { error: { text: 'Not found ' } }
      const { error } = result
      if (error) return
      const { getEnterpriseApp: app, getAppAccessToken } = result
      const { refreshToken } = getAppAccessToken || {}
      const { id: appId, externalId, version, appInstallation } = app || {}
      const {
        hasPayment,
        hasTrial,
        trialEndAt,
        isTrial,
        trialDays,
        hasUsage,
        trialUnits
      } = appInstallation || {}
      const canStartTrial = hasPayment && hasTrial && !isTrial && !trialEndAt && trialDays > 0 && (!hasUsage || trialUnits > 0)
      // console.warn('------------APP_SUBSCRIPTION_RULE_FORM_SAVE', {
      //   canStartTrial,
      //   hasPayment,
      //   hasTrial,
      //   isTrial,
      //   trialEndAt,
      //   trialDays,
      //   hasUsage,
      //   trialUnits
      // })
      const state = store.getState()
      const companyLocale = selectors.companyLocaleSelector(state) || DEFAULT_LOCALE
      const updatedApp = { ...app, refreshToken, installedVersion: version, companyLocale }
      if (canStartTrial) handlers.appTrialStart({ id: appId, externalId })
      handlers.popupHide('apps-paid-install')
      handlers.appSubscriptionRuleFormReady()
      handlers.appPreviewPopulate(data.id)
      handlers.appUpdate(updatedApp)
    }
  })

const appSubscriptionRuleFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('popup', errors)
  scrollToError && scrollToError(errors)
  handlers.appSubscriptionRuleFormReady()
}

payloads$(actions.APP_SUBSCRIPTION_RESUBSCRIBE_FORM_SAVE)
  .subscribe(async () => {
    const state = store.getState()
    const { id, externalId } = selectors.appsSelectedSelector(state) || {}
    const routeData = selectors.routerFieldSelector(state, { field: 'data' })
    const { id: routerAppId } = routeData || {}
    const result = await q('cancelUnsubscribeEnterpriseAppFromPlan', {
      appId: id || routerAppId,
      appExternalId: externalId
    }) || {}
    const { error } = result || { error: {} }
    if (error) return appSubscriptionResubscribeFormSaveErrors(appSubscriptionFormServerErrorsTransform(error))
  })

const appSubscriptionResubscribeFormSaveErrors = (errors, scrollToError) => {
  scrollToError && scrollToError(errors)
  handlers.appSubscriptionResubscribeFormReady()
}

// App Trial

payloads$(actions.APP_TRIAL_START)
  .subscribe(async ({ id, externalId }) => {
    const result = await q('startEnterpriseAppTrial', ({ appId: id, appExternalId: externalId }))
    if (!result || result.error) return handlers.navigateToRoute('appsSubscriptionAdd', { id })
    handlers.navigateToRoute('appsIframe', { id })
  })

// App Payment Retry

payloads$(actions.APP_PAYMENT_RETRY)
  .subscribe(async ({ id, externalId }) => {
    const { error } = await q('retryEnterpriseAppPayment', ({ appId: id, appExternalId: externalId })) || {}
    if (error) return appPaymentRetrySaveErrors(appPaymentRetryServerErrorsTransform(error))
    handlers.appPaymentRetryReady()
    handlers.navigateToRoute('appsIframe', { id })
  })

const appPaymentRetrySaveErrors = (errors) => {
  handlers.formErrorsSet('appPaymentRetry', errors)
  handlers.appPaymentRetryReady()
}

// Form Uninstall
payloads$(actions.APP_UNINSTALL_FORM_GET)
  .subscribe(() => {
    const state = store.getState()
    const id = selectors.routerDataFieldSelector(state, { field: 'id' })
    const { externalId } = selectors.appsFindByIdSelector(state, { id }) || {}
    handlers.appUninstallFormPopulate({ id, externalId })
  })

payloads$(actions.APP_UNINSTALL_FORM_SAVE)
  .subscribe(async ({ data, scrollToError }) => {
    const errors = appUninstallFormValidate(data)
    if (errors.length) return appUninstallFormSaveErrors(errors)
    const { error } = await q('uninstallEnterpriseApp', appUninstallFormSaveTransform(data)) || {}
    if (error) return appUninstallFormSaveErrors(appUninstallFormServerErrorsTransform(error, scrollToError))
    handlers.appUninstallFormReady()
  })

const appUninstallFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('appUninstall', errors)
  scrollToError && scrollToError(errors)
  handlers.appUninstallFormReady()
}

// Form resources change
store$
  .pipe(
    map(state => JSON.stringify(state.forms.appSubscriptionBilling)),
    distinctUntilChanged(),
    debounceTime(300)
  ).subscribe((data) => {
    if (!data) return
    const state = store.getState()
    const form = { ...state.forms.appSubscriptionBilling }
    const billingForm = JSON.stringify(
      Object.keys(form).reduce((acc, item) => {
        acc[item] = form[item]?.value
        return acc
      }, {})
    )
    const billingFormLocked = selectors.appsFieldSelector(state, { field: 'billingFormLocked' })
    const hasChanges = billingForm !== billingFormLocked
    handlers.appSubscriptionBillingFormIsUpdated(hasChanges)
  })

payloads$(actions.APP_LOAD)
  .subscribe(async ({ appId, ref, refreshToken: token }) => {
    const { apps } = store.getState()
    let { list } = apps || {}
    list = list || []
    const metadata = list.find((app) => app.id === appId)?.metadata || null
    ref.current.contentWindow.postMessage({ action: 'timify:apps:auth', payload: { token, metadata } }, '*')
    // DEPRECATED
    ref.current.contentWindow.postMessage({ action: 'timify:apps:metadata', payload: metadata }, '*')
  })
