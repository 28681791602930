import React from 'react'
import {
  BranchesPreviewResourcesWrapper,
  BranchesPreviewServicesWrapper,
  BranchesPreviewGroupsWrapper,
  BranchesPreviewStatisticsWrapper,
  // BranchesPreviewCustomersWrapper,
  PopupTriggerWrapper
} from '../../../Beasts'
import { BranchesPreviewDetails } from '../../../Beauties'
import PropTypes from 'prop-types'
import {
  Title,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  Link,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  SimpleTabsBodyItem,
  Blur,
  t
} from '../../../Common'

import './BranchesPreview.css'

const BranchesPreview = props => {
  let {
    branch,
    hash,
    onClickLogin,
    permissions,
    hideBranchLoginBtn,
    branchesRead,
    branchesWrite,
    tagsRead,
    allowFulfilment,
    showBranchLoginWithoutCustomisationsBtn
  } = props
  branch = branch || {}
  hideBranchLoginBtn = !!hideBranchLoginBtn
  showBranchLoginWithoutCustomisationsBtn = !!showBranchLoginWithoutCustomisationsBtn
  const availableTabs = ['details', 'resources', 'services', 'groups', 'statistics']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]

  return (
    <div className='ta-branches-preview'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title label={branch.name || ''}>
              {(permissions.delete &&
                <PopupTriggerWrapper name='branch-delete' position='bottom' id={branch.id}>
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('branches.preview.deleteBranch')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                        <FontAwesome5 icon='trash' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </PopupTriggerWrapper>
              )}
              {(allowFulfilment && showBranchLoginWithoutCustomisationsBtn &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('branches.list.tooltip.loginWithoutCustomisations.message')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link className='ta-btn ta-btn-ghost ta-btn-icon-only' external onClick={(e) => onClickLogin(e, branch, true)}>
                      <FontAwesome5 icon='key' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(allowFulfilment && !hideBranchLoginBtn &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('branches.list.tooltip.loginWithCustomisations.message')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external onClick={(e) => onClickLogin(e, branch)}>
                      <FontAwesome5 icon='key' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(branchesWrite &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('branches.preview.editBranch')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link className='ta-btn ta-btn-primary ta-btn-icon-only' to={`/branches/${branch.id}/edit`}>
                      <FontAwesome5 icon='pencil' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!branchesRead} label={t('global.accessDenied')} icon='ban'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='details' label={t('global.details')} />
                <SimpleTabsHeaderItem name='resources' label={t('global.resources')} />
                <SimpleTabsHeaderItem name='services' label={t('global.services')} />
                <SimpleTabsHeaderItem name='groups' label={t('global.groups')} />
                {/* <SimpleTabsHeaderItem name='customers' label='Customers' /> */}
                <SimpleTabsHeaderItem name='statistics' label={t('global.statistics')} />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='details'>
                  <BranchesPreviewDetails branch={branch} tagsRead={tagsRead} />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='resources' renderOnlyIfActive>
                  <BranchesPreviewResourcesWrapper />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='services' renderOnlyIfActive>
                  <BranchesPreviewServicesWrapper />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='groups' renderOnlyIfActive>
                  <BranchesPreviewGroupsWrapper />
                </SimpleTabsBodyItem>
                {/* <SimpleTabsBodyItem name='customers' renderOnlyIfActive>
                  <BranchesPreviewCustomersWrapper />
                </SimpleTabsBodyItem> */}
                <SimpleTabsBodyItem name='statistics' renderOnlyIfActive>
                  <BranchesPreviewStatisticsWrapper />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>

            </SimpleTabs>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

BranchesPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default BranchesPreview
