import { UPLOAD_FILES_LIMIT, UPLOAD_FILE_FORMATS } from '../../../Settings/settings'
import { translateServerCode, validator } from '../../../Utils'

const NAME_MAX_LENGTH = '50'
const TEXT_AREA_MAX_LENGTH = '400'
const icons = [
  'rocket',
  'globe',
  'calendar',
  'users',
  'running',
  'chart-bar',
  'envelope',
  'folder-open',
  'phone',
  'dumbbell',
  'heart',
  'camera',
  'book',
  'clipboard',
  'music'
]

export const attributeCategoryFormTransform = ({ category, branches, hash }) => {
  const { id, name, icon, attributeType, externalId, allowedBranchIds } = category || {}

  const locationOptions = branches.map(branch => ({
    label: branch.name,
    value: branch.id
  }))

  const availableTabs = ['resource', 'service', 'course']
  const attributeTypeValue = availableTabs.includes(hash) ? hash : availableTabs[0]
  const locationValues = allowedBranchIds?.length ? allowedBranchIds : ['all']

  return {
    id: {
      value: id
    },
    externalId: {
      value: externalId || ''
    },
    name: {
      value: name || ''
    },
    icon: {
      icons,
      value: icon || icons[0]
    },
    attributeType: {
      value: attributeType?.toLowerCase?.() || attributeTypeValue
    },
    location: {
      values: locationValues,
      options: locationOptions
    }
  }
}

export const attributeCategoryFormValidate = (category, externalIds) => {
  if (!category) return

  const rules = [
    { 'name.value': ['required', `max:${NAME_MAX_LENGTH}`] },
    { 'attributeType.value': ['required'] },
    { 'location.values': ['required'] }
  ]

  const messages = {
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    attributeType: { required: 'errors.required' },
    location: { required: 'errors.required' }
  }

  const replaces = {
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: { key: 'MAX', value: NAME_MAX_LENGTH }
    },
    attributeType: {
      required: {
        key: 'FIELD_NAME',
        value: 'attributes.category.form.type.selector.label',
        translateReplace: true
      }
    },
    location: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.branch',
        translateReplace: true
      }
    }
  }
  const errors = validator(category, rules, messages, replaces)

  // external Id
  const externalIdValue = category.externalId && category.externalId.value
  if (!!externalIdValue && externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

export const attributeCategorySaveTransform = category => {
  const { id, externalId, name, icon, attributeType, location } = category || {}

  const result = {
    category: {
      id: id?.value || null,
      externalId: externalId?.value || null,
      name: name?.value,
      icon: icon?.value,
      attributeType: (attributeType?.value || '').toUpperCase()
    }
  }
  if (location?.values && !location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: location.values || []
    }
  }
  return result
}

export const attributeCategoryServerErrorsTransform = error => {
  const errors = []

  if (error?.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'attributeCategoryGlobalError', value: translateServerCode(error.code) })
  }
  return errors
}

export const attributeFormTransform = ({ branches, categoryId: proppedCategoryId, attribute }) => {
  const {
    id,
    externalId,
    name,
    allowedBranchIds,
    categoryId,
    explanationTxt,
    tooltipTxt,
    fieldType,
    fileUploadLimit,
    fileUploadTypes,
    isAvailableInWidget,
    selectOptions
  } = attribute || {}
  const locationValues = allowedBranchIds?.length ? allowedBranchIds : ['all']
  const locationOptions = branches.map(branch => ({
    label: branch.name,
    value: branch.id
  }))
  const hasCustomSelectOptionValues = selectOptions?.length && selectOptions.some(({ label, value }) => label !== value)
  const uploadFileFormats = UPLOAD_FILE_FORMATS.map(({ value }) => value)

  return {
    id: {
      value: id
    },
    externalId: {
      value: externalId || ''
    },
    name: {
      value: name || ''
    },
    category: {
      value: categoryId || proppedCategoryId
    },
    location: {
      values: locationValues,
      options: locationOptions
    },
    type: {
      value: fieldType
    },
    selectOptions: {
      values: selectOptions
    },
    selectOptionsCheckbox: {
      value: hasCustomSelectOptionValues
    },
    isMultipleFile: {
      value: fileUploadLimit > 1
    },
    fileUploadTypes: {
      values: uploadFileFormats.every(item => fileUploadTypes?.includes?.(item))
        ? ['all']
        : fileUploadTypes
    },
    showInWidget: {
      value: isAvailableInWidget
    },
    hasExplanation: {
      value: !!explanationTxt
    },
    explanationText: {
      value: explanationTxt || ''
    },
    hasTooltip: {
      value: !!tooltipTxt
    },
    tooltipText: {
      value: tooltipTxt || ''
    }
  }
}

export const attributeFormValidate = (form, externalIds) => {
  if (!form) return
  const {
    type,
    hasExplanation,
    hasTooltip,
    selectOptionsCheckbox,
    selectOptions
  } = form

  const rules = [
    { 'name.value': ['required', `max:${NAME_MAX_LENGTH}`] },
    { 'category.value': ['required'] },
    { 'type.value': ['required'] },
    { 'location.values': ['required'] }
  ]
  if (type?.value === 'FILES') rules.push({ 'fileUploadTypes.values': ['required'] })
  if (hasExplanation?.value) rules.push({ 'explanationText.value': ['required', `max:${TEXT_AREA_MAX_LENGTH}`] })
  if (hasTooltip?.value) rules.push({ 'tooltipText.value': ['required', `max:${TEXT_AREA_MAX_LENGTH}`] })

  const messages = Object.keys(rules).reduce((acc, curr) => ({
    [curr.split('.')[0]]: { required: 'errors.required', max: 'errors.invalidMaxLength' },
    ...acc
  }), {})

  const replaces = {
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: { key: 'MAX', value: NAME_MAX_LENGTH }
    },
    type: {
      required: {
        key: 'FIELD_NAME',
        value: 'attributes.form.type.selector.label',
        translateReplace: true
      }
    },
    category: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.category',
        translateReplace: true
      }
    },
    location: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.branch',
        translateReplace: true
      }
    },
    fileUploadTypes: {
      required: {
        key: 'FIELD_NAME',
        value: 'attributes.form.upload.settings.select.label',
        translateReplace: true
      }
    },
    explanationText: {
      required: {
        key: 'FIELD_NAME',
        value: 'attributes.form.explanationText.label',
        translateReplace: true
      },
      max: { key: 'MAX', value: TEXT_AREA_MAX_LENGTH }
    },
    tooltipText: {
      required: {
        key: 'FIELD_NAME',
        value: 'attributes.form.tooltipText.label',
        translateReplace: true
      },
      max: { key: 'MAX', value: TEXT_AREA_MAX_LENGTH }
    }
  }

  const errors = validator(form, rules, messages, replaces)

  // Select options validation
  if (['SELECT', 'SELECT_MULTI'].includes(type?.value)) {
    const isCustomValuesEnabled = selectOptionsCheckbox?.value
    selectOptions.values = selectOptions.values || []
    const selectOptionsValues = selectOptions.values.map(item => (item.value || '').trim())
    const selectOptionsLabels = selectOptions.values.map(item => (item.label || '').trim())
    const uniqueValuesLength = [...new Set(selectOptionsValues)].length
    const uniqueLabelsLength = [...new Set(selectOptionsLabels)].length

    if (!selectOptions.values?.length) {
      errors.push({
        key: 'selectOptions',
        value: 'errors.required',
        replace: [{ key: 'FIELD_NAME', value: 'global.value', translateReplace: true }],
        index: 0
      })
    }

    selectOptions.values.forEach((item, index) => {
      const label = (item.label || '').trim()
      const value = (item.value || '').trim()
      const isEmpty = !label || (isCustomValuesEnabled && !value)
      // Empty
      if (isEmpty) {
        errors.push({
          key: 'selectOptions',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'global.value', translateReplace: true }],
          index
        })
      }
      // Duplicate values
      if (isCustomValuesEnabled && !isEmpty && selectOptionsValues.length !== uniqueValuesLength) {
        const matchWith = selectOptionsValues.filter(option => option === value)
        if (matchWith.length > 1) {
          errors.push({
            key: 'selectOptions',
            value: 'errors.unique',
            replace: [{ key: 'FIELD_NAME', value: 'global.value', translateReplace: true }],
            index
          })
        }
      }
      // Duplicate labels
      if (isCustomValuesEnabled && !isEmpty && selectOptionsLabels.length !== uniqueLabelsLength) {
        const matchWith = selectOptionsLabels.filter(option => option === label)
        if (matchWith.length > 1) {
          errors.push({
            key: 'selectOptions',
            value: 'errors.unique',
            replace: [{ key: 'FIELD_NAME', value: 'global.label', translateReplace: true }],
            index
          })
        }
      }
    })
  }

  // external Id
  const externalIdValue = form.externalId && form.externalId.value
  if (!!externalIdValue && externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

export const attributeSaveTransform = (form, attributeCategory) => {
  const { id: categoryId, attributeType } = attributeCategory || {}
  const {
    id,
    name,
    type,
    location,
    externalId,
    hasTooltip,
    tooltipText,
    showInWidget,
    selectOptions,
    hasExplanation,
    isMultipleFile,
    fileUploadTypes,
    explanationText,
    selectOptionsCheckbox
  } = form || {}
  const uploadFileFormats = UPLOAD_FILE_FORMATS.map(({ value }) => value)
  const hasCustomValue = !!selectOptionsCheckbox?.value

  const result = {
    attribute: {
      id: id?.value,
      name: name?.value,
      externalId: externalId?.value,
      categoryId,
      attributeType,
      fieldType: type?.value,
      selectOptions: selectOptions?.values?.map?.(({ label, value }) => ({ label, value: hasCustomValue ? value : label })),
      fileUploadLimit: isMultipleFile && (isMultipleFile?.value ? UPLOAD_FILES_LIMIT : 1),
      fileUploadTypes: fileUploadTypes?.values?.includes?.('all') ? uploadFileFormats : fileUploadTypes?.values,
      isAvailableInWidget: showInWidget?.value,
      explanationTxt: hasExplanation?.value ? explanationText?.value : null,
      tooltipTxt: hasTooltip?.value ? tooltipText?.value : null
    }
  }

  if (location?.values && !location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: location.values || []
    }
  }

  return result
}

export const attributeServerErrorsTransform = (error, branchesList) => {
  const errors = []

  if (error?.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code === 'GlobalAttributeSaveError') {
    let { localErrors } = error?.data || {}
    localErrors = localErrors || []
    const branchesWithErrors = localErrors.map(item => {
      const { name } = branchesList.find(branch => branch.id === item.companyId) || {}
      return name
    }) || []

    errors.push({
      key: 'attributeGlobalError',
      value: 'errors.servicesGroups.missingLocally',
      replace: [{
        key: 'TYPE', value: 'global.attribute'
      }, {
        key: 'BRANCHES', value: branchesWithErrors.join(' <br/>')
      }]
    })
  } else {
    errors.push({ key: 'attributeGlobalError', value: translateServerCode(error.code) })
  }

  return errors
}

export const attributeDeleteFormValidate = (form, selectedAttribute) => {
  const { name } = form || {}
  const errors = []

  if (!name || (name.value.toLowerCase().trim() !== selectedAttribute.name.toLowerCase().trim())) {
    errors.push({ key: 'name', value: 'errors.delete.namesNotMatching' })
  }

  return errors
}
