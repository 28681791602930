import React from 'react'
import PropTypes from 'prop-types'
import {
  BlueTemplate,
  FontAwesome5,
  FormButtons,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Link,
  Loader,
  t
} from '../../Common'
import { TwoFAFormWrapper, TwoFACodeFormWrapper } from '../../Beasts'
import { dangerousHTML } from '../../Utils'

import './TwoFAForm.css'

const TwoFALoginForm = ({
  companyName,
  onAccountSelect,
  isSetAccount2FA,
  recoveryCodes,
  loginToAccount,
  onBack,
  pending
}) => {
  const classnames = ['ta-2fa-login']
  if (!isSetAccount2FA && !recoveryCodes) {
    classnames.push('ta-2fa-login__wrapper')
  }

  return (
    <div className='ta-2fa-template-wrapper'>
      <BlueTemplate logoUrl='/'>
        <div className={classnames.join(' ')}>
          {(!isSetAccount2FA &&
            <>
              <div className='ta-2fa-login-header'>
                {t('twoFA.login.activationRequired')}
                {((!recoveryCodes && !!isSetAccount2FA) &&
                  <HoverPopup className='ta-2fa-select__box__logout'>
                    <HoverPopupContent position='top' autoSize>
                      {t('navigation.logout.tooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link to='/logout'>
                        <FontAwesome5 icon='arrow-left' type='solid' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                )}
                {((!recoveryCodes && !isSetAccount2FA) &&
                  <div className='ta-2fa-select__box__logout' onClick={onBack}>
                    <FontAwesome5 icon='arrow-left' type='solid' />
                  </div>
                )}
              </div>
              <div className='ta-2fa-login-content'>
                {(!recoveryCodes &&
                  <>
                    {(companyName &&
                      <div className='ta-2fa-company-message'>
                        {dangerousHTML(t('twoFA.form.required', [{ key: 'COMPANY_NAME', value: companyName }]))}
                      </div>)}
                    <TwoFAFormWrapper onActivate={() => { }} />
                  </>
                )}
                {(recoveryCodes && recoveryCodes.length &&
                  <>
                    {pending && <Loader active />}
                    <div className='ta-2fa-recovery-codes'>
                      <div className='ta-2fa-recovery-codes__title'>
                        <FontAwesome5 className='ta-2fa-recovery-codes__success-icon' icon='check-square' type='solid' />{t('global.success')}
                      </div>
                      <div className='ta-2fa-recovery-codes__description'>{dangerousHTML(t('twoFA.recoveryCodes.description'))}</div>
                      <div className='ta-2fa-recovery-codes__list'>
                        {recoveryCodes.map(code =>
                          <div className='ta-2fa-recovery-codes__list__item' key={code}>
                            <div className='ta-2fa-recovery-codes__list__item__circle' />{code}
                          </div>
                        )}
                      </div>
                    </div>
                    <FormButtons>
                      <button onClick={loginToAccount} className='ta-2fa-btn-full-width ta-btn ta-btn-primary'>{t('global.verify')}</button>
                    </FormButtons>
                  </>
                )}
              </div>
            </>
          )}
          {(!!isSetAccount2FA &&
            <TwoFACodeFormWrapper onAccountSelect={onAccountSelect} />
          )}
        </div>
      </BlueTemplate>
    </div>
  )
}

TwoFALoginForm.propTypes = {
  isSetAccount2FA: PropTypes.bool,
  onAccountSelect: PropTypes.func,
  onBack: PropTypes.func
}

export default TwoFALoginForm
