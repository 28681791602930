import React from 'react'

import {
  Row,
  Col,
  Form,
  Price,
  StripedTitle,
  t
} from '../../../Common'
import { AppsSubscriptionRow, AppsSubscriptionInvoiceRow } from '../../../Beauties'
import { capitalize } from '../../../Utils'

import './AppsSubscription.css'

const AppsSubscriptionPlansForm = (props) => {
  const {
    plans,
    activePlan,
    onPlanClick,
    previousPlan,
    newOverviewText,
    vat,
    onSubmit,
    currencyCode
  } = props

  const price = (plans.find(item => item.name === activePlan) || {}).price

  return (
    <Form
      className='ta-apps__subscription-plans-form'
      name='appSubscription'
      onSubmit={onSubmit}
    >
      {plans.map(plan => {
        return (
          <AppsSubscriptionRow
            key={plan.name}
            name={plan.name}
            disabled={previousPlan === plan.name}
            active={activePlan === plan.name}
            title={capitalize(plan.name)}
            text={plan.text}
            boxValue={<Price currency={currencyCode} price={plan.price} />}
            boxLabel={t('global.perMonth')}
            bottomOffset
            onClick={onPlanClick}
          />
        )
      })}
      <StripedTitle topOffset label={t('global.overview')} />
      <AppsSubscriptionInvoiceRow
        label={t('apps.preview.section.pricing.monthlySubscription')}
        description={newOverviewText}
        price={<Price currency={currencyCode} price={price} />}
        withDivider
      />
      {!!vat && (
        <>
          <AppsSubscriptionInvoiceRow
            label={t('global.total.net')}
            price={<Price currency={currencyCode} price={price} />}
          />
          <AppsSubscriptionInvoiceRow
            label={t('global.vat')}
            price={<Price currency={currencyCode} price={price * vat} />}
            withDivider
          />
        </>
      )}
      <AppsSubscriptionInvoiceRow
        label={t('global.total')}
        price={<Price currency={currencyCode} price={price + (price * vat)} />}
        big
      />
      <Row>
        <Col>
          <button type='submit' className='ta-btn ta-btn-block ta-btn-primary'>
            {t('apps.subscriptions.form.confrimAndPay.button.text')}
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <button className='ta-btn ta-btn-block ta-btn-black-ghost'>
            {t('buttons.upgradeLater')}
          </button>
        </Col>
      </Row>
    </Form>
  )
}

export default AppsSubscriptionPlansForm
