import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import { BOOKING_SETTINGS_DEFAULTS } from '../../../Settings'
import {
  Row,
  Col,
  BorderedBox,
  FormGroup,
  Select,
  FontAwesome5,
  Error,
  t
} from '../../../Common'
import './EmailSettings.css'

const EmailSettingsRemindersSelect = props => {
  const [focusedIndex, setFocusedIndex] = useState(null)
  let { reminders, errors, name, noOffset } = props
  reminders = reminders || []
  const remindersOptions = BOOKING_SETTINGS_DEFAULTS.bookingReminder.map(item => ({
    value: item * 60,
    label: item < 24
      ? t('servicesGroups.reminder.form.select.hours.label', [{ key: 'HOURS', value: item }])
      : t('servicesGroups.reminder.form.select.days.label', [{ key: 'DAYS', value: item / 24 }])
  }))
  const deleteButtonClassNames = ['ta-settings__btn-delete']
  const addButtonClassNames = ['ta-settings__btn-add']

  const onFocus = (index) => {
    setFocusedIndex(index)
  }

  const onBlur = () => {
    setFocusedIndex(null)
  }

  const onChange = (value, index) => {
    if (!reminders[index] && typeof reminders[index] !== 'number') return
    reminders[index] = value || null
    handlers.formFieldsUpdate('settings', {
      [name]: {
        values: [...reminders],
        errors
      }
    })
  }

  const onDelete = index => {
    handlers.formFieldsUpdate('settings', {
      [name]: {
        values: [...reminders.filter((item, key) => key !== index)],
        errors
      }
    })
  }

  const onAdd = () => {
    handlers.formFieldsUpdate('settings', {
      [name]: {
        values: [...reminders, 0],
        errors
      }
    })
  }

  return (
    <Row>
      <Col>
        <BorderedBox noOffset={noOffset}>
          {reminders.map((item, index) => {
            const reminderClassNames = ['ta-settings-notifications__reminder']
            if (reminders.length > 1) reminderClassNames.push('has-delete')

            return (
              <div key={index} className='ta-settings-notifications__reminders-container'>
                <Row>
                  <Col>
                    <FormGroup
                      className={reminderClassNames.join(' ')}
                      focused={focusedIndex === index}
                      filled={!!item}
                      labelText={t('settings.section.reminder.form.select.label')}
                      labelMandatory
                    >
                      <Select
                        className='ta-single-select'
                        noResultsText={t('global.noResults')}
                        value={item}
                        onFocus={() => onFocus(index)}
                        onBlur={onBlur}
                        onChange={(selectedValue) => onChange(selectedValue.value, index)}
                        options={remindersOptions.filter(option => !reminders.includes(option.value) || option.value === item)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {(reminders.length > 1 &&
                  <div className={deleteButtonClassNames.join(' ')} onClick={() => onDelete(index)}>
                    <FontAwesome5 icon='trash' type='regular' />
                  </div>
                )}
              </div>
            )
          })}
          {(reminders.length < 3 &&
            <div className={addButtonClassNames.join(' ')} onClick={onAdd}>
              <FontAwesome5 icon='plus' type='regular' /> {t('settings.section.reminder.form.button.addReminder')}
            </div>
          )}
          <Error noOffset name={name} />
        </BorderedBox>
      </Col>
    </Row>
  )
}

EmailSettingsRemindersSelect.propTypes = {
  name: PropTypes.string,
  reminders: PropTypes.array
}

export default EmailSettingsRemindersSelect
