import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './Checkbox.css'

class Checkbox extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onClick = this.onClick.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onClick (e) {
    let { disabled, onChange, onChangeAddon, name, form, readonly } = this.props
    form = form || { value: '' }
    if (readonly) return null
    if (disabled !== undefined ? disabled : form.disabled) return null
    const value = !this.ref.checked
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    const {
      label,
      children,
      name,
      value,
      disabled,
      hideError,
      inline,
      theme,
      form = {},
      className,
      wrapperClassName
    } = this.props
    const classNames = ['ta-checkbox']
    if (value || form.value) classNames.push('active')
    if (focused) classNames.push('focused')
    if (theme === 'switch') classNames.push('switch')
    if (theme === 'box') classNames.push('box')
    if (disabled !== undefined ? disabled : form.disabled) classNames.push('disabled')
    const wrapperClassNames = ['ta-checkbox-wrapper']
    if (inline) wrapperClassNames.push('inline')
    if (className) classNames.push(className)
    if (wrapperClassName) wrapperClassNames.push(wrapperClassName)

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={wrapperClassNames.join(' ')}>
        <FormGroup disabled={disabled !== undefined ? disabled : form.disabled}>
          <input
            className='ta-checkbox-field'
            ref={ref => { this.ref = ref }}
            type='checkbox'
            name={name}
            checked={value || form.value || false}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={() => { }}
            disabled={disabled !== undefined ? disabled : form.disabled}
            autoComplete='off'
          />
          <div className={classNames.join(' ')} onClick={this.onClick}>
            {children || label || form.label}
          </div>
          {!hideError &&
            <Error noOffset name={name} />}
        </FormGroup>
      </div>
    )
  }
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  readonly: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: (state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(Checkbox), FormContext)
