import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Customers } from '../../Beauties'
import { t, isRouteDisabledByCustomisations } from '../../Common'
import formsInitialState from '../../Store/Config/formsInitialState'

const customersMock = [{
  email: 'bm@customer.com',
  fullName: 'BM Custmer',
  id: '12345',
  internalId: '1234',
  isGloballyDeleted: null,
  isUpdatedLocally: null,
  phone: { prefix: '44', country: 'GB', number: '1434634996', phone: '+441434634996' },
  totalBookings: 3
},
{
  email: 'bm@customer.com',
  fullName: 'BM Custmer 2',
  id: '12345',
  internalId: '1234',
  isGloballyDeleted: null,
  isUpdatedLocally: null,
  phone: { prefix: '44', country: 'GB', number: '1434634996', phone: '+441434634996' },
  totalBookings: 143
},
{
  email: 'bm@customer.com',
  fullName: 'BM Custmer 3',
  id: '12345',
  internalId: '1234',
  isGloballyDeleted: null,
  isUpdatedLocally: null,
  phone: { prefix: '44', country: 'GB', number: '1434634996', phone: '+441434634996' },
  totalBookings: 16
}]

const CustomersListWrapper = props => {
  let {
    customers,
    total,
    pending,
    letter,
    search,
    tags,
    message,
    enterpriseId,
    hash,
    account,
    shouldUseMongoCustomerSearch
  } = props
  customers = customers || []
  tags = tags || []
  account = account || {}
  total = total || 0
  message = message || null
  pending = !!pending
  let { isAdmin, enterprisePermissions, isOwner } = account || {}
  isOwner = !!isOwner
  enterprisePermissions = enterprisePermissions || {}
  let { globalCustomersRead, globalCustomersWrite } = enterprisePermissions || {}
  globalCustomersRead = !!isAdmin || !!globalCustomersRead
  globalCustomersWrite = !!isAdmin || !!globalCustomersWrite

  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/customers/customer-fields')
      return
    }

    handlers.customersListGet()
    handlers.customersToDeleteGet()

    return () => handlers.formSet('customersFilters', formsInitialState.customersFilters)
  }, [])

  const label = letter
    ? `${t('customers.list.customersWith')} ${letter}`
    : t('customers.list.all')

  return (
    <Customers
      customers={globalCustomersRead ? customers : customersMock}
      label={label}
      total={total}
      search={search}
      isOwner={isOwner}
      customersRead={globalCustomersRead}
      customersWrite={globalCustomersWrite}
      tags={tags}
      hash={hash}
      message={message}
      shouldUseMongoCustomerSearch={shouldUseMongoCustomerSearch}
      enterpriseId={enterpriseId}
      pending={pending}
    />
  )
}

CustomersListWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  hash: state.router.hash,
  letter: (state.forms.customersFilters && state.forms.customersFilters.letter && state.forms.customersFilters.letter.value) || '',
  search: (state.forms.customersFilters && state.forms.customersFilters.search && state.forms.customersFilters.search.value) || '',
  customers: state.customers.list,
  total: state.customers.total,
  pending: state.customers.pendingList,
  tags: state.companyTags.list,
  message: state.customers.messageList,
  enterpriseId: (state.account.enterprise && state.account.enterprise.id) || null,
  account: state.account,
  shouldUseMongoCustomerSearch: (state.account && state.account.enterprise && state.account.enterprise.settings && state.account.enterprise.settings.shouldUseMongoCustomerSearch) || false
})

export default connect(maps)(CustomersListWrapper)
