import React from 'react'

import { AppsSkeleton, AppsSubscriptionInvoiceRow } from '../../../Beauties'
import {
  StripedTitle,
  FormSection,
  t,
  Price,
  Alert
} from '../../../Common'

import './AppsSubscription.css'

const AppsSubscriptionGone = (props) => {
  let {
    name,
    version,
    plan,
    pricingType,
    isOwner,
    isAdmin,
    onClickUpgrade,
    onUninstall,
    priceRange,
    unitDescription,
    usageUnitPrice,
    isVariablePrice,
    price,
    currencyCode
  } = props
  price = price || ''
  const headerText = t('apps.subscriptionGone.header')
  const overviewText = isOwner
    ? t('apps.subscriptionGone.overview.ownerText')
    : t('apps.subscriptionGone.overview.text')
  const footerClassNames = ['ta-btn', 'ta-btn-black-ghost']
  let [minPrice, maxPrice] = priceRange || []
  minPrice = minPrice || ''
  maxPrice = maxPrice || ''
  let invoiceRowLabel = ''
  if (pricingType === 'payPerUsage' && isVariablePrice) invoiceRowLabel = t('apps.subscriptions.variablePrice')
  if (pricingType === 'payPerUsage' && !isVariablePrice) invoiceRowLabel = t('apps.subscriptions.entityPrice', [{ key: 'NAME', value: unitDescription }])
  if (pricingType === 'monthly') invoiceRowLabel = t('apps.preview.section.pricing.monthlySubscription')
  let invoicePrice
  if (pricingType === 'monthly') {
    invoicePrice = (
      <Price currency={currencyCode} price={price} />
    )
  }
  if (pricingType === 'payPerUsage' && !isVariablePrice) {
    invoicePrice = (
      <span>
        <Price currency={currencyCode} price={usageUnitPrice} /> / {unitDescription}
      </span>
    )
  }
  if (pricingType === 'payPerUsage' && isVariablePrice) {
    invoicePrice = (
      <span>
        <Price currency={currencyCode} price={minPrice} /> - <Price currency={currencyCode} price={maxPrice} /> / {unitDescription}
      </span>
    )
  }

  return (
    <AppsSkeleton
      name={name}
      version={version}
      plan={plan}
      className='ta-apps__subscription-gone'
      headerText={headerText}
      headerImage='/images/paidApps/banner-cancelled-gone.png'
    >
      <Alert theme='info'>
        {t('global.vatApplicability')}
      </Alert>
      <StripedTitle label={t('apps.subscriptions.subscriptionType.title')} />
      {pricingType === 'payPerUsage' && t('apps.preview.section.pricing.payPerUsage')}
      {pricingType === 'monthly' && t('apps.preview.section.pricing.monthlySubscription')}
      <StripedTitle topOffset label={t('global.overview')} />
      <AppsSubscriptionInvoiceRow
        label={invoiceRowLabel}
        price={invoicePrice}
        description={overviewText}
      />
      {(isOwner &&
        <FormSection>
          <button onClick={onClickUpgrade} className='ta-btn ta-btn-block ta-btn-primary'>
            {t('buttons.upgradeNow')}
          </button>
        </FormSection>
      )}
      {(isAdmin &&
        <>
          <div className='ta-separator' />
          <div className={footerClassNames.join(' ')} onClick={onUninstall}>
            {t('buttons.uninstall.app')}
          </div>
        </>
      )}
    </AppsSkeleton>
  )
}

export default AppsSubscriptionGone
