import { useEffect } from 'react'
import { AttributeCategoryForm } from '../../Beauties'
import { Loader, t } from '../../Common'
import { connect, handlers, selectors } from '../../Store'

const AttributeCategoryAddWrapper = ({
  hash,
  isAdmin,
  pendingForm,
  pendingList,
  showExternalIds,
  enterprisePermissions
}) => {
  const { branchesAccessBlacklist } = enterprisePermissions || {}
  const isPending = pendingForm || pendingList

  const onSubmit = cb => {
    handlers.attributesCategoryFormSubmit((errors) => cb?.(errors))
  }

  useEffect(() => {
    handlers.attributesCategoryFormGet()
  }, [])

  return (
    <>
      <Loader active={isPending} />
      {!isPending && (
        <AttributeCategoryForm
          isAdmin={isAdmin}
          onSubmit={onSubmit}
          cancelLink={`/management/attributes@@${hash}`}
          showExternalIds={showExternalIds}
          title={t('attributes.category.form.add.title')}
          branchesAccessBlacklist={branchesAccessBlacklist}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  showExternalIds: selectors.companySettingsFieldSelector(state, { field: 'showExternalIds' }),
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
  enterprisePermissions: selectors.accountFieldSelector(state, { field: 'enterprisePermissions' }),
  pendingForm: selectors.attributesFieldSelector(state, { field: 'pendingCategoryForm' }),
  pendingList: selectors.attributesFieldSelector(state, { field: 'pendingList' })
})

export default connect(maps)(AttributeCategoryAddWrapper)
