import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Input,
  Textarea,
  SectionTitle,
  SingleSelect,
  MultipleSelect,
  PhoneInput,
  Error,
  t,
  BorderedBox
} from '../../../Common'
import { SUBSCRIPTION_PLANS } from '../../../Settings'
import { sortAlphabetically, sortBy } from '../../../Utils'

import './BranchesForm.css'

const BranchesForm = props => {
  const {
    title,
    onSubmit,
    cancelLink,
    tags,
    type,
    locales,
    timezones,
    locale,
    enterpriseRegion
  } = props
  const tagsOptions = tags.map((tag, index) => ({ label: tag.name, value: tag.id, color: tag.color }))
  const subscriptionPlansOptions = SUBSCRIPTION_PLANS.map(item => ({ value: item.value, label: item.name }))
  const localeOptions = locales.map(item => ({ label: `${item.code} / ${item.country && item.country.label}`, value: item.code }))
  const sortedLocalesOptions = localeOptions.sort(sortBy('label'))
  const selectedLocale = locales.find(item => item.code === locale) || {}
  let { country } = selectedLocale
  country = country || {}
  let { timezones: localeTimeZones } = country
  localeTimeZones = localeTimeZones || []
  const timezoneOptions = selectedLocale
    ? timezones
      .filter(item => localeTimeZones.includes(item.code))
      .map(item => ({ label: item.label, value: item.code }))
    : []
  return (
    <div className='ta-branches__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branches'>
            <FormSection>
              <SectionTitle label={t('global.externalId.label')} icon='fingerprint' />
              <Row>
                <Col>
                  <Input
                    name='externalId'
                    label={t('global.externalId.label')}
                    hintText={t('global.externalId.hint')}
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              {(type === 'add' &&
                <>
                  <SectionTitle label={t('branches.form.branchDetails.title')} icon='building' />
                  <Row>
                    <Col>
                      <Input name='name' label={t('global.name.label')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input name='personName' label={t('global.personName.label')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input name='email' label={t('global.email.label')} mandatory />
                    </Col>
                  </Row>
                </>
              )}
              {(type === 'add' &&
                <>
                  <Row>
                    <Col>
                      <SingleSelect
                        name='locale'
                        label={t('global.locale.label')}
                        options={sortedLocalesOptions}
                        mandatory
                        searchable
                      />
                    </Col>
                  </Row>
                  {(locale &&
                    <BorderedBox>
                      <Row>
                        <Col>
                          <Input
                            name='country'
                            label={t('global.country.label')}
                            value={!!selectedLocale && country.label}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SingleSelect
                            name='timezone'
                            label={t('global.timezone.label')}
                            options={timezoneOptions}
                            mandatory
                          />
                        </Col>
                      </Row>
                      {(enterpriseRegion &&
                        <Row>
                          <Col>
                            <Input
                              name='customRegion'
                              label={t('global.customRegion.label')}
                              disabled={type === 'edit'}
                              mandatory
                            />
                          </Col>
                        </Row>
                      )}
                    </BorderedBox>
                  )}
                  <Row>
                    <Col>
                      <PhoneInput name='phone' label={t('global.telephone.label')} />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  <SingleSelect
                    name='plan'
                    label={t('global.plan.label')}
                    mandatory
                    options={subscriptionPlansOptions}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Textarea
                    name='info'
                    label={t('global.locationDescription.label')}
                    limit={150}
                  />
                </Col>
              </Row>
              <SectionTitle label={t('global.tags')} icon='tag' />
              <Row>
                <Col>
                  <MultipleSelect
                    name='tags'
                    label={t('global.tags')}
                    options={tagsOptions.sort(sortAlphabetically)}
                  />
                </Col>
              </Row>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

BranchesForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default BranchesForm
