import React from 'react'
import { SimpleListItem, FontAwesome5, Alert, t } from '../../Common'

const CustomerFieldsToDelete = props => {
  let {
    customerFieldsToDeleteData,
    onDeleteGlobalEntity,
    globalDataFieldsWrite
  } = props
  customerFieldsToDeleteData = customerFieldsToDeleteData || []

  return (
    <div className='ta-customer-fields--to-delete'>
      {customerFieldsToDeleteData.length > 0 && (
        <Alert
          label={t('customerFields.pendingDeletion')}
          theme='alert'
        >
          {t('course.list.delete.conflict.message')}
        </Alert>
      )}
      {customerFieldsToDeleteData.map(item => {
        let { branch, customerField } = item
        branch = branch || {}
        customerField = customerField || {}
        return (
          <SimpleListItem className='ta-customer-fields-to-delete--branch' key={customerField.id}>
            <div className='ta-customer-fields-to-delete--branch__data'>
              <p className='ta-customer-fields-to-delete--branch__name'>{branch.name}</p>
              <div className='ta-customer-fields-to-delete--item'>
                <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
              </div>
              <div className='ta-customer-fields-to-delete--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{customerField.id}</p>
              </div>
            </div>
            {(globalDataFieldsWrite &&
              <div className='ta-customer-fields-to-delete--branch__buttons'>
                <button onClick={() => onDeleteGlobalEntity(branch.id, branch.region, customerField.externalId, customerField.id)}>{t('global.delete')}</button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {!customerFieldsToDeleteData.length && <Alert noOffset label={t('customerFields.noFieldsToDelete')} theme='no-content' />}
    </div>
  )
}

export default CustomerFieldsToDelete
