import React from 'react'
import { Loader, Link, t } from '../../Common'
import { SSOForm } from '../../Beauties'

import './SSO.css'

const SSO = props => {
  const {
    pending,
    pendingLanguage,
    onSubmit
  } = props

  return (
    <div className='ta-sso'>
      {(!pendingLanguage &&
        <div className='ta-sso__box'>
          <div className='ta-sso__title'>{t('sso.title')}</div>
          <Loader active={pendingLanguage || pending} />
          <SSOForm onSubmit={onSubmit} />
          <div className='ta-sso__box__login-btn'>
            <Link to='/login'>{t('sso.timifyLogin.btn')}</Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default SSO
