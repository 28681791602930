// Add here initial state for forms if required
export default {
  account: {},
  accountsSearch: {},
  appsFilters: {},
  appSubscription: {},
  appUninstall: {},
  attribute: {},
  attributeCategory: {},
  user: {},
  userPasswordChange: {},
  branches: {},
  branchesFilter: {},
  branchesConfirmSetPassword: {},
  branchResourcesFilter: {},
  branchServicesFilter: {},
  branchGroupsFilter: {},
  branchesResource: {},
  branchesService: {},
  branchesGroup: {},
  branchesCustomer: {},
  branchStatisticsFilter: {},
  branchesImport: {},
  branchServicesImport: {},
  branchServiceCategoriesImport: {},
  branchResourcesImport: {},
  branchResourceCategoriesImport: {},
  branchGroupsImport: {},
  branchGroupCategoriesImport: {},
  customerFields: {},
  customerFieldsDelete: {},
  companyTags: {},
  companyTagsPreview: {},
  courses: {},
  globalsSettings: {},
  serviceChangedLocally: {},
  courseChangedLocally: {},
  services: {},
  servicesList: {},
  servicesFilters: {},
  serviceCategory: {},
  customers: {},
  customersFilters: {
    useExactMatchSearch: { value: 'exact' }
  },
  resourceCategory: {},
  login: {},
  tags: {},
  users: {},
  usersFilters: {},
  branchesFilters: {},
  userGroups: {},
  dashboard: {},
  dashboardFilters: {},
  dashboardMostBookedResourcesType: {},
  dashboardStatisticsSignUpTimes: {},
  branchStatisticsSignUpTimes: {},
  marketingConversions: {},
  branchMarketingConversions: {},
  statisticsSettings: {},
  statisticsMostBookedServicesType: {},
  statisticsSignUpTimes: {},
  apps: {},
  privateApp: {},
  bookingSettings: {},
  settings: {},
  settingsWebApp: {},
  settingsCustomers: {},
  settingsNotifications: {},
  resourceCategoriesFilter: {},
  debugOptions: {},
  userPermissionType: {},
  permissionTypesFilter: {},
  sso: {},
  usersDelete: {},
  branchesDelete: {},
  systemMessagesFilters: {},
  activityLogsFilters: {}
}
