import React from 'react'
import { format, HoverPopup, HoverPopupContent, HoverPopupTrigger, t } from '../../Common'

const AvailabilityTimelinesGridAvailableIntervals = props => {
  let { times1, times2, isDouble, getIntervalPositioning } = props
  times1 = times1 || []

  const renderIntervals = (intervals, type) => (
    intervals.map(item => {
      let { width, left } = getIntervalPositioning(item)
      width = width || ''
      const parsedWidth = parseFloat(width.replace('%', ''))
      const isBig = parsedWidth > 12
      const begin = format(item.begin, 'time', { format: 'HH:mm', isUTC: true })
      const end = format(item.end, 'time', { format: 'HH:mm', isUTC: true })
      const classNames = ['ta-availability-timelines__grid-day-available-interval', type]
      if (!isBig) classNames.push('pointer')

      return (
        <div
          key={`${type}-${item.begin}`}
          className={classNames.join(' ')}
          style={{ width, left }}
        >
          {!isBig && (
            <HoverPopup>
              <HoverPopupContent autoSize>
                {begin} - {end}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className='ta-availability-timelines__grid-day-available-interval__hover-trigger' />
              </HoverPopupTrigger>
            </HoverPopup>
          )}
          {isBig && (
            <>
              <div className='ta-availability-timelines__grid-day-available-interval-time begin'>
                {begin}
              </div>
              <div className='ta-availability-timelines__grid-day-available-interval-time end'>
                {end}
              </div>
            </>
          )}
        </div>
      )
    })
  )

  return (
    <>
      {times1.length === 0 && (
        <div className='ta-availability-timelines__grid-day-unavailable-label working'>
          {t('resources.form.section.availability.noWorkingHours')}
        </div>
      )}
      {renderIntervals(times1, 'working')}
      {isDouble && times2 && times2.length === 0 && (
        <div className='ta-availability-timelines__grid-day-unavailable-label booking'>
          {t('resources.form.section.availability.noBookingHours')}
        </div>
      )}
      {isDouble && times2 && times2.length > 0 && (
        renderIntervals(times2, 'booking')
      )}
    </>
  )
}

export default AvailabilityTimelinesGridAvailableIntervals
