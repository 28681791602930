import { Col, FormText, Row, SectionTitle, t } from '../../../Common'
import { ServiceFormAllowancePlanWrapper } from '../../../Beasts'
import { dangerousHTML } from '../../../Utils'

import './ServiceForm.css'

const ServiceFormAvailability = () => {
  return (
    <>
      <SectionTitle label={t('servicesGroups.section.service.availability')} icon='clock' />
      <Row>
        <Col>
          <FormText noOffset>{dangerousHTML(t('servicesGroups.section.service.availability.description'))}</FormText>
        </Col>
      </Row>
      <ServiceFormAllowancePlanWrapper />
    </>
  )
}

export default ServiceFormAvailability
