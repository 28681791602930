import React from 'react'
import PropTypes from 'prop-types'
import { Link, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAppUninstall = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__app-uninstall'>
      <div className='ta-popup__title'>{dangerousHTML(t('popup.app.uninstall'))}</div>
      <div className='ta-popup__buttons'>
        <Link className='ta-btn ta-btn-secondary' onClick={reject}>{t('global.no')}</Link>
        <Link className='ta-btn ta-btn-primary' onClick={approve}>{t('global.yes')}</Link>
      </div>
    </div>
  )
}

PopupAppUninstall.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupAppUninstall
