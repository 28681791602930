import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { CourseCategoryForm } from '../../Beauties'

const CourseCategoryEditWrapper = props => {
  let {
    id,
    form,
    pending,
    showExternalIds,
    isAdmin,
    branchesAccessBlacklist
  } = props
  pending = !!pending
  form = form || {}
  showExternalIds = !!showExternalIds

  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.courseCategoryFormGet(id)
  }, [initial])

  const onSubmit = cb => {
    handlers.courseCategoryFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CourseCategoryForm
          title={t('servicesGroups.edit.groupCategory.heading')}
          type='edit'
          cancelLink='/management/courses'
          onSubmit={onSubmit}
          showExternalIds={showExternalIds}
          isAdmin={isAdmin}
          branchesAccessBlacklist={branchesAccessBlacklist}
          location={form.location && form.location.values}
        />
      )}
    </>
  )
}

CourseCategoryEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.categoryId,
  pending: state.courses.pendingCategoryForm,
  form: state.forms.courseCategory,
  showExternalIds: state.company.settings.showExternalIds,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default memo(connect(maps)(CourseCategoryEditWrapper))
