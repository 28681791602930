import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  TableRowHeader,
  TableRowMainCol,
  TableRowCol,
  Title,
  Link,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Alert,
  Blur,
  t
} from '../../Common'
import {
  BranchesList,
  BranchesFilter
} from '../../Beauties'

import './Branches.css'

const Branches = props => {
  const {
    branches,
    search,
    onClick,
    showImportMessage,
    hideBranchLoginBtn,
    hideBranchAddBtn,
    hideBranchImportBtn,
    branchesRead,
    branchesWrite,
    tagsRead,
    isAdmin,
    exportTemplateCode,
    allowFulfilment,
    onClickExportBranches,
    showBranchLoginWithoutCustomisationsBtn
  } = props
  const classNames = ['ta-branches']
  if (showImportMessage) classNames.push('import-message-open')
  if (!branchesRead) classNames.push('no-permissions')
  let buttonsColWidth = '32px'
  if (showBranchLoginWithoutCustomisationsBtn && !hideBranchLoginBtn) buttonsColWidth = '62px'

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-branches__title'>
            <Title label={t('branches.list.title')}>
              {(isAdmin && exportTemplateCode &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('branches.list.popup.exportTemplate')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link onClick={onClickExportBranches} className='ta-btn ta-btn-secondary ta-btn-icon-only'>
                      <FontAwesome5 icon='file-download' type='s' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(branchesWrite &&
                <>
                  {(!hideBranchImportBtn &&
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('brances.list.popup.importBranches')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link to='/branches/import' className='ta-btn ta-btn-secondary ta-btn-icon-only'>
                          <FontAwesome5 icon='upload' type='s' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                  {(!hideBranchAddBtn &&
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('branches.list.addNew')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link to='/branches/add' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                          <FontAwesome5 icon='plus' type='s' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                </>
              )}
            </Title>
          </div>
          {(branchesRead &&
            <BranchesFilter />
          )}
          {(showImportMessage &&
            <Alert label={t('branches.list.imoportStarted')} theme='success'>
              {t('branches.list.imoportStarted.message', [{ key: 'ENTITY', value: t('global.branch') }])}
            </Alert>
          )}
          <TableRowHeader>
            <TableRowMainCol>{`${t('global.location')} (${t('global.resultsWithCount', [{ key: 'COUNT', value: branches.length || '0' }])})`}</TableRowMainCol>
            <TableRowCol size='110px'>{t('global.plan')}</TableRowCol>
            <TableRowCol size='110px'>{t('global.registered')}</TableRowCol>
            {(!hideBranchLoginBtn || showBranchLoginWithoutCustomisationsBtn) && (
              <TableRowCol size={buttonsColWidth} />
            )}
          </TableRowHeader>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!branchesRead} icon='ban' label={t('global.accessDenied')}>
            <BranchesList
              branches={branches}
              search={search}
              onClick={onClick}
              hideBranchLoginBtn={hideBranchLoginBtn}
              allowFulfilment={allowFulfilment}
              tagsRead={tagsRead}
              showBranchLoginWithoutCustomisationsBtn={showBranchLoginWithoutCustomisationsBtn}
            />
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Branches
