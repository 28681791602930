import React from 'react'
import {
  t,
  Row,
  Col,
  Form,
  Input,
  FontAwesome5,
  InfiniteScroll,
  TableRow,
  TableRowMainCol,
  TableRowMainColTitle,
  Alert
} from '../../../Common'
import { highlight, getNameByColor } from '../../../Utils'

const PopupDashboardStatisticsSettings = props => {
  const {
    search,
    onChange,
    tags,
    statisticId,
    onSelectTag
  } = props

  return (
    <div className='ta-popup__dashboard-statistics'>
      <Form name={`${statisticId}StatisticsForm`}>
        <Row>
          <Col>
            <Input
              name='search'
              label={t('global.search')}
              addon={<FontAwesome5 icon='search' type='s' />}
              onChange={onChange}
              clearable
            />
          </Col>
        </Row>
        <InfiniteScroll loadMore={() => false}>
          {tags.map((item, i) => {
            const { color, name } = item || {}
            const colorName = getNameByColor(color)
            return (
              <div className='ta-tags__item' key={i} onClick={() => onSelectTag(item)}>
                <TableRow color={colorName}>
                  <TableRowMainCol>
                    <TableRowMainColTitle className='ta-tags__item__title'>
                      {highlight(name || '-', search)}
                    </TableRowMainColTitle>
                  </TableRowMainCol>
                </TableRow>
              </div>
            )
          })}
          {(!tags.length &&
            <Alert noOffset label={t('global.noTags')} theme='no-content' />
          )}
        </InfiniteScroll>
      </Form>
    </div>
  )
}

export default PopupDashboardStatisticsSettings
