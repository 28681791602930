import React from 'react'
import { TagsTitle } from '../../Beauties'
import { TagsListWrapper } from '../../Beasts'
import { Blur, t } from '../../Common'

import './Tags.css'

const Tags = props => {
  let { tagsRead, tagsWrite, pending } = props
  const classNames = ['ta-tags']

  return (
    <div className={classNames.join(' ')}>
      <TagsTitle tagsWrite={tagsWrite} />
      <Blur active={!tagsRead && !pending} icon='ban' label={t('global.accessDenied')}>
        <TagsListWrapper />
      </Blur>
    </div>
  )
}

export default Tags
