import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Link,
  SimpleTabs,
  SimpleTabsBody,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  Title,
  t
} from '../../Common'
import AttributesCategoriesList from './AttributesCategoriesList'

import './Attributes.css'
import { PopupTriggerWrapper } from '../../Beasts'

const Attributes = ({ hash, isAdmin, groupedCategories }) => {
  const classNames = ['ta-attributes']
  const availableTabs = ['resource', 'service', 'course']
  const activeTab = availableTabs.includes(hash) ? hash : 'resource'

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-attributes__title'>
            <Title label={t('attributes.page.title')}>
              <PopupTriggerWrapper name='attributes-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 icon='lightbulb' />
                </Link>
              </PopupTriggerWrapper>
              {isAdmin && (
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('global.category.add')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link to={`/management/attributes/categories/add@@${activeTab}`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
                      <FontAwesome5 icon='folder' type='solid' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
        </FixedContentHeader>
      </FixedContent>
      <FixedContentBody>
        <div className='ta-attributes__list'>
          <SimpleTabs active={activeTab} scrollableContent>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem
                name='resource'
                label={t('global.resources')}
                icon='gem'
              />
              <SimpleTabsHeaderItem
                name='service'
                label={t('global.services')}
                icon='list'
              />
              <SimpleTabsHeaderItem
                name='course'
                label={t('global.groups')}
                icon='users-class'
              />
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <AttributesCategoriesList
                isAdmin={isAdmin}
                activeTab={activeTab}
                groupedCategories={groupedCategories}
              />
            </SimpleTabsBody>
          </SimpleTabs>
        </div>
      </FixedContentBody>
    </div>
  )
}

export default Attributes
