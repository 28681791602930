import React, { Component } from 'react'
import { connect } from '../../Store'
import { ErrorBoundary, Loader } from '../../Common'
import { App, Staging } from '../../Beauties'
import 'moment/min/locales'

class AppWrapper extends Component {
  render () {
    let {
      isAuthenticated,
      hasTokens,
      route,
      isPending,
      isReady,
      customisations
    } = this.props
    customisations = customisations || {}
    const { settings } = customisations
    const { cssOverwriteUrl } = settings || {}

    return (
      <ErrorBoundary>
        {isReady && (
          <>
            {(['development', 'staging'].includes(process.env.REACT_APP_ENV) &&
              <Staging />
            )}
            <App
              isAuthenticated={isAuthenticated}
              hasTokens={hasTokens}
              route={route}
              isPending={isPending}
              hasOverwriteCss={!!cssOverwriteUrl}
            />
          </>
        )}
        <Loader active hideBackground />
      </ErrorBoundary>
    )
  }
}

const maps = state => ({
  isReady: state.app.isReady,
  isPending: state.app.isPending,
  isAuthenticated: !!state.account.userId,
  hasTokens: !!state.auth.tokens,
  route: state.router.name,
  customisations: state.app.customisations
})

export default connect(maps)(AppWrapper)
