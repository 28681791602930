import React from 'react'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Input,
  Error,
  SectionTitle,
  Row,
  Col,
  MultipleSelect,
  t,
  Alert
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './CustomerFieldCategoryForm.css'

const CustomerFieldCategoryForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-customer-fields__category-form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-customer-fields__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='customerFieldCategory'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                      mandatory
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection ignoreBottomOffset={allowOnlyGlobalCustomers}>
              <SectionTitle label={t('global.name')} icon='sliders-h' />
              <Input
                name='name'
                label={t('global.name.label')}
                hintText={t('customerFields.form.category.fieldName.hint')}
                mandatory
                limit={50}
              />
            </FormSection>
            {(!allowOnlyGlobalCustomers &&
              <FormSection ignoreBottomOffset>
                <SectionTitle label={t('global.location')} icon='building' />
                <Row>
                  <Col>
                    <MultipleSelect
                      name='location'
                      label={t('global.select.label')}
                      searchable
                      hasAll={hasAll}
                      disableSelected={type === 'edit'}
                      allLabel={t('global.allLocations')}
                    />
                  </Col>
                </Row>
                {(type === 'edit' &&
                  <Alert theme='alert'>
                    <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
                  </Alert>
                )}
              </FormSection>
            )}
            <FormButtons>
              <Row>
                <Col>
                  <Error name='globalErrors' noTopOffset />
                </Col>
              </Row>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomerFieldCategoryForm
