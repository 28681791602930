import { Link, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAttributeCategoryDelete = ({ approve, reject }) => {
  return (
    <div className='ta-popup__resources-delete'>
      <div className='ta-popup__title'>
        {dangerousHTML(t('global.category.delete.text'))}
      </div>
      <div className='ta-popup__buttons'>
        <Link className='ta-btn ta-btn-secondary' onClick={reject}>{t('buttons.cancel.label')}</Link>
        <Link className='ta-btn ta-btn-primary' onClick={approve}>{t('global.delete')}</Link>
      </div>
    </div>
  )
}

export default PopupAttributeCategoryDelete
