import { sortByOrderIndex } from '../../../Utils'
import { attributeCategoryFormTransform, attributeFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  pendingList: true,
  pendingForm: true,
  pendingCategoryForm: true,
  pendingPreview: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  attributesReset: () => dispatch(actions.ATTRIBUTES_RESET),
  attributesGet: () => dispatch(actions.ATTRIBUTES_GET),
  attributesPopulate: ({ list, categoriesList }) => dispatch(actions.ATTRIBUTES_POPULATE, { list, categoriesList }),
  attributesReady: () => dispatch(actions.ATTRIBUTES_READY),
  attributeCategoryUpdate: category => dispatch(actions.ATTRIBUTE_CATEGORY_UPDATE, category),
  attributeUpdate: attribute => dispatch(actions.ATTRIBUTE_UPDATE, attribute),
  attributesDeleteFormSubmit: (id, scrollToError) => dispatch(actions.ATTRIBUTES_DELETE_FORM_SUBMIT, id, scrollToError),
  attributesDelete: id => dispatch(actions.ATTRIBUTES_DELETE, id),
  attributesDeleted: id => dispatch(actions.ATTRIBUTES_DELETED, id),
  attributesCategoryDelete: id => dispatch(actions.ATTRIBUTES_CATEGORY_DELETE, id),
  attributesCategoryDeleted: id => dispatch(actions.ATTRIBUTES_CATEGORY_DELETED, id),
  attributesPreviewReady: () => dispatch(actions.ATTRIBUTES_PREVIEW_READY),

  // Category form
  attributesCategoryFormGet: id => dispatch(actions.ATTRIBUTES_CATEGORY_FORM_GET, id),
  attributesCategoryFormPopulate: category => {
    handlers.attributesCategoryFormReady()
    handlers.formSet('attributeCategory', attributeCategoryFormTransform(category))
  },
  attributesCategoryFormSubmit: (scrollToError) => dispatch(actions.ATTRIBUTES_CATEGORY_FORM_SUBMIT, scrollToError),
  attributesCategoryFormReady: () => dispatch(actions.ATTRIBUTES_CATEGORY_FORM_READY),

  // Attribute form
  attributesFormGet: id => dispatch(actions.ATTRIBUTES_FORM_GET, id),
  attributesFormPopulate: params => {
    handlers.attributesFormReady()
    handlers.formSet('attribute', attributeFormTransform(params))
  },
  attributesFormSubmit: (scrollToError) => dispatch(actions.ATTRIBUTES_FORM_SUBMIT, scrollToError),
  attributesFormReady: () => dispatch(actions.ATTRIBUTES_FORM_READY),

  // Subscriptions
  attributesSubscriptionSet: params => dispatch(actions.ATTRIBUTES_SUBSCRIPTION_SET, params),
  attributesUpdatedSubscription: id => dispatch(actions.ATTRIBUTES_UPDATED_SUBSCRIPTION, id),
  attributeCategoryUpdatedSubscription: id => dispatch(actions.ATTRIBUTE_CATEGORY_UPDATED_SUBSCRIPTION, id)
})

reducer.ATTRIBUTES_RESET = () => reducer.initialState

reducer.ATTRIBUTES_GET = state => ({
  ...state,
  pendingList: true
})

reducer.ATTRIBUTES_POPULATE = (state, { list, categoriesList }) => ({
  ...state,
  list,
  categoriesList
})

reducer.ATTRIBUTES_READY = state => ({
  ...state,
  pendingList: false
})

reducer.ATTRIBUTE_CATEGORY_UPDATE = (state, category) => {
  if (!category) return state
  return {
    ...state,
    categoriesList: (state.categoriesList || [])
      .filter(item => item.id !== category.id)
      .concat([category])
      .sort(sortByOrderIndex)
  }
}

reducer.ATTRIBUTE_UPDATE = (state, attribute) => {
  if (!attribute) return state
  return {
    ...state,
    list: (state.list || [])
      .filter(item => item.id !== attribute.id)
      .concat([attribute])
      .sort(sortByOrderIndex)
  }
}

reducer.ATTRIBUTES_DELETE_FORM_SUBMIT = state => state

reducer.ATTRIBUTES_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.ATTRIBUTES_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

reducer.ATTRIBUTES_CATEGORY_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.ATTRIBUTES_CATEGORY_DELETED = (state, id) => ({
  ...state,
  categoriesList: state.categoriesList.filter(item => item.id !== id)
})

reducer.ATTRIBUTES_PREVIEW_READY = state => ({
  ...state,
  pendingPreview: false
})

// Category form
reducer.ATTRIBUTES_CATEGORY_FORM_GET = state => ({
  ...state,
  pendingCategoryForm: true
})

reducer.ATTRIBUTES_CATEGORY_FORM_SUBMIT = state => ({
  ...state,
  pendingCategoryForm: true
})

reducer.ATTRIBUTES_CATEGORY_FORM_READY = state => ({
  ...state,
  pendingCategoryForm: false
})

// Attribute form
reducer.ATTRIBUTES_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.ATTRIBUTES_FORM_SUBMIT = state => ({
  ...state,
  pendingForm: true
})

reducer.ATTRIBUTES_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.ATTRIBUTES_SUBSCRIPTION_SET = state => state

reducer.ATTRIBUTES_UPDATED_SUBSCRIPTION = state => state

reducer.ATTRIBUTE_CATEGORY_UPDATED_SUBSCRIPTION = state => state

export default reducer
