import React from 'react'
import {
  SectionTitle,
  ListItem,
  BorderedBox,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { BookingRemindersExample } from '../../../Beauties'

const CoursePreviewAdvanced = props => {
  const {
    customerEmailRemindersMinutes,
    resourceEmailRemindersMinutes,
    resourceRemindersEmailRecipients,
    companyCustomerEmailRemindersMinutes,
    companyResourceEmailRemindersMinutes
  } = props

  const hasCustomCustomerReminders = !!customerEmailRemindersMinutes
  const hasCustomResourceReminders = !!resourceEmailRemindersMinutes
  const customerReminders = customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes
  const resourceReminders = resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes
  const bookingRemindersHasCustomers = customerReminders && customerReminders.length > 0
  const bookingRemindersHasStaff = resourceReminders && resourceReminders.length > 0
  const bookingRemindersHasOwner = resourceRemindersEmailRecipients && resourceRemindersEmailRecipients.includes('owner')
  const bookingRemindersHasResource = resourceRemindersEmailRecipients && resourceRemindersEmailRecipients.includes('resource')
  const bookingRemindersOthers = (resourceRemindersEmailRecipients && resourceRemindersEmailRecipients.length && resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')) || []

  return (
    (hasCustomCustomerReminders || hasCustomResourceReminders) &&
    <div className='ta-courses__preview__advanced'>
      <SectionTitle label={t('servicesGroups.reminder.heading')} icon='bell' ignoreTopOffset />
      <ListItem>
        {dangerousHTML(t('servicesGroups.reminder.infoText'))}
        {(hasCustomCustomerReminders &&
          <>
            <strong><li>{t('servicesGroups.reminder.emails.customersAndParticipants')}</li></strong>
            <BorderedBox>
              {!bookingRemindersHasCustomers && <strong>{t('global.disabled')}</strong>}
              {bookingRemindersHasCustomers && <BookingRemindersExample values={customerReminders} size='small' />}
            </BorderedBox>
          </>
        )}
        {(hasCustomResourceReminders &&
          <>
            <strong><li>{t('global.staff')}</li></strong>
            <BorderedBox>
              {!bookingRemindersHasStaff && <strong>{t('global.disabled')}</strong>}
              {(bookingRemindersHasStaff &&
                <>
                  {bookingRemindersHasOwner && <strong><li>{t('global.owner')}</li></strong>}
                  {bookingRemindersHasResource && <strong><li>{t('servicesGroups.resources')}</li></strong>}
                  {(bookingRemindersOthers.length > 0 &&
                    <>
                      <strong><li>{t('servicesGroups.others')}</li></strong>
                      {bookingRemindersOthers.map(item => <li className='ta-list-item__participant' key={item}>{item}</li>)}
                    </>
                  )}
                  <BookingRemindersExample values={resourceReminders} size='small' />
                </>
              )}
            </BorderedBox>
          </>
        )}
      </ListItem>
    </div>
  )
}

export default CoursePreviewAdvanced
