import React from 'react'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Row,
  Col,
  Error,
  t
} from '../../Common'
import { BillingFormFields } from '../../Beauties'

import './BillingForm.css'

const BillingForm = props => {
  const {
    title,
    onSubmit,
    cancelLink,
    country
  } = props

  return (
    <div className='ta-billing__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-billing__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='billing'>
            <BillingFormFields country={country} />
            <FormSection>
              <Row>
                <Col>
                  <Error name='globalErrors' noTopOffset />
                </Col>
              </Row>
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.save.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BillingForm
