import React from 'react'
import {
  FontAwesome5,
  Link,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const NavigationLinks = props => {
  const { hideSupportLink, customSupportLink } = props

  return (
    <div className='ta-navigation__links'>
      {(!hideSupportLink &&
        <HoverPopup className='ta-navigation__links__link'>
          <HoverPopupContent position='right' autoSize>{t('navigation.support.tooltip')}</HoverPopupContent>
          <HoverPopupTrigger>
            <Link to={customSupportLink || 'https://support.timify.com/'} target='_blank' external>
              <FontAwesome5 icon='life-ring' type='solid' />
            </Link>
          </HoverPopupTrigger>
        </HoverPopup>
       )}
      {/* <HoverPopup className='ta-navigation__links__link'>
        <HoverPopupContent position='right' autoSize>{t('navigation.appcues.tooltip')}</HoverPopupContent>
        <HoverPopupTrigger>
          <Link>
            <FontAwesome5 icon='question-circle' type='regular' />
          </Link>
        </HoverPopupTrigger>
      </HoverPopup> */}
      <HoverPopup className='ta-navigation__links__link'>
        <HoverPopupContent position='right' autoSize>{t('global.logout')}</HoverPopupContent>
        <HoverPopupTrigger>
          <Link to='/logout'>
            <FontAwesome5 icon='sign-out-alt' type='solid' />
          </Link>
        </HoverPopupTrigger>
      </HoverPopup>
    </div>
  )
}

export default NavigationLinks
