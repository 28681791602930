import React from 'react'
import PropTypes from 'prop-types'
import { feedContextInProps } from '../../../Utils'
import {
  SimpleTabsContext,
  HoverPopupContent,
  HoverPopupTrigger,
  FontAwesome5,
  HoverPopup
} from '../../../Common'

const SimpleTabsHeaderItem = props => {
  const {
    onChange,
    onClick,
    name,
    disable,
    icon,
    iconType = 's',
    label,
    pullRight,
    iconOnly,
    className,
    active,
    tooltipText,
    tooltipPosition
  } = props
  const isActive = name === active

  const handleOnClick = () => {
    if (onClick) onClick()
    if (!disable && name && onChange) onChange(name)
  }

  const classNames = ['ta-simple-tabs__header-item']
  if (className) classNames.push(className)
  if (pullRight) classNames.push('pull-right')
  if (iconOnly && !isActive) classNames.push('icon-only')
  if (name === active) classNames.push('active')
  if (disable) classNames.push('disabled')
  classNames.push(`ta-simple-tabs-${name}`)

  return (
    <div className={classNames.join(' ')} onClick={handleOnClick} data-testid='simple-tabs-header-item'>
      <HoverPopup disabled={!tooltipText}>
        <HoverPopupContent position={tooltipPosition || 'top'} autoSize>
          {tooltipText}
        </HoverPopupContent>
        <HoverPopupTrigger>
          {icon && <FontAwesome5 icon={icon} type={iconType} />}
          {(!iconOnly || isActive) && label}
        </HoverPopupTrigger>
      </HoverPopup>
    </div>
  )
}

SimpleTabsHeaderItem.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  pullRight: PropTypes.bool,
  iconOnly: PropTypes.bool,
  className: PropTypes.string
}

export default feedContextInProps(SimpleTabsHeaderItem, SimpleTabsContext)
