import React from 'react'
import { Row, Col, WeekDayIntervalsInput, t } from '../../Common'
import { getDayLabelByIndex } from '../../Utils'

const WeekDay = props => {
  const { day, prefix, isAvailabilityDisabled, intervalGapMessage } = props

  return (
    <div className='ta-week-day'>
      <Row>
        <Col>
          <div className='ta-week-day__intervals'>
            <WeekDayIntervalsInput
              name={`${prefix}Day${day}Intervals`}
              label={t(`weekdays.${getDayLabelByIndex(day)}.short`)}
              disabled={isAvailabilityDisabled}
              intervalGapMessage={intervalGapMessage}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default WeekDay
