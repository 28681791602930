import { validator, translateServerCode } from '../../../Utils'

const customerFieldsDefaults = {
  category: {}
}

export const customerFieldListErrorTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

export const customerFieldTransform = customerField => {
  if (!customerField) return
  return customerField
}

// SAVE
export const customerFieldSaveTransform = customerField => {
  const isActive = !!customerField.isActive.value
  const isMandatoryOffline = isActive && !!customerField.isMandatoryOffline.value
  const isPublished = isActive && !!customerField.isPublished.value
  const isMandatoryOnline = isPublished && !!customerField.isMandatoryOnline.value
  const isUniqueKey = !!customerField.isUniqueKey.value
  const isCustomValuesEnabled = customerField.selectOptionsCheckbox && customerField.selectOptionsCheckbox.value
  const hasOverwrite = !!customerField.hasOverwrite.value
  const result = {
    customerField: {
      id: customerField.id,
      externalId: (customerField.externalId && customerField.externalId.value) || null,
      categoryId: (customerField.categoryId && customerField.categoryId.value) || null,
      type: customerField.type && customerField.type.value,
      placeholder: customerField.placeholder && customerField.placeholder.value,
      description: customerField.description && customerField.description.value,
      hasOverwrite: !!customerField.hasOverwrite.value,
      isAfterAuth: !!customerField.isAfterAuth.value,
      isActive,
      isMandatoryOffline,
      isPublished,
      isMandatoryOnline,
      isUniqueKey,
      displayOnHover: customerField.displayOnHover.value,
      displayOnBookingForm: hasOverwrite ? customerField.displayOnBookingForm.value : null,
      specificServiceIds: (customerField.services.values || []).length > 0
        ? customerField.services.values.includes('all')
          ? []
          : customerField.services.values
        : null,
      specificCourseIds: (customerField.courses.values || []).length > 0
        ? customerField.courses.values.includes('all')
          ? []
          : customerField.courses.values
        : null
    }
  }
  if (!customerField.translationKey) {
    result.customerField.label = (customerField.label && customerField.label.value) || ''
    result.customerField.selectOptions = (customerField.type.value === 'SELECT' && customerField.selectOptions.values.map((item, index) => ({
      value: isCustomValuesEnabled ? item.value : item.label,
      label: item.label
    }))) || []
  }
  if (customerField.isLabelEdited && customerField.isLabelEdited.value) result.customerField.label = (customerField.label && customerField.label.value) || ''
  if (customerField.location && customerField.location.values && !customerField.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...customerField.location.values,
        ...(customerField.location.insufficient || [])
      ]
    }
  }
  return result
}

export const customerFieldsFiltersPopulate = branches => {
  return {
    type: {
      value: 'all',
      options: branches.map(branch => ({
        label: branch.name, value: branch.id
      }))
    }
  }
}

export const customerFieldsTransform = customerFields => customerFields

// FORM
export const customerFieldFormTransform = ({ customerField, branches, account }) => {
  if (!customerField) customerField = customerFieldsDefaults
  if (!customerField.category) customerField.category = customerFieldsDefaults.category

  const nameFields = ['firstName', 'lastName', 'company']
  const filterByDefaultId = ['firstName', 'lastName', 'company']
  const classicDefaultFields = ['firstName', 'lastName', 'mobilePhone', 'email']
  const serviceCourseDisabledFields = ['firstName', 'lastName', 'mobilePhone', 'email']
  const classicInactiveFields = [
    'avatar',
    'title',
    'salutation',
    'company',
    'birthday',
    'landlinePhone',
    'businessPhone',
    'address',
    'birthday',
    'notes'
  ]
  const { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  const {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !customerField.id
  let isServiceDisabled = false
  let isCourseDisabled = false
  let isActiveDisabled = filterByDefaultId.includes(customerField.defaultId) && !!customerField.isActive && customerField.activeNameFields.length < 2
  let isPublishedDisabled = filterByDefaultId.includes(customerField.defaultId) && !!customerField.isPublished && customerField.publishedNameFields.length < 2
  const availableServices = customerField.availableServices || []
  const availableServicesCategories = customerField.availableServicesCategories || []
  const availableCourses = customerField.availableCourses || []
  const availableCoursesCategories = customerField.availableCoursesCategories || []
  const allowedBranchExternalIds = customerField.allowedBranchExternalIds || []
  const allowedBranchIds = customerField.allowedBranchIds || []
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []

  if (customerField.plan === 'CLASSIC') {
    // isActiveDisabled
    if (classicInactiveFields.includes(customerField.defaultId)) {
      isPublishedDisabled = true
    }

    // isPublishedDisabled
    if (classicDefaultFields.includes(customerField.defaultId)) {
      isPublishedDisabled = true
      isActiveDisabled = true
    }
  }

  if (serviceCourseDisabledFields.includes(customerField.defaultId)) {
    isServiceDisabled = true
    isCourseDisabled = true
  }

  const result = {
    label: {
      value: customerField.label || '',
      mandatory: !customerField.translationKey
    },
    description: {
      value: customerField.description || ''
    },
    type: {
      value: (customerField.type && customerField.type) || ''
    },
    placeholder: {
      value: (customerField.placeholder && customerField.placeholder) || ''
    },
    externalId: {
      value: customerField.externalId || ''
    },
    ownExternalId: customerField.externalId,
    // Active for online booking
    isPublished: {
      value: !!customerField.isPublished,
      disabled: isPublishedDisabled
    },
    // Mandatory for online booking
    isMandatoryOnline: {
      value: customerField.isActive ? !!customerField.isMandatoryOnline : false
    },
    // Mandatory for internal usage
    isMandatoryOffline: {
      value: customerField.isActive ? !!customerField.isMandatoryOffline : false
    },
    isDefault: {
      value: !!customerField.isDefault
    },
    // Enabled
    isActive: {
      value: !!customerField.isActive,
      disabled: isActiveDisabled
    },
    hasOverwrite: {
      value: (customerField.hasOverwrite !== undefined) ? !!customerField.hasOverwrite : true
    },
    isAfterAuth: {
      value: (customerField.isAfterAuth !== undefined) ? !!customerField.isAfterAuth : true
    },
    isUniqueKey: {
      value: !!customerField.isUniqueKey
    },
    displayOnHover: {
      value: !!customerField.displayOnHover,
      disabled: nameFields.includes(customerField.defaultId)
    },
    displayOnBookingForm: {
      value: !!customerField.displayOnBookingForm,
      disabled: nameFields.includes(customerField.defaultId)
    },
    categoryName: {
      value: customerField.category.name || ''
    },
    categoryId: {
      value: customerField.category && customerField.category.id
    },
    services: {
      disabled: isServiceDisabled,
      values: customerField.specificServiceIds
        ? customerField.specificServiceIds.length === 0
          ? ['all']
          : customerField.specificServiceIds
        : [],
      options: availableServicesCategories
        .map(category => ({
          name: category.name,
          items: availableServices
            .filter(item => item.categoryId === category.id)
            .map(item => ({ label: item.name, value: item.id }))
        }))
        .filter(category => category.items.length > 0)
    },
    courses: {
      disabled: isCourseDisabled,
      values: customerField.specificCourseIds
        ? customerField.specificCourseIds.length === 0
          ? ['all']
          : customerField.specificCourseIds
        : [],
      options: availableCoursesCategories
        .map(category => ({
          name: category.name,
          items: availableCourses
            .filter(item => item.categoryId === category.id)
            .map(item => ({ label: item.name, value: item.id }))
        }))
        .filter(category => category.items.length > 0)
    },
    location: {
      disabled: isServiceDisabled,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    },
    selectOptions: {
      values: (customerField.selectOptions || []).map(item => ({
        label: item.label,
        value: item.value
      }))
    },
    selectOptionsCheckbox: {
      value: ((customerField.selectOptions || []).length > 0 && customerField.selectOptions.some(item => item.label !== item.value)) || false
    },
    isLabelEdited: {
      value: false
    },
    defaultId: customerField.defaultId || null,
    translationKey: customerField.translationKey || null
  }

  if (customerField.id) result.id = customerField.id

  return result
}

export const customerFieldDeleteFormTransform = () => {
  return {
    name: {
      value: ''
    }
  }
}

export const customerFieldFormValidate = (customerField, customerFieldsList) => {
  if (!customerField) return
  customerFieldsList = customerFieldsList || []

  const rules = [{
    'label.value': [`requiredIf:${!customerField.translationKey}`, 'max:160']
  }, {
    'type.value': ['required', 'max:100']
  }, {
    'description.value': ['max:1000']
  }, {
    'placeholder.value': ['max:100']
  }]

  const messages = {
    label: {
      requiredIf: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    type: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    description: {
      max: 'errors.invalidMaxLength'
    },
    placeholder: {
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    label: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '80'
      }
    },
    type: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.type.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '100'
      }
    },
    description: {
      max: {
        key: 'MAX',
        value: '100'
      }
    },
    placeholder: {
      max: {
        key: 'MAX',
        value: '100'
      }
    }
  }

  const errors = validator(customerField, rules, messages, replaces)

  // location
  if (!customerField.location.values?.length) {
    errors.push({
      key: 'location',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.location.label', translateReplace: true }]
    })
  }

  // Select options validation
  if (customerField.type && customerField.type.value === 'SELECT') {
    const isCustomValuesEnabled = customerField.selectOptionsCheckbox && customerField.selectOptionsCheckbox.value
    const selectOptionsValues = customerField.selectOptions.values.map(item => (item.value || '').trim())
    const selectOptionsLabels = customerField.selectOptions.values.map(item => (item.label || '').trim())
    const uniqueValuesLength = [...new Set(selectOptionsValues)].length
    const uniqueLabelsLength = [...new Set(selectOptionsLabels)].length
    customerField.selectOptions.values.forEach((item, index) => {
      const label = (item.label || '').trim()
      const value = (item.value || '').trim()
      const hasTranslationKey = !!customerField.translationKey
      const isEmpty = (!label && !hasTranslationKey) || (isCustomValuesEnabled && !value)
      // Empty
      if (isEmpty) {
        errors.push({
          key: 'selectOptions',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: (isCustomValuesEnabled && !!label) ? 'global.value' : 'global.label.placeholder', translateReplace: true }],
          index
        })
      }
      // Duplicate values
      if (isCustomValuesEnabled && !isEmpty && selectOptionsValues.length !== uniqueValuesLength) {
        const matchWith = selectOptionsValues.filter(option => option === value)
        if (matchWith.length > 1) {
          errors.push({
            key: 'selectOptions',
            value: 'errors.unique',
            replace: [{ key: 'FIELD_NAME', value: 'global.value', translateReplace: true }],
            index
          })
        }
      }
      // Duplicate labels
      if (isCustomValuesEnabled && !isEmpty && selectOptionsLabels.length !== uniqueLabelsLength) {
        const matchWith = selectOptionsLabels.filter(option => option === label)
        if (matchWith.length > 1 && !hasTranslationKey) {
          errors.push({
            key: 'selectOptions',
            value: 'errors.unique',
            replace: [{ key: 'FIELD_NAME', value: 'global.label.placeholder', translateReplace: true }],
            index
          })
        }
      }
    })
  }

  // Mandatory online booking field checkbox validation
  if (['company', 'firstName', 'lastName'].includes(customerField.defaultId)) {
    const mandatoryFields = customerFieldsList
      .filter(item => ['company', 'firstName', 'lastName'].includes(item.defaultId))
      .reduce((fields, item) => ({ ...fields, [item.defaultId]: item }), {})
    mandatoryFields[customerField.defaultId].isMandatoryOnline = customerField.isMandatoryOnline && customerField.isMandatoryOnline.value
    mandatoryFields[customerField.defaultId].isActive = customerField.isActive && customerField.isActive.value
    mandatoryFields[customerField.defaultId].isPublished = customerField.isPublished && customerField.isPublished.value
    const atLeastOneIsMandatoryBookingField = Object.values(mandatoryFields).some(item => item.isMandatoryOnline && item.isActive && item.isPublished)
    if (!atLeastOneIsMandatoryBookingField) {
      errors.push({
        key: 'customerFieldsGlobalErrors',
        value: 'errors.customer.dataFields.isMandatoryOnline.disabled'
      })
    }
  }

  // external id validation
  const externalIdValue = customerField.externalId && customerField.externalId.value
  let externalIds = customerFieldsList.map(customerField => customerField.externalId).filter(Boolean) || []
  externalIds = externalIds.filter(item => item !== customerField.ownExternalId)
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

export const customerFieldFormServerErrorsTransform = ({ error, branches }) => {
  const errors = []
  if (error?.message) {
    if (error) {
      if (error.code === 'GlobalCustomerFieldSaveError') {
        errors.push({
          key: 'globalCategoryErrors',
          value: translateServerCode(error.code),
          replace: [{ key: 'CONTENT', value: error.value }]
        })
      } else if (error.code === 'ExternalIdConflicts') {
        errors.push({ key: 'externalId', value: translateServerCode(error.code) })
      } else if (error.code === 'NoServiceAndCourse') {
        errors.push({ key: 'globalCategoryErrors', value: translateServerCode(error.code) })
      } else if (['SpecificServiceNotAllowed', 'SpecificCourseNotAllowed'].includes(error.code)) {
        errors.push({ key: 'customerFieldsErrors', value: translateServerCode(error.code) })
      } else {
        if (error.message) {
          errors.push({ key: 'customerFieldsGlobalErrors', value: error.message })
        } else {
          errors.push({ key: 'customerFieldsGlobalErrors', value: translateServerCode(error.code) })
        }
      }
    }
  }
  return errors
}

// CATEGORY FORM
export const customerFieldCategoryFormTransform = ({ category, branches, account }) => {
  category = category || {}
  branches = branches || []
  const allowedBranchExternalIds = category.allowedBranchExternalIds || []
  const allowedBranchIds = category.allowedBranchIds || []
  const { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  const {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !category.id
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []
  return {
    id: {
      value: category.id || ''
    },
    externalId: {
      value: category.externalId || ''
    },
    ownExternalId: category.externalId,
    name: {
      value: category.name || ''
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }
}

export const customerFieldCategoryFormValidate = (form, externalIds) => {
  const { category, location } = form || {}
  if (!category) return
  const errors = []
  if (!category.name) {
    errors.push({
      key: 'name',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }]
    })
  }

  // location
  if (!location?.length) {
    errors.push({
      key: 'location',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.location', translateReplace: true }]
    })
  }

  // external id validation
  externalIds = externalIds.filter(item => item !== form.ownExternalId)
  if (externalIds.includes(category.externalId)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

// CATEGORY SAVE
export const customerFieldCategorySaveTransform = form => {
  const result = {
    category: {
      id: form.id.value,
      name: form.name.value,
      externalId: form.externalId.value || null
    },
    ownExternalId: form.ownExternalId,
    location: form.location.values

  }
  if (form.location && form.location.values && !form.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...form.location.values,
        ...(form.location.insufficient || [])
      ]
    }
  }
  return result
}

export const customerFieldCategoryFormServerErrorsTransform = ({ error, branches }) => {
  const errors = []
  if (error && error.message) {
    if (error) {
      if (error.code === 'GlobalCustomerFieldSaveError') {
        const { data } = error || {}
        let { localErrors } = data || {}
        localErrors = localErrors || []
        const branchesWithErrors = localErrors.map(item => {
          const company = branches.find(branch => branch.id === item.companyId) || {}
          return company.name
        }) || []
        errors.push({
          key: 'globalCategoryErrors',
          value: 'errors.customerFields.branchesNotAllowed',
          replace: [
            { key: 'ENTITY_NAME', value: 'global.localCustomerFieldCategory', translateReplace: true },
            { key: 'BRANCHES', value: branchesWithErrors.join(' <br/>') }
          ]
        })
      } else if (error.code === 'ExternalIdConflicts') {
        errors.push({ key: 'externalId', value: translateServerCode(error.code) })
      } else if (error.code === 'NoServiceAndCourse') {
        errors.push({ key: 'globalCategoryErrors', value: translateServerCode(error.code) })
      } else {
        if (error.message) {
          errors.push({ key: 'customerFieldsGlobalErrors', value: error.message })
        } else {
          errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
        }
      }
    }
  }
  return errors
}
