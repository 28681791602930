import React from 'react'
import { PopupTriggerWrapper } from '../../../Beasts'
import {
  Alert,
  Error,
  Form,
  t,
  AccordionTitle,
  Accordion,
  AccordionBody,
  FontAwesome5,
  Row,
  Col,
  StripedTitle,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  Link,
  Price
} from '../../../Common'
import { SERVICE_COMBINATION_INITIAL_KEYS, SERVICE_INITIAL_KEYS } from '../../../Settings/settings'
import { convertMinutesToDaysHoursMinutes, dangerousHTML, getDayLabelByIndex } from '../../../Utils'

const keysMap = {
  externalId: 'global.externalId',
  name: 'globalSettings.form.section.attributes.serviceName',
  color: 'globalSettings.form.section.attributes.serviceColor',
  description: 'globalSettings.form.section.attributes.serviceDescription',
  durationsPattern: 'globalSettings.form.section.attributes.duration',
  durationBefore: 'globalSettings.form.section.attributes.preparationTime',
  durationAfter: 'globalSettings.form.section.attributes.followup',
  extraPersonsPerParticipant: 'globalSettings.form.section.attributes.guests',
  maxParticipants: 'global.maxParticipants',
  hasCombinationSameResourcesPreference: 'globalSettings.form.section.attributes.assignToSameResource',
  combinationServiceIds: 'servicesGroups.list.serviceCombination.headline',
  dependencies: 'globalSettings.form.section.attributes.resources',
  price: 'globalSettings.form.section.attributes.price',
  isBookable: 'globalSettings.form.section.attributes.displayInBookingWidget',
  hasOnlinePayment: 'globalSettings.form.section.attributes.onlinePayment',
  isPaymentMandatory: 'globalSettings.form.section.attributes.onlinePaymentMandatory',
  customerEmailRemindersMinutes: 'globalSettings.form.section.attributes.customerEmailReminders',
  resourceEmailRemindersMinutes: 'globalSettings.form.section.attributes.resourceEmailReminders',
  locallyUpdatedServiceIds: 'global.service',
  calendar: 'global.availability'
}

const ServicePreviewChangedLocally = props => {
  let { locallyChangedData, service, globalServicesWrite, globalServicesRead } = props
  const restoreDisabled = !globalServicesWrite || !globalServicesRead
  const INITIAL_KEYS = service.isCombination ? SERVICE_COMBINATION_INITIAL_KEYS : SERVICE_INITIAL_KEYS
  locallyChangedData = locallyChangedData || []
  const translations = {
    minutes: t('global.minutes'),
    hours: t('global.hours'),
    days: t('global.days')
  }

  const getValue = (key, val) => {
    if (key === 'combinationServiceIds') return `${val.length} ${t('servicesGroups.list.serviceCombination.headline')}`
    if (key === 'dependencies') return `${val.length} ${t(keysMap.dependencies)} ${t('global.updates')}`
    if (key === 'description') return dangerousHTML(val)
    if (key === 'price') return val === null ? t('global.disabled') : <Price price={val} />
    if (['durationBefore', 'durationAfter'].includes(key)) return convertMinutesToDaysHoursMinutes(val, translations)
    if (['customerEmailRemindersMinutes', 'resourceEmailRemindersMinutes'].includes(key)) {
      if (!val?.length) return t('global.disabled')
      return val.map(v => convertMinutesToDaysHoursMinutes(v, translations)).join(', ')
    }
    if (key === 'durationsPattern') {
      if (val.length === 1) return convertMinutesToDaysHoursMinutes(val[0], translations)
      return (
        val.map((item, index) => {
          const label = t(index % 2 === 0 ? 'global.interval' : 'global.gap')
          return label + ' ' + convertMinutesToDaysHoursMinutes(item, translations)
        }).join(', ')
      )
    }
    if (key === 'calendar') {
      const { bookingWeeklyAllowancePlan } = val || {}
      return ( bookingWeeklyAllowancePlan && bookingWeeklyAllowancePlan?.length ) ? bookingWeeklyAllowancePlan?.map(({ isActive, times }, index) =>
        (isActive &&
          <div key={index}>
            {t(`weekdays.${getDayLabelByIndex(index)}.long`)}:
            {times.map(({ begin, end }, index) => ` ${begin} - ${end}${index === (times.length - 1) ? '' : '; '}`)}
            <br />
          </div>
        )): t("global.disabled")
    }
    if (typeof val === 'boolean') return val ? t('global.enabled') : t('global.disabled')
    return val
  }

  return (
    <div className='ta-services__preview--changed-locally'>
      {locallyChangedData.map(item => {
        let { branch, id: serviceId } = item
        branch = branch || {}

        return (
          <Accordion
            key={item.id}
            className='ta-services__preview--changed-locally-accordion'
            expand={false}
          >
            <AccordionTitle>
              <Row>
                <Col>
                  <strong className='ta-services__preview--changed-locally-accordion-title'>{branch.name || t('global.insufficientPermissions')}</strong>
                </Col>
              </Row>
              <Row noOffset className='ta-services__preview--changed-locally-accordion-options'>
                <Col>
                  <span className='ta-services__preview--changed-locally-accordion-option'>
                    <FontAwesome5 icon='id-badge' type='s' />&nbsp;&nbsp;{branch.id?.substr(0, 6)}
                  </span>
                  <span className='ta-services__preview--changed-locally-accordion-option'>
                    <FontAwesome5 icon='pencil' type='s' />&nbsp;&nbsp;{Object.keys(item.localUpdates).length}&nbsp;{t('services.preview.localChanges.label')}
                  </span>
                </Col>
              </Row>
            </AccordionTitle>
            <AccordionBody>
              <StripedTitle label={t('services.preview.localChanges.label')} />
              <Row>
                <Col>
                  {INITIAL_KEYS.filter(key => item.localUpdates[key] !== undefined).map(key => {
                    return (
                      <IconListItem key={key}>
                        <IconListItemTitle>{t(keysMap[key])}:</IconListItemTitle>
                        <IconListItemBody>
                          {getValue(key, item.localUpdates[key])}&nbsp;&nbsp;
                          {(
                            key === 'price' &&
                            service.isCombination &&
                            item.localUpdates[key] === service.price &&
                              <PopupTriggerWrapper name='services-price-overriden' position='right'>
                                <Link className='btn-small-icon-only' external>
                                  <FontAwesome5 icon='question-circle' type='regular' />
                                </Link>
                              </PopupTriggerWrapper>
                        )}
                        </IconListItemBody>
                      </IconListItem>
                    )
                  })}
                </Col>
              </Row>
              <Link
                disabled={!branch.name || restoreDisabled}
                to={`/management/services/${serviceId}/branches/${branch.id}/restore`}
                className={`ta-btn ta-btn-primary ta-btn-block ${(!branch.name || restoreDisabled) ? 'ta-btn-disabled' : ''}`}
              >
                {t('globals.restore.form.restoreButton.label', [
                  { key: 'ELEMENT', value: service.isCombination ? t('global.serviceCombination') : t('global.service') }
                ])}
              </Link>
            </AccordionBody>
          </Accordion>
        )
      })}
      {(!locallyChangedData.length &&
        <Row>
          <Col>
            <Alert noOffset label={t('tags.noBranches')} theme='no-content' />
          </Col>
        </Row>
      )}
      <Form name='serviceChangedLocally'>
        <Error name='globalErrors' noTopOffset />
      </Form>
    </div>
  )
}

export default ServicePreviewChangedLocally
