import React from 'react'
import PropTypes from 'prop-types'
import { PopupTriggerWrapper } from '../../../Beasts'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Input,
  SectionTitle,
  MultipleSelect,
  SingleSelect,
  Radio,
  Checkbox,
  BorderedBox,
  Textarea,
  FontAwesome5,
  MultipleFieldsSelect,
  Error,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t,
  HintText,
  Alert
} from '../../../Common'
import { customerFieldTypes } from '../../../Settings'
import { dangerousHTML } from '../../../Utils'

import './CustomerField.css'

const CustomerFieldForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    type,
    categoryId,
    isPublished,
    isDefault,
    defaultId,
    isActive,
    formType,
    isActiveDisabled,
    isPublishedDisabled,
    courses,
    services,
    isNameField,
    hasOverwrite,
    showExternalIds,
    allowOnlyGlobalCustomers,
    customisations,
    isAdmin,
    branchesAccessBlacklist,
    location
  } = props
  location = location || []
  const { hideCustomerFieldsFieldTypeSection } = customisations?.settings || {}
  const fieldTypeOptions = customerFieldTypes
    .map(item => ({ value: item.value, label: t(item.label) }))
    .sort((a, b) => a.label > b.label ? 1 : -1)
  const disablePositionChange = ['firstName', 'lastName', 'companyName', 'email', 'businessPhone', 'landlinePhone', 'mobilePhone'].includes(defaultId)
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-customer-fields__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-customer-fields__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='customerFields' noValidate>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                      mandatory
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            {(isDefault &&
              <Row>
                <Col>
                  <Input name='label' label={t('global.name.label')} disabled />
                </Col>
              </Row>
            )}
            {(!isDefault &&
              <FormSection>
                <Row>
                  <Col>
                    <Input
                      name='label'
                      label={t('global.name.label')}
                      hintText={t('customerFields.form.fieldName.hint')}
                      limit={160}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input name='categoryName' label={t('global.category')} disabled mandatory />
                    <Input name='categoryId' value={categoryId} type='hidden' disabled mandatory />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SingleSelect
                      disabled={(formType === 'edit')}
                      options={fieldTypeOptions}
                      name='type'
                      label={t('customerFields.form.fieldType.label')}
                      mandatory
                    />
                  </Col>
                </Row>
                {(type === 'SELECT' &&
                  <FormSection>
                    <SectionTitle label={t('global.values')} icon='sliders-h'>
                      <PopupTriggerWrapper name='customer-fields-selectbox-values' position='right'>
                        <Link className='btn-small-icon-only' external>
                          <FontAwesome5 icon='question-circle' />
                        </Link>
                      </PopupTriggerWrapper>
                    </SectionTitle>
                    <Row>
                      <Col>
                        <MultipleFieldsSelect hasOrdering name='selectOptions' label={t('global.value')} limit={40} />
                      </Col>
                    </Row>
                  </FormSection>
                )}
              </FormSection>
            )}
            {(!isDefault && !hideCustomerFieldsFieldTypeSection &&
              <FormSection>
                <SectionTitle label={t('customerFields.form.section.dataStorage.heading')} icon='save'>
                  <PopupTriggerWrapper name='customer-fields-data-storage' position='right'>
                    <Link className='btn-small-icon-only' external>
                      <FontAwesome5 icon='question-circle' />
                    </Link>
                  </PopupTriggerWrapper>
                </SectionTitle>
                <Row>
                  <Col>
                    <Radio name='hasOverwrite' value label={t('customerFields.form.section.dataStorage.radioOverwrite.description')} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Radio name='hasOverwrite' value={false} label={t('customerFields.form.section.dataStorage.radioKeepCopies.description')} />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <SectionTitle label={t('customerFields.form.section.service.heading')} icon='list'>
                <PopupTriggerWrapper name='customer-fields-services' position='right'>
                  <Link className='btn-small-icon-only' external>
                    <FontAwesome5 icon='question-circle' />
                  </Link>
                </PopupTriggerWrapper>
              </SectionTitle>
              <Row>
                <Col>
                  <MultipleSelect
                    name='services'
                    label={t('global.services')}
                    searchable
                    hasAll
                    allLabel={t('global.all')}
                    hasCategories
                    options={services}
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('customerFields.form.section.groups.heading')} icon='list'>
                <PopupTriggerWrapper name='customer-fields-courses' position='right'>
                  <Link className='btn-small-icon-only' external>
                    <FontAwesome5 icon='question-circle' />
                  </Link>
                </PopupTriggerWrapper>
              </SectionTitle>
              <Row>
                <Col>
                  <MultipleSelect
                    name='courses'
                    label={t('global.groups')}
                    searchable
                    hasAll
                    allLabel={t('global.all')}
                    hasCategories
                    options={courses}
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('customerFields.form.displaySettings.heading')} icon='eye' />
              <Alert theme='alert'>
                {dangerousHTML(t('customerFields.form.displaySettings.warning'))}
              </Alert>
              <Row>
                <Col>
                  <HoverPopup disabled={!isNameField}>
                    <HoverPopupContent position='top'>
                      {t('customerFields.form.displaySettings.checboxDisplayOnHover.tooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <div className='ta-customer-fields__form__is-active-checkbox'>
                        <Checkbox
                          theme='switch'
                          name='displayOnHover'
                          label={t('customerFields.form.displaySettings.checboxDisplayOnHover.description')}
                          inline
                        />
                      </div>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(hasOverwrite &&
                <Row>
                  <Col>
                    <HoverPopup disabled={!isNameField}>
                      <HoverPopupContent position='top'>
                        {t('customerFields.form.displaySettings.checboxDisplayOnHover.tooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <div className='ta-customer-fields__form__is-active-checkbox'>
                          <Checkbox
                            theme='switch'
                            name='displayOnBookingForm'
                            label={t('customerFields.form.displaySettings.checboxDisplayOnBookingForm.description')}
                            inline
                          />
                        </div>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </Col>
                </Row>
              )}
            </FormSection>
            <FormSection>
              <SectionTitle label={t('global.settings')} icon='cogs' />
              {(type !== 'CHECKBOX' &&
                <Row>
                  <Col>
                    <Checkbox name='isUniqueKey' label={t('customerFields.form.section.settings.checkboxIsUniqueIdentifier.description')} inline />
                    <HintText>{t('customerFields.form.section.settings.checkboxIsUniqueIdentifier.hint')}</HintText>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <HoverPopup disabled={!isActiveDisabled}>
                    <HoverPopupContent position='bottom'>
                      {t('customerFields.form.tooltip.atLeastOneNameFieldRequired')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <div className='ta-customer-fields__form__is-active-checkbox'>
                        <Checkbox name='isActive' label={t('customerFields.form.section.settings.checkboxShowInCustomers.description')} />
                      </div>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(isActive &&
                <>
                  <Row>
                    <Col>
                      <Checkbox name='isMandatoryOffline' label={t('global.internalUsage.mandatory')} inline />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <HoverPopup disabled={!isPublishedDisabled}>
                        <HoverPopupContent position='bottom'>
                          {t('customerFields.form.tooltip.atLeastOneNameFieldRequired')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Checkbox name='isPublished' label={t('customerFields.form.section.settings.checkboxActivateOnline.description')} />
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </Col>
                  </Row>
                  {(isPublished &&
                    <BorderedBox>
                      <Row>
                        <Col>
                          <Radio name='isAfterAuth' value={false} label={t('customerFields.form.section.settings.activateOnline.radioBeforeAuth.description')} disabled={disablePositionChange} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio name='isAfterAuth' value label={t('customerFields.form.section.settings.activateOnline.radioAfterAuth.description')} disabled={disablePositionChange} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            name='placeholder'
                            label={t('global.placeholder')}
                            hintText={t('customerFields.form.section.settings.activateOnline.placeholder.hint')}
                            limit={100}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Textarea
                            name='description'
                            label={t('global.description.label')}
                            hintText={t('customerFields.form.section.settings.activateOnline.explanation.hint')}
                            limit={1000}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='isMandatoryOnline'
                            label={t('global.onlineBooking.mandatory')}
                            inline
                          />
                        </Col>
                      </Row>
                    </BorderedBox>
                  )}
                </>
              )}
            </FormSection>
            {(!allowOnlyGlobalCustomers &&
              <FormSection>
                <SectionTitle label={t('global.location.label')} icon='building' />
                <Row>
                  <Col>
                    <MultipleSelect
                      name='location'
                      label={t('global.select.label')}
                      searchable
                      hasAll={hasAll}
                      disableSelected={formType === 'edit'}
                      allLabel={t('global.allLocations')}
                    />
                  </Col>
                </Row>
                {(formType === 'edit' &&
                  <Alert theme='alert'>
                    <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
                  </Alert>
                )}
              </FormSection>
            )}
            <FormSection>
              <Error name='customerFieldsGlobalErrors' noTopOffset />
              <Error name='customerFieldsErrors' noTopOffset />
              <Error name='globalCategoryErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CustomerFieldForm.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default CustomerFieldForm
