import React from 'react'
import { SectionTitle, Link, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServicesCreateTypeSelect = props => {
  const { id: categoryId, closePopup } = props
  const onClick = () => {
    closePopup && closePopup()
  }

  return (
    <div className='ta-popup__services-create-type-select'>
      <SectionTitle
        label={dangerousHTML(t('servicesGroups.add.modal.title'))}
        icon='plus'
        ignoreTopOffset
      />
      <div className='ta-popup__text'>
        <Link
          to={`/management/services/add?c=${categoryId}`}
          className='ta-popup__services-create-type-select__btn'
          action={onClick}
        >
          <span className='ta-popup__services-create-type-select__btn__title'>
            {t('global.service')}
          </span>
          {t('servicesGroups.add.modal.service.description')}
        </Link>
        <Link
          to={`/management/services/add-combination?c=${categoryId}`}
          className='ta-popup__services-create-type-select__btn last'
          action={onClick}
        >
          <span className='ta-popup__services-create-type-select__btn__title'>
            {t('global.serviceCombination')}
          </span>
          {t('servicesGroups.add.modal.serviceCombination.description')}
        </Link>
      </div>
    </div>
  )
}

export default PopupServicesCreateTypeSelect
