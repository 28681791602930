import React from 'react'
import {
  FormSection,
  SectionTitle,
  Row,
  Col,
  Input,
  t
} from '../../../Common'

const ServiceFormExternalId = props => {
  return (
    <FormSection>
      <SectionTitle label={t('global.externalId')} icon='fingerprint' ignoreTopOffset />
      <Row>
        <Col>
          <Input
            name='externalId'
            label={t('global.externalId')}
            hintText={t('global.externalId.hint')}
          />
        </Col>
      </Row>
    </FormSection>
  )
}

export default ServiceFormExternalId
