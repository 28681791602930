import { registerRoute, registerAfterEach } from '../Router'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE, AVAILABLE_LANGUAGES, DEFAULT_LOCALES_BY_LANG } from '../../Settings'
import { loginFormInitialValues } from '../../Store/Components/Auth/utils'
import { handlers } from '../../Store'

registerAfterEach((route, state, handlers) => {
  const { query } = route
  let {
    token,
    locale: routeLocale,
    debug,
    redirect,
    accountId,
    url
  } = query || {}
  routeLocale = !SUPPORTED_LOCALES.includes(routeLocale) ? null : routeLocale
  const appLocale = !SUPPORTED_LOCALES.includes(state.app.locale) ? null : state.app.locale
  const persistPayload = {}
  if (debug === 'true') persistPayload.isDebuggingActive = true
  if (debug === 'false') {
    persistPayload.isDebuggingActive = false
    persistPayload.isPhraseActive = false
    persistPayload.isIntercomActive = true
  }
  handlers.persistSet(persistPayload)
  if (token) {
    handlers.logout()
    handlers.loginWithOneTimeToken(token)
  }
  let loginUrl = '/login'
  const locale = routeLocale || appLocale || DEFAULT_LOCALE
  const params = []
  const isDownloadRoute = redirect && redirect.includes('download')
  if (locale && locale !== DEFAULT_LOCALE) params.push(`locale=${locale}`)
  if (accountId) params.push(`accountId=${accountId}`)
  if (url) params.push(`url=${url}`)
  if (redirect && !redirect.includes('logout')) { params.push(`redirect=${encodeURIComponent(redirect)}`) }
  if (!state.auth.tokens && !['login', 'sso', 'passwordForgot'].includes(route.name)) {
    if (!redirect) params.push(`redirect=${route.path}`)
    if (params.length > 0) loginUrl = `${loginUrl}?${params.join('&')}`
    handlers.navigateToPath(loginUrl)
  }
  if (state.auth.tokens && route.name === 'login' && !isDownloadRoute) handlers.navigateToPath('/')
  window.Appcues && window.Appcues.page()
})

// HOME

registerRoute('home', {
  pattern: '/',
  onAfter: (route, state, handlers) => {
    const { account, company } = state
    const { userId } = account || {}
    const { id: enterpriseId } = company || {}
    if (userId && enterpriseId) {
      handlers.navigateToPath(`/?enterprise=${enterpriseId}`)
    }
  }
})

// AUTH

registerRoute('login', {
  pattern: '/login',
  onAfter: (route, state, handlers) => {
    const mappedLocales = {}
    const availableLocales = AVAILABLE_LANGUAGES.map(language => language.locale)
    SUPPORTED_LOCALES.forEach(locale => {
      mappedLocales[locale] = availableLocales.find(availableLocale => locale.includes(availableLocale)) || DEFAULT_LOCALES_BY_LANG[locale.split('-')[0]] || 'en-gb'
    })
    let locale = (route.data && route.data.locale) || state.app.locale
    locale = mappedLocales[locale] || DEFAULT_LOCALE
    handlers.languageChange(locale)
    handlers.formFieldsUpdate('login', { language: { ...loginFormInitialValues().language, value: locale } })
  }
})

registerRoute('sso', {
  pattern: '/login-sso',
  props: {
    flow: 'sso'
  },
  onAfter: (route, state, handlers) => {
    let locale = (route.data && route.data.locale) || state.app.locale
    locale = SUPPORTED_LOCALES.indexOf(locale) === -1 ? DEFAULT_LOCALE : locale
    handlers.languageChange(locale)
  }
})

registerRoute('loginCallCentre', {
  pattern: '/login-call-centre',
  onAfter: (route, state, handlers) => {
    const { oldPath } = route || {}
    handlers.callCentreLogin()
    handlers.navigateToPath(oldPath || '/')
  }
})

registerRoute('passwordForgot', {
  pattern: '/password-forgot',
  onAfter: (route, state, handlers) => {
    let locale = (route.data && route.data.locale) || state.app.locale
    locale = SUPPORTED_LOCALES.indexOf(locale) === -1 ? DEFAULT_LOCALE : locale
    handlers.languageChange(locale)
    handlers.formFieldsUpdate('login', { language: { ...loginFormInitialValues().language, value: locale } })
  }
})

registerRoute('logout', {
  pattern: '/logout',
  onAfter: (route, state, handlers) => {
    handlers.logout()
  }
})

// BRANCHES

registerRoute('branches', {
  pattern: '/branches',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      size: 'big'
    },
    overlay: true
  }
})

registerRoute('branchesPreview', {
  pattern: '/branches/:id/preview',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      size: 'big',
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesEdit', {
  pattern: '/branches/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches',
    formDiscard: 'branches'
  }
})

registerRoute('branchesResourceEdit', {
  pattern: '/branches/:branchId/resources/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesResourcePasswordChange', {
  pattern: '/branches/:branchId/resources-password-change/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesResourcesImport', {
  pattern: '/branches/:id/resources-import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesResourceCategoriesImport', {
  pattern: '/branches/:id/resource-categories-import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesServiceEdit', {
  pattern: '/branches/:branchId/services/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesServiceCombinationEdit', {
  pattern: '/branches/:branchId/services/:id/edit-combination',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesServicesImport', {
  pattern: '/branches/:id/services-import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesServiceCategoriesImport', {
  pattern: '/branches/:id/service-categories-import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesGroupEdit', {
  pattern: '/branches/:branchId/groups/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesGroupsImport', {
  pattern: '/branches/:id/groups-import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesGroupCategoriesImport', {
  pattern: '/branches/:id/group-categories-import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesCustomerEdit', {
  pattern: '/branches/:branchId/customers/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

registerRoute('branchesAdd', {
  pattern: '/branches/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches',
    formDiscard: 'branches'
  }
})

registerRoute('branchesImport', {
  pattern: '/branches/import',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      parentSize: 'big'
    },
    overlay: true,
    defaultRedirect: '/branches'
  }
})

// BRANCH TAGS

registerRoute('branchesTags', {
  pattern: '/branches/tags',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('branchesTagsAdd', {
  pattern: '/branches/tags/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/branches/tags',
    formDiscard: 'tags'
  }
})

registerRoute('branchesTagsEdit', {
  pattern: '/branches/tags/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/branches/tags',
    formDiscard: 'tags'
  }
})

registerRoute('branchesTagsPreview', {
  pattern: '/branches/tags/:id/preview',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/branches/tags'
  }
})

registerRoute('download', {
  pattern: '/download',
  onAfter: (route, state, handlers) => {
    const { query } = route
    const { url, accountId } = query
    handlers.appFileDownload({ url, accountId })
  }
})

// ACCOUNTS

registerRoute('accounts', {
  pattern: '/accounts',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      negative: true
    },
    overlay: true
  }
})

// ACCOUNT

registerRoute('account', {
  pattern: '/account',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('accountEdit', {
  pattern: '/account/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/account',
    formDiscard: 'account'
  }
})

registerRoute('accountBilling', {
  pattern: '/account/billing',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('accountBillingEdit', {
  pattern: '/account/billing/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/account/billing'
  }
})

registerRoute('accountInvoices', {
  pattern: '/account/invoices',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('user', {
  pattern: '/user',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('userEdit', {
  pattern: '/user/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user',
    formDiscard: 'user'
  }
})
registerRoute('userEditPassword', {
  pattern: '/user/change-password',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user'
  }
})

registerRoute('userEditEmail', {
  pattern: '/user/change-email',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user',
    formDiscard: 'account'
  }
})

registerRoute('userEditLanguage', {
  pattern: '/user/change-language',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user',
    formDiscard: 'userLanguageChange'
  }
})

// MANAGERS

registerRoute('managers', {
  pattern: '/managers',
  onAfter: () => {
    handlers.navigateToPath('/managers/users')
  }
})

// USERS

registerRoute('users', {
  pattern: '/managers/users',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true,
    formDiscard: 'account'
  }
})

registerRoute('usersEdit', {
  pattern: '/managers/users/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/managers/users',
    formDiscard: 'users'
  }
})

registerRoute('usersAdd', {
  pattern: '/managers/users/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/managers/users',
    formDiscard: 'users'
  }
})

registerRoute('usersPreview', {
  pattern: '/managers/users/:id/preview',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/managers/users'
  }
})

// USER PERMISSIONS

registerRoute('userPermissions', {
  pattern: '/managers/user-permissions',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('userPermissionsAdd', {
  pattern: '/managers/user-permissions/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/managers/user-permissions'
  }
})

registerRoute('userPermissionsEdit', {
  pattern: '/managers/user-permissions/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/managers/user-permissions'
  }
})

registerRoute('userPermissionsPreview', {
  pattern: '/managers/user-permissions/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/managers/user-permissions'
  }
})

// GLOBALS CUSTOMER FIELDS

registerRoute('customerFieldsCategories', {
  pattern: '/customers/categories',
  onAfter: () => {
    handlers.navigateToPath('/customers/customer-fields')
  }
})

registerRoute('customerFieldsCategoryAdd', {
  pattern: '/customers/customer-fields/categories/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields',
    formDiscard: 'customerFieldCategory'
  }
})

registerRoute('customerFieldCategoryPreview', {
  pattern: '/customers/customer-fields/categories/:categoryId',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields'
  }
})

registerRoute('customerFieldsCategoryEdit', {
  pattern: '/customers/customer-fields/categories/:categoryId/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields',
    formDiscard: 'customerFieldCategory'
  }
})

registerRoute('customerFieldsCategoriesPreview', {
  pattern: '/customers/customer-fields/categories/:categoryId',
  onAfter: () => {
    handlers.navigateToPath('/customers/customer-fields')
  }
})

registerRoute('customerFieldsReorder', {
  pattern: '/customers/customer-fields/reorder',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      hideCloseButton: true
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields'
  }
})

registerRoute('customerFieldAdd', {
  pattern: '/customers/customer-fields/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields',
    formDiscard: 'customerFields'
  }
})

registerRoute('customerFieldsPreview', {
  pattern: '/customers/customer-fields/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields'
  }
})

registerRoute('customerFieldsEdit', {
  pattern: '/customers/customer-fields/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/customer-fields',
    formDiscard: 'customerFields'
  }
})

registerRoute('customerFields', {
  pattern: '/customers/customer-fields',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

// GLOBAL TAGS

registerRoute('companyTags', {
  pattern: '/customers/company-tags',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('companyTagsReorder', {
  pattern: '/customers/company-tags/reorder',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      hideCloseButton: true
    },
    overlay: true,
    defaultRedirect: '/customers/company-tags'
  }
})

registerRoute('companyTagAdd', {
  pattern: '/customers/company-tags/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/company-tags',
    formDiscard: 'companyTags'
  }
})

registerRoute('companyTagEdit', {
  pattern: '/customers/company-tags/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/company-tags',
    formDiscard: 'companyTags'
  }
})

registerRoute('companyTagPreview', {
  pattern: '/customers/company-tags/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers/company-tags'
  }
})

// GLOBAL CUSTOMERS

registerRoute('customers', {
  pattern: '/customers',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('customersAdd', {
  pattern: '/customers/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers',
    formDiscard: 'customers'
  }
})

registerRoute('customerPreview', {
  pattern: '/customers/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers'
  }
})

registerRoute('customersEdit', {
  pattern: '/customers/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/customers',
    formDiscard: 'customers'
  }
})

registerRoute('globalsSettings', {
  pattern: '/settings/globals',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('globalsSettingsEdit', {
  pattern: '/settings/globals/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings/globals',
    formDiscard: 'globalsSettings'
  }
})

// GLOBAL MANAGEMENT

registerRoute('globalManagement', {
  pattern: '/management',
  onAfter: () => {
    handlers.navigateToPath('/management/resource-categories')
  }
})

// GLOABAL SERVICES

registerRoute('servicesCategories', {
  pattern: '/management/services/categories',
  onAfter: () => {
    handlers.navigateToPath('/management/services')
  }
})

registerRoute('serviceCategoryAdd', {
  pattern: '/management/services/categories/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services',
    formDiscard: 'serviceCategory'
  }
})

registerRoute('serviceCategoryPreview', {
  pattern: '/management/services/categories/:categoryId',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services'
  }
})

registerRoute('servicesReorder', {
  pattern: '/management/services/reorder',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      hideCloseButton: true
    },
    overlay: true,
    defaultRedirect: '/management/services'
  }
})

registerRoute('serviceCategoryEdit', {
  pattern: '/management/services/categories/:categoryId/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services',
    formDiscard: 'serviceCategory'
  }
})

registerRoute('serviceAdd', {
  pattern: '/management/services/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services',
    formDiscard: 'services'
  }
})

registerRoute('serviceCombinationAdd', {
  pattern: '/management/services/add-combination',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services',
    formDiscard: 'services'
  }
})

registerRoute('servicePreview', {
  pattern: '/management/services/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services'
  }
})

registerRoute('serviceBranchRestore', {
  pattern: '/management/services/:id/branches/:branchId/restore',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services'
  }
})

registerRoute('serviceRestore', {
  pattern: '/management/services/:id/branches/restore',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services'
  }
})

registerRoute('serviceEdit', {
  pattern: '/management/services/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services',
    formDiscard: 'services'
  }
})

registerRoute('serviceCombinationEdit', {
  pattern: '/management/services/:id/edit-combination',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/services',
    formDiscard: 'services'
  }
})

registerRoute('services', {
  pattern: '/management/services',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

// GLOBAL RESOURCES

registerRoute('resourceCategories', {
  pattern: '/management/resource-categories',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('resourceCategoryAdd', {
  pattern: '/management/resource-categories/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/resource-categories',
    formDiscard: 'resourceCategory'
  }
})

registerRoute('resourceCategoryPreview', {
  pattern: '/management/resource-categories/:categoryId',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/resource-categories'
  }
})

registerRoute('resourceCategoryEdit', {
  pattern: '/management/resource-categories/:categoryId/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/resource-categories',
    formDiscard: 'resourceCategory'
  }
})

// GLOBAL COURSES

registerRoute('coursesCategories', {
  pattern: '/management/courses/categories',
  onAfter: () => {
    handlers.navigateToPath('/management/courses')
  }
})

registerRoute('courseCategoryAdd', {
  pattern: '/management/courses/categories/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses',
    formDiscard: 'courseCategory'
  }
})

registerRoute('courseCategoryPreview', {
  pattern: '/management/courses/categories/:categoryId',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses'
  }
})

registerRoute('coursesReorder', {
  pattern: '/management/courses/reorder',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      hideCloseButton: true
    },
    overlay: true,
    defaultRedirect: '/management/courses'
  }
})

registerRoute('courseCategoryEdit', {
  pattern: '/management/courses/categories/:categoryId/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses',
    formDiscard: 'courseCategory'
  }
})

registerRoute('coursesCategoriesPreview', {
  pattern: '/management/courses/categories/:categoryId',
  onAfter: () => {
    handlers.navigateToPath('/management/courses')
  }
})

registerRoute('courseAdd', {
  pattern: '/management/courses/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses',
    formDiscard: 'courses'
  }
})

registerRoute('securitySettings', {
  pattern: '/settings/security',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('securitySettingsEdit', {
  pattern: '/settings/security/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings/security',
    formDiscard: 'settingsSecurity'
  }
})

registerRoute('coursePreview', {
  pattern: '/management/courses/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses'
  }
})

registerRoute('courseBranchRestore', {
  pattern: '/management/courses/:id/branches/:branchId/restore',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses'
  }
})

registerRoute('courseRestore', {
  pattern: '/management/courses/:id/branches/restore',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses'
  }
})

registerRoute('courseEdit', {
  pattern: '/management/courses/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/courses',
    formDiscard: 'courses'
  }
})

registerRoute('courses', {
  pattern: '/management/courses',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

// GLOBAL ATTRIBUTES
registerRoute('attributes', {
  pattern: '/management/attributes',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('attributeCategoryAdd', {
  pattern: '/management/attributes/categories/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/attributes',
    formDiscard: 'attributeCategory'
  }
})

registerRoute('attributeCategoryEdit', {
  pattern: '/management/attributes/categories/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/attributes',
    formDiscard: 'attributeCategory'
  }
})

registerRoute('attributeAdd', {
  pattern: '/management/attributes/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/attributes',
    formDiscard: 'attribute'
  }
})

registerRoute('attributeEdit', {
  pattern: '/management/attributes/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/attributes',
    formDiscard: 'attribute'
  }
})

registerRoute('attributePreview', {
  pattern: '/management/attributes/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/attributes'
  }
})

registerRoute('attributeCategoryPreview', {
  pattern: '/management/attributes/categories/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/attributes'
  }
})

// USER GROUPS

registerRoute('userGroups', {
  pattern: '/users/user-groups',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('userGroupsAdd', {
  pattern: '/users/user-groups/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/users/user-groups'

  }
})

registerRoute('userGroupsEdit', {
  pattern: '/users/user-groups/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/users/user-groups'
  }
})

// APPS

registerRoute('apps', {
  pattern: '/apps',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('appsPreview', {
  pattern: '/apps/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps'
  }
})

registerRoute('appsIframe', {
  pattern: '/apps/:id/iframe',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps'
  }
})

registerRoute('appsUninstall', {
  pattern: '/apps/:id/uninstall',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps',
    formDiscard: 'appsUninstall'
  }
})

registerRoute('appsSubscription', {
  pattern: '/apps/:id/subscription',
  props: {
    sidebar: {
      position: 'left',
      level: 2,
      noBottomFade: true
    },
    overlay: true,
    defaultRedirect: '/apps',
    formDiscard: 'appSubscription'
  }
})

registerRoute('appsSubscriptionAdd', {
  pattern: '/apps/:id/subscription/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps',
    formDiscard: 'appSubscription'
  }
})

registerRoute('appsSubscriptionCancelled', {
  pattern: '/apps/:id/subscription/cancelled',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps',
    formDiscard: 'appSubscription'
  }
})

registerRoute('appsSubscriptionFailed', {
  pattern: '/apps/:id/subscription/failed',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps'
  }
})

registerRoute('appsSubscriptionGone', {
  pattern: '/apps/:id/subscription/gone',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/apps'
  }
})

// SETTINGS

registerRoute('settings', {
  pattern: '/settings',
  onAfter: () => {
    handlers.navigateToPath('/settings/bookings')
  }
})

registerRoute('bookingSettingsEdit', {
  pattern: '/settings/bookings/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings/bookings',
    formDiscard: 'bookingSettings'
  }
})

registerRoute('bookingSettings', {
  pattern: '/settings/bookings',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

// registerRoute('webAppSettings', {
//   pattern: '/settings/webApp',
//   props: {
//     sidebar: {
//       position: 'left',
//       level: 1
//     },
//     overlay: true
//   }
// })

// registerRoute('webAppSettingsEdit', {
//   pattern: '/settings/webApp/edit',
//   props: {
//     sidebar: {
//       position: 'left',
//       level: 2
//     },
//     overlay: true,
//     defaultRedirect: '/settings/webApp',
//     formDiscard: 'webAppSettings'
//   }
// })

registerRoute('emailSettings', {
  pattern: '/settings/email',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('emailSettingsEdit', {
  pattern: '/settings/email/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings/email',
    formDiscard: 'settings'
  }
})

registerRoute('customersSettings', {
  pattern: '/settings/customers',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('customersSettingsEdit', {
  pattern: '/settings/customers/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings/customers',
    formDiscard: 'settingsCustomers'
  }
})

registerRoute('securitySettings', {
  pattern: '/settings/security',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('securitySettingsEdit', {
  pattern: '/settings/security/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings/security',
    formDiscard: 'settingsSecurity'
  }
})

// PERMISSION TYPES

registerRoute('permissionTypeAdd', {
  pattern: '/management/permission-types/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/permission-types',
    formDiscard: 'permissionType'
  }
})

registerRoute('permissionTypeEdit', {
  pattern: '/management/permission-types/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/permission-types',
    formDiscard: 'permissionType'
  }
})

registerRoute('permissionTypePreview', {
  pattern: '/management/permission-types/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/management/permission-types'
  }
})

registerRoute('permissionTypes', {
  pattern: '/management/permission-types',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

// ACTIVITY LOG

registerRoute('activity', {
  pattern: '/activity',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

// NOTIFICATIONS

registerRoute('notifications', {
  pattern: '/notifications',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})
