import React from 'react'
import moment from 'moment'
import { AvailabilityTimelinesGridUnavailableIntervals, AvailabilityTimelinesGridAvailableIntervals, t } from '../../Common'
import { getMinutesDiff } from '../../Utils'

const AvailabilityTimelinesGridDays = props => {
  let { timelines, isDouble, unavailableTimelines, availabilityTimelineLimits } = props
  availabilityTimelineLimits = availabilityTimelineLimits || {}
  const rangeInMinutes = getMinutesDiff(availabilityTimelineLimits.min, availabilityTimelineLimits.max)

  const getIntervalPositioning = interval => {
    let left = 0
    let width = 0
    const leftInMinutes = getMinutesDiff(availabilityTimelineLimits.min, interval.begin)
    if (leftInMinutes) left = `${leftInMinutes / rangeInMinutes * 100}%`
    const widthInMinutes = getMinutesDiff(interval.begin, interval.end)
    if (widthInMinutes) width = `${widthInMinutes / rangeInMinutes * 100}%`
    return { left, width }
  }

  return (
    <div className='ta-availability-timelines__grid-days'>
      {timelines.map((day, index) => {
        const dayClassNames = ['ta-availability-timelines__grid-day']
        if (isDouble && day.isActive) dayClassNames.push('big')

        return (
          <div className={dayClassNames.join(' ')} key={day.index}>
            <div className='ta-availability-timelines__grid-day-label'>
              {moment().weekday(index).format('dd')}
            </div>
            <div className='ta-availability-timelines__grid-day-intervals'>
              <AvailabilityTimelinesGridUnavailableIntervals
                dayIndex={day.index}
                unavailableTimelines={unavailableTimelines}
                getIntervalPositioning={getIntervalPositioning}
              />
              {day.isActive && (
                <AvailabilityTimelinesGridAvailableIntervals
                  isDouble={isDouble}
                  times1={day.times1}
                  times2={day.times2}
                  getIntervalPositioning={getIntervalPositioning}
                />
              )}
            </div>
            {!day.isActive && (
              <div className='ta-availability-timelines__grid-day-unavailable-label'>{t('resources.preview.tabDetails.times.notWorking')}</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AvailabilityTimelinesGridDays
