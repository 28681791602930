import {
  Accordion,
  AccordionBody,
  AccordionTitle,
  Alert,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Link,
  SectionTitle,
  t
} from '../../Common'
import { AttributesListItem } from '../../Beauties'
import { PopupTriggerWrapper } from '../../Beasts'

const AttributeCategory = ({
  hash,
  isLast,
  isAdmin,
  category,
  attributes,
  onAttributeClick
}) => {
  const { name, icon, id } = category || {}
  const hasAttributes = !!attributes?.length
  const classNames = ['ta-attributes__category']
  if (!isAdmin) classNames.push('read')

  return (
    <Accordion className={classNames.join(' ')} focusOnHover>
      <AccordionTitle>
        <SectionTitle label={name} icon={icon}>
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('couse.preview.category.openCategory')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Link
                to={`/management/attributes/categories/${category.id}@@${hash}`}
                className='ta-btn ta-btn-secondary ta-btn-icon-only'
              >
                <FontAwesome5 icon='folder' />
              </Link>
            </HoverPopupTrigger>
          </HoverPopup>
          {isAdmin && (
            <>
              <PopupTriggerWrapper
                name='attribute-category-delete'
                position={isLast ? 'top' : 'bottom'}
                id={id}
                disable={hasAttributes}
              >
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t(hasAttributes
                      ? 'attribute.category.delete.btn.disabled'
                      : 'global.category.delete')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link
                      className={`ta-btn ta-btn-secondary ta-btn-icon-only ${hasAttributes ? 'ta-btn-disabled' : ''}`}
                      external
                    >
                      <FontAwesome5 icon='trash' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              </PopupTriggerWrapper>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('buttons.editCategory.tooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link
                    to={`/management/attributes/categories/${category.id}/edit@@${hash}`}
                    className='ta-btn ta-btn-secondary ta-btn-icon-only'
                  >
                    <FontAwesome5 icon='pencil' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('attributes.addAttribute')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link to={`/management/attributes/add?c=${id}@@${hash}`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
                    <FontAwesome5 icon='plus' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </SectionTitle>
      </AccordionTitle>
      <AccordionBody>
        {!hasAttributes && (
          <Alert
            label={t('attributes.page.noAttributes.text')}
            theme='no-content'
            noOffset
          />
        )}
        {!!hasAttributes && attributes.map(attribute => (
          <AttributesListItem key={attribute.id} attribute={attribute} onClick={onAttributeClick} />
        ))}
      </AccordionBody>
    </Accordion>
  )
}

export default AttributeCategory
