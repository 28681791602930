import React from 'react'
import {
  Link,
  SectionTitle,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  DnDDroppable,
  Form,
  Error,
  t
} from '../../Common'
import { truncateText } from '../../Utils'
import { PopupTriggerWrapper } from '../../Beasts'
import { CustomerFieldsList } from '../../Beauties'

const CustomerFieldsCategory = props => {
  const {
    category,
    pending,
    customerFields = [],
    isDragAndDropMode,
    index,
    isLast,
    areCategoriesExpanded,
    enterpriseId,
    globalDataFieldsWrite,
    globalDataFieldsDelete
  } = props

  const classNames = ['ta-customer-fields__category']
  const isGlobal = enterpriseId && category.internalId
  if (index === 0) classNames.push('first')
  if (pending) classNames.push('pending')
  const isDefaultCategory = category.isDefault
  if (category.isUpdated) classNames.push('updated')
  if (category.isDeleted) classNames.push('deleted')
  if (isDefaultCategory) classNames.push('default')
  if (isGlobal) classNames.push('global')
  const shouldUseTranslation = category.translationKey && !category.name
  const categorName = shouldUseTranslation ? t(category.translationKey) : category.name
  const categorNameLimit = isDefaultCategory ? 28 : 40

  return (
    <div className={classNames.join(' ')}>
      <SectionTitle
        label={truncateText(categorName, categorNameLimit, true)}
        extraText={isDefaultCategory ? t('global.defaultCategory.hint') : ''}
        icon='folder'
      >
        {((globalDataFieldsDelete && !isDragAndDropMode && !isDefaultCategory) &&
          <PopupTriggerWrapper
            name='customer-field-category-delete'
            position={isLast ? 'top' : 'bottom'}
            id={category.id}
          >
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {t('global.category.delete')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Link className='ta-btn ta-btn-secondary ta-btn-icon-only' external>
                  <FontAwesome5 icon='trash' />
                </Link>
              </HoverPopupTrigger>
            </HoverPopup>
          </PopupTriggerWrapper>
        )}
        {(globalDataFieldsWrite && isDragAndDropMode && !areCategoriesExpanded && !isDefaultCategory &&
          <Link className='ta-btn ta-btn-secondary ta-btn-icon-only ta-grab'>
            <FontAwesome5 icon='arrows-v' />
          </Link>
        )}
        {((globalDataFieldsWrite && !isDragAndDropMode && !isDefaultCategory) &&
          <>
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>{t('buttons.editCategory.tooltip')}</HoverPopupContent>
              <HoverPopupTrigger>
                <Link to={`/customers/customer-fields/categories/${category.id}/edit`} className='ta-btn ta-btn-secondary ta-btn-icon-only'>
                  <FontAwesome5 icon='pencil' />
                </Link>
              </HoverPopupTrigger>
            </HoverPopup>
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {t('customerFields.list.buttonNewField.tooltip')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Link to={`/customers/customer-fields/add?c=${category.id}`} className='ta-btn ta-btn-primary ta-btn-icon-only'>
                  <FontAwesome5 icon='plus' />
                </Link>
              </HoverPopupTrigger>
            </HoverPopup>
          </>
        )}
      </SectionTitle>
      <DnDDroppable id={category.id} type='customerField' isDropDisabled={!!isDefaultCategory}>
        <CustomerFieldsList
          customerFields={customerFields}
          isDragAndDropMode={isDragAndDropMode}
          areCategoriesExpanded={areCategoriesExpanded}
          enterpriseId={enterpriseId}
        />
        <Form name={`customerFieldCategory${category.id}`}>
          <Error name='globalErrors' noTopOffset />
        </Form>
      </DnDDroppable>
    </div>
  )
}

export default CustomerFieldsCategory
