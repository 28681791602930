import React from 'react'
import { WeekDayWrapper } from '../../Beasts'
import { sortWeekDays } from '../../Utils'

import './WeekDays.css'

const WeekDays = props => {
  const { prefix, isAvailabilityDisabled, intervalGapMessage } = props
  const availableDays = sortWeekDays([...Array(7).keys()])

  return (
    <div className='ta-week-days'>
      {availableDays.map(day => (
        <WeekDayWrapper
          key={day}
          day={day}
          prefix={prefix}
          isAvailabilityDisabled={isAvailabilityDisabled}
          intervalGapMessage={intervalGapMessage}
        />
      ))}
    </div>
  )
}

export default WeekDays
