import React from 'react'
import {
  HoverPopupContent,
  HoverPopupTrigger,
  SectionTitle,
  FormSection,
  BorderedBox,
  HoverPopup,
  Checkbox,
  FormText,
  Row,
  Col,
  t
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'
import { CourseRemindersSelect, CourseOtherRecipientsSelect } from '../../../../Beauties'
import './BranchesGroupForm.css'

const BranchesGroupFormReminders = props => {
  const {
    plan,
    courseCustomReminderCustomerSwitch,
    courseReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    courseCustomReminderStaffSwitch,
    courseReminderStaffSwitch,
    courseReminderStaffOthersCheckBox,
    courseReminderStaffOthers,
    resourceEmailRemindersMinutes,
    allowedSet
  } = props

  return (
    <FormSection>
      <SectionTitle label={t('servicesGroups.reminder.heading')} icon='bell' ignoreTopOffset />
      <Row>
        <Col>
          <Row>
            <Col>
              <FormText noOffset>{dangerousHTML(t('servicesGroups.reminder.questionText'))}</FormText>
            </Col>
          </Row>
          <Row>
            <Col>
              <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('customerEmailRemindersMinutes')}>
                <HoverPopupContent position='top'>
                  {!allowedSet.includes('customerEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('customerEmailRemindersMinutes')} name='courseCustomReminderCustomerSwitch' label={t('servicesGroups.reminder.customers.custom.checkbox.label')} theme='switch' />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
          {(courseCustomReminderCustomerSwitch &&
            <BorderedBox>
              <Row>
                <Col>
                  <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('customerEmailRemindersMinutes')}>
                    <HoverPopupContent position='top'>
                      {!allowedSet.includes('customerEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('customerEmailRemindersMinutes')} name='courseReminderCustomerSwitch' label={t('servicesGroups.reminder.customers.checkbox.label')} theme='switch' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(courseReminderCustomerSwitch &&
                <Row className='ta-row__no-margin'>
                  <Col>
                    <CourseRemindersSelect
                      formName='branchesGroup'
                      isDisabled={plan !== 'ENTERPRISE' || !allowedSet.includes('customerEmailRemindersMinutes')}
                      disabledKey={!allowedSet.includes('customerEmailRemindersMinutes') ? 'globalSettings.form.section.attributes.disabled' : undefined}
                      reminders={customerEmailRemindersMinutes}
                      name='customerEmailRemindersMinutes'
                    />
                  </Col>
                </Row>
              )}
            </BorderedBox>
          )}
          <Row>
            <Col>
              <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('resourceEmailRemindersMinutes')}>
                <HoverPopupContent position='top'>
                  {!allowedSet.includes('resourceEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('resourceEmailRemindersMinutes')} name='courseCustomReminderStaffSwitch' label={t('servicesGroups.reminder.staff.custom.checkbox.label')} theme='switch' />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
          {(courseCustomReminderStaffSwitch &&
            <BorderedBox>
              <Row>
                <Col>
                  <HoverPopup disabled={plan === 'ENTERPRISE' && allowedSet.includes('resourceEmailRemindersMinutes')}>
                    <HoverPopupContent position='top'>
                      {!allowedSet.includes('resourceEmailRemindersMinutes') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox disabled={plan !== 'ENTERPRISE' || !allowedSet.includes('resourceEmailRemindersMinutes')} name='courseReminderStaffSwitch' label={t('servicesGroups.reminder.staff.checkbox.label')} theme='switch' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(courseReminderStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <BorderedBox className='ta-courses__form--outer-box'>
                        <Row>
                          <Col>
                            <Checkbox disabled name='courseReminderStaffOwnerCheckBox' label={t('global.owner')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox disabled name='courseReminderStaffResourcesCheckBox' label={t('servicesGroups.resources')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox disabled name='courseReminderStaffOthersCheckBox' label={t('servicesGroups.others')} />
                          </Col>
                        </Row>
                        {(courseReminderStaffOthersCheckBox &&
                          <CourseOtherRecipientsSelect
                            recipients={courseReminderStaffOthers}
                            name='courseReminderStaffOthers'
                          />
                        )}
                      </BorderedBox>
                    </Col>
                  </Row>
                  <CourseRemindersSelect
                    formName='branchesGroup'
                    isDisabled={plan !== 'ENTERPRISE' || !allowedSet.includes('resourceEmailRemindersMinutes')}
                    disabledKey={!allowedSet.includes('customerEmailRemindersMinutes') ? 'globalSettings.form.section.attributes.disabled' : undefined}
                    reminders={resourceEmailRemindersMinutes}
                    name='resourceEmailRemindersMinutes'
                  />
                </BorderedBox>
              )}
            </BorderedBox>
          )}
        </Col>
      </Row>
    </FormSection>
  )
}

export default BranchesGroupFormReminders
