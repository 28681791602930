import {
  Alert,
  BorderedBox,
  Checkbox,
  Col,
  Error,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  Form,
  FormButtons,
  FormSection,
  FormText,
  HintText,
  Input,
  Link,
  MultipleFieldsSelect,
  MultipleSelect,
  Radio,
  Row,
  SectionTitle,
  SingleSelect,
  Textarea,
  Title,
  t
} from '../../../Common'
import { attributeFieldTypes } from '../../../Settings'
import { UPLOAD_FILE_FORMATS } from '../../../Settings/settings'
import { dangerousHTML, sortBy } from '../../../Utils'

import './AttributeForm.css'

const AttributeForm = ({
  title,
  type,
  onSubmit,
  isEditing,
  cancelLink,
  hasTooltip,
  categories,
  fileUploadTypes,
  showExternalIds,
  hasExplanation
}) => {
  const fieldTypes = attributeFieldTypes
    .map(item => ({ value: item.value, label: t(item.label) }))
    .sort(sortBy('label'))
  const showSelectOptions = ['SELECT', 'SELECT_MULTI'].includes(type)
  const showUploadOptions = type === 'FILES'
  const categoryOptions = categories.map(({ id, name }) => ({ value: id, label: name }))

  return (
    <div className='ta-attributes__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-services__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='attribute'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <SectionTitle label={t('global.details')} icon='file' />
              <Row>
                <Col>
                  <Input
                    name='name'
                    label={t('global.name.label')}
                    hintText={t('attributes.form.name.input.hint')}
                    limit={50}
                    mandatory
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SingleSelect
                    name='category'
                    label={t('global.category')}
                    options={categoryOptions}
                    mandatory
                    searchable
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SingleSelect
                    name='type'
                    label={t('attributes.form.type.selector.label')}
                    options={fieldTypes}
                    disabled={isEditing}
                    mandatory
                    searchable
                  />
                </Col>
              </Row>
              {(showSelectOptions &&
                <BorderedBox className='ta-attributes__form__select-options'>
                  <Title label={t('global.values')} isCompact />
                  <Row>
                    <Col>
                      <MultipleFieldsSelect hasOrdering name='selectOptions' label={t('global.value')} limit={40} maxLength={255} />
                    </Col>
                  </Row>
                </BorderedBox>
                )}
              {(showUploadOptions &&
                <BorderedBox className='ta-attributes__form__upload-settings'>
                  <Title label={t('attributes.form.upload.settings.title')} isCompact />
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <FormText bold noOffset>{t('attributes.form.upload.settings.radio.label')}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='isMultipleFile'
                            value={false}
                            label={t('attributes.form.upload.settings.radio.single')}
                            disabled={isEditing}
                          />
                        </Col>
                      </Row>
                      <Row noOffset>
                        <Col>
                          <Radio
                            name='isMultipleFile'
                            value
                            label={t('attributes.form.upload.settings.radio.multiple')}
                            disabled={isEditing}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='relative'>
                      <Row>
                        <Col>
                          <FormText bold noOffset>{t('attributes.form.upload.settings.select.text')}</FormText>
                        </Col>
                      </Row>
                      <MultipleSelect
                        name='fileUploadTypes'
                        label={t('attributes.form.upload.settings.select.label')}
                        options={UPLOAD_FILE_FORMATS}
                        searchable
                        hasAll
                        mandatory
                      />
                      <HintText>{dangerousHTML(t('attributes.form.upload.settings.select.hint'))}</HintText>
                    </Col>
                  </Row>
                </BorderedBox>
                )}
            </FormSection>
            <FormSection>
              <SectionTitle label={t('global.accessibility')} icon='eye' />
              <Row>
                <Col>
                  <Checkbox
                    theme='switch'
                    name='showInWidget'
                    label={t('attributes.form.showInWidget.checkbox.title')}
                  />
                  <HintText>
                    {t('attributes.form.showInWidget.checkbox.hint')}
                  </HintText>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('global.settings')} icon='cogs' />
              <Row>
                <Col>
                  <Checkbox
                    name='hasExplanation'
                    label={t('attributes.form.showExplanation.checkbox.title')}
                  />
                  <HintText>
                    {t('attributes.form.showExplanation.checkbox.hint')}
                  </HintText>
                </Col>
              </Row>
              {hasExplanation && (
                <BorderedBox>
                  <Row>
                    <Col>
                      <Textarea
                        name='explanationText'
                        label={t('attributes.form.explanationText.label')}
                        limit={400}
                        mandatory
                      />
                    </Col>
                  </Row>
                </BorderedBox>
              )}
              <Row>
                <Col>
                  <Checkbox
                    name='hasTooltip'
                    label={t('attributes.form.showTooltip.checkbox.title')}
                  />
                  <HintText>
                    {t('attributes.form.showTooltip.checkbox.hint')}
                  </HintText>
                </Col>
              </Row>
              {hasTooltip && (
                <BorderedBox>
                  <Row>
                    <Col>
                      <Textarea
                        name='tooltipText'
                        label={t('attributes.form.tooltipText.label')}
                        limit={400}
                        mandatory
                      />
                    </Col>
                  </Row>
                </BorderedBox>
              )}
            </FormSection>
            <FormSection>
              <SectionTitle label={t('global.branches')} icon='building' />
              <Alert theme='alert' label={t('attributes.category.form.branches.alert.title')}>
                {t('attributes.form.branches.alert.text')}
              </Alert>
              <MultipleSelect
                hasAll
                mandatory
                searchable
                name='location'
                label={t('global.select')}
                allLabel={t('global.allLocations')}
                disableSelected={isEditing}
              />
            </FormSection>
            <Row>
              <Col>
                <Error name='attributeGlobalError' />
              </Col>
            </Row>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary ta-attributes__category-form__submit-btn'>
                <FontAwesome5 icon='save' />
                {t('buttons.saveChanges.label')}
              </button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default AttributeForm
