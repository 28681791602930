
import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  Title,
  FontAwesome5,
  Link,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t,
  Form,
  Row,
  Col,
  Input,
  SingleSelect
} from '../../Common'

const UsersTitle = props => {
  const { usersWrite, userGroups } = props
  const classNames = ['ta-users__title']

  return (
    <div className={classNames.join(' ')}>
      <Title label={t('global.users')}>
        <PopupTriggerWrapper name='users-help-box' position='bottom' extraClassName='ta-help-box'>
          <Link className='ta-title__link' external>
            {t('global.heading.help')} <FontAwesome5 icon='lightbulb' type='r' />
          </Link>
        </PopupTriggerWrapper>
        {(usersWrite &&
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('users.title.add')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Link to='/managers/users/add' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                <FontAwesome5 icon='plus' type='s' />
              </Link>
            </HoverPopupTrigger>
          </HoverPopup>
        )}
      </Title>
      <Form name='usersFilters'>
        <Row>
          <Col size={50}>
            <Input name='search' label={t('users.list.search.placeholder')} clearable />
          </Col>
          <Col size={50}>
            <SingleSelect
              name='userGroup'
              label={t('global.userGroup.label')}
              options={userGroups}
              clearable
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default UsersTitle
