import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Loader,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Alert,
  t
} from '../../Common'
import { InvoicesList } from '../../Beauties'

import './Invoices.css'

const Invoices = props => {
  const {
    pending,
    invoices,
    currency,
    pendingDownload,
    onClickInvoiceLink,
    message,
    downloadFile
  } = props

  return (
    <div className='ta-invoices'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-invoices__title'>
            <Title label={t('global.invoices')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Loader active={pending} />
          {(message &&
            <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
              {message.text && t(message.text)}
            </Alert>
          )}
          {(!pending && !message &&
            <InvoicesList
              invoices={invoices}
              currency={currency}
              pendingDownload={pendingDownload}
              onClickInvoiceLink={onClickInvoiceLink}
              downloadFile={downloadFile}
            />
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

Invoices.propTypes = {
  pending: PropTypes.bool.isRequired,
  message: PropTypes.object
}

export default Invoices
