import React from 'react'
import {
  FormSection,
  SectionTitle,
  t
} from '../../../Common'
import { AppsListItemWrapper } from '../../../Beasts'
import { highlight } from '../../../Utils'

const AppsListCategory = props => {
  const {
    category,
    activeTab,
    search
  } = props
  const categoryName = t(`apps.list.categories.${category.name}`)

  return (
    <FormSection>
      <SectionTitle
        icon='folder'
        fullLabel={categoryName}
        label={highlight(categoryName, search)}
      />
      {category.items.map(app => (
        <AppsListItemWrapper
          key={app.id}
          app={app}
          activeTab={activeTab}
        />
      ))}
    </FormSection>
  )
}

export default AppsListCategory
