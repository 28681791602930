import {
  SectionTitle,
  Input,
  Link,
  Form,
  Row,
  Col,
  t,
  FormText
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAttributeDelete = ({ approve, reject }) => {
  return (
    <div>
      <SectionTitle
        label={dangerousHTML(t('resources.preview.heading.buttonDelete.tooltip.title'))}
        icon='trash-alt'
        ignoreTopOffset
      />
      <Form name='attributeDelete' onSubmit={approve}>
        <Row>
          <Col>
            <FormText>
              {t('attributes.delete.text')}
            </FormText>
          </Col>
        </Row>
        <Row>
          <Col className='relative'>
            <Input
              name='name'
              label={t('global.name')}
              hintText={t('attributes.delete.hintText')}
              mandatory
              autoFocus
            />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Link className='ta-btn ta-btn-secondary' onClick={reject}>
            {t('buttons.cancel.label')}
          </Link>
          <button type='submit' className='ta-btn ta-btn-primary'>
            {t('global.delete')}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default PopupAttributeDelete
