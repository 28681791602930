import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Menu } from '../../Beauties'
import { menu } from '../../Settings'

const MenuWrapper = props => {
  let {
    router,
    customisations,
    hideMenuCallCentre,
    isBillingRead,
    notifications
  } = props
  const { systemMessagesCount } = notifications || {}
  router = router || {}
  const { name: route } = router || {}
  const {
    hideMenuGlobalCustomers,
    hideMenuGlobalCustomersCustomers,
    hideMenuGlobalCustomersCustomerFields,
    hideMenuGlobalCustomersTags,
    hideMenuNotifications,
    hideMenuActivity,
    showDataAttributes
  } = customisations?.settings || {}
  // Customisations
  const exceptions = []
  if (hideMenuGlobalCustomers) exceptions.push('globalCustomers')
  if (hideMenuGlobalCustomersCustomers) exceptions.push('customers')
  if (hideMenuGlobalCustomersCustomerFields) exceptions.push('customerFields')
  if (hideMenuGlobalCustomersTags) exceptions.push('companyTags')
  if (hideMenuCallCentre) exceptions.push('loginCallCentre')
  if (hideMenuNotifications) exceptions.push('notifications')
  if (hideMenuActivity) exceptions.push('activity')
  if (!showDataAttributes) exceptions.push('attributes')

  // Chargebee
  if (!isBillingRead) {
    exceptions.push('billing')
    exceptions.push('invoices')
  }

  const filteredMenu = menu
    .filter(item => !exceptions.includes(item.id))
    .map(item => {
      const result = {
        ...item,
        notifications: item?.id === 'notifications' ? systemMessagesCount : 0,
        submenu: item.submenu
          ? item.submenu.filter(subItem => !exceptions.includes(subItem.id))
          : null
      }
      if (result.submenu && result.submenu.length === 1) {
        const subItem = result.submenu[0] || {}
        result.to = subItem.to
        result.routes = [...(result.routes || []), ...(subItem.routes || [])]
        result.submenu = null
      }
      return result
    })

  const onClickMenuItem = to => {
    let { router, formsWithUnsavedChanges } = props
    router = router || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    let { props: routerProps } = router || {}
    routerProps = routerProps || {}
    const { formDiscard } = routerProps || {}
    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, to)
    }
    handlers.navigateToPath(to)
  }

  return (
    <Menu menu={filteredMenu} route={route} onClickMenuItem={onClickMenuItem} />
  )
}

MenuWrapper.propTypes = {
  route: PropTypes.string
}

const maps = state => {
  const { id } = state.company || {}
  return {
    notifications: selectors.notificationsSelector(state),
    router: state.router,
    customisations: selectors.customisationsSelector(state),
    formsWithUnsavedChanges: state.forms.formsWithUnsavedChanges,
    isBillingRead: state.account && state.account.isBillingRead,
    hideMenuCallCentre: !state.account.userAccounts.find(item => {
      let { authScope, enterprise, expiresAt, isConfirmed } = item || {}
      enterprise = enterprise || {}
      return authScope === 'ENTERPRISE_CALL_CENTRE' && !expiresAt && enterprise.id === id && isConfirmed
    })
  }
}

export default connect(maps)(MenuWrapper)
