import React, { memo } from 'react'
import {
  Link,
  SectionTitle,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  DnDDroppable,
  Form,
  Error,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { CoursesList } from '../../Beauties'
import { truncateText } from '../../Utils'

const CoursesCategory = props => {
  const {
    category,
    pending,
    courses = [],
    isDragAndDropMode,
    index,
    areCategoriesExpanded,
    enterpriseId,
    globalGroupsWrite,
    globalGroupsDelete
  } = props
  const classNames = ['ta-courses__category']
  if (index === 0) classNames.push('first')
  if (pending) classNames.push('pending')
  if (category.isUpdated) classNames.push('updated')
  if (category.isDeleted) classNames.push('deleted')
  const isDefaultCategory = category.isDefault
  const categoryName = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name
  const categoryNameLimit = isDefaultCategory ? 28 : 40

  return (
    <div key={category.id} className={classNames.join(' ')}>
      <SectionTitle
        label={truncateText(categoryName, categoryNameLimit, true)}
        extraText={isDefaultCategory ? t('global.defaultCategory.hint') : ''}
        icon='folder'
      >
        {(globalGroupsWrite &&
          <>
            {(globalGroupsDelete && !isDragAndDropMode && !isDefaultCategory &&
              <PopupTriggerWrapper
                name='course-category-delete'
                position={index === 0 ? 'bottom' : 'top'}
                id={category.id}
              >
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('global.category.delete')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link
                      className='ta-btn ta-btn-secondary ta-btn-icon-only'
                      external
                    >
                      <FontAwesome5 icon='trash' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              </PopupTriggerWrapper>
            )}
            {(isDragAndDropMode && !areCategoriesExpanded && !isDefaultCategory &&
              <Link className='ta-btn ta-btn-secondary ta-btn-icon-only'>
                <FontAwesome5 icon='arrows-v' />
              </Link>
            )}
            {(!isDragAndDropMode &&
              <>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('buttons.editCategory.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link
                      to={`/management/courses/categories/${category.id}/edit`}
                      className='ta-btn ta-btn-secondary ta-btn-icon-only'
                    >
                      <FontAwesome5 icon='pencil' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('courses.list.buttonAddCourse.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link
                      to={`/management/courses/add?c=${category.id}`}
                      className='ta-btn ta-btn-primary ta-btn-icon-only'
                    >
                      <FontAwesome5 icon='plus' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </>
        )}
      </SectionTitle>
      <DnDDroppable id={category.id} type='courses'>
        <CoursesList
          courses={courses}
          isDragAndDropMode={isDragAndDropMode}
          areCategoriesExpanded={areCategoriesExpanded}
          enterpriseId={enterpriseId}
          categoryIndex={category.orderIndex}
        />
        <Form name={`courseCategory${category.id}`}>
          <Error name='globalErrors' noTopOffset />
        </Form>
      </DnDDroppable>
    </div>
  )
}

export default memo(CoursesCategory)
