import React from 'react'
import {
  FormSection,
  SectionTitle,
  Row,
  Col,
  BorderedBox,
  Radio,
  Checkbox,
  Alert,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'

import { dangerousHTML } from '../../../../Utils'

const BranchesGroupFormSettings = props => {
  const {
    hasOnlinePayment,
    hasStripeConnected,
    customisations,
    allowedSet
  } = props
  const hasOnlinePaymentDisabled = !hasStripeConnected && !hasOnlinePayment
  const { settings } = customisations || {}
  const { hideServicesFormOnlinePayment } = settings || {}

  const isIsBookableDisabled = !allowedSet?.includes('isBookable')
  const isHasOnlinePaymentDisabled = !allowedSet?.includes('hasOnlinePayment')
  const isIsPaymentMandatoryDisabled = !allowedSet?.includes('isPaymentMandatory')

  return (
    <FormSection>
      <SectionTitle label={t('branches.form.group.edit.settings.title')} icon='cogs' />
      <Row>
        <Col>
          <HoverPopup disabled={!isIsBookableDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox disabled={isIsBookableDisabled} name='isActive' label={t('branches.form.group.edit.settings.onlineBookable')} />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      {(!hideServicesFormOnlinePayment &&
        <>
          <Row>
            <Col>
              <HoverPopup disabled={!isHasOnlinePaymentDisabled} className='ta-service-form-inputs__popup'>
                <HoverPopupContent position='top'>
                  {t('globalSettings.form.section.attributes.disabled')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox
                    name='hasOnlinePayment'
                    label={t('branches.form.group.edit.settings.activateOnlinePayment.label')}
                    disabled={hasOnlinePaymentDisabled || isHasOnlinePaymentDisabled}
                  />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
          {(hasOnlinePayment &&
            <BorderedBox>
              <HoverPopup disabled={!isIsPaymentMandatoryDisabled} className='ta-service-form-inputs__popup'>
                <HoverPopupContent position='top'>
                  {t('globalSettings.form.section.attributes.disabled')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Row>
                    <Col>
                      <Radio disabled={hasOnlinePaymentDisabled || isIsPaymentMandatoryDisabled} name='isOnlinePaymentMandatory' value={false} label={t('branches.form.group.edit.settings.paymentMandatory.optional.label')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio disabled={hasOnlinePaymentDisabled || isIsPaymentMandatoryDisabled} name='isOnlinePaymentMandatory' value={!!true} label={t('branches.form.group.edit.settings.paymentMandatory.mandatory.label')} />
                    </Col>
                  </Row>
                </HoverPopupTrigger>
              </HoverPopup>
            </BorderedBox>
          )}
        </>
      )}
      {(!hasStripeConnected && !hasOnlinePayment && !hideServicesFormOnlinePayment &&
        <Alert noOffset label={t('branches.form.group.edit.settings.stripeNotConnected.label')} theme='alert'>
          <p>{dangerousHTML(t('branches.form.group.edit.settings.stripeNotConnected.message'))}</p>
        </Alert>
      )}
      {(!hasStripeConnected && hasOnlinePayment && !hideServicesFormOnlinePayment &&
        <Alert noOffset label={t('branches.form.group.edit.settings.stripeNotConnectedWithPayment.label')} theme='alert'>
          <p>{dangerousHTML(t('branches.form.group.edit.settings.stripeNotConnectedWithPayment.message'))}</p>
        </Alert>
      )}
    </FormSection>
  )
}

export default BranchesGroupFormSettings
