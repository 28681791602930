import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Navigation } from '../../Beauties'

class NavigationWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.expand = this.expand.bind(this)
    this.collapse = this.collapse.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.state = { isHover: false }
  }

  expand () {
    handlers.navigationExpand()
  }

  collapse () {
    handlers.navigationCollapse()
  }

  onMouseEnter () {
    this.setState({ isHover: true })
  }

  onMouseLeave () {
    this.setState({ isHover: false })
  }

  render () {
    const { isHover } = this.state
    const {
      isCollapsed,
      sidebar,
      accounts,
      route,
      customisations
    } = this.props
    const disabled = !(['home'].includes(route))
    const accountsCount = accounts.length

    return (
      <Navigation
        isCollapsed={isCollapsed}
        negativeLevel={sidebar.negative && sidebar.level}
        accountsCount={accountsCount}
        isHover={isHover}
        expand={this.expand}
        collapse={this.collapse}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        disabled={disabled}
        customisations={customisations}
      />
    )
  }
}

PropTypes.propTypes = {
  status: PropTypes.bool.isRequired
}

const maps = state => ({
  route: state.router.name,
  isCollapsed: state.navigation.isCollapsed || false,
  sidebar: state.router.props.sidebar || {},
  customisations: selectors.customisationsSelector(state),
  accounts: state.account.accounts || []
})

export default connect(maps)(NavigationWrapper)
