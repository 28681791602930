import React from 'react'

import {
  Title,
  PlanBadge,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  t
} from '../../Common'

const AppsSkeleton = (props) => {
  const {
    name,
    version,
    plan,
    headerImage,
    headerText,
    children,
    className,
    footer
  } = props
  const classNames = ['ta-apps__skeleton']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-apps__title'>
            <Title label={name || t('apps.appDetails')}>
              <div className='ta-apps__skeleton__head-version'>v {version}</div>
              <PlanBadge plan={plan || 'CLASSIC'} />
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          {headerImage && (
            <img className='ta-apps__skeleton__header-image' src={headerImage} alt='header' />
          )}
          <div className='ta-apps__skeleton__container-box'>
            {headerText && (
              <div className='ta-apps__skeleton__header-text'>
                {headerText}
              </div>
            )}
            {children}
          </div>
          {!!footer && (
            <div className='ta-apps__skeleton__footer'>
              {footer}
            </div>
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default AppsSkeleton
