import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Form,
  FormButtons,
  Input,
  t
} from '../../Common'

const ForgottenPasswordForm = props => {
  const { onSubmit } = props

  return (
    <div className='ta-forgotten-password-form'>
      <Form onSubmit={onSubmit} name='login'>
        <Row>
          <Col>
            <Input
              name='email'
              label={t('global.email.label')}
              mandatory
              hideError
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormButtons>
              <button type='submit' className='ta-btn ta-btn-primary ta-btn-block'>{t('global.passwordRequest')}</button>
            </FormButtons>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

ForgottenPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired

}

export default ForgottenPasswordForm
