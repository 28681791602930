export default [{
  value: 'TEXT',
  label: 'customerFields.fieldType.text'
}, {
  value: 'SELECT',
  label: 'customerFields.fieldType.select'
}, {
  value: 'SELECT_MULTI',
  label: 'attributeFields.fieldType.multiselect'
}, {
  value: 'TEXTAREA',
  label: 'customerFields.fieldType.textarea'
}, {
  value: 'FILES',
  label: 'customerFields.fieldType.upload'
}]
