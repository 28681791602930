import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  Row,
  Col,
  SectionTitle,
  AccordionList,
  AccordionListTitle,
  AccordionListBody,
  FontAwesome5,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  IconListItemButtons,
  StatusBox,
  Price,
  Spinner,
  Link,
  Alert,
  format,
  t
} from '../../../Common'
import { BranchesPreviewServicesFilter } from '../../../Beauties'
import { PopupTriggerWrapper } from '../../../Beasts'
import { convertMinutesToDaysHoursMinutes, dangerousHTML, truncateText, highlight } from '../../../Utils'
import './BranchesPreviewServices.css'

const BranchesPreviewServices = props => {
  let {
    isPending,
    categoriesWithServices,
    filter,
    branch,
    showImportMessage,
    branchesWrite,
    branchesDelete,
    hideBranchEditServiceBtn,
    hideBranchDeleteServiceBtn
  } = props
  branch = branch || {}
  const { id, currency } = branch || {}
  return (
    <div className='ta-branch__preview__services'>
      {(isPending &&
        <Spinner />
      )}
      {(!isPending && showImportMessage &&
        <Alert label={t('branches.importStarted')} theme='success'>
          {t('branches.list.imoportStarted.message', [{ key: 'ENTITY', value: t('global.service') }])}
        </Alert>
      )}
      {(!isPending &&
        <BranchesPreviewServicesFilter branchesWrite={branchesWrite} companyId={id} />
      )}
      {!isPending && categoriesWithServices.map((category, index) => (
        <div key={index}>
          <SectionTitle
            label={category.name === 'default' ? t('branches.preview.services.section.services.title') : category.name}
            icon='folder' ignoreTopOffset={index === 0}
          />
          {category.services.length > 0 && category.services.map(service => {
            const {
              id: serviceId,
              externalId,
              internalId,
              companyId,
              userId,
              name,
              createdAt,
              updatedAt,
              isBookable,
              hasOnlineWarning,
              hasOnlinePayment,
              price,
              duration,
              resourcesCount,
              color,
              isCombination
            } = service
            const translations = {
              minutes: t('global.minutes'),
              hours: t('global.hours'),
              days: t('global.days')
            }
            const extraInfo = []
            if (userId) extraInfo.push(renderToString(<FontAwesome5 icon='user' />))
            extraInfo.push((isBookable && !hasOnlineWarning) ? t('global.enabled') : t('global.disabled'))

            return (
              <AccordionList key={service.id} expand={false}>
                <AccordionListTitle>
                  <span>{highlight(truncateText(name, 40, true) || '', filter)}</span>
                  {dangerousHTML(extraInfo.join('&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;'))}
                </AccordionListTitle>
                <AccordionListBody>
                  <Row>
                    <Col size={60}>
                      <SectionTitle
                        label={t('branches.preview.services.section.details.title')}
                        noIcon
                        ignoreTopOffset
                      />
                      {(serviceId &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>ID:</IconListItemTitle>
                          <IconListItemBody offset='160px'>{serviceId}</IconListItemBody>
                        </IconListItem>
                      )}
                      {(externalId &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.externalId')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>{externalId}</IconListItemBody>
                        </IconListItem>
                      )}
                      {(name &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.name')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>{name}</IconListItemBody>
                        </IconListItem>
                      )}
                      <IconListItem>
                        <IconListItemTitle width='160px'>{t('global.type')}:</IconListItemTitle>
                        <IconListItemBody offset='160px'>{isCombination ? t('servicesGroups.list.serviceCombination.headline') : t('global.service')}</IconListItemBody>
                      </IconListItem>
                      {(resourcesCount > 0 &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.resources')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>{resourcesCount}</IconListItemBody>
                        </IconListItem>
                      )}
                      {(price > 0 &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.price')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>
                            <Price price={price} currency={currency} />
                          </IconListItemBody>
                        </IconListItem>
                      )}
                      {(color &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.color')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>
                            <FontAwesome5 icon='square' type='solid' color={color} />&nbsp;
                            {color}
                          </IconListItemBody>
                        </IconListItem>
                      )}
                      {(createdAt &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.createdAt')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>{format(createdAt, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}</IconListItemBody>
                        </IconListItem>
                      )}
                      {(updatedAt &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.updatedAt')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>{format(updatedAt, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}</IconListItemBody>
                        </IconListItem>
                      )}
                      {(duration &&
                        <IconListItem>
                          <IconListItemTitle width='160px'>{t('global.duration')}:</IconListItemTitle>
                          <IconListItemBody offset='160px'>
                            {convertMinutesToDaysHoursMinutes(duration, translations)}
                          </IconListItemBody>
                        </IconListItem>
                      )}
                    </Col>
                    <Col>
                      <SectionTitle label={t('branches.preview.resources.section.onlineStatus.title')} noIcon ignoreTopOffset />
                      <IconListItem>
                        <IconListItemTitle width='180px'>{t('global.onlineBookable')}:</IconListItemTitle>
                        <IconListItemBody offset='180px'>
                          <StatusBox status={hasOnlineWarning ? 'yellow' : isBookable ? 'green' : 'grey'}>
                            {isBookable ? t('global.yes') : t('global.no')}
                          </StatusBox>
                        </IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='180px'>{t('global.payments')}:</IconListItemTitle>
                        <IconListItemBody offset='180px'>
                          <StatusBox status={hasOnlinePayment ? 'green' : 'red'}>
                            {hasOnlinePayment ? t('global.yes') : t('global.no')}
                          </StatusBox>
                        </IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='180px'>{t('global.canBeOnline')}:</IconListItemTitle>
                        <IconListItemBody offset='180px'>
                          <StatusBox status={hasOnlineWarning ? 'red' : 'green'}>
                            {hasOnlineWarning ? t('global.no') : t('global.yes')}
                          </StatusBox>
                        </IconListItemBody>
                      </IconListItem>
                    </Col>
                  </Row>
                  {(branchesWrite && (!hideBranchEditServiceBtn || !hideBranchDeleteServiceBtn) &&
                    <Row>
                      <Col>
                        <SectionTitle label={t('global.actions')} icon='directions' />
                        {(!hideBranchEditServiceBtn &&
                          <IconListItem>
                            <IconListItemTitle>{t('branches.preview.services.section.edit.title')}:</IconListItemTitle>
                            <IconListItemButtons>
                              <Link className='ta-btn ta-btn-primary ta-btn-icon-only' to={`/branches/${branch.id}/services/${serviceId}/${isCombination ? 'edit-combination' : 'edit'}?c=${category.id}`}>
                                <FontAwesome5 icon='pencil' type='solid' />
                              </Link>
                            </IconListItemButtons>
                          </IconListItem>
                        )}
                        {(branchesDelete && !internalId && !hideBranchDeleteServiceBtn &&
                          <IconListItem>
                            <IconListItemTitle>{t('branches.preview.services.section.delete.title')}:</IconListItemTitle>
                            <IconListItemButtons>
                              <PopupTriggerWrapper name='branch-service-delete' position='left' id={{ companyId, id: serviceId, externalId }}>
                                <Link className='ta-btn ta-btn-primary ta-btn-icon-only' external>
                                  <FontAwesome5 icon='trash' type='solid' />
                                </Link>
                              </PopupTriggerWrapper>
                            </IconListItemButtons>
                          </IconListItem>
                        )}
                      </Col>
                    </Row>
                  )}
                </AccordionListBody>
              </AccordionList>
            )
          })}
          {(!categoriesWithServices.length > 0 &&
            <Alert noOffset label={t('branches.preview.services.section.noServices')} theme='no-content' />
          )}
        </div>
      ))}
    </div>
  )
}

export default BranchesPreviewServices
