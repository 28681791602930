import React from 'react'
import { AvailabilityTimelinesGridBackground, AvailabilityTimelinesGridDays } from '../../Common'

const AvailabilityTimelinesGrid = props => {
  const { hoursRange, timelines, isDouble, unavailableTimelines, availabilityTimelineLimits } = props

  return (
    <div className='ta-availability-timelines__grid'>
      <AvailabilityTimelinesGridBackground hoursRange={hoursRange} />
      <AvailabilityTimelinesGridDays
        timelines={timelines}
        isDouble={isDouble}
        unavailableTimelines={unavailableTimelines}
        availabilityTimelineLimits={availabilityTimelineLimits}
      />
    </div>
  )
}

export default AvailabilityTimelinesGrid
