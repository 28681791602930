import React from 'react'
import PropTypes from 'prop-types'
import {
  AppAuthWrapper,
  LoginWrapper,
  ForgottenPasswordWrapper,
  SSOWrapper
} from '../../Beasts'
import { RouteNotFound } from '../../Beauties'

import './App.css'

const App = props => {
  const {
    isAuthenticated,
    hasTokens,
    route,
    isPending,
    hasOverwriteCss
  } = props
  const excepted = ['passwordForgot']
  const classNames = ['ta-app']
  if (hasOverwriteCss) classNames.push('overwrite')

  return (
    <div className={classNames.join(' ')}>
      {route === 'sso' && !isPending && <SSOWrapper />}
      {route === 'passwordForgot' && !hasTokens && !isAuthenticated && <ForgottenPasswordWrapper />}
      {route !== 'notfound' && !excepted.includes(route) && !hasTokens && !isAuthenticated && <LoginWrapper />}
      {route !== 'notfound' && hasTokens && isAuthenticated && <AppAuthWrapper />}
      {route === 'notfound' && <RouteNotFound />}
    </div>
  )
}

App.propTypes = {
  account: PropTypes.object,
  route: PropTypes.string
}

export default App
