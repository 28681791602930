import React from 'react'
import { GoogleStaticMap } from '../../Common'

import './Address.css'

const Address = props => {
  const { address, details = '', size } = props

  return (
    <div className='ta-address'>
      <div className='ta-address__map'>
        <GoogleStaticMap address={address} size={size} />
      </div>
      <div className='ta-address__label'>
        {address}
        {details && `, ${details}`}
      </div>
    </div>
  )
}

export default Address
