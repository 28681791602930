import React from 'react'
import {
  IconListItemTitle,
  IconListItemBody,
  IconListItem,
  UserPreview,
  Link,
  t
} from '../../../Common'

const UserPreviewDetails = props => {
  let {
    name,
    email,
    roles,
    phone,
    avatarUrl,
    hasPermissionTypeAssigned,
    userPermissionTypeAssignedToUser
  } = props
  roles = roles || []
  avatarUrl = avatarUrl || ''
  hasPermissionTypeAssigned = !!hasPermissionTypeAssigned
  const phoneFormated = ((phone && phone.prefix && phone.number) && `+${phone.prefix} (0) ${phone.number}`) || ''
  const { name: permissionTypeName, color: permissionTypeColor } = userPermissionTypeAssignedToUser || {}

  return (
    <div className='ta-users__preview__details'>
      <UserPreview
        hasPermissionTypeAssigned={hasPermissionTypeAssigned}
        name={name}
        avatarUrl={avatarUrl}
        roles={roles}
        permissionTypeName={permissionTypeName}
        permissionTypeColor={permissionTypeColor}
      />
      {(phoneFormated &&
        <IconListItem icon='phone' iconType='s'>
          <IconListItemTitle width='130px'>{t('global.phone.label')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>{phoneFormated}</IconListItemBody>
        </IconListItem>
      )}
      {(email &&
        <IconListItem icon='envelope' iconType='s'>
          <IconListItemTitle width='130px'>{t('global.email.label')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>
            <Link to={`mailto:${email}`} target='_top' external>{email}</Link>
          </IconListItemBody>
        </IconListItem>
      )}
    </div>
  )
}

export default UserPreviewDetails
