import React from 'react'
import moment from 'moment'
import {
  Col,
  Row,
  SimpleBox,
  FontAwesome5,
  SimpleListItem,
  SimpleListItemButtons,
  SimpleListItemTitle,
  SimpleListItemContent,
  ChartAxisTickWithCircle,
  Form,
  SingleSelect,
  OptionsSelect,
  Link,
  ChartAxisTickTruncated,
  Avatar,
  Alert,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../Common'
import { StatisticsCompositeWrapper, StatisticsWrapper } from '../../Beasts'
import { truncateText, dangerousHTML } from '../../Utils'

import './Dashboard.css'

const Dashboard = props => {
  const {
    // Overall statistics
    totalServiceEventsOverall,
    totalServiceEventsWithTitleOverall,
    totalServiceEventsWithTitleAndCustomerOverall,
    totalOnlineServiceEventsWithIdOverall,
    totalInternalServiceEventsWithIdOverall,
    totalServiceEventsWithIdOverall,
    totalServiceEventsWithCustomerOverall,
    totalOnlineServiceEventsWithIdAndCustomerOverall,
    totalInternalServiceEventsWithIdAndCustomerOverall,
    totalServiceEventsWithIdAndCustomerOverall,
    totalCourseEventsOverall,
    topBookingServicesOverall,
    totalCustomersOverall,
    totalInternalCustomersOverall,
    totalOnlineCustomersOverall,
    totalCourseEventsWithTitleOverall,
    totalCourseEventsWithIdOverall,
    totalCourseBookingsWithTitleOverall,
    totalOnlineCourseBookingsWithTitleOverall,
    totalInternalCourseBookingsWithTitleOverall,
    totalCourseBookingsWithIdOverall,
    totalOnlineCourseBookingsWithIdOverall,
    totalInternalCourseBookingsWithIdOverall,
    totalRevenueOverall,
    totalServiceInStorePaidRevenueOverall,
    totalServiceOnlinePaidRevenueOverall,
    totalCourseInStorePaidRevenueOverall,
    totalCourseOnlinePaidRevenueOverall,
    totalCourseBookingsOverall,
    totalServiceRevenueOverall,
    totalCourseRevenueOverall,
    // Normal statistics
    totalServiceEventsWithTitle,
    totalServiceEvents,
    totalOnlineServiceEventsWithId,
    totalInternalServiceEventsWithId,
    totalServiceEventsWithId,
    totalServiceEventsWithTitleAndCustomer,
    totalServiceEventsWithCustomer,
    totalOnlineServiceEventsWithIdAndCustomer,
    totalInternalServiceEventsWithIdAndCustomer,
    totalServiceEventsWithIdAndCustomer,
    totalRescheduledEventsDaily,
    totalRescheduledEventsMonthly,
    totalCourseBookings,
    totalCourseBookingGuests,
    totalCourseBookingGuestsAndParticipants,
    totalCancelledEvents,
    totalCancelledOnlineEvents,
    totalCancelledInternalEvents,
    averageDailyServiceBookings,
    averageWeeklyServiceBookings,
    averageWeeklyCancelledBookings,
    averageServiceBookingsPerResource,
    averageBookingParticipantsPerCourseEvent,
    averageBookingGuestsPerCourseEvent,
    eventsByDaysUTC,
    topBookingServices,
    topBookingResourcesValue,
    topBookingBranches,
    totalCustomers,
    totalOnlineCustomers,
    totalInternalCustomers,
    totalCourseEventsWithTitle,
    totalCourseEventsWithId,
    totalCourseEvents,
    totalOnlinePaidRevenue,
    totalOnlineCourseBookingsWithTitle,
    totalInternalCourseBookingsWithTitle,
    totalCourseBookingsWithTitle,
    totalOnlineCourseBookingsWithId,
    totalInternalCourseBookingsWithId,
    totalCourseBookingsWithId,
    eventSignupsByHoursOfDayUTC,
    eventSignupsByDaysOfWeekUTC,
    eventSignupsByDaysUTC,
    onlineMarketingStatistics,
    totalResourcesDaily,
    totalResourcesMonthly,
    sickDenialsDaysDaily,
    sickDenialsDaysMonthly,
    blockerDenialsDaysDaily,
    blockerDenialsDaysMonthly,
    // WILL GO LIVE IN V2
    // vacationDenialsDaysDaily,
    // vacationDenialsDaysMonthly,
    totalResourcesWorkingHours,
    totalResourcesWorkingBookedHours,
    totalResourcesOnlineHours,
    totalResourcesOnlineBookedHours,
    totalServiceOnlinePaidRevenue,
    totalServiceInStorePaidRevenue,
    totalServiceRevenue,
    totalCourseOnlinePaidRevenue,
    totalCourseInStorePaidRevenue,
    totalCourseRevenue,
    totalRevenue,
    topBookingServicesWithPrice,
    // Filter + branches information
    branchCurrency,
    statisticsLastUpdate,
    dashboardFiltersType,
    areStatisticsForSameMonth,
    dailyStatisticsMonth,
    monthlyStatisticsMonth,
    dashboardFiltersYear,
    isMessageShown,
    setIsMessageShown,
    locale,
    isFirstDayOfMonth,
    isFilteredMonthCurrent,
    filteredMonthLabel,
    onFilterYearSubmit,
    onFilterMonthSubmit,
    onFilterTypeChange,
    onExportClick,
    messageExport
  } = props

  const classNames = ['ta-dashboard']
  const statsDescription = areStatisticsForSameMonth ? t('statistics.thisMonth') : t('statistics.prevMonth')
  const weekdays = moment.weekdays()
  const days = moment.weekdaysShort()
  const dailyStatisticsMonthName = moment(dailyStatisticsMonth, 'MM').format('MMMM')
  const monthlyStatisticsMonthName = moment(monthlyStatisticsMonth, 'MM').format('MMMM')
  const displayLifetimeStatistics = dashboardFiltersType === 'lifetime'

  return (
    <div className={classNames.join(' ')}>
      <Row>
        <Col size={80} className='ta-dashboard__filters-container'>
          <Form name='dashboardFilters' className='ta-statistics-filters'>
            <Row>
              <Col size={20} className='no-right-padding'>
                <SingleSelect
                  name='type'
                  label={t('global.type.label')}
                  onChange={dashboardFiltersType === 'now' ? null : onFilterTypeChange}
                />
              </Col>
              {(!displayLifetimeStatistics &&
                <>
                  <Col size={20} className='no-right-padding'>
                    <SingleSelect
                      name='year'
                      label={t('global.year.label')}
                      onChangeAddon={val => onFilterYearSubmit(val)}
                    />
                  </Col>
                  <Col size={20} className='no-right-padding'>
                    <SingleSelect
                      name='month'
                      label={t('global.month.label')}
                      onChangeAddon={onFilterMonthSubmit}
                    />
                  </Col>
                </>
              )}
              <Col size={20}>
                <Link
                  className='ta-btn ta-statistics__filter-submit-btn ta-info'
                  external
                  onClick={() => setIsMessageShown(true)}
                >
                  <FontAwesome5 icon='info' color='#505a5e' type='s' />
                </Link>
              </Col>
              {(displayLifetimeStatistics &&
                <>
                  <Col size={20} />
                  <Col size={20} />
                </>
              )}
              <Col size={20} />
            </Row>
          </Form>
        </Col>
        <Col className='ta-dashboard-export-btn-container' size={20}>
          <Link
            type='submit'
            className='ta-btn ta-dashboard-export-btn'
            onClick={onExportClick}
          >
            <FontAwesome5 icon='file-export' type='s' />
            {t('global.exportData')}
          </Link>
        </Col>
      </Row>
      {(!!messageExport &&
        <Alert theme='success' label={messageExport} />
      )}
      {(isMessageShown &&
        <Alert
          theme='alert'
          // className={`${(!displayLifetimeStatistics && !isFilteredMonthCurrent && !isFirstDayOfMonth) && 'no-margin'} with-border`}
          noIcon
          noOffset
          label={displayLifetimeStatistics
            ? t('statistics.filter.all')
            : isFilteredMonthCurrent
              ? t('statistics.info.currentMonthToDateHeading')
              : `${filteredMonthLabel} ${dashboardFiltersYear}`}
          hasCloseButton
          onCloseCallback={() => setIsMessageShown(false)}
        >
          {(displayLifetimeStatistics &&
            t('statistics.info.lifetimeSummary', [{ key: 'UPDATE', value: statisticsLastUpdate || '0700' }])
          )}
          {(!displayLifetimeStatistics && isFilteredMonthCurrent &&
            t('statistics.info.currentMonthToDate', [{ key: 'UPDATE', value: statisticsLastUpdate || '0070' }])
          )}
          {((displayLifetimeStatistics || isFilteredMonthCurrent) && isFirstDayOfMonth &&
            <>
              <br />
              <br />
            </>
          )}
          {(isFirstDayOfMonth &&
            dangerousHTML(t('statistics.info.firsDayOfMonthMessage'))
          )}
        </Alert>
      )}

      {(displayLifetimeStatistics &&
        <>
          <Row>
            <Col>
              <div className='ta-dashboard__section'>{t('global.overview')}</div>
              <p className='ta-dashboard__section--subheading'>{t('statistics.overview.info')}</p>
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  addend1: totalServiceEventsWithTitleOverall,
                  sum: totalServiceEventsOverall,
                  equationMiddle: {
                    addend1: totalOnlineServiceEventsWithIdOverall,
                    addend2: totalInternalServiceEventsWithIdOverall,
                    sum: totalServiceEventsWithIdOverall,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  }
                }}
                texts={{
                  title: t('statistics.serviceBookings'),
                  subTitle: t('statistics.services.extraInfo'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.services'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.singleBookings.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  addend1: totalServiceEventsWithTitleAndCustomerOverall,
                  sum: totalServiceEventsWithCustomerOverall,
                  equationMiddle: {
                    addend1: totalOnlineServiceEventsWithIdAndCustomerOverall,
                    addend2: totalInternalServiceEventsWithIdAndCustomerOverall,
                    sum: totalServiceEventsWithIdAndCustomerOverall,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  }
                }}
                texts={{
                  title: t('statistics.singleBookingSignUps'),
                  subTitle: t('statistics.singleBookingSignUps.extraInfo'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.services'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.singleBookingSignUps.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  addend1: totalCourseEventsWithTitleOverall,
                  addend2: totalCourseEventsWithIdOverall,
                  sum: totalCourseEventsOverall
                }}
                texts={{
                  title: t('statistics.groupBookings'),
                  subTitle: t('statistics.groups.addedToCalendar'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.groups'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.groups.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  equationStart: {
                    addend1: totalOnlineCourseBookingsWithTitleOverall,
                    addend2: totalInternalCourseBookingsWithTitleOverall,
                    sum: totalCourseBookingsWithTitleOverall,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  },
                  equationMiddle: {
                    addend1: totalOnlineCourseBookingsWithIdOverall,
                    addend2: totalInternalCourseBookingsWithIdOverall,
                    sum: totalCourseBookingsWithIdOverall,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  },
                  sum: totalCourseBookingsOverall
                }}
                texts={{
                  title: t('statistics.groupsSignUps'),
                  subTitle: t('statistics.groupsSignUps.extraInfo'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.groups'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.groupsSignUps.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                data={(topBookingServicesOverall || []).map(({ name, value, color }) => ({
                  name: (
                    <HoverPopup>
                      <HoverPopupContent>
                        {name}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        {truncateText(name, 40, true)}
                      </HoverPopupTrigger>
                    </HoverPopup>
                  ),
                  value,
                  color
                }))}
                label={t('statistics.mostBooked')}
                headerLabel={t('statistics.servicesAndGroups')}
                type='pie'
                options={{
                  syncId: 'topBookingServicesOverall',
                  containerMinHeight: '234px',
                  tooltip: true,
                  dataKey: 'value',
                  legend: {
                    align: 'right',
                    verticalAlign: 'middle',
                    isBookingsStat: true
                  }
                }}
                statisticId='topBookingServicesOverall'
                bigHeader
              />
            </Col>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  equationStart: {
                    addend1: totalServiceOnlinePaidRevenueOverall,
                    addend2: totalServiceInStorePaidRevenueOverall,
                    sum: totalServiceRevenueOverall,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.inAdvance'),
                    label1Icon: 'window-maximize',
                    label2Color: '#b26492',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.onSiteSales'),
                    label2Icon: 'store'
                  },
                  equationMiddle: {
                    addend1: totalCourseOnlinePaidRevenueOverall,
                    addend2: totalCourseInStorePaidRevenueOverall,
                    sum: totalCourseRevenueOverall,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.inAdvance'),
                    label1Icon: 'window-maximize',
                    label2Color: '#b26492',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.onSiteSales'),
                    label2Icon: 'store'
                  },
                  sum: totalRevenueOverall
                }}
                currency={branchCurrency}
                isFinancial
                texts={{
                  title: t('statistics.finances'),
                  subTitle: t('statistics.revenue.extraInfo'),
                  textAddend1: t('statistics.singleBookingSignUps'),
                  textAddend2: t('statistics.groupsSignUps'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.revenue.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                isShort
                data={{
                  addend1: totalInternalCustomersOverall,
                  addend2: totalOnlineCustomersOverall,
                  sum: totalCustomersOverall
                }}
                texts={{
                  title: t('global.customers'),
                  subTitle: t('statistics.customers.extraInfo'),
                  textAddend1: t('statistics.customers.manual'),
                  textAddend2: t('statistics.customers.widget'),
                  textSum: t('global.total')
                }}
              />
            </Col>
            <Col size={50} />
          </Row>
        </>
      )}
      {(!displayLifetimeStatistics &&
        <>
          <Row>
            <Col>
              <div className='ta-dashboard__section'>{t('statistics.tab.bookings')}</div>
              <p className='ta-dashboard__section--subheading'>{dangerousHTML(t('statistics.bookings.info'))}</p>
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  addend1: totalServiceEventsWithTitle,
                  sum: totalServiceEvents,
                  equationMiddle: {
                    addend1: totalOnlineServiceEventsWithId,
                    addend2: totalInternalServiceEventsWithId,
                    sum: totalServiceEventsWithId,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  }
                }}
                texts={{
                  title: t('statistics.serviceBookings'),
                  subTitle: t('statistics.services.extraInfo'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.services'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.singleBookings.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  addend1: totalServiceEventsWithTitleAndCustomer,
                  sum: totalServiceEventsWithCustomer,
                  equationMiddle: {
                    addend1: totalOnlineServiceEventsWithIdAndCustomer,
                    addend2: totalInternalServiceEventsWithIdAndCustomer,
                    sum: totalServiceEventsWithIdAndCustomer,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  }
                }}
                texts={{
                  title: t('statistics.singleBookingSignUps'),
                  subTitle: t('statistics.singleBookingSignUps.extraInfo'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.services'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.singleBookingSignUps.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col size={33}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.bookings.avgRegular')}
                extraHeaderText={t('statistics.perDay')}
                title={averageDailyServiceBookings}
                statisticId='averageDailyServiceBookings'
                labels={[
                  {
                    icon: 'calendar-alt',
                    position: 'right',
                    text: t('statistics.addedManuallyInCalendar.tooltip'),
                    color: '#505a5e'
                  },
                  {
                    icon: 'window-maximize',
                    position: 'right',
                    text: t('statistics.bookedViaWidget.tooltip'),
                    color: '#769851'
                  }
                ]}
              />
            </Col>
            <Col size={33}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.bookings.avgRegular')}
                extraHeaderText={t('statistics.perWeek')}
                infoPopup={{
                  position: 'top',
                  text: t('statistics.bookings.avgRegular.tooltip')
                }}
                title={averageWeeklyServiceBookings}
                statisticId='averageWeeklyServiceBookings'
                labels={[
                  {
                    icon: 'calendar-alt',
                    position: 'top',
                    text: t('statistics.addedManuallyInCalendar.tooltip'),
                    color: '#505a5e'
                  },
                  {
                    icon: 'window-maximize',
                    position: 'top',
                    text: t('statistics.bookedViaWidget.tooltip'),
                    color: '#769851'
                  }
                ]}
              />
            </Col>
            <Col size={33} />
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  addend1: totalCourseEventsWithTitle,
                  addend2: totalCourseEventsWithId,
                  sum: totalCourseEvents
                }}
                texts={{
                  title: t('statistics.groupBookings'),
                  subTitle: t('statistics.groups.addedToCalendar'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.groups'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.groups.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  equationStart: {
                    addend1: totalOnlineCourseBookingsWithTitle,
                    addend2: totalInternalCourseBookingsWithTitle,
                    sum: totalCourseBookingsWithTitle,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  },
                  equationMiddle: {
                    addend1: totalOnlineCourseBookingsWithId,
                    addend2: totalInternalCourseBookingsWithId,
                    sum: totalCourseBookingsWithId,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.viaWidget'),
                    label1Icon: 'window-maximize',
                    label2Color: '#505a5e',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.viaCalendar'),
                    label2Icon: 'calendar-alt'
                  },
                  sum: totalCourseBookings
                }}
                texts={{
                  title: t('statistics.groupsSignUps'),
                  subTitle: t('statistics.groupsSignUps.extraInfo'),
                  textAddend1: t('global.adhoc'),
                  textAddend2: t('global.groups'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.groupsSignUps.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col size={33}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.groupParticipantsVsGuests')}
                statisticId='totalCourseBookingGuestsAndParticipants'
                calculatePercentage={{
                  total: totalCourseBookingGuestsAndParticipants,
                  value: totalCourseBookingGuests,
                  data1Description: t('global.additionalParticipant'),
                  data2Description: t('global.participants'),
                  availabilityPercentage: true,
                  availabilityText: 'guests',
                  useFirstPercentage: true,
                  useSecondValue: true
                }}
                infoPopup={{
                  text: t('statistics.guests.popup'),
                  position: 'right'
                }}
              />
            </Col>
            <Col size={33}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.avg.participants')}
                title={averageBookingParticipantsPerCourseEvent}
                statisticId='averageBookingParticipantsPerCourseEvent'
                labels={[
                  {
                    icon: 'calendar-alt',
                    position: 'top',
                    text: t('statistics.addedManuallyInCalendar.tooltip'),
                    color: '#505a5e'
                  },
                  {
                    icon: 'window-maximize',
                    position: 'top',
                    text: t('statistics.bookedViaWidget.tooltip'),
                    color: '#769851'
                  }
                ]}
                infoPopup={{
                  position: 'top',
                  text: t('statistics.avgParticipants.tooltip')
                }}
              />
            </Col>
            <Col size={33}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.avg.guests')}
                title={averageBookingGuestsPerCourseEvent}
                statisticId='averageBookingGuestsPerCourseEvent'
                infoPopup={{
                  position: 'left',
                  text: t('statistics.avg.guests.popup')
                }}
                labels={[
                  {
                    icon: 'calendar-alt',
                    position: 'top',
                    text: t('statistics.addedManuallyInCalendar.tooltip'),
                    color: '#505a5e'
                  },
                  {
                    icon: 'window-maximize',
                    position: 'top',
                    text: t('statistics.bookedViaWidget.tooltip'),
                    color: '#769851'
                  }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                isShort
                data={{
                  addend1: totalCancelledOnlineEvents,
                  addend2: totalCancelledInternalEvents,
                  sum: totalCancelledEvents
                }}
                texts={{
                  title: t('global.cancellations'),
                  subTitle: t('statistics.singleBookings.viaWidget'),
                  textAddend1: t('statistics.byCustomer'),
                  textAddend2: t('statistics.byStaff'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.cancellations.tooltip'),
                  position: 'left'
                }}
              />
            </Col>
            <Col size={50}>
              <Row>
                <Col size={50}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.cancellations.avg')}
                    headerLabel={t('statistics.singleBookings.viaWidget')}
                    extraHeaderText={t('statistics.perWeek')}
                    title={averageWeeklyCancelledBookings}
                    statisticId='averageWeeklyCancelledBookings'
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.course.popupInfo')
                    }}
                    isBig
                  />
                </Col>
                <Col size={50}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.rescheduled')}
                    headerLabel={t('statistics.singleBookings.viaWidget')}
                    statisticId='totalRescheduledEvents'
                    title={totalRescheduledEventsDaily}
                    secondDescription={statsDescription}
                    secondData={totalRescheduledEventsMonthly}
                    icon={(totalRescheduledEventsDaily !== totalRescheduledEventsMonthly
                      ? totalRescheduledEventsDaily > totalRescheduledEventsMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    iconColor={(totalRescheduledEventsDaily > totalRescheduledEventsMonthly) ? 'red' : 'green'}
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.reschedule.popupInfo')
                    }}
                    isBig
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                label={t('statistics.peakSignUpTimes')}
                headerLabel={dangerousHTML(t('statistics.bookings.viaWidgetUTC'))}
                type='area'
                extractKey='hour'
                data={(eventSignupsByHoursOfDayUTC || []).map((item, i) => ({ hour: `${i}:00`, 'sign-ups': item }))}
                options={{
                  syncId: 'eventSignupsByHoursOfDayUTC',
                  containerMinHeight: '234px',
                  labelsKey: 'hour',
                  tooltip: true,
                  fill: '#385f87',
                  dataKey: 'sign-ups',
                  interval: 3,
                  dot: { stroke: '#385f87', strokeWidth: 2, r: 1 },
                  customTooltip: {
                    className: 'new',
                    labelText: t('statistics.betweenTimes'),
                    getNextLabel: true,
                    reverseLabelOrder: true,
                    customLabelKey: 'hour'
                  }
                }}
                statisticId='dailyBookingTotals'
                labelButton={t('global.all')}
                bigHeader
                infoPopup={{
                  position: 'top',
                  text: t('statistics.bookings.viaWidget.tooltip')
                }}
              />
            </Col>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                label={t('statistics.peakSignUpDays')}
                headerLabel={t('statistics.bookings.viaWidget')}
                type='bar'
                extractKey='day'
                barsKey={t('statistics.avgBookings')}
                data={(eventSignupsByDaysOfWeekUTC || []).map((item, i) => ({ day: days[i], Bookings: item.value ? item.value : item }))}
                options={{
                  syncId: 'eventSignupsByDaysOfWeekUTC',
                  containerMinHeight: '229px',
                  bars: [{ key: 'Bookings', fill: '#385f87' }],
                  labelsKey: 'day',
                  tooltip: true,
                  customTick: <ChartAxisTickWithCircle />,
                  customTooltip: {
                    className: 'new',
                    textBeforeLabel: 'On ',
                    customLabelKey: 'day',
                    customLabels: weekdays.map(item => ({ label: item }))
                  },
                  verticalGrid: false
                }}
                statisticId='eventSignupsByDaysOfWeekUTC'
                infoPopup={{
                  position: 'left',
                  text: t('statistics.peakSignUpDays.tooltip')
                }}
                labelButton={t('global.all')}
                bigHeader
              />
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                settingsButton
                filterType='dashboard-sign-ups'
                label={t('statistics.dailySignUpTotals')}
                headerLabel={t('statistics.bookings.viaWidget')}
                type='area'
                extractKey='date'
                data={(eventSignupsByDaysUTC || []).map((item, i) => ({ date: `${i + 1} ` + `${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}`.toUpperCase().substring(0, 3), formattedDate: 'on ' + moment(`${dashboardFiltersYear}-${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}-${i + 1}`, 'YYYY-MMMM-DD').format('DD.MM.YYYY'), bookings: item }))}
                options={{
                  syncId: 'eventSignupsByDaysUTC',
                  containerMinHeight: '234px',
                  dataKey: 'bookings',
                  fill: '#385f87',
                  labelsKey: 'date',
                  tooltip: true,
                  interval: 4,
                  dot: { stroke: '#385f87', strokeWidth: 2, r: 1 },
                  secondChart: 'key2',
                  customTooltip: {
                    className: 'new',
                    customLabelKey: 'formattedDate',
                    getLabelFromPayload: true,
                    reverseLabelOrder: true
                  }
                }}
                statisticId='eventsByDays'
                infoPopup={{
                  position: 'top',
                  text: t('statistics.dailySignUpTotals.tooltip')
                }}
                interval={5}
                labelButton={t('global.all')}
                bigHeader
              />
            </Col>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                label={t('statistics.dailyCalendarBookings')}
                headerLabel={t('statistics.dailyCalendarBookings.extraInfo')}
                type='area'
                extractKey='date'
                data={(eventsByDaysUTC || []).map((item, i) => ({ date: `${i + 1} ` + `${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}`.toUpperCase().substring(0, 3), formattedDate: 'on ' + moment(`${dashboardFiltersYear}-${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}-${i + 1}`, 'YYYY-MMMM-DD').format('DD.MM.YYYY'), bookings: item }))}
                options={{
                  syncId: 'eventsByDaysUTC',
                  containerMinHeight: '234px',
                  dataKey: 'bookings',
                  fill: '#385f87',
                  labelsKey: 'date',
                  tooltip: true,
                  interval: 4,
                  dot: { stroke: '#385f87', strokeWidth: 2, r: 1 },
                  secondChart: 'key2',
                  customTooltip: {
                    className: 'new',
                    customLabelKey: 'formattedDate',
                    getLabelFromPayload: true,
                    reverseLabelOrder: true
                  }
                }}
                statisticId='eventsByDays'
                infoPopup={{
                  position: 'left',
                  text: t('statistics.dailyCalendarBookings.tooltip')
                }}
                interval={5}
                labelButton={t('global.all')}
                bigHeader
              />
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                label={t('statistics.marketing')}
                type='composed'
                data={(onlineMarketingStatistics || []).map((item) => {
                  const conversions = Object.values(item)[0] || 0
                  const activity = Object.keys(item)[0] || '-'
                  return { activity, Conversions: conversions }
                })}
                extractKey='activity'
                barsKey='Conversions'
                options={{
                  syncId: 'onlineMarketingUtmStatistics',
                  containerMinHeight: '234px',
                  bars: [{ key: 'Conversions', fill: '#385f87' }],
                  labelsKey: 'activity',
                  tooltip: true,
                  margin: {
                    top: 20,
                    right: 10,
                    left: 65,
                    bottom: 0
                  },
                  interval: 100,
                  horizontalGrid: false,
                  customTooltip: { className: 'new' },
                  customTick: <ChartAxisTickTruncated />
                }}
                statisticId='onlineMarketingUtmStatistics'
                infoPopup={{
                  position: 'top',
                  text: t('statistics.bookings.marketing.tooltip')
                }}
                buttons={(onlineMarketingStatistics !== null &&
                  <Form name='marketingConversions'>
                    <OptionsSelect name='type' />
                  </Form>
                )}
                bigHeader
              />
            </Col>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                label={t('statistics.mostBooked')}
                headerLabel={t('statistics.servicesAndGroups')}
                type='composed'
                data={(topBookingServices || []).map(item => ({ name: item.name, Bookings: item.value }))}
                options={{
                  syncId: 'mostBookedServices',
                  containerMinHeight: '234px',
                  bars: [{ key: 'Bookings', fill: '#385f87' }],
                  labelsKey: 'name',
                  tooltip: true,
                  margin: {
                    top: 20,
                    right: 10,
                    left: 65,
                    bottom: 0
                  },
                  interval: 0,
                  horizontalGrid: false,
                  customTooltip: {
                    className: 'new'
                  },
                  customTick: <ChartAxisTickTruncated />
                }}
                statisticId='topBookingServices'
                labelsHoverClass='no-padding'
                labels={[
                  {
                    icon: 'calendar-alt',
                    position: 'top',
                    text: t('statistics.addedManuallyInCalendar.tooltip'),
                    color: '#505a5e'
                  },
                  {
                    icon: 'window-maximize',
                    position: 'top',
                    text: t('statistics.bookedViaWidget.tooltip'),
                    color: '#769851'
                  }
                ]}
                bigHeader
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='ta-dashboard__section'>{t('statistics.resourceActivity')}</div>
              <p className='ta-dashboard__section--subheading'>{t('statistics.resourceActivity.hintText')}</p>
            </Col>
          </Row>
          <Row>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.totalResources')}
                title={totalResourcesDaily}
                statisticId='totalResources'
                secondDescription={statsDescription}
                secondData={totalResourcesMonthly}
                icon={(totalResourcesDaily !== totalResourcesMonthly
                  ? totalResourcesDaily > totalResourcesMonthly
                    ? 'arrow-up'
                    : 'arrow-down'
                  : null
                )}
                iconColor={(totalResourcesDaily > totalResourcesMonthly) ? 'green' : 'red'}
              />
            </Col>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.sickDays')}
                title={sickDenialsDaysDaily}
                statisticId='sickDays'
                infoPopup={{ position: 'top', text: t('statistics.resources.sickDays.tooltip') }}
                secondDescription={statsDescription}
                secondData={sickDenialsDaysMonthly}
                icon={(sickDenialsDaysDaily !== sickDenialsDaysMonthly
                  ? sickDenialsDaysDaily > sickDenialsDaysMonthly
                    ? 'arrow-up'
                    : 'arrow-down'
                  : null
                )}
                iconColor={(sickDenialsDaysDaily > sickDenialsDaysMonthly) ? 'red' : 'green'}
              />
            </Col>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.blockedDays')}
                title={blockerDenialsDaysDaily}
                statisticId='blockedDays'
                infoPopup={{ position: 'top', text: t('statistics.resources.blockedDays.tooltip') }}
                secondDescription={statsDescription}
                secondData={blockerDenialsDaysMonthly}
                icon={(blockerDenialsDaysDaily !== blockerDenialsDaysMonthly
                  ? blockerDenialsDaysDaily > blockerDenialsDaysMonthly
                    ? 'arrow-up'
                    : 'arrow-down'
                  : null
                )}
                iconColor={(blockerDenialsDaysDaily > blockerDenialsDaysMonthly) ? 'red' : 'green'}
              />
            </Col>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.avgBookings')}
                extraHeaderText='/resource'
                infoPopup={{
                  position: 'left',
                  text: t('statistics.avgBookings.popupInfo')
                }}
                title={averageServiceBookingsPerResource}
                statisticId='averageServiceBookingsPerResource'
              />
            </Col>
            {/* Will be deployed later */}
            {/* <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText='Holidays'
                title={vacationDenialsDaysDaily}
                statisticId='holidays'
                secondDescription={statsDescription}
                secondData={vacationDenialsDaysMonthly}
                icon={vacationDenialsDaysDaily !== vacationDenialsDaysMonthly
                  ? vacationDenialsDaysDaily > vacationDenialsDaysMonthly
                    ? 'arrow-up'
                    : 'arrow-down'
                  : null
                }
                iconColor={(vacationDenialsDaysDaily > vacationDenialsDaysMonthly) ? 'red' : 'green'}
              />
            </Col> */}
          </Row>
          <Row>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.resourceCapacityWorkingHrs')}
                statisticId='totalResourcesWorkingTimes totalResourcesWorkingBookedTimes'
                infoPopup={{ position: 'top', text: t('statistics.workingHoursCapacity.tooltip') }}
                calculatePercentage={{
                  total: totalResourcesWorkingHours,
                  value: totalResourcesWorkingBookedHours,
                  data1Description: t('statistics.bookedHrs'),
                  data2Description: t('statistics.resourceWorkingHrs')
                }}
                showAvailableCapacity
              />
            </Col>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.resourceCapacityBookingHrs')}
                statisticId='totalResourcesOnlineHours totalResourcesOnlineBookedHours'
                infoPopup={{ position: 'top', text: t('statistics.resources.bookingHoursCapacity.tooltip') }}
                calculatePercentage={{
                  total: totalResourcesOnlineHours,
                  value: totalResourcesOnlineBookedHours,
                  data1Description: t('statistics.bookedHrs'),
                  data2Description: t('statistics.bookingHrs')
                }}
                showAvailableCapacity
              />
            </Col>
            <Col size={50} />
          </Row>
          <Row>
            <Col size={50}>
              <SimpleBox
                className='ta-simple-box-big'
                headerText={t('statistics.mostBookedResource')}
                statisticId='topBookingResources'
                buttons={(topBookingResourcesValue !== null &&
                  <Form name='dashboardMostBookedResourcesType' className='ta-dashboard-form__no-margin'>
                    <OptionsSelect name='type' />
                  </Form>
                )}
              >
                {(topBookingResourcesValue || []).filter((item, i) => i < 5).map((item, index) => (
                  <Row key={index}>
                    <SimpleListItem>
                      <SimpleListItemContent>
                        <>
                          {(item.image &&
                            <Avatar image={item.image} name={item.name} />
                          )}
                          {(item.name &&
                            <p className='ta-simple-list-item__title'>{item.name}</p>
                          )}
                          {(item.address && item.address.formatted) && (
                            <ul className='ta-list-inline'>
                              <li> <FontAwesome5 icon='building' type='s' /></li>
                              <li className='ta-list-inline__address'>{item.address.formatted}</li>
                            </ul>
                          )}
                        </>
                        <>
                          <SimpleListItemButtons>
                            {((index <= 2 && <div className='small-statistic-box'>{item.value}</div>) ||
                              <div className='small-statistic-box'>{item.value}</div>
                            )}
                          </SimpleListItemButtons>
                        </>
                      </SimpleListItemContent>
                    </SimpleListItem>
                  </Row>
                ))}
                {(!(topBookingResourcesValue || []).length &&
                  <div className='ta-statistic-box__title__no-results'>
                    <FontAwesome5 icon='sad-tear' /> <span className='ta-statistic-box__title__no-results--text'>{t('statistics.notEnoughData')}</span>
                    <p>{t('statistics.needMoreInfo')}</p>
                  </div>
                )}
              </SimpleBox>
            </Col>
            <Col size={50} />
          </Row>
          <Row>
            <Col>
              <div className='ta-dashboard__section'>{t('global.customers')}</div>
              <p className='ta-dashboard__section--subheading'>{t('statistics.customers.hintText')}</p>
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                isShort
                data={{
                  addend1: totalInternalCustomers,
                  addend2: totalOnlineCustomers,
                  sum: totalCustomers
                }}
                texts={{
                  title: t('global.customers'),
                  subTitle: t('statistics.customers.newNumber'),
                  textAddend1: t('statistics.customers.manual'),
                  textAddend2: t('statistics.customers.widget'),
                  textSum: t('global.total')
                }}
              />
            </Col>
            <Col size={50} />
          </Row>
          <Row>
            <Col>
              <div className='ta-dashboard__section'>{t('statistics.finances')}</div>
              <p className='ta-dashboard__section--subheading'>{t('statistics.revenue.info')}</p>
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <StatisticsCompositeWrapper
                data={{
                  equationStart: {
                    addend1: totalServiceOnlinePaidRevenue,
                    addend2: totalServiceInStorePaidRevenue,
                    sum: totalServiceRevenue,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.inAdvance'),
                    label1Icon: 'window-maximize',
                    label2Color: '#b26492',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.onSiteSales'),
                    label2Icon: 'store'
                  },
                  equationMiddle: {
                    addend1: totalCourseOnlinePaidRevenue,
                    addend2: totalCourseInStorePaidRevenue,
                    sum: totalCourseRevenue,
                    label1Color: '#769851',
                    label1TextColor: '#769851',
                    label1Text: t('statistics.inAdvance'),
                    label1Icon: 'window-maximize',
                    label2Color: '#b26492',
                    label2TextColor: '#505a5e',
                    label2Text: t('statistics.onSiteSales'),
                    label2Icon: 'store'
                  },
                  sum: totalRevenue
                }}
                currency={branchCurrency}
                isFinancial
                texts={{
                  title: t('statistics.finances'),
                  subTitle: t('statistics.revenue.extraInfo'),
                  textAddend1: t('statistics.singleBookingSignUps'),
                  textAddend2: t('statistics.groupsSignUps'),
                  textSum: t('global.total')
                }}
                tooltip={{
                  text: t('statistics.revenue.tooltip'),
                  position: 'top'
                }}
              />
            </Col>
            <Col size={50}>
              <StatisticsWrapper
                statisticType='chart'
                data={(topBookingServicesWithPrice || []).map(({ name, value, color, totalBookings }) => ({
                  name: (
                    <HoverPopup>
                      <HoverPopupContent>
                        {name}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        {truncateText(name, 40, true)}
                      </HoverPopupTrigger>
                    </HoverPopup>
                  ),
                  value,
                  color,
                  totalBookings
                }))}
                label={t('statistics.servicesWithPrice')}
                headerLabel={t('statistics.paidInAdvance')}
                type='pie'
                options={{
                  syncId: 'topBookingServicesWithPrice',
                  containerMinHeight: '234px',
                  tooltip: true,
                  dataKey: 'value',
                  currency: branchCurrency,
                  locale,
                  legend: {
                    align: 'right',
                    verticalAlign: 'middle',
                    currency: branchCurrency
                  }
                }}
                statisticId='topBookingServicesWithPrice'
                bigHeader
              />
            </Col>
          </Row>
          <Row>
            <Col size={25}>
              <StatisticsWrapper
                statisticType='box'
                extraClassName='ta-simple-box-fixed-dimensions'
                headerText={t('statistics.paidInAdvanceVsOnSite')}
                statisticId='totalRevenue totalOnlinePaidRevenue'
                infoPopup={{ position: 'top', text: t('statistics.finance.comparePaid.tooltip') }}
                calculatePercentage={{
                  total: totalRevenue,
                  value: totalOnlinePaidRevenue,
                  useSecondValue: true,
                  data1Description: t('statistics.paidInAdvance'),
                  data2Description: t('statistics.paidInStore')
                }}
              />
            </Col>
            <Col size={25} />
            <Col size={25} />
            <Col size={25} />
          </Row>
          <Row>
            <Col>
              <div className='ta-dashboard__section'>{t('global.locations')}</div>
              <p className='ta-dashboard__section--subheading'>{t('statistics.locations.subtitle')}</p>
            </Col>
          </Row>
          <Row>
            <Col size={50}>
              <SimpleBox
                className='ta-simple-box-big'
                headerText={t('statistics.locations.heading')}
                statisticId='branchesMostBookings'
              >
                {(topBookingBranches || []).map((item, index) => (
                  <Row key={index}>
                    <SimpleListItem>
                      <SimpleListItemTitle title={item.name} />
                      <SimpleListItemContent>
                        {((item.address && item.address.formatted) &&
                          <ul className='ta-list-inline'>
                            <li> <FontAwesome5 icon='map' type='s' /></li>
                            <li className='ta-list-inline__address'>{item.address.formatted}</li>
                          </ul>
                        )}
                        <SimpleListItemButtons>
                          <div className='small-statistic-box'>{item.value}</div>
                        </SimpleListItemButtons>
                      </SimpleListItemContent>
                    </SimpleListItem>
                  </Row>
                ))}
                {(!(topBookingBranches || []).length &&
                  <div className='ta-statistic-box__title__no-results'>
                    <FontAwesome5 icon='sad-tear' /> <span className='ta-statistic-box__title__no-results--text'>{t('statistics.notEnoughData')}</span>
                    <p>{t('statistics.needMoreInfo')}</p>
                  </div>
                )}
              </SimpleBox>
            </Col>
            <Col size={50} />
          </Row>
        </>
      )}
      <Row className='buffer'>
        <Col />
      </Row>
    </div>
  )
}

export default Dashboard
