import { useEffect } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { Attributes } from '../../Beauties'
import { groupByKey } from '../../Utils'
import { Loader } from '../../Common'

const AttributesWrapper = ({ hash, categories, pendingList, isAdmin }) => {
  const groupedCategories = groupByKey(categories, 'attributeType')

  useEffect(() => {
    handlers.attributesGet()
  }, [])

  return (
    <>
      <Loader active={pendingList} />
      {!pendingList && (
        <Attributes isAdmin={isAdmin} hash={hash} groupedCategories={groupedCategories} />
      )}
    </>
  )
}

const maps = state => ({
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
  pendingList: selectors.attributesFieldSelector(state, { field: 'pendingList' }),
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  categories: selectors.attributesCategoriesListSelector(state)
})

export default connect(maps)(AttributesWrapper)
