import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SectionTitle,
  FormSection,
  FormButtons,
  Error,
  Link,
  Form,
  Row,
  Col,
  Input,
  ColorIconSelect,
  MultipleSelect,
  t
} from '../../../Common'
import { PermissionsFormWrapper } from '../../../Beasts'

import './PermissionTypesAdd.css'

const PermissionTypeAdd = props => {
  let {
    cancelLink,
    title,
    readOnlyExternalIds,
    showExternalIds,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-permission-types__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={props.onSubmit} name='permissionType'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle
                  label={t('permissionTypes.edit.section.externalId.heading')}
                  icon='fingerprint'
                />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      disabled={readOnlyExternalIds}
                      label={t('global.externalId.label')}
                      hintText={t('permissionTypes.edit.section.details.externalId.example')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}

            <FormSection>
              <SectionTitle
                label={t('permissionTypes.edit.section.details.heading')}
                icon='sliders-h'
              />
              <Row>
                <Col>
                  <ColorIconSelect name='color' iconFieldName='icon'>
                    <Input
                      name='name'
                      mandatory
                      label={t('global.name.placeholder')}
                      hintText={t('permissionTypes.edit.section.details.name.example')}
                    />
                  </ColorIconSelect>
                </Col>
              </Row>
            </FormSection>

            <FormSection>
              <SectionTitle
                label={t('permissionTypes.edit.section.permissions.heading')}
                icon='unlock-alt'
              />
              <PermissionsFormWrapper />
            </FormSection>
            <Row>
              <Col>
                <SectionTitle label={t('global.location')} icon='building' />
                <MultipleSelect
                  name='location'
                  label={t('global.select.label')}
                  searchable
                  disableSelected={type === 'edit'}
                  hasAll={hasAll}
                  allLabel={t('global.allLocations')}
                />
              </Col>
            </Row>
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>
                {t('buttons.cancel.label')}
              </Link>
              <button type='submit' className='ta-btn ta-btn-primary'>
                {t('buttons.save.label')}
              </button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default PermissionTypeAdd
