import React from 'react'
import { Link, FontAwesome5 } from '../../Common'

import './Blur.css'

const Blur = props => {
  const {
    children,
    active,
    icon,
    activateIcon = icon,
    link,
    label,
    activateLabel,
    text,
    button,
    className,
    extraClassName
  } = props

  const classNames = ['ta-blur']
  const childrenClassNames = []

  if (active) classNames.push('active')
  if (className) childrenClassNames.push(className)
  if (extraClassName) classNames.push(extraClassName)
  if (active) childrenClassNames.push('blur')

  return (
    <div className={classNames.join(' ')}>
      <div className={active ? 'blur' : undefined}>
        {children}
      </div>
      <div className='ta-blur__overlay'>
        <div className='ta-blur__content'>
          {link
            ? (
              <Link className='ta-blur__content__link' to={link}>
                {icon && (
                  <div className='ta-blur__content__icon'>
                    <FontAwesome5 className='ta-blur__content__icon__normal' icon={icon} />
                    {activateIcon && <FontAwesome5 className='ta-blur__content__icon__active' icon={activateIcon} />}
                  </div>
                )}
                {label && <div className='ta-blur__content__label'>{label}</div>}
                {activateLabel && <div className='ta-blur__content__activate-label'>{activateLabel}</div>}
              </Link>
            )
            : (
              <>
                {icon && (
                  <div className='ta-blur__content__icon'>
                    <FontAwesome5 icon={icon} type='s' />
                  </div>
                )}
                {label && <div className='ta-blur__content__label'>{label}</div>}
              </>
            )}
          <>
            {text && <div className='ta-blur__content__text'>{text}</div>}
            {(button && button.link && button.label) && <Link className='ta-blur__content__button ta-btn ta-btn-primary' to={button.link}>{button.label}</Link>}
          </>
        </div>
      </div>
    </div>
  )
}

export default Blur
