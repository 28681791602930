import React from 'react'
import {
  SectionTitle,
  Form,
  Row,
  Col,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Title,
  Link,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Blur,
  Loader,
  SingleSelect,
  HintText
} from '../../Common'
import {
  CustomersList,
  CustomersListFilterSearch,
  CustomersListFilterSort,
  CustomersListFilterTags
} from '../../Beauties'
import {
  PopupTriggerWrapper,
  CustomersToDeleteWrapper,
  CustomersListFilterAlphabetWrapper
} from '../../Beasts'

import './Customers.css'
import { dangerousHTML } from '../../Utils'

const filterSearchTextKeys = [
  { label: 'customers.fieldSearch.label', placeholder: 'customers.fieldSearch.placeholder' },
  { label: 'global.searchByPhoneNameEmail.AdvanceMongo.label', placeholder: 'global.searchByPhoneNameEmail.AdvanceMongo.placeholder' }
]

const Customers = props => {
  const {
    label,
    total,
    customers,
    hash,
    search,
    tags,
    enterpriseId,
    customersRead,
    customersWrite,
    shouldUseMongoCustomerSearch,
    pending
  } = props
  const classNames = ['ta-customers']
  if (customersRead) classNames.push('access-granted')
  if (shouldUseMongoCustomerSearch) classNames.push('advanced-search')
  const availableTabs = ['activeCustomers', 'deletedCustomers']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeCustomers'
  const searchQueryTypes = [{ value: 'exact', label: t('customers.searchType.exact') }, { value: 'partial', label: t('customers.searchType.partial') }]
  const filterSearchText = filterSearchTextKeys[shouldUseMongoCustomerSearch ? 1 : 0]
  const hasAdvanceSearch = shouldUseMongoCustomerSearch && enterpriseId

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-customers__title'>
            <Title label={t('global.customers')}>
              <PopupTriggerWrapper name='customers-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 icon='lightbulb' />
                </Link>
              </PopupTriggerWrapper>
              {(customersWrite &&
                <>
                  {/*
                    {(isOwner && !hideCustomersDownloadButton &&
                      <PopupTriggerWrapper name='customers-download' position='bottom'>
                        <HoverPopup>
                          <HoverPopupContent position='left' autoSize>
                            {t('customers.buttonHeading.downloadTooltip')}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            <Link className='ta-btn ta-btn-secondary ta-btn-icon-only' external>
                              <FontAwesome5 icon='download' type='solid' />
                            </Link>
                          </HoverPopupTrigger>
                        </HoverPopup>
                      </PopupTriggerWrapper>
                    )}
                  */}
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('customers.list.buttonHeading.addTooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link to='/customers/add' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                        <FontAwesome5 icon='plus' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={customersRead ? 'inactive' : ''} active={!customersRead} icon='ban' label={t('global.accessDenied')}>
            <div className='ta-customers__list'>
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='activeCustomers' label={t('customers.list.activeCustomers')} icon='sliders-h' />
                  <SimpleTabsHeaderItem name='deletedCustomers' label={t('customers.list.forDeletion')} icon='minus-octagon' />
                </SimpleTabsHeader>
                <SimpleTabsBody className={!customersRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='activeCustomers'>
                    <Form name='customersFilters'>
                      <Row>
                        <Col>
                          <CustomersListFilterSearch {...filterSearchText} />
                        </Col>
                        {hasAdvanceSearch && (
                          <Col size={40}>
                            <SingleSelect
                              name='useExactMatchSearch'
                              label={t('customers.searchType.label')}
                              options={searchQueryTypes}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        {(!shouldUseMongoCustomerSearch &&
                          <Col size={50}>
                            <CustomersListFilterSort />
                          </Col>
                        )}
                        <Col size={50}>
                          <CustomersListFilterTags tags={tags} />
                        </Col>
                      </Row>
                      {shouldUseMongoCustomerSearch && <HintText>{dangerousHTML(t('customers.filtersHint'))}</HintText>}
                      <CustomersListFilterAlphabetWrapper />
                    </Form>
                    <SectionTitle label={hasAdvanceSearch ? label : `${label} (${total})`} noIcon />
                    <Loader active={pending} />
                    {(!pending &&
                      <CustomersList
                        customers={customers}
                        search={search}
                        enterpriseId={enterpriseId}
                      />
                    )}
                  </SimpleTabsBodyItem>
                  <SimpleTabsBodyItem name='deletedCustomers' renderOnlyIfActive>
                    <CustomersToDeleteWrapper />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            </div>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Customers
