import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  DnDWrapper,
  DnDDroppable,
  DnDDraggable,
  Alert,
  Form,
  Error,
  t,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  FontAwesome5,
  Row,
  Col
} from '../../Common'
import { ServicesTitle, ServicesFilters } from '../../Beauties'
import { ServicesCategoryWrapper, ServicesToDeleteWrapper } from '../../Beasts'
import { dangerousHTML } from '../../Utils'

import './Services.css'

const Services = props => {
  const {
    categories,
    pending,
    onDragStart,
    onDragUpdate,
    onDragEnd,
    hash,
    isDragAndDropMode,
    areExpanded,
    onCategoriesToggle,
    hasFilteredServices,
    hasServiceCombinations,
    globalServicesWrite
  } = props
  const classNames = ['ta-services']
  if (isDragAndDropMode && !areExpanded) classNames.push('categories-collapse')
  const availableTabs = ['activeServices', 'deletedServices']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeServices'

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <ServicesTitle
            isDragAndDropMode={isDragAndDropMode}
            areExpanded={areExpanded}
            onCategoriesToggle={onCategoriesToggle}
            globalServicesWrite={globalServicesWrite}
          />
        </FixedContentHeader>
        <FixedContentBody>
          <div className='ta-customer-fields__list'>
            {(!pending &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='activeServices' label={t('services.list.tabs.activeServices')} icon='sliders-h' />
                  <SimpleTabsHeaderItem name='deletedServices' label={t('services.list.tabs.deleteServices')} icon='minus-octagon' />
                </SimpleTabsHeader>
                <SimpleTabsBody>
                  <SimpleTabsBodyItem name='activeServices'>
                    <FixedContent>
                      <FixedContentHeader>
                        <ServicesFilters disabled={isDragAndDropMode} hasServiceCombinations={hasServiceCombinations} />
                      </FixedContentHeader>
                      <FixedContentBody>
                        {(isDragAndDropMode &&
                          <Alert className='ta-services__reorder-message' theme='alert'>
                            {(!areExpanded &&
                              <div>{dangerousHTML(t('servicesGroups.list.reorder.note.defaultCategory.text'))}</div>
                            )}
                            {t('global.changesSaved')}
                            {(areExpanded &&
                              <div>
                                {dangerousHTML(t('global.reordering.categorySwitch.text', [{
                                  key: 'ICON',
                                  value: renderToString(<FontAwesome5 icon='compress-alt' />)
                                }]))}
                              </div>
                            )}
                            {(!areExpanded &&
                              <div>
                                {dangerousHTML(t('servicesGroups.list.reorder.note.serviceSwitch.text', [{
                                  key: 'ICON',
                                  value: renderToString(<FontAwesome5 icon='expand-alt' />)
                                }]))}
                              </div>
                            )}
                            {dangerousHTML(t('global.reordering.exit.text', [{
                              key: 'ICON',
                              value: renderToString(<FontAwesome5 icon='times' />)
                            }]))}
                          </Alert>
                        )}
                        <DnDWrapper
                          onDragUpdate={onDragUpdate}
                          onDragStart={onDragStart}
                          onDragEnd={onDragEnd}
                        >
                          <DnDDroppable id='category' type='category'>
                            {categories.filter(category => !category.isDefault).map((category, index, arr) => (
                              <DnDDraggable
                                className='ta-services__category__dnd-wrapper'
                                key={category.id}
                                type='service'
                                id={category.id}
                                index={index}
                                isDragDisabled={category.isDefault || !isDragAndDropMode || areExpanded}
                              >
                                <ServicesCategoryWrapper
                                  key={category.id}
                                  category={category}
                                  isDragAndDropMode={isDragAndDropMode}
                                  index={index}
                                  areCategoriesExpanded={areExpanded}
                                  isLastIndex={index === arr.length - 1}
                                />
                              </DnDDraggable>
                            ))}
                          </DnDDroppable>
                          {((!isDragAndDropMode || areExpanded) && categories.filter(category => category.isDefault).map((category, index, arr) => (
                            <ServicesCategoryWrapper
                              key={category.id}
                              category={category}
                              isDragAndDropMode={isDragAndDropMode}
                              areCategoriesExpanded={areExpanded}
                              index={index}
                              isLastIndex={index === arr.length - 1}
                            />
                          )))}
                          {(hasFilteredServices &&
                            <Row>
                              <Col>
                                <Alert noOffset label={t('servicesGroups.list.service.noResultsBox.empty.text')} theme='no-content' />
                              </Col>
                            </Row>
                          )}
                          <Form name='serviceCategory'>
                            <Error name='globalErrors' />
                          </Form>
                        </DnDWrapper>
                      </FixedContentBody>
                    </FixedContent>
                  </SimpleTabsBodyItem>
                  <SimpleTabsBodyItem renderOnlyIfActive name='deletedServices'>
                    <ServicesToDeleteWrapper />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Services
