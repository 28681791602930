import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Error,
  SectionTitle,
  ListItem,
  FormButtons,
  Row,
  Col,
  t
} from '../../Common'

import './TwoFAForm.css'

const TwoFARefreshCodesForm = ({ code, onSubmit, onCancel }) => {
  return (
    <Form name='twoFA' onSubmit={onSubmit} className='ta-2fa-disable-container'>
      <SectionTitle icon='lock-open' label={t('user.preview.security.refresh.title')} ignoreTopOffset />
      <ListItem>{t('user.preview.security.refresh.description')}</ListItem>
      <Row>
        <Col>
          <Input
            name='code'
            label={t('global.pinCode.label')}
            mandatory
            hideError
          />
          <Error noOffset name='code' />
        </Col>
      </Row>
      <FormButtons>
        <button onClick={onCancel} type='button' className='ta-btn ta-btn-secondary'>{t('global.no')}</button>
        <button type='submit' disabled={!code} className='ta-btn ta-btn-primary'>{t('global.refresh')}</button>
      </FormButtons>
    </Form>
  )
}

TwoFARefreshCodesForm.propTypes = {
  code: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default TwoFARefreshCodesForm
