
import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  Title,
  FontAwesome5,
  Link,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const TagsTitle = props => {
  const { tagsWrite } = props
  const classNames = ['ta-tags__title']

  return (
    <div className={classNames.join(' ')}>
      <Title label={t('global.tags')}>
        <PopupTriggerWrapper name='tags-help-box' position='bottom' extraClassName='ta-help-box'>
          <Link className='ta-title__link' external>
            {t('global.heading.help')} <FontAwesome5 icon='lightbulb' type='r' />
          </Link>
        </PopupTriggerWrapper>
        {(tagsWrite &&
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('tags.add')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Link to='/branches/tags/add' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                <FontAwesome5 icon='plus' type='s' />
              </Link>
            </HoverPopupTrigger>
          </HoverPopup>
        )}
      </Title>
    </div>
  )
}

export default TagsTitle
