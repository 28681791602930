import { payloads$, actions, handlers } from '../../../Store'
import { q } from '../../API'
import { CHARGEBEE_SITE } from '../../../Settings'

payloads$(actions.CHARGEBEE_PORTAL_OPEN)
  .subscribe(async (section) => {
    const chargebee = window.Chargebee
    if (!chargebee) return
    const result = await q('getEnterpriseChargebeeSelfPortal')
    if (!result) return
    const chargebeeInstance = chargebee.init({ site: CHARGEBEE_SITE })
    chargebeeInstance.setPortalSession(() => new Promise(async (resolve) => resolve(result)))
    const portal = chargebeeInstance.createChargebeePortal()
    portal.openSection({
      sectionType: chargebee.getPortalSections().PAYMENT_SOURCES
    }, {
      close: () => {
        chargebeeInstance.logout()
        handlers.paymentMethodsGet()
      }
    })
  })
