import React from 'react'
import {
  SectionTitle,
  ListItem,
  FormSection,
  BorderedBox,
  Link,
  t
} from '../../../Common'
import { dangerousHTML, extractFromHtml } from '../../../Utils'

const BookingSettingsWidget = props => {
  let {
    isWidgetWeeklyView,
    showWidgetCategoriesClosed,
    showWidgetFirstCategoryOpen,
    showWidgetLanguageSelector,
    showWidgetTimezoneSelector,
    hideResourcesSection,
    hideServiceDuration,
    customTermsAndConditionsUrl,
    customPrivacyPolicyUrl,
    disclaimerConfig,
    widgetExternalUrlRedirect,
    hasTimifyLogin,
    hasFacebookLogin,
    hasGuestBooking,
    rawSettings,
    customersMiddlewareUrl,
    customisations,
    widgetConversions
  } = props

  const { settings } = customisations || {}
  const { hideWidgetSettingsConversions } = settings || {}
  disclaimerConfig = disclaimerConfig || {}
  if (customersMiddlewareUrl) {
    hasTimifyLogin = false
    hasFacebookLogin = false
    hasGuestBooking = true
  }
  const authOptions = []
  if (hasTimifyLogin) authOptions.push('TIMIFY')
  if (hasFacebookLogin) authOptions.push('Facebook')
  if (hasGuestBooking) authOptions.push('As Guest')
  const customTermsAndConditionsUrlWithHttp = `${customTermsAndConditionsUrl && customTermsAndConditionsUrl.includes('http') ? '' : 'http://'}${customTermsAndConditionsUrl}`
  const customPrivacyPolicyUrlWithHttp = `${customPrivacyPolicyUrl && customPrivacyPolicyUrl.includes('http') ? '' : 'http://'}${customPrivacyPolicyUrl}`
  const widgetExternalUrlRedirectWithHttp = `${widgetExternalUrlRedirect && widgetExternalUrlRedirect.includes('http') ? '' : 'http://'}${widgetExternalUrlRedirect}`
  let {
    active: hasDisclaimer,
    text: disclaimerText,
    displayOn: disclaimerPlace
  } = disclaimerConfig || {}
  hasDisclaimer = !!hasDisclaimer && !!extractFromHtml(disclaimerText)
  disclaimerText = disclaimerText || ''
  disclaimerPlace = disclaimerPlace || null
  widgetConversions = widgetConversions || {}

  return (
    <div className='ta-booking-settings__widget'>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.language.heading')} icon='language' />
        <ListItem>
          {(rawSettings.showWidgetLanguageSelector === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {showWidgetLanguageSelector && dangerousHTML(t('settings.section.widget.language.enabledText'))}
          {!showWidgetLanguageSelector && dangerousHTML(t('settings.section.widget.language.disabledText'))}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.availabilityDisplay.heading')} icon='calendar-day' />
        <ListItem>
          {(rawSettings.isWidgetWeeklyView === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {isWidgetWeeklyView && t('settings.section.widget.availabilityDisplay.weekly.infoText')}
          {!isWidgetWeeklyView && t('settings.section.widget.availabilityDisplay.daily.infoText')}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.closeCategories.heading')} icon='sort' />
        <ListItem>
          {(rawSettings.showWidgetCategoriesClosed === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {!showWidgetCategoriesClosed && t('settings.section.widget.closeCategories.disabled.infoText')}
          {showWidgetCategoriesClosed && t('settings.section.widget.closeCategories.enabled.infoText')}
          {(showWidgetFirstCategoryOpen &&
            <BorderedBox>
              {t('settings.section.widget.closeCategories.openFirst.enabled.infoText')}
            </BorderedBox>
          )}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.hideResources.heading')} icon='user-circle' />
        <ListItem>
          {(rawSettings.hideResourcesSection === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {!hideResourcesSection && t('settings.section.widget.hideResources.display')}
          {hideResourcesSection && dangerousHTML(t('settings.section.widget.hideResources.enabled.infoText'))}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.hideServiceDuration.heading')} icon='hourglass-half' />
        {/* <SectionTitle label={t('servicesGroups.form.section.duration.heading')} icon='hourglass-half' /> */}
        <ListItem>
          {(rawSettings.hideServiceDuration === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {!hideServiceDuration && dangerousHTML(t('settings.section.hideServiceDuration.show'))}
          {hideServiceDuration && dangerousHTML(t('settings.section.hideServiceDuration'))}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.registration.heading')} icon='user-secret' />
        <ListItem>
          {(rawSettings.hasTimifyLogin === null && rawSettings.hasFacebookLogin === null && rawSettings.hasGuestBooking === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {t('settings.section.registration.hintText')}
          <br />
          {(hasTimifyLogin &&
            <ListItem className='bullet-item no-offset'>
              TIMIFY
            </ListItem>
          )}
          {(hasFacebookLogin &&
            <ListItem className='bullet-item no-offset'>
              Facebook
            </ListItem>
          )}
          {(hasGuestBooking &&
            <ListItem className='bullet-item no-offset'>
              {t('global.guest')}
            </ListItem>
          )}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.customTerms.heading')} icon='file-signature' />
        <ListItem>
          {(rawSettings.customPrivacyPolicyUrl === null && rawSettings.customTermsAndConditionsUrl === null && rawSettings.disclaimerConfig === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {((customTermsAndConditionsUrl || customPrivacyPolicyUrl || hasDisclaimer)
            ? t('settings.section.widget.customTerms.enabled.infoText')
            : t('settings.section.widget.customTerms.disabled.infoText')
          )}
          {((customTermsAndConditionsUrl || customPrivacyPolicyUrl || hasDisclaimer) &&
            <BorderedBox topOffset>
              {(customTermsAndConditionsUrl &&
                <>
                  <ListItem className='bullet-item black'>
                    {t('global.termsAndConditions')}:
                  </ListItem>
                  <ListItem className='bullet-item'>
                    <Link to={customTermsAndConditionsUrlWithHttp} target='_blank' external>
                      {customTermsAndConditionsUrlWithHttp}
                    </Link>
                  </ListItem>
                </>
              )}
              {(customPrivacyPolicyUrl &&
                <>
                  <ListItem className='bullet-item black'>
                    {t('global.privacyPolicy')}:
                  </ListItem>
                  <ListItem className='bullet-item'>
                    <Link to={customPrivacyPolicyUrlWithHttp} target='_blank' external>
                      {customPrivacyPolicyUrlWithHttp}
                    </Link>
                  </ListItem>
                </>
              )}
              {(hasDisclaimer &&
                <>
                  <ListItem className='bullet-item black'>
                    {t('settings.section.widget.checkbox.disclaimer.label')}:
                  </ListItem>
                  <div className='ta-disclaimer-container'>
                    <div className='ta-disclaimer-dot' />
                    {dangerousHTML(disclaimerText, false, true)}
                  </div>
                  <ListItem className='bullet-item'>
                    {(disclaimerPlace === 'CUSTOMER_DETAIL_FORM'
                      ? t('settings.section.widget.disclaimer.customer.option')
                      : t('settings.section.widget.disclaimer.booking.option')
                    )}
                  </ListItem>
                </>
              )}
            </BorderedBox>
          )}
        </ListItem>
      </FormSection>
      <FormSection>
        <SectionTitle label={t('settings.section.widget.timezone.heading')} icon='clock' />
        <ListItem>
          {(rawSettings.showWidgetTimezoneSelector === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {showWidgetTimezoneSelector && dangerousHTML(t('settings.section.widget.timezone.enabledText'))}
          {(!showWidgetTimezoneSelector && dangerousHTML(t('settings.section.widget.timezone.disabledText'))
          )}
        </ListItem>
      </FormSection>
      {(!hideWidgetSettingsConversions &&
        <FormSection className='no-margin'>
          <SectionTitle label={t('settings.section.widget.conversionTracking.heading')} icon='chart-area' />
          <ListItem>

            {((rawSettings.widgetConversions === null || (rawSettings.widgetConversions.facebookPixelId === null && rawSettings.widgetConversions.googleAnalyticsId === null)) &&
              <>
                <strong>{t('global.defaultValue')}</strong>
                <br />
              </>
            )}

            {((widgetConversions.facebookPixelId || widgetConversions.googleAnalyticsId)
              ? dangerousHTML(t('settings.section.widget.conversionTracking.enabled.description'))
              : dangerousHTML(t('settings.section.widget.conversionTracking.disabled.description'))
            )}

            {((widgetConversions.facebookPixelId || widgetConversions.googleAnalyticsId) &&
              <BorderedBox>
                {(widgetConversions.facebookPixelId &&
                  <>
                    <ListItem className='bullet-item black'>
                      {dangerousHTML(t('settings.section.widget.conversionTracking.facebookPixel.heading'))}
                    </ListItem>
                    <ListItem className='bullet-item'>
                      {dangerousHTML(t('settings.section.widget.conversionTracking.facebookPixelID.heading'))}<span>: {widgetConversions.facebookPixelId}</span>
                    </ListItem>
                  </>
                )}
                {(widgetConversions.googleAnalyticsId &&
                  <>
                    <ListItem className='bullet-item black'>
                      {dangerousHTML(t('settings.section.widget.conversionTracking.googleAnalytics.heading'))}
                    </ListItem>
                    <ListItem className='bullet-item'>
                      {dangerousHTML(t('settings.section.widget.conversionTracking.googleAnalyticsID.heading'))}<span>: {widgetConversions.googleAnalyticsId}</span>
                    </ListItem>
                  </>
                )}
                <ListItem className='bullet-item black'>
                  {dangerousHTML(t('settings.section.widget.conversionTracking.customConsentMessage.heading'))}
                </ListItem>
                {(widgetConversions.messageTarget &&
                  <>
                    <ListItem className='bullet-item'>
                      {t(`settings.section.widget.conversionTracking.customMessageVisibility.${widgetConversions.messageTarget}.heading`)}
                    </ListItem>
                  </>
                )}
                <ListItem className='bullet-item'>
                  {widgetConversions.customMessage ? dangerousHTML(widgetConversions.customMessage, false, true) : dangerousHTML(t('consentMessage.text'))}
                </ListItem>
              </BorderedBox>
            )}
          </ListItem>
        </FormSection>
      )}

      <FormSection>
        <SectionTitle label={t('settings.section.widget.redirectToExternal.heading')} icon='external-link-alt' />
        <ListItem>
          {(rawSettings.widgetExternalUrlRedirect === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {(widgetExternalUrlRedirect
            ? t('settings.section.widget.redirectToExternal.enabled.infoText')
            : t('settings.section.widget.redirectToExternal.disabled.infoText')
          )}
          {(widgetExternalUrlRedirect &&
            <BorderedBox>
              <ListItem className='bullet-item'>
                <Link to={widgetExternalUrlRedirectWithHttp} target='_blank' external>
                  {widgetExternalUrlRedirectWithHttp}
                </Link>
              </ListItem>
            </BorderedBox>
          )}
        </ListItem>
      </FormSection>
    </div>
  )
}

export default BookingSettingsWidget
