import React, { Fragment, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Avatar
  // t
} from '../../Common'

const SelectOptions = (props) => {
  const {
    // multi,
    value,
    renderItem,
    selectOptions,
    noResultsText,
    hideNoResults,
    hasCategories,
    handleOnSelect,
    selectedOptionsIndex,
    optionsIncludeAllOption
    // handleOnSelectAllCategory
  } = props

  const areCategoriesEmpty = useMemo(() =>
    selectOptions.every(category => (!category.name || (category.items || []).length === 0)),
  [selectOptions])
  const noResults = selectOptions.length === 0 || (hasCategories && areCategoriesEmpty)

  const renderOption = useCallback((item, index, categoryName, categoryIndex) => {
    const { label, avatarName, avatarImage, avatarColor, color } = item
    const isActive = `${item.value}` === (typeof value === 'number' ? `${value}` : value)
    const hasAvatar = avatarName || avatarImage || avatarColor
    const hasColor = !!color
    let relativeSelectedOptionsIndex = selectedOptionsIndex
    if (hasCategories && Number.isInteger(selectedOptionsIndex) && item.value !== 'all') {
      const previousCategoriesItemsCount = selectOptions
        .filter((_, i) => i < categoryIndex)
        .reduce((acc, category) => acc.concat(category.items || []), [])
        .length
      relativeSelectedOptionsIndex -= previousCategoriesItemsCount
    }
    if (optionsIncludeAllOption && categoryIndex > 0) relativeSelectedOptionsIndex -= 1
    const isSelected = index === relativeSelectedOptionsIndex

    if (renderItem) {
      return (
        <React.Fragment key={index}>
          {renderItem(
            item,
            index,
            {
              isActive,
              isSelected,
              onSelect: (e) => {
                e.stopPropagation()
                handleOnSelect(item, categoryName)
              }
            }
          )}
        </React.Fragment>
      )
    }

    const itemClassNames = ['ta-select__options__item']
    if (hasAvatar) itemClassNames.push('hasAvatar')
    if (isActive) itemClassNames.push('active')
    if (isSelected) itemClassNames.push('selected')

    return (
      <div
        key={item.value}
        className={itemClassNames.join(' ')}
        onClick={(e) => {
          e.stopPropagation()
          handleOnSelect(item, categoryName)
        }}
      >
        {(hasAvatar &&
          <Avatar name={avatarName} image={avatarImage} color={avatarColor} />
        )}
        {(hasColor &&
          <div
            className='ta-select__options__item__color'
            style={{
              backgroundColor: color,
              boxShadow: isActive ? `0 0 0 1px ${color}` : 'none'
            }}
          />
        )}
        <span className='ta-select__options__item__label'>{label}</span>
      </div>
    )
  }, [handleOnSelect, hasCategories, renderItem, selectOptions, selectedOptionsIndex, value, optionsIncludeAllOption])

  const renderItems = useCallback(
    (items, categoryName, categoryIndex) =>
      items.map((item, index) => renderOption(item, index, categoryName, categoryIndex)),
    [renderOption]
  )

  return (
    <>
      {(!hideNoResults && noResults &&
        <div className='ta-select__options__no-results-text'>{noResultsText}</div>
      )}
      {!noResults && !hasCategories && renderItems(selectOptions)}
      {!noResults && hasCategories && selectOptions.map((category, index) => {
        let { name, items, extraText } = category
        items = items || []
        if (!name) return renderOption(category, index) // if option is 'all'
        if (items.length === 0) return null

        return (
          <Fragment key={index}>
            <div className='ta-select__options__category'>
              <div className='ta-select__options__category-name'>
                {name}
                {(extraText &&
                  <span className='ta-select__options__category-extraText'>
                    ({extraText})
                  </span>
                )}
              </div>
              {/* {multi &&
                <button
                  type='button'
                  className='ta-btn ta-btn-primary ta-select__options__category-button'
                  onClick={() => handleOnSelectAllCategory(category)}
                >
                  {t('buttons.selectAll.label')}
                </button>
              } */}
            </div>
            {renderItems(items, name, index)}
          </Fragment>
        )
      })}
    </>
  )
}

SelectOptions.propTypes = {
  value: PropTypes.any,
  multi: PropTypes.bool,
  renderItem: PropTypes.func,
  noResultsText: PropTypes.any,
  hideNoResults: PropTypes.bool,
  hasCategories: PropTypes.bool,
  selectOptions: PropTypes.array,
  handleOnSelect: PropTypes.func,
  selectedOptionsIndex: PropTypes.number,
  optionsIncludeAllOption: PropTypes.bool,
  handleOnSelectAllCategory: PropTypes.func
}

export default SelectOptions
