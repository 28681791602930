import { payloads$, actions, handlers, store, selectors } from '../..'
import { q } from '../../API'
import { DEFAULT_LOCALE } from '../../../Settings'
import {
  loginServerErrorsTransform,
  loginFormValidate,
  loginSSOFormValidate,
  loginSSOFormServerErrorsTransform
  // loginFormInitialValues
} from './utils'

const setLoginErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('login', errors)
  handlers.loginFormReady()
  scrollToError && scrollToError(errors)
}

// Login with SSO
payloads$(actions.LOGIN_WITH_SSO)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = loginSSOFormValidate(form)
    if (errors.length) return setLoginSSOErrors(errors, scrollToError)
    const { email } = form || {}
    const { value: emailValue } = email || {}
    const url = await q('getRedirectBranchManagerURL', { email: emailValue })
    const { error } = url || { error: { code: 'SSOError' } }
    const hasUrlParams = (url || '').includes('?')
    if (error) return setLoginSSOErrors(loginSSOFormServerErrorsTransform(error), scrollToError)
    window.location = `${url}${hasUrlParams ? '&' : '?'}redirectURI=${window.location.origin}/login`
  })

const setLoginSSOErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('sso', errors)
  handlers.loginFormReady()
  scrollToError && scrollToError(errors)
}

// Pass 2FA
payloads$(actions.AUTH_TWO_FA)
  .subscribe(async ({ code, callback }) => {
    const tokens = await q('authenticate2FA', { code, type: 'OTP' })
    const { error } = tokens
    if (error && error.code === 'Auth2FACodeValidationFailed') {
      handlers.formErrorsSet('twoFA', [{ key: 'code', value: 'errors.twoFA.invalidToken' }])
      handlers.loginPendingDefaultSet()
    }
    if (error) return setLoginErrors(loginServerErrorsTransform(error))
    handlers.authTokensChange(tokens)
    handlers.twoFAFormPopulate()
    handlers.loginPendingDefaultSet()
    callback()
  })

// Login with email and password
payloads$(actions.LOGIN_WITH_EMAIL_AND_PASSWORD)
  .subscribe(async ({ fields, scrollToError }) => {
    const errors = loginFormValidate(fields)
    if (errors.length) return setLoginErrors(errors, scrollToError)

    const tokens = await q('loginEnterpriseAccountWithEmailAndPassword', {
      email: fields.email,
      password: fields.password,
      locale: fields.locale,
      longSession: fields.remember
    })
    const { error } = tokens

    if (error) return setLoginErrors(loginServerErrorsTransform(error), scrollToError)
    handlers.authTokensChange(tokens)
  })

// Login with one time token
payloads$(actions.LOGIN_WITH_ONE_TIME_TOKEN)
  .subscribe(async token => {
    const tokens = await q('getTokensWithOneTimeLoginToken', {
      authScope: 'ENTERPRISE',
      loginToken: token,
      longSession: false
    })
    const { error } = tokens
    if (error && error.code === 'Auth2FACodeRequired') {
      handlers.loginPendingDefaultSet()
      return
    }
    if (error) {
      // TODO: handle error
      handlers.loginFormReady()
      return
    }
    handlers.logout()
    setTimeout(() => handlers.authTokensChange(tokens), 1500)
  })

// Auth tokens change
payloads$(actions.AUTH_TOKENS_CHANGE)
  .subscribe(async tokens => {
    const { accessToken, refreshToken, sessionDuration, account } = tokens
    handlers.authTokensPopulate({
      accessToken,
      refreshToken,
      expires: (new Date()).getTime() + parseInt(sessionDuration, 10)
    })

    handlers.accountChanged(account)
  })

// Logout
payloads$(actions.LOGOUT)
  .subscribe(async ({ noRedirect }) => {
    const state = store.getState()
    const { app } = state
    const routerLocale = selectors.routerDataFieldSelector(state, { field: 'locale' })
    const locale = routerLocale || app.locale || DEFAULT_LOCALE

    // Reset to initial state
    handlers.branchesReset()
    handlers.accountReset()
    handlers.companyAppcuesLogout()
    handlers.branchesReset()
    handlers.invoicesReset()
    handlers.companyReset()
    handlers.tagsReset()
    handlers.usersReset()
    handlers.appsReset()
    handlers.servicesReset()
    handlers.resourcesReset()
    handlers.coursesReset()
    handlers.customersReset()
    handlers.customerFieldsReset()
    handlers.companyTagsReset()
    handlers.appsPrivateAccessKeyReset()
    handlers.permissionTypesReset()
    handlers.userPermissionTypesReset()
    handlers.overlayHide()

    let loginUrl = '/login'

    if (locale !== DEFAULT_LOCALE) loginUrl += `?locale=${locale}`
    handlers.loginFormPopulate()
    if (noRedirect) return
    handlers.navigateToPath(loginUrl)
  })
