import React, { useEffect, useState } from 'react'
import { connect, handlers } from '../../Store'
import { AccountSelect } from '../../Beauties'
import { sortAccounts } from '../../Utils'
import { TwoFALoginFormWrapper } from '../../Beasts'

const AccountSelectWrapper = props => {
  const { accounts, pending, search, account } = props
  const [isPassed2FA, setIsPassed2FA] = useState(false)
  const [company2FAId, setCompany2FAId] = useState(null)
  const isAccountUses2FA = account.auth2FAs && account.auth2FAs[0] && account.auth2FAs[0].isActive

  useEffect(() => {
    return () => handlers.formSet('accountsSearch', {})
  }, [])

  const checkIsAccountUses2FA = (id) => {
    const account = accounts.find(account => account.id === id)
    if (!account) return false

    return account.settings && account.settings.requiredEnterpriseAuth2FAType === 'OTP' && !isAccountUses2FA
  }

  const onAccountChange = (id) => {
    if (checkIsAccountUses2FA(id)) return setCompany2FAId(id)
    handlers.accountSelect(id)
  }

  const onAccountSelect = code => {
    handlers.loginWith2FA(code, () => setIsPassed2FA(true))
  }

  const onResendEmailClick = ({ accountId, email }) => {
    handlers.accountEmailConfirmationSend({ accountId, email })
  }

  const onBack = () => {
    setCompany2FAId(null)
  }

  const sortedAccounts = accounts.sort(sortAccounts)
  const showSearch = accounts.length > 5

  if ((isAccountUses2FA && !isPassed2FA) || (!!company2FAId && !isAccountUses2FA)) {
    const company = accounts.find(acc => acc.id === company2FAId)
    return (
      <TwoFALoginFormWrapper onAccountSelect={onAccountSelect} company={company} onBack={onBack} />
    )
  }

  return (
    <AccountSelect
      accounts={sortedAccounts}
      pending={pending}
      onAccountChange={onAccountChange}
      onResendEmailClick={onResendEmailClick}
      showSearch={showSearch}
      search={search}
    />
  )
}

const maps = state => ({
  accounts: state.account.accounts || [],
  account: state.account || {},
  pending: state.account.pendingAccountSelect || false,
  search: (state.forms.accountsSearch.search && state.forms.accountsSearch.search.value) || ''
})

export default connect(maps)(AccountSelectWrapper)
