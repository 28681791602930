import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SectionTitle,
  Form,
  Col,
  Row,
  FormSection,
  Input,
  FormButtons,
  // SingleSelect,
  t,
  Error,
  AddressInput,
  PhoneInput
} from '../../../Common'

import '../Account.css'

const AccountForm = props => {
  const { title, onSubmit, cancelLink, showAutocompleteCompanyEditForm } = props

  return (
    <div className='ta-account__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-account__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='account'>
            <FormSection>
              <SectionTitle label={t('account.form.section.keyAccountDetails.heading')} icon='briefcase' />
              <Row>
                <Col>
                  <Input
                    clearable
                    name='companyName'
                    label={t('account.form.section.keyAccountDetails.companyName.label')}
                    mandatory
                    hasAutocomplete={showAutocompleteCompanyEditForm}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    clearable
                    name='slug'
                    label={t('account.form.section.keyAccountDetails.slug.label')}
                    hasAutocomplete={showAutocompleteCompanyEditForm}
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <SingleSelect name='language' label={t('account.form.section.keyAccountDetails.language.label')} />
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                  <SingleSelect name='timezone' label={t('account.form.section.keyAccountDetails.timezone.label')} />
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <PhoneInput
                    name='phone'
                    label={t('account.form.section.keyAccountDetails.phone.label')}
                    mandatory
                    hasAutocomplete={showAutocompleteCompanyEditForm}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AddressInput
                    clearable
                    name='address'
                    label={t('account.form.section.keyAccountDetails.address.label')}
                    withMap
                    hasAutocomplete={showAutocompleteCompanyEditForm}
                  />
                </Col>
              </Row>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

AccountForm.propTypes = {
  message: PropTypes.object
}

export default AccountForm
