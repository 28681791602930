import React from 'react'
import {
  Row,
  Col,
  Form,
  FormButtons,
  Input,
  t
} from '../../Common'

const SSOForm = props => {
  const { onSubmit } = props

  return (
    <div className='ta-sso-form'>
      <Form onSubmit={onSubmit} name='sso'>
        <Row>
          <Col>
            <Input
              name='email'
              label={t('sso.fieldEmail.label')}
              mandatory
            />
          </Col>
        </Row>
        <FormButtons>
          <button type='submit' className='ta-btn ta-btn-dark ta-btn-block'>
            {t('sso.login.btn')}
          </button>
        </FormButtons>
      </Form>
    </div>
  )
}

export default SSOForm
