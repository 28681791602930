import { PopupTriggerWrapper } from '../../../Beasts'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  IconListItem,
  IconListItemBody,
  IconListItemTitle,
  Link,
  SectionTitle,
  Title,
  t
} from '../../../Common'
import { getAttributeFieldLabel } from '../../../Utils'

import './AttributePreview.css'

const AttributePreview = ({ attribute, category, isAdmin, hash }) => {
  const {
    id,
    externalId,
    name,
    fieldType,
    isAvailableInWidget,
    tooltipTxt,
    explanationTxt
  } = attribute || {}
  const { name: categoryName } = category || {}

  return (
    <div className='ta-attributes__preview'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-attributes__preview__title'>
            <Title label={name}>
              {isAdmin && (
                <>
                  <PopupTriggerWrapper name='attribute-delete' position='bottom' id={id}>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('attributes.deleteAttribute')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Link
                          className='ta-btn ta-btn-delete ta-btn-icon-only'
                          external
                        >
                          <FontAwesome5 icon='trash' />
                        </Link>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('attributes.editAttribute')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        to={`/management/attributes/${id}/edit@@${hash}`}
                        className='ta-btn ta-btn-primary ta-btn-icon-only'
                      >
                        <FontAwesome5 icon='pencil' />
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <div className='ta-attributes__preview__summary'>
            <SectionTitle label={t('global.details')} icon='file' ignoreTopOffset />
            <IconListItem>
              <IconListItemTitle width='160px'>{t('attributes.preview.attributeId')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{id}</IconListItemBody>
            </IconListItem>
            {externalId && (
              <IconListItem>
                <IconListItemTitle width='160px'>{t('global.externalId')}:</IconListItemTitle>
                <IconListItemBody offset='160px'>{externalId}</IconListItemBody>
              </IconListItem>
            )}
            <IconListItem>
              <IconListItemTitle width='160px'>{t('global.category')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{categoryName}</IconListItemBody>
            </IconListItem>
            <IconListItem>
              <IconListItemTitle width='160px'>{t('global.fieldType')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{t(getAttributeFieldLabel(fieldType))}</IconListItemBody>
            </IconListItem>
            <IconListItem>
              <IconListItemTitle width='160px'>{t('global.bookingWidgetDisplay')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{t(isAvailableInWidget ? 'global.yes' : 'global.no')}</IconListItemBody>
            </IconListItem>
            <SectionTitle label={t('global.settings')} icon='cogs' />
            <IconListItem>
              <IconListItemTitle width='160px'>{t('attributes.includeExplanation')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{t(explanationTxt ? 'global.yes' : 'global.no')}</IconListItemBody>
            </IconListItem>
            <IconListItem>
              <IconListItemTitle width='160px'>{t('attributes.includeTooltip')}:</IconListItemTitle>
              <IconListItemBody offset='160px'>{t(tooltipTxt ? 'global.yes' : 'global.no')}</IconListItemBody>
            </IconListItem>
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default AttributePreview
