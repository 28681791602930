import React from 'react'
import {
  Title,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  SectionTitle,
  Link,
  t
} from '../../../Common'

import './UserGroupPreview.css'

const UserGroupPreview = props => {
  const { user, hash } = props
  const availableTabs = ['details']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]

  return (
    <div className='ta-user-group-details'>

      <FixedContent>
        <FixedContentHeader>
          <div className='ta-user-group__title'>
            <Title label={t('global.userDetails')}>
              <Link to={`/managers/users/${user.id}/edit`} className='ta-btn ta-btn-primary'>
                <FontAwesome5 icon='pen' />
              </Link>
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <>
            <SimpleTabs active={activeTab}>
              <div className='ta-user-group-details__tabs-wrapper'>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='building' label={t('global.details')} />
                  <SimpleTabsHeaderItem name='resources' icon='users' label={t('global.resources')} />
                  <SimpleTabsHeaderItem name='statistics' icon='chart-bar' label={t('global.statistics')} />
                  <SimpleTabsHeaderItem name='fullfilmentLog' icon='chart-bar' label={t('global.fullfilmentLog')} />
                  <SimpleTabsHeaderItem name='history' icon='chart-area' label={t('global.history')} />
                </SimpleTabsHeader>
              </div>
              <SimpleTabsBody>

                <SimpleTabsBodyItem name='details'>
                  <SectionTitle label={t('userGroup.preview.section.owner.title')} icon='briefcase' />
                  <IconListItem iconType='s'>
                    <IconListItemTitle width='130px'>ID</IconListItemTitle>
                    <IconListItemBody offset='130px'>{user.id}</IconListItemBody>
                  </IconListItem>

                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.names')}</IconListItemTitle>
                    <IconListItemBody offset='130px'>{user.names}</IconListItemBody>
                  </IconListItem>

                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.phone')}</IconListItemTitle>
                    <IconListItemBody offset='130px'>
                      {user.phone}
                    </IconListItemBody>
                  </IconListItem>

                  <IconListItem>
                    <IconListItemTitle width='130px'>{t('global.email')}</IconListItemTitle>
                    <IconListItemBody offset='130px'>
                      {user.email}
                    </IconListItemBody>
                  </IconListItem>

                </SimpleTabsBodyItem>

              </SimpleTabsBody>

            </SimpleTabs>
          </>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default UserGroupPreview
