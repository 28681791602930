import React from 'react'
import {
  Title,
  AvailabilityTimelines,
  t,
  FontAwesome5
} from '../../../Common'

const ServicePreviewAvailability = ({ calendar, hasSpecificBookingDays }) => {
  return (
    <>
      {hasSpecificBookingDays && calendar && calendar?.bookingWeeklyAllowancePlan && (
        <>
          <div className='services__preview_title_container'>
            <FontAwesome5 icon='clock' />
            <Title label={t('servicesGroups.section.service.availability')} ignoreTopOffset size='m' />
          </div>
          <AvailabilityTimelines firstTimeline={calendar.bookingWeeklyAllowancePlan} />
        </>
      )}
    </>
  )
}

export default ServicePreviewAvailability
