import React from 'react'
import {
  DnDDraggable,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  ListButton,
  Price,
  DottedTimeline,
  DottedTimelineItem,
  t,
  Link
} from '../../Common'
import { convertMinutesToDaysHoursMinutes, highlight, truncateText, dangerousHTML } from '../../Utils'

const CoursesListItem = props => {
  const {
    course,
    index,
    isDragAndDropMode,
    areCategoriesExpanded,
    search
  } = props
  const classNames = ['ta-courses__course']
  if (isDragAndDropMode) classNames.push('is-draggable')
  if (course.isDeleted) classNames.push('deleted')
  if (course.isUpdated) classNames.push('updated')
  const translations = {
    minutes: t('global.minutes'),
    hours: t('global.hours'),
    days: t('global.days')
  }
  let { locallyUpdatedBranches } = course || {}
  locallyUpdatedBranches = locallyUpdatedBranches || {}
  const globalLabelClassNames = ['global-label', 'edited']
  const locallyChangedBranches = (locallyUpdatedBranches && Object.keys(locallyUpdatedBranches).length) || false
  const globalLabelPosition = 'top'

  return (
    <div className={classNames.join(' ')}>
      <DnDDraggable
        className='ta-courses__course__dnd-wrapper'
        type='course'
        id={course.id}
        index={index}
        isDragDisabled={!isDragAndDropMode || !areCategoriesExpanded}
      >
        <SimpleListItem
          to={!isDragAndDropMode && `/management/courses/${course.id}`}
          status={(course.isStalled || course.hasOnlineWarning || course.hasOnlinePaymentWarning
            ? 'stalled'
            : course.isBookable
              ? 'active'
              : 'inactive'
          )}
          draggable={isDragAndDropMode}
        >
          <SimpleListItemTitle title={highlight(truncateText(course.name, 37, true), search)}>
            {(course.hasOnlinePayment &&
              <FontAwesome5
                icon='cc-stripe'
                type='brand'
                className='ta-stripe-active'
              />
            )}
            {(course.offer &&
              <HoverPopup>
                <HoverPopupContent position={course.name.length > 35 ? 'left' : 'right'}>
                  <strong>{t('servicesGroups.list.group.warning.tooltip.offer')}:</strong>  {/* Assigned to offer */}
                  <div>{course.offer.name}</div>
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <FontAwesome5 icon='bullhorn' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
            {(locallyChangedBranches &&
              <HoverPopup>
                <HoverPopupContent position={globalLabelPosition}>
                  {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
                </HoverPopupContent>
                <Link to={`/management/courses/${course.id}@@changedLocally`}>
                  <div className={globalLabelClassNames.join(' ')}>
                    <HoverPopupTrigger>
                      <FontAwesome5 icon='pencil' type='s' />
                      {t('global.changedLocally')}
                    </HoverPopupTrigger>
                  </div>
                </Link>

              </HoverPopup>
            )}
          </SimpleListItemTitle>
          <SimpleListItemContent>
            <ul className='ta-list-inline'>
              <li><FontAwesome5 icon='users' type='solid' /> {course.resourcesCount} {t('global.resources')}</li>
              {(course.duration &&
                <li><FontAwesome5 icon='hourglass-half' type='solid' /> {convertMinutesToDaysHoursMinutes(course.duration, translations)}</li>
              )}
              {(course.price > 0 &&
                <li><FontAwesome5 icon='money-bill' type='solid' /> <Price price={course.price} /></li>
              )}
              {(course.durationsPattern && course.durationsPattern.length > 1 &&
                <li>
                  <HoverPopup>
                    <HoverPopupContent position='right' autoSize>
                      <DottedTimeline>
                        {t('global.duration')}:
                        {course.durationsPattern.map((item, index) => {
                          const label = t(index % 2 === 0 ? 'global.interval' : 'global.gap')
                          return (
                            <div key={index}>
                              <DottedTimelineItem type='big'><strong>{label}</strong></DottedTimelineItem>
                              <DottedTimelineItem type='small' className={index === course.durationsPattern.length - 1 ? 'last' : ''}>
                                {convertMinutesToDaysHoursMinutes(item, translations)}
                              </DottedTimelineItem>
                            </div>
                          )
                        })}
                      </DottedTimeline>
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <FontAwesome5 icon='info-circle' type='solid' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </li>
              )}
            </ul>
          </SimpleListItemContent>
          {(isDragAndDropMode &&
            <SimpleListItemButtons>
              <ListButton icon='arrows-v' />
            </SimpleListItemButtons>
          )}
          {((course.isStalled || course.hasOnlineWarning || course.hasOnlinePaymentWarning) &&
            <HoverPopup className='ta-course__popup' disabled={!course.isStalled && !course.hasOnlineWarning && !course.hasOnlinePaymentWarning}>
              <HoverPopupContent position='left'>
                {course.isStalled && t('servicesGroups.list.service.warning.tooltip.dependencies')}
                {course.hasOnlineWarning && t('servicesGroups.list.service.warning.tooltip.online')}
                {course.hasOnlinePaymentWarning && t('servicesGroups.list.service.warning.onlinePayment')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className='ta-courses__course__is-stalled'>
                  <FontAwesome5 icon='exclamation-triangle' type='solid' />
                </div>
              </HoverPopupTrigger>
            </HoverPopup>
          )}
        </SimpleListItem>
      </DnDDraggable>
    </div>
  )
}

export default CoursesListItem
