import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { ActivityLogs } from '../../Beauties'
import { isRouteDisabledByCustomisations } from '../../Common'

const ActivityLogsWrapper = props => {
  let {
    pending,
    activityLogs,
    activityLogsLastPage,
    activityLogsCount,
    message
  } = props
  activityLogs = activityLogs || []
  activityLogsCount = activityLogsCount || 0
  const [hasAdvancedFilter, setHasAdvancedFilter] = useState(true)

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.formSet('activityLogsFilters', {})
    handlers?.activityLogsListGet()
  }, [])

  const loadMore = page => !pending && handlers.activityLogsListPageIncrement(page)

  const toggleAdvancedOptions = () => {
    setHasAdvancedFilter((prev) => !prev)
  }

  return (
    <ActivityLogs
      activityLogs={activityLogs.map(activityLog => ({ ...activityLog }))}
      activityLogsCount={activityLogsCount}
      pending={pending}
      hasMore={!activityLogsLastPage}
      loadMore={loadMore}
      hasAdvancedFilter={hasAdvancedFilter}
      toggleAdvancedOptions={toggleAdvancedOptions}
      message={message}
    />
  )
}

ActivityLogsWrapper.propTypes = {
  activityLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  activityLogsLastPage: selectors.activityLogsLastPageSelector(state),
  activityLogsCount: selectors.activityLogsCountSelector(state),
  pending: selectors.activityLogsPendingSelector(state),
  activityLogs: selectors.activityLogsListSelector(state),
  message: selectors.activityLogsMessageListSelector(state)
})

export default memo(connect(maps)(ActivityLogsWrapper))
