import React from 'react'

import './BorderedBox.css'

const BorderedBox = props => {
  const { children, topOffset, noOffset, className, ...rest } = props
  const classNames = ['ta-bordered-box']
  if (topOffset) classNames.push('top-offset')
  if (noOffset) classNames.push('no-offset')
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} {...rest}>
      {children}
    </div>
  )
}

export default BorderedBox
