import React from 'react'
import { renderToString } from 'react-dom/server'

import {
  Row,
  Col,
  Alert,
  Price,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './AppsSubscription.css'

const AppsSubscriptionFormButtons = (props) => {
  const {
    type,
    hasBillingDetails,
    hasPaymentMethod,
    noAutoCollect,
    onSubmit,
    showMessage,
    limitValue,
    extraConsumedCharges,
    hasChangesBillingForm,
    currencyCode
  } = props

  return (
    <>
      {type === 'edit' && (
        <>
          {(showMessage &&
            <Row noOffset>
              <Col>
                <Alert
                  theme='alert'
                  hasTopOffset
                >
                  {!!limitValue && t('apps.subscription.form.changeThreshold.message.label')}
                  {!limitValue && dangerousHTML(t('apps.subscription.form.changeThreshold.spent.message.label', [{ key: 'PRICE', value: renderToString(<Price currency={currencyCode} price={extraConsumedCharges} />) }]))}
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <HoverPopup block disabled={!hasChangesBillingForm}>
                <HoverPopupContent position='top'>
                  {dangerousHTML(t('apps.subscriptions.form.billingFormHasChanges.tooltip'))}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <button onClick={onSubmit} className={`ta-btn ta-btn-primary ${hasChangesBillingForm ? 'disabled' : ''}`}>
                    {t('buttons.saveChanges')}
                  </button>
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
        </>
      )}
      {(type !== 'edit' && hasBillingDetails && (noAutoCollect || hasPaymentMethod) &&
        <>
          <Row>
            <Col>
              <div className='ta-apps__subscription-form__terms'>
                {t('apps.subscriptions.form.terms.text')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <HoverPopup block disabled={!hasChangesBillingForm}>
                <HoverPopupContent position='top'>
                  {dangerousHTML(t('apps.subscriptions.form.billingFormHasChanges.tooltip'))}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <button onClick={onSubmit} className={`ta-btn ta-btn-block ta-btn-primary ${hasChangesBillingForm ? 'disabled' : ''}`}>
                    {t('apps.subscriptions.form.confrimAndPay.button.text')}
                  </button>
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default AppsSubscriptionFormButtons
