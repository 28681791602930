import fetch from 'isomorphic-fetch'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES, PATH_TO_S3_LOCALE } from '../Settings'
import { flattenMessages } from '../Utils'
import { store, selectors } from '../Store'

export const translations = {}

export const fetchLocale = async (locale, version, fetchFromS3 = false, defaultLocales = undefined) => {
  let locales = null
  if (fetchFromS3) {
    try {
      locales = await fetch(`${PATH_TO_S3_LOCALE}/${locale}.json?v=${version}`).then(response => response.json())
    } catch (error) {
      locales = defaultLocales
      console.warn('Translations error: ', error)
    }
  } else {
    try {
      locales = await import(`../Assets/locales/${locale}.json`)
      locales = locales.default
    } catch (error) {
      locales = defaultLocales
      console.warn('Translations error: ', error)
    }
  }
  return locales
}

export const languageChange = async (locale = DEFAULT_LOCALE, cb) => {
  const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)
  const loadFromS3 = isStaging && PATH_TO_S3_LOCALE
  const v = new Date().getTime()
  locale = locale && locale.toLowerCase()
  if (SUPPORTED_LOCALES.indexOf(locale) === -1) locale = DEFAULT_LOCALE
  const defaultTranslations = await fetchLocale(`en-gb`, v, loadFromS3)
  if (!defaultTranslations) return cb(locale)
  const data = locale === 'en-gb' ? defaultTranslations : await fetchLocale(locale, v, loadFromS3, defaultTranslations)
  const defaultHolidaysTranslations = await fetchLocale('holidays/en-gb', v)
  const holidaysData = locale === 'en-gb' ? defaultHolidaysTranslations : await fetchLocale(`holidays/${locale}`, v, false, defaultHolidaysTranslations)
  // Load text customisations
  const state = store.getState()
  const customisations = selectors.customisationsSelector(state)
  const { translations: customisationTranslations } = customisations || {}
  let { _all_: allLocales } = customisationTranslations || {}
  allLocales = allLocales || []
  const customisationTranslationsObject = allLocales.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {})
  translations[locale] = {
    ...flattenMessages(defaultTranslations || {}),
    ...flattenMessages(data || {}),
    ...flattenMessages(defaultHolidaysTranslations || {}),
    ...flattenMessages(holidaysData || {}),
    ...flattenMessages(customisationTranslationsObject || {})
  }
  cb(locale)
}