import React from 'react'

const FixedContentHeader = props => {
  const { children } = props

  return (
    <div className='ta-fixed-content__header'>{children}</div>
  )
}

export default FixedContentHeader
