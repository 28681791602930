import React from 'react'
import {
  Col,
  Row,
  FontAwesome5,
  Form,
  SingleSelect,
  Link,
  Blur,
  t
} from '../../Common'
import { StatisticsWrapper, StatisticsCompositeWrapper } from '../../Beasts'
import { truncateText } from '../../Utils'

import './Dashboard.css'

const DashboardMock = props => {
  let { usePermissionText } = props
  usePermissionText = !!usePermissionText
  const classNames = ['ta-dashboard']

  return (
    <div className={classNames.join(' ')}>
      <Row>
        <Col size={80} className='ta-dashboard__filters-container'>
          <Form name='dashboardFilters' className='ta-statistics-filters'>
            <Row>
              <Col size={20} className='no-right-padding'>
                <SingleSelect
                  name='type'
                  label={t('global.type.label')}
                  options={[
                    { label: t('statistics.lifetime.lifetime'), value: 'lifetime' },
                    { label: t('statistics.lifetime.month'), value: 'months' }
                  ]}
                  value='lifetime'
                  onChange={null}
                  disabled
                />
              </Col>

              <Col size={20} className='no-right-padding' >
                <Link
                  className='ta-btn ta-statistics__filter-submit-btn ta-info disabled'
                  external
                  onClick={() => null}
                  disabled
                >
                  <FontAwesome5 icon='info' color='#505a5e' type='s' />
                </Link>
              </Col>
              <Col size={20} />
              <Col size={20} />
              <Col size={20} />
            </Row>
          </Form>
        </Col>
        <Col className='ta-dashboard-export-btn-container' size={20}>
          <Link
            type='submit'
            className='ta-btn ta-dashboard-export-btn disabled'
            disabled
          >
            <FontAwesome5 icon='file-export' type='s' />
            {t('global.exportData')}
          </Link>
        </Col>
      </Row>
      <Blur
        active
        label={usePermissionText ? null : t('global.noPermissions')}
        icon={usePermissionText ? 'ban' : 'frown'}
        text={usePermissionText
          ? t('global.accessDenied')
          : t('statistics.empty.label')}
        button={usePermissionText ? null : { link: '/branches/add', label: t('branches.add.label') }}
        extraClassName='ta-dashboard'
      >
        <Row>
          <Col>
            <div className='ta-dashboard__section'>Overview</div>
            <p className='ta-dashboard__section--subheading'>A summary of your business activity: from the date you opened your TIMIFY account up to today</p>
          </Col>
        </Row>

        <Row>
          <Col size={50}>
            <StatisticsCompositeWrapper
              data={{
                addend1: 111,
                sum: 412,
                equationMiddle: {
                  addend1: 180,
                  addend2: 131,
                  sum: 301,
                  label1Color: '#769851',
                  label1TextColor: '#769851',
                  label1Text: t('statistics.viaWidget'),
                  label1Icon: 'window-maximize',
                  label2Color: '#505a5e',
                  label2TextColor: '#505a5e',
                  label2Text: t('statistics.viaCalendar'),
                  label2Icon: 'calendar-alt'
                }
              }}
              texts={{
                title: t('statistics.serviceBookings'),
                subTitle: t('statistics.services.extraInfo'),
                textAddend1: t('global.adhoc'),
                textAddend2: 'Services',
                textSum: t('global.total')
              }}
              tooltip={{
                text: 'Single bookings - ad-hoc and/or with a service - that were added to your calendar. <br/> <br/> Ad-hoc refers to bookings added to your calendar, where no service was selected.',
                position: 'left'
              }}
            />
          </Col>
          <Col size={50}>
            <StatisticsCompositeWrapper
              data={{
                addend1: 90,
                sum: 200,
                equationMiddle: {
                  addend1: 30,
                  addend2: 80,
                  sum: 110,
                  label1Color: '#769851',
                  label1TextColor: '#769851',
                  label1Text: t('statistics.viaWidget'),
                  label1Icon: 'window-maximize',
                  label2Color: '#505a5e',
                  label2TextColor: '#505a5e',
                  label2Text: t('statistics.viaCalendar'),
                  label2Icon: 'calendar-alt'
                }
              }}
              texts={{
                title: t('statistics.singleBookingSignUps'),
                subTitle: 'Single bookings with customers',
                textAddend1: t('global.adhoc'),
                textAddend2: 'Services',
                textSum: t('global.total')
              }}
              tooltip={{
                text: 'Sign-ups are customers who self-schedule themselves for a service, or are manually added to a single booking. <br/>  <br/> Ad-hoc refers to bookings added to your calendar, where no service was selected.',
                position: 'left'
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col size={50}>
            <StatisticsCompositeWrapper
              data={{
                addend1: 90,
                addend2: 130,
                sum: 220
              }}
              texts={{
                title: t('statistics.groupBookings'),
                subTitle: t('statistics.groups.addedToCalendar'),
                textAddend1: t('global.adhoc'),
                textAddend2: t('global.groups'),
                textSum: t('global.total')
              }}
              tooltip={{
                text: t('statistics.groups.tooltip'),
                position: 'left'
              }}
            />
          </Col>
          <Col size={50}>
            <StatisticsCompositeWrapper
              data={{
                equationStart: {
                  addend1: 250,
                  addend2: 231,
                  sum: 481,
                  label1Color: '#769851',
                  label1TextColor: '#769851',
                  label1Text: t('statistics.viaWidget'),
                  label1Icon: 'window-maximize',
                  label2Color: '#505a5e',
                  label2TextColor: '#505a5e',
                  label2Text: t('statistics.viaCalendar'),
                  label2Icon: 'calendar-alt'
                },
                equationMiddle: {
                  addend1: 103,
                  addend2: 397,
                  sum: 420,
                  label1Color: '#769851',
                  label1TextColor: '#769851',
                  label1Text: t('statistics.viaWidget'),
                  label1Icon: 'window-maximize',
                  label2Color: '#505a5e',
                  label2TextColor: '#505a5e',
                  label2Text: t('statistics.viaCalendar'),
                  label2Icon: 'calendar-alt'
                },
                sum: 901
              }}
              texts={{
                title: t('statistics.groupsSignUps'),
                subTitle: t('statistics.groupsSignUps.extraInfo'),
                textAddend1: t('global.adhoc'),
                textAddend2: t('global.groups'),
                textSum: t('global.total')
              }}
              tooltip={{
                text: t('statistics.groupsSignUps.tooltip'),
                position: 'left'
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col size={50}>
            <StatisticsWrapper
              statisticType='chart'
              data={[{ name: 'Car wash', value: 31, color: '#cce123' }, { name: 'Car inspection', value: 21, color: '#bbb123' }, { name: 'Car repairs', value: 90, color: '#aaa123' }].map(({ name, value, color }) => ({ name: truncateText(name, 24, true), value, color })).map(({ name, value, color }) => ({ name: truncateText(name, 24, true), value, color }))}
              label='Most booked services'
              headerLabel='Services + groups'
              type='pie'
              options={{
                syncId: 'topBookingServicesOverall',
                containerMinHeight: '234px',
                tooltip: true,
                dataKey: 'value',
                legend: {
                  align: 'right',
                  verticalAlign: 'middle',
                  isBookingsStat: true
                }
              }}
              statisticId='topBookingServicesOverall'
              bigHeader
            />
          </Col>
          <Col size={50}>
            <StatisticsCompositeWrapper
              data={{
                equationStart: {
                  addend1: 601,
                  addend2: 891,
                  sum: 1492,
                  label1Color: '#769851',
                  label1TextColor: '#769851',
                  label1Text: t('statistics.inAdvance'),
                  label1Icon: 'window-maximize',
                  label2Color: '#b26492',
                  label2TextColor: '#505a5e',
                  label2Text: t('statistics.onSiteSales'),
                  label2Icon: 'store'
                },
                equationMiddle: {
                  addend1: 900,
                  addend2: 213,
                  sum: 1113,
                  label1Color: '#769851',
                  label1TextColor: '#769851',
                  label1Text: t('statistics.inAdvance'),
                  label1Icon: 'window-maximize',
                  label2Color: '#b26492',
                  label2TextColor: '#505a5e',
                  label2Text: t('statistics.onSiteSales'),
                  label2Icon: 'store'
                },
                sum: 48912
              }}
              currency='EUR'
              isFinancial
              texts={{
                title: 'Revenue',
                subTitle: 'Revenue generated from single and group booking sign-up fees',
                textAddend1: t('statistics.singleBookingSignUps'),
                textAddend2: t('statistics.groupsSignUps'),
                textSum: t('global.total')
              }}
              tooltip={{
                text: 'Fees from service and group booking sign-ups. This includes fees for bookings that have taken place, and bookings that are scheduled to take place.',
                position: 'left'
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col size={50}>
            <StatisticsCompositeWrapper
              isShort
              data={{
                addend1: 324,
                addend2: 70,
                sum: 394
              }}
              texts={{
                title: 'Customers',
                subTitle: 'Number of total customers',
                textAddend1: 'Man. added / imported',
                textAddend2: t('statistics.customers.widget'),
                textSum: t('global.total')
              }}
              tooltip={{
                text: 'Customer total includes participants, but not guests.',
                position: 'left'
              }}
            />
          </Col>
          <Col size={50} />
        </Row>
      </Blur>
    </div>
  )
}

export default DashboardMock
