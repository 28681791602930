import React from 'react'

import './FixedContent.css'

const FixedContent = props => {
  const { children } = props

  return (
    <div className='ta-fixed-content'>{children}</div>
  )
}

export default FixedContent
