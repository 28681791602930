import React from 'react'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Input,
  Radio,
  Row,
  Col,
  HintText,
  Error,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  MultipleSelect,
  SectionTitle,
  t
} from '../../../Common'

import './ResourceCategoryForm.css'

const ResourceCategoryForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    disableRadioBookable = false,
    disableRadioDependency = false,
    showExternalIds,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-resources__category-form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-resource-categories__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='resourceCategory'>
            {(showExternalIds &&
              <FormSection>
                <SectionTitle label={t('global.externalId')} icon='fingerprint' ignoreTopOffset />
                <Input
                  name='externalId'
                  label={t('global.externalId')}
                  hintText={t('global.externalId.hint')}
                />
              </FormSection>
            )}
            <FormSection>
              <SectionTitle label={t('global.name')} icon='sliders-h' />
              <Input
                name='name'
                label={t('resources.edit.category.fieldName.label')}
                hintText={t('resources.edit.category.fieldName.hint')}
                mandatory
                limit={50}
              />
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <HoverPopup disabled={!disableRadioBookable}>
                    <HoverPopupContent position='bottom'>
                      {t('resources.edit.category.tooltip.text')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Radio name='isDependency' value='bookable' disabled={disableRadioBookable}>
                        <div className='ta-resources__category-type'>{t('resources.list.section.tagBookable')}</div>
                        <div>{t('resources.edit.category.checkboxBookable.description')}</div>
                      </Radio>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>

              <HoverPopup disabled={!disableRadioDependency}>
                <HoverPopupContent position='bottom'>
                  {t('resources.edit.category.tooltip.text')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Radio name='isDependency' value='dependency' disabled={disableRadioDependency}>
                    <div className='ta-resources__category-type dependency'>{t('resources.list.section.tagDependency')}</div>
                    <div>{t('resources.edit.category.checkboxDependency.description')}</div>
                    <HintText>{t('resources.edit.category.checkboxDependency.hint')}</HintText>
                  </Radio>
                </HoverPopupTrigger>
              </HoverPopup>
              <FormSection ignoreBottomOffset>
                <SectionTitle label={t('global.location')} icon='building' />
                <MultipleSelect
                  name='location'
                  label={t('global.select.label')}
                  searchable
                  disableSelected={type === 'edit'}
                  hasAll={hasAll}
                  allLabel={t('global.allLocations')}
                />
              </FormSection>
              <Row>
                <Col>
                  <Error name='globalErrors' noTopOffset />
                  <Error name='globalResourceCategories' shouldNotTranslate />
                </Col>
              </Row>
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.saveChanges.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default ResourceCategoryForm
