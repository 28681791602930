import PropTypes from 'prop-types'
import { PopupUrlOpenWarning } from '../../../Beauties'

const PopupUrlOpenWarningWrapper = props => {
  const { closePopup, extras } = props
  const { url } = extras || {}

  const approve = () => {
    closePopup?.()
  }

  const reject = () => {
    closePopup?.()
  }

  return (
    <PopupUrlOpenWarning
      approve={approve}
      reject={reject}
      url={url}
    />
  )
}

PopupUrlOpenWarningWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

export default PopupUrlOpenWarningWrapper
