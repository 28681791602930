import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  Form,
  FormText,
  SectionTitle,
  Input,
  Row,
  Col,
  Loader,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupBranchResourceDelete = props => {
  const {
    name,
    onSubmit,
    onCancel,
    pending
  } = props

  return (
    <div className='ta-popup__account-delete'>
      <Loader active={pending} />
      <SectionTitle
        label={dangerousHTML(t('resources.preview.heading.buttonDelete.tooltip.title'))}
        icon='trash-alt'
        ignoreTopOffset
      />
      <Form name='branchResourceDelete' onSubmit={onSubmit}>
        <FormText>{dangerousHTML(t('resources.preview.heading.buttonDelete.tooltip.text', [{ key: 'NAME', value: name }]))}</FormText>
        <Row>
          <Col className='relative'>
            <Input
              name='name'
              label={t('global.name')}
              hintText={t('resources.preview.heading.buttonDelete.tooltip.hint', [{ key: 'NAME', value: name }])}
              mandatory
            />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Link className='ta-btn ta-btn-secondary' onClick={onCancel}>{t('buttons.cancel.label')}</Link>
          <button type='submit' className='ta-btn ta-btn-primary'>{t('global.delete')}</button>
        </div>
      </Form>
    </div>
  )
}

PopupBranchResourceDelete.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default PopupBranchResourceDelete
