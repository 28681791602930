import React from 'react'
import PropTypes from 'prop-types'
import {
  t
} from '../../../Common'
import {
  PermissionTypeAccordion,
  PermissionTypeSummaryPermissionItem
} from '../../../Beauties'
import './PermissionTypeSummaryPermissions.css'

const PermissionTypeSummaryPermissions = props => {
  const {
    permissions,
    categoriesList,
    selectedResourceId,
    isPermissionTypes,
    isPermissionTypeAssigned,
    isAdminPermission
  } = props
  let {
    // Account
    accountRead,
    // Calendar
    calendarReadSet,
    calendarWrite,
    calendarDelete,
    // ShiftPlan
    shiftRead,
    shiftWrite,
    shiftDelete,
    // Customers
    customersReadSet,
    customersWrite,
    customersDelete,
    // Notifications
    notificationsRead,
    // Statistics
    statisticsRead,
    // Resources
    resourcesReadSet,
    resourcesWrite,
    resourcesDelete,
    // Services and Groups
    servicesAndGroupsRead,
    servicesAndGroupsWrite,
    servicesAndGroupsDelete,
    // Permission Types
    permissionsGroupsRead,
    permissionsGroupsWrite,
    permissionsGroupsDelete,
    // Apps
    appsRead
  } = permissions || {}
  resourcesWrite = !!resourcesWrite
  resourcesDelete = !!resourcesDelete
  servicesAndGroupsRead = !!servicesAndGroupsRead
  servicesAndGroupsWrite = !!servicesAndGroupsWrite
  servicesAndGroupsDelete = !!servicesAndGroupsDelete
  permissionsGroupsRead = !!permissionsGroupsRead
  permissionsGroupsWrite = !!permissionsGroupsWrite
  permissionsGroupsDelete = !!permissionsGroupsDelete

  const getPermissionTag = (permission) => {
    return permission
      ? <div className='ta-permission-types__preview__summary__permission__tag ta-permission-types__preview__summary__permission__tag__yes'>{t('global.yes')}</div>
      : <div className='ta-permission-types__preview__summary__permission__tag ta-permission-types__preview__summary__permission__tag__no'>{t('global.no')}</div>
  }

  return (
    <div className='ta-permission-types__preview__summary__permissions'>
      <PermissionTypeAccordion title={t('global.account')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(accountRead)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.calendarAndScheduling')}>
        <div className='ta-permission-types__preview__summary__permission__wrapper'>
          <div className='ta-permission-types__preview__summary__permission__title'>
            {t('global.read')}
            {getPermissionTag(!!calendarReadSet)}
          </div>
          {(calendarReadSet &&
            <PermissionTypeSummaryPermissionItem
              categoriesList={categoriesList}
              specificResourceCategoryIds={calendarReadSet && calendarReadSet.specificResourceCategoryIds}
              specificResourceIds={calendarReadSet && calendarReadSet.specificResourceIds}
              selectedResourceId={selectedResourceId}
              defaultTagName={t('permissionTypes.detail.summary.profileCalendar')}
              allEntitiesText={t('permissionTypes.form.permissions.readSelected.calendarAll')}
              shouldShowDefaultTag={!!isPermissionTypes || isPermissionTypeAssigned}
              isPermissionTypeAssigned={isPermissionTypeAssigned}
            />
          )}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(calendarWrite)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(calendarDelete)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.shiftplan')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(shiftRead)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(shiftWrite)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(shiftDelete)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.statistics')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(statisticsRead)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.history')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(notificationsRead)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.customers')}>
        <div className='ta-permission-types__preview__summary__permission__wrapper'>
          <div className='ta-permission-types__preview__summary__permission__title'>
            {t('global.read')}
            {getPermissionTag(!!customersReadSet)}
          </div>
          {(customersReadSet &&
            <PermissionTypeSummaryPermissionItem
              categoriesList={categoriesList}
              specificResourceCategoryIds={customersReadSet && customersReadSet.specificResourceCategoryIds}
              specificResourceIds={customersReadSet && customersReadSet.specificResourceIds}
              selectedResourceId={selectedResourceId}
              defaultTagName={t('permissionTypes.detail.summary.profileCustomers')}
              allEntitiesText={t('permissionTypes.form.permissions.readSelected.allCustomers')}
              shouldShowDefaultTag={!!isPermissionTypes || isPermissionTypeAssigned}
              isPermissionTypeAssigned={isPermissionTypeAssigned}
            />
          )}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(customersWrite)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(customersDelete)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.resources')}>
        <div className='ta-permission-types__preview__summary__permission__wrapper'>
          <div className='ta-permission-types__preview__summary__permission__title'>
            {t('global.read')}
            {getPermissionTag(!!resourcesReadSet)}
          </div>
          {(resourcesReadSet &&
            <PermissionTypeSummaryPermissionItem
              categoriesList={categoriesList}
              specificResourceCategoryIds={resourcesReadSet && resourcesReadSet.specificResourceCategoryIds}
              specificResourceIds={resourcesReadSet && resourcesReadSet.specificResourceIds}
              selectedResourceId={selectedResourceId}
              defaultTagName={t('permissionTypes.detail.summary.profileResource')}
              allEntitiesText={t('permissionTypes.form.permissions.readSelected.allResources')}
              shouldShowDefaultTag={!!isPermissionTypes || isPermissionTypeAssigned}
              isPermissionTypeAssigned={isPermissionTypeAssigned}
            />
          )}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(resourcesWrite)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(resourcesDelete)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.servicesAndGroups')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(isAdminPermission ? servicesAndGroupsRead : true)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(servicesAndGroupsWrite)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(servicesAndGroupsDelete)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.apps')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(appsRead)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.permissionTypes')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(isAdminPermission ? permissionsGroupsRead : true)}
        </div>
        {(isAdminPermission &&
          <>
            <div className='ta-permission-types__preview__summary__permission__title'>
              {t('global.write')}
              {getPermissionTag(permissionsGroupsWrite)}
            </div>
            <div className='ta-permission-types__preview__summary__permission__title'>
              {t('global.delete')}
              {getPermissionTag(permissionsGroupsDelete)}
            </div>
          </>
        )}
      </PermissionTypeAccordion>
    </div>
  )
}

PermissionTypeSummaryPermissions.propTypes = {
  permissions: PropTypes.object.isRequired,
  categoriesList: PropTypes.array.isRequired
}

export default PermissionTypeSummaryPermissions
