import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { ServiceCombinationForm } from '../../Beauties'

const ServiceCombinationEditWrapper = props => {
  let {
    id,
    pending,
    showExternalIds,
    form,
    hash,
    resourceRemindersEmailRecipients,
    customerEmailRemindersMinutes
  } = props
  showExternalIds = !!showExternalIds
  form = form || {}
  const hasCustomerReminders = customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0 && (customerEmailRemindersMinutes.length !== 1 || customerEmailRemindersMinutes[0] !== 0)
  const hasResourceReminders = resourceRemindersEmailRecipients && resourceRemindersEmailRecipients.length > 0
  const hasAdvancedTab = hasCustomerReminders || hasResourceReminders

  useEffect(() => {
    handlers.serviceCombinationFormGet(id)
  }, [id])

  const onSubmit = cb => {
    handlers.serviceCombinationFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServiceCombinationForm
          id={id}
          title={t('servicesGroups.edit.serviceCombination.heading')}
          type='edit'
          cancelLink={`/management/services/${id}`}
          onSubmit={onSubmit}
          showExternalIds={showExternalIds}
          hash={hash}
          hasAdvancedTab={hasAdvancedTab}
        />
      )}
    </>
  )
}

const maps = state => ({
  id: state.router.data.id,
  pending: state.services.pendingForm,
  showExternalIds: state.company.settings && state.company.settings.showExternalIds,
  form: state.forms.services,
  hash: state.router.hash,
  resourceRemindersEmailRecipients: state.company.settings && state.company.settings.resourceRemindersEmailRecipients,
  customerEmailRemindersMinutes: state.company.settings && state.company.settings.customerEmailRemindersMinutes
})

export default memo(connect(maps)(ServiceCombinationEditWrapper))
