import React from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'

const BranchesPreviewServicesFilter = props => {
  const { companyId, branchesWrite } = props
  return (
    <Form name='branchServicesFilter'>
      <Row noOffset>
        <Col size={branchesWrite ? 95 : 100}>
          <Input
            name='name'
            label={t('global.searchByName.label')}
            icon='map'
            addon={<FontAwesome5 icon='search' />}
          />
        </Col>
        {(branchesWrite &&
          <Col>
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {t('global.importData')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <PopupTriggerWrapper
                  extraClassName='ta-service-import-popup'
                  name='branch-services-import'
                  position='bottom-left'
                  id={{ selector: '.ta-popup__content.ta-service-import-popup', companyId }}
                >
                  <button className='ta-btn ta-btn__export ta-btn-icon-only ta-btn-secondary'>
                    <FontAwesome5 icon='upload' type='s' />
                  </button>
                </PopupTriggerWrapper>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default BranchesPreviewServicesFilter
